import React, { useCallback, useEffect, useMemo } from 'react'
import { Selector, useDispatch, useSelector } from 'react-redux'

import TeamSelector from '@shared/TeamSelector'
import { getManagementType } from '@domain/instanceConfig/selectors'
import { getSelectedTeamIds } from '@generic/selectors/selectedTeams'
import { getLocationParams, getNotRestrictedTeamsTree } from '@generic/selectors'

import { setSelectedTeamId } from '@generic/actions/actions'

import { teamTitles } from '@common/accessController/constants'
import { isSMO } from '@myImoConfig/containers/ConfigSteps/utils'
import { getTeamsList, getCurrentTeamId } from '@common/teamFilter/teamFilterConditions'
import { replace, flatten, head, isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { GenericTeam } from '@domain/generic/reducers/types'

interface BreadcrumbTeamSelectorProps {
  hideRestrictedTeams?: boolean
}

// Code borrowed from Header.js
export const BreadcrumbTeamSelector = ({ hideRestrictedTeams }: BreadcrumbTeamSelectorProps) => {
  const dispatch = useDispatch()

  const teamsTree = useSelector((state) =>
    (getNotRestrictedTeamsTree as Selector<unknown, $TSFixMe, unknown>)(state, hideRestrictedTeams),
  )

  const selectedTeamIds = useSelector(getSelectedTeamIds)
  const teamsList = useSelector(getTeamsList())
  const managementType = useSelector(getManagementType)
  const navigate = useNavigate()

  const withSmo = isSMO(managementType)

  const locationParams = useSelector(getLocationParams)

  const { pathname, isImoControlTower, isVcTracker, teamId, fromTeam, fromImoTeam, isOrgDesignTracker, fromTeamHome } =
    locationParams

  const getTeamSelectType = useMemo(() => {
    if (isImoControlTower) return teamTitles.IMO

    if (isVcTracker) return teamTitles.CENTRAL_VC

    if (isOrgDesignTracker) return teamTitles.CENTRAL_ORG_DESIGN

    return withSmo ? teamTitles.SEPARATION : teamTitles.INTEGRATION
  }, [isImoControlTower, isVcTracker, withSmo])

  const getExistingTeam = useMemo(() => {
    const headTeam: GenericTeam | undefined = head(flatten(Object.values(teamsList)))

    return headTeam?.id
  }, [teamsList])

  const teamExists = (id: number) => {
    const flattenTeams: GenericTeam[] = flatten(Object.values(teamsList))

    return flattenTeams.find((team) => team.id === id)
  }

  const updateLocationWithTeam = (id: number) => {
    const paramsForImoTeam = fromImoTeam ? `?fromImoTeam=${fromImoTeam}` : ''
    const fromTeamParams = fromTeam ? `?fromTeam=${fromTeam}` : paramsForImoTeam
    const fromTeamHomeParams = fromTeamHome ? `?fromTeamHome=true` : ''
    const params = fromTeamParams.length ? fromTeamParams : fromTeamHomeParams
    navigate(replace(pathname, `team/${teamId}`, `team/${id}`) + params)
  }

  useEffect(() => {
    if (!teamId || isEmpty(teamsList)) return

    if (!teamExists(teamId)) {
      const selectedTeam = getCurrentTeamId(locationParams, selectedTeamIds)
      const existingSelectedTeam = teamExists(selectedTeam) ? selectedTeam : getExistingTeam

      existingSelectedTeam && updateLocationWithTeam(existingSelectedTeam)

      return
    }

    if (isImoControlTower && teamId !== selectedTeamIds.imoTeam) {
      dispatch(setSelectedTeamId({ imoTeam: teamId }))
    } else if (isVcTracker && teamId !== selectedTeamIds.centralTeam) {
      dispatch(setSelectedTeamId({ centralTeam: teamId }))
    } else if (!isImoControlTower && !isVcTracker && teamId !== selectedTeamIds.team) {
      dispatch(setSelectedTeamId({ team: teamId }))
    }
  }, [teamId, teamsTree, selectedTeamIds, teamsList])

  const handleTeamSelect = useCallback(
    (team: GenericTeam) => {
      updateLocationWithTeam(team.id)

      if (isImoControlTower) {
        dispatch(setSelectedTeamId({ imoTeam: team.id }))

        return
      }

      if (isVcTracker) {
        // TODO: refactor selectedTeamId logic, centralTeam id always equal to the first id in teams response
        dispatch(setSelectedTeamId({ centralTeam: team.id }))

        return
      }

      if (isOrgDesignTracker) {
        dispatch(setSelectedTeamId({ centralOrgDesignTeam: team.id }))
        return
      }

      dispatch(setSelectedTeamId({ team: team.id }))
    },
    [updateLocationWithTeam, isImoControlTower, isVcTracker, isOrgDesignTracker],
  )

  return (
    <>
      {teamId ? (
        <TeamSelector
          teamType={getTeamSelectType}
          teamsList={teamsList}
          selectedTeam={teamId}
          onTeamSelect={handleTeamSelect}
          managementType={managementType}
          isVCTracker={isVcTracker}
          isOrgDesignTracker={isOrgDesignTracker}
        />
      ) : null}
    </>
  )
}

import { userRoles, accessRights } from '../../constants'
import { routesList } from '../../routesList'

export const CONFIG_ADMIN_PERMISSIONS = {
  name: userRoles.CONFIG_ADMIN,
  role: userRoles.CONFIG_ADMIN,
  priority: 8,
  exceptions: [routesList.CONFIG.route],
  redirects: {
    [routesList.CONFIG.route]: routesList.CONFIG.route,
  },
  permissions: [
    {
      route: routesList.CONFIG,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.USER_MANAGEMENT,
      accessLevel: accessRights.READ_WRITE,
    },
  ],
}

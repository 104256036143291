// Libs
import { map } from 'lodash'
import { groupBy } from 'lodash/fp'

export const formatLibrariesList = (librariesToUpdate: $TSFixMe, selectedLibraryId: $TSFixMe) =>
  map(librariesToUpdate, (library) => ({
    ...library,
    selected: library.id === selectedLibraryId,
  }))

export const getRows = (list: $TSFixMe) =>
  list
    .map((item: $TSFixMe) => {
      const keyProcessName = list.find((listItem: $TSFixMe) => listItem.id === item.keyProcessId)?.name

      if (item.type === 'project') {
        const { name: projectName, ...project } = item

        const hasTasks = list.find((listItem: $TSFixMe) => listItem.type === 'task' && listItem.projectId === item.id)

        return hasTasks ? { ...item, keyProcessName, projectName } : { ...project, keyProcessName, name: projectName }
      }

      if (item.type === 'task') {
        const projectName = list.find((listItem: $TSFixMe) => listItem.id === item.projectId)?.name

        return { ...item, keyProcessName, projectName }
      }

      return item
    })
    .filter((item: $TSFixMe) => item.type !== 'keyProcess' && item.name)

export const getDayOneRowNodeId = ({ data: { keyProcessId, id, projectId } }: $TSFixMe) =>
  `${keyProcessId}-${projectId}-${id}`

export const setPrimaryFirst = (libs: $TSFixMe) => {
  const { isPrimary = [], rest = [] } = groupBy(({ isPrimary }) => (isPrimary ? 'isPrimary' : 'rest'), libs)

  return [...isPrimary, ...rest]
}

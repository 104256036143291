import { createSaga } from '@common/sagaCreator/createSaga'
import { getInitiativeListV2Api } from '@common/net'
import { call, put } from 'redux-saga/effects'
import { setInitiativesCategoriesList } from '@teamValueCaptureV2/initiativeList/actions/actions'

export const fetchInitiativesCategories = createSaga(function* fetchInitiativesCategories({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, synergyTypes } = payload
  const api = yield getInitiativeListV2Api()
  const data = yield call(
    api.request('getInitiativesCategories', {
      query: {
        teamId,
        synergyTypes,
      },
    }),
  )

  yield put(setInitiativesCategoriesList({ data }))

  return data
})

import { LogoutDiagnostics } from '@domain/generic/sagas/logout'
import { api } from '@common/net'
import { Diagnostics } from '../Sider/components/useLogDiagnostics'

const logSuccess = (type: 'ERROR' | 'DEBUG' | 'LOGOUT', response: Awaited<ReturnType<typeof api.post>>) => {
  const isNotProd = ['development', 'qa', 'staging'].includes(window.NODE_ENV)
  if (isNotProd) {
    window.console.info(`SEND ${type} SUCCESS 👉`, response)
  }
}

export async function sendErrorLog(
  details: Diagnostics & { error: { message?: string; errorStack?: string; componentStack?: string } },
  type = 'unknown_frontend_error',
) {
  const payload = {
    level: 'error',
    type: type,
    data: details,
  }

  const response = await api.post({ url: 'log', data: payload })

  logSuccess('ERROR', response)
}

export async function sendDebugLog(details: Diagnostics, type = 'debug') {
  const payload = {
    level: 'info',
    type: type,
    data: details,
  }

  const response = await api.post({ url: 'log', data: payload })

  logSuccess('DEBUG', response)
}

export async function sendLogoutLog(details: LogoutDiagnostics, type = 'logout') {
  const payload = {
    level: 'info',
    type: type,
    data: details,
  }
  const response = await api.post({ url: 'log', data: payload })

  logSuccess('LOGOUT', response)
}

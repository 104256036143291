/* eslint-disable react/require-default-props */
// [aliases/synonyms]: SmartDropdown, Filter, DropdownFilter, FilterDropdown
import React from 'react'
import { getOr } from 'lodash/fp'
import { Placement } from '@shared/Popover'
import Menu from './Menu'
import { StyledButton, StyledPopover, Title } from './index.styles'

interface Props {
  name?: string
  id?: string
  list: $TSFixMe
  selectedItem: $TSFixMe
  selectItem: (...args: $TSFixMe[]) => $TSFixMe
  className?: string
  icon?: string
  title?: string
  getOptionLabel?: (...args: $TSFixMe[]) => $TSFixMe
  getOptionValue?: (...args: $TSFixMe[]) => $TSFixMe
  menuStyles?: $TSFixMe
  menuIconSize?: number
  isCustomLabel?: boolean
  disabled?: boolean
  getCustomLabel?: (...args: $TSFixMe[]) => $TSFixMe
  buttonProps?: $TSFixMe
  rightIcon?: string
  elementToScroll?: string
  ariaLabel?: string
  ariaLabelledBy?: string
  onInteraction?:
    | ((nextOpenState: boolean, event?: React.SyntheticEvent<HTMLElement, Event> | undefined) => void)
    | undefined
  byPassSingleItemCheck?: boolean
}

const MenuSelect = ({
  name,
  id,
  list,
  selectedItem,
  selectItem,
  className,
  icon,
  getOptionLabel = (item: $TSFixMe) => getOr(item, 'name', item),
  getOptionValue = (item: $TSFixMe) => getOr(item, 'id', item),
  isCustomLabel,
  getCustomLabel = (item: $TSFixMe) => getOr(item, 'name', item),
  menuStyles,
  menuIconSize,
  buttonProps,
  title,
  rightIcon,
  elementToScroll,
  disabled,
  ariaLabel,
  ariaLabelledBy,
  onInteraction,
  byPassSingleItemCheck = false,
}: Props) => {
  const label = isCustomLabel ? getCustomLabel(selectedItem) : getOptionLabel(selectedItem)

  if (list.length === 1 && !byPassSingleItemCheck) {
    return (
      <StyledButton
        icon={icon || selectedItem.icon}
        text={label}
        className="single-item"
        {...{ ...buttonProps, id, 'aria-label': ariaLabel }}
        minimal
        small
      />
    )
  }

  const getText = () =>
    title ? (
      <>
        <Title>{`${title}: `}</Title>
        {label}
      </>
    ) : (
      label
    )

  return (
    <StyledPopover
      className={className}
      onInteraction={onInteraction}
      content={
        <Menu
          id={id ? `${id}_menu` : undefined}
          ariaLabelledBy={ariaLabelledBy}
          ariaLabel={ariaLabel ? `${ariaLabel} Menu` : undefined}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          list={list}
          setActiveItem={selectItem}
          activeItem={selectedItem}
          styles={menuStyles}
          iconSize={menuIconSize}
          elementToScroll={elementToScroll}
        />
      }
      placement={Placement.BOTTOM_START as $TSFixMe}
      targetTagName={'div'}
      disabled={disabled}
    >
      <StyledButton
        name={name}
        disabled={disabled}
        minimal
        small
        rightIcon={rightIcon || 'caret-down'}
        icon={icon || selectedItem.icon}
        text={getText()}
        {...{ ...buttonProps, id, 'aria-label': ariaLabel, 'aria-controls': id ? `${id}_menu` : undefined }}
      />
    </StyledPopover>
  )
}

MenuSelect.defaultProps = {
  className: '',
  menuStyles: {},
  icon: null,
  title: null,
  isCustomLabel: false,
  disabled: false,
  buttonProps: {},
  rightIcon: null,
  elementToScroll: null,
  id: undefined,
  ariaLabel: undefined,
}

export default MenuSelect

import { createSelector } from 'reselect'
import { get, filter, map, orderBy } from 'lodash'
import { find, getOr as getOrFp, flow, map as mapFp, filter as filterFp } from 'lodash/fp'
import { clientState, getAllTeams, allTeams } from './index'
import { teams } from '@common/accessController/constants'
import { VALUE_CAPTURE_DELIVERABLE_TYPE_ID } from '@helpers/constants'

export const getSelectedTeamIds = createSelector(clientState, (client) => get(client, ['selectedTeamIds']))
export const getSelectedTeamId = createSelector(clientState, (client) => get(client, ['selectedTeamIds', 'team']))
export const getSelectedImoTeamId = createSelector(clientState, (client) => get(client, ['selectedTeamIds', 'imoTeam']))
export const getSelectedCentralTeamId = createSelector(clientState, (client) =>
  get(client, ['selectedTeamIds', 'centralTeam']),
)
export const getSelectedCentralOrgDesignTeamId = createSelector(clientState, (client) =>
  get(client, ['selectedTeamIds', 'centralOrgDesignTeam']),
)

// getActiveVCTeamId will return active team id on VC Tracker page
// when the page was loaded or the team has been changed
export const getActiveVCTeamId = createSelector(clientState, (client) => get(client, 'activeVCTeamId'))

// TODO: get rid of closure, do selector with additional argument
export const getTeamById = (teamId: number) =>
  createSelector(getAllTeams, (teamsList: $TSFixMe) => {
    return teamsList.find((team: $TSFixMe) => team.id === Number(teamId))
  })

// TODO: get rid of getSelectedTeamData, getSelectedImoTeamData, getSelectedImoTeamData. Start using getTeamById
export const getSelectedTeamData = createSelector(getSelectedTeamId, getAllTeams, (teamId, teamsList: $TSFixMe) => {
  return teamsList.find((team: $TSFixMe) => team.id === teamId)
})

export const getIsIntegrationTeamSelected = createSelector(getSelectedTeamData, (selectedTeam) => {
  if (!selectedTeam) return false

  return [teams.INTEGRATION, teams.SEPARATION].includes(selectedTeam.teamType)
})

export const getIsSelectedProcessFromIntegrationTeam = createSelector(
  getAllTeams,
  (_: $TSFixMe[], teamId: $TSFixMe) => teamId,
  (allTeams, teamId) => {
    // @ts-ignore
    const selectedProcessTeam = allTeams.find(({ id }: $TSFixMe) => id === teamId)

    if (!selectedProcessTeam) return false

    return [teams.INTEGRATION, teams.SEPARATION].includes(selectedProcessTeam.teamType)
  },
)

export const getSelectedImoTeamData = createSelector(
  getSelectedImoTeamId,
  getAllTeams,
  (teamId, teamsList: $TSFixMe) => {
    return teamsList.find((team: $TSFixMe) => team.id === teamId)
  },
)

export const getSelectedCentralTeamData = createSelector(
  getSelectedCentralTeamId,
  getAllTeams,
  (teamId, teamsList: $TSFixMe) => {
    return teamsList.find((team: $TSFixMe) => team.id === teamId)
  },
)

const importKnowledgeCenterState = (state: $TSFixMe) => get(state, ['client', 'importKnowledgeCenter'])

export const getLibrariesForImport = createSelector(importKnowledgeCenterState, (state) =>
  get(state, ['librariesForImport']),
)

export const getLibraryAssets = createSelector(importKnowledgeCenterState, (state) => get(state, ['libraryAssets']))

export const getLibraryAssetsFetchDate = createSelector(importKnowledgeCenterState, (state) =>
  get(state, ['libraryAssetsFetchDate']),
)

export const getSelectedAssetIds = createSelector(getLibraryAssets, (libraryAssets) => {
  const allAssets = libraryAssets.reduce(
    (acc: $TSFixMe, data: $TSFixMe) => [...acc, ...(data.videos || data.articles || data.introductionMaterials)],
    [],
  )

  const selectedVideos = filter(allAssets, { selected: true })

  return map(selectedVideos, 'id')
})

export const getRevieweeTeamIds = createSelector(
  allTeams,
  getSelectedCentralTeamId,
  (allTeams, selectedCentralTeamId) => {
    return flow(find({ id: selectedCentralTeamId }), getOrFp([], 'revieweeTeams'), mapFp('id'))(allTeams)
  },
)

export const getVCRevieweeTeamIds = createSelector(
  allTeams,
  getSelectedCentralTeamId,
  (allTeams, selectedCentralTeamId) => {
    return flow(
      find({ id: selectedCentralTeamId }),
      getOrFp([], 'revieweeTeams'),
      filterFp((item: { id: number; deliverableTypeIds: number[] }) =>
        item.deliverableTypeIds.includes(VALUE_CAPTURE_DELIVERABLE_TYPE_ID),
      ),
      mapFp('id'),
    )(allTeams)
  },
)

export const getActiveRevieweeVCTeams = createSelector(
  allTeams,
  getVCRevieweeTeamIds,
  (allTeams, vcRevieweeTeamIds) => {
    const teams = vcRevieweeTeamIds.map((id: number) => find({ id }, allTeams))

    return orderBy(teams, (team: $TSFixMe) => team.longName?.toLowerCase())
  },
)

import * as Yup from 'yup'
import { pick } from 'lodash/fp'
import { matches } from 'lodash'

export const DayOneReadinessSubmitSchema = Yup.array().of(
  Yup.object().shape({
    complete: Yup.mixed().oneOf([true], 'Scenario should be marked as completed'),
  }),
)

export function validateSelectedScenario() {
  const {
    path,
    createError,
    parent,
    options: { context },
  } = this

  if (parent.disabled || !parent.selected) return true

  const valueToCheck = pick(['category', 'description', 'interdependencies'], parent)
  const scenarioMatcher = matches(valueToCheck)

  const isNotValid = context?.scenarios?.some(scenarioMatcher)

  if (isNotValid) return createError({ path, message: Object.values(valueToCheck).join(' - ') })

  return true
}

export const DayOneReadinessLibrarySchema = Yup.array().of(
  Yup.object().shape({
    category: Yup.mixed().nullable().test('checkSelectedScenario', validateSelectedScenario),
  }),
)

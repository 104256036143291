import { call, put, select } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getTaskActivityLogApi } from '@common/net'
import { setTaskActivityLog } from '../actions/actions'
import { ACTIVITY_LOG_PAGE_SIZE } from '@domain/myImoClient/common/constants'
import { getTaskActivityLogs } from '@domain/myImoClient/common/taskActivityLog/selectors'
import { Action } from 'redux-actions'
import { AgNumberAxisThemeOptions } from 'ag-grid-community'

export type FetchTaskActivityLogActionPayload = {
  teamId: number
  keyProcessId: number
  taskId: number
  keyProcessProjectId: AgNumberAxisThemeOptions
  replace?: boolean
  offset?: number
}

export const fetchTaskActivityLogSaga = createSaga(function* fetchTaskActivityLogSaga(
  action: Action<FetchTaskActivityLogActionPayload>,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const state: ReturnType<typeof getTaskActivityLogs> = yield select(getTaskActivityLogs)

  const { teamId, keyProcessId, taskId, keyProcessProjectId, replace, offset } = action.payload

  const currentTaskLog = state?.[taskId]

  const pageNo = currentTaskLog ? currentTaskLog.items.length : 0
  let nextOffset = pageNo
  if (offset !== undefined) {
    nextOffset = offset
  }

  const taskActivityLogApi = yield getTaskActivityLogApi()

  const data = yield call(
    taskActivityLogApi.request('fetchTaskActivityLog', {
      query: { teamId, keyProcessId, taskId, keyProcessProjectId, limit: ACTIVITY_LOG_PAGE_SIZE, offset: nextOffset },
    }),
  )

  yield put(setTaskActivityLog({ taskId, data, replace }))
},
false)

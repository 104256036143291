import { put, call } from 'redux-saga/effects'
import { fetchCalendar, setCalendar } from '@teamWorkspace/actions/actions'
import { getTeamWorkspaceApi } from '@common/net'
import { omit } from 'lodash'
import { createSaga } from '@common/sagaCreator/createSaga'
import { parseOwner } from '@shared/Grid/utils/ownerCellUtils'

export const getCalendar = createSaga(function* getCalendar({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamWorkspaceApi()
  const calendarData = yield call(api.request('getCalendar', { query: { teamId: payload } }))

  yield put(setCalendar(calendarData))
})

export const createCalendar = createSaga(function* createCalendar({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(api.request('createCalendar', { query: { teamId }, body: omit(data, 'isNew', 'id') }))

  yield put(fetchCalendar(teamId))
})

export const updateCalendar = createSaga(function* updateCalendar({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(
    api.request('updateCalendar', {
      query: { teamId, calendarId: data.id },
      body: { ...omit(data, 'isNew', 'createdAt', 'updatedAt', 'id', 'teamId'), owner: parseOwner(data.owner) },
    }),
  )

  yield put(fetchCalendar(teamId))
})

export const deleteCalendar = createSaga(function* deleteCalendar({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(api.request('deleteCalendar', { query: { teamId, calendarId: data.id } }))

  yield put(fetchCalendar(teamId))
})

import { api } from '@root/src/common/net'
import { useQuery } from 'react-query'

export const useImportantNotifications = (enabled = true) => {
  const { data } = useQuery(
    ['notifications-hub', 'important'],
    async () => {
      const { data } = await api.get<{ id: string; typeId: number }[]>({ url: 'notifications-hub/important' })
      return data
    },
    { useErrorBoundary: false, enabled },
  )
  return { data }
}

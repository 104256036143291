import React from 'react'
import { Intent, Spinner, SpinnerSize } from '@blueprintjs/core'
import { LoaderBackdrop } from './index.styles'
import cn from 'classnames'

interface Props {
  isShown?: boolean
  loaderText?: string
  position?: 'fixed' | 'absolute'
  showSpinner?: boolean
}

const Loader = ({ isShown, position, loaderText, showSpinner }: Props) => (
  <LoaderBackdrop
    style={{ visibility: isShown ? 'visible' : 'hidden', position }}
    className={cn({ 'loader-text': loaderText })}
  >
    {showSpinner && <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />}
    {loaderText && <div>{loaderText}</div>}
  </LoaderBackdrop>
)

Loader.defaultProps = {
  isShown: true,
  position: 'fixed',
  loaderText: null,
}

export default Loader

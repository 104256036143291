import { handleActions } from 'redux-actions'
import * as constants from '@functionalActivity/actions/actionTypes'

export interface IFunctionalActivityState {
  functionalActivityMappings: $TSFixMe[]
  locked: boolean
}

export const initialState = {
  functionalActivityMappings: [],
  locked: false,
}

const functionalActivityReducer = handleActions<IFunctionalActivityState, $TSFixMe>(
  {
    [constants.SET_KEY_PROCESSES]: (state, action) => {
      const {
        functionalActivity: { functionalActivityMappings, locked },
      } = action.payload

      return {
        ...state,
        functionalActivityMappings,
        locked,
      }
    },

    [constants.CREATE_KEY_PROCESS_SUCCESS]: (state, action) => {
      const { data } = action.payload
      const { functionalActivityMappings, locked } = state

      return {
        ...state,
        functionalActivityMappings: [...functionalActivityMappings, data],
        locked,
      }
    },

    [constants.UPDATE_KEY_PROCESS_SUCCESS]: (state, action) => {
      const { id, data } = action.payload
      const { functionalActivityMappings, locked } = state

      const updatedKeyProcesses = functionalActivityMappings.map((keyProcess) => {
        if (keyProcess.id === id) {
          return data
        }

        return keyProcess
      })

      return {
        ...state,
        functionalActivityMappings: updatedKeyProcesses,
        locked,
      }
    },

    [constants.DELETE_KEY_PROCESS_SUCCESS]: (state, action) => {
      const { id } = action.payload
      const { functionalActivityMappings, locked } = state

      const filteredKeyProcesses = functionalActivityMappings.filter((keyProcess) => keyProcess.id !== id)

      return {
        ...state,
        functionalActivityMappings: filteredKeyProcesses,
        locked,
      }
    },
  },
  initialState,
)

export default functionalActivityReducer

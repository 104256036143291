import styled from 'styled-components'
import { Icon } from '@blueprintjs/core'
import { CustomPopover } from '@shared/Popover'
import { brightGrey } from '@imo/imo-ui-toolkit'

export const StyledIcon = styled(Icon)`
  vertical-align: middle;
  ${({
    // @ts-expect-error TS(2339): Property 'styles' does not exist on type 'IIconPro... Remove this comment to see the full error message
    styles,
  }) => styles}
`

export const PopoverContent = styled.div`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .attachment-item {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &.instructions {
    margin-top: 15px;
    margin-bottom: -5px;

    h3 {
      color: ${brightGrey};
      margin-bottom: 0;
    }
  }
`
export const StyledCustomPopover = styled(CustomPopover)<{ customStyles?: $TSFixMe }>`
  ${({ customStyles }) => customStyles}
`

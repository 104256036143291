import { handleActions } from 'redux-actions'
import * as constants from '@myImoConfigActions/deliverables/actionTypes'
import { omit, orderBy } from 'lodash'
import { ORG_DESIGN_DELIVERABLE_TYPE_ID } from '@helpers/constants'
import { sortDeliverables } from '@helpers/sortDeliverables'

export type DeliverableType = {
  createdAt: string
  custom: boolean
  deliverables: Deliverable[]
  id: number
  order: number
  typeName: string
  updatedAt: string
}
interface IDeliverablesState {
  deliverableTypes: DeliverableType[]
}

export type Deliverable = {
  active: boolean
  createdAt: string
  custom: boolean
  dealType: string | null
  defaultLongName: string
  deliverableTypeId: number
  deliverableTypeName: string
  dueDate: string
  id: number
  instruction: string
  instructionURL: string
  libraryId: null | number
  longName: string
  order: number
  orgDesignLayer?: {
    dueDateDesign: string
    dueDateSelection: string
  } | null
  numberOfOrgDesignLayers?: number
  shortName: string
  startDate: string
  templateURL: string
  updatedAt: string
}

const DELIVERABLE_SORTING_ACCESSORS: Record<
  'orgLayerAccessor' | 'startDateAccessor' | 'dueDateAccessor' | 'orderAccessor',
  keyof Deliverable
> = {
  orgLayerAccessor: 'orgDesignLayer',
  startDateAccessor: 'startDate',
  dueDateAccessor: 'dueDate',
  orderAccessor: 'order',
}

const initialState: IDeliverablesState = {
  deliverableTypes: [],
}

export const deliverablesReducer = handleActions<IDeliverablesState, $TSFixMe>(
  {
    [constants.ON_DELIVERABLE_TYPE_SUCCESS]: (state, action) => {
      const deliverableTypes = action.payload.map((type: DeliverableType) => {
        const { deliverables } = type

        return {
          ...type,
          deliverables: sortDeliverables({
            ...DELIVERABLE_SORTING_ACCESSORS,
            deliverables,
          }),
        }
      })

      return { ...state, deliverableTypes }
    },
    [constants.CHANGE_DELIVERABLE_TYPE]: (state, action) => {
      const { deliverableTypes } = action.payload

      return { ...state, deliverableTypes }
    },
    [constants.DELETE_DELIVERABLE]: (state, action) => {
      const { deliverableTypes } = state
      const {
        index,
        type: { id: typeId, name: typeName },
      } = action.payload

      const updatedDeliverableTypes = deliverableTypes.map((type) => {
        const { deliverables } = type

        if (type.id !== typeId && type.typeName !== typeName) {
          return type
        }

        const updatedDeliverables = deliverables.filter((_, deliverableIndex: number) => deliverableIndex !== index)

        return {
          ...type,
          deliverables: sortDeliverables({
            ...DELIVERABLE_SORTING_ACCESSORS,
            deliverables: updatedDeliverables,
          }),
        }
      })

      return { ...state, deliverableTypes: updatedDeliverableTypes }
    },
    [constants.STORE_NUMBER_OF_ORG_DESIGN_LAYERS]: (state, action) => {
      return {
        ...state,
        deliverableTypes: state.deliverableTypes.map((deliverableType) => {
          if (deliverableType.id === ORG_DESIGN_DELIVERABLE_TYPE_ID) {
            const deliverableMap = deliverableType.deliverables.reduce(
              (acc: Record<'layers' | 'otherDeliverables', Deliverable[]>, d: Deliverable) => {
                if (d.hasOwnProperty('orgDesignLayer') && d.orgDesignLayer !== null) {
                  return {
                    ...acc,
                    layers: [...acc.layers, d],
                  }
                }

                return {
                  ...acc,
                  otherDeliverables: [...acc.otherDeliverables, d],
                }
              },
              { layers: [], otherDeliverables: [] },
            )

            const layers = orderBy(deliverableMap.layers, 'order')

            // The offset is needed because the 'top' layer of the organisation is not part of the IMO process
            const withOffset = action.payload - 1

            const activeLayers = layers.slice(0, withOffset).map((l) => ({ ...l, active: true }))
            const inactiveLayers = layers.slice(withOffset).map((l) => ({ ...l, active: false }))

            return {
              ...deliverableType,
              numberOfOrgDesignLayers: action.payload,
              deliverables: sortDeliverables({
                ...DELIVERABLE_SORTING_ACCESSORS,
                deliverables: [...deliverableMap.otherDeliverables, ...activeLayers, ...inactiveLayers],
              }),
            }
          }

          return deliverableType
        }),
      }
    },
    [constants.UPDATE_DELIVERABLE]: (state, action) => {
      const { deliverableTypes } = state
      const { index, deliverableTypeId, deliverableTypeName, id, ...deliverableData } = action.payload

      const updatedDeliverableTypes = deliverableTypes.map((type) => {
        const { deliverables } = type

        if (type.id !== deliverableTypeId && type.typeName !== deliverableTypeName) {
          return type
        }

        const updatedDeliverables = deliverables.map((deliverable, deliverableIndex: number) => {
          if (id ? deliverable.id === id : deliverableIndex === index) {
            return {
              ...deliverable,
              ...deliverableData,
            }
          }

          return deliverable
        })

        return {
          ...type,
          deliverables: sortDeliverables({
            ...DELIVERABLE_SORTING_ACCESSORS,
            deliverables: updatedDeliverables,
          }),
        }
      })

      return { ...state, deliverableTypes: updatedDeliverableTypes }
    },
    [constants.CREATE_DELIVERABLE]: (state, action) => {
      const { deliverableTypes } = state
      const { deliverableTypeId, deliverableTypeName } = action.payload

      const updatedDeliverableTypes = deliverableTypes.map((type) => {
        const { deliverables } = type

        if (type.id !== deliverableTypeId || type.typeName !== deliverableTypeName) {
          return type
        }

        const updatedDeliverables = [...deliverables, omit(action.payload, 'index') as Deliverable]

        return {
          ...type,
          deliverables: sortDeliverables({
            ...DELIVERABLE_SORTING_ACCESSORS,
            deliverables: updatedDeliverables,
          }),
        }
      })

      return { ...state, deliverableTypes: updatedDeliverableTypes }
    },
  },
  initialState,
)

import { EntityType } from '@helpers/constants'
import { createAction } from 'redux-actions'
import * as constants from './actionTypes'

type TeamRole = {
  role: string
  teamName: string
  teamType: string
}

export type Watcher = {
  userId: number
  teams: TeamRole[]
  name: string
}

// api calls
type WatchTaskPayload = {
  teamId: number
  taskId: number
  projectId: number
  keyProcessId: number
  entityType: EntityType
}
export const watchTask = createAction<WatchTaskPayload>(constants.WATCH_TASK)

type WatchInterdependentTaskPayload = {
  teamId: number
  taskId: number
  projectId: number
  interdependencyId: number
  entityType: EntityType
}
export const watchInterdependentTask = createAction<WatchInterdependentTaskPayload>(constants.WATCH_INTERDEPENDENT_TASK)

type UnwatchTaskPayload = {
  teamId: number
  taskId: number
  userId: number
  projectId: number
  keyProcessId: number
  entityType: EntityType
}
export const unwatchTask = createAction<UnwatchTaskPayload>(constants.UNWATCH_TASK)

type UnwatchInterdependentTaskPayload = {
  teamId: number
  taskId: number
  userId: number
  projectId: number
  interdependencyId: number
  entityType: EntityType
}
export const unwatchInterdependentTask = createAction<UnwatchInterdependentTaskPayload>(
  constants.UNWATCH_INTERDEPENDENT_TASK,
)

export type FetchTaskWatchersPayload = {
  teamId: number
  keyProcessId: number
  projectId: number
  taskId: number
}
export const fetchTaskWatchers = createAction<FetchTaskWatchersPayload>(constants.FETCH_TASK_WATCHERS)

export type FetchInterdependentTaskWatchersPayload = {
  teamId: number
  projectId: number
  taskId: number
  interdependencyId: number
}
export const fetchInterdependentTaskWatchers = createAction<FetchInterdependentTaskWatchersPayload>(
  constants.FETCH_INTERDEPENDENT_TASK_WATCHERS,
)

// store updates
type SetTaskWatchersPayload = {
  taskId: number
  watchers: Watcher[]
}
export const setTaskWatchers = createAction<SetTaskWatchersPayload>(constants.SET_TASK_WATCHERS)

type AddWatcherPayload = { teamId: number; taskId: number; projectId: number; watcher: Watcher }
export const addTaskWatcher = createAction<AddWatcherPayload>(constants.ADD_TASK_WATCHER)

type RemoveWatcherPayload = { taskId: number; userId: number }
export const removeTaskWatcher = createAction<RemoveWatcherPayload>(constants.REMOVE_TASK_WATCHER)

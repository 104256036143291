import { isArray, map } from 'lodash/fp'
import { call, put } from 'redux-saga/effects'
import { getInitiativeListV2Api } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { synergyTypes } from '@helpers/constants'
import { setMonthlyPipelineStatus } from '@teamValueCaptureV2/monthByMonth/actions/actions'
import { SET_MONTHLY_PIPELINE_LOADING_STATE } from '@teamValueCaptureV2/monthByMonth/actions/actionTypes'

export const fetchMonthlyPipelineStatuses = createSaga(function* fetchMonthlyPipelineStatuses({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, subtype, startDate, endDate, synergyType, categoryIds, frequency } = payload

  const query = { teamId, startDate, endDate, synergyType, frequency } as {
    teamId: string
    startDate: string
    endDate: string
    categoryId?: string
    subtype?: string
  }

  if (isArray(categoryIds) && categoryIds.length) {
    query.categoryId = map(JSON.stringify, categoryIds).toString()
  }

  if (synergyType === synergyTypes.REVENUE) {
    query.subtype = subtype
  }

  const api = yield getInitiativeListV2Api()
  const monthlyPipelines = yield call(
    api.request('getMonthByMonthStatus', {
      query,
    }),
  )

  yield put(setMonthlyPipelineStatus({ monthlyPipelines }))
},
SET_MONTHLY_PIPELINE_LOADING_STATE)

/* eslint-disable react/require-default-props */
import React from 'react'
import { StyledPopover, PopoverContent } from './index.styles'
import classnames from 'classnames'

interface Props {
  fill?: boolean
  suppressInteractions?: boolean
  portalClassName?: string
  className?: string
  children: React.ReactNode
  content?: React.ReactNode
  contentStyles?: $TSFixMe
  placement?: $TSFixMe
  interactionKind?: $TSFixMe
  popoverClassName?: string
  hoverOpenDelay?: number
  hoverCloseDelay?: number
  disabled?: boolean
  isOpen?: boolean
  wrapperTagName?: string
  targetTagName?: string
  boundary?: string
}

const CustomPopover = ({
  fill,
  className,
  content,
  contentStyles,
  portalClassName,
  suppressInteractions,
  children,
  ...tooltipProps
}: Props) => {
  return (
    // @ts-ignore `targetTagName` and `boundary` are not recognised as valid props but is needed in Data Request Tracker
    <StyledPopover
      className={className}
      fill={fill}
      content={<PopoverContent styles={contentStyles}>{content}</PopoverContent>}
      portalClassName={classnames(portalClassName, {
        'disable-interactions': suppressInteractions,
      })}
      {...tooltipProps}
    >
      {children}
    </StyledPopover>
  )
}

CustomPopover.defaultProps = {
  fill: false,
  suppressInteractions: false,
  portalClassName: null,
  contentStyles: {},
  className: null,
  content: null,
}

export default CustomPopover

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class DeliverableTypeApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
  }

  createDeliverableType = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: '/deliverable-type' },
      {
        method: 'POST',
        body,
      },
    )
  }

  bulkUpdateDeliverableType = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: '/deliverable-type/bulk' },
      {
        method: 'PUT',
        body,
      },
    )
  }

  getDeliverableType = () => {
    return this.call({ pathname: '/deliverable-type' })
  }

  getDeliverableTypeWithDeliverables = () => {
    return this.call({
      pathname: '/deliverable-type',
      query: {
        include: 'deliverables',
      },
    })
  }

  updateDeliverableType = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: '/deliverable-type' },
      {
        method: 'POST',
        body,
      },
    )
  }
}

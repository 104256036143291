import { isArray, map } from 'lodash/fp'
import { createSaga } from '@common/sagaCreator/createSaga'
import { call, put, select } from 'redux-saga/effects'
import { getInitiativeListV2Api } from '@common/net'
import { getIsDayOneMissed } from '@generic/selectors'
import { setOneTimeImplementationCosts } from '@teamValueCaptureV2/monthByMonth/actions/actions'
import { SET_ONE_TIME_IMPLEMENTATION_COSTS_LOADING_STATE } from '@teamValueCaptureV2/monthByMonth/actions/actionTypes'

export const fetchOneTimeImplementationCosts = createSaga(function* fetchOneTimeImplementationCosts({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  yield put(setOneTimeImplementationCosts({ oneTimeImplementationCosts: [] }))
  const { teamId, startDate, endDate, categoryIds } = payload

  const isDayOneMissed = yield select(getIsDayOneMissed)
  if (isDayOneMissed) {
    return
  }

  const query = { teamId, startDate, endDate } as {
    teamId: string
    startDate: string
    endDate: string
    categoryId?: string
  }

  if (isArray(categoryIds) && categoryIds.length) {
    query.categoryId = map(JSON.stringify, categoryIds).toString()
  }

  const api = yield getInitiativeListV2Api()
  const oneTimeImplementationCosts = yield call(
    api.request('getOneTimeImplementationCostsData', {
      query,
    }),
  )

  yield put(setOneTimeImplementationCosts({ oneTimeImplementationCosts: oneTimeImplementationCosts }))
},
SET_ONE_TIME_IMPLEMENTATION_COSTS_LOADING_STATE)

import { createSaga } from '@common/sagaCreator/createSaga'
import { call, put } from 'redux-saga/effects'
import { getInitiativeListV2Api } from '@common/net'
import { setDeltaTrackerData } from '@teamValueCaptureV2/monthByMonth/actions/actions'
import { SET_DELTA_TRACKER_DATA } from '@teamValueCaptureV2/monthByMonth/actions/actionTypes'

export const fetchDeltaTrackerData = createSaga(function* fetchDeltaTrackerData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, ...query } = payload

  const api = yield getInitiativeListV2Api()
  const response = yield call(
    api.request('fetchDeltaTrackerData', {
      query,
      body: { teamId },
    }),
  )

  yield put(setDeltaTrackerData(response))
},
SET_DELTA_TRACKER_DATA)

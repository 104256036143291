import { call } from 'redux-saga/effects'
import { getDayOneApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const deleteTaskFile = createSaga(function* deleteTaskFile(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, projectId, keyProcessId, id: taskId, fileId, onSuccess } = action.payload

  const api = yield getDayOneApi()
  yield call(api.request('deleteTaskFile', { query: { teamId, projectId, keyProcessId, taskId, fileId } }))

  yield onSuccess && call(onSuccess)
},
true)

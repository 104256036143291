import { call, put } from 'redux-saga/effects'
import { getDeliverablesApi } from '@common/net'
import { setTeamDeliverablesTrackerData } from '../actions/actions'

import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchTeamDeliverablesTracker = createSaga(function* fetchTeamDeliverablesTracker(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = action.payload

  const api = yield getDeliverablesApi()
  const deliverables = yield call(
    api.request('getTeamDeliverablesTracker', {
      query: { teamId },
    }),
  )

  yield put(setTeamDeliverablesTrackerData(deliverables))
})

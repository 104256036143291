import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchPipelineByIntegrationTeamData = createAction(
  constants.FETCH_PIPELINE_BY_INTEGRATION_TEAM_DATA,
  null,
  getMetaWithActionLoading,
)
export const setPipelineByIntegrationTeamData = createAction(constants.SET_PIPELINE_BY_INTEGRATION_TEAM_DATA)

export const fetchPipelineByYearOfExecutionData = createAction(
  constants.FETCH_PIPELINE_BY_YEAR_OF_EXECUTION_DATA,
  null,
  getMetaWithActionLoading,
)
export const setPipelineByYearOfExecutionData = createAction(constants.SET_PIPELINE_BY_YEAR_OF_EXECUTION_DATA)

import { call, put } from 'redux-saga/effects'
import { deleteRowsSuccess, fetchCommunicationsData } from '@communications/actions/actions'
import { getCommunicationsApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const deleteRows = createSaga(function* deleteRows({
  payload: rowIds,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getCommunicationsApi()
  try {
    yield call(api.request('deleteRows', { query: { rowIds } }))
    yield put(deleteRowsSuccess(rowIds))
  } catch (e) {
    yield put(fetchCommunicationsData())

    throw e
  }
},
true)

import { isArray, map } from 'lodash/fp'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getTeamResourcesApi } from '@common/net'
import {
  FETCH_DATA_PENDING,
  FETCH_DATA_INTERDEPENDENCY_POPUP,
  FETCH_INTERDEPENDENCY_SUMMARY,
  FETCH_INTERDEPENDENCY_SUMMARY_TOTALS,
  FETCH_INTERDEPENDENCY_FREQUENCY,
} from '../actions/actionTypes'
import {
  fetchDataSuccess,
  fetchDataFail,
  setInterdependencyPopupData,
  setInterdependencySummary,
  setInterdependencySummaryTotals,
  setInterdependencyFrequency,
} from '../actions'

export const fetchInterdependencyData = createSaga(function* fetchInterdependencyData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { priorityIds, requestStatusIds, taskStatusIds } = payload

  const query = {} as $TSFixMe

  if (isArray(priorityIds) && priorityIds.length) {
    query.priority = map(JSON.stringify, priorityIds).toString()
  }

  if (isArray(requestStatusIds) && requestStatusIds.length) {
    query.requestStatus = map(JSON.stringify, requestStatusIds).toString()
  }

  if (isArray(taskStatusIds) && taskStatusIds.length) {
    query.taskStatus = map(JSON.stringify, taskStatusIds).toString()
  }

  const api = yield getTeamResourcesApi()

  try {
    const { interdependencyMatrix, locked } = yield call(api.fetchMatrixData, { query })

    yield put(fetchDataSuccess({ data: interdependencyMatrix || [], locked }))
  } catch (e) {
    yield put(fetchDataFail())
  }
})

export const fetchInterdependencySummary = createSaga(function* fetchInterdependencySummary({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { type } = payload
  const api = yield getTeamResourcesApi()

  const data = yield call(api.request('getInterdependencySummary', { query: { type } }))

  yield put(setInterdependencySummary({ list: data }))
})

export const fetchInterdependencySummaryTotals = createSaga(function* fetchInterdependencySummaryTotals(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const api = yield getTeamResourcesApi()

  const data = yield call(api.request('getInterdependencySummary', { query: { type: 'totals-pie-chart' } }))

  yield put(setInterdependencySummaryTotals({ totals: data }))
})

export const fetchInterdependencyFrequency = createSaga(function* fetchInterdependencyFrequency({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { frequency } = payload
  const api = yield getTeamResourcesApi()

  const data = yield call(api.request('getInterdependencyFrequency', { query: { frequency } }))

  yield put(setInterdependencyFrequency({ frequency: data }))
})

export const fetchInterdependencyPopupData = createSaga(function* fetchInterdependencyPopupData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { sender, receiver, priorityIds, requestStatusIds, taskStatusIds } = payload
  const requestBody = {
    query: {
      teamId: sender,
      additionalParams: {},
    },
  } as $TSFixMe

  if (receiver) {
    requestBody.query.additionalParams.dependantTeams = receiver
  }

  if (isArray(priorityIds) && priorityIds.length) {
    requestBody.query.additionalParams.priority = map(JSON.stringify, priorityIds).toString()
  }

  if (isArray(requestStatusIds) && requestStatusIds.length) {
    requestBody.query.additionalParams.requestStatus = map(JSON.stringify, requestStatusIds).toString()
  }

  if (isArray(taskStatusIds) && taskStatusIds.length) {
    requestBody.query.additionalParams.taskStatus = map(JSON.stringify, taskStatusIds).toString()
  }

  const api = yield getTeamResourcesApi()
  const methodName = receiver === 'total' ? 'getTotalReceived' : 'getMatrixPopupData'
  const data = yield call(api.request(methodName, requestBody))

  yield put(setInterdependencyPopupData({ data }))
})

export default function* interdependenciesSaga() {
  yield all([
    takeLatest(FETCH_DATA_PENDING, fetchInterdependencyData),
    takeLatest(FETCH_DATA_INTERDEPENDENCY_POPUP, fetchInterdependencyPopupData),
    takeLatest(FETCH_INTERDEPENDENCY_SUMMARY, fetchInterdependencySummary),
    takeLatest(FETCH_INTERDEPENDENCY_SUMMARY_TOTALS, fetchInterdependencySummaryTotals),
    takeLatest(FETCH_INTERDEPENDENCY_FREQUENCY, fetchInterdependencyFrequency),
  ])
}

import { getFeatureFlags } from '@views/hooks/useFeatureFlags'

export const primaryExitCriteriaTagOptions = [
  { label: 'Not selected', value: null },
  { label: 'Organization/People/Hiring', value: 'Organization/People/Hiring' },
  { label: 'System separation/config', value: 'System separation/config' },
  { label: 'Vendor contract', value: 'Vendor contract' },
  { label: 'Customer contract', value: 'Customer contract' },
  { label: 'Onboarding/Knowledge transfer', value: 'Onboarding/Knowledge transfer' },
  { label: 'Legal/Regulatory', value: 'Legal/Regulatory' },
  { label: 'Other', value: 'Other' },
]

export const costAssumptionTagOptions = [
  { label: 'Not selected', value: null },
  { label: 'Fixed monthly charge', value: 'Fixed monthly charge' },
  { label: '3rd party cost pass-through', value: '3rd party cost pass-through' },
  { label: 'Ad hoc service', value: 'Ad hoc service' },
]

export const tsaActiveStatuses = [
  { label: 'Not selected', value: null },
  { label: 'Not yet started', value: 'Not yet started' },
  { label: 'Active', value: 'Active' },
  { label: 'Active - Change/Issue', value: 'Active - Change/Issue' },
  { label: 'Completed/Ended', value: 'Completed/Ended' },
]

export const exitExtensionRequestStatuses = [
  { label: 'Not selected', value: null },
  { label: 'No open request', value: 'No open request' },
  { label: 'Extension Requested', value: 'Extension Requested' },
  { label: 'Extension Approved/Confirmed', value: 'Extension Approved/Confirmed' },
  { label: 'Exit/Termination Notified', value: 'Exit/Termination Notified' },
]

export const getColumnTooltip = (columnName, managementType = 'IMO') => {
  switch (columnName) {
    case 'priorityTSA': {
      return `Use this column to pin a high priority TSA service or TSA service with issues for easy tracking by the ${managementType} and functional workstream`
    }
    case 'lockedAll': {
      return `Only the ${managementType} and TSA Central Team can lock and unlock each TSA phase. The  ${managementType} and TSA Central Team may lock or unlock based on the status of the negotiations and to manage version control throughout the program.`
    }
    default:
      return null
  }
}

export const TSAv2ColumnNames = {
  // general columns
  id: 'ID',
  isTsaPriority: 'Priority TSA',
  reviewStatus: 'Review status',
  keyProcess: 'Key process',
  isAllLocked: 'Locked all',
  nameOfService: 'Service name',

  // phase 1
  descriptionOfService: 'Service description',
  typeOfService: 'Service type',
  serviceGeography: 'Geography',
  serviceDuration: 'Service duration (months)',
  impliedServiceExitDate: 'Implied service exit date',
  primaryExitCriteriaTag: 'Primary exit criteria tag',
  tsaExitCriteria: 'TSA exit criteria',
  isPhase1Completed: 'Complete phase 1',
  isPhase1Locked: 'Lock phase 1',

  // phase 2
  costRecurrentMonthlyCharge: `Cost: recurring monthly charge (<$>)`,
  costNonRecurrentCharge: `Cost: non-recurring charge (<$>)`,
  costAssumptionTag: 'Cost assumption tag',
  costAssumptions: 'Cost assumptions',
  serviceLevel: 'Service level',
  isPhase2Completed: 'Complete phase 2',
  isPhase2Locked: 'Lock phase 2',

  // phase 3
  tsaContactRemainCOSeller: 'TSA service contact: RemainCo',
  tsaContactDivestCOBuyer: 'TSA service contact: DivestCo',
  legalEntityRemainCOSeller: 'Legal entity: RemainCo',
  legalEntityRemainCOSellerBuySideCarveOut: 'Legal entity: Seller',
  legalEntityDivestCOBuyer: 'Legal entity: DivestCo',
  isPhase3Completed: 'Complete phase 3',
  isPhase3Locked: 'Lock phase 3',

  // phase 4
  primaryLinkedProject: 'Primary linked project',
  linkedProjectDueDate: 'Linked project due date',
  linkedProjectStatus: 'Linked project status',
  otherLinkedProjects: 'Other linked projects',
  isPhase4Completed: 'Complete phase 4',
  isPhase4Locked: 'Lock phase 4',

  // phase 5
  tsaActiveStatus: 'TSA active status',
  exitExtensionRequestStatus: 'Exit and extension request status',
  originalContractualTSAEndDate: 'Original contractual TSA service exit date',
  forecastTSAExitDate: 'Forecasted TSA service exit date',
  revisedConstructionalExitExtensionDate: 'Revised contractual exit date',
  isPhase5Locked: 'Lock phase 5',
  comments: 'Comments',
}

/**
 * Override column names by feature flags
 *
 * @param {string} tenant
 * @returns {TSAv2ColumnNames}
 */
export function OverrideTSAv2ColumnNames() {
  const names = { ...TSAv2ColumnNames }
  const { AMZ_TSAV2_CUSTOM_COPY } = getFeatureFlags()

  if (AMZ_TSAV2_CUSTOM_COPY.enabled) {
    names.legalEntityRemainCOSeller = 'Providing geography'
    names.legalEntityDivestCOBuyer = 'Receiving geography'
    names.legalEntityRemainCOSellerBuySideCarveOut = 'Providing geography'
  }

  return names
}

export const TsaV2ActivityLogCustomCopy = {
  legalEntityDivestCOBuyer: 'the DivestCo legal entity',
  legalEntityDivestCOSellerIMO: 'the Seller legal entity',
  legalEntityDivestCOSellerSMO: 'the RemainCo legal entity',
}

/**
 * Override custom text copy for activity log by feature flags
 *
 * @returns {TsaV2ActivityLogCustomCopy}
 */
export function OverrideTSAv2ActivityLogCustomCopy() {
  const copy = { ...TsaV2ActivityLogCustomCopy }

  const { AMZ_TSAV2_CUSTOM_COPY } = getFeatureFlags()

  if (AMZ_TSAV2_CUSTOM_COPY.enabled) {
    copy.legalEntityDivestCOBuyer = 'receiving geography'
    copy.legalEntityDivestCOSellerSMO = 'providing geography'
    copy.legalEntityDivestCOSellerIMO = 'providing geography'
  }
  return copy
}

export const customColumnsFields = {
  reviewStatus: 'reviewStatus',
  typeOfService: 'typeOfService',
}

export const PHASES = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
}

export const TSA_PHASE_REQUIRED_FIELDS = {
  [PHASES.ONE]: [
    'descriptionOfService',
    'typeOfService',
    'serviceGeography',
    'serviceDuration',
    'primaryExitCriteriaTag',
  ],
  [PHASES.TWO]: ['costAssumptionTag', 'costNonRecurrentCharge', 'costRecurrentMonthlyCharge'],
  [PHASES.THREE]: ['tsaContactRemainCOSeller', 'tsaContactDivestCOBuyer'],
  [PHASES.FOUR]: ['primaryLinkedProject'],
}

export const TSA_PHASE_SCROLL_TO_COLUMNS = {
  [PHASES.ONE]: 'impliedServiceExitDate',
  [PHASES.TWO]: 'costRecurrentMonthlyCharge',
  [PHASES.THREE]: 'tsaContactRemainCOSeller',
  [PHASES.FOUR]: 'primaryLinkedProject',
  [PHASES.FIVE]: 'tsaActiveStatus',
}

export const completePhaseTooltip = 'To complete phase, please fill fields marked in red'
export const tsaEndDateMessage = 'Your TSA exit project due date is after the TSA end date'
export const forecastTsaEndDateMessage = 'Your Forecast TSA exit due date is after the TSA end date'
export const noRowsToShowDescription = 'There are currently no key processes here, click the button below to add.'
export const getLockColumnTooltip = (managementType) =>
  `Only the ${managementType} or TSA central team can change the locked column.`

export const FIELDS_WITH_DEPENDENCIES = [
  'serviceDuration',
  'teamKeyProcessV2Id',
  'keyProcess',
  'impliedServiceExitDate',
  'tsaId',
  'primaryLinkedProject',
]

export const getPhaseCompleteError = (phase) =>
  `Please confirm the values in the fields highlighted in red for Phase ${phase} before proceeding`

export const TSA_DELIVERABLE_KEY = 'TSA and/or rTSA'

export const TSA_VERSION = {
  ONE: 1,
  TWO: 2,
}

export const noTeamSelectedText =
  'Please select team to import key processes and sub-processes from Planning Assumptions deliverables'
export const noKeyProcessesText =
  'There are currently no key processes or sub-processes to import, please come back later'
export const NO_ACTIVE_TSA_DELIVERABLE_TEXT = 'There are currently no active TSA deliverables'

export const tsaIDs = {
  WATCHERS: 'watchers',
}

export const DUE_DATE_VALUE = 'Due date'

const prefix = `@generic`

export const FETCH_INITIAL_CLIENT_DATA = `${prefix}/FETCH_INITIAL_CLIENT_DATA`
export const FETCH_INITIAL_CLIENT_DATA_SUCCESS = `${prefix}/FETCH_INITIAL_CLIENT_DATA_SUCCESS`
export const SET_INITIAL_CLIENT_DATA_LOADED = `${prefix}/SET_INITIAL_CLIENT_DATA_LOADED`

export const FETCH_USER = `${prefix}/FETCH_USER`
export const SET_USER = `${prefix}/SET_USER`
export const SET_IS_FIRST_TIME_USER = `${prefix}/SET_IS_FIRST_TIME_USER`

export const FETCH_USERS_LIST = `${prefix}/FETCH_USERS_LIST`
export const SET_USERS_LIST = `${prefix}/SET_USERS_LIST`

export const FETCH_TEAMS_LIST = `${prefix}/FETCH_TEAMS_LIST`
export const SET_TEAMS_LIST = `${prefix}/SET_TEAMS_LIST`
export const SET_TEAM_DATA = `${prefix}/SET_TEAM_DATA`

export const SET_KNOWLEDGE_CENTER_VISIBILITY_STATUS = `${prefix}/SET_KNOWLEDGE_CENTER_VISIBILITY_STATUS`
export const SET_KNOWLEDGE_CENTER_VISIBILITY_STATUS_SUCCESS = `${prefix}/SET_KNOWLEDGE_CENTER_VISIBILITY_STATUS_SUCCESS`

export const SET_SELECTED_TEAM_IDS = `${prefix}/SET_SELECTED_TEAM_IDS`
export const SET_SELECTED_TEAM_ID = `${prefix}/SET_SELECTED_TEAM_ID`

export const FETCH_LIBRARIES = `${prefix}/FETCH_LIBRARIES`
export const SET_LIBRARIES = `${prefix}/SET_LIBRARIES`

export const FETCH_LIBRARIES_V2 = `${prefix}/FETCH_LIBRARIES_V2`
export const SET_LIBRARIES_V2 = `${prefix}/SET_LIBRARIES_V2`

// V2 linked libraries by team
export const FETCH_TEAM_LINKED_LIBRARIES = `${prefix}/FETCH_TEAM_LINKED_LIBRARIES`
export const FETCH_TEAM_LINKED_LIBRARIES_STATE = `${prefix}/FETCH_TEAM_LINKED_LIBRARIES_STATE`
export const SET_TEAM_LINKED_LIBRARIES = `${prefix}/SET_TEAM_LINKED_LIBRARIES`

// files management
export const DOWNLOAD_FILE = `${prefix}/DOWNLOAD_FILE`
export const DOWNLOAD_FILE_XLS = `${prefix}/DOWNLOAD_FILE_XLS`
export const UPLOAD_XLS_FILE = `${prefix}/UPLOAD_XLS_FILE`
export const SET_ABORT_CONTROLLER = `${prefix}/SET_ABORT_CONTROLLER`

export const UPLOAD_FILE = `${prefix}/UPLOAD_FILE`
export const DELETE_FILE = `${prefix}/DELETE_FILE`
export const CANCEL_UPLOADING_FETCH = `${prefix}/CANCEL_UPLOADING_FETCH`

export const SET_COMPANY_A_LOGO = `${prefix}/SET_COMPANY_A_LOGO`
export const SET_COMPANY_B_LOGO = `${prefix}/SET_COMPANY_B_LOGO`

export const FETCH_CONFIG = `${prefix}/FETCH_CONFIG`
export const SET_CONFIG = `${prefix}/SET_CONFIG`

export const SET_PERMISSIONS = `${prefix}/SET_PERMISSIONS`
export const SET_ROLE = `${prefix}/SET_ROLE`
export const SET_ROLES = `${prefix}/SET_ROLES`

export const SET_LOADER = `${prefix}/SET_LOADER`
export const SHOW_WARNING_MESSAGE = `${prefix}/SHOW_WARNING_MESSAGE`

export const GRID_CELL_UPDATE = `${prefix}/GRID_CELL_UPDATE`

export const FETCH_DICTIONARIES = `${prefix}/FETCH_DICTIONARIES`
export const SET_DICTIONARIES = `${prefix}/SET_DICTIONARIES`

export const FETCH_CONFIG_AND_USER = `${prefix}/FETCH_CONFIG_AND_USER`

export const FETCH_TEAM_DELIVERABLE_STATUS = `${prefix}/FETCH_TEAM_DELIVERABLE_STATUS`
export const SET_TEAM_DELIVERABLE_STATUS = `${prefix}/SET_TEAM_DELIVERABLE_STATUS`

export const SET_PDF_MODE = `${prefix}/SET_PDF_MODE`
export const SET_CACHED_LOGOS = `${prefix}/SET_CACHED_LOGOS`

export const GET_SNAPSHOT_MIN_DATE = `${prefix}/GET_SNAPSHOT_MIN_DATE`
export const SET_SNAPSHOT_MIN_DATE = `${prefix}/SET_SNAPSHOT_MIN_DATE`

export const SET_MIRRORING_INITIATIVE_VISIBILITY_STATUS = `${prefix}/SET_MIRRORING_INITIATIVE_VISIBILITY_STATUS`
export const SET_TOGGLES_STATUS_SUCCESS = `${prefix}/SET_TOGGLES_STATUS_SUCCESS`

export const SET_FTE_DASHBOARD_VISIBILITY_STATUS = `${prefix}/SET_FTE_DASHBOARD_VISIBILITY_STATUS`

export const SET_SHARED_TARGETS_VISIBILITY_STATUS = `${prefix}/SET_SHARED_TARGETS_VISIBILITY_STATUS`

export const SET_CONFIDENTIAL_FILTER = `${prefix}/SET_CONFIDENTIAL_FILTER`

export const FETCH_TOGGLES = `${prefix}/FETCH_TOGGLES`
export const FETCH_TOGGLES_RESET = `${prefix}/FETCH_TOGGLES_RESET`
export const FETCH_TOGGLES_SUCCESS = `${prefix}/FETCH_TOGGLES_SUCCESS`

export const SAVE_INITIATIVE_ARGS = `${prefix}/SAVE_INITIATIVE_ARGS`

export const SET_ACTION_STATE = `${prefix}/SET_ACTION_STATE`
export const CLEAR_ACTION_STATE = `${prefix}/CLEAR_ACTION_STATE`

export const UPDATE_WELCOME_POPUP_TEXT = `${prefix}/UPDATE_WELCOME_POPUP_TEXT`

export const LOGOUT = `${prefix}/LOGOUT`

export const SERVER_NOT_RESPONDING = `${prefix}/SERVER_NOT_RESPONDING`

export const TOGGLE_CHAT = `${prefix}/TOGGLE_CHAT`
export const TOGGLE_SUPPORT_BOT = `${prefix}/TOGGLE_SUPPORT_BOT`
export const TOGGLE_DOCS_BOT = `${prefix}/TOGGLE_DOCS_BOT`

export const REFRESH_TOKEN = `${prefix}/REFRESH_TOKEN`

export const REFRESH_TOKEN_SUCCESS = `${prefix}/REFRESH_TOKEN_SUCCESS`

export const SET_ROUTER_LOCATION = `${prefix}/SET_ROUTER_LOCATION`

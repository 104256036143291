const prefix = '@mutualDiscovery/'

export const FETCH_MUTUAL_DISCOVERY_DATA = `${prefix}FETCH_DATA`
export const SET_MUTUAL_DISCOVERY_DATA = `${prefix}SET_DATA`
export const CLEAR_MUTUAL_DISCOVERY_DATA = `${prefix}CLEAR_DATA`

export const SAVE_PROCESS = `${prefix}SAVE_PROCESS`
export const ADD_PROCESS = `${prefix}ADD_PROCESS`
export const UPDATE_PROCESS = `${prefix}UPDATE_PROCESS`
export const UPDATE_PROCESS_SUCCESS = `${prefix}UPDATE_PROCESS_SUCCESS`

export const DELETE_PROCESS = `${prefix}DELETE_PROCESS`
export const DELETE_PROCESS_SUCCESS = `${prefix}DELETE_PROCESS_SUCCESS`

export const DELETE_PROCESS_ITEMS = `${prefix}DELETE_PROCESS_ITEMS`
export const DELETE_PROCESS_ITEMS_SUCCESS = `${prefix}DELETE_PROCESS_ITEMS_SUCCESS`

export const FETCH_PROCESSES_FROM_LIBRARY = `${prefix}FETCH_PROCESSES_FROM_LIBRARY`
export const SET_PROCESSES_FROM_LIBRARY = `${prefix}SET_PROCESSES_FROM_LIBRARY`
export const RESET_PROCESSES_FROM_LIBRARY = `${prefix}RESET_PROCESSES_FROM_LIBRARY`

export const UPDATE_PROCESSES_FROM_LIBRARY = `${prefix}UPDATE_PROCESSES_FROM_LIBRARY`

export const ADD_PROCESSES_FROM_LIBRARY = `${prefix}ADD_PROCESSES_FROM_LIBRARY`

export const SET_VISIBLE_ROW_ID = `${prefix}SET_VISIBLE_ROW_ID`

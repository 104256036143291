import Config from '@root/src/config'
import { HttpStatusError } from '@root/src/helpers/customErrors'
import { HttpMethod, midfetch } from './fetch'
import { toUrlSearchParams } from './query-string'
import {
  Metadata,
  NotificationApiAction,
} from '@views/shared/DayOne/Aside/ActivityLogMessageFormatter/ActivityLogMessageFormatter'

export const DEFAULT_ACTIVITY_LOG_OFFSET = 0
export const DEFAULT_ACTIVITY_LOG_LIMIT = 10

type RequestOptions<TRequest = unknown> = {
  url: string
  headers?: Record<string, unknown>
  data?: TRequest
  query?: Record<string, unknown>
  signal?: AbortSignal
}

type HttpResponse<TResponse = unknown> = {
  status: number
  data?: TResponse
}

export function createApiClient({ baseUrl }: { baseUrl: string }) {
  const toUrl = (url: string, query?: Record<string, unknown>) => {
    const path = new URL(url, baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`)
    const queryString = query ? `?${toUrlSearchParams(query)}` : ''

    return `${path}${queryString}`
  }

  const request =
    (method: HttpMethod) =>
    async <TResponse = unknown, TRequest = unknown>(
      options: RequestOptions<TRequest>,
    ): Promise<HttpResponse<TResponse>> => {
      const { url, headers = {}, data, query, signal } = options

      const defaultHeaders = {
        'Content-Type': 'application/json',
      }

      let requestBody: BodyInit | null | undefined

      const isFormData = data instanceof FormData

      if (data) {
        if (isFormData) {
          requestBody = data
        } else {
          requestBody = JSON.stringify(data)
        }
      }

      const response = await midfetch(toUrl(url, query), {
        method,
        headers: isFormData ? headers : { ...defaultHeaders, ...headers },
        body: requestBody,
        signal,
      })

      const { status } = response
      const ok = status >= 200 && status < 300

      const responseBody = await response.text()
      let responseJson
      try {
        responseJson = JSON.parse(responseBody)
      } catch {
        responseJson = undefined
      }

      if (!ok) {
        throw new HttpStatusError({
          message: responseJson?.message ?? 'There was an API error response.',
          statusCode: status,
        })
      }

      return {
        status,
        data: status === 204 ? null : responseJson, // TODO: review 204 case, undefined could be used
      }
    }

  return {
    get<TResponse = unknown>(options: Omit<RequestOptions<TResponse>, 'data'>): Promise<HttpResponse<TResponse>> {
      return request('get')(options)
    },

    post: request('post'),
    put: request('put'),
    patch: request('PATCH'), // see: https://github.com/github/fetch/issues/37
    delete: request('delete'),
  }
}

// Usage:
// api.get({ url: 'foo/bar', query: { ... } })
// api.post({ url: 'foo/bar', data: { ... } })
export const api = createApiClient({ baseUrl: Config.API_SERVER })

export interface WatcherView {
  id: number
  user: {
    id: number
    name: string
    surname: string
    userRoles: {
      createdAt: string
      id: number
      roleId: number
      updatedAt: string
      userId: number
    }[]
    userTeamRoles: {
      id: number
      role: {
        name: string
      }
      team: {
        longName: string
        shortName: string
        teamType: {
          name: string
        }
      }
    }[]
  }
}

export interface WatcherPermissionView {
  canWatch: boolean
  canUnwatch: boolean
  watchingType?: 'owner' | 'teamMember'
}

interface GetProjectLog {
  teamId: number
  keyProcessId: number
  keyProcessProjectId: number
  limit?: number
  offset?: number
}

interface GetTaskLog {
  teamId: number
  keyProcessId: number
  keyProcessProjectId: number
  keyProcessTaskId: number
  limit?: number
  offset?: number
}

export interface LogItem {
  id: string
  action: NotificationApiAction
  user: Partial<{ id: number; name: string; surname: string }>
  field: string
  entityType: string
  metadata: Metadata
  createdAt: string
}

export function activityLogApiFacade(client: ReturnType<typeof createApiClient> = api) {
  return {
    getProjectLog({ keyProcessId, keyProcessProjectId, teamId, limit, offset }: GetProjectLog) {
      if (!limit || !offset) {
        limit = DEFAULT_ACTIVITY_LOG_LIMIT
        offset = DEFAULT_ACTIVITY_LOG_OFFSET
      }

      return client.get<{ total: number; logs: LogItem[] }>({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/activity-logs?limit=${limit}&offset=${offset}`,
      })
    },
    getTaskLog({ keyProcessId, keyProcessProjectId, keyProcessTaskId, teamId, limit, offset }: GetTaskLog) {
      if (!limit || !offset) {
        limit = DEFAULT_ACTIVITY_LOG_LIMIT
        offset = DEFAULT_ACTIVITY_LOG_OFFSET
      }

      return client.get<{ total: number; logs: LogItem[] }>({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/tasks/${keyProcessTaskId}/activity-logs?limit=${limit}&offset=${offset}`,
      })
    },
  }
}

interface GetProjectWatchers {
  teamId: number
  keyProcessId: number
  keyProcessProjectId: number
}

interface GetTaskWatchers {
  teamId: number
  keyProcessId: number
  keyProcessProjectId: number
  keyProcessTaskId: number
}

interface UnwatchProject {
  teamId: number
  keyProcessId: number
  keyProcessProjectId: number
}

interface UnwatchTask {
  teamId: number
  keyProcessId: number
  keyProcessProjectId: number
  keyProcessProjectTaskId: number
}

export function watchersApiFacade(client: ReturnType<typeof createApiClient> = api) {
  return {
    getProjectWatchers({ keyProcessId, keyProcessProjectId, teamId }: GetProjectWatchers) {
      return client.get<WatcherView[]>({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/watchers`,
      })
    },
    getTaskWatchers({ keyProcessId, keyProcessProjectId, keyProcessTaskId, teamId }: GetTaskWatchers) {
      return client.get<WatcherView[]>({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/tasks/${keyProcessTaskId}/watchers`,
      })
    },
    getProjectWatchPermissions({ keyProcessId, keyProcessProjectId, teamId }: GetProjectWatchers) {
      return client.get<WatcherPermissionView>({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/watchers/permissions`,
      })
    },
    getTaskWatchPermissions({ keyProcessId, keyProcessProjectId, teamId, keyProcessTaskId }: GetTaskWatchers) {
      return client.get<WatcherPermissionView>({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/tasks/${keyProcessTaskId}/watchers/permissions`,
      })
    },
    unwatchProject({ keyProcessId, keyProcessProjectId, teamId }: UnwatchProject) {
      return client.post({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/unwatch`,
      })
    },
    watchProject({ keyProcessId, keyProcessProjectId, teamId }: UnwatchProject) {
      return client.post({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/watch`,
      })
    },
    unwatchTask({ keyProcessId, keyProcessProjectId, teamId, keyProcessProjectTaskId }: UnwatchTask) {
      return client.post({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/tasks/${keyProcessProjectTaskId}/unwatch`,
      })
    },
    watchTask({ keyProcessId, keyProcessProjectId, keyProcessProjectTaskId, teamId }: UnwatchTask) {
      return client.post({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/tasks/${keyProcessProjectTaskId}/watch`,
      })
    },
  }
}

interface CreateMirror {
  teamId: number
  receivingTeamId: number
  keyProcessId: number
  keyProcessProjectId: number
}

interface DeleteMirrors {
  teamId: number
  keyProcessId: number
  keyProcessProjectId: number
}
interface DeleteMirror extends DeleteMirrors {
  receivingTeamId: number
}

interface GetMirrors {
  teamId: number
  keyProcessId: number
  keyProcessProjectId: number
}

export function mirrorApiFacade(client: ReturnType<typeof createApiClient> = api) {
  return {
    createMirror({ teamId, keyProcessId, keyProcessProjectId, receivingTeamId }: CreateMirror) {
      return client.post({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/mirror?receivingTeamId=${receivingTeamId}`,
      })
    },
    deleteMirrors({ teamId, keyProcessId, keyProcessProjectId }: DeleteMirrors) {
      return client.delete({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/mirror/all`,
      })
    },
    deleteMirror({ keyProcessId, keyProcessProjectId, receivingTeamId, teamId }: DeleteMirror) {
      return client.delete({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/mirror?receivingTeamId=${receivingTeamId}`,
      })
    },
    getMirrors({ teamId, keyProcessId, keyProcessProjectId }: GetMirrors) {
      return client.get<{ id: number; longName: string }[]>({
        url: `teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/mirrors`,
      })
    },
  }
}

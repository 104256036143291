import { AgGridColumnProps } from 'ag-grid-react'
import { processItemFields } from '../../../../common/accessController/strategies/deliverables/constants'
import { getFeatureFlags } from '../../../hooks/useFeatureFlags'
import { valueGetterWithInterdependency, valueSetterWithInterdependency } from '../../DayOne/utils/mapDayOneData'
import { ownerTextMatcher } from '@shared/Grid/utils/filterTextMatchers'
import { COLUMN_TYPE } from '../../Grid/constants'
import { CustomOwnerFilter, CustomOwnerFloatingFilter } from './CustomOwnerFilter'

const OWNER_FILTER_OLD: { forceExcelIterate?: boolean } & AgGridColumnProps = {
  headerName: 'Owner',
  field: 'owner',
  type: COLUMN_TYPE.owner,
  suppressSizeToFit: true,
  enableCellChangeFlash: false,
  width: 125,
  forceExcelIterate: true,
  valueGetter: valueGetterWithInterdependency(processItemFields.OWNER),
  valueSetter: valueSetterWithInterdependency(processItemFields.OWNER),
}

const OWNER_FILTER_NEW: { forceExcelIterate?: boolean } & AgGridColumnProps = {
  headerName: 'Owner',
  field: 'owner',
  type: COLUMN_TYPE.owner,
  suppressSizeToFit: true,
  enableCellChangeFlash: false,
  width: 125,
  forceExcelIterate: true,
  valueGetter: valueGetterWithInterdependency(processItemFields.OWNER),
  valueSetter: valueSetterWithInterdependency(processItemFields.OWNER),
  filter: 'agMultiColumnFilter',
  filterParams: {
    debounceMs: 0,
    suppressAndOrCondition: true,
    filters: [
      {
        filter: 'agTextColumnFilter',
        filterParams: {
          textMatcher: ownerTextMatcher,
        },
      },
      {
        filter: CustomOwnerFilter,
        floatingFilterComponent: CustomOwnerFloatingFilter,
      },
    ],
  },
}

export type CreateOwnerColumnDefOpts = {
  isEditableField: (...args: unknown[]) => unknown
}

export function createOwnerColumnDef({ isEditableField }: CreateOwnerColumnDefOpts) {
  const { TEAM_HOME_V2_LINKS } = getFeatureFlags()
  const isTeamHomeV2LinksActive = Boolean(TEAM_HOME_V2_LINKS?.enabled)
  if (isTeamHomeV2LinksActive) {
    return { ...OWNER_FILTER_NEW, editable: isEditableField }
  }
  return { ...OWNER_FILTER_OLD, editable: isEditableField }
}

import { all, call, put } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { getTeamResourcesApi } from '@common/net'
import { get, pick } from 'lodash'
import { createSaga } from '@common/sagaCreator/createSaga'
import { transformEmptyStringsToNull } from '@helpers/utils'
import {
  createMasterCalendarEventSuccess,
  deleteMasterCalendarEventSuccess,
  setMasterCalendarEvents,
  updateMasterCalendarEventSuccess,
} from '@teamResources/resourcesHome/actions/actions'
import {
  deleteMasterCalendarAttachment,
  uploadMasterCalendarAttachment,
} from '@teamResources/resourcesHome/sagas/masterCalendarAttachments'
import { eventFields } from '@teamResources/resourcesHome/selectors/constants'
import { UPLOAD_MC_ATTACHMENT, DELETE_MC_ATTACHMENT } from '@teamResources/resourcesHome/actions/actionTypes'

export const fetchMasterCalendarEvents = createSaga(function* fetchMasterCalendarEvents({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { labels } = payload

  const api = yield getTeamResourcesApi()
  const data = yield call(
    api.request('getCalendarEvents', {
      query: {
        labels,
      },
    }),
  )

  yield put(setMasterCalendarEvents(data))

  yield call(delay, 100)
})

export function* handleFilesChanges(
  eventId: $TSFixMe,
  { uploaded, deleted, attachmentsList }: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const resultDeleted = yield all(
    deleted.map(({ id }: $TSFixMe) =>
      call(deleteMasterCalendarAttachment, { eventId, fileId: id, type: DELETE_MC_ATTACHMENT }),
    ),
  )

  const resultUploaded = yield all(
    uploaded.map(({ file }: $TSFixMe) =>
      call(uploadMasterCalendarAttachment, { eventId, file, type: UPLOAD_MC_ATTACHMENT }),
    ),
  )

  const data = {
    attachments: [...attachmentsList],
    errors: {
      deleted: [],
      uploaded: [],
    },
  } as { attachments: $TSFixMe[]; errors: { deleted: $TSFixMe[]; uploaded: $TSFixMe[] } }

  resultUploaded.forEach((res: $TSFixMe, i: $TSFixMe) => {
    const result = get(uploaded, i) as $TSFixMe
    if (!res) {
      data.attachments = data.attachments.filter(({ id }) => id !== result.id)
      data.errors.uploaded.push(result)
    } else {
      data.attachments = data.attachments.map((item) => (item.id === result.id ? { ...item, ...res } : item))
    }
  })

  resultDeleted.forEach((res: $TSFixMe, i: number) => {
    const result = get(deleted, i)

    if (!res) {
      data.attachments.push(result)
      data.errors.deleted.push(result)
    }
  })

  return data
}

export const createMasterCalendarEvent = createSaga(function* createMasterCalendarEvent({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data, callback } = payload
  const transformedData = transformEmptyStringsToNull(data)

  const api = yield getTeamResourcesApi()
  const { id } = yield call(
    api.request('createCalendarEvent', {
      body: pick(transformedData, eventFields),
    }),
  )

  const uploadResult = yield call(handleFilesChanges, id, data.attachments)

  yield put(
    createMasterCalendarEventSuccess({
      data: {
        ...transformedData,
        id,
        attachments: get(uploadResult, ['attachments']),
        isNew: false,
      },
    }),
  )

  if (!!callback) yield call(callback)
})

export const updateMasterCalendarEvent = createSaga(function* updateMasterCalendarEvent({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { eventId, data, callback } = payload
  const transformedData = transformEmptyStringsToNull(data)

  const api = yield getTeamResourcesApi()
  yield call(
    api.request('updateCalendarEvent', {
      query: {
        eventId,
      },
      body: pick(transformedData, eventFields),
    }),
  )

  const uploadResult = yield call(handleFilesChanges, eventId, data.attachments)

  yield put(
    updateMasterCalendarEventSuccess({
      id: eventId,
      data: { ...transformedData, attachments: get(uploadResult, ['attachments']) },
    }),
  )

  if (!!callback) yield call(callback)
})

export const deleteMasterCalendarEvent = createSaga(function* deleteMasterCalendarEvent({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { eventId } = payload

  const api = yield getTeamResourcesApi()
  yield call(
    api.request('deleteCalendarEvent', {
      query: {
        eventId,
      },
    }),
  )

  yield put(deleteMasterCalendarEventSuccess({ id: eventId }))
})

const prefix = '@teamContacts/'

export const FETCH_TEAM_CONTACTS = `${prefix}FETCH_TEAM_CONTACTS`
export const SET_TEAM_CONTACTS = `${prefix}SET_TEAM_CONTACTS`

export const CREATE_TEAM_CONTACT = `${prefix}CREATE_TEAM_CONTACTS`
export const CREATE_TEAM_CONTACT_SUCCESS = `${prefix}CREATE_TEAM_CONTACTS_SUCCESS`

export const DELETE_TEAM_CONTACT = `${prefix}DELETE_TEAM_CONTACTS`
export const DELETE_TEAM_CONTACT_SUCCESS = `${prefix}DELETE_TEAM_CONTACTS_SUCCESS`

export const UPDATE_TEAM_CONTACT = `${prefix}UPDATE_TEAM_CONTACT`
export const UPDATE_TEAM_CONTACT_SUCCESS = `${prefix}UPDATE_TEAM_CONTACTS_SUCCESS`

import { actionTypes } from '../utils'

import * as configConstants from '@myImoConfigActions/config/actionTypes'
import * as programSetupConstants from '@myImoConfigActions/programSetup/actionTypes'
import * as deliverableFormConstants from '@myImoConfigActions/deliverables/actionTypes'
import * as taxonomyConstants from '@myImoConfigActions/projectTaxonomy/actionTypes'
import * as teamSetupConstants from '@myImoConfigActions/teamSetup/actionTypes'
import * as teamHierarchyConstants from '@myImoConfigActions/teamImoHierarchy/actionTypes'
import * as teamDeliverablesConstants from '@myImoConfigActions/teamDeliverables/actionTypes'
import * as usersConstants from '@myImoConfigActions/users/actionTypes'

//TODO: remove unused error codes (numbers)
export default {
  [configConstants.FETCH_COMPLETENESS_STATUSES]: {
    [actionTypes.FAIL]: ['We were unable to save this step of the configuration', 25],
  },
  [configConstants.RESET_ALL_STEPS]: {
    [actionTypes.FAIL]: ['We were unable to reset the status of all steps in the configuration', 24],
  },
  [configConstants.LAUNCH_CONFIG]: {
    [actionTypes.FAIL]: ['We were unable to launch the instance'],
  },
  //step 1
  [programSetupConstants.FETCH_PROGRAM_SETUP]: {
    [actionTypes.FAIL]: ['We were unable to load this step of the configuration', 28],
  },
  [programSetupConstants.CREATE_PROGRAM_SETUP]: {
    [actionTypes.FAIL]: ['We were unable to load this step of the configuration', 29],
  },
  //step 2
  [deliverableFormConstants.UPDATE_DELIVERABLE_TYPES]: {
    [actionTypes.FAIL]: ['We were unable to save this step of the configuration', 26],
  },
  [deliverableFormConstants.DELETE_DELIVERABLE]: {
    [actionTypes.INFO]: ['We were unable to create the custom deliverable {longName}'],
  },
  [deliverableFormConstants.CREATE_DELIVERABLE]: {
    [actionTypes.SUCCESS]: ['You have successfully created the deliverable {longName}'],
  },
  [deliverableFormConstants.UPDATE_DELIVERABLE]: {
    [actionTypes.SUCCESS]: ['You have successfully updated the deliverable {longName}'],
  },
  [deliverableFormConstants.GET_DELIVERABLE_TYPES]: {
    [actionTypes.FAIL]: ['We were unable to load this step of the configuration', 27],
  },
  [deliverableFormConstants.DELETE_DELIVERABLE]: {
    [actionTypes.FAIL]: ['We were unable to delete the deliverable {longName}'],
  },
  [deliverableFormConstants.CREATE_DELIVERABLE_FILES]: {
    [actionTypes.FAIL]: ['We were unable to upload the file attachments'],
  },
  //step 5
  [taxonomyConstants.CREATE_TAXONOMY]: {
    [actionTypes.FAIL]: ['We were unable to save this step of the configuration', 30],
  },
  [taxonomyConstants.GET_TAXONOMY]: {
    [actionTypes.FAIL]: ['We were unable to load this step of the configuration', 31],
  },
  //step 4
  [teamSetupConstants.SETUP_TEAMS]: {
    [actionTypes.FAIL]: ['We were unable to save this step of the configuration', 37],
  },
  [teamSetupConstants.GET_TEAMS]: {
    [actionTypes.FAIL]: ['We were unable to load this step of the configuration', 38],
  },
  //step 5
  [teamHierarchyConstants.CREATE_TEAM_HIERARCHY]: {
    [actionTypes.FAIL]: ['We were unable to save this step of the configuration', 36],
    [actionTypes.VALIDATION_FAIL]: ['All fields must be filled out before proceeding', 35],
  },
  [teamHierarchyConstants.GET_TEAM_DATA]: {
    [actionTypes.FAIL]: ['We were unable to load this step of the configuration', 34],
  },
  //step 6
  [teamDeliverablesConstants.GET_TEAM_DELIVERABLES]: {
    [actionTypes.FAIL]: ['We were unable to load this step of the configuration', 33],
  },
  [teamDeliverablesConstants.SAVE_TEAM_DELIVERABLES]: {
    [actionTypes.FAIL]: ['We were unable to save this step of the configuration', 32],
  },
  //step 7
  [usersConstants.GET_USERS]: {
    [actionTypes.FAIL]: ['We were unable to load this step of the configuration', 40],
  },
  [usersConstants.UPDATE_USERS]: {
    [actionTypes.FAIL]: ['We were unable to save this step of the configuration', 41],
  },
  [usersConstants.SEND_INVITE_EMAIL]: {
    [actionTypes.FAIL]: ['Something went wrong, please try again later'],
    [actionTypes.SUCCESS]: ['Activation email was sent successfully'],
  },
  [configConstants.DELETE_DICTIONARY_WORDING]: {
    [actionTypes.VALIDATION_FAIL]: [`{option} can't be deleted, please unlink the {option} {modulePhrase}`],
  },
  //step 9
  [configConstants.SAVE_WELCOME_NOTE]: {
    [actionTypes.FAIL]: ['We were unable to save this step of the configuration', 22],
  },
  [configConstants.GET_WELCOME_NOTE]: {
    [actionTypes.FAIL]: ['We were unable to load this step of the configuration', 23],
  },
}

const prefix = '@statusUpdate/'

export const FETCH_YEAR_5_PIPELINE_SUMMARY = `${prefix}FETCH_YEAR_5_PIPELINE_SUMMARY`
export const SET_YEAR_5_PIPELINE_SUMMARY = `${prefix}SET_YEAR_5_PIPELINE_SUMMARY`

export const FETCH_YEAR_IMPACT_CHART = `${prefix}FETCH_YEAR_IMPACT_CHART`
export const SET_YEAR_IMPACT_CHART = `${prefix}SET_YEAR_IMPACT_CHART`

export const FETCH_VALUE_CAPTURE_STATUS = `${prefix}FETCH_VALUE_CAPTURE_STATUS`
export const SET_STAGE_GATE_CALC_TYPE = `${prefix}SET_STAGE_GATE_CALC_TYPE`
export const UPDATE_VALUE_CAPTURE_STATUS = `${prefix}UPDATE_VALUE_CAPTURE_STATUS`
export const ADD_VALUE_CAPTURE_STATUS = `${prefix}ADD_VALUE_CAPTURE_STATUS`
export const REMOVE_VALUE_CAPTURE_STATUS = `${prefix}REMOVE_VALUE_CAPTURE_STATUS`

export const FETCH_INITIAL_ONE_TIME_COSTS = `${prefix}FETCH_INITIAL_ONE_TIME_COSTS`

export const FETCH_STATUS_UPDATE_ONE_TIME_COST = `${prefix}FETCH_STATUS_UPDATE_ONE_TIME_COST`
export const SET_STATUS_UPDATE_ONE_TIME_COST = `${prefix}SET_STATUS_UPDATE_ONE_TIME_COST`

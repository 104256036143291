import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class TSAExitPlansApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  createTSA = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/functional-list-draft/exit-plan`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateTSA = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/functional-list-draft/exit-plan/${query.processId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteTSA = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/functional-list-draft/exit-plan/${query.processId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }
}

import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'
import { ACTIVITY_LOG_PAGE_SIZE } from '@domain/myImoClient/common/constants'

export interface ITaskActivityLogState {
  [key: string]: $TSFixMe
}

type TaskActivityLogAction = {
  taskId: number
  replace?: boolean
  data: $TSFixMe[]
}

export const initialState = {}
export const taskActivityLogInitialState = { hasMore: true, items: [] }

const taskActivityLogReducer = handleActions<ITaskActivityLogState, TaskActivityLogAction>(
  {
    [constants.FETCH_TASK_ACTIVITY_LOG]: (state, action) => {
      const { taskId } = action.payload
      if (!state[taskId]) {
        // Init task id if not exists
        return { ...state, [taskId]: taskActivityLogInitialState }
      }

      return state
    },
    [constants.SET_TASK_ACTIVITY_LOG]: (state, action) => {
      const { taskId, data, replace } = action.payload
      const currentTaskState = state[taskId] ? state[taskId] : taskActivityLogInitialState

      // If it returns less than the page size no more items are available
      const hasMore = data.length === ACTIVITY_LOG_PAGE_SIZE

      const nextTaskProps: { hasMore: boolean; items: $TSFixMe[] } = { hasMore, items: [] }
      if (!replace) {
        nextTaskProps.items = [...currentTaskState.items]
      }

      nextTaskProps.items = [...nextTaskProps.items, ...data]

      return { ...state, [taskId]: nextTaskProps }
    },
  },
  initialState,
)

export default taskActivityLogReducer

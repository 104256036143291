import {
  filter,
  find,
  findKey,
  first,
  get,
  hasIn,
  isNil,
  lowerCase,
  matches,
  orderBy,
  set,
  times,
  uniqBy,
  values,
} from 'lodash'
import {
  INTERDEPENDENCY_PROJECT_LIST_ID,
  POSITIONS,
  listItemTypes,
  processItemHierarchy,
  MIRRORED_PROJECT_PROCESS_LIST_ID,
} from '../constants'
import { getUserDisplayName } from '@shared/Lookup/utils'
import { combineGridValidators, normalizeStringValue } from '@helpers/gridValidations'
import { processItemFields } from '@common/accessController/strategies/deliverables/constants'
import { getValueHierarchy } from '@helpers/utils'
import { listIdFormatValidation } from '@helpers/validators'
import { capitalize, cloneDeep, pick } from 'lodash/fp'
import { DayOneProjectListDto, TeamKeyProcessProjectOrTask } from '@common/types/dtos/DayOneProjectListDto'
import { InterdependencyLink, ProjectMirror } from '@common/types/dtos/KeyProcessProjectTaskBaseDto'
import { getParentMap, getParentNode } from './projectListHierarchy'

export const valueGetterWithInterdependency =
  (field: string) =>
  ({ data }: { data?: TeamKeyProcessProjectOrTask }) => {
    return data && data.isIncoming && data.interdependency ? get(data, ['interdependency', field]) : get(data, field)
  }

export const valueSetterWithInterdependency =
  (field: $TSFixMe) =>
  ({ data, newValue }: $TSFixMe) => {
    const dataPath = data.isIncoming && data.interdependency ? ['interdependency', field] : field

    set(data, dataPath, newValue)

    return true
  }

export type getRowNodeIdProcessItemProps =
  | TeamKeyProcessProjectOrTask
  | {
      id: number
      teamId: number
      type: string
      isIncoming?: boolean
      interdependency?: InterdependencyLink
      mirror?: ProjectMirror
    }

export const getRowNodeIdProcessItem = (data: getRowNodeIdProcessItemProps & { rowId?: string }) => {
  const { isIncoming, interdependency, teamId, id, type, mirror, rowId } = data || {}

  if (rowId) {
    return rowId
  }

  const isIncomingInterdependency = isIncoming && interdependency
  const rowIdPrefix = mirror ? `mirroredProjects_${teamId}` : teamId
  return `${rowIdPrefix}_${id}_${isIncomingInterdependency ? listItemTypes.INTERDEPENDENCY : type}`
}

export const getDataWithHierarchy = (projectPlanList: DayOneProjectListDto) => {
  const parentMap = getParentMap(projectPlanList)

  return projectPlanList.map((item: TeamKeyProcessProjectOrTask) => {
    const projectItem = cloneDeep(item)

    if (projectItem.type === 'project') {
      const parentItem = getParentNode(projectItem, parentMap)

      return parentItem
        ? {
            ...projectItem,
            hierarchy: [getRowNodeIdProcessItem(parentItem), getRowNodeIdProcessItem(projectItem)],
          }
        : {
            ...projectItem,
            hierarchy: [getRowNodeIdProcessItem(projectItem)],
          }
    }

    if (projectItem.type === 'task') {
      const parentItem = getParentNode(projectItem, parentMap)

      const keyProcessItem = getParentNode(parentItem as TeamKeyProcessProjectOrTask, parentMap)

      return parentItem && keyProcessItem
        ? {
            ...projectItem,
            hierarchy: [
              getRowNodeIdProcessItem(keyProcessItem),
              getRowNodeIdProcessItem(parentItem),
              getRowNodeIdProcessItem(projectItem),
            ],
          }
        : {
            ...projectItem,
            hierarchy: [getRowNodeIdProcessItem(projectItem)],
          }
    }

    return {
      ...projectItem,
      hierarchy: [getRowNodeIdProcessItem(projectItem)],
    }
  })
}

export const sortByIdWithInterdependency = (i: $TSFixMe, process: $TSFixMe) => {
  const id = valueGetterWithInterdependency('projectListId')({
    data: process,
  })

  return isNil(id) ? POSITIONS.MIDDLE : parseInt(get(id.split('.'), i, 0))
}

export const getSortingByListIdHierarchyLevels = (numberOfLevels: $TSFixMe) =>
  times(numberOfLevels, (i) => (process: $TSFixMe) => sortByIdWithInterdependency(i, process))

const sortingColumns = ['primaryIMO', 'longTeamName']

export const getSortingIndexByProcess = (process: $TSFixMe) => {
  if (process.isIncoming) return POSITIONS.END
  if (process.mirror) return POSITIONS.ENDv2 // :')

  return isNil(process.projectListId) ? POSITIONS.MIDDLE : POSITIONS.START
}

export const mapDayOneData = (rowData: DayOneProjectListDto): DayOneProjectListDto => {
  const sortingOptions = [
    getSortingIndexByProcess,
    ...sortingColumns,
    ...getSortingByListIdHierarchyLevels(processItemHierarchy[listItemTypes.TASK]),
  ]

  return orderBy(getDataWithHierarchy(uniqBy(rowData, getRowNodeIdProcessItem)), sortingOptions)
}

const defaultModel = {
  selectedProcess: {},
  ancestors: [],
  followers: [],
  ancestor: {},
  predecessors: [],
  selectedFollower: {},
  selectedPredecessor: {},
}

export const getSelectedTaskAncestors = ({ projectId }: $TSFixMe, allProcesses: $TSFixMe) => {
  const allProjects = filter(allProcesses, (listItem) => listItem.type === 'project')
  const ancestor = find(allProjects, (item) => item.id === projectId)
  const availableToChooseAncestors = filter(allProjects, (project) => !project.isIncoming)

  return { ancestor, ancestors: availableToChooseAncestors }
}

export const getSelectedProjectAncestors = ({ keyProcessId, isIncoming }: $TSFixMe, allProcesses: $TSFixMe) => {
  const ancestors = filter(
    allProcesses,
    (process) => process.type === 'keyProcess' && process.isIncoming === isIncoming,
  )

  const ancestor = find(ancestors, (item) => item.id === keyProcessId)

  return { ancestor, ancestors }
}

export const getSelectedProcessSiblingsData = (
  { taskId, type, followerId, predecessorId, projectListId }: $TSFixMe,
  allProcesses: $TSFixMe,
) => {
  const siblings = filter(allProcesses, (process) => {
    const isInterdependency = !isNil(process.interdependency)

    if (process.mirror) {
      return false
    }

    return isInterdependency
      ? process.type === type && process.taskId !== taskId
      : process.type === type && process.projectListId !== projectListId
  })

  const selectedFollower = find(siblings, { id: followerId }) || {}

  const selectedPredecessor = find(siblings, { id: predecessorId }) || {}

  const checkIsNotRelated = ({ id }: $TSFixMe) => ![followerId, predecessorId].includes(id)

  return {
    predecessors: siblings.filter(checkIsNotRelated),
    followers: siblings.filter(checkIsNotRelated),
    selectedFollower,
    selectedPredecessor,
  }
}

export const getSelectedProcessData =
  (getRowNodeId: $TSFixMe, withTeamId: $TSFixMe) => (items: $TSFixMe, selectedProcessId: $TSFixMe) => {
    const selectedProcess = items.find((item: $TSFixMe) => getRowNodeId(item) === selectedProcessId)

    if (!selectedProcess) return defaultModel

    const list = withTeamId && !selectedProcess.mirror ? filter(items, { teamId: selectedProcess.teamId }) : items

    switch (selectedProcess.type) {
      case listItemTypes.PROJECT:
        return {
          selectedProcess,
          ...getSelectedProjectAncestors(selectedProcess, list),
          ...getSelectedProcessSiblingsData(selectedProcess, list),
          rowsData: items,
        }
      default:
        return {
          selectedProcess,
          ...getSelectedTaskAncestors(selectedProcess, list),
          ...getSelectedProcessSiblingsData(selectedProcess, list),
          rowsData: items,
        }
    }
  }

export const getInterdependencyRowId = (process: $TSFixMe) => {
  if (process.isIncoming && process.interdependency) {
    return getRowNodeIdProcessItem({
      teamId: get(process, ['interdependency', 'senderTeam', 'id']),
      id: process.id,
      type: listItemTypes.TASK,
    })
  }

  if (process.isOutgoing && process.interdependency)
    return getRowNodeIdProcessItem({
      teamId: get(process, ['interdependency', 'receiverTeam', 'id']),
      id: process.id,
      type: listItemTypes.INTERDEPENDENCY,
    })

  return null
}

export const formatUsers = (context: $TSFixMe, users: $TSFixMe) =>
  users.map(
    (user: $TSFixMe) =>
      user.displayName ||
      first(
        values(context.users)
          .filter((u) => u.id === user.id)
          .map((u) => getUserDisplayName(u)),
      ),
  )

export const getOtherTeamMembers = ({ context, data = {} }: $TSFixMe) => {
  const teamMembers =
    data.isIncoming && data.interdependency ? get(data, ['interdependency', 'teamMembers']) : get(data, 'teamMembers')

  if (!teamMembers) return ''

  return formatUsers(context, teamMembers)
}

export const getReceiverProjectOwner = ({ data }: $TSFixMe) => {
  const owner = get(data, 'interdependency.taskL2.L2Projects[0].owner', null)

  return owner?.displayName || ''
}

export const getTeamMembers = ({ context, data }: $TSFixMe) => {
  const teamMembers = get(data, 'teamMembers', null)

  if (!teamMembers) return ''

  return formatUsers(context, teamMembers)
}

export const checkValueHierarchyPresence = ({ items, valueHierarchy, teamId, field }: $TSFixMe) =>
  items.reduce((result: $TSFixMe, item: $TSFixMe) => {
    const itemId = valueGetterWithInterdependency(field)({ data: item })
    const idIndex = valueHierarchy?.findIndex((id: $TSFixMe) => id === itemId)

    if (teamId === item.teamId && idIndex > -1) {
      result[idIndex] = item
    }

    return result
  }, {})

export const checkValueNameUniqueness = ({ items, valueHierarchy, field }: $TSFixMe) =>
  items.some((result: $TSFixMe, item: $TSFixMe) => {
    const itemId = valueGetterWithInterdependency(field)({ data: item })
    const idIndex = valueHierarchy?.findIndex((id: $TSFixMe) => id === itemId)

    if (idIndex > -1) result[idIndex] = item

    return result
  }, {})

export const matchSiblingWithTheSameName = ({ data, rowNodeId, parent, isCaseSensitive = false }: $TSFixMe) => {
  const hierarchyObject = parent
    ? {
        ...pick(['keyProcessId', 'projectId'], parent),
        [`${parent?.type}Id`]: parent?.id,
      }
    : {}

  const normalizeValue = normalizeStringValue(isCaseSensitive)

  const siblingsMather = matches(hierarchyObject)

  return (item: $TSFixMe) => {
    const isSibling = item.type === data.type && siblingsMather(item)

    if (!isSibling) return false

    if (getRowNodeIdProcessItem(item) === rowNodeId) return false

    return normalizeValue(item?.name) === normalizeValue(data?.name)
  }
}

export const listIdAvailabilityValidation =
  ({ hierarchyLevel, itemType, parentType, items, field }: $TSFixMe) =>
  (value: $TSFixMe, data: $TSFixMe, { node }: $TSFixMe) => {
    if (!value) {
      return { valid: false, message: `ID field cannot be empty` }
    }

    const { id: rowNodeId } = node

    const validation = listIdFormatValidation(hierarchyLevel, itemType)(value)

    if (!validation.valid) return validation

    const valueHierarchy = getValueHierarchy(value)

    //Check that process is not interdependency with id 99
    if (
      first(valueHierarchy) === INTERDEPENDENCY_PROJECT_LIST_ID ||
      first(valueHierarchy) === MIRRORED_PROJECT_PROCESS_LIST_ID.toString()
    ) {
      validation.valid = false
      validation.message = `ID ${first(valueHierarchy)} is reserved, please choose another ID`

      return validation
    }

    const presenceCheckingResult = checkValueHierarchyPresence({ items, valueHierarchy, field, teamId: data.teamId })

    //Check duplicate
    if (presenceCheckingResult[hierarchyLevel - 1]) {
      validation.valid = false
      validation.message = `ID ${value} already exists, please choose another ID`

      return validation
    }

    //Check parent presence
    const parentHierarchyLevel = hierarchyLevel - 2
    const isChild = hasIn(valueHierarchy, parentHierarchyLevel)
    const parent = presenceCheckingResult[parentHierarchyLevel]

    if (isChild && !parent) {
      validation.valid = false
      validation.message = `ID ${
        valueHierarchy[parentHierarchyLevel]
      } does not exist, please choose another ${lowerCase(parentType)} ID`

      return validation
    }

    const isSiblingMatchesWithName = matchSiblingWithTheSameName({ data, rowNodeId, parent, isCaseSensitive: false })

    const isSiblingsHasSameName = items.some(isSiblingMatchesWithName)

    if (isSiblingsHasSameName) {
      validation.valid = false
      validation.message = capitalize(`${lowerCase(data?.type)} ${data?.name?.trim()} already exists`)
    }

    return validation
  }

export const listIdValueSetter = (params: $TSFixMe) => {
  const {
    data: { type, teamId },
    context,
  } = params

  const hierarchyLevel = processItemHierarchy[type]
  const parentType = findKey(processItemHierarchy, (v) => v === hierarchyLevel - 1)
  const items = context.dayOneData.filter((item: $TSFixMe) => item.teamId === teamId)

  return combineGridValidators(params, processItemFields.PROJECT_LIST_ID, [
    listIdAvailabilityValidation({
      hierarchyLevel,
      itemType: type,
      parentType,
      items,
      field: processItemFields.PROJECT_LIST_ID,
    }),
  ])
}

export const getInterdependencyOwnerPath = (item: $TSFixMe) =>
  item.isIncoming
    ? ['interdependency', 'taskL2', 'L2Projects', 0, 'owner']
    : ['interdependency', 'taskL2', 'senderProjectOwner']

export const getLinkedInitiative = (rowData: $TSFixMe, { dayOneData }: $TSFixMe) => {
  if (rowData.hierarchy.length === 3) {
    const project = dayOneData.find((row: $TSFixMe) => {
      const isProject = row.hierarchy.length === 2
      const treeDataMatch = row.hierarchy[0] === rowData.hierarchy[0] && row.hierarchy[1] === rowData.hierarchy[1]

      return isProject && treeDataMatch
    })

    return project.linkedInitiative
  }

  return rowData.linkedInitiative
}

import React, { memo, useMemo } from 'react'
import { Alignment, PopoverInteractionKind, Switch } from '@blueprintjs/core'
import { LabelSpanFlex, ListItem, StyledList, StyledPopover } from './index.styles'
import { useDispatch, useSelector } from 'react-redux'
import { getToggleSettings } from '@generic/selectors'
import PropTypes from 'prop-types'
import { getAdvancedFinancialsModule } from '@domain/instanceConfig/selectors'
import { Placement } from '@shared/Popover'
import {
  actionList,
  getConfidentialFilterOption,
  VCControllerIDs,
} from '@myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/TeamValueCaptureController/utils'
import { setConfidentialFilter } from '@generic/actions/actions'
import { setIsAdvancedFinancialsOpened } from '@teamValueCaptureV2/advancedFinancials/actions/actions'

const VCControllerMenu = ({ teamId, isVCTracker, interactionKind, children }) => {
  const dispatch = useDispatch()
  const toggleSettings = useSelector(getToggleSettings)
  const confidentialFilter = getConfidentialFilterOption(toggleSettings[VCControllerIDs.CONFIDENTIAL])
  const advancedFinancialsModule = useSelector(getAdvancedFinancialsModule)

  const isActive = (id) => toggleSettings[id]

  const onChange =
    ({ action, id }) =>
    ({ target }) => {
      dispatch(action({ status: target?.checked, teamId, item: id }))
    }

  const handleChangeConfidentialFilter = ({ value }) => {
    dispatch(setConfidentialFilter({ item: VCControllerIDs.CONFIDENTIAL, value, teamId }))
    dispatch(setIsAdvancedFinancialsOpened(false))
  }

  const popoverContent = useMemo(() => {
    return (
      <StyledList>
        {actionList({
          isVCTracker,
          isAdvancedFinancialsTurnedOn: advancedFinancialsModule.active,
          handleChangeConfidentialFilter,
          confidentialFilter,
        }).map((item) => {
          if (item.hide) return null

          return (
            <ListItem key={item.id}>
              <LabelSpanFlex>
                {item.icon}
                <span className="item-text">{item.label}</span>
              </LabelSpanFlex>
              {item.customHandler || (
                <Switch
                  checked={isActive(item.id)}
                  onChange={onChange({ action: item.action, id: item.id })}
                  alignIndicator={Alignment.RIGHT}
                />
              )}
            </ListItem>
          )
        })}
      </StyledList>
    )
  }, [advancedFinancialsModule.active, toggleSettings, confidentialFilter])

  return (
    <StyledPopover
      content={popoverContent}
      placement={Placement.BOTTOM_START}
      targetTagName={'div'}
      interactionKind={interactionKind}
    >
      {children}
    </StyledPopover>
  )
}

VCControllerMenu.propTypes = {
  teamId: PropTypes.number.isRequired,
  isVCTracker: PropTypes.bool,
  interactionKind: PropTypes.string,
  children: PropTypes.node.isRequired,
}

VCControllerMenu.defaultProps = {
  isVCTracker: false,
  interactionKind: PopoverInteractionKind.HOVER,
}

export default memo(VCControllerMenu)

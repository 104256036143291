import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class InitiativeWatchersApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  watchInitiative = ({ query }: $TSFixMe) => {
    const { teamId, initiativeId } = query

    return this.call(
      { pathname: `/teams/${teamId}/value-capture/v2/initiatives-v2/${initiativeId}/watch` },
      { method: 'POST' },
    )
  }

  unwatchInitiative = ({ query }: $TSFixMe) => {
    const { teamId, initiativeId } = query

    return this.call(
      {
        pathname: `/teams/${teamId}/value-capture/v2/initiatives-v2/${initiativeId}/unwatch`,
      },
      { method: 'POST' },
    )
  }

  fetchInitiativeWatchers = ({ query }: $TSFixMe) => {
    const { teamId, initiativeId } = query

    return this.call({
      pathname: `/teams/${teamId}/value-capture/v2/initiatives-v2/${initiativeId}/watchers`,
    })
  }
}

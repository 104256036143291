import { createAction } from 'redux-actions'
import {
  ADD_RISK_LOG_DATA,
  ADD_RISK_LOG_DATA_PENDING,
  ADD_RISK_LOG_DATA_SUCCESS,
  DELETE_RISK_LOG_DATA_FAIL,
  DELETE_RISK_LOG_DATA_PENDING,
  DELETE_RISK_LOG_DATA_SUCCESS,
  FETCH_CONTROL_TOWER_RISK_LOG_DATA,
  FETCH_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS,
  FETCH_RISK_LOG_DATA_FAIL,
  FETCH_RISK_LOG_DATA_PENDING,
  FETCH_RISK_LOG_DATA_SUCCESS,
  FETCH_TR_RISK_LOG_DATA,
  UPDATE_CONTROL_TOWER_RISK_LOG_DATA,
  UPDATE_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS,
  UPDATE_RISK_LOG_DATA_FAIL,
  UPDATE_RISK_LOG_DATA_PENDING,
  UPDATE_RISK_LOG_DATA_SUCCESS,
  UPDATE_TR_RISK_LOG_DATA,
} from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchTrRiskLogData = createAction(FETCH_TR_RISK_LOG_DATA, null, getMetaWithActionLoading)
export const updateTrRiskLogData = createAction(UPDATE_TR_RISK_LOG_DATA)

export const fetchRiskLogDataPending = createAction(FETCH_RISK_LOG_DATA_PENDING, null, getMetaWithActionLoading)
export const fetchRiskLogDataSuccess = createAction(FETCH_RISK_LOG_DATA_SUCCESS)
export const fetchRiskLogDataFail = createAction(FETCH_RISK_LOG_DATA_FAIL)

export const updateRiskLogDataPending = createAction(UPDATE_RISK_LOG_DATA_PENDING)
export const updateRiskLogDataSuccess = createAction(UPDATE_RISK_LOG_DATA_SUCCESS)
export const updateRiskLogDataFail = createAction(UPDATE_RISK_LOG_DATA_FAIL)

export const addRiskLogDataPending = createAction(ADD_RISK_LOG_DATA_PENDING)
export const addRiskLogData = createAction(ADD_RISK_LOG_DATA)
export const addRiskLogDataSuccess = createAction(ADD_RISK_LOG_DATA_SUCCESS)

export const deleteRiskLogDataPending = createAction(DELETE_RISK_LOG_DATA_PENDING)
export const deleteRiskLogDataSuccess = createAction(DELETE_RISK_LOG_DATA_SUCCESS)
export const deleteRiskLogDataFail = createAction(DELETE_RISK_LOG_DATA_FAIL)

export const fetchControlTowerRiskLogData = createAction(
  FETCH_CONTROL_TOWER_RISK_LOG_DATA,
  null,
  getMetaWithActionLoading,
)
export const updateControlTowerRiskLogData = createAction(UPDATE_CONTROL_TOWER_RISK_LOG_DATA)

export const fetchControlTowerRiskLogDataSuccess = createAction(FETCH_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS)

export const updateControlTowerRiskLogDataSuccess = createAction(UPDATE_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS)

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class TeamCharterApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch, '')

    this.host = Config.API_SERVER
  }

  getTeamCharterData = ({ query: { teamId, date } }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${teamId}/team-charter`,
      query: { include: 'leads', ...(date ? { historyDate: date } : {}) },
    })
  }

  updateTeamCharterData = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/team-charter/` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  getInScopeKeyProcesses = ({ query: { teamId, date } }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${teamId}/team-key-process-v2`,
      query: {
        source: 'TeamCharter',
        ...(date ? { historyDate: date } : {}),
      },
    })
  }

  createInScopeKeyProcess = ({ query, body }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/team-key-process-v2` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateInScopeKeyProcess = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/team-key-process-v2/${query.id}?source=TeamCharter` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteInScopeKeyProcess = ({ query }: $TSFixMe) => {
    const { teamId, id } = query

    return this.call(
      {
        pathname: `teams/${teamId}/team-key-process-v2/${id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  getOutScopeKeyProcesses = ({ query: { teamId, date } }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${teamId}/key-processes-outscope`,
      query: {
        ...(date ? { historyDate: date } : {}),
      },
    })
  }

  createOutScopeKeyProcess = ({ query, body }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/key-processes-outscope` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateOutScopeKeyProcess = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/key-processes-outscope/${query.id}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteOutScopeKeyProcess = ({ query }: $TSFixMe) => {
    const { id, teamId } = query

    return this.call(
      {
        pathname: `teams/${teamId}/key-processes-outscope/${id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  getMacroPlanningAssumption = ({ query: { teamId, date } }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${teamId}/team-charter-macro-planning-assumption`,
      query: {
        ...(date ? { historyDate: date } : {}),
      },
    })
  }

  createMacroPlanningAssumption = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/team-charter-macro-planning-assumption`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateMacroPlanningAssumption = ({ query, body }: $TSFixMe) => {
    const { id, teamId } = query

    return this.call(
      {
        pathname: `teams/${teamId}/team-charter-macro-planning-assumption/${id}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteMacroPlanningAssumption = ({ query, body }: $TSFixMe) => {
    const { id } = body

    return this.call(
      {
        pathname: `teams/${query.teamId}/team-charter-macro-planning-assumption/${id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  getKeyInterdependencies = ({ query: { teamId, date } }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${teamId}/team-charter-key-interdependency`,
      query: {
        ...(date ? { historyDate: date } : {}),
      },
    })
  }

  createKeyInterdependency = ({ query, body }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/team-charter-key-interdependency` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateKeyInterdependency = ({ body, query }: $TSFixMe) => {
    const { id, teamId } = query

    return this.call(
      { pathname: `teams/${teamId}/team-charter-key-interdependency/${id}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteKeyInterdependency = ({ query }: $TSFixMe) => {
    const { id, teamId } = query

    return this.call(
      {
        pathname: `teams/${teamId}/team-charter-key-interdependency/${id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  getKeyRiskToMitigate = ({ query: { teamId, date } }: $TSFixMe) =>
    this.call({
      pathname: `teams/${teamId}/team-charter-key-risk-to-mitigate`,
      query: {
        ...(date ? { historyDate: date } : {}),
      },
    })

  createKeyRiskToMitigate = ({ query, body }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/team-charter-key-risk-to-mitigate` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateKeyRiskToMitigate = ({ body, query }: $TSFixMe) => {
    const { teamId, id } = query

    return this.call(
      { pathname: `teams/${teamId}/team-charter-key-risk-to-mitigate/${id}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteKeyRiskToMitigate = ({ query }: $TSFixMe) => {
    const { teamId, id } = query

    return this.call(
      {
        pathname: `teams/${teamId}/team-charter-key-risk-to-mitigate/${id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  getKeyDecisionToMake = ({ query: { teamId, date } }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${teamId}/team-charter-key-decision-to-make`,
      query: {
        ...(date ? { historyDate: date } : {}),
      },
    })
  }

  createKeyDecisionToMake = ({ query, body }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/team-charter-key-decision-to-make` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateKeyDecisionToMake = ({ body, query }: $TSFixMe) => {
    const { teamId, id } = query

    return this.call(
      { pathname: `teams/${teamId}/team-charter-key-decision-to-make/${id}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteKeyDecisionToMake = ({ query }: $TSFixMe) => {
    const { teamId, id } = query

    return this.call(
      {
        pathname: `teams/${teamId}/team-charter-key-decision-to-make/${id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  getTeamMembers = ({ query: { teamId, date } }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${teamId}/team-charter/members`,
      query: {
        ...(date ? { historyDate: date } : {}),
      },
    })
  }

  deleteTeamMember = ({ query }: $TSFixMe) => {
    const { teamId, id } = query

    return this.call(
      {
        pathname: `teams/${teamId}/team-charter/members/${id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  saveTeamMembers = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-charter/members`,
      },
      { method: 'POST', body },
    )
  }

  updateTeamMembers = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-charter/members/${query.rowId}`,
      },
      { method: 'PUT', body },
    )
  }

  getLibraryInScopeProcesses = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/libraries/${query.libId}/key-processes`,
      query: {
        fields: 'keyProcessId,teamId,name,libraryFunctionId,active',
      },
    })
  }

  getTeamCharterLibraryData = ({ query: { teamId, id } }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${teamId}/team-charter/library/${id}`,
    })
  }

  saveTeamCharterLibraryData = ({ query: { teamId, id, body } }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${teamId}/team-charter/library/${id}`,
      },
      { method: 'PUT', body },
    )
  }
}

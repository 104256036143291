import { findIndex, assoc, merge, get, map, sortBy, omit } from 'lodash/fp'
import { formatWithoutTimezone, getCurrentDate } from '@shared/Grid/utils/dates'
import { defaultStatusValueOrder } from '@shared/Grid/utils/statusCellUtils'

type SortOrder = {
  [key: string]: (args: $TSFixMe) => $TSFixMe
}

const SORT_ORDER_STATE: SortOrder = {
  status: ({ status }: { status: string }) => defaultStatusValueOrder[status.toLowerCase()],
  team: ({ team }: $TSFixMe) => team.longName,
  createdAt: ({ createdAt }: $TSFixMe) => createdAt,
  dueDate: ({ dueDate }: $TSFixMe) => dueDate,
  topic: ({ topic }: { topic: string }) => topic.toLowerCase(),
}

export const updateData = ({ data, id, body, sortOrder }: $TSFixMe) => {
  const index = findIndex(['id', id], data)
  const item = omit(['isNew'], get(index, data))
  const updatedDate = formatWithoutTimezone(getCurrentDate())
  const updatedItem = merge(item, { ...body, updatedAt: updatedDate })
  const updatedData = assoc(index, updatedItem, data)
  const sortOrderFns = sortOrder.map((item: string) => SORT_ORDER_STATE[item])

  return sortBy(sortOrderFns, updatedData)
}

export const generateUpdatedByUser = ({ name, surname, id }: $TSFixMe) => ({
  displayName: `${name} ${surname}`,
  id,
})

export const prepareUpdatedData = ({ id, body, user, sortOrder }: $TSFixMe) => ({
  id,
  body: {
    ...body,
    updatedBy: user ? generateUpdatedByUser(user) : { ...body.updatedBy },
  },
  sortOrder: sortOrder ?? ['status', 'team', 'dueDate'],
})

export const normalizeDecisionLogData = map((item: $TSFixMe) => ({
  ...item,
  isVisibleToIMO: Boolean(item.isVisibleToIMO),
}))

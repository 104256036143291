import { createSaga } from '@common/sagaCreator/createSaga'
import { getGenericApi } from '@common/net'
import { call, put } from 'redux-saga/effects'
import { setTogglesStatusSuccess } from '@generic/actions/actions'
import * as actions from '@generic/actions/actions'

export const fetchToggles = createSaga(function* fetchToggles({
  payload: { teamId },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getGenericApi()
  if (teamId) {
    const { hideMirroredInitiatives, ...toggles } = yield call(api.request('fetchToggles', { query: { teamId } }))

    yield put(
      actions.fetchTogglesSuccess({ toggles: { ...toggles, showMirroredInitiatives: !hideMirroredInitiatives } }),
    )
  }
})

export const setTeamUserSettings = createSaga(function* setTeamUserSettings({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getGenericApi()

  const { status, item, teamId, value } = payload

  const isInitiativeToggle = item === 'showMirroredInitiatives'
  const formattedStatus = isInitiativeToggle ? !status : status
  const itemName = isInitiativeToggle ? 'hideMirroredInitiatives' : item
  const valueToSet = value || Boolean(formattedStatus)
  const defaultValue = !status || 'All'

  try {
    yield call(
      api.request('setTeamUserSettings', {
        body: { [itemName]: valueToSet },
        query: { teamId },
      }),
    )

    yield put(setTogglesStatusSuccess({ status: value || Boolean(status), item }))
  } catch (e) {
    yield put(setTogglesStatusSuccess({ status: defaultValue, item }))
  }
})

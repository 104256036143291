import styled from 'styled-components'
import { ImoGrid } from '@shared/Grid/ImoGrid'

export const Container = styled.div`
  height: 100%;

  .bp4-icon-help {
    margin-left: 4px;
    margin-top: -4px;
  }
`

export const FileContainer = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-left: 5px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
`

export const StyledGrid = styled(ImoGrid)`
  .ag-overlay {
    height: calc(100% - 32px);
    bottom: 0;
    top: unset;
  }

  .loading .ag-root-wrapper::after {
    margin-top: 12px;
  }

  &.deliverable-files-grid {
    .ag-theme-balham {
      height: 102px;
    }

    .ag-popup {
      display: none;
    }
  }

  .action-icon {
    text-align: center;
  }
`

export const StyledUploadedFilesGrid = styled(ImoGrid)`
  .custom-deliverable-attachment {
    .ag-theme-balham {
      height: calc(100vh - 228px);
    }
  }

  .action-icon {
    text-align: center;
  }
`

export const Title = styled.span`
  line-height: 0.2em;
  vertical-align: middle;
`

export const TooltipBlock = styled.span`
  line-height: 0.2em;
  margin: 4px 0 3px;
  width: 14px;
  vertical-align: middle;
`

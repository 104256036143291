export const updateStateSingleItem =
  (stateKey: string) =>
  (state: $TSFixMe, action: { payload: { id: number; data: { id: number; isNew: boolean } } }) => {
    const { id, data } = action.payload

    if (state[stateKey]?.items) {
      return {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          items: state[stateKey].items.map((item: $TSFixMe) => (item.id === id ? { ...item, ...data } : item)),
        },
      }
    }
    return {
      ...state,
      [stateKey]: state[stateKey].map((item: $TSFixMe) => (item.id === id ? { ...item, ...data } : item)),
    }
  }

import { actionTypes } from '../../utils'
import * as constants from '@communications/actions/actionTypes'

export default {
  [constants.FETCH_COMMUNICATIONS_DATA]: {
    [actionTypes.FAIL]: ['We were unable to load the Communications Calendar'],
  },
  [constants.FETCH_COLUMNS_DATA]: {
    [actionTypes.FAIL]: ['We were unable to load the calendar columns'],
  },
  [constants.UPDATE_COLUMNS_DATA]: {
    [actionTypes.FAIL]: ['We were unable to update the calendar columns'],
  },
  [constants.DELETE_COLUMNS_DATA]: {
    [actionTypes.FAIL]: ['We were unable to delete the calendar column'],
  },
  [constants.SAVE_ROW]: {
    [actionTypes.FAIL]: ['We were unable to save the calendar row'],
    [actionTypes.VALIDATION_FAIL]: ['We were unable to save the calendar row'],
  },
  [constants.DELETE_ROWS]: {
    [actionTypes.FAIL]: ['We were unable to delete the calendar row'],
  },
  [constants.CREATE_REQUEST]: {
    [actionTypes.FAIL]: ['We were unable to add a new communcations request'],
    [actionTypes.VALIDATION_FAIL]: ['We were unable to add a new communcations request'],
  },
  [constants.FETCH_REQUESTS]: {
    [actionTypes.FAIL]: ['We were unable to load the communications event request'],
  },
  [constants.UPDATE_REQUEST]: {
    [actionTypes.FAIL]: ['We were unable to update the communications event request'],
  },
  [constants.DELETE_REQUEST]: {
    [actionTypes.FAIL]: ['We were unable to delete the communications event request'],
  },
  [constants.SAVE_EVENT]: {
    [actionTypes.VALIDATION_FAIL]: [
      'We were unable to save the communications event. Please check the fields highlighted in red and try again.',
    ],
    [actionTypes.FAIL]: ['We were unable to save the communications event'],
  },
  [constants.FETCH_EVENT]: {
    [actionTypes.FAIL]: ['We were unable to load the communications event'],
  },
  [constants.DELETE_EVENTS]: {
    [actionTypes.FAIL]: ['We were unable to delete the communications event'],
  },
  [constants.UPLOAD_ATTACHMENT]: {
    [actionTypes.FAIL]: [`We were unable to upload the file attachment`],
  },
  [constants.DELETE_ATTACHMENT]: {
    [actionTypes.FAIL]: ['We were unable to delete the file attachment'],
  },
}

import { actionTypes } from '../../utils'
import * as constants from '@dayOneReadiness/actions/actionTypes'

export default {
  [constants.FETCH_SCENARIOS]: {
    [actionTypes.FAIL]: ['We were unable to load the Day 1 scenarios'],
  },
  [constants.CREATE_SCENARIO]: {
    [actionTypes.FAIL]: ['We were unable to create a Day 1 scenario'],
  },
  [constants.UPDATE_SCENARIO]: {
    [actionTypes.FAIL]: ['We were unable to update the Day 1 scenarios'],
  },
  [constants.DELETE_SCENARIO]: {
    [actionTypes.FAIL]: ['We were unable to delete a Day 1 scenario'],
  },
}

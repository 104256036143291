import { get, isNil, upperFirst } from 'lodash/fp'
import { newOwnersFormatter } from '@shared/Grid/utils/ownerCellUtils'
import { stringComparator } from '@helpers/utilsComparators'

import {
  agRichKeyCreator,
  agSelectFilterValueFormatter,
  getParamsForAgRichSelect,
  getValueLabel,
} from '@helpers/gridUtils'
import {
  changingDayOneOptions,
  entanglementCategoryOptions,
  entanglementOptions,
} from '@myImoClient/components/Deliverables/MutualDiscovery/constants'
import { booleanYesNoOptions, INDENTATION_CELL_STEP, COLUMN_TYPE } from '@shared/Grid/constants'
import { applyTextColumnFilterParams, formatBooleanToYesNo, getIndentClassName } from '@shared/Grid/utils/general'
import { isSMO, nameManagementType } from '@myImoConfig/containers/ConfigSteps/utils'
import { separationDealTypes, integrationDealTypes } from '@helpers/constants'
import { Colors } from '@blueprintjs/core'
import { ActionIconRenderer } from '@imo/imo-ui-toolkit'
import CssTruncateTextRenderer from '@imo/imo-ui-toolkit/dist/components/Grid/CellRenderers/CssTruncatedTextRenderer'

export const getValueFormatted = get('valueFormatted')

export const isEntanglementDealType = (dealType) =>
  [integrationDealTypes.BUY_SIDE_CURVE_OUT, separationDealTypes.DEMERGER, separationDealTypes.DIVESTITURE].includes(
    dealType,
  )

export const getColumnDefs = ({
  isBuySideDealType,
  managementType,
  TSAOptions,
  dealType,
  mutualDiscoveryActive,
  TSAColumnName,
}) => {
  const isEntanglement = mutualDiscoveryActive && isEntanglementDealType(dealType)
  const isSMOType = isSMO(managementType)

  return [
    {
      headerName: `${upperFirst(nameManagementType(managementType))} team`,
      field: 'team.longName',
      tooltipField: 'team.longName',
      width: 100,
      pinned: 'left',
      cellRenderer: CssTruncateTextRenderer,
      filter: 'agSetColumnFilter',
      comparator: stringComparator,
      filterParams: {
        filterOptions: ['contains'],
        debounceMs: 700,
        caseSensitive: false,
        suppressAndOrCondition: true,
        comparator: stringComparator,
      },
    },
    {
      headerName: 'Geography',
      field: 'geography',
      tooltipField: 'geography',
      width: 90,
      pinned: 'left',
      cellRenderer: CssTruncateTextRenderer,
      filter: 'agSetColumnFilter',
      comparator: stringComparator,
      filterParams: {
        filterOptions: ['contains'],
        debounceMs: 700,
        caseSensitive: false,
        suppressAndOrCondition: true,
        comparator: stringComparator,
      },
      suppressSizeToFit: true,
    },
    {
      headerName: 'Complete',
      field: 'complete',
      width: 77,
      pinned: 'left',
      cellRenderer: ActionIconRenderer,
      cellRendererParams: ({ value }) => ({
        icon: value ? 'tick-circle' : null,
        iconSize: 14,
        color: Colors.BLUE2,
        rendererStyle: {
          justifyContent: 'center',
        },
        contentStyle: {
          alignItems: 'center',
          justifyContent: 'center',
        },
        iconStyle: {
          cursor: 'default',
        },
      }),
      cellClassRules: {
        align_top: false,
      },
      filter: 'agSetColumnFilter',
      keyCreator: agSelectFilterValueFormatter(booleanYesNoOptions),
      filterParams: {
        debounceMs: 0,
        suppressAndOrCondition: true,
      },
      suppressSizeToFit: true,
    },
    {
      headerName: 'Owner(s)',
      field: 'owners',
      type: COLUMN_TYPE.owners,
    },
    {
      headerName: 'Changing on Day 1',
      field: 'changingDayOne',
      width: 130,
      hide: isSMOType,
      ...getParamsForAgRichSelect(changingDayOneOptions),
      filter: 'agSetColumnFilter',
      comparator: stringComparator,
      keyCreator: agSelectFilterValueFormatter(booleanYesNoOptions),
      filterParams: {
        caseSensitive: false,
        comparator: stringComparator,
      },
      suppressSizeToFit: true,
      tooltipValueGetter: getValueFormatted,
    },
    {
      headerName: 'Entanglement',
      field: 'entanglement',
      width: 100,
      suppressSizeToFit: true,
      tooltipValueGetter: getValueFormatted,
      hide: !isEntanglement,
      ...getParamsForAgRichSelect(entanglementOptions),
      keyCreator: agRichKeyCreator,
    },
    {
      headerName: 'Category',
      field: 'entanglementCategory',
      width: 100,
      suppressSizeToFit: true,
      tooltipValueGetter: getValueFormatted,
      hide: !isEntanglement || !isSMOType,
      ...getParamsForAgRichSelect(entanglementCategoryOptions),
      keyCreator: agRichKeyCreator,
    },
    {
      headerName: 'Day 1 solution',
      field: 'dayOneSolution',
      tooltipField: 'dayOneSolution',
      columnGroupShow: null,
      width: 100,
      suppressSizeToFit: true,
      hide: !isSMOType,
      keyCreator: agRichKeyCreator,
    },
    {
      headerName: TSAColumnName,
      field: 'tsa',
      width: TSAColumnName?.toLowerCase() === 'tsa' ? 89 : 130,
      ...getParamsForAgRichSelect(TSAOptions),
      tooltipValueGetter: getValueFormatted,
      hide: !isBuySideDealType,
      filter: 'agSetColumnFilter',
      comparator: stringComparator,
      keyCreator: agRichKeyCreator,
      filterParams: {
        caseSensitive: false,
        comparator: stringComparator,
      },
      suppressSizeToFit: true,
    },
    {
      headerName: 'Day 1 planning assumptions',
      field: 'dayOneAssumptions',
      width: 160,
      suppressSizeToFit: false,
      comparator: stringComparator,
      ...applyTextColumnFilterParams({ withHeaderSearch: true }),
    },
    {
      headerName: 'Post close assumptions',
      field: 'postCloseAssumptions',
      tooltipField: 'postCloseAssumptions',
      width: 140,
      suppressSizeToFit: false,
      comparator: stringComparator,
      ...applyTextColumnFilterParams({ withHeaderSearch: true }),
    },
  ]
}

export const autoGroupColumnDef = {
  headerName: 'Key process/sub-process',
  field: 'name',
  width: 150,
  tooltipField: 'name',
  suppressSizeToFit: false,
  comparator: stringComparator,
  ...applyTextColumnFilterParams({
    withHeaderSearch: true,
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: CssTruncateTextRenderer,
      calculateColumnWidth: true,
      indentationStep: INDENTATION_CELL_STEP,
    },
  }),
  cellClass: getIndentClassName,
}

export const defaultColDef = {
  editable: false,
  sortable: true,
  filter: true,
  floatingFilter: true,
  cellClassRules: {
    multiline_cell: 'true',
    align_top: 'true',
  },
  cellRenderer: CssTruncateTextRenderer,
}

export const processCellCallback = (params) => {
  const {
    column: { colId },
    node: { data },
    value,
    context,
  } = params

  const tsaCellCallback = ({ value, context: { TSAOptions } }) => {
    if (!value) return null

    return getValueLabel(TSAOptions, value)
  }

  switch (colId) {
    case 'complete':
      return formatBooleanToYesNo(value)

    case 'changingDayOne':
      if (isNil(value)) {
        return ''
      }

      return formatBooleanToYesNo(value)

    case 'name':
      return get('keyProcessId', data) ? `--  ${value}` : value

    case 'owners':
      return newOwnersFormatter({ value })

    case 'entanglement':
    case 'entanglementCategory':
      return value ? upperFirst(value) : ''

    case 'tsa':
      return tsaCellCallback({ value, context })

    default:
      return value
  }
}

import { notSelectedOption } from '@helpers/constants'

export const defaultWindowWidth = 1385

export const DEFAULT_HEADER_GRID_HEIGHT = 33

export const AUTO_GROUP_COLUMN_ID = 'ag-Grid-AutoColumn'

export const INDENTATION_CELL_STEP = 28

export const booleanYesNoBaseOptions = [
  { label: 'No', value: false },
  { label: 'Yes', value: true },
]

export const booleanYesNoOptions = [notSelectedOption, ...booleanYesNoBaseOptions]

export const activeInactiveOptions = [
  { label: 'Inactive', value: false },
  { label: 'Active', value: true },
]

export const COLUMN_TYPE = {
  status: 'status',
  topic: 'topic',
  currency: 'currency',
  dueDate: 'dueDate',
  boolean: 'boolean',
  priority: 'priority',
  select: 'select',
  owner: 'owner',
  owners: 'owners',
  generalDate: 'generalDate',
  icon: 'icon',
  textarea: 'textarea',
  textareaWithoutWidth: 'textareaWithoutWidth',
  checkboxWithText: 'checkboxWithText',
  checkbox: 'checkbox',
  riskProbability: 'riskProbability',
  potentialImpact: 'potentialImpact',
}

export const defaultEditorOptions = {
  withEdit: true,
  withSort: true,
  withFilter: true,
}

export const COLUMN_EVENTS = {
  COLUMN_RESIZED: 'columnResized',
}

/*
 * Options for 'formatCentsToMoney' function
 * next option will return string like '10000.00'
 */
export const XLS_CURRENCY_OPTIONS = {
  withCurrency: false,
  withRounding: false,
  withWording: false,
}

//Styles for multiline cell
export const CELL_LEFT_PADDING = 12
export const LINE_HEIGHT = 14
export const ROW_BORDER = 1
export const CELL_TOP_PADDING = 9
export const ADDITIONAL_WIDTH_FOR_SAFE_TRUNCATE = 2

export const customColumnTypes = {
  FREE_TEXT: 'FreeText',
  DROPDOWN: 'Dropdown',
}

import styled from 'styled-components'
import {
  USER_AVATAR_RIGHT_MARGIN,
  USER_AVATAR_SIZE,
} from '@myImoClient/pages/Communications/Communications/components/ReviewRequestsPopup/utils'
import { white } from '@imo/imo-ui-toolkit'
import { Colors } from '@blueprintjs/core'

export const RoundedWrapper = styled.div`
  width: ${({
    // @ts-expect-error TS(2339): Property 'size' does not exist on type
    size,
  }) => size || USER_AVATAR_SIZE}px;
  height: ${({
    // @ts-expect-error TS(2339): Property 'size' does not exist on type
    size,
  }) => size || USER_AVATAR_SIZE}px;
  border-radius: 50%;
  background-color: ${Colors.GRAY2};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${USER_AVATAR_RIGHT_MARGIN}px;
  font-size: 12px;
  line-height: 1;
  letter-spacing: -1px;
  color: ${white};
`

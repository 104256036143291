import { userRoles, accessRights, teams } from '../constants'
import { routesList } from '../routesList'

export const CENTRAL_RESTRICTED_PERMISSIONS = () => {
  const sharedPermissionKeys = {
    name: teams.CENTRAL,
    role: userRoles.RESTRICTED,
    priority: 7,
    teamsPermissions: {
      deliverable: accessRights.RESTRICTED,
    },
  }

  return {
    ...sharedPermissionKeys,
    permissions: [
      {
        route: routesList.DASHBOARD.MY_TEAM.ROOT,
        accessLevel: accessRights.READ_WRITE,
      },
      {
        route: routesList.DASHBOARD.MY_TEAM.TEAM_HOME,
        accessLevel: accessRights.READ_WRITE,
      },
      {
        route: routesList.DASHBOARD.PROJECTS_AND_TASKS,
        accessLevel: accessRights.READ_WRITE,
      },
      {
        route: routesList.DASHBOARD.DELIVERABLE.ROOT,
        accessLevel: accessRights.READ_WRITE,
      },
      {
        route: routesList.DASHBOARD.MY_TEAM.TEAM_WORKSPACE,
        accessLevel: accessRights.READ_WRITE,
      },
      {
        route: routesList.DASHBOARD.MY_TEAM.DECISION_LOG,
        accessLevel: accessRights.READ_WRITE,
      },
      {
        route: routesList.DASHBOARD.MY_TEAM.RISK_LOG,
        accessLevel: accessRights.READ_WRITE,
      },
      {
        route: routesList.DASHBOARD.MY_TEAM.TEAM_STATUS_UPDATE,
        accessLevel: accessRights.READ_WRITE,
      },
    ],
  }
}

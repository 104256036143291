import { handleActions } from 'redux-actions'
import * as constants from '@teamResources/masterplan/actions/actionTypes'

export interface IMasterplanState {
  masterplanURL: string
}

export const initialState = {
  masterplanURL: '',
}

const masterplanReducer = handleActions<IMasterplanState, $TSFixMe>(
  {
    [constants.SET_TEAM_RESOURCES_MASTERPLAN]: (state, action) => ({ ...state, masterplanURL: action.payload }),
  },
  initialState,
)

export default masterplanReducer

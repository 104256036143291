import { FONT_FAMILY, dirtyWhite } from '@imo/imo-ui-toolkit'
import { Colors } from '@blueprintjs/core'

export const Placement = {
  AUTO_START: 'auto-start',
  AUTO: 'auto',
  AUTO_END: 'auto-end',
  TOP_START: 'top-start',
  TOP: 'top',
  TOP_END: 'top-end',
  RIGHT_START: 'right-start',
  RIGHT: 'right',
  RIGHT_END: 'right-end',
  BOTTOM_END: 'bottom-end',
  BOTTOM: 'bottom',
  BOTTOM_START: 'bottom-start',
  LEFT_END: 'left-end',
  LEFT: 'left',
  LEFT_START: 'left-start',
} as const

export const PopoverStyle = {
  VERTICAL_PADDING: '10px',
  HORIZONTAL_PADDING: '12px',
  COLOR: dirtyWhite,
  BACKGROUND_COLOR: '#394b59',
  BACKGROUND_COLOR_DANGER: Colors.RED3,
  FONT: `12px/1.5 ${FONT_FAMILY}`,
}

export const POPOVER_ARROW_SVG_SIZE = 30

export const UNSET_POPOVER_CLASS_NAME = null

import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'

import { refetchTeamCharterData } from './refetchTeamCharterData'
import { fetchTeamCharterData } from './fetchTeamCharterData'
import { updateTeamCharterData } from './updateTeamCharterData'
import {
  createInScopeKeyProcessData,
  updateInScopeKeyProcessData,
  deleteInScopeKeyProcessData,
} from './inScopeKeyProcess'
import {
  createOutScopeKeyProcessData,
  updateOutScopeKeyProcessData,
  deleteOutScopeKeyProcessData,
} from './outScopeKeyProcess'
import {
  createMacroPlanningAssumptionData,
  updateMacroPlanningAssumptionData,
  deleteMacroPlanningAssumptionData,
} from './macroPlanningAssumption'
import {
  createKeyInterdependencyData,
  updateKeyInterdependencyData,
  deleteKeyInterdependencyData,
} from './keyInterdependency'
import {
  createKeyRiskToMitigateData,
  updateKeyRiskToMitigateData,
  deleteKeyRiskToMitigateData,
} from './keyRiskToMitigate'
import {
  createKeyDecisionToMakeData,
  updateKeyDecisionToMakeData,
  deleteKeyDecisionToMakeData,
} from './keyDecisionToMake'

import { saveTeamMemberRow } from './saveTeamMemberRow'
import { fetchTeamMembers, deleteTeamMemberData } from './teamMembers'
import { fetchTeamCharterLibraryData, saveTeamCharterLibraryData } from './teamCharterLibraryData'

export default function* teamCharterSagaV2() {
  yield all([
    takeLatest(constants.FETCH_TEAM_CHARTER_DATA, fetchTeamCharterData),
    takeLatest(constants.UPDATE_TEAM_CHARTER_DATA, updateTeamCharterData),
    takeLatest(constants.REFETCH_TEAM_CHARTER_DATA, refetchTeamCharterData),

    takeLatest(constants.CREATE_IN_SCOPE_KEY_PROCESS_PENDING, createInScopeKeyProcessData),
    takeLatest(constants.UPDATE_IN_SCOPE_KEY_PROCESS_PENDING, updateInScopeKeyProcessData),
    takeLatest(constants.DELETE_IN_SCOPE_KEY_PROCESS_PENDING, deleteInScopeKeyProcessData),

    takeLatest(constants.CREATE_OUT_SCOPE_KEY_PROCESS, createOutScopeKeyProcessData),
    takeLatest(constants.UPDATE_OUT_SCOPE_KEY_PROCESS, updateOutScopeKeyProcessData),
    takeEvery(constants.DELETE_OUT_SCOPE_KEY_PROCESS, deleteOutScopeKeyProcessData),

    takeLatest(constants.CREATE_MACRO_PLANNING_ASSUMPTION, createMacroPlanningAssumptionData),
    takeLatest(constants.UPDATE_MACRO_PLANNING_ASSUMPTION, updateMacroPlanningAssumptionData),
    takeLatest(constants.DELETE_MACRO_PLANNING_ASSUMPTION, deleteMacroPlanningAssumptionData),

    takeLatest(constants.CREATE_KEY_INTERDEPENDENCY, createKeyInterdependencyData),
    takeLatest(constants.UPDATE_KEY_INTERDEPENDENCY, updateKeyInterdependencyData),
    takeEvery(constants.DELETE_KEY_INTERDEPENDENCY, deleteKeyInterdependencyData),

    takeLatest(constants.CREATE_KEY_RISK_TO_MITIGATE, createKeyRiskToMitigateData),
    takeLatest(constants.UPDATE_KEY_RISK_TO_MITIGATE, updateKeyRiskToMitigateData),
    takeEvery(constants.DELETE_KEY_RISK_TO_MITIGATE, deleteKeyRiskToMitigateData),

    takeLatest(constants.CREATE_KEY_DECISION_TO_MAKE, createKeyDecisionToMakeData),
    takeLatest(constants.UPDATE_KEY_DECISION_TO_MAKE, updateKeyDecisionToMakeData),
    takeEvery(constants.DELETE_KEY_DECISION_TO_MAKE, deleteKeyDecisionToMakeData),

    takeLatest(constants.FETCH_TEAM_MEMBERS, fetchTeamMembers),
    takeEvery(constants.DELETE_TEAM_MEMBER, deleteTeamMemberData),
    takeLatest(constants.SAVE_TEAM_MEMBERS, saveTeamMemberRow),

    takeLatest(constants.FETCH_TEAM_CHARTER_LIBRARY_DATA, fetchTeamCharterLibraryData),
    takeLatest(constants.SAVE_TEAM_CHARTER_LIBRARY_DATA, saveTeamCharterLibraryData),
  ])
}

import { accessRights, teams } from '../constants'
import { routesList } from '../routesList'
import { CENTRAL_MEMBER_PERMISSIONS } from './centralMember'

export const CENTRAL_VC_MEMBER_PERMISSIONS = {
  ...CENTRAL_MEMBER_PERMISSIONS,
  name: teams.CENTRAL_VC,
  priority: 3,
  permissions: [
    ...CENTRAL_MEMBER_PERMISSIONS.permissions,
    {
      route: routesList.VALUE_CAPTURE_TRACKER_V2.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
  ],
}

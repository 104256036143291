import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'
import {
  fetchAdvancedFinancials,
  updateDetailedFinancialsNameOrCategory,
  updateDetailedFinancials,
  createDetailedFinancials,
  deleteDetailedFinancials,
  onCloseAdvancedFinancials,
  updateFinancialData,
  updateRunRate,
  updateRunRateImpact,
} from './advancedFinancials'

export default function* advancedFinancialsSaga() {
  yield all([
    takeLatest(constants.FETCH_ADVANCED_FINANCIALS, fetchAdvancedFinancials),
    takeLatest(constants.CREATE_ADVANCED_FINANCIALS, createDetailedFinancials),
    takeLatest(constants.UPDATE_ADVANCED_FINANCIALS_NAME_OR_CATEGORY, updateDetailedFinancialsNameOrCategory),
    takeLatest(constants.UPDATE_ADVANCED_FINANCIALS, updateDetailedFinancials),
    takeLatest(constants.DELETE_ADVANCED_FINANCIALS, deleteDetailedFinancials),
    takeLatest(constants.CLOSE_ADVANCED_FINANCIALS, onCloseAdvancedFinancials),
    takeLatest(constants.UPDATE_FINANCIAL_DATA, updateFinancialData),
    takeLatest(constants.UPDATE_RUN_RATE, updateRunRate),
    takeLatest(constants.UPDATE_RUN_RATE_IMPACT, updateRunRateImpact),
  ])
}

import React from 'react'
import { isSMO } from '@myImoConfig/containers/ConfigSteps/utils'
import { VersionWrapper, ImoLogo, SmoLogo, McKinseyLogo, ImoVersion } from './LogoTooltip.styles'

interface Props {
  managementType: string
}

const LogoTooltip = ({ managementType }: Props) => {
  const releaseVersion = (window as $TSFixMe).IMO_DASHBOARD_FRONTEND_VERSION
  const transformedVersion = releaseVersion?.replace('v2', '1')

  return (
    <VersionWrapper>
      {isSMO(managementType) ? <SmoLogo /> : <ImoLogo />}
      <McKinseyLogo />
      <ImoVersion>Version {transformedVersion || '1.0.0'}</ImoVersion>
    </VersionWrapper>
  )
}

export default LogoTooltip

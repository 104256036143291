import { call, put } from 'redux-saga/effects'
import { getImoControlTowerApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { fetchColumnsData as fetchColumnsDataAction, setColumnsData } from '@steerCoRoadmap/actions/actions'
import { omit } from 'lodash'

export const fetchColumnsData = createSaga(function* fetchColumnsData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = action.payload

  const api = yield getImoControlTowerApi()
  const columns = yield call(
    api.request('getColumnsData', {
      query: { teamId },
    }),
  )

  yield put(
    setColumnsData({
      columns: columns.sort(
        (col1: $TSFixMe, col2: $TSFixMe) => col2.visible - col1.visible || col1.orderId - col2.orderId,
      ),
    }),
  )
},
true)

export const updateColumnsData = createSaga(function* updateColumnsData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getImoControlTowerApi()
  const { teamId, data } = payload

  yield call(
    api.request('updateColumnsData', {
      query: { teamId },
      body: data.map(({ isNew, ...column }: $TSFixMe) => (isNew ? omit(column, ['id']) : column)),
    }),
  )

  yield put(fetchColumnsDataAction({ teamId }))
},
true)

export const deleteColumnsData = createSaga(function* deleteColumnsData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getImoControlTowerApi()
  const { teamId, data } = payload

  yield call(
    api.request('deleteColumnsData', {
      query: {
        columnIds: data,
        teamId,
      },
    }),
  )

  yield put(fetchColumnsDataAction({ teamId }))
},
true)

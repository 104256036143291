import { put, call } from 'redux-saga/effects'
import { updateProcessSuccess } from '../actions/actions'
import { getMutualDiscoveryApi } from '@common/net'
import { omit } from 'lodash'
import { transformEmptyStringsToNull } from '@helpers/utils'
import { createSaga } from '@common/sagaCreator/createSaga'
import { parseOwners } from '@shared/Grid/CellEditors/utils'

function* updateSubProcess({ teamId, data, id, keyProcessId }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  yield put(updateProcessSuccess({ id, keyProcessId, data }))

  const api = yield getMutualDiscoveryApi()
  yield call(
    api.request('updateSubProcess', {
      body: {
        ...transformEmptyStringsToNull(
          omit(data, [
            'id',
            'isNew',
            'keyProcessId',
            'keyProcess',
            'libraryFunctionId',
            'custom',
            'teamId',
            'processName',
            'subProcessName',
          ]),
        ),
        owners: data.owners ? parseOwners(data.owners) : data.owners,
      },
      query: { teamId, id, keyProcessId },
    }),
  )
}

function* updateKeyProcess({ teamId, data, id }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  yield put(updateProcessSuccess({ id, data }))

  const api = yield getMutualDiscoveryApi()
  yield call(
    api.request('updateKeyProcess', {
      body: {
        ...transformEmptyStringsToNull(
          omit(data, [
            'id',
            'isNew',
            'keyProcessId',
            'keyProcess',
            'libraryFunctionId',
            'custom',
            'teamId',
            'processName',
            'subProcessName',
          ]),
        ),
        owners: data.owners ? parseOwners(data.owners) : data.owners,
      },
      query: { teamId, id },
    }),
  )
}

export const updateProcess = createSaga(function* updateProcess({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { keyProcessId } = payload

  if (keyProcessId) {
    return yield call(updateSubProcess, payload)
  }

  return yield call(updateKeyProcess, payload)
})

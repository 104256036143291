import React from 'react'
import { renderToString } from 'react-dom/server'
import { isNil, find, sortBy, reject } from 'lodash/fp'
import { call, put, select } from 'redux-saga/effects'
import { getInitiativeListV2Api } from '@common/net'
import {
  getFilterForRequest,
  checkIsFilterValid,
  getFilterForTable,
} from '@myImoClient/components/TeamValueCaptureV2/InitiativeList/utils'
import {
  setInitiativeList,
  addInitiativeToInitiativeList,
  resetInitiativeList,
  saveInitiativeFetchParams,
  resetAllInitiativeFilters,
} from '@teamValueCaptureV2/initiativeList/actions/actions'
import {
  setUserInitiativeFilter,
  setSelectedUserInitiativeFilter,
  setIsRedirect,
  setTableFilters,
  flushTableFilter,
} from '@teamValueCaptureV2/userInitiativeFilter/actions/actions'
import {
  getTeamId,
  getSynergyType,
  getStatusLockedTabs,
  getInitiativeColumnsCustomNames,
  getInitiativeFetchParams,
} from '@teamValueCaptureV2/initiativeList/selectors'
import {
  getUserInitiativeFilter,
  getInitiativeListFilterState,
  getIsRedirect,
  getSelectedUserInitiativeFilter,
} from '@teamValueCaptureV2/userInitiativeFilter/selectors'
import { createSaga } from '@common/sagaCreator/createSaga'
import { INFINITE_SCROLL_LIMIT } from '@teamValueCaptureV2/initiativeList/sagas/constants'
import { getCustomColumns, getIsDayOneMissed } from '@generic/selectors'
import { getBodyParams } from '@teamValueCaptureV2/utils'
import { dictionariesNames } from '@helpers/constants'
import CustomFilterTitle from '@shared/Grid/CustomFilterToolPanel/CustomFilterTitle/CustomFilterTitle'

export const fetchUserInitiativeFilter = createSaga(function* fetchUserInitiativeFilter({ payload }) {
  const { teamId, synergyType, isInitialFetch, columnDefs = [] } = payload
  const customColumns = yield select((state) => getCustomColumns(state, dictionariesNames.VALUE_CAPTURE))
  const api = yield getInitiativeListV2Api()

  const filters = yield call(
    api.request('getUserInitiativeFilter', {
      query: { teamId, synergyType },
      body: getBodyParams({}),
    }),
  )

  const userInitiativeFilter = filters.map((filter) => ({
    ...filter,
    filter: getFilterForTable(JSON.parse(filter.filter), customColumns),
  }))

  yield put(setUserInitiativeFilter(userInitiativeFilter))

  const filterState = yield select(getInitiativeListFilterState)

  // filter can be already set if user have been redirected to Initiative List from Summary Status
  // initiative can be selected and filter set if user have been redirected to Initiative List from Project plan
  const filterAlreadySet = !isNil(filterState)
  const isRedirect = yield select(getIsRedirect)

  if (isRedirect || filterAlreadySet || !isInitialFetch) {
    yield put(setIsRedirect(false))

    return
  }

  const defaultFilter = find({ isDefault: true }, userInitiativeFilter)

  if (!defaultFilter) return

  const isFilterValid = checkIsFilterValid({ filter: defaultFilter, columnDefs })

  if (!isFilterValid) return

  yield put(setSelectedUserInitiativeFilter(defaultFilter))
})

export const fetchUserInitiativeFilterAndInitiatives = createSaga(function* fetchUserInitiativeFilterAndInitiatives({
  payload,
}) {
  const { teamId, synergyType, linkedInitiativeId, linkedSynergyTypeForOTC, columnDefs, resetAllFiltersInStore } =
    payload

  const isDayOneMissed = yield select(getIsDayOneMissed)

  if (resetAllFiltersInStore) {
    yield put(resetAllInitiativeFilters(null))
    yield put(setUserInitiativeFilter([]))
    yield put(setTableFilters({}))
    yield put(flushTableFilter())
  }

  if (isDayOneMissed) return

  const api = yield getInitiativeListV2Api()
  const { lockedTabs, synergyInitiatives, initiativeColumnsCustomNames, deliverableInfo } = yield call(
    api.request('getInitiativeList', { query: { teamId, synergyType } }),
  )

  yield put(
    setInitiativeList({
      lockedTabs,
      initiativeColumnsCustomNames,
      list: synergyInitiatives,
      linkedInitiativeId,
      linkedSynergyTypeForOTC,
      deliverableInfo,
    }),
  )

  yield call(fetchUserInitiativeFilter, {
    payload: { teamId, synergyType, isInitialFetch: true, columnDefs },
  })
})
export const fetchUserInitiativeFilterAndInfinityList = createSaga(function* fetchUserInitiativeFilterAndInfinityList({
  payload,
}) {
  const {
    teamId,
    synergyType,
    linkedInitiativeId,
    linkedSynergyTypeForOTC,
    offset = 0,
    withClearing,
    sorting,
    filters,
    columnDefs,
    resetAllFiltersInStore,
  } = payload

  const isDayOneMissed = yield select(getIsDayOneMissed)
  const customColumns = yield select((state) => getCustomColumns(state, dictionariesNames.VALUE_CAPTURE))
  const initiallyLockedTabs = yield select(getStatusLockedTabs)
  const initiallyInitiativeColumnsCustomNames = yield select(getInitiativeColumnsCustomNames)
  const initiativeFetchParams = yield select(getInitiativeFetchParams)

  if (withClearing) {
    yield put(resetInitiativeList())
  }

  if (resetAllFiltersInStore) {
    yield put(resetAllInitiativeFilters(null))
    yield put(setUserInitiativeFilter([]))
    yield put(setTableFilters({}))
    yield put(flushTableFilter())
  }

  if (isDayOneMissed) return

  yield call(fetchUserInitiativeFilter, { payload: { teamId, synergyType, isInitialFetch: true, columnDefs } })

  const filterState = yield select(getInitiativeListFilterState)

  let needFetch = isNil(filterState)

  let dataFromServer = {
    lockedTabs: initiallyLockedTabs,
    initiativeColumnsCustomNames: initiallyInitiativeColumnsCustomNames,
    synergyInitiatives: [],
  }

  if (needFetch) {
    const api = yield getInitiativeListV2Api()
    const responseSort = sorting || initiativeFetchParams.sorting
    const responseFilter = filters || initiativeFetchParams.filters

    const body = {
      synergyType,
      limit: INFINITE_SCROLL_LIMIT,
      offset,
    }

    dataFromServer = yield call(
      api.request('getInfinityInitiativeList', {
        query: {
          teamId,
        },
        body: getBodyParams({ body, sorting: responseSort, filters: responseFilter, customColumns }),
      }),
    )

    yield put(
      saveInitiativeFetchParams({
        teamId,
        synergyType,
        linkedInitiativeId,
        linkedSynergyTypeForOTC,
        offset,
        withClearing,
        sorting: responseSort,
        filters: responseFilter,
      }),
    )
  }

  const { lockedTabs, synergyInitiatives, initiativeColumnsCustomNames, deliverableInfo } = dataFromServer

  yield put(
    addInitiativeToInitiativeList({
      lockedTabs,
      initiativeColumnsCustomNames,
      list: synergyInitiatives,
      linkedInitiativeId,
      linkedSynergyTypeForOTC,
      deliverableInfo,
    }),
  )
})

export const addUserInitiativeFilter = createSaga(function* addUserInitiativeFilter({ payload }) {
  const { filterName, filters } = payload
  const api = yield getInitiativeListV2Api()
  const teamId = yield select(getTeamId)
  const synergyType = yield select(getSynergyType)
  const oldUserFilters = yield select(getUserInitiativeFilter)
  const customColumns = yield select((state) => getCustomColumns(state, dictionariesNames.VALUE_CAPTURE))
  const filtersToSave = JSON.stringify(getFilterForRequest(filters, customColumns))

  const newFilter = {
    filterName: filterName,
    filter: filtersToSave,
    isDefault: false,
  }

  const newFiltersOptimistic = sortBy('filterName', [...oldUserFilters, { ...newFilter, filter: filters }])

  yield put(setUserInitiativeFilter(newFiltersOptimistic))

  try {
    yield call(
      api.request('saveUserInitiativeFilter', {
        query: { teamId, synergyType },
        body: newFilter,
      }),
    )
  } catch (error) {
    throw error
  } finally {
    yield call(fetchUserInitiativeFilter, { payload: { teamId, synergyType } })

    const filters = yield select(getUserInitiativeFilter)
    const filter = find({ filterName }, filters)

    yield put(setSelectedUserInitiativeFilter(filter))
  }
})

export const changeUserInitiativeFilter = createSaga(function* changeUserInitiativeFilter({ payload }) {
  const { filter: newFilter } = payload
  const api = yield getInitiativeListV2Api()
  const teamId = yield select(getTeamId)
  const synergyType = yield select(getSynergyType)
  const oldUserFilters = yield select(getUserInitiativeFilter)

  const needReplaceExistingFilter = !newFilter.id

  let filterId
  let filterToSendToServer
  let newFiltersOptimistic

  if (needReplaceExistingFilter) {
    //replace name in existing filter

    const existedFilter = find(
      ({ filterName }) => filterName.toLowerCase() === newFilter.filterName.toLowerCase(),
      oldUserFilters,
    )

    filterId = existedFilter.id
    filterToSendToServer = {
      filterName: newFilter.filterName,
      filter: JSON.stringify(newFilter.filters),
      isDefault: existedFilter.isDefault,
    }

    newFiltersOptimistic = oldUserFilters.map((filter) => {
      return filter.id === newFilter.id ? newFilter : filter
    })
  } else {
    // change isDefault checkbox

    filterId = newFilter.id
    filterToSendToServer = {
      filterName: newFilter.filterName,
      filter: JSON.stringify(newFilter.filter),
      isDefault: newFilter.isDefault,
    }

    newFiltersOptimistic = oldUserFilters.map((filter) => {
      return filter.id === newFilter.id ? newFilter : { ...filter, isDefault: false }
    })
  }

  yield put(setUserInitiativeFilter(newFiltersOptimistic))

  try {
    yield call(
      api.request('changeUserInitiativeFilter', {
        query: { teamId, synergyType, filterId },
        body: filterToSendToServer,
      }),
    )
  } catch (error) {
    throw error
  } finally {
    yield call(fetchUserInitiativeFilter, { payload: { teamId, synergyType } })

    if (needReplaceExistingFilter) {
      const filters = yield select(getUserInitiativeFilter)
      const filter = find({ filterName: newFilter.filterName }, filters)

      yield put(setSelectedUserInitiativeFilter(filter))
    }
  }
})

export const deleteUserInitiativeFilter = createSaga(function* deleteUserInitiativeFilter({ payload }) {
  const { id } = payload
  const api = yield getInitiativeListV2Api()
  const teamId = yield select(getTeamId)
  const synergyType = yield select(getSynergyType)
  const oldUserFilters = yield select(getUserInitiativeFilter)
  const newFilters = reject({ id }, oldUserFilters)
  const selectedUserInitiativeFilter = yield select(getSelectedUserInitiativeFilter)

  try {
    yield call(
      api.request('deleteUserInitiativeFilter', {
        query: { teamId, filterId: id },
      }),
    )

    yield put(setUserInitiativeFilter(newFilters))

    if (selectedUserInitiativeFilter.id === id) {
      // it's not possible to change the title and leave the FilterToolPanelContainer open, so it's made with native JS
      const filterToolPanelTitle = document.querySelector('.ag-side-bar .ag-side-button-button .ag-side-button-label')

      if (!filterToolPanelTitle) return

      filterToolPanelTitle.innerHTML = renderToString(<CustomFilterTitle />)
    }
  } catch (error) {
    yield call(fetchUserInitiativeFilter, { payload: { teamId, synergyType } })
  }
})

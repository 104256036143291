import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@teamValueCaptureV2/statusUpdate/actions/actionTypes'
import {
  fetchYear5PipelineSummary,
  fetchYearlyImpactChart,
  fetchInitialOneTimeCosts,
  fetchStatusUpdateOneTimeCosts,
} from './summaryChart'
import { fetchValueCaptureStatuses } from './fetchValueCaptureStatuses'
import { updateValueCaptureStatuses } from './updateValueCaptureStatuses'

export default function* statusUpdateSaga() {
  yield all([
    takeLatest(constants.FETCH_YEAR_5_PIPELINE_SUMMARY, fetchYear5PipelineSummary),
    takeLatest(constants.UPDATE_VALUE_CAPTURE_STATUS, updateValueCaptureStatuses),
    takeLatest(constants.FETCH_VALUE_CAPTURE_STATUS, fetchValueCaptureStatuses),
    takeLatest(constants.FETCH_YEAR_IMPACT_CHART, fetchYearlyImpactChart),
    takeLatest(constants.FETCH_INITIAL_ONE_TIME_COSTS, fetchInitialOneTimeCosts),
    takeLatest(constants.FETCH_STATUS_UPDATE_ONE_TIME_COST, fetchStatusUpdateOneTimeCosts),
  ])
}

const prefix = '@mbm/'

export const FETCH_HEADCOUNT_REDUCTION = `${prefix}FETCH_HEADCOUNT_REDUCTION`
export const SET_HEADCOUNT_REDUCTION = `${prefix}SET_HEADCOUNT_REDUCTION`
export const SET_HEADCOUNT_REDUCTION_DATA_STATE = `${prefix}SET_HEADCOUNT_REDUCTION_DATA_STATE`

export const FETCH_ONE_TIME_IMPLEMENTATION_COSTS = `${prefix}FETCH_ONE_TIME_IMPLEMENTATION_COSTS`
export const SET_ONE_TIME_IMPLEMENTATION_COSTS = `${prefix}SET_ONE_TIME_IMPLEMENTATION_COSTS`
export const SET_ONE_TIME_IMPLEMENTATION_COSTS_LOADING_STATE = `${prefix}SET_ONE_TIME_IMPLEMENTATION_COSTS_LOADING_STATE`

export const FETCH_MONTHLY_PIPELINE_STATUS = `${prefix}FETCH_MONTHLY_PIPELINE_STATUS`
export const SET_MONTHLY_PIPELINE_STATUS = `${prefix}SET_MONTHLY_PIPELINE_STATUS`
export const SET_MONTHLY_PIPELINE_LOADING_STATE = `${prefix}SET_MONTHLY_PIPELINE_LOADING_STATE`

export const FETCH_DELTA_TRACKER_DATA = `${prefix}FETCH_DELTA_TRACKER_DATA`
export const SET_DELTA_TRACKER_DATA = `${prefix}SET_DELTA_TRACKER_DATA`

// Constants for Month By Month VC 2.1
export const FETCH_MONTHLY_ACTUALS_STATUS_V2 = `${prefix}FETCH_MONTHLY_ACTUALS_STATUS_V2`
export const SET_MONTHLY_ACTUALS_STATUS_V2 = `${prefix}SET_MONTHLY_ACTUALS_STATUS_V2`
export const SET_MONTHLY_ACTUALS_STATUS_V2_LOADING_STATE = `${prefix}SET_MONTHLY_ACTUALS_STATUS_V2_LOADING_STATE`

export const FETCH_MONTHLY_PIPELINE_STATUS_V2 = `${prefix}FETCH_MONTHLY_PIPELINE_STATUS_V2`
export const SET_MONTHLY_PIPELINE_STATUS_V2 = `${prefix}SET_MONTHLY_PIPELINE_STATUS_V2`
export const SET_MONTHLY_PIPELINE_STATUS_V2_LOADING_STATE = `${prefix}SET_MONTHLY_PIPELINE_STATUS_V2_LOADING_STATE`

export const FETCH_DELTA_TRACKER_V2_DATA = `${prefix}FETCH_DELTA_TRACKER_V2_DATA`
export const SET_DELTA_TRACKER_V2_DATA = `${prefix}SET_DELTA_TRACKER_V2_DATA`

import { call, put } from 'redux-saga/effects'
import { setTeamWeeklyStatusData } from '@teamHome/actions/actions'
import { getMyTeamApi } from '@common/net'

import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchTeamWeekStatus = createSaga(function* fetchTeamWeekStatus({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const myTeamApi = yield getMyTeamApi()
  const statusData = yield call(
    myTeamApi.request('getTeamWeeklyStatus', { query: { teamId: payload.teamId, historyDate: payload.historyDate } }),
  )

  yield put(setTeamWeeklyStatusData(statusData))
})

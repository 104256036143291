import { handleActions } from 'redux-actions'

import * as constants from '@batchUpload/actions/actionTypes'
import { UploadState } from '@batchUpload/constants'

interface IBatchUploadState {
  errors: $TSFixMe[]
  uploadState: number
  isLoadedFile: boolean
  isValidFile: boolean
  selectedFile?: $TSFixMe | null
  rows: {
    uploaded: number
    total: number
  }
}

export const initialState: IBatchUploadState = {
  errors: [],
  uploadState: UploadState.READY,
  isLoadedFile: false,
  isValidFile: false,
  selectedFile: null,
  rows: {
    uploaded: 0,
    total: 0,
  },
}

const batchUploadReducer = handleActions<IBatchUploadState, $TSFixMe>(
  {
    [constants.SET_BATCH_FILE]: (state, action) => {
      const { selectedFile } = action.payload

      return {
        ...state,
        selectedFile,
        isLoading: true,
      }
    },

    [constants.SET_FILE_LOADED]: (state, action) => {
      return {
        ...state,
        isLoadedFile: action.payload,
        isLoading: false,
      }
    },

    [constants.SET_BATCH_FILE_UPLOAD_ERRORS]: (state, action) => {
      const { errors, rows, isValidFile } = action.payload

      return { ...state, errors, rows, isValidFile, isLoadedFile: true }
    },

    [constants.BATCH_FILE_UPLOAD_RESET]: (state) => ({
      ...state,
      errors: [],
      isLoadedFile: false,
      uploadState: UploadState.READY,
      isValidFile: false,
    }),

    [constants.SET_BATCH_UPLOAD_RESULT]: (state, action) => ({
      ...state,
      uploadState: action.payload,
    }),
  },
  initialState,
)

export default batchUploadReducer

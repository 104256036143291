import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@day1IssueKPIs/actions/actionTypes'

import { fetchDay1IssueKPIsData } from './fetchDay1IssueKPIsData'
import { fetchDay1IssueKPIsByTeamsData } from './fetchDay1IssueKPIsByTeamsData'

export default function* day1IssueKPIsSaga() {
  yield all([
    takeLatest(constants.FETCH_DAY1_ISSUE_KPI_DATA, fetchDay1IssueKPIsData),
    takeLatest(constants.FETCH_DAY1_ISSUE_KPI_BY_TEAMS_DATA, fetchDay1IssueKPIsByTeamsData),
  ])
}

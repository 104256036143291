import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class ConfigApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
  }

  getProgramSetup = () => {
    return this.call({ pathname: '/project-setup' })
  }

  createProgramSetup = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: '/project-setup' },
      {
        method: 'PUT',
        body,
      },
    )
  }

  getCompletenessStatuses = () => {
    return this.call({ pathname: '/step' })
  }

  reset = ({ query }: $TSFixMe) => {
    const requestData = {
      pathname: '/system/reset',
    } as $TSFixMe

    if (query) {
      requestData.query = query
    }

    return this.call(requestData, { method: 'PUT' })
  }

  getWelcomeNote = () => {
    return this.call({ pathname: '/welcome-note' })
  }

  createWelcomeNote = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: '/welcome-note' },
      {
        method: 'PUT',
        body,
      },
    )
  }

  getCoreComponentStatuses = () => {
    return this.call({ pathname: '/module-options' })
  }

  setCoreComponentStatuses = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: '/module-options' },
      {
        method: 'PUT',
        body,
      },
    )
  }

  launchConfig = () => {
    return this.call({ pathname: '/launch' }, { method: 'POST' })
  }
}

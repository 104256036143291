import { union } from 'lodash'
import { getOr, sortBy, isEmpty, isNil } from 'lodash/fp'
import * as teamConstants from '@generic/selectors/teamConstants'
import { teamTypesOrder, transformTeamType, userRolesOrder, userRoles, teams } from './constants'
import { routesList } from '@common/accessController/routesList'

export const findPermissionByRole = (permissionsList: $TSFixMe, role: $TSFixMe) => {
  return permissionsList.find((permission: $TSFixMe) => {
    if (teamConstants.CENTRAL_ROLES.includes(role?.teamType)) {
      let teamType = role?.teamType
      if (role?.role?.toLowerCase() === userRoles.MEMBER_READ_ONLY) {
        teamType = teams.CENTRAL
      }
      return teamType === permission?.name && permission?.role === role?.role?.toLowerCase()
    }

    return permission?.name === transformTeamType(role?.teamType) && permission?.role === role?.role?.toLowerCase()
  })
}

export const getAdminRoleData = (permissionsList: $TSFixMe, userAdminRoles: $TSFixMe) => {
  return permissionsList.find((permission: $TSFixMe) => permission.role === userAdminRoles[0].name.toLowerCase())
}

// union of permissions by user teams
export const unionUserTeamPermissions = (permissionsList: $TSFixMe, userRoles: $TSFixMe) => {
  if (isEmpty(userRoles) || isNil(userRoles)) return null

  const rolePrivileges = userRoles.reduce(
    (privilege: $TSFixMe, role: $TSFixMe) => {
      const rolePermissions = findPermissionByRole(permissionsList, role)

      const permissions = rolePermissions.permissions
        ? union([...privilege.permissions, ...rolePermissions.permissions])
        : privilege.permissions

      const exceptions = rolePermissions.exceptions
        ? union([...privilege.exceptions, ...rolePermissions.exceptions])
        : privilege.exceptions

      const redirects = rolePermissions.redirects ? rolePermissions.redirects : privilege.redirects

      return {
        permissions,
        exceptions,
        redirects,
      }
    },
    {
      permissions: [],
      exceptions: [],
      redirects: {},
    },
  )

  if (!rolePrivileges) return null

  return rolePrivileges
}

// union of permissions by admin roles
export const unionAdminRolePermissions = (permissionsList: $TSFixMe, userAdminRoles: $TSFixMe) => {
  if (isEmpty(userAdminRoles) || isNil(userAdminRoles)) return null

  const adminRoleData = getAdminRoleData(permissionsList, userAdminRoles)

  if (!adminRoleData) return null

  return adminRoleData
}

// union of permissions of all user roles (user teams, admin roles)
export const findPermissionByRoles = (permissionsList: $TSFixMe, user: $TSFixMe) => {
  const userRoles = getOr([], 'teams', user)
  const userAdminRoles = getOr([], 'roles', user)

  if (isEmpty(userRoles) && isEmpty(userAdminRoles)) {
    return {
      permissions: [],
      exceptions: [],
      redirects: {},
    }
  }

  const userTeamPermissions = unionUserTeamPermissions(permissionsList, userRoles)
  const adminRolePermissions = unionAdminRolePermissions(permissionsList, userAdminRoles)

  if (isNil(userTeamPermissions) && adminRolePermissions) {
    return adminRolePermissions
  }

  if (isNil(adminRolePermissions) && userTeamPermissions) {
    return userTeamPermissions
  }

  return {
    ...userTeamPermissions,
    permissions: union([...userTeamPermissions.permissions, ...adminRolePermissions.permissions]),
  }
}

export const sortRolesByPriority =
  (permissionsList: $TSFixMe) =>
  (...roles: $TSFixMe) => {
    const mappedRole1 = findPermissionByRole(permissionsList, roles[0])
    const mappedRole2 = findPermissionByRole(permissionsList, roles[1])

    return mappedRole1?.priority - mappedRole2?.priority
  }

// get highest user team role or admin role if user has no team roles
export const getHighestUserRoleInTeams = (user: $TSFixMe, permissionsList: $TSFixMe) => {
  /*
    sort user roles by priority
    check role priority at @common/accessController/permissions/index.md
  */
  const roles = [...getOr([], 'teams', user)].sort(sortRolesByPriority(permissionsList))
  const adminRoles = getOr([], 'roles', user)

  if (isEmpty(roles) && isEmpty(adminRoles)) {
    return {}
  }

  if (isEmpty(roles) && !isEmpty(adminRoles)) {
    return getAdminRoleData(permissionsList, adminRoles)
  }

  return roles?.[0] || {}
}

export const sortTeamRoles = sortBy([
  ({ teamType }) => teamTypesOrder.findIndex((type) => type === teamType),
  ({ role }) => userRolesOrder[role?.toLowerCase()],
  ({ longName }) => longName?.toLowerCase(),
])

export const REPORTERS_PAGES = [
  routesList.DASHBOARD.MY_TEAM.ROOT.title,
  routesList.TEAM_RESOURCES.ROOT.title,
  routesList.COMMUNICATIONS.ROOT.title,
]

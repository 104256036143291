import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class DayOneReadinessApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  getScenarios = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `teams/${query.teamId}/scenarios` })
  }

  getScenariosFromLibrary = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${query.teamId}/scenarios/library/${query.libraryId}`,
    })
  }

  addScenariosFromLibrary = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/scenarios/library/${query.libraryId}`,
      },
      {
        method: 'PUT',
        body,
      },
    )
  }

  createScenario = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/scenarios`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateScenario = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/scenarios/${query.scenarioId}`,
      },
      {
        method: 'PUT',
        body,
      },
    )
  }

  deleteScenario = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/scenarios/${query.scenarioId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }
}

import { createSaga } from '@common/sagaCreator/createSaga'
import { getDataRequestTrackerApi } from '@common/net'
import { call, put } from 'redux-saga/effects'
import {
  deleteDataRequestSuccess,
  setDataRequests,
  updateDataRequestSuccess,
} from '@teamResources/dataRequestTracker/actions/actions'
import { omit } from 'lodash'
import { parseLookupValue } from '@shared/Grid/utils/lookupCellUtils'
import { transformEmptyStringsToNull } from '@helpers/utils'

export const userFormatter = parseLookupValue('displayName')

export const formatDataRequestComplexValues = ({ teamProviding, requestor, sourceContact, ...data }: $TSFixMe) =>
  transformEmptyStringsToNull({
    ...omit(data, ['id', 'isNew', 'teamRequesting', 'dataRequestId', 'updatedAt', 'createdAt', 'dueDateChanged']),
    teamProviding: parseLookupValue('longName')(teamProviding),
    requestor: userFormatter(requestor),
    sourceContact: userFormatter(sourceContact),
  })

export const fetchDataRequests = createSaga(function* fetchDataRequests(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getDataRequestTrackerApi()

  const data = yield call(api.request('getDataRequests'))

  yield put(setDataRequests(data))
}, true)

export const createDataRequest = createSaga(function* createDataRequest({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data } = payload
  const api = yield getDataRequestTrackerApi()

  const { id, dataRequestId } = yield call(
    api.request('createDataRequest', {
      body: formatDataRequestComplexValues(data),
    }),
  )

  const createdDataRequest = { ...data, id, dataRequestId, isNew: false }

  yield put(updateDataRequestSuccess({ data: createdDataRequest, id: data.id }))
},
true)

export const updateDataRequest = createSaga(function* updateDataRequest({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data, id } = payload
  const api = yield getDataRequestTrackerApi()

  yield call(
    api.request('updateDataRequest', {
      query: {
        requestId: id,
      },
      body: formatDataRequestComplexValues(data),
    }),
  )
  yield put(updateDataRequestSuccess({ data, id }))
})

export const deleteDataRequest = createSaga(function* deleteDataRequest({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { id } = payload

  const api = yield getDataRequestTrackerApi()

  yield call(
    api.request('deleteDataRequest', {
      query: {
        requestId: id,
      },
    }),
  )

  yield put(deleteDataRequestSuccess({ id }))
},
true)

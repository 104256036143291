import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchControlPanelData = createAction(constants.FETCH_CONTROL_PANEL_DATA)
export const fetchControlPanelDataWithLoader = createAction(
  constants.FETCH_CONTROL_PANEL_DATA,
  null,
  getMetaWithActionLoading,
)
export const setControlPanelData = createAction(constants.SET_CONTROL_PANEL_DATA)

export const fetchMetricData = createAction(constants.FETCH_METRIC_DATA, null, getMetaWithActionLoading)
export const setMetricData = createAction(constants.SET_METRIC_DATA)

export const updateTaskMetricData = createAction(constants.UPDATE_TASK_METRIC_DATA)

import * as Yup from 'yup'
import { REQUIRED_FIELD_MESSAGE } from '@helpers/constants'

export const DataRequestSchema = Yup.object().shape({
  status: Yup.string().nullable().required(REQUIRED_FIELD_MESSAGE),
  teamRequestingId: Yup.number().nullable().required(REQUIRED_FIELD_MESSAGE),
  teamRequesting: Yup.object().nullable().required(REQUIRED_FIELD_MESSAGE),
  teamProviding: Yup.object().nullable().required(REQUIRED_FIELD_MESSAGE),
  topic: Yup.string().trim().nullable().required(REQUIRED_FIELD_MESSAGE),
  dateRequested: Yup.date().nullable().required(REQUIRED_FIELD_MESSAGE),
  dueDate: Yup.date().nullable().required(REQUIRED_FIELD_MESSAGE),
  requestor: Yup.object().nullable().required(REQUIRED_FIELD_MESSAGE),
})

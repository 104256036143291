import { GLOBAL_IMO, GLOBAL_SMO, teams, userRoles } from '@common/accessController/constants'
import { uppercaseAllWords } from '@helpers/utils'

export const GLOBAL_IMO_ROLES = [teams.IMO_GLOBAL, teams.SMO_GLOBAL]

export const GLOBAL_IMO_TEAM_NAMES = [GLOBAL_IMO, GLOBAL_SMO]

export const IMO_ROLES = [teams.IMO, teams.SMO]

export const ROLES_WITHOUT_VC = [userRoles.MEMBER_WITHOUT_VC, userRoles.RESTRICTED]

export const ALL_IMO_ROLES = [teams.IMO, teams.SMO, ...GLOBAL_IMO_ROLES]

export const GLOBAL_IMO_ROLES_WITH_ADMIN = [...GLOBAL_IMO_ROLES, userRoles.INSTANCE_ADMIN]

export const TEAM_MEMBER_ROLES = [
  userRoles.MEMBER,
  userRoles.MEMBER_WITH_VC,
  userRoles.MEMBER_WITH_VC_AND_ORG_DESIGN,
  userRoles.MEMBER_WITHOUT_VC,
  userRoles.MEMBER_READ_ONLY,
  userRoles.MEMBER_WITHOUT_VC_READ_ONLY,
  userRoles.RESTRICTED,
  userRoles.RESTRICTED_WITH_VC,
]

export const CENTRAL_ROLES = [teams.CENTRAL, teams.CENTRAL_VC, teams.CENTRAL_ORG_DESIGN]

export const INTEGRATION_ROLES = [teams.INTEGRATION, teams.SEPARATION]

export const CENTRAL_INTEGRATION_ROLES = [...CENTRAL_ROLES, ...INTEGRATION_ROLES]

export const USER_ADMIN = uppercaseAllWords(userRoles.USER_ADMIN)
export const CONFIG_ADMIN = uppercaseAllWords(userRoles.CONFIG_ADMIN)

export const ADMIN_ROLES = [USER_ADMIN, CONFIG_ADMIN]

export const teamConnections = {
  SECONDARY: 'secondary',
  PRIMARY: 'primary',
  CENTRAL: 'central',
}

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'

// api calls
export const watchProject = createAction(constants.WATCH_PROJECT)
export const unwatchProject = createAction(constants.UNWATCH_PROJECT)
export const fetchProjectWatchers = createAction(constants.FETCH_PROJECT_WATCHERS)

// store updates
export const setProjectWatchers = createAction(constants.SET_PROJECT_WATCHERS)
export const addProjectWatcher = createAction(constants.ADD_PROJECT_WATCHER)
export const removeProjectWatcher = createAction(constants.REMOVE_PROJECT_WATCHER)

/* eslint-disable no-console */
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import nanoid from 'nanoid'

import { getTenantName, userInfo as selectUserInfo } from '@generic/selectors'
import { getIsLaunched, getModules } from '@domain/instanceConfig/selectors'
import { getFeatureFlags, useFeatureFlags } from '@hooks/useFeatureFlags'

import { showWarningMessage } from '@generic/actions/actions'
import { actionTypes } from '@common/notifications/utils'

import { User } from '@common/types/dtos/UserDto'
import { sendDebugLog } from '../../ErrorBoundary/api'
import { useLocation } from 'react-router-dom'

function sanitizeUserInfo(userInfo: User) {
  return {
    id: userInfo?.id,
    isActive: userInfo?.isActive,
    isAdmin: userInfo?.isAdmin,
    firstTime: userInfo?.firstTime,
    hasRestrictedDomain: userInfo?.hasRestrictedDomain,
    teams: userInfo?.teams?.map((team) => ({
      id: team,
      role: team?.role,
      teamType: team?.teamType,
      teamDeliverablesInReview: team?.teamDeliverablesInReview,
      day1DeliverablesList: team?.day1DeliverablesList,
      reporterIds: team?.reporterIds,
      secondaryReporterIds: team?.secondaryReporterIds,
    })),
    roles: userInfo?.roles,
  }
}

export type Diagnostics = ReturnType<typeof getDiagnostics>

function getDiagnostics({
  id,
  tenantName,
  modules,
  isLaunched,
  pathname,
  userInfo,
  isProd,
}: {
  id: string
  tenantName: string
  modules: { enabled: boolean; name: string }[]
  isLaunched: boolean
  pathname: string
  userInfo: User
  isProd: boolean
}) {
  const tenant = sessionStorage.getItem('_mid-tenant')
  const tenantId = tenant ? JSON.parse(tenant)?.tenantId : undefined

  const debugInfo = {
    id,
    tenant: {
      id: tenantId,
      name: tenantName,
      launched: isLaunched,
      modules,
      featureFlags: getFeatureFlags(),
    },
    user: isProd ? sanitizeUserInfo(userInfo) : userInfo,
    page: {
      url: window.location.href,
      host: window.location.host,
      path: pathname,
    },
    browser: {
      // platform is deprecated but it is useful and hard to reproduce
      // so lets just take it if it's available
      platform: window?.navigator?.platform,
      userAgent: window?.navigator?.userAgent,
      cookieEnabled: window?.navigator?.cookieEnabled,
      screen: {
        height: window?.screen?.height,
        width: window?.screen?.width,
        orientation: window?.screen?.orientation?.type,
      },
    },
    timestamp: new Date().toISOString(),
  }

  return debugInfo
}

export function useGetDiagnostics(id: string) {
  const location = useLocation()
  const isLaunched = useSelector(getIsLaunched)
  const modules = useSelector(getModules)
  const userInfo = useSelector(selectUserInfo)
  const tenantName = useSelector(getTenantName)

  // Determine if we are in the production environment or not. In prod we want to
  // avoid logging any sensitive information into the the logs if possible.
  const isProd = !['development', 'qa', 'staging'].includes(window.NODE_ENV)

  return getDiagnostics({ id, tenantName, isLaunched, modules, userInfo, isProd, pathname: location.pathname })
}

export function useLogDiagnostics() {
  const location = useLocation()
  const isLaunched = useSelector(getIsLaunched)
  const modules = useSelector(getModules)
  const userInfo = useSelector(selectUserInfo)
  const dispatch = useDispatch()
  const tenantName = useSelector(getTenantName)

  const [isLoggingDiagnostics, setSendingDebug] = useState(false)
  const { isEnabled } = useFeatureFlags()

  // Determine if we are in the production environment or not. In prod we want to
  // avoid logging any sensitive information into the the logs if possible.
  const isProd = !['development', 'qa', 'staging'].includes(window.NODE_ENV)

  // Early return if feature flag is disabled
  if (!isEnabled('DEBUG_APP')) {
    return {}
  }

  const onLogDiagnostics = async () => {
    const debugId = nanoid()
    const debugInfo = getDiagnostics({
      id: debugId,
      tenantName,
      isLaunched,
      modules,
      userInfo,
      isProd,
      pathname: location.pathname,
    })

    console.log('====== Diagnostic Log =======')
    console.log('')

    console.log(`====== ID: ${debugInfo.id} ======`)

    // Copy and Pastable
    console.log('====== Copy and Paste Version: ======')
    console.log(JSON.stringify(debugInfo))
    console.log('')

    // So you can break it down easily in the browser for more tech savvy users
    console.log('====== Console Inspectable Version: ======')
    console.log(debugInfo)
    console.log('')

    console.log('============================')

    if (!isProd) {
      navigator?.clipboard?.writeText(JSON.stringify(debugInfo))
    }

    // Send off a request to put this in the logs
    setSendingDebug(true)

    try {
      // Fire API request
      await sendDebugLog(debugInfo)

      // Show success toast message
      dispatch(
        showWarningMessage({
          actionType: actionTypes.SUCCESS,
          errorMessage: `Diagnostic information has been logged successfully under ID:  ${debugInfo.id}`,
          timeout: 30000,
        }),
      )
    } catch (error) {
      // Show error toast message
      dispatch(
        showWarningMessage({
          actionType: actionTypes.FAIL,
          errorMessage: `Failed to log diagnostic information, please try again`,
          timeout: 5000,
        }),
      )
    } finally {
      setSendingDebug(false)
    }
  }

  return { onLogDiagnostics, isLoggingDiagnostics }
}

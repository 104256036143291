import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'
import { fetchMonthlyPipelineStatuses } from './fetchMonthlyPipelineStatuses'
import { fetchHeadcountReduction } from './fetchHeadcountReduction'
import { fetchOneTimeImplementationCosts } from './fetchOneTimeImplementationCosts'
import { fetchDeltaTrackerData } from './fetchDeltaTrackerData'
import { fetchMonthlyActualsStatusesV2, fetchMonthlyPipelineStatusesV2 } from './fetchMonthlyStatusesV2'
import { fetchDeltaTrackerV2Data } from './fetchDeltaTrackerV2Data'

export default function* initiativeListV2Saga() {
  yield all([
    takeLatest(constants.FETCH_HEADCOUNT_REDUCTION, fetchHeadcountReduction),
    takeLatest(constants.FETCH_ONE_TIME_IMPLEMENTATION_COSTS, fetchOneTimeImplementationCosts),
    takeLatest(constants.FETCH_MONTHLY_PIPELINE_STATUS, fetchMonthlyPipelineStatuses),
    takeLatest(constants.FETCH_DELTA_TRACKER_DATA, fetchDeltaTrackerData),
    takeLatest(constants.FETCH_MONTHLY_ACTUALS_STATUS_V2, fetchMonthlyActualsStatusesV2),
    takeLatest(constants.FETCH_MONTHLY_PIPELINE_STATUS_V2, fetchMonthlyPipelineStatusesV2),
    takeLatest(constants.FETCH_DELTA_TRACKER_V2_DATA, fetchDeltaTrackerV2Data),
  ])
}

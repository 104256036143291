/* eslint-disable react/require-default-props */
// Libs
import React, { createRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import { camelCase, isEmpty, first } from 'lodash/fp'
import { getUserDisplayName, portalContainer } from '@shared/Lookup/utils'
import { Position } from '@blueprintjs/core'

// Components
import { CreateItemOption, ItemRenderer } from './ItemRenderer'
import { ClearButton, StyledSuggest } from './Lookup.styles'
import { ENTER_KEY_CODE } from '@helpers/constants'

const getRenderer = (renderer: $TSFixMe, isSelected: $TSFixMe) => {
  return renderer === null
    ? (item: $TSFixMe, itemProps: $TSFixMe) => (
        <ItemRenderer
          item={item}
          itemProps={itemProps}
          isSelected={isSelected}
          key={`item-${camelCase(getUserDisplayName(item))}`}
        />
      )
    : renderer(isSelected)
}

function renderClearButton(isValue: $TSFixMe, handleDeselectValue: $TSFixMe) {
  return isValue && handleDeselectValue ? (
    <ClearButton icon="small-cross" size={14} onClick={handleDeselectValue} />
  ) : null
}

type SelectLookupProps = {
  itemsList: $TSFixMe[]
  selectedValue?: $TSFixMe
  activeItemText?: string
  filterItems: (...args: $TSFixMe[]) => $TSFixMe
  createNewItem?: (...args: $TSFixMe[]) => $TSFixMe
  handleSelectValue: (...args: $TSFixMe[]) => $TSFixMe
  handleDeselectValue?: (...args: $TSFixMe[]) => $TSFixMe
  customInputProps?: $TSFixMe
  customStyles?: $TSFixMe
  rowsToShow?: 3 | 5 | 10
  isMinimal?: boolean
  isCellEditorComponent?: boolean
  isSelected?: boolean | ((...args: $TSFixMe[]) => $TSFixMe)
  itemRenderer?: (...args: $TSFixMe[]) => $TSFixMe
  handleAddValue?: (...args: $TSFixMe[]) => $TSFixMe
}

const SelectLookup = ({
  itemsList,
  selectedValue,
  activeItemText,
  filterItems,
  createNewItem,
  handleSelectValue,
  handleDeselectValue,
  customInputProps,
  customStyles,
  isMinimal,
  rowsToShow,
  isSelected,
  itemRenderer,
  isCellEditorComponent,
  handleAddValue,
}: SelectLookupProps) => {
  const inputRef: React.RefObject<HTMLInputElement> = createRef()
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (!inputRef.current) return

    inputRef.current.focus()

    inputRef.current.addEventListener('keydown', onKeyDownInputProps.onKeyDown)

    return () => {
      inputRef.current?.removeEventListener('keydown', onKeyDownInputProps.onKeyDown)
    }
  }, [inputRef.current])

  const onKeyDownInputProps = {
    onKeyDown: (e: $TSFixMe) => {
      if (e.keyCode === ENTER_KEY_CODE) {
        const value = e.target.value?.trim()
        const result = handleAddValue?.(itemsList, value)

        if (!isEmpty(result)) {
          return handleSelectValue(first(result))
        }

        if (createNewItem && value) {
          handleSelectValue(createNewItem(value))
        }
      }
    },
  }

  return (
    <>
      <StyledSuggest
        activeItem={null}
        items={itemsList}
        onQueryChange={setQuery}
        query={query}
        itemRenderer={getRenderer(itemRenderer, isSelected)}
        itemPredicate={filterItems}
        //TODO remove name and surname when all owners will have same view
        inputValueRenderer={(item: $TSFixMe) => (item as $TSFixMe).name || (item as $TSFixMe).displayName}
        onItemSelect={handleSelectValue}
        createNewItemFromQuery={createNewItem}
        createNewItemRenderer={CreateItemOption}
        scrollToActiveItem={true}
        inputProps={{
          inputRef: inputRef,
          placeholder: activeItemText,
          ...customInputProps,
          ...(isCellEditorComponent ? onKeyDownInputProps : {}),
        }}
        popoverProps={
          {
            boundary: 'viewport',
            minimal: isMinimal,
            usePortal: true,
            position: Position.BOTTOM,
            portalClassName: classNames('single-lookup-popover', `height_${rowsToShow}`),
            wrapperTagName: 'div',
            targetTagName: 'div',
            portalContainer,
          } as $TSFixMe
        }
        selectedItem={selectedValue}
        data-cy={'lookup-single'}
        customStyles={customStyles.singleSelectStyles}
      />
      {renderClearButton(selectedValue && activeItemText, handleDeselectValue)}
    </>
  )
}

SelectLookup.defaultProps = {
  customInputProps: {},
  createNewItem: null,
  customStyles: {},
  activeItemText: '',
  rowsToShow: 10,
  isMinimal: false,
  isCellEditorComponent: false,
  isSelected: false,
  selectedValue: null,
  itemRenderer: null,
  handleDeselectValue: null,
}

export default SelectLookup

import { teams, userRoles, VALUE_CAPTURE, ORG_DESIGN } from '@common/accessController/constants'
import { intersection, matches, size } from 'lodash'
import {
  curry,
  flow,
  groupBy,
  sortBy,
  mapValues,
  uniqBy,
  pick,
  getOr,
  isEmpty,
  unionBy,
  map,
  values,
  orderBy,
  filter,
  get,
  flatten,
  find,
  isString,
} from 'lodash/fp'
import { DAY_ONE_ID, dictionariesNames, TSA_DELIVERABLES_IDS, TSA_V2_DELIVERABLES_IDS } from '@helpers/constants'
import { getUserDisplayName } from '@shared/Lookup/utils'
import * as teamConstants from './teamConstants'
import { GLOBAL_IMO_ROLES, IMO_ROLES, GLOBAL_IMO_TEAM_NAMES, teamConnections } from './teamConstants'
import { customColumnsFields } from '@myImoClient/components/TSAv2/shared/constants'

export const day1TsaDeliverables = [DAY_ONE_ID, ...TSA_DELIVERABLES_IDS, ...TSA_V2_DELIVERABLES_IDS]

/*
  checkers
*/

export const hasGlobalRole = (role: $TSFixMe, withAdmin = true) => {
  if (!role) return

  return (withAdmin ? teamConstants.GLOBAL_IMO_ROLES_WITH_ADMIN : teamConstants.GLOBAL_IMO_ROLES).includes(
    role.teamType,
  )
}

export const checkIsLeadRole = (role: $TSFixMe) => {
  if (!role) return

  return [userRoles.LEAD].includes(role.toLowerCase())
}

export const checkIsMemberWithoutVC = (role: $TSFixMe) => {
  if (!role) return

  return [userRoles.MEMBER_WITHOUT_VC].includes(role.toLowerCase())
}

export const hasMemberWithoutVC = (roles: $TSFixMe) => {
  if (!roles) return

  return roles.some(({ role }: $TSFixMe) => role.toLowerCase() === userRoles.MEMBER_WITHOUT_VC)
}

export const checkIsRestricted = (role?: $TSFixMe) =>
  [userRoles.RESTRICTED, userRoles.RESTRICTED_WITH_VC].includes(role?.toLowerCase())

export const checkIsRestrictedWithoutVC = (role?: $TSFixMe) => userRoles.RESTRICTED === role?.toLowerCase()

export const hasRestrictedRole = (roles: $TSFixMe) => {
  if (!roles) return

  return roles.some(({ role }: $TSFixMe) => checkIsRestricted(role))
}

// IMO teams checkers
export const hasImoRole = (role: $TSFixMe) => {
  if (!role) return

  return teamConstants.IMO_ROLES.includes(role.teamType)
}

export const hasOneOfImoRoles = (team: $TSFixMe) => {
  if (!team) return

  return teamConstants.ALL_IMO_ROLES.includes(team?.teamType)
}

export const checkIsIMOTeamType = (teamType: $TSFixMe) => teamConstants.ALL_IMO_ROLES.includes(teamType)

export const checkIsImoWithoutVCRole = (role: $TSFixMe) => role?.toLowerCase() === userRoles.MEMBER_WITHOUT_VC

export const checkIsImoWithoutVCReadOnlyRole = (role: $TSFixMe) =>
  role?.toLowerCase() === userRoles.MEMBER_WITHOUT_VC_READ_ONLY

export const checkIsGlobalRole = (role: $TSFixMe) => GLOBAL_IMO_TEAM_NAMES.includes(role?.longName)

export const checkHasGlobalRole = (roles: $TSFixMe) => {
  if (!roles) return

  return roles.some((role: $TSFixMe) => {
    return GLOBAL_IMO_TEAM_NAMES.includes(role?.longName)
  })
}

export const checkHasGlobalImoWithoutVCRole = (roles: $TSFixMe) => {
  if (!roles) return

  return roles.some((role: $TSFixMe) => {
    const isImoWithoutVCRole = checkIsImoWithoutVCRole(role?.role)
    const isImoWithoutVCRoleReadOnly = checkIsImoWithoutVCReadOnlyRole(role?.role)

    return (isImoWithoutVCRole || isImoWithoutVCRoleReadOnly) && checkIsGlobalRole(role)
  })
}

export const checkIsWithoutVCRole = (role: $TSFixMe) => role?.toLowerCase() === userRoles.MEMBER_WITHOUT_VC

export const checkIsReadOnlyRole = (role: $TSFixMe) =>
  [userRoles.MEMBER_WITHOUT_VC_READ_ONLY, userRoles.MEMBER_READ_ONLY].includes(role?.toLowerCase())

export const checkIsWithoutVCOrReadonly = (role: $TSFixMe) =>
  checkIsWithoutVCRole(role?.role) || checkIsReadOnlyRole(role?.role)

export const checkIsRoleWithVC = (role: $TSFixMe) =>
  !checkIsRestrictedWithoutVC(role) && !checkIsReadOnlyRole(role) && !checkIsWithoutVCRole(role)

export const checkIsRoleWithOD = (role: $TSFixMe, connection: string) =>
  [userRoles.MEMBER_WITH_VC_AND_ORG_DESIGN, userRoles.LEAD].includes(role?.toLowerCase()) ||
  (connection === teamConnections.CENTRAL && [userRoles.MEMBER, userRoles.LEAD].includes(role?.toLowerCase()))

export const checkIsVCRole = (role: $TSFixMe) => !checkIsWithoutVCRole(role?.role) && !checkIsReadOnlyRole(role?.role)

// check if team reports to responsible imo or central team
export const hasResponsibleImoTeam = (teamId: $TSFixMe, roles: $TSFixMe) => {
  if (!roles) return false

  return roles.some(({ teamType, reporterIds, secondaryReporterIds }: $TSFixMe) => {
    if (teamConstants.GLOBAL_IMO_ROLES.includes(teamType)) return true

    return (
      teamConstants.IMO_ROLES.includes(teamType) &&
      (reporterIds?.includes(teamId) || secondaryReporterIds?.includes(teamId))
    )
  })
}

// central teams checkers
export const hasCentralRole = (roles: $TSFixMe) => {
  if (!roles) return

  return roles.some(({ teamType }: $TSFixMe) => teamConstants.CENTRAL_ROLES.includes(teamType))
}

export const hasCentralWithoutReadOnlyRole = (roles: $TSFixMe) => {
  if (!roles) return

  return roles.some((role: $TSFixMe) => {
    return !checkIsReadOnlyRole(role.role) && role?.teamType === teams.CENTRAL_VC
  })
}

export const getCentralWithVCRole = (roles: $TSFixMe) => {
  if (!roles) return

  return roles.find((role: $TSFixMe) => {
    return !checkIsRestricted(role?.role) && !checkIsWithoutVCOrReadonly(role) && role?.teamType === teams.CENTRAL_VC
  })
}

// check if team reports to responsible central team
export const hasResponsibleCentralTeam = (teamId: $TSFixMe, roles: $TSFixMe) => {
  if (!roles) return false

  return roles.some((role: $TSFixMe) => {
    return (
      teamConstants.CENTRAL_ROLES.includes(role?.teamType) &&
      role?.revieweeTeams?.find((team: $TSFixMe) => team?.id === teamId)
    )
  })
}

export const checkIsIntegrationTeamWithoutVC = (roles: $TSFixMe, teamId: $TSFixMe) => {
  if (!roles) return false

  const currentRole = roles.find((role: $TSFixMe) => role?.id === teamId)

  if (!currentRole) return false

  return checkIsWithoutVCOrReadonly(currentRole) && teamConstants.INTEGRATION_ROLES.includes(currentRole?.teamType)
}

export const checkIsIntegrationCentralTeamWithVC = (roles: $TSFixMe, teamId: $TSFixMe) => {
  if (!roles) return false

  const currentRole = roles.find((role: $TSFixMe) => role?.id === teamId)

  if (!currentRole) return false

  return (
    !checkIsWithoutVCOrReadonly(currentRole) && teamConstants.CENTRAL_INTEGRATION_ROLES.includes(currentRole?.teamType)
  )
}

export const checkIsCentralTeamWithoutVC = (roles: $TSFixMe, teamId: $TSFixMe) => {
  if (!roles) return false

  const currentRole = roles.find((role: $TSFixMe) => role?.id === teamId)

  if (!currentRole) return false

  return checkIsWithoutVCOrReadonly(currentRole) && teamConstants.CENTRAL_ROLES.includes(currentRole?.teamType)
}

export const checkIsCentralTeamWithVC = (roles: $TSFixMe, teamId: $TSFixMe) => {
  if (!roles) return false

  const currentRole = roles.find((role: $TSFixMe) => role?.id === teamId)

  if (!currentRole) return false

  return !checkIsWithoutVCOrReadonly(currentRole) && teamConstants.CENTRAL_ROLES.includes(currentRole?.teamType)
}

export const isValueCaptureTeam = (team: $TSFixMe) => {
  return teams.CENTRAL === team?.teamType && team?.classification === VALUE_CAPTURE
}

export const isOrgDesignTeam = (team: $TSFixMe) => {
  return teams.CENTRAL === team?.teamType && team?.classification === ORG_DESIGN
}

export const isValueCaptureTeamAvailable = (allTeams: $TSFixMe) => {
  return allTeams.find(isValueCaptureTeam)
}

export const isOrgDesignTeamAvailable = (allTeams: $TSFixMe) => {
  return allTeams.find(isOrgDesignTeam)
}

// check if user has Team member (w/wo vc as well) / Team lead role in specific team
export const hasSpecificTeamRole = (roles: $TSFixMe, teamId: $TSFixMe) => {
  if (!roles || !teamId) return false

  return roles.some((role: $TSFixMe) => {
    return (
      [userRoles.MEMBER, userRoles.MEMBER_WITH_VC, userRoles.MEMBER_WITHOUT_VC, userRoles.LEAD].includes(
        role?.role?.toLowerCase(),
      ) && role?.id === teamId
    )
  })
}

/*
  Restricted user (of integration team) permissions check
*/

// if returns true - my team is unlocked (redirect or exception shouldn't be set)
export const hasRestrictedWithTSA = (roles: $TSFixMe) => {
  if (!roles) return false

  // check if restricted role exists and if tsa deliverables allowed
  // if no restricted users with tsa - redirect to Day1
  return roles.find(({ role, allowedDeliverables = [], id }: $TSFixMe) => {
    const hasTSADeliverables = size(intersection(allowedDeliverables, day1TsaDeliverables)) >= 2

    return checkIsRestricted(role) && (hasTSADeliverables || hasResponsibleImoTeam(id, roles))
  })
}

export const getSortedActiveUsersList = (userList: $TSFixMe) =>
  flow([
    values,
    map(({ id, isActive, ...user }) => ({
      displayName: getUserDisplayName(user),
      id,
      isActive,
    })),
    filter(get('isActive')),
    orderBy(({ displayName }) => displayName?.toLowerCase(), ['asc']),
  ])(userList)

export const checkUserRoles = (staticRoles: $TSFixMe) => (roles: $TSFixMe) => {
  return roles.some((role: $TSFixMe) => staticRoles.includes(role.name))
}

export const checkTeamMember = curry((nameTemplate: $TSFixMe, teams: $TSFixMe) =>
  teams.some(({ shortName }: $TSFixMe) => shortName === nameTemplate),
)

export const sortByName = sortBy(({ name }) => name.toLowerCase())

export const getGroupedRolesByLevel = (roles: $TSFixMe) =>
  flow([
    groupBy(({ teamType }) => {
      if (GLOBAL_IMO_ROLES.includes(teamType)) return teams.IMO_GLOBAL

      if (IMO_ROLES.includes(teamType)) return teams.IMO

      return teams.REPORTER
    }),
    mapValues(uniqBy(pick(['role', 'connection', 'parentRole']))),
  ])(roles)

export const getIsGlobalImoWithoutVC = (groupedRoles: $TSFixMe) => {
  const globalImoRoles = getOr([], teams.IMO_GLOBAL, groupedRoles)

  return !isEmpty(globalImoRoles) && globalImoRoles.some(checkIsWithoutVCOrReadonly)
}

export const getIsGlobalImoMember = (groupedRoles: $TSFixMe) => {
  const globalImoRoles = getOr([], teams.IMO_GLOBAL, groupedRoles)

  return !isEmpty(globalImoRoles) && globalImoRoles.some(checkIsVCRole)
}

const deliverableComparator = pick(['role', 'parentRole', 'teamId', 'deliverableId', 'parentLevel'])

export const unionTeamsDeliverables = unionBy(deliverableComparator)

const deliverableTypeComparator = pick(['role', 'parentRole', 'id', 'deliverableTypeIds', 'parentLevel'])

export const buildRoleInfoFromDeliverableData = curry((parentTeam: $TSFixMe, deliverable: $TSFixMe) => {
  const { parentRole, role, connection, parents = [] } = parentTeam

  const teamParents = [...parents, parentTeam]

  return {
    ...deliverable,
    parentRole: role || parentRole,
    id: deliverable.teamId,
    connection: connection || teamConnections.CENTRAL,
    parents: teamParents,
    parentLevel: teamParents?.length,
  }
})

export const buildTeamInfoObjectFromDeliverableTypeData = curry((parentTeam: $TSFixMe, deliverableType: $TSFixMe) => {
  const { parentRole, role, connection, parents = [] } = parentTeam

  const teamParents = [...parents, parentTeam]

  return {
    ...deliverableType,
    parentRole: role || parentRole,
    connection: connection || teamConnections.CENTRAL,
    parents: teamParents,
    parentLevel: teamParents.length,
  }
})

export const getReportersByDeliverableInReview = (roles: $TSFixMe, reporters: $TSFixMe) =>
  [...roles, ...reporters].reduce((deliverables, team) => {
    const { teamDeliverablesInReview } = team

    if (!teamDeliverablesInReview) return deliverables

    return unionTeamsDeliverables(deliverables, teamDeliverablesInReview.map(buildRoleInfoFromDeliverableData(team)))
  }, [])

export const getReportersByDeliverableTypesInReview = (roles: $TSFixMe, reporters = []) =>
  [...roles, ...reporters].reduce((deliverableTypes, team) => {
    const { revieweeTeams } = team

    if (!size(revieweeTeams)) return deliverableTypes

    return unionBy(
      deliverableTypeComparator,
      deliverableTypes,
      revieweeTeams.map(buildTeamInfoObjectFromDeliverableTypeData(team)),
    )
  }, [])

export const getLibraryGuidanceFilePath = (libraries: $TSFixMe, guidanceKey: $TSFixMe) => {
  const primaryLibrary = find('isPrimary', libraries)

  if (!primaryLibrary) return null

  const guidance = get(['guidance', guidanceKey, 'pdf', 'key'], primaryLibrary)

  const libGuidance = isString(guidance)
    ? {
        directory: 'shared',
        key: guidance.split('/').slice(1).join('/'),
      }
    : null

  if (!libGuidance) return null

  return libGuidance
}

export const getUniqTeamsIds = (teams: $TSFixMe) => uniqBy('id', flatten(teams)).map(({ id }: $TSFixMe) => id)

export const getTeamMentionsAsReporterByDeliverableTypeId = ({
  allReportersByDeliverableType,
  deliverableTypeId,
  teamId,
}: $TSFixMe) =>
  allReportersByDeliverableType.filter(
    ({ id, deliverableTypeIds }: $TSFixMe) => id === teamId && deliverableTypeIds.includes(deliverableTypeId),
  )

export const getTeamMentionsAsReporterByDeliverableId = ({
  allReportersByDeliverable,
  deliverableId,
  teamId,
}: $TSFixMe) => allReportersByDeliverable.filter(matches({ id: teamId, deliverableId }))

export const checkIsImoConnection = (connection: $TSFixMe) =>
  [teamConnections.PRIMARY, teamConnections.SECONDARY].includes(connection)

export const reservedColumnNames = ['category', 'priority']

export const filterAndDecorateColumns = (dictionaries: $TSFixMe, module: $TSFixMe) =>
  getOr([], module, dictionaries).reduce((acc: $TSFixMe, column: $TSFixMe, index: $TSFixMe) => {
    if (!reservedColumnNames.includes(column.name) && column.active) {
      let extendedColumn = column
      if (module === dictionariesNames.TSA) {
        const reviewStatusField = index === 0 && customColumnsFields.reviewStatus
        const typeOfServiceStatusField = index === 1 && customColumnsFields.typeOfService

        extendedColumn = { ...extendedColumn, field: reviewStatusField || typeOfServiceStatusField }
      }

      acc.push(extendedColumn)
    }

    return acc
  }, [])

export const getPriorityTeamByRole = (roleInfo: $TSFixMe) => {
  const { parents, team } = roleInfo

  return isEmpty(parents) ? team : parents[0]
}

import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'

interface IInitiativeWatchersState {
  [key: string]: $TSFixMe[]
}

export const initialState = {}

const initiativeWatchersReducer = handleActions<IInitiativeWatchersState, $TSFixMe>(
  {
    [constants.SET_INITIATIVE_WATCHERS]: (state, action) => {
      const { initiativeId, watchers } = action.payload

      return { ...state, [initiativeId]: watchers }
    },

    [constants.ADD_INITIATIVE_WATCHER]: (state, action) => {
      const { initiativeId, watcher } = action.payload

      return {
        ...state,
        [initiativeId]: state[initiativeId] ? [...state[initiativeId], watcher] : [watcher],
      }
    },

    [constants.REMOVE_INITIATIVE_WATCHER]: (state, action) => {
      const { initiativeId, userId } = action.payload

      if (!state[initiativeId]) {
        return state
      }

      const updatedWatchers = state[initiativeId].filter((watcher) => watcher.userId !== userId)

      return { ...state, [initiativeId]: updatedWatchers }
    },
  },
  initialState,
)

export default initiativeWatchersReducer

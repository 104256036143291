import { put, select, call, takeLatest, all } from 'redux-saga/effects'
import * as teamSetupConstants from '@myImoConfigActions/teamSetup/actionTypes'
import * as configConstants from '@myImoConfigActions/config/actionTypes'
import { getTeamSetupApi } from '@common/net'
import { validateTeamSetup } from '@helpers/validatorsConfig'
import * as selectors from '@domain/myImoConfig/selectors'
import { omit, mapValues } from 'lodash'
import { createSaga } from '@common/sagaCreator/createSaga'
import { handleForbiddenError } from '../utils'
import { errorType, ValidationError } from '@helpers/customErrors'
import { getManagementType } from '@domain/instanceConfig/selectors'
import { isSMO } from '@myImoConfig/containers/ConfigSteps/utils'
import { teamTitles } from '@common/accessController/constants'
import { NavigateFunction } from 'react-router-dom'

export const setupTeams = createSaga(function* setupTeams({
  payload: { navigate },
}: {
  payload: { navigate: NavigateFunction }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  try {
    const teamSetupApi = yield getTeamSetupApi()
    const data = yield select(selectors.getTeamSetup)

    yield call(validateTeamSetup, data)

    yield call(
      teamSetupApi.request('teamSetup', {
        body: mapValues(data, (teams) =>
          teams.map((t: $TSFixMe) => (t.isNew ? omit(t, ['isNew', 'id']) : omit(t, 'isNew'))),
        ),
      }),
    )

    yield put({ type: configConstants.SET_STEP_COMPLETED, payload: { no: 4 } })
    navigate('/config/team-imo-hierarchy')
  } catch (e) {
    const { name, message } = e as $TSFixMe
    if (name === errorType.VALIDATION) {
      const managementType = yield select(getManagementType)

      throw new ValidationError({
        message: message,
        props: { teamType: isSMO(managementType) ? teamTitles.SEPARATION : teamTitles.INTEGRATION },
      })
    }

    throw e
  }
},
true)

export const getTeams = createSaga(
  function* getTeams(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const teamsApi = yield getTeamSetupApi()
    const getTeamsData = yield call(teamsApi.request('getTeams'))

    yield put({ type: teamSetupConstants.GET_TEAMS_SUCCESS, payload: getTeamsData })
  },
  true,
  handleForbiddenError,
)

export function* teamSetupWatcher() {
  yield all([
    takeLatest(teamSetupConstants.SETUP_TEAMS, setupTeams),
    takeLatest(teamSetupConstants.GET_TEAMS, getTeams),
  ])
}

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'

import { fetchProjectWatchers, watchProject, unwatchProject } from './watchers'

export default function* projectWatchersSaga() {
  yield all([
    takeLatest(constants.WATCH_PROJECT, watchProject),
    takeLatest(constants.UNWATCH_PROJECT, unwatchProject),
    takeLatest(constants.FETCH_PROJECT_WATCHERS, fetchProjectWatchers),
  ])
}

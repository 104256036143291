import { get, isNil } from 'lodash'
import { isIncomingKeyProcess, isKeyProcess, isProject, isTask } from '@shared/DayOne/utils/getProcessStatus'
import { getNewRowData } from '@shared/DayOne/utils/handleAddNewRow'
import { getProjectPlanNewRow } from '@shared/ProjectPlan/utils/getProjectPlanNewRow'
import { listItemTypes } from '@shared/DayOne/constants'

export const getContextMenuItems = ({
  params: { api, node },
  addItemAction,
  deleteAction,
  deleteDetailsData,
  projectPlan,
}: $TSFixMe) => {
  const data = get(node, ['data'], null)

  if (!node || !node.isSelected()) {
    api.deselectAll()
    !!node && node.setSelected(true)
  }

  const selectedRows = api.getSelectedRows()

  if (selectedRows?.length > 1)
    return [
      {
        name: 'Delete rows',
        icon: '<span class="bp4-icon bp4-icon-minus" data-cy="context-menu-delete-multiple-items" />',
        action: () => {
          deleteAction(selectedRows, api)
          deleteDetailsData(selectedRows)
        },
        cssClasses: ['contextMenuItem'],
      },
    ]

  const allChildrenCount = get(node, ['allChildrenCount'], null)
  const itemsList = get(api.getModel(), 'rowsToDisplay', []).map((row: $TSFixMe) => row.data)

  const addAction = (type: $TSFixMe) =>
    projectPlan
      ? // @ts-expect-error check interface
        addItemAction(getProjectPlanNewRow(type, data, api, itemsList))
      : // @ts-expect-error check interface
        addItemAction(getNewRowData(type, data, itemsList))

  const items = {
    keyProcess: {
      add: {
        name: 'Add key process',
        icon: '<span class="bp4-icon bp4-icon-plus" data-cy="context-menu-add-key-process" />',
        action: () => addAction(listItemTypes.KEY_PROCESS),
        cssClasses: ['contextMenuItem'],
      },
      remove: {
        name: 'Delete key process',
        icon: '<span class="bp4-icon bp4-icon-minus" data-cy="context-menu-delete-key-process" />',
        action: () => {
          deleteAction([data], api)
          deleteDetailsData([data])
        },
        cssClasses: ['contextMenuItem'],
      },
    },
    project: {
      add: {
        name: 'Add project',
        icon: '<span class="bp4-icon bp4-icon-plus" data-cy="context-menu-add-project" />',
        action: () => addAction(listItemTypes.PROJECT),
        cssClasses: ['contextMenuItem'],
      },
      remove: {
        name: 'Delete project',
        icon: '<span class="bp4-icon bp4-icon-minus" data-cy="context-menu-delete-project" />',
        action: deleteAction.bind(null, [data], api),
        cssClasses: ['contextMenuItem'],
      },
    },
    task: {
      add: {
        name: 'Add task',
        icon: '<span class="bp4-icon bp4-icon-plus" data-cy="context-menu-add-task" />',
        action: () => addAction(listItemTypes.TASK),
        cssClasses: ['contextMenuItem'],
      },
      remove: {
        name: 'Delete task',
        icon: '<span class="bp4-icon bp4-icon-minus" data-cy="context-menu-delete-task" />',
        action: deleteAction.bind(null, [data], api),
        cssClasses: ['contextMenuItem'],
      },
    },
  }

  const { keyProcess, project, task } = items

  if (isNil(data) || isIncomingKeyProcess(data)) {
    const { add } = keyProcess

    return [add]
  }

  if (isKeyProcess(data)) {
    const { add, remove } = keyProcess
    const { add: addProject } = project

    return !allChildrenCount ? [add, remove, addProject] : [add, remove]
  }

  if (isProject(data)) {
    const { add, remove } = project
    const { add: addTask } = task

    return !allChildrenCount ? [add, remove, addTask] : [add, remove]
  }

  if (isTask(data)) {
    const { add, remove } = task

    return [add, remove]
  }

  return []
}

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchUserInitiativeFilterAndInitiatives = createAction(
  constants.FETCH_USER_INITIATIVE_FILTER_AND_INITIATIVES,
  null,
  getMetaWithActionLoading,
)

export const fetchUserInitiativeFilterAndInfinityList = createAction(
  constants.FETCH_USER_INITIATIVE_FILTER_AND_INFINITY_LIST,
  null,
  getMetaWithActionLoading,
)

export const setInitiativeListFilterState = createAction(constants.SET_INITIATIVE_LIST_FILTER_STATE)

export const setUserInitiativeFilter = createAction(constants.SET_USER_INITIATIVE_FILTER)

export const flushTableFilter = createAction(constants.FLUSH_TABLE_FILTER)

export const setSelectedUserInitiativeFilter = createAction(constants.SET_SELECTED_USER_INITIATIVE_FILTER)

export const addUserInitiativeFilter = createAction(constants.ADD_USER_INITIATIVE_FILTER)

export const changeUserInitiativeFilter = createAction(constants.CHANGE_USER_INITIATIVE_FILTER)

export const deleteUserInitiativeFilter = createAction(constants.DELETE_USER_INITIATIVE_FILTER)

export const setIsRedirect = createAction(constants.SET_IS_REDIRECT)

export const setTableFilters = createAction(constants.SET_TABLE_FILTERS, null, getMetaWithActionLoading)

import React, { forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@blueprintjs/core'
import { ActionItemCell, ActionText } from './index.styles'
import { getIsGridSuppressClickEdit } from '@imo/imo-ui-toolkit'

//TODO move to ui-toolkit
const CheckboxWithTextRenderer = forwardRef(({ value, disabled, api, setValue }, ref) => {
  const { text, isChecked } = value

  const isNotEditable = getIsGridSuppressClickEdit(api)

  const handleToggle = () =>
    setValue({
      isChecked: !isChecked,
      text,
    })

  useImperativeHandle(ref, () => {
    return {}
  })

  return (
    <ActionItemCell>
      <Checkbox
        checked={isChecked}
        onChange={handleToggle}
        className="action-item-checkbox"
        disabled={disabled || isNotEditable}
      />
      <ActionText>{text}</ActionText>
    </ActionItemCell>
  )
})

CheckboxWithTextRenderer.propTypes = {
  value: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  api: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
}

CheckboxWithTextRenderer.defaultProps = {
  disabled: false,
}

export default CheckboxWithTextRenderer

import { actions as midActions } from '@nvt/mid-react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SideBar from '../components'

const mapStateToProps = (state: $TSFixMe) => ({
  mid: state.mid,
})

const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  actions: bindActionCreators({ ...midActions }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)

import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { FilterContainer } from './styles'
import { get, isArray, isFunction } from 'lodash/fp'
import PropTypes from 'prop-types'
import {
  getRowFieldsValues,
  getValuesModel,
  isActiveFilter,
  renderFilterValues,
  setDeepValues,
  setModelValue,
} from '@shared/Grid/HierarchyFilter/utils'
import { capitalize } from 'lodash'

function formatFloatingFilters(project, task) {
  const projectFilters = Object.keys(project ?? {}).filter((key) => project[key])
  const taskFilters = Object.keys(task ?? {}).filter((key) => task[key])
  const projectFilterString = projectFilters.length
    ? `[Projects] ${projectFilters.map((filter) => capitalize(filter)).join(', ')}`
    : ''
  const taskFilterString = taskFilters.length
    ? `[Tasks] ${taskFilters.map((filter) => capitalize(filter)).join(', ')}`
    : ''

  return `${projectFilterString}${projectFilterString ? ' - ' : ''}${taskFilterString}`
}

export const HierarchyFloatingFilter = forwardRef((_, ref) => {
  const [filterValue, setFilterValue] = useState()

  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel) {
        if (!parentModel) {
          setFilterValue('')
        } else {
          setFilterValue(formatFloatingFilters(parentModel.value.project, parentModel.value.task))
        }
      },
    }
  })

  return <input style={{ width: '100%' }} disabled value={filterValue} />
})

const HierarchyFilter = forwardRef((props, ref) => {
  const { model, filterChangedCallback, api, height, doesFilterPass, doesRowPassOtherFilter } = props
  const [selected, setSelected] = useState({})

  useEffect(() => {
    const valuesModel = isActiveFilter(selected)
      ? getValuesModel({ api, model, doesRowPassOtherFilter, selected, defaultValue: false })
      : getValuesModel({ api, model, doesRowPassOtherFilter })

    setSelected(valuesModel)
  }, [])

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(
    ref,
    () => {
      return {
        doesFilterPass(row) {
          if (isFunction(doesFilterPass)) return doesFilterPass({ row, model, selected })

          const { data } = row
          const rowValues = getRowFieldsValues(data, model)

          return get(rowValues, selected)
        },

        isFilterActive() {
          return isActiveFilter(selected)
        },

        getModel() {
          return { value: selected }
        },

        setModel(filterModel) {
          if (!isArray(filterModel)) return

          const dataModel = getValuesModel({ api, model, doesRowPassOtherFilter })

          const newModel = filterModel.reduce(
            (result, [path, value]) => setModelValue(result, path, value),
            setDeepValues(dataModel, false),
          )

          setSelected(newModel)
        },

        getModelAsString() {
          if (!isActiveFilter(selected)) {
            return ''
          }

          return formatFloatingFilters(selected.project, selected.task)
        },
      }
    },
    [selected],
  )

  const onChange = (e) => {
    const { target } = e
    const path = JSON.parse(get(['target', 'dataset', 'path'], e))
    const { checked } = target

    setSelected(setModelValue(selected, path, checked))
  }

  useEffect(() => {
    filterChangedCallback()
  }, [selected])

  return <FilterContainer height={height}>{renderFilterValues({ obj: selected, onChange, model })}</FilterContainer>
})

HierarchyFilter.defaultProps = {
  height: '216px',
  doesFilterPass: null,
}

HierarchyFilter.propTypes = {
  filterChangedCallback: PropTypes.func.isRequired,
  model: PropTypes.array.isRequired,
  api: PropTypes.object.isRequired,
  height: PropTypes.string,
  doesFilterPass: PropTypes.func,
  doesRowPassOtherFilter: PropTypes.func.isRequired,
}

export default HierarchyFilter

export const actionTypes = {
  SUCCESS: 'success',
  FAIL: 'fail',
  INFO: 'info',
  WARNING: 'warning',
  VALIDATION_FAIL: 'validation fail',
  SNAPSHOT_NOT_FOUND: 'snapshot not found',
  TIMEOUT: 'timeout',
}

export type ActionTypes = keyof typeof actionTypes

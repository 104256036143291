/* eslint-disable react/forbid-dom-props */
import React from 'react'
import { VCControllerIDs } from '@myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/TeamValueCaptureController/utils'
import { greyForBtn, ImoIcon } from '@imo/imo-ui-toolkit'
import { IconText } from './index.styles'
import { values } from 'lodash/fp'
import { IAppliedFiltersConfig } from '@myImoClient/components/Header/AppliedFilters/interfaces'
import { changeActiveFlag } from '@myImoClient/components/Header/AppliedFilters/utils'

const TeamVCRoutes = {
  LIST: 'initiative-list',
  SUMMARY_STATUS: 'status-update',
  MONTH_BY_MONTH: 'month-by-month',
  PROJECT_MAP: 'project-map',
}

const VCTrackerRoutes = {
  LATE_OR_AT_RISK: 'late-or-at-risk',
  SUMMARY_STATUS: 'summary-status',
  MONTH_BY_MONTH: 'month-by-month',
  ALL_INITIATIVES: 'all-initiatives',
  PIPELINE_BY_TEAM: 'pipeline-by-team',
  PIPELINE_BY_YEAR: 'pipeline-by-year',
  CXO_DASHBOARD: 'cxo-dashboard',
}

export const appliedFiltersConfig: IAppliedFiltersConfig[] = [
  {
    id: VCControllerIDs.MIRRORED_INITIATIVES,
    icon: (color) => <ImoIcon icon="mirror-out" size={16} color={color || greyForBtn} />,
    advancedFinancialsOnly: true,
    isActive: false,
    teamVCPages: [TeamVCRoutes.LIST, TeamVCRoutes.SUMMARY_STATUS, TeamVCRoutes.MONTH_BY_MONTH],
    VCTrackerPages: [
      VCTrackerRoutes.LATE_OR_AT_RISK,
      VCTrackerRoutes.SUMMARY_STATUS,
      VCTrackerRoutes.ALL_INITIATIVES,
      VCTrackerRoutes.PIPELINE_BY_TEAM,
      VCTrackerRoutes.PIPELINE_BY_YEAR,
      VCTrackerRoutes.CXO_DASHBOARD,
    ],
  },
  {
    id: VCControllerIDs.FTE_DASHBOARD,
    icon: (color) => <ImoIcon icon="people" color={color || greyForBtn} size={16} />,
    isActive: false,
    teamVCPages: [TeamVCRoutes.MONTH_BY_MONTH],
    VCTrackerPages: [VCTrackerRoutes.MONTH_BY_MONTH],
  },
  {
    id: VCControllerIDs.SHARED_TARGET,
    icon: (color) => <ImoIcon icon="intersection" size={16} color={color || greyForBtn} />,
    advancedFinancialsOnly: true,
    isActive: false,
    teamVCPages: [],
    VCTrackerPages: [
      VCTrackerRoutes.PIPELINE_BY_TEAM,
      VCTrackerRoutes.PIPELINE_BY_YEAR,
      VCTrackerRoutes.MONTH_BY_MONTH,
    ],
  },
  {
    id: VCControllerIDs.CONFIDENTIAL,
    icon: (color, text) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ImoIcon icon="confidential" size={16} color={color || greyForBtn} />
        <IconText color={color}>: {text}</IconText>
      </div>
    ),
    isActive: false,
    teamVCPages: values(TeamVCRoutes),
    VCTrackerPages: values(VCTrackerRoutes),
  },
]

export const renderIcon =
  (toggleSettings: $TSFixMe, isAdvancedFinancialsTurnedOn: boolean): $TSFixMe =>
  (filterList: IAppliedFiltersConfig[]) =>
    changeActiveFlag({ toggleSettings, isAdvancedFinancialsTurnedOn, filterList })

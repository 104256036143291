export enum ORG_DESIGN_PHASES {
  Design = 'Design',
  Selection = 'Selection',
  Implementation = 'Implementation',
}

export enum DESIGN_PHASE_STAGES {
  ToDo = 'To do',
  InProgress = 'In progress',
  ReadyForReview = 'Ready for review',
  FinalSubmitted = 'Final (submitted)',
  FinalApproved = 'Final (approved)',
}

export enum SELECTION_PHASE_STAGES {
  ToDo = 'To do',
  InProgress = 'In progress',
  ReadyForReview = 'Ready for review',
  FinalSubmitted = 'Final (submitted)',
  FinalApproved = 'Final (approved)',
}

export enum IMPLEMENTATION_PHASE_STAGES {
  ToDo = 'To do',
  TransitionPlan = 'Transition plan',
  OneToOneCommunication = '1:1 communication',
  Announcement = 'Announcement',
  ImplementationSubmitted = 'Implementation (submitted)',
  ImplementationApproved = 'Implementation (approved)',
}

export type LAYER_STAGES = {
  designStage: DESIGN_PHASE_STAGES
  selectionStage: SELECTION_PHASE_STAGES
  implementationStage: IMPLEMENTATION_PHASE_STAGES
}

export enum ORG_DESIGN_STATUSES {
  Locked = 'Locked',
  NotStarted = 'Not Started',
  OnTrack = 'On Track',
  AtRisk = 'At Risk',
  Delayed = 'Delayed',
  Completed = 'Completed',
}

export enum OD_STATUS_REASONS {
  DueDate = 'Due Date',
  DeltaSynergy = 'Delta Synergy',
}

interface LayerBase {
  id: number
  teamId: number
  orgDesignTeamLayerId: number
  orgDesignTeamLayerName: string
  orgDesignTeamLayerShortName: string
  startDate: string
  description: string | null
  teamMembers: null | { displayName?: string; id?: number }[]
  team: { longName: string; shortName: string }
  hierarchy?: string[]
  teamStatus: string | null
  teamStatusRationale: string | null
  hasOrgCharts: boolean
}

export interface DesignLayer extends LayerBase {
  fullBaselineFTE: number | null
  fullBaselineCost: number | null
  designFTE: number | null
  designCost: number | null
  plannedSynergiesFTE: number | null
  plannedSynergiesCost: number | null
  designStage: DESIGN_PHASE_STAGES
  designOwner: { displayName?: string; id?: number } | null
  designStatus: ORG_DESIGN_STATUSES
  designDueDate: string
}

export interface SelectionLayer extends LayerBase {
  selectionStage: SELECTION_PHASE_STAGES
  internalFTE: number | null
  internalCost: number | null
  terminationsFTE: number | null
  terminationsCost: number | null
  peopleWaitingDecisionFTE: number
  peopleWaitingDecisionCost: number
  positionsToFillFTE: number
  positionsToFillCost: number
  forecastSynergiesFTE: number
  forecastSynergiesCost: number
  deltaSynergiesFTE: number
  deltaSynergiesCost: number
  fullBaselineFTE: number | null
  fullBaselineCost: number | null
  selectionOwner: { displayName?: string; id?: number } | null
  selectionStatus: ORG_DESIGN_STATUSES
  selectionDueDate: string
}

export interface ImplementationLayer extends LayerBase {
  selectionsFTE: number | null
  communicationsRequired: number | null
  communicationsMade: number | null
  announcementCompleted: boolean
  announcementPlanDate: string
  transitionExitWindowStart: string
  transitionExitWindowEnd: string
  fullBaselineFTE: number | null
  fullBaselineCost: number | null
  implementationStage: IMPLEMENTATION_PHASE_STAGES
  implementationOwner: { displayName?: string; id?: number } | null
  implementationStatus: ORG_DESIGN_STATUSES
  selectionDueDate: string
  implementationDueDate: string
}

export interface SelectedLayer {
  teamOrgDesignLayerId: number
  teamId: number
  layerName: string
  designFTE?: number | null
  designCost?: number | null
  internalFTE?: number | null
  internalCost?: number | null
  terminationsFTE?: number | null
  terminationsCost?: number | null
  peopleWaitingDecisionFTE?: number
  positionsToFillFTE?: number
  announcementCompleted?: boolean
  dueDate: string
  designStatus?: ORG_DESIGN_STATUSES
  selectionStatus?: ORG_DESIGN_STATUSES
  implementationStatus?: ORG_DESIGN_STATUSES
  designStage?: DESIGN_PHASE_STAGES
  selectionStage?: SELECTION_PHASE_STAGES
  implementationStage?: IMPLEMENTATION_PHASE_STAGES
  linkedInitiative?: {
    id: number
    name: string
    stageGate: {
      level: number
      status?: string
    }
    linkedProject?: {
      id: number
      name: string
      status: string
      projectListId: string
    }
  }
}

export type AllPhasesLayer = DesignLayer & SelectionLayer & ImplementationLayer

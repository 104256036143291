import { combineReducers } from 'redux'
import { all } from 'redux-saga/effects'
import teamSetupSaga from '@domain/myImoClient/valueCaptureV2/teamSetup/sagas'
import teamSetupReducer from './teamSetup/reducers/reducer'
import summaryStatusSaga from '@domain/myImoClient/valueCaptureV2/summaryStatus/sagas'
import summaryStatusReducer from '@valueCaptureTrackerV2/summaryStatus/reducers'
import lateOrAtRiskSaga from '@domain/myImoClient/valueCaptureV2/lateOrAtRisk/sagas'
import lateOrAtRiskReducer from './lateOrAtRisk/reducers/reducer'
import pipelineByTeamReducer from './pipelineByTeam/reducers/reducer'
import pipelineByTeamSaga from '@valueCaptureTrackerV2/pipelineByTeam/sagas'
import cxoDashboardSaga from '@domain/myImoClient/valueCaptureV2/cxoDashboard/sagas'
import cxoDashboardReducer from './cxoDashboard/reducers/reducer'

export const valueCaptureV2Reducer = combineReducers({
  teamSetup: teamSetupReducer,
  summaryStatus: summaryStatusReducer,
  lateOrAtRisk: lateOrAtRiskReducer,
  pipelineByTeam: pipelineByTeamReducer,
  cxoDashboard: cxoDashboardReducer,
})

export function* valueCaptureV2Sagas() {
  yield all([teamSetupSaga(), summaryStatusSaga(), lateOrAtRiskSaga(), pipelineByTeamSaga(), cxoDashboardSaga()])
}

import { createSelector } from 'reselect'
import { flow, get, getOr, identity } from 'lodash/fp'
import { IDayOneState, initialState } from '../reducers/reducer'
import { mapDayOneData, getDeletionData } from '@shared/DayOne/utils'
import { getRowNodeIdProcessItem, getSelectedProcessData } from '@shared/DayOne/utils/mapDayOneData'
import type { RootState } from '@store/index'

export const clientState = (state: RootState): IDayOneState => state['@dayOne'] || initialState

const dayOneProjectList = (state: RootState) => clientState(state).dayOneProjectList
const projectListMeta = (state: RootState) => clientState(state).projectListMeta
export const getDayOneProjectList = createSelector(dayOneProjectList, identity)
export const getProjectListMeta = createSelector(projectListMeta, identity)
export const getSnapshotMinDate = createSelector(clientState, ({ snapshotMinDate }) =>
  snapshotMinDate ? new Date(snapshotMinDate) : new Date(),
)

export const getFilterState = (state: RootState) => clientState(state).filter

export const getIsLockedDayOneProjectList = createSelector(clientState, (state) => state.isLockedDayOneProjectList)

export const selectedProcessId = (state: RootState) => clientState(state).selectedProcessId
export const getSelectedProcessId = createSelector(selectedProcessId, identity)

export const getSelectedProcess = createSelector(
  [clientState, (state: IDayOneState, info: { id: number; type: 'task' | 'project'; incoming: boolean }) => info],
  (state, info) =>
    state.dayOneProjectList.find((item) =>
      info.incoming ? item.isIncoming && item.id === info.id : item.id === info.id && item.type === info.type,
    ),
)
/**
 * TODO: investigate this issue
 * approach `createSelector(dayOneProjectList, mapDayOneData)` breaks a unit test
 *
 * FAIL src/domain/myImoClient/myTeam/teamHome/sagas/teamHome.spec.ts
 * ReferenceError: Cannot access '_mapDayOneData' before initialization
 *  */
export const getDayOneProjectListForGrid = createSelector(dayOneProjectList, (list) => mapDayOneData(list))

export const getLibraryProjectList = createSelector(clientState, getOr([], ['libraryProjectList']))
export const getLibraryProjectListState = createSelector(clientState, get('libraryProjectListState'))

export const processesFromLibrary = flow(clientState, get('libraryProjectList'))
export const getProcessesFromLibrary = createSelector(processesFromLibrary, identity)

// @ts-expect-error fix types
const getDayOneSelectedProjectData = getSelectedProcessData(getRowNodeIdProcessItem)

const getProgramPlanSelectedProjectData = getSelectedProcessData(getRowNodeIdProcessItem, true)

export const getDayOneAsidePanelData = createSelector(
  getDayOneProjectListForGrid,
  getSelectedProcessId,
  getDayOneSelectedProjectData,
)

export const getProgramPlanAsidePanelData = createSelector(
  getDayOneProjectListForGrid,
  getSelectedProcessId,
  getProgramPlanSelectedProjectData,
)

export const getItemsToDelete = createSelector(
  getDayOneProjectList,
  (_: $TSFixMe, rowsToDelete: $TSFixMe) => rowsToDelete,
  (items, rowsToDelete) => getDeletionData(rowsToDelete, items),
)

export const getProcessById = createSelector(
  getDayOneProjectList,
  (_: $TSFixMe, selectedProcessId: $TSFixMe) => selectedProcessId,
  (items: $TSFixMe, selectedProcessId) =>
    items.find((item: $TSFixMe) => getRowNodeIdProcessItem(item) === selectedProcessId),
)

export const imoTeamHasLinkedLibraries = createSelector(clientState, get('imoTeamHasLinkedLibraries'))

export const getVisibleRowId = createSelector(clientState, get('visibleRowId'))

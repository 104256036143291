import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  StyledDialog,
  TopErrorBlock,
  StyledText,
  StyledBody,
  StyledFooter,
  FooterActions,
  ScrolledErrorBlock,
} from './index.styles'
import nanoid from 'nanoid'
import { Button, Intent } from '@blueprintjs/core'
import { LogoBlack } from '@shared/Logo'
import { noop } from 'lodash'
import { getTenantName } from '@generic/selectors'
import { sendErrorLog } from './api'
import sendErrorMail from './utils'
import { useGetDiagnostics } from '../Sider/components/useLogDiagnostics'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

interface Props {
  hasError?: boolean
  message?: string | null
  errorStack?: string | null
  componentStack?: string | null
  onClose?: (...args: $TSFixMe[]) => $TSFixMe
  resetErrorState: (...args: $TSFixMe[]) => $TSFixMe
}

const ErrorDialog = ({ hasError, message, errorStack, componentStack, onClose, resetErrorState }: Props) => {
  const tenantName = useSelector(getTenantName)
  const errorId = nanoid()
  const { t } = useTranslation()
  const location = useLocation()
  const initialErrorUrl = useRef(location.pathname)
  const pageUrl = window.location.href

  useEffect(() => {
    const isUrlChanged = location.pathname !== initialErrorUrl.current

    if (isUrlChanged) resetErrorState()
  }, [location.pathname])

  const diagnostics = useGetDiagnostics(errorId)

  // Fire off the error log when the error happens
  useEffect(() => {
    sendErrorLog({
      ...diagnostics,
      error: {
        message: message ? message : undefined,
        errorStack: errorStack ? errorStack : undefined,
        componentStack: componentStack ? componentStack : undefined,
      },
    })
  }, [])

  const handleSendError = () => {
    sendErrorMail({ errorId, message, errorStack, componentStack, pageUrl, tenantName })
  }

  return (
    <StyledDialog title="An error has occurred" isOpen={hasError} isCloseButtonShown={false}>
      <StyledBody>
        <TopErrorBlock>
          <LogoBlack />
          <StyledText>
            <p>{t('ERROR_DIALOG.MESSAGE')}</p>
            <p>
              {t('ERROR_DIALOG.QUOTE_ERROR_ID')}: <strong>{errorId}</strong>
            </p>
          </StyledText>
        </TopErrorBlock>
        <ScrolledErrorBlock>
          {message && <p>{message}</p>}
          {errorStack && (
            <>
              <strong>{t('ERROR_DIALOG.ERROR_STACK')}</strong>: {errorStack}
            </>
          )}
          {componentStack && (
            <>
              <br />
              <strong>{t('ERROR_DIALOG.COMPONENT_STACK')}</strong>: {componentStack}
            </>
          )}
          <br />
          <strong>{t('ERROR_DIALOG.PAGE_URL')}</strong>: {pageUrl}
          <br />
          <strong>{t('ERROR_DIALOG.ERROR_ID')}</strong>: {errorId}
        </ScrolledErrorBlock>
        <StyledText>{t('ERROR_DIALOG.PLEASE_CLICK')}</StyledText>
      </StyledBody>
      <StyledFooter>
        <FooterActions>
          <Button onClick={onClose}>{t('ERROR_DIALOG.REFRESH_PAGE')}</Button>
          <Button intent={Intent.PRIMARY} onClick={handleSendError}>
            {t('ERROR_DIALOG.SEND_REPORT')}
          </Button>
        </FooterActions>
      </StyledFooter>
    </StyledDialog>
  )
}

ErrorDialog.defaultProps = {
  hasError: false,
  message: '',
  errorStack: '',
  componentStack: '',
  onClose: noop,
}

export default ErrorDialog

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'

// api calls
export const watchInitiative = createAction(constants.WATCH_INITIATIVE)
export const unwatchInitiative = createAction(constants.UNWATCH_INITIATIVE)
export const fetchInitiativeWatchers = createAction(constants.FETCH_INITIATIVE_WATCHERS)

// store updates
export const setInitiativeWatchers = createAction(constants.SET_INITIATIVE_WATCHERS)
export const addInitiativeWatcher = createAction(constants.ADD_INITIATIVE_WATCHER)
export const removeInitiativeWatcher = createAction(constants.REMOVE_INITIATIVE_WATCHER)

import * as constants from '@domain/myImoClient/myTeam/knowledgeCenter/actions/actionTypes'
import { actionTypes } from '@common/notifications/utils'

const successfulVideoUploadMessage = 'The video is successfully uploaded'
const successfulArticleUploadMessage = 'The article is successfully uploaded'
const successfulIntroductionMaterialUploadMessage = 'The file is successfully uploaded'
const failMessage = 'Something went wrong, please try again later'

export default {
  [constants.CREATE_NEW_KNOWLEDGE_CENTER_VIDEO]: {
    [actionTypes.SUCCESS]: [successfulVideoUploadMessage],
    [actionTypes.FAIL]: [failMessage],
  },
  [constants.UPDATE_KNOWLEDGE_CENTER_VIDEO]: {
    [actionTypes.SUCCESS]: [successfulVideoUploadMessage],
    [actionTypes.FAIL]: [failMessage],
  },
  [constants.CREATE_NEW_KNOWLEDGE_CENTER_ARTICLE]: {
    [actionTypes.SUCCESS]: [successfulArticleUploadMessage],
    [actionTypes.FAIL]: [failMessage],
  },
  [constants.UPDATE_KNOWLEDGE_CENTER_ARTICLE]: {
    [actionTypes.SUCCESS]: [successfulArticleUploadMessage],
    [actionTypes.FAIL]: [failMessage],
  },
  [constants.CREATE_NEW_INTRODUCTION_MATERIAL]: {
    [actionTypes.SUCCESS]: [successfulIntroductionMaterialUploadMessage],
    [actionTypes.FAIL]: [failMessage],
  },
  [constants.UPDATE_INTRODUCTION_MATERIAL]: {
    [actionTypes.SUCCESS]: [successfulIntroductionMaterialUploadMessage],
    [actionTypes.FAIL]: [failMessage],
  },
}

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchKeyProcesses = createAction(constants.FETCH_KEY_PROCESSES, null, getMetaWithActionLoading)
export const setKeyProcesses = createAction(constants.SET_KEY_PROCESSES)

export const createKeyProcess = createAction(constants.CREATE_KEY_PROCESS)
export const createKeyProcessSuccess = createAction(constants.CREATE_KEY_PROCESS_SUCCESS)

export const updateKeyProcess = createAction(constants.UPDATE_KEY_PROCESS)
export const updateKeyProcessSuccess = createAction(constants.UPDATE_KEY_PROCESS_SUCCESS)

export const deleteKeyProcess = createAction(constants.DELETE_KEY_PROCESS)
export const deleteKeyProcessSuccess = createAction(constants.DELETE_KEY_PROCESS_SUCCESS)

import { createSaga } from '@common/sagaCreator/createSaga'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
  createActionMap,
  deleteActionMap,
  fetchProgramProjectPlanData,
  fetchProjectList,
  removeListItem,
  silentUpdateActionMap,
  updateActionMap,
  updateDayOneItemIDSuccess,
  updateDayOneItemSuccess,
  updatePredecessorFollowerSuccess,
} from '@dayOne/actions/actions'
import * as constants from '@dayOne/actions/actionTypes'
import { getSelectedImoTeamId } from '@generic/selectors/selectedTeams'
import { omit } from 'lodash'
import { getDayOneApi } from '@common/net'
import { getProcessById } from '@dayOne/selectors'
import { getRowNodeIdProcessItem } from '@shared/DayOne/utils/mapDayOneData'
import { checkIsUserOnlyMember, shouldRefreshAfterUpdate, updatePredecessorOrFollowerChecker } from '@dayOne/utils'
import { updateCustomColumn } from '@dayOne/sagas/byTypes/updateCustomColumn'
import { get } from 'lodash/fp'

export const updateDayOneProcessItem = createSaga(function* updateDayOneProcessItem(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data, rowNodeId, withInterdependency, isMirroredInterdependency, refresh, customColumn } = action.payload

  const oldData = yield select((state) => getProcessById(state, rowNodeId))
  const oldInterdependencyData = yield select((state) => getProcessById(state, withInterdependency))
  const isDueDataChanged = oldData?.dueDate !== data.dueDate
  const shouldRefresh = yield call(shouldRefreshAfterUpdate, data, oldData, isMirroredInterdependency)
  const onSuccess = function* (successData: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const isDueDataChanged = successData?.payload?.isDueDataChanged
    const forecastDate = successData?.payload?.forecastDate

    if (isDueDataChanged) {
      return yield put(updateDayOneItemSuccess({ data: { forecastDate }, rowNodeId }))
    }

    if (shouldRefresh && refresh) return yield call(refresh, true)

    const updatedPredecessorFollower = yield updatePredecessorOrFollowerChecker(oldData, data)

    if (updatedPredecessorFollower) {
      const getRelated = get(updatedPredecessorFollower)
      const getRelatedId = get(`${updatedPredecessorFollower}Id`)
      const oldDataId = getRelatedId(oldData)

      yield put(
        updatePredecessorFollowerSuccess({
          oldRelated: oldDataId ? { id: oldDataId } : null,
          newRelated: getRelated(data),
          relation: updatedPredecessorFollower,
          data,
        }),
      )
    }

    yield put(updateDayOneItemSuccess({ data, rowNodeId }))

    if (withInterdependency)
      yield put(
        updateDayOneItemSuccess({
          data: omit(data, [
            'hierarchy',
            'timeFlag',
            'type',
            'id',
            'index',
            'projectListId',
            'projectId',
            'keyProcessId',
            'attachments',
            'keyProcessId',
            'interdependency',
            'isIncoming',
            'isOutgoing',
            'longTeamName',
            'primaryIMO',
            'teamId',
            'ancestor',
            'customColumns',
            'libraryFunctionId',
          ]),
          rowNodeId: withInterdependency,
        }),
      )
  }

  const onFailure = function* () {
    yield put(
      updateDayOneItemSuccess({
        data: {
          ancestor: null,
          teamKeyProcessL2Id: oldData.keyProcessId,
          ...oldData,
        },
        rowNodeId,
      }),
    )

    if (withInterdependency)
      yield put(
        updateDayOneItemSuccess({
          data: oldInterdependencyData,
          rowNodeId: withInterdependency,
        }),
      )
  }

  const payload = {
    ...action.payload,
    data: omit(data, 'projectListId'),
    onSuccess,
    onFailure,
    optimisticUpdate: !shouldRefresh,
    isDueDataChanged,
  }

  if (data.type === 'project') {
    // This is not part of the project DTO. Unsure if it is still used elsewhere,
    // so only remove if we're updating a project.
    delete payload.data.teamKeyProcessL2Id
  }

  if (customColumn) {
    return yield call(updateCustomColumn, { payload, type: action.type })
  }

  yield put(silentUpdateActionMap[data.type](payload))
},
true)

export const updateDayOneProcessItemId = createSaga(function* updateDayOneProcessItemId(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data, rowNodeId, teamId, withInterdependency, refresh } = action.payload
  const oldData = yield select((state) => getProcessById(state, rowNodeId))

  const notAllProcessShown = yield checkIsUserOnlyMember({ teamId })

  const onSuccess = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    yield put(updateDayOneItemIDSuccess(action.payload))

    if (withInterdependency)
      yield put(
        updateDayOneItemIDSuccess({
          data: { ...data, teamId: data?.interdependency?.senderTeam?.id },
          rowNodeId: withInterdependency,
        }),
      )

    if (notAllProcessShown && refresh) return yield call(refresh, true)
  }

  const onFailure = function* () {
    yield put(updateDayOneItemIDSuccess({ ...action.payload, data: oldData }))
  }

  const payload = {
    ...action.payload,
    data,
    onSuccess,
    onFailure,
  }

  if (notAllProcessShown) {
    payload.optimisticUpdate = false
  }

  yield put((!notAllProcessShown ? silentUpdateActionMap : updateActionMap)[data.type](payload))
})

export const deleteDayOneProcessItem = createSaga(function* deleteDayOneProcessItem(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data, teamId, withInterdependency } = action.payload

  if (data.isNew) {
    return yield put(removeListItem({ rowNodeId: getRowNodeIdProcessItem(data) }))
  }

  const onSuccess = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    if (withInterdependency) {
      const imoTeamId = yield select(getSelectedImoTeamId)

      yield put(fetchProgramProjectPlanData({ teamId: imoTeamId }))
    } else {
      yield put(fetchProjectList({ teamId }))
    }
  }

  const onError = function* () {}

  const payload = { ...action.payload, onSuccess, onError }

  return yield put(deleteActionMap[data.type](payload))
},
true)

export const deleteDayOneProcessItems = function* deleteDayOneProcessItems(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = action

  const dayOneApi = yield getDayOneApi()

  return yield call(
    dayOneApi.request('deleteItemsList', {
      query: {
        teamId,
      },
      body: data,
    }),
  )
}

export const deleteItemsList = createSaga(function* deleteItemsList(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data, withInterdependency } = action.payload

  yield all(
    Object.keys(data).map((id) =>
      call(deleteDayOneProcessItems, { teamId: id, data: data[id].map((item: $TSFixMe) => omit(item, 'teamId')) }),
    ),
  )

  if (withInterdependency) {
    const imoTeamId = yield select(getSelectedImoTeamId)

    yield put(fetchProgramProjectPlanData({ teamId: imoTeamId }))
  } else {
    yield put(fetchProjectList({ teamId }))
  }
})

export const createDayOneProcessItem = createSaga(function* createDayOneProcessItem(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data, teamId, withInterdependency, disableLoadingOverlay } = action.payload

  const onSuccess = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    disableLoadingOverlay()
    if (withInterdependency) {
      const imoTeamId = yield select(getSelectedImoTeamId)

      yield put(fetchProgramProjectPlanData({ teamId: imoTeamId }))
    } else {
      yield put(fetchProjectList({ teamId }))
    }
  }

  const onFailure = function* () {
    disableLoadingOverlay()
    if (!get(['payload', 'refresh'], action)) yield put(fetchProjectList({ teamId }))
  }

  const payload = { ...action.payload, onSuccess, onFailure }

  return yield put(createActionMap[data.type](payload))
},
true)

export default [
  takeLatest(constants.CREATE_DAY_ONE_PROCESS_ITEM, createDayOneProcessItem),
  takeLatest(constants.UPDATE_DAY_ONE_ITEM_ID, updateDayOneProcessItemId),
  takeLatest(constants.UPDATE_DAY_ONE_PROCESS_ITEM, updateDayOneProcessItem),
  takeLatest(constants.DELETE_DAY_ONE_PROCESS_ITEM, deleteDayOneProcessItem),
  takeLatest(constants.DELETE_DAY_ONE_ITEMS_LIST, deleteItemsList),
]

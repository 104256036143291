import * as Yup from 'yup'

export const OtherMembersSchema = Yup.array().of(
  Yup.object().shape({
    id: Yup.mixed(),
    displayName: Yup.string().nullable(),
  }),
)

export const TeamMembersSchema = Yup.array()
  .of(
    Yup.object().shape({
      id: Yup.mixed().when(['responsibilities', 'displayName'], {
        is: (responsibilities, displayName) => responsibilities === 'Content' && !displayName,
        then: Yup.number().nullable().required('This field cannot be empty'),
        otherwise: Yup.mixed(),
      }),
      displayName: Yup.string().nullable(),
      dueDate: Yup.date().when('responsibilities', {
        is: 'Content',
        then: Yup.date().typeError('This field cannot be empty'),
        otherwise: Yup.date().nullable(),
      }),
      responsibilities: Yup.string(),
    }),
  )
  .min(1)
  .max(3)

export const EventSchema = Yup.object().shape({
  teamMembers: TeamMembersSchema,
  otherMembers: OtherMembersSchema,
  name: Yup.string().trim().nullable().required('This field cannot be empty'),
  status: Yup.string().nullable().required('This field cannot be empty'),
  medium: Yup.string().nullable().required('This field cannot be empty'),
  columnId: Yup.string().nullable().required('This field cannot be empty'),
  rowId: Yup.string().nullable().required('This field cannot be empty'),
  comments: Yup.string().nullable(),
  confidential: Yup.bool().required(),
  description: Yup.string().nullable(),
})

import React, { createContext, useEffect, useState } from 'react'
import { io, Socket } from 'socket.io-client'
import Config from '@root/src/config'
import {
  ActiveCellCoords,
  ActiveUserSocketData,
  ClientToServerEvents,
  Rooms,
  ServerToClientEvents,
  RealTimeEvents,
} from '@domain/realTime/types'
import { useSelector } from 'react-redux'
import { ACTIVE_USER_COLORS } from './utils/constants'
import { getRefreshedToken } from '../generic/selectors'
import { useActiveUsersEvents } from './events/useActiveUsersEvents'

const URL = Config.IMO_PUB_SUB_SERVER

type RealTimeContext = {
  socket?: Socket
  authenticated: boolean
  joinRoom: ({ room, teamId }: { room: Rooms; teamId: number }) => void
  leaveRoom: ({ room, teamId }: { room: Rooms; teamId: number }) => void
  activeUsers: ActiveUserSocketData[]
  sendEditingStartedEvent: () => void
  sendEditingStoppedEvent: () => void
  sendFocusEvent: (cellCords: ActiveCellCoords, room: Rooms) => void
  sendBlurEvent: () => void
}

export const RealTimeContext = createContext<RealTimeContext>({
  authenticated: false,
  joinRoom: () => {},
  leaveRoom: () => {},
  activeUsers: [],
  sendEditingStartedEvent: () => {},
  sendEditingStoppedEvent: () => {},
  sendFocusEvent: () => {},
  sendBlurEvent: () => {},
})

export const RealTimeProvider = ({ children }: { children: React.ReactNode }) => {
  const [socket, setSocket] = useState<Socket>()
  const [authenticated, setAuthenticated] = useState(false)
  const [activeUsers, setActiveUsers] = useState<ActiveUserSocketData[]>([])
  const [currentRoom, setCurrentRoom] = useState<Rooms | null>(null)
  const refreshedToken = useSelector(getRefreshedToken)

  const joinRoom = ({ room, teamId }: { room: Rooms; teamId: number }) => {
    if (socket) {
      socket.emit(ClientToServerEvents.Subscribe, {
        room,
        teamId,
        colorPallette: ACTIVE_USER_COLORS,
      })
      setCurrentRoom(room)
    }
  }

  const leaveRoom = ({ room, teamId }: { room: Rooms; teamId: number }) => {
    if (socket) {
      socket.emit(ClientToServerEvents.Unsubscribe, { room, teamId })
      setCurrentRoom(null)
    }
  }

  const getAuthOptions = (refreshedToken?: string) => {
    const token = refreshedToken || sessionStorage.getItem('_mid-access-token')
    const tenant = sessionStorage.getItem('_mid-tenant')
    const tenantId = tenant ? JSON.parse(tenant)?.tenantId : undefined

    return {
      token,
      tenant: tenantId,
    }
  }

  const onConnect = (socket: Socket) => {
    socket.emit(ClientToServerEvents.Authenticate, getAuthOptions())
  }

  const onDisconnect = (reason: string) => {
    setAuthenticated(false)
    global.console.info('Socket IO disconnected:', reason)
  }

  const onError = (err: Error) => {
    global.console.error('Socket IO error:', err.message)
  }

  const onAuthenticationSuccess = () => {
    setAuthenticated(true)
  }

  const updateActiveUsers = ({ data }: { data: ActiveUserSocketData[] }) => {
    setActiveUsers(data)
  }

  useEffect(() => {
    if (socket && socket.connected && authenticated) {
      socket.emit(ClientToServerEvents.RefreshToken, getAuthOptions(refreshedToken))
    }
  }, [refreshedToken])

  useEffect(() => {
    const socket = io(URL, {
      autoConnect: true,
      transports: ['websocket'],
      path: Config.SOCKET_IO_CLIENT_PATH,
    })

    setSocket(socket)

    socket.on(RealTimeEvents.Connect, () => onConnect(socket))
    socket.on(RealTimeEvents.Disconnect, onDisconnect)
    socket.on(RealTimeEvents.ConnectionError, onError)
    socket.on(ServerToClientEvents.Error, onError)
    socket.on(ServerToClientEvents.AuthenticationSuccess, () => onAuthenticationSuccess())
    socket.on(ServerToClientEvents.SubscribeSuccess, updateActiveUsers)
    socket.on(ServerToClientEvents.UnsubscribeSuccess, updateActiveUsers)
    socket.on(ServerToClientEvents.DisconnectSuccess, updateActiveUsers)

    return () => {
      socket.off(RealTimeEvents.Connect, () => onConnect(socket))
      socket.off(RealTimeEvents.Disconnect, onDisconnect)
      socket.off(RealTimeEvents.ConnectionError, onError)
      socket.off(ServerToClientEvents.Error, onError)
      socket.off(ServerToClientEvents.AuthenticationSuccess, () => onAuthenticationSuccess())
      socket.off(ServerToClientEvents.SubscribeSuccess, updateActiveUsers)
      socket.off(ServerToClientEvents.UnsubscribeSuccess, updateActiveUsers)
      socket.off(ServerToClientEvents.DisconnectSuccess, updateActiveUsers)

      socket.disconnect()
    }
  }, [])

  const { sendEditingStartedEvent, sendEditingStoppedEvent, sendFocusEvent, sendBlurEvent } = useActiveUsersEvents(
    updateActiveUsers,
    currentRoom,
    socket,
  )

  return (
    <RealTimeContext.Provider
      value={{
        socket,
        joinRoom,
        leaveRoom,
        authenticated,
        activeUsers,
        sendEditingStartedEvent,
        sendEditingStoppedEvent,
        sendFocusEvent,
        sendBlurEvent,
      }}
    >
      {children}
    </RealTimeContext.Provider>
  )
}

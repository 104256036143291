import { filter, flatMap, mergeWith, omit, unionBy } from 'lodash'
import { flattenTree } from '@helpers/utils'
import { getOr, groupBy, pick } from 'lodash/fp'

export const transformMDPAData = (data: $TSFixMe) =>
  // @ts-expect-error flattenTree doesn't take two args in the 3rd param mapper fn.. Double check.
  flattenTree(data, 'subKeyProcesses', (process: $TSFixMe, parent: $TSFixMe) => {
    const newProcess = {
      ...omit(process, 'subKeyProcesses'),
      complete: Boolean(process.complete),
      owners: !process.owners || process.owners?.every((o: $TSFixMe) => !o) ? null : process.owners,
    } as {
      complete: boolean
      owners: $TSFixMe[]
      keyProcessId?: $TSFixMe
      keyProcess?: $TSFixMe
      processName: string
      subProcessName: string
    }

    if (parent) {
      newProcess.keyProcessId = parent.id
      newProcess.keyProcess = omit(parent, 'subKeyProcesses')
    }

    newProcess.processName = parent ? parent.name : process.name
    newProcess.subProcessName = parent && process.name

    return newProcess
  })

export const formatItemSelection = (item: $TSFixMe) => ({ ...item, disabled: item.selected })

export const flattenProcessFromLibrary = (list: $TSFixMe) => {
  return flatMap(list, (keyProcess) => [
    formatItemSelection(keyProcess),
    ...keyProcess.subKeyProcesses.map((subProcess: $TSFixMe) => ({
      ...formatItemSelection(subProcess),
      keyProcessId: keyProcess.id,
      keyProcess,
    })),
  ])
}

const getClearProcessData = pick(['id', 'name', 'selected'])

export const transformProcessFromLibraryToAdd = (data: $TSFixMe) => {
  const groupedData = groupBy(getOr('parent', 'keyProcessId'), data)

  return groupedData?.parent?.map((process) => ({
    ...getClearProcessData(process),
    subKeyProcesses: getOr([], process.id, groupedData).map(getClearProcessData),
  }))
}

export const getMDPARowNodeId = ({ data: { id, keyProcessId = '' } }: $TSFixMe) => `${keyProcessId}-${id}`

const getChildren = (selectedItem: $TSFixMe, allItems: $TSFixMe[]) => {
  const { id } = selectedItem

  return filter(allItems, (item) => item.keyProcessId === id)
}

const mergeProjects = (objValue: $TSFixMe, srcValue: $TSFixMe) => unionBy(objValue, srcValue, getMDPARowNodeId)

// To delete row show in confirmation its name and names of its children.
// Data about parents isn't needed.
// E.g. if selected row is project, return project data and all its childs
export const getDeletionData = (selectedRows: $TSFixMe[], allRows: $TSFixMe[]) => {
  const dataToDelete = {
    keyProcesses: [] as $TSFixMe[],
    subProcesses: [] as $TSFixMe[],
    rowsToDelete: [] as $TSFixMe[],
  }

  selectedRows.forEach((row) => {
    dataToDelete.rowsToDelete.push(row)

    if (!row.keyProcessId)
      mergeWith(
        dataToDelete,
        {
          keyProcesses: [row],
          subProcesses: getChildren(row, allRows),
        },
        mergeProjects,
      )

    mergeWith(dataToDelete, { subProcesses: [row] }, mergeProjects)
  })

  return dataToDelete
}

export const getDeleteMultipleMDPAItemsData = (selectedRows: $TSFixMe[]) => {
  return selectedRows.map((item) => pick(['id', 'keyProcessId'], item))
}

export const checkIsMatchProcess = (process: $TSFixMe, processToCompare: $TSFixMe) =>
  process.id === processToCompare.id && ('keyProcessId' in process && 'keyProcessId' in processToCompare ? process.keyProcessId === processToCompare.keyProcessId : true)

export const updateProcesses = ({ data, keyProcessId, id }: $TSFixMe, processes: $TSFixMe[]) => {
  const newProcesses = processes.map((process) => {
    if (checkIsMatchProcess(process, { id, keyProcessId })) {
      return { ...process, ...data }
    }

    if (checkIsMatchProcess(getOr({}, 'keyProcess', process), { id, keyProcessId })) {
      return {
        ...process,
        keyProcess: {
          ...process.keyProcess,
          name: data.name,
        },
      }
    }

    return process
  })

  return newProcesses
}

export const checkIsChildSubProcess = (process: $TSFixMe, keyProcessId: $TSFixMe) =>
  process.keyProcessId === keyProcessId

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchScenarios = createAction(constants.FETCH_SCENARIOS)
export const fetchScenariosWithLoader = createAction(constants.FETCH_SCENARIOS, null, getMetaWithActionLoading)
export const setScenarios = createAction(constants.SET_SCENARIOS)

export const fetchScenariosFromLibrary = createAction(
  constants.FETCH_SCENARIOS_FROM_LIBRARY,
  null,
  getMetaWithActionLoading,
)
export const setScenariosFromLibrary = createAction(constants.SET_SCENARIOS_FROM_LIBRARY)
export const resetScenariosFromLibrary = createAction(constants.RESET_SCENARIOS_FROM_LIBRARY)
export const updateScenarioFromLibrary = createAction(constants.UPDATE_SCENARIO_FROM_LIBRARY)

export const addScenariosFromLibrary = createAction(constants.ADD_SCENARIOS_FROM_LIBRARY)

export const createScenario = createAction(constants.CREATE_SCENARIO)
export const createScenarioSuccess = createAction(constants.CREATE_SCENARIO_SUCCESS)

export const updateScenario = createAction(constants.UPDATE_SCENARIO)
export const updateScenarioSuccess = createAction(constants.UPDATE_SCENARIO_SUCCESS)

export const deleteScenario = createAction(constants.DELETE_SCENARIO)
export const deleteScenarioSuccess = createAction(constants.DELETE_SCENARIO_SUCCESS)

import { handleActions } from 'redux-actions'
import * as constants from '@teamResources/teamStructure/actions/actionTypes'

export interface ITeamStructureState {
  teamStructureURL: string
}

export const initialState = {
  teamStructureURL: '',
}

const teamStructureReducer = handleActions<ITeamStructureState, $TSFixMe>(
  {
    [constants.SET_TEAM_STRUCTURE]: (state, action) => ({ ...state, teamStructureURL: action.payload }),
  },
  initialState,
)

export default teamStructureReducer

import React from 'react'
import { ImoIcon } from '@imo/imo-ui-toolkit'
import VCTrackerRootV2 from '@views/myImoClient/pages/ValueCaptureTrackerV2/VCTrackerRootV2'
import { AccessControl } from '@views/shared/AccessControl/AccessControl'
import { synergyTypes } from '@helpers/constants'
import { RouteObject } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getManagementType } from '@root/src/domain/instanceConfig/selectors'

export const PipelineByTeamCrumbTitle = () => {
  const managementType = useSelector(getManagementType)
  return <>Pipeline by {managementType === 'SMO' ? 'Separation' : 'Integration'} Team</>
}

export const vcTrackerRouter: RouteObject[] = [
  {
    path: '/value-capture-tracker',
    handle: {
      crumb: { icon: <ImoIcon icon="value-capture" size={16} />, title: 'Value Capture Tracker' },
    },
    children: [
      { path: '', element: <AccessControl component={VCTrackerRootV2} /> },
      {
        path: 'team/:teamId',
        lazy: async () => {
          const { ValueCaptureLayout } = await import(
            /* webpackChunkName: "VC Tracker" */ '@views/myImoClient/pages/ValueCaptureTrackerV2/ValueCapture'
          )
          return { element: <ValueCaptureLayout /> }
        },
        children: [
          {
            path: 'summary-status',
            lazy: async () => {
              const { SummaryStatus } = await import(
                /* webpackChunkName: "VC Tracker" */
                '@views/myImoClient/pages/ValueCaptureTrackerV2/SummaryStatus/SummaryStatus'
              )
              return { element: <SummaryStatus /> }
            },
          },
          {
            path: 'late-or-at-risk',
            lazy: async () => {
              const { LateOrAtRisk } = await import(
                /* webpackChunkName: "VC Tracker" */ '@views/myImoClient/pages/ValueCaptureTrackerV2/LateOrAtRisk'
              )
              return { element: <LateOrAtRisk /> }
            },
            handle: {
              crumb: { title: 'Late or At Risk' },
            },
          },
          {
            path: 'all-initiatives',
            lazy: async () => {
              const { AllInitiativesNav } = await import(
                /* webpackChunkName: "VC Tracker" */
                '@views/myImoClient/pages/ValueCaptureTrackerV2/AllInitiatives'
              )
              return { element: <AllInitiativesNav /> }
            },
            handle: {
              crumb: { title: 'All Initiatives' },
            },
            children: [
              {
                path: 'cost',
                lazy: async () => {
                  const { AllInitiativesTab } = await import(
                    /* webpackChunkName: "VC Tracker" */
                    '@views/myImoClient/pages/ValueCaptureTrackerV2/AllInitiatives'
                  )
                  return { element: <AllInitiativesTab synergyType={synergyTypes.COST} /> }
                },
              },
              {
                path: 'revenue',
                lazy: async () => {
                  const { AllInitiativesTab } = await import(
                    /* webpackChunkName: "VC Tracker" */
                    '@views/myImoClient/pages/ValueCaptureTrackerV2/AllInitiatives'
                  )
                  return { element: <AllInitiativesTab synergyType={synergyTypes.REVENUE} /> }
                },
                handle: {
                  crumb: { title: 'Revenue' },
                },
              },
              {
                path: 'nwc',
                lazy: async () => {
                  const { AllInitiativesTab } = await import(
                    /* webpackChunkName: "VC Tracker" */
                    '@views/myImoClient/pages/ValueCaptureTrackerV2/AllInitiatives'
                  )
                  return { element: <AllInitiativesTab synergyType={synergyTypes.NWC} /> }
                },
                handle: {
                  crumb: { title: 'NWC/Capex' },
                },
              },
              {
                path: 'otc',
                lazy: async () => {
                  const { AllInitiativesTab } = await import(
                    /* webpackChunkName: "VC Tracker" */
                    '@views/myImoClient/pages/ValueCaptureTrackerV2/AllInitiatives'
                  )
                  return { element: <AllInitiativesTab synergyType={synergyTypes.ONE_TIME_COST} /> }
                },
                handle: {
                  crumb: { title: 'One-time Cost' },
                },
              },
            ],
          },
          {
            path: 'pipeline-by-team',
            lazy: async () => {
              const { PipelineByIntegrationTeam } = await import(
                /* webpackChunkName: "VC Tracker" */
                '@views/myImoClient/pages/ValueCaptureTrackerV2/PipelineByIntegrationTeam'
              )
              return { element: <PipelineByIntegrationTeam /> }
            },
            handle: {
              crumb: { title: <PipelineByTeamCrumbTitle /> },
            },
          },
          {
            path: 'pipeline-by-year',
            lazy: async () => {
              const { PipelineByYearOfExecution } = await import(
                /* webpackChunkName: "VC Tracker" */
                '@views/myImoClient/pages/ValueCaptureTrackerV2/PipelineByYearOfExecution'
              )
              return { element: <PipelineByYearOfExecution /> }
            },
            handle: {
              crumb: { title: 'Expected Impact by Year' },
            },
          },
          {
            path: 'month-by-month',
            lazy: async () => {
              const { MonthByMonthTab } = await import(
                /* webpackChunkName: "VC Tracker" */ '@views/myImoClient/pages/ValueCaptureTrackerV2/ValueCapture'
              )
              return { element: <MonthByMonthTab /> }
            },
            handle: {
              crumb: { title: 'Month-by-month' },
            },
          },
          {
            path: 'cxo-dashboard',
            lazy: async () => {
              const { CxODashboard } = await import(
                /* webpackChunkName: "VC Tracker" */ '@views/myImoClient/pages/ValueCaptureTrackerV2/CxODashboard'
              )
              return { element: <CxODashboard /> }
            },
            handle: {
              crumb: { title: 'CxO Dashboard' },
            },
          },
          {
            path: 'setup',
            lazy: async () => {
              const { TeamSetup } = await import(
                /* webpackChunkName: "VC Tracker" */ '@views/myImoClient/pages/ValueCaptureTrackerV2/TeamSetup/TeamSetup'
              )
              return { element: <TeamSetup /> }
            },
            handle: {
              crumb: { title: 'Team Setup' },
            },
          },
        ],
      },
    ],
  },
]

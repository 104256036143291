/* eslint-disable react/require-default-props */
// [aliases/synonyms]: SmartDropdown, Filter, DropdownFilter, FilterDropdown
import React from 'react'
import { getOr } from 'lodash/fp'
import { MenuItem, Classes } from '@blueprintjs/core'
import styled from 'styled-components'
import { Placement } from '@shared/Popover'
import MenuItemText from './MenuItemText'
import { StyledButton, StyledPopover, StyledMenu, Title } from './index.styles'

type PopoverProps = {
  id?: string
  className?: string
  menuContent: React.ReactElement
  icon: string
  title?: string
  label: string
  ariaLabel?: string
  onClose?: () => void
}

const MultipleMenuSelectButton = styled(StyledButton)`
  & > .${Classes.ICON} {
    margin-right: 7px;
  }
`

export const Popover = ({ id, className, menuContent, icon, title, label, ariaLabel, onClose }: PopoverProps) => (
  <StyledPopover
    className={className}
    content={menuContent}
    placement={Placement.BOTTOM_START as $TSFixMe}
    targetTagName={'div'}
    onClose={onClose}
  >
    <MultipleMenuSelectButton
      data-cy={id ? `${id}-select` : undefined}
      minimal
      icon={icon as $TSFixMe}
      rightIcon="caret-down"
      aria-label={ariaLabel}
      text={
        <>
          {title && <Title>{title}:&nbsp;</Title>}
          {label}
        </>
      }
    />
  </StyledPopover>
)

Popover.defaultProps = {
  id: undefined,
  title: null,
}

type MultipleMenuSelectProps = {
  className?: string
  icon?: string
  title?: string
  options: $TSFixMe
  selectAllOption?: $TSFixMe
  getOptionLabel?: (...args: $TSFixMe[]) => $TSFixMe
  getOptionValue?: (...args: $TSFixMe[]) => $TSFixMe
  onSelected: (...args: $TSFixMe[]) => $TSFixMe
  onAllSelected?: (...args: $TSFixMe[]) => $TSFixMe
  getLabel: (...args: $TSFixMe[]) => $TSFixMe
  testSelectAll?: (...args: $TSFixMe[]) => $TSFixMe
  allowEmptySelection?: boolean
  ariaLabel?: string
  onClose?: () => void
}

const MultipleMenuSelect = ({
  className,
  icon,
  title,
  options,
  selectAllOption,
  getOptionLabel = (item: $TSFixMe) => getOr(item, 'name', item),
  getOptionValue = (item: $TSFixMe) => getOr(item, 'name', item),
  onSelected,
  onAllSelected,
  getLabel,
  testSelectAll = (options: $TSFixMe) => options.every((o: $TSFixMe) => o.isActive),
  allowEmptySelection,
  ariaLabel,
  onClose,
}: MultipleMenuSelectProps) => {
  const isAllSelected = testSelectAll(options)

  const handleAllOptionClick = () => {
    if (isAllSelected && !allowEmptySelection) {
      return
    }

    onAllSelected && onAllSelected()
  }

  const handleMenuItemClick = (item: $TSFixMe) => () => {
    if (!allowEmptySelection) {
      const activeOptions = options.filter((o: $TSFixMe) => o.isActive)
      if (activeOptions.length === 1 && getOptionValue(activeOptions[0]) === getOptionValue(item)) {
        return
      }
    }

    onSelected(item)
  }

  const menuContent = (
    <StyledMenu className={className} aria-label={`${ariaLabel} menu`}>
      {selectAllOption && (
        <MenuItem
          key={getOptionValue(selectAllOption)}
          shouldDismissPopover={false}
          disabled={selectAllOption.disabled}
          onClick={handleAllOptionClick}
          text={<MenuItemText text={getOptionLabel(selectAllOption)} customTooltip={selectAllOption.tooltip} />}
          icon={isAllSelected ? 'tick' : 'minus'}
          role="menuitem"
        />
      )}
      {options.map((item: $TSFixMe) => {
        if (!item) {
          return null
        }

        return (
          <MenuItem
            key={getOptionValue(item)}
            shouldDismissPopover={false}
            disabled={item.disabled}
            onClick={handleMenuItemClick(item)}
            text={<MenuItemText text={getOptionLabel(item)} customTooltip={item.tooltip} />}
            icon={item.isActive ? 'tick' : ('' as $TSFixMe)}
            role="menuitem"
          />
        )
      })}
    </StyledMenu>
  )

  return (
    <Popover
      className={className}
      menuContent={menuContent}
      icon={icon as $TSFixMe}
      title={title}
      label={getLabel(options)}
      ariaLabel={ariaLabel ? ariaLabel : undefined}
      onClose={onClose}
    />
  )
}

MultipleMenuSelect.defaultProps = {
  className: '',
  icon: null,
  title: null,
  selectAllOption: null,
  onAllSelected: null,
  allowEmptySelection: false,
}

export default MultipleMenuSelect

export * from './utils'

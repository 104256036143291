import { combineReducers } from 'redux'
import { reducerBuilder } from '@nvt/mid-react'

import Config from '../../config'
import genericReducer from '@generic/reducers/reducer'
import myTeamReducer from '@teamHome/reducers/reducer'
import decisionLogReducer from '../myImoClient/myTeam/decisionLog/reducers/reducer'
import riskLogReducer from '../myImoClient/myTeam/riskLog/reducers/reducer'
import communicationsReducer from '@communications/reducers/reducer'
import deliverablesReducer from '@teamDeliverables/reducers/reducer'
import interdependenciesReducer from '@teamResources/interdependencies/reducers/reducer'
import knowledgeCenterReducer from '@domain/myImoClient/myTeam/knowledgeCenter/reducers/reducer'
import importKnowledgeCenterReducer from '@domain/myImoClient/myTeam/importKnowledgeCenterAssets/reducers/reducer'
import { loginErrors } from '@common/notifications/login'
import projectWatchersReducer from '@projectWatchers/reducers/reducer'
import taskWatchersReducer from '@taskWatchers/reducers/reducer'
import projectActivityLogReducer from '@domain/myImoClient/common/projectActivityLog/reducers/reducer'
import taskActivityLogReducer from '@domain/myImoClient/common/taskActivityLog/reducers/reducer'
import { valueCaptureV2Reducer } from '@domain/myImoClient/valueCaptureV2'
import myImoConfigReducer from '../myImoConfig/reducers/'
import dayOneReducer from '@dayOne/reducers/reducer'
import batchUploadReducer from '@batchUpload/reducers/reducer'
import centralFunctionalTSAReducer from '@centralFunctionalTSA/reducers/reducer'
import TSAv2Reducer from '@domain/myImoClient/TSAv2/reducers/reducer'
import dayOneReadinessReducer from '@dayOneReadiness/reducers/reducer'
import functionalActivityReducer from '@functionalActivity/reducers/reducer'
import functionalProcessDesignReducer from '@functionalProcessDesign/reducers/reducer'
import mutualDiscoveryReducer from '../myImoClient/myTeam/teamDeliverables/mutualDiscovery/reducers/reducer'
import teamCharterReducer from '@domain/myImoClient/myTeam/teamDeliverables/teamCharter/reducers/reducer'
import controlPanelReducer from '@controlPanel/reducers/reducer'
import day1IssueKPIsReducer from '@day1IssueKPIs/reducers/reducer'
import hypercareLogReducer from '@hypercareLog/reducers/reducer'
import steerCoRoadmapReducer from '@steerCoRoadmap/reducers/reducer'
import teamStatusTrackerReducer from '@teamStatusTracker/reducers/reducer'
import teamWorkspaceReducer from '@teamWorkspace/reducers/reducer'
import { teamValueCaptureV2Reducer } from '@teamValueCaptureV2/index'
import dataRequestTrackerReducer from '@teamResources/dataRequestTracker/reducers/reducer'
import statusReportReducer from '@teamResources/statusReport/reducers/reducer'
import customDeliverableReducer from '@customDeliverable/reducers/reducer'
import scenarioReportReducer from '@teamResources/scenarioReport/reducers/reducer'
import fullProjectPlanReducer from '@teamResources/fullProjectPlan/reducers/reducer'
import masterplanReducer from '@teamResources/masterplan/reducers/reducer'
import planningAssumptionsReportReducer from '@teamResources/planningAssumptionsReport/reducers/reducer'
import resourcesHomeReducer from '@teamResources/resourcesHome/reducers/reducer'
import scopeTeamMembersReducer from '@teamResources/scopeTeamMembers/reducers/reducer'
import teamContactsReducer from '@teamResources/teamContacts/reducers/reducer'
import teamStructureReducer from '@teamResources/teamStructure/reducers/reducer'
import initiativeActivityLogReducer from '@domain/myImoClient/common/initiativeActivityLog/reducers/reducer'
import initiativeWatchersReducer from '@domain/myImoClient/common/initiativeWatchers/reducers/reducer'
import batchUploadActualsReducer from '@domain/myImoClient/common/batchUploadActuals/reducers/reducer'
import moduleOptions from '../myImoConfig/moduleOptions/reducer/reducer'
import instanceConfigReducer from '../instanceConfig/reducer'

const midReducer = reducerBuilder({
  apiServer: Config.API_SERVER,
  mid: {
    errors: loginErrors,
  },
  enableCookieAuth: Config.ENABLE_COOKIE_AUTH,
})

export const clientReducer = combineReducers({
  myTeam: myTeamReducer,
  communications: communicationsReducer,
  teamDeliverables: deliverablesReducer,
  valueCaptureTrackerV2: valueCaptureV2Reducer,
  interdependencies: interdependenciesReducer,
  decisionLog: decisionLogReducer,
  riskLog: riskLogReducer,
  knowledgeCenter: knowledgeCenterReducer,
  importKnowledgeCenter: importKnowledgeCenterReducer,
})

export default function createReducer() {
  return combineReducers({
    '@teamValueCaptureV2': teamValueCaptureV2Reducer,
    mid: midReducer,
    generic: genericReducer,
    config: myImoConfigReducer,
    client: clientReducer,
    instanceConfig: instanceConfigReducer,
    '@dayOne': dayOneReducer,
    '@batchUpload': batchUploadReducer,
    '@centralFunctionalTSA': centralFunctionalTSAReducer,
    '@TSAv2': TSAv2Reducer,
    // TODO: seems it's unused key, recheck
    '@dayOneInterimOrg': dayOneReadinessReducer,
    '@dayOneReadiness': dayOneReadinessReducer,
    '@functionalActivity': functionalActivityReducer,
    '@functionalProcessDesign': functionalProcessDesignReducer,
    '@mutualDiscovery': mutualDiscoveryReducer,
    '@teamCharter': teamCharterReducer,
    '@controlPanel': controlPanelReducer,
    '@day1IssueKPIs': day1IssueKPIsReducer,
    '@hypercareLog': hypercareLogReducer,
    '@projectWatchers': projectWatchersReducer,
    '@taskWatchers': taskWatchersReducer,
    '@initiativeWatchers': initiativeWatchersReducer,
    '@projectActivityLog': projectActivityLogReducer,
    '@taskActivityLog': taskActivityLogReducer,
    '@initiativeActivityLog': initiativeActivityLogReducer,
    '@steerCoRoadmap': steerCoRoadmapReducer,
    '@teamStatusTracker': teamStatusTrackerReducer,
    '@teamWorkspace': teamWorkspaceReducer,
    '@dataRequestTracker': dataRequestTrackerReducer,
    '@statusReport': statusReportReducer,
    '@customDeliverable': customDeliverableReducer,
    '@scenarioReport': scenarioReportReducer,
    '@fullProjectPlan': fullProjectPlanReducer,
    '@masterplan': masterplanReducer,
    '@planningAssumptionsReport': planningAssumptionsReportReducer,
    '@resourcesHome': resourcesHomeReducer,
    '@scopeTeamMembers': scopeTeamMembersReducer,
    '@teamContacts': teamContactsReducer,
    '@teamStructure': teamStructureReducer,
    '@batchUploadActuals': batchUploadActualsReducer,
    '@moduleOptions': moduleOptions,
  })
}

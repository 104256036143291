import { put, call } from 'redux-saga/effects'
import { getTeamResourcesApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { setPlanningAssumptionsReport } from '@teamResources/planningAssumptionsReport/actions/actions'

export const fetchPlanningAssumptionsReport = createSaga(function* fetchPlanningAssumptionsReport(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const api = yield getTeamResourcesApi()
  const data = yield call(api.request('getPlanningAssumptionsReport'))

  yield put(setPlanningAssumptionsReport(data || null))
},
true)

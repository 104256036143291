import { find, get } from 'lodash'
import { isNil, isFunction, toNumber, last, isObject, isEmpty, flow, filter, map } from 'lodash/fp'
import moment from 'moment'
import { getFormattedDate } from '@imo/imo-ui-toolkit'
import { BLANKS_FILTER_LABEL, cellPlaceholders, NOT_SELECTED_LABEL } from './constants'
import { toStringSafe } from '@helpers/utils'
import { LAST_LOGIN } from '@myImoConfig/components/UserAccounts/constants'

export const getStatusValue = (props) => {
  const { value, context, colDef, isSevenStep, colId } = props

  if (!value || !moment(new Date(value)).isValid()) {
    return colDef?.field === LAST_LOGIN || colId === LAST_LOGIN
      ? cellPlaceholders.statuses.emptyLogin
      : cellPlaceholders.statuses.empty
  }

  const statusDate = moment.utc(value)

  return `${context?.isStepSeven || isSevenStep ? '' : 'Created on'} ${statusDate.format(
    'MMM DD, YYYY',
  )} at ${statusDate.format('HH:mm')} GMT`
}

export const getCreatedDateString = () => moment().format('MMM D YYYY')

export const getActiveProcessesAmount = (processes) =>
  processes.reduce((acc, process) => {
    if (process.active) {
      acc++
    }

    return acc
  }, 0)

export const getValueLabel = (options, value) =>
  get(
    find(options, ({ value: optValue }) => toStringSafe(optValue) === toStringSafe(value)),
    'label',
    '',
  )

export const getValues = (options, props, canUnselect) => {
  const opts = isFunction(options) ? options(props) : options

  return canUnselect ? [{ value: null, label: NOT_SELECTED_LABEL }, ...opts] : opts
}

export const agSelectFilterValueFormatter = (options) => (props) => {
  const { value } = props

  let values = getValues(options, props)

  return isNil(value) ? BLANKS_FILTER_LABEL : getValueLabel(values, value)
}

export const agRichKeyCreator = (params) => {
  const {
    colDef: { valueFormatter },
    value,
  } = params

  const formattedValue = valueFormatter ? valueFormatter(params) : value

  return formattedValue || BLANKS_FILTER_LABEL
}

/**
 * @deprecated Causes bug like https://mckinsey.atlassian.net/browse/IMO-10570
 */
export const getParamsForAgRichSelect = (options, placeholder = '', canUnselect = false) => {
  return {
    suppressKeyboardEvent: () => true,
    cellEditorPopup: true,
    valueFormatter: (props) => {
      const { value } = props
      let values = getValues(options, props, canUnselect)

      return !isNil(value) ? getValueLabel(values, value) || value : placeholder
    },
    cellEditorParams: (props) => {
      let values = getValues(options, props, canUnselect)

      return {
        values: values.map(({ value }) => value),
        formatValue: (value) => getValueLabel(values, value) || value || '',
      }
    },
  }
}

export const getColumnsState = (state, columns) =>
  state.map((col) => {
    const column = find(columns, { id: toNumber(col.colId) })

    return { ...col, hide: column ? !column.visible : col.hide }
  })

export const recalculateColumnsOrder = (columns, newColumns, orderField = 'orderId') => {
  const orderedCols = newColumns.map(({ colDef, visible }) => ({ id: colDef.colId, visible })).slice(1)

  const cols = columns.map((column) => {
    const columnIndex = orderedCols?.findIndex(({ id }) => id === column.id)

    return {
      ...column,
      [orderField]: columnIndex + 1,
      visible: get(orderedCols, [columnIndex, 'visible'], false),
    }
  })

  return cols
}

export const getParamsForNewRowPlaceholder = ({ valueFormatter, defaultValue } = {}) => ({
  valueFormatter: (params) => {
    const {
      value,
      colDef: { placeholder },
      data: { isNew } = {},
    } = params

    if (isNew && placeholder && (!value || value === defaultValue)) {
      return placeholder
    }

    if (valueFormatter) {
      return valueFormatter(params)
    }
  },
  cellClassRules: {
    'ag-imo-cell-not-selected': ({ value, colDef: { placeholder }, data: { isNew } = {} }) =>
      !value && isNew && placeholder,
  },
})

export const getIsAddableRow = (data) => !get(data, 'isNew')

export const prepareXLSXFilename = (fileName, date) => {
  const name = fileName.split('.')
  let formattedDate = date || getFormattedDate(new Date(), 'll')

  if (last(name) === 'xlsx') {
    name.splice(-1)

    return `${name.join('.')} - ${formattedDate}.xlsx`
  }

  return `${fileName} - ${formattedDate}.xlsx`
}

export const getVisibleRows = (gridApi) => {
  let temp = []
  const renderedNodes = gridApi.getRenderedNodes()

  renderedNodes.forEach((node) => {
    temp.push(node.data)
  })

  return temp
}

export const prefilterRows = ({ api, filterState, callback, applyToEmptyModel = false }) => {
  const needResetAllFilters = isObject(filterState) && isEmpty(filterState)

  if (needResetAllFilters) return api.setFilterModel(null)

  if (filterState && (api.getModel()?.rowsToDisplay?.length > 0 || applyToEmptyModel)) {
    Object.keys(filterState).forEach((key) => {
      api.getFilterInstance(key, (filterInstance) => {
        filterInstance.setModel(filterState[key])
        api.onFilterChanged()
      })
    })

    callback && callback()
  }
}

export const getAppliedSorting = (columnApi = {}) => {
  if (!('getColumnState' in columnApi)) return []

  const state = columnApi.getColumnState()

  return flow(
    filter('sort'),
    map(({ colId, sort }) => ({ colId, sort })),
  )(state)
}

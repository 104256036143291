const prefix = '@hypercareLog/'

export const FETCH_HYPERCARE_LOG_DATA = `${prefix}FETCH_DATA`
export const SET_HYPERCARE_LOG_DATA = `${prefix}SET_DATA`
export const RESET_HYPERCARE_LOG_DATA = `${prefix}RESET_HYPERCARE_LOG_DATA`

export const SAVE_ISSUE = `${prefix}SAVE_ISSUE`
export const ADD_ISSUE = `${prefix}ADD_ISSUE`

export const UPDATE_ISSUE = `${prefix}UPDATE_ISSUE`
export const UPDATE_ISSUE_SUCCESS = `${prefix}UPDATE_ISSUE_SUCCESS`

export const DELETE_ISSUE = `${prefix}DELETE_ISSUE`
export const DELETE_ISSUE_SUCCESS = `${prefix}DELETE_ISSUE_SUCCESS`

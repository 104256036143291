const prefix = '@tvc/af/'

export const FETCH_ADVANCED_FINANCIALS = `${prefix}FETCH_ADVANCED_FINANCIALS`
export const SET_ADVANCED_FINANCIALS = `${prefix}SET_ADVANCED_FINANCIALS`
export const SET_IS_ADVANCED_FINANCIALS_OPENED = `${prefix}SET_IS_ADVANCED_FINANCIALS_OPENED`
export const CLOSE_ADVANCED_FINANCIALS = `${prefix}CLOSE_ADVANCED_FINANCIALS`
export const ADVANCED_FINANCIALS_LOADER = `${prefix}ADVANCED_FINANCIALS_LOADER`

export const CREATE_ADVANCED_FINANCIALS = `${prefix}CREATE_ADVANCED_FINANCIALS`
export const CREATE_ADVANCED_FINANCIALS_SUCCESS = `${prefix}CREATE_ADVANCED_FINANCIALS_SUCCESS`

export const DELETE_ADVANCED_FINANCIALS = `${prefix}DELETE_ADVANCED_FINANCIALS`
export const DELETE_ADVANCED_FINANCIALS_SUCCESS = `${prefix}DELETE_ADVANCED_FINANCIALS_SUCCESS`
export const UPDATE_ADVANCED_FINANCIALS_NAME_OR_CATEGORY = `${prefix}UPDATE_ADVANCED_FINANCIALS_NAME_OR_CATEGORY`
export const UPDATE_ADVANCED_FINANCIALS = `${prefix}UPDATE_ADVANCED_FINANCIALS`

export const UPDATE_FINANCIAL_DATA = `${prefix}UPDATE_FINANCIAL_DATA`
export const UPDATE_RUN_RATE = `${prefix}UPDATE_RUN_RATE`

export const UPDATE_RUN_RATE_IMPACT = `${prefix}UPDATE_RUN_RATE_IMPACT`

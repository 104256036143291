import { createAction } from 'redux-actions'
import * as constants from './actionTypes'

export const getUsers = createAction(constants.GET_USERS)
export const setUsers = createAction(constants.SET_USERS)
export const getUsersSuccess = createAction(constants.GET_USERS_SUCCESS)
export const updateUsers = createAction(constants.UPDATE_USERS)
export const sendInviteEmail = createAction(constants.SEND_INVITE_EMAIL)
export const sendResetEmail = createAction(constants.SEND_RESET_EMAIL)

export const setWhoLockedData = createAction(constants.SET_WHO_LOCKED_DATA)
export const getWhoLockedData = createAction(constants.GET_WHO_LOCKED_DATA)
export const closeWhoLockedData = createAction(constants.CLOSE_WHO_LOCKED_DATA)

export const setLock = createAction(constants.SET_LOCK)
export const setIsReadOnly = createAction(constants.SET_IS_READ_ONLY)
export const setIsViewLocked = createAction(constants.SET_IS_VIEW_LOCKED)

import HttpClient from '../HttpClient'
import Config from '@root/src/config'
import { synergyTypes } from '@helpers/constants'

export default class VCTrackerV2Api extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch, '')

    this.host = Config.API_SERVER
  }

  getTeamVCStatusV2 = ({ query: { teamId, ...other } }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${teamId}/value-capture/v2/summary-status`,
      query: other,
    })
  }

  getValueCaptureTeams = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/value-capture/teams-list/${query.teamId}`,
      query: {
        teamType: 'Value Capture',
      },
    })
  }

  // Late or At Risk
  getLateOrAtRiskData = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/value-capture/v2/late-or-at-risk-metric`,
      query: {
        synergyType: query.valueType,
      },
    })
  }

  getMetricDetails = ({ query }: $TSFixMe) => {
    const { teamId, valueType, metricType, metricName } = query

    return this.call({
      pathname: `teams/${teamId}/value-capture/v2/late-or-at-risk-metric/metric-state`,
      query: {
        metricType,
        metricName,
        synergyType: valueType,
      },
    })
  }

  setMetricDetails = ({ query, body }: $TSFixMe) => {
    const { teamId, metricId } = query

    return this.call(
      {
        pathname: `teams/${teamId}/value-capture/v2/late-or-at-risk-metric/metric-state/${metricId}`,
      },
      {
        method: 'PUT',
        body,
      },
    )
  }

  getPipelineByIntegrationTeamData = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/value-capture-integration-team-pipeline/v2`,
      query: {
        synergyType: query.value,
        calculationType: query.calculationType,
      },
    })
  }

  getPipelineByYearOfExecution = ({ query }: $TSFixMe) => {
    const { teamId, synergyType, subtype1 } = query

    return this.call({
      pathname: `teams/${teamId}/value-capture/v2/yearly-pipeline-execution/total`,
      query: { synergyType, subtype1 },
    })
  }

  //CxO Dashboard
  getCxODashboardData = ({ query }: $TSFixMe) => {
    const { valueType, subtype, year } = query

    const queryParams =
      valueType !== synergyTypes.ONE_TIME_COST
        ? {
            synergyType: valueType,
            subtype,
            year,
          }
        : {
            synergyType: valueType,
            year,
          }

    return this.call({
      pathname: `teams/${query.teamId}/value-capture/v2/cxo`,
      query: queryParams,
    })
  }

  saveTeamOrder = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/order`,
      },
      { body, method: 'PUT' },
    )
  }
}

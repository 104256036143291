import styled from 'styled-components'
import { ACTIVE_SIDER_ICON_COLOR } from '@helpers/constants'
import tutorialIMOLogo from '@assets/img/logo/siderTutorial/logo@2x.png'
import tutorialSMOLogo from '@assets/img/logo/siderTutorial/logo@2x_SMO.png'

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  vertical-align: middle;

  span {
    font-weight: 300;
    font-size: 14px;
    padding: 8px 5px 0 2px;
  }
`

export const TutorialText = styled.div`
  margin: 10px 0 0;
  max-width: 220px;
`

export const SharedImage = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`

const userGuideLogo = styled(SharedImage)`
  position: relative;
  width: 84px;
  height: 21px;
  margin: 5px;
`

export const UserGuideIMOLogo = styled(userGuideLogo)`
  background-image: url(${tutorialIMOLogo});
`

export const UserGuideSMOLogo = styled(userGuideLogo)`
  background-image: url(${tutorialSMOLogo});
`
export const LinkBlock = styled.div`
  button > span {
    color: ${ACTIVE_SIDER_ICON_COLOR};
    font-size: 12px;
  }

  button:hover {
    background-color: unset !important;
    background: none !important;
  }

  .bp4-button {
    padding-left: 0;
    padding-right: 0;
    justify-content: start;
  }
`

import { put, call } from 'redux-saga/effects'
import { setHypercareLogData } from '@hypercareLog/actions/actions'
import { getHypercareLogApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchHypercareLogData = createSaga(function* fetchHypercareLogData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, isImo } = action.payload

  const api = yield getHypercareLogApi()
  const data = yield call(
    api.request(isImo ? 'getImoHypercareLogData' : 'getHypercareLogData', {
      query: { teamId },
    }),
  )

  yield put(setHypercareLogData(data))
})

import { get, upperFirst } from 'lodash/fp'
import { defaultCategories } from '@helpers/constants'
import { specificOptions } from '@helpers/statuses'
import { SPECIFIC_PRIORITIES } from '@root/src/views/shared/DayOne/constants'

const getGenericLabel = (allLabel: $TSFixMe, multipleSuffix: $TSFixMe, labelProp: $TSFixMe) => (options: $TSFixMe) => {
  const activeCount = options.filter((o: $TSFixMe) => o.isActive).length
  if (activeCount === options.length) {
    return allLabel
  }

  if (activeCount === 1) {
    return get(
      labelProp,
      options.find((o: $TSFixMe) => o.isActive),
    )
  }

  return `${activeCount} ${multipleSuffix}`
}

export const getCategoryLabel = getGenericLabel(defaultCategories.ALL_CATEGORIES, 'categories', 'name')

export const getPriorityLabel = getGenericLabel(get('label', SPECIFIC_PRIORITIES.ALL_PRIORITIES), 'priorities', 'label')

export const getStatusLabel = getGenericLabel(upperFirst(specificOptions.ALL_STATUSES), 'statuses', 'label')

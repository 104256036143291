import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@dayOneReadiness/actions/actionTypes'
import {
  fetchScenarios,
  createScenario,
  updateScenario,
  deleteScenario,
  fetchScenariosFromLibrary,
  addScenariosFromLibrary,
} from './scenarios'

export default function* dayOneReadinessSaga() {
  yield all([
    takeLatest(constants.FETCH_SCENARIOS, fetchScenarios),
    takeLatest(constants.FETCH_SCENARIOS_FROM_LIBRARY, fetchScenariosFromLibrary),
    takeLatest(constants.CREATE_SCENARIO, createScenario),
    takeLatest(constants.ADD_SCENARIOS_FROM_LIBRARY, addScenariosFromLibrary),
    takeLatest(constants.UPDATE_SCENARIO, updateScenario),
    takeLatest(constants.DELETE_SCENARIO, deleteScenario),
  ])
}

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class GenericApi extends HttpClient {
  host: string

  constructor(fetch: $TSFixMe) {
    super(fetch, '')

    this.host = Config.API_SERVER
  }

  getClientConfig = () => {
    return this.call({ pathname: '/instance-config' })
  }

  getUser = () => {
    return this.call({ pathname: '/users/user-info' })
  }

  updateUserInfo = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: '/users/user-info' },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  getUsers = () => {
    return this.call({ pathname: '/users' })
  }

  getLibraries = () => {
    return this.call({ pathname: '/libraries' })
  }

  getLibrariesV2 = ({ query }: $TSFixMe) => {
    return this.call({ pathname: '/libraries/all', query })
  }

  getTeamsList = () => {
    return this.call({ pathname: '/teams' })
  }

  setKnowledgeCenterVisibilityStatus = ({ query, body }: $TSFixMe) =>
    this.call(
      {
        pathname: `/teams/${query.teamId}/activate-knowledge-center`,
      },
      {
        method: 'PUT',
        body: { active: body.status },
      },
    )

  // files management
  getFileSignedUrl = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `/files`, query })
  }

  // TODO combine with getLibrariesV2 EP, same purpose
  getLibrariesForImport = ({ query: { type } }: $TSFixMe) =>
    this.call({
      pathname: '/libraries/all',
      query: {
        includeAllTypes: true,
        type: type,
      },
    })

  getLinkedLibrariesByTeam = ({ query: { teamId } }: $TSFixMe) => {
    return this.call({ pathname: `/teams/${teamId}/linked-libraries` })
  }

  createFile = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `/files/upload`, routePrefix: '', query },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  deleteFile = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `/files`, routePrefix: '', query }, { method: 'DELETE' })
  }

  getDictionaries = () => {
    return this.call({ pathname: '/dictionary' })
  }

  getDeliverableStatus = () => {
    return this.call({ pathname: '/teams/team-deliverable-status' })
  }

  fetchToggles = ({ query }: $TSFixMe) => {
    return this.call({ pathname: '/users/settings', query })
  }

  setTeamUserSettings = ({ body, query }: $TSFixMe) =>
    this.call(
      {
        pathname: '/users/settings/team-user-settings',
        query,
      },
      {
        method: 'PUT',
        body,
      },
    )

  getSnapshotMinDate = ({ query: { teamId, synergyType, exportType } }: $TSFixMe) => {
    return this.call({
      host: Config.JOB_SERVER,
      pathname: `/teams/${teamId}/value-capture/v2/initiatives-v2/export/list-first-available-date`,
      query: { synergyType, exportType },
    })
  }

  startUserLoginTimeTracking = () => {
    return this.call({ pathname: '/users/login' }, { method: 'POST' })
  }
}

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'
import {
  fetchInitiativeList,
  fetchInfiniteInitiativeList,
  fetchInitiativeFiltersAndLength,
  updateSelectedAndMirroredInitiatives,
  createInitiative,
  deleteInitiative,
  deleteInitiativeAttachment,
  uploadInitiativeAttachments,
  fetchAllInitiativeMetadata,
  preUpdateInitiative,
} from './initiativeList'
import { fetchFinancials, updateFinancials, populateInitiative } from './financials'
import {
  fetchInitiativeCommentsList,
  fetchStageGateValidationStatus,
  createInitiativeComment,
  updateStageGate,
} from './stageGate'
import { fetchInitiativesCategories } from '@teamValueCaptureV2/initiativeList/sagas/initiativesCategories'
import { getSubLinesForMirroring, saveInitiativeMirrorTeams } from './initiativeMirrorTeam'

export default function* initiativeListSagaV2() {
  yield all([
    takeLatest(constants.FETCH_INITIATIVE_LIST, fetchInitiativeList),
    takeLatest(constants.FETCH_INFINITE_INITIATIVE_LIST, fetchInfiniteInitiativeList),
    takeLatest(constants.FETCH_ALL_INITIATIVE_METADATA, fetchAllInitiativeMetadata),
    takeLatest(constants.FETCH_INITIATIVE_FILTERS_AND_LENGTH, fetchInitiativeFiltersAndLength),
    takeLatest(constants.CREATE_INITIATIVE, createInitiative),
    takeLatest(constants.UPDATE_INITIATIVE, preUpdateInitiative),
    takeLatest(constants.DELETE_INITIATIVE, deleteInitiative),
    takeLatest(constants.UPDATE_SELECTED_TEAM_AND_MIRRORED_INITIATIVES, updateSelectedAndMirroredInitiatives),
    // Monthly pipeline status

    takeLatest(constants.FETCH_FINANCIALS, fetchFinancials),
    takeLatest(constants.UPDATE_FINANCIALS_PENDING, updateFinancials),

    // Stage gate
    takeLatest(constants.FETCH_INITIATIVE_COMMENTS_LIST, fetchInitiativeCommentsList),
    takeLatest(constants.FETCH_STAGE_GATE_VALIDATION_STATUS, fetchStageGateValidationStatus),
    takeLatest(constants.CREATE_INITIATIVE_COMMENT, createInitiativeComment),
    takeLatest(constants.UPDATE_STAGE_GATE, updateStageGate),

    // initiative attachments
    takeLatest(constants.DELETE_INITIATIVE_ATTACHMENT, deleteInitiativeAttachment),
    takeLatest(constants.UPLOAD_INITIATIVE_ATTACHMENTS, uploadInitiativeAttachments),
    takeLatest(constants.POPULATE_INITIATIVE, populateInitiative),

    //Categories
    takeLatest(constants.FETCH_INITIATIVES_CATEGORIES, fetchInitiativesCategories),

    takeLatest(constants.SET_MIRROR_TEAMS, saveInitiativeMirrorTeams),
    // Mirroring sub-lines
    takeLatest(constants.GET_SUB_LINES_FOR_MIRRORING, getSubLinesForMirroring),
  ])
}

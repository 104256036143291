import HttpClient, { FetchFn } from '../HttpClient'
import Config from '@root/src/config'

type Query = { teamId: number; projectId: number; taskId: number; interdependencyId: number }

export default class InterdependentTaskWatchersApi extends HttpClient {
  constructor(fetch: FetchFn) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  watchInterdependentTask = ({ query }: { query: Query }) => {
    const { teamId, taskId, interdependencyId } = query
    return this.call(
      {
        pathname: `teams/${teamId}/tasks/${taskId}/interdependency-tasks/${interdependencyId}/watch`,
      },
      { method: 'POST' },
    )
  }

  unwatchInterdependentTask = ({ query }: { query: Query }) => {
    const { teamId, taskId, interdependencyId } = query

    return this.call(
      {
        pathname: `teams/${teamId}/tasks/${taskId}/interdependency-tasks/${interdependencyId}/unwatch`,
      },
      { method: 'POST' },
    )
  }

  fetchInterdependentTaskWatchers = ({ query }: { query: Query }) => {
    const { teamId, taskId, interdependencyId } = query

    return this.call({
      pathname: `teams/${teamId}/tasks/${taskId}/interdependency-tasks/${interdependencyId}/watchers`,
    })
  }
}

import { handleActions } from 'redux-actions'
import * as constants from '@controlPanel/actions/actionTypes'

export interface IControlPanelState {
  controlPanelData: $TSFixMe[] | null
  metricData?: $TSFixMe
}

export const initialState = {
  controlPanelData: null,
  metricData: null,
}

const controlPanelReducer = handleActions<IControlPanelState, $TSFixMe>(
  {
    [constants.SET_CONTROL_PANEL_DATA]: (state, action) => ({ ...state, controlPanelData: action.payload }),
    [constants.SET_METRIC_DATA]: (state, action) => ({ ...state, metricData: action.payload }),
  },
  initialState,
)

export default controlPanelReducer

export const GET_STEPS = 'GET_STEPS'
export const SET_STEP_COMPLETED = 'SET_STEP_COMPLETED'

export const SET_CORE_COMPONENT_STATUS = 'SET_CORE_COMPONENT_STATUS'
export const SET_CORE_COMPONENT_STATUSES = 'SET_CORE_COMPONENT_STATUSES'
export const SET_MODULE_SELECT_VALUE = 'SET_MODULE_SELECT_VALUE'
export const UPDATE_CUSTOM_COLUMN_NAME = 'UPDATE_CUSTOM_COLUMN_NAME'

export const ADD_DICTIONARY_WORDING = 'ADD_DICTIONARY_WORDING'
export const UPDATE_DICTIONARY_WORDING = 'UPDATE_DICTIONARY_WORDING'
export const DELETE_DICTIONARY_WORDING = 'DELETE_DICTIONARY_WORDING'

export const ADD_DICTIONARY_CUSTOM_COLUMN = 'ADD_DICTIONARY_CUSTOM_COLUMN'
export const DELETE_DICTIONARY_CUSTOM_COLUMN = 'DELETE_DICTIONARY_CUSTOM_COLUMN'
export const UPDATE_DICTIONARY_CUSTOM_COLUMN = 'UPDATE_DICTIONARY_CUSTOM_COLUMN'

export const GET_WELCOME_NOTE = 'GET_WELCOME_NOTE'
export const SET_WELCOME_NOTE_DATA = 'SET_WELCOME_NOTE_DATA'
export const SAVE_WELCOME_NOTE = 'SAVE_WELCOME_NOTE'

export const FETCH_COMPLETENESS_STATUSES = 'FETCH_COMPLETENESS_STATUSES'
export const FETCH_COMPLETENESS_STATUSES_SUCCESS = 'FETCH_COMPLETENESS_STATUSES_SUCCESS'
export const FETCH_COMPLETENESS_STATUSES_STATE = 'FETCH_COMPLETENESS_STATUSES_STATE'

export const RESET_ALL_STEPS = 'RESET_ALL_STEPS'
export const RESET_ALL_STEPS_SUCCESS = 'RESET_ALL_STEPS_SUCCESS'

export const FETCH_USER_AND_LAUNCH = 'FETCH_USER_AND_LAUNCH'
export const LAUNCH_CONFIG = 'LAUNCH_CONFIG'
export const LAUNCH_CONFIG_SUCCESS = 'LAUNCH_CONFIG_SUCCESS'

export const SET_HAS_CHANGES = 'SET_HAS_CHANGES'
export const SET_CONFIG_WARNING_WAS_SHOWED = 'SET_CONFIG_WARNING_WAS_SHOWED'

import styled from 'styled-components'
import { Colors } from '@blueprintjs/core'
import { CustomTooltip } from '@shared/Popover'
import { lightGray, mckDeepBlue, textDarkGrey, Popup } from '@imo/imo-ui-toolkit'
import { ImoGrid } from '@shared/Grid/ImoGrid'

export const UserName = styled.div`
  margin-top: 4px;
  color: ${mckDeepBlue};
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    margin-left: 9px;
    font-size: 12px;
    color: ${lightGray};
    font-weight: normal;
  }
`
export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`

export const TruncateBlock = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
  width: 100%;
  -webkit-line-clamp: 2;
  font-size: 12px;
  line-height: 1.33;
  color: #333333;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 49px);
`

export const StyledGrid = styled(ImoGrid)`
  margin-bottom: 0;

  .complete-toggle-cell {
    display: flex;
    align-items: center;

    .ag-react-container {
      height: auto;
    }
  }

  .ag-header-cell-label .ag-header-cell-text {
    padding-left: 5px;
  }

  .delete-review-cell {
    .ag-react-container {
      text-align: center;
    }
  }

  .review-description-cell {
    line-height: initial;
  }

  &.review-requests-grid {
    height: 450px;
  }

  .button-cell {
    .ag-react-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export const StyledTooltip = styled(CustomTooltip)`
  display: inline-block;
  white-space: nowrap;
`

export const StyledPopup = styled(Popup)`
  width: 779px;
  max-height: 491px;
  overflow-y: hidden;

  .popup-content {
    padding: 0;
  }

  .popup-title-container {
    .icon {
      color: ${Colors.BLUE3};
    }
  }

  .popup-title {
    font-size: 16px/500;
    line-height: 1.31;
    color: ${textDarkGrey};
    border-bottom: none;
  }
`

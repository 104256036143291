import { isArray, map } from 'lodash/fp'
import { createSaga } from '@common/sagaCreator/createSaga'
import { call, put } from 'redux-saga/effects'
import { setHeadcountReduction } from '@teamValueCaptureV2/monthByMonth/actions/actions'
import { getInitiativeListV2Api } from '@common/net'
import { SET_HEADCOUNT_REDUCTION_DATA_STATE } from '@teamValueCaptureV2/monthByMonth/actions/actionTypes'

export const fetchHeadcountReduction = createSaga(function* fetchHeadcountReduction({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, startDate, endDate, categoryIds } = payload

  const query = { teamId, startDate, endDate } as {
    teamId: string
    startDate: string
    endDate: string
    categoryId?: string
  }

  if (isArray(categoryIds) && categoryIds.length) {
    query.categoryId = map(JSON.stringify, categoryIds).toString()
  }

  const api = yield getInitiativeListV2Api()
  const headcountReduction = yield call(
    api.request('getHeadcountReductionData', {
      query,
    }),
  )

  yield put(setHeadcountReduction({ headcountReduction }))
},
SET_HEADCOUNT_REDUCTION_DATA_STATE)

import { isSMO } from '@myImoConfig/containers/ConfigSteps/utils'
import { get, isNil, isEmpty, filter, intersection, reduce } from 'lodash/fp'
import {
  MUTUAL_DISCOVERY_ID,
  ENTANGLEMENTS_AND_PLANNING_ASSUMPTION_ID,
  POST_CLOSE_PLANNING_ASSUMPTION_UPDATE,
} from '@helpers/constants'
import { teams as teamType } from '@common/accessController/constants'

export const getProcessFilterCondition = (item: $TSFixMe, managementType: string) => {
  const accessor = isSMO(managementType) ? 'dayOneSolution' : 'tsa'
  const value = isSMO(managementType) ? 'TSA' : 'Yes'
  const hasTsaValue = get(accessor, item) === value
  const isKeyProcess = isNil(item.keyProcess)

  return isKeyProcess && hasTsaValue
}

export const transformProjectsForSelection = (items: $TSFixMe) => {
  return items.map((item: $TSFixMe) => ({
    displayName: item.name,
    id: item.id,
    projectId: item.projectId,
    projectListId: item.projectListId,
  }))
}

export const getActiveKeyProcesses = ({ managementType, teamActiveDeliverablesIDs, data }: $TSFixMe) => {
  const TSA_DELIVERABLE_ID = isSMO(managementType) ? ENTANGLEMENTS_AND_PLANNING_ASSUMPTION_ID : MUTUAL_DISCOVERY_ID

  const activeTeamTSADeliverablesIDs = intersection(teamActiveDeliverablesIDs, [
    POST_CLOSE_PLANNING_ASSUMPTION_UPDATE,
    TSA_DELIVERABLE_ID,
  ])

  // in case all TSA deliverables are inactive, keyProcesses list should be empty
  if (isEmpty(activeTeamTSADeliverablesIDs)) return []

  return transformProjectsForSelection(
    filter((item: $TSFixMe) => getProcessFilterCondition(item, managementType), data),
  )
}

const getSelectedLinkedProjectIds = (tsaRows: any[], selectedRow: { id: number }) =>
  reduce((acc: [], row: { primaryLinkedProjectId: number | null; id: number }) => {
    if (row?.primaryLinkedProjectId && selectedRow.id !== row.id) {
      // @ts-ignore
      acc.push(row?.primaryLinkedProjectId)
    }

    return acc
  }, [])(tsaRows)

export const getLinkedProjects = ({ selectedRow, projects, tsaRows, activeProjectDeliverablesIDs }: $TSFixMe) => {
  if (isEmpty(activeProjectDeliverablesIDs)) return []

  const selectedLinkedProjectIds = getSelectedLinkedProjectIds(tsaRows, selectedRow)
  const selectedOtherProjectIds = selectedRow.otherLinkedProjects.map(({ id }: $TSFixMe) => id)
  const projectsToExclude = [...selectedLinkedProjectIds, ...selectedOtherProjectIds]
  const result = projects.filter(
    ({ id, type, mirror }: $TSFixMe) => !mirror && !projectsToExclude.includes(id) && type === 'project',
  )

  return transformProjectsForSelection(result)
}

export const getOtherLinkedProjects = ({ selectedRow, data, activeProjectDeliverablesIDs }: $TSFixMe) => {
  if (isEmpty(activeProjectDeliverablesIDs)) return []

  const notSelectedProjects = data.filter(
    ({ id, type, mirror }: $TSFixMe) => !mirror && selectedRow.primaryLinkedProjectId !== id && type === 'project',
  )

  return transformProjectsForSelection(notSelectedProjects)
}

export const getTSAActivityLogSource = ({ selectedTeamType }: $TSFixMe) => {
  switch (selectedTeamType) {
    case teamType.CENTRAL:
      return 'TSA Master List'
    case teamType.INTEGRATION:
      return 'TSA deliverable'
    default:
      return 'All TSAs'
  }
}

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@domain/myImoClient/TSAv2/actions/actionTypes'
import {
  fetchTSA,
  fetchTeamResourcesTSA,
  createTSA,
  deleteTSA,
  updateTSA,
  fetchTSAProcessesFromLibrary,
  addTSAProcessesFromLibrary,
  getSnapshotMinDate,
  getMasterListTSAStatus,
  fetchActivityLog,
} from './TSASagas'

export default function* TSAv2Saga() {
  yield all([
    takeLatest(constants.FETCH_TSA, fetchTSA),
    takeLatest(constants.FETCH_TEAM_RESOURCES_TSA, fetchTeamResourcesTSA),
    takeLatest(constants.CREATE_TSA, createTSA),
    takeLatest(constants.DELETE_TSA, deleteTSA),
    takeLatest(constants.UPDATE_TSA, updateTSA),
    takeLatest(constants.FETCH_TSA_PROCESSES_FROM_LIBRARY, fetchTSAProcessesFromLibrary),
    takeLatest(constants.ADD_TSA_PROCESSES_FROM_LIBRARY, addTSAProcessesFromLibrary),
    takeLatest(constants.GET_SNAPSHOT_MIN_DATE, getSnapshotMinDate),
    takeLatest(constants.GET_MASTER_LIST_TSA_STATUS, getMasterListTSAStatus),
    takeLatest([constants.FETCH_ACTIVITY_LOG_PENDING, constants.FETCH_MORE_ACTIVITY_LOG_PENDING], fetchActivityLog),
  ])
}

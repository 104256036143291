import { getNpvValidationError } from '@views/myImoConfig/components/ModuleOptionsV2/utils'
import { isString, isNumber, isNil } from 'lodash/fp'

const prepareOptions = (options: $TSFixMe, isPriorityItem?: boolean) => {
  return options.map((option: $TSFixMe) => {
    const result: { name: string; id?: number; lockProjectPlan?: boolean } = { name: option.name }

    if (isNumber(option.id)) {
      result.id = option.id
    }

    if (isPriorityItem) {
      result.lockProjectPlan = Boolean(option.lockProjectPlan)
    }

    return result
  })
}

const buildDictionaryPayload = (dictionary: $TSFixMe, isPriorityItem?: boolean) => {
  const isFreeText = dictionary.columnType === 'FreeText'

  if (isString(dictionary.id) || isNil(dictionary.id)) {
    return {
      options: isFreeText ? [] : prepareOptions(dictionary.options, isPriorityItem),
      name: dictionary.name,
      isCustom: dictionary.isCustom,
      active: dictionary.active,
      columnType: dictionary.columnType,
      initiativeType: dictionary.initiativeType,
      advancedFinancialsType: dictionary.advancedFinancialsType,
      characterLimit: isFreeText ? dictionary.characterLimit : null,
    }
  }

  return {
    ...dictionary,
    characterLimit: isFreeText ? dictionary.characterLimit : null,
    options: isFreeText ? [] : prepareOptions(dictionary.options),
  }
}

export const prepareModules = (modules: $TSFixMe) => {
  return modules.map((item: $TSFixMe) => ({
    key: item.key,
    active: item.active,
    locked: item.locked,
    configType: item.configType,
  }))
}

export const prepareModulesSelect = (modulesSelect: $TSFixMe) => {
  return modulesSelect.reduce((acc: $TSFixMe[], item: $TSFixMe) => {
    if (item.key === 'mirroringTeams') {
      acc.push({
        configType: item.configType,
        id: item.id,
        key: item.key,
        value: item.value,
      })

      return acc
    }

    if (item.key === 'lockDueDateD1') return acc

    if (item.key === 'npvSettings') {
      acc.push({
        ...item,
        value: {
          taxRate: getNpvValidationError('taxRate', item.value.taxRate) ? null : item.value.taxRate,
          wacc: getNpvValidationError('wacc', item.value.wacc) ? null : item.value.wacc,
        },
      })

      return acc
    }

    acc.push(item)

    return acc
  }, [])
}

export const prepareDictionaries = (dictionaries: $TSFixMe) => {
  const result: $TSFixMe = {}

  result.valueCapture = dictionaries.valueCapture.map(buildDictionaryPayload)

  result.projectPlan = dictionaries.projectPlan.map((item: $TSFixMe) => {
    if (item.isDefault) return item
    const isPriorityItem = item.name === 'priority'
    return buildDictionaryPayload(item, isPriorityItem)
  })

  result.riskLog = dictionaries.riskLog.map(buildDictionaryPayload)

  result.decisionLog = dictionaries.decisionLog.map(buildDictionaryPayload)

  if (dictionaries.tsa) {
    result.tsa = dictionaries.tsa.map((item: $TSFixMe) => {
      return {
        ...item,
        options: prepareOptions(item.options),
      }
    })
  }

  return result
}

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@teamHome/actions/actionTypes'
import { fetchProjectPlanList } from './fetchProjectPlanList'
import {
  updateInbox,
  updateArchive,
  fetchNotifications,
  archiveEntityNotifications,
  unarchiveEntityNotifications,
  archiveAllNotifications,
} from './notifications'
import { fetchTeamWeekStatus } from './fetchTeamWeekStatus'
import { updateTeamWeekStatus } from './updateTeamWeekStatus'
import { fetchDeliverableTypesStatuses } from './fetchDeliverableTypesStatuses'
import { fetchTeamProjectsStatuses } from './fetchTeamProjectsStatuses'
import { fetchKeyIssues } from './fetchKeyIssues'
import { updateKeyIssue } from './updateKeyIssue'
import { createKeyIssue } from './createKeyIssue'
import { deleteKeyIssue } from './deleteKeyIssue'
import { fetchAllTeamsKeyProcessDetails } from './fetchKeyProcessDetails'
import {
  createOtherTeamUpdate,
  deleteOtherTeamUpdate,
  fetchOtherTeamUpdates,
  updateOtherTeamUpdate,
  submitWeeklyStatusUpdate,
} from '@teamHome/sagas/otherTeamUpdates'
import { fetchFlaggedRisksAndDecisions } from '@teamHome/sagas/flaggedRisksAndDecisions'
import { addNotificationToLoading, removeNotificationFromLoading } from '../actions/actions'

//TODO create separate reducers for each page (My team, Weekly status update etc.)

export default function* teamHomeSaga() {
  yield all([
    takeLatest(constants.FETCH_PROJECT_PLAN_LIST, fetchProjectPlanList),
    takeLatest(constants.ADD_NOTIFICATION_TO_LOADING, addNotificationToLoading),
    takeLatest(constants.REMOVE_NOTIFICATION_FROM_LOADING, removeNotificationFromLoading),
    takeLatest(constants.FETCH_NOTIFICATIONS, fetchNotifications),
    takeLatest(constants.UPDATE_INBOX_NOTIFICATIONS, updateInbox),
    takeLatest(constants.UPDATE_ARCHIVE_NOTIFICATIONS, updateArchive),
    takeLatest(constants.ARCHIVE_ENTITY_NOTIFICATIONS, archiveEntityNotifications),
    takeLatest(constants.UNARCHIVE_ENTITY_NOTIFICATIONS, unarchiveEntityNotifications),
    takeLatest(constants.ARCHIVE_ALL_NOTIFICATIONS, archiveAllNotifications),
    takeLatest(constants.FETCH_TEAM_WEEK_STATUS, fetchTeamWeekStatus),
    takeLatest(constants.UPDATE_TEAM_WEEKLY_STATUS, updateTeamWeekStatus),
    takeLatest(constants.FETCH_DELIVERABLE_TYPES_STATUSES, fetchDeliverableTypesStatuses),
    takeLatest(constants.FETCH_TEAM_PROJECTS_STATUSES, fetchTeamProjectsStatuses),
    takeLatest(constants.FETCH_KEY_ISSUES, fetchKeyIssues),
    takeLatest(constants.CREATE_KEY_ISSUE, createKeyIssue),
    takeLatest(constants.UPDATE_KEY_ISSUE, updateKeyIssue),
    takeLatest(constants.DELETE_KEY_ISSUE, deleteKeyIssue),
    takeLatest(constants.FETCH_OTHER_TEAM_UPDATES, fetchOtherTeamUpdates),
    takeLatest(constants.CREATE_OTHER_TEAM_UPDATE, createOtherTeamUpdate),
    takeLatest(constants.UPDATE_OTHER_TEAM_UPDATE, updateOtherTeamUpdate),
    takeLatest(constants.DELETE_OTHER_TEAM_UPDATE, deleteOtherTeamUpdate),
    takeLatest(constants.FETCH_FLAGGED_RISKS_AND_DECISIONS, fetchFlaggedRisksAndDecisions),
    takeLatest(constants.SUBMIT_WEEKLY_STATUS_UPDATE, submitWeeklyStatusUpdate),
    takeLatest(constants.FETCH_KEY_PROCESS_DELETE_DETAILS, fetchAllTeamsKeyProcessDetails),
  ])
}

// Libs
import { call, put } from 'redux-saga/effects'

// Actions
import { setProjectPlanList } from '@teamHome/actions/actions'

// Utils
import { getMyTeamApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export interface FetchProjectPlanListActionPayload {
  teamId: number
  paginationParams?: { offset?: number; limit?: number }
  withoutInterdependencies?: boolean
  isTsaExitPlans?: boolean
  isProgramProjectPlan?: boolean
}

export const fetchProjectPlanList = createSaga(function* fetchProjectPlanList(action: {
  payload: FetchProjectPlanListActionPayload
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const {
    teamId,
    paginationParams,
    withoutInterdependencies,
    isTsaExitPlans,
    isProgramProjectPlan = false,
  } = action.payload

  const query = isTsaExitPlans
    ? { teamId, isTsaExitPlans }
    : paginationParams
    ? { teamId, ...paginationParams, withoutInterdependencies }
    : { teamId, withoutInterdependencies }

  const api = yield getMyTeamApi()
  const projectPlanList = isProgramProjectPlan
    ? yield call(api.request('getProgramProjectList', { query }))
    : yield call(api.request('getProjectPlanList', { query }))

  yield put(setProjectPlanList({ projectPlanList }))
})

import { ACTION_TYPES, ProcessStatus } from '@imo/imo-ui-toolkit'
import {
  getFilterForRequest,
  getSortingForRequest,
} from '@myImoClient/components/TeamValueCaptureV2/InitiativeList/utils'
import { isEmpty, isNull, pick } from 'lodash/fp'
import { initiativeFields } from '@teamValueCaptureV2/initiativeList/sagas/constants'
import { SynergyTypes } from '@helpers/constants'

export const refreshPageDialogParams = {
  actionType: ACTION_TYPES.FAIL,
  errorMessage: 'A new version of this page is available. Would you like to refresh the page?',
  additionalAction: {
    text: 'Refresh',
    icon: 'updated',
    onClick: () => window.location.reload(),
  },
  timeout: 0,
}

export const getItemsByTeam = (subLinesData: $TSFixMe[]) =>
  subLinesData.flatMap((it) => {
    if (it.isTeam && it.selected) {
      return [
        {
          teamId: it.id,
          netImpactItemIds: subLinesData.flatMap((subLine) =>
            subLine.teamId === it.id && subLine.teamId && subLine.selected ? [subLine.id] : [],
          ),
        },
      ]
    }

    return []
  })

export const getBodyParams = ({ body, sorting, filters, customColumns }: $TSFixMe) => {
  const bodyParams = body

  if (getSortingForRequest(sorting, customColumns)) {
    bodyParams.sort = JSON.stringify(getSortingForRequest(sorting, customColumns))
  }

  if (!isEmpty(getFilterForRequest(filters, customColumns))) {
    bodyParams.filters = JSON.stringify(getFilterForRequest(filters, customColumns))
  }

  return bodyParams
}

export const getInitiativeForUpdate = (data: $TSFixMe, synergyType: SynergyTypes) => {
  const newData = pick(initiativeFields[synergyType], data)
  const { status } = data

  if (isNull(status) || status === ProcessStatus.ON_HOLD) newData.status = status

  return newData
}

import { call } from 'redux-saga/effects'
import { getTeamResourcesApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const uploadMasterCalendarAttachment = createSaga(function* uploadMasterCalendarAttachment(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { eventId, file } = action
  const formData = new FormData()
  formData.append('attachment', file)
  const api = yield getTeamResourcesApi()

  return yield call(api.request('uploadCalendarAttachment', { query: { eventId }, body: formData }))
})

export const deleteMasterCalendarAttachment = createSaga(function* deleteMasterCalendarAttachment(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { eventId, fileId } = action
  const api = yield getTeamResourcesApi()
  yield call(api.request('deleteCalendarAttachment', { query: { eventId, fileId } }))

  return true
})

import { assoc, map, flow, getOr, merge, sortBy, groupBy, values } from 'lodash/fp'
import { FINANCIAL_MONTH_NAMES, FINANCIALS_FIELD } from '@helpers/financialsConstants'
import { defaultCategories } from '@helpers/constants'
import { sortTeamRoles } from '@common/accessController/utils'

const buildAccumulator = (objNames: $TSFixMe) => {
  return Object.keys(objNames).reduce((acc, name) => {
    acc[name] = { name: objNames[name], additional: {}, [FINANCIALS_FIELD.DATA_UPDATE]: {}, [FINANCIALS_FIELD.MONTH_EXPLANATION]: {}, }

    return acc
  }, {} as { [key: string]: $TSFixMe; name?: string; additional?: $TSFixMe })
}

export const setupFinancialRows = (data: $TSFixMe) => {
  const fields = Object.keys(FINANCIAL_MONTH_NAMES)

  const objectRows = data.reduce((acc: $TSFixMe, item: $TSFixMe) => {
    fields.forEach((field) => {
      acc[field][item.year] = item[field]
      acc[field][FINANCIALS_FIELD.INITIATIVE_ID] = item[FINANCIALS_FIELD.INITIATIVE_ID]
      acc[field][FINANCIALS_FIELD.SUBTYPE1] = item[FINANCIALS_FIELD.SUBTYPE1]
      acc[field][FINANCIALS_FIELD.SUBTYPE2] = item[FINANCIALS_FIELD.SUBTYPE2]
      acc[field][FINANCIALS_FIELD.SUBTYPE3] = item[FINANCIALS_FIELD.SUBTYPE3]
      acc[field][FINANCIALS_FIELD.SYNERGY_TYPE] = item[FINANCIALS_FIELD.SYNERGY_TYPE]
      acc[field][FINANCIALS_FIELD.MONTH_EXPLANATION][item.year] = item[FINANCIALS_FIELD.MONTH_EXPLANATION][field]

      if (item[FINANCIALS_FIELD.ADDITIONAL]) {
        acc[field][FINANCIALS_FIELD.ADDITIONAL][item.year] = item.additional[field]
      }

      acc[field][FINANCIALS_FIELD.DATA_UPDATE][item.year] = getOr(null, [FINANCIALS_FIELD.DATA_UPDATE, field], item)
    })

    return acc
  }, buildAccumulator(FINANCIAL_MONTH_NAMES))

  return Object.values(objectRows)
}

export const prepareFinancialsBody = ({ data }: $TSFixMe) => {
  const updatedData = flow([
    groupBy(FINANCIALS_FIELD.YEAR),
    values,
    map((yearData: $TSFixMe) => {
      return Object.assign({}, ...yearData)
    }),
  ])(data)

  return updatedData
}

export const updateItems = ({ data, items, key }: $TSFixMe) => {
  items.forEach((item: $TSFixMe) => {
    const index = data?.findIndex((el: $TSFixMe) => el[key] === item[key])
    if (index < 0) {
      data.concat(item)
    } else {
      const updatedItem = merge(data[index], item)

      data = assoc(index, updatedItem, data)
    }
  })

  return data
}

export const formattedCategories = (categories: $TSFixMe, synergyType: $TSFixMe) => {
  const categoriesByType = getOr([], synergyType, categories)

  return [
    {
      name: defaultCategories.ALL_CATEGORIES,
      id: defaultCategories.ALL_CATEGORIES,
    },
    ...sortBy([({ id }) => !id, ({ name }) => name.toLowerCase()], categoriesByType),
  ]
}

export const getTransformedTeamRoles = (userRoles: $TSFixMe) => {
  const transformedTeamRoles = userRoles.map((role: $TSFixMe) => ({
    ...role,
    longName: role?.longName || role?.teamName,
    role: role?.role || role?.roleName,
  }))

  return sortTeamRoles(transformedTeamRoles)
}

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'

export const batchUploadFile = createAction(constants.BATCH_UPLOAD_FILE)
export const batchUploadInitiatives = createAction(constants.BATCH_UPLOAD_VC_INITIATIVES_FILE)
export const batchUploadFileSuccess = createAction(constants.BATCH_UPLOAD_FILE_SUCCESS)
export const setBatchUploadResult = createAction(constants.SET_BATCH_UPLOAD_RESULT)
export const setBatchFileUploadErrors = createAction(constants.SET_BATCH_FILE_UPLOAD_ERRORS)
export const batchFileUploadReset = createAction(constants.BATCH_FILE_UPLOAD_RESET)
export const setBatchFile = createAction(constants.SET_BATCH_FILE)
export const setFileLoaded = createAction(constants.SET_FILE_LOADED)
export const downloadBatchUploadTemplate = createAction(constants.DOWNLOAD_BATCH_UPLOAD_TEMPLATE)

import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getToggleSettings } from '@generic/selectors'
import { Container } from '@myImoClient/components/Header/AppliedFilters/index.styles'
import { isEmpty } from 'lodash/fp'
import { getModules, getAdvancedFinancialsModule, getValueCaptureModule } from '@domain/instanceConfig/selectors'
import { appliedFiltersConfig, renderIcon } from '@myImoClient/components/Header/AppliedFilters/config'
import FilterIcon from '@myImoClient/components/Header/AppliedFilters/FilterIcon'
import { matchPath, useMatches } from 'react-router-dom'
import { routesList } from '@root/src/common/accessController/routesList'

const AppliedFilters = () => {
  const routeMatches = useMatches()

  const isVcTracker = routeMatches.some((match) =>
    matchPath(routesList.VALUE_CAPTURE_TRACKER_V2.ROOT.route, match.pathname),
  )

  const isTeamVc = routeMatches.some((match) =>
    matchPath(routesList.DASHBOARD.MY_TEAM.VALUE_CAPTURE_V2.ROOT.route, match.pathname),
  )

  const toggleSettings = useSelector(getToggleSettings)
  const modules = useSelector(getModules)
  const advancedFinancialsModule = useSelector(getAdvancedFinancialsModule)
  const valueCaptureModule = useSelector(getValueCaptureModule)
  const [filterList, updateFilterList] = useState(appliedFiltersConfig)

  useEffect(() => {
    if (!isEmpty(toggleSettings)) {
      updateFilterList(renderIcon(toggleSettings, advancedFinancialsModule.active))
    }
  }, [toggleSettings, advancedFinancialsModule.active])

  const isTogglesEmpty = isEmpty(toggleSettings)
  const isNotVCPages = !isVcTracker && !isTeamVc
  const isVCTrackerDisabled = !isEmpty(modules) && !valueCaptureModule.active

  if (isTogglesEmpty || isNotVCPages || isVCTrackerDisabled) return null

  return (
    <Container>
      {filterList.map((item) => (
        <FilterIcon isVcTracker={isVcTracker} key={item.id} toggleSettings={toggleSettings} {...item} />
      ))}
    </Container>
  )
}

export default memo(AppliedFilters)

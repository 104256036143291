import { call } from 'redux-saga/effects'
import { getTeamCharterApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const updateTeamCharterData = createSaga(function* updateTeamCharterData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, ...updatedData } = action.payload

  const teamCharterApi = yield getTeamCharterApi()
  yield call(
    teamCharterApi.request('updateTeamCharterData', {
      body: updatedData,
      query: { teamId },
    }),
  )
},
true)

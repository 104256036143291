import { all, takeLatest, takeEvery } from 'redux-saga/effects'
import * as constants from '@communications/actions/actionTypes'

import { fetchCommunicationsData } from './fetchCommunicationsData'
import { fetchColumnsData } from './fetchColumnsData'
import { updateColumnsData } from './updateColumnsData'
import { deleteColumnsData } from './deleteColumnsData'
import { saveRow } from './saveRow'
import { deleteRows } from './deleteRows'
import { createRequest } from './createRequest'
import { fetchRequests } from './fetchRequests'
import { updateRequest } from './updateRequest'
import { deleteRequest } from './deleteRequest'

import { saveEvent } from './saveEvent'
import { fetchEvent } from './fetchEvent'
import { deleteEvents } from './deleteEvents'
import { uploadEventAttachment } from './uploadEventAttachment'
import { deleteEventAttachment } from './deleteEventAttachment'

export default function* communicationsSaga() {
  yield all([
    takeLatest(constants.FETCH_COMMUNICATIONS_DATA, fetchCommunicationsData),
    takeLatest(constants.FETCH_COLUMNS_DATA, fetchColumnsData),
    takeLatest(constants.UPDATE_COLUMNS_DATA, updateColumnsData),
    takeLatest(constants.DELETE_COLUMNS_DATA, deleteColumnsData),
    takeLatest(constants.SAVE_ROW, saveRow),
    takeLatest(constants.DELETE_ROWS, deleteRows),
    takeLatest(constants.CREATE_REQUEST, createRequest),
    takeLatest(constants.FETCH_REQUESTS, fetchRequests),
    takeLatest(constants.UPDATE_REQUEST, updateRequest),
    takeLatest(constants.DELETE_REQUEST, deleteRequest),
    takeLatest(constants.SAVE_EVENT, saveEvent),
    takeLatest(constants.FETCH_EVENT, fetchEvent),
    takeLatest(constants.DELETE_EVENTS, deleteEvents),
    takeEvery(constants.UPLOAD_ATTACHMENT, uploadEventAttachment),
    takeEvery(constants.DELETE_ATTACHMENT, deleteEventAttachment),
  ])
}

import { createSaga } from '@common/sagaCreator/createSaga'
import { getTeamCharterApi } from '@common/net'
import { call, put } from 'redux-saga/effects'
import * as actions from '../actions/actions'
import { getOmittedData } from '../helpers'
import { fetchTeamCharterData } from '../actions/actions'

export const fetchTeamCharterLibraryData = createSaga(function* fetchTeamCharterLibraryData({
  payload: { teamId, libraryId },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamCharterApi()
  const data = yield call(
    api.request('getTeamCharterLibraryData', {
      query: { teamId, id: libraryId },
    }),
  )

  yield put(actions.setTeamCharterLibraryData(data))
})

export const saveTeamCharterLibraryData = createSaga(function* saveTeamCharterLibraryData({
  payload: { teamId, libraryId, body },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamCharterApi()

  yield call(
    api.request('saveTeamCharterLibraryData', {
      query: { teamId, id: libraryId, body: getOmittedData(body) },
    }),
  )

  yield put(fetchTeamCharterData({ teamId }))
})

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'

export const fetchKnowledgeCenterData = createAction(constants.FETCH_KNOWLEDGE_CENTER_DATA)
export const fetchReadModeArticles = createAction(constants.FETCH_READ_MODE_ARTICLES)
export const setKnowledgeCenterVideo = createAction(constants.SET_KNOWLEDGE_CENTER_VIDEO)
export const setKnowledgeCenterArticles = createAction(constants.SET_KNOWLEDGE_CENTER_ARTICLES)
export const setKnowledgeCenterIntroductionMaterials = createAction(
  constants.SET_KNOWLEDGE_CENTER_INTRODUCTION_MATERIALS,
)

export const createNewKnowledgeCenterVideo = createAction(constants.CREATE_NEW_KNOWLEDGE_CENTER_VIDEO)
export const addKnowledgeCenterVideo = createAction(constants.ADD_KNOWLEDGE_CENTER_VIDEO)
export const updateKnowledgeCenterVideo = createAction(constants.UPDATE_KNOWLEDGE_CENTER_VIDEO)
export const deleteKnowledgeCenterVideo = createAction(constants.DELETE_KNOWLEDGE_CENTER_VIDEO)
export const updateVideosOrder = createAction(constants.UPDATE_VIDEOS_ORDER)
export const saveVideosOrder = createAction(constants.SAVE_VIDEOS_ORDER)
export const resetCreateKnowledgeCenterVideosState = createAction(constants.RESET_CREATE_KNOWLEDGE_CENTER_VIDEO_STATE)

export const createNewKnowledgeCenterArticle = createAction(constants.CREATE_NEW_KNOWLEDGE_CENTER_ARTICLE)
export const updateKnowledgeCenterArticle = createAction(constants.UPDATE_KNOWLEDGE_CENTER_ARTICLE)
export const updateArticlesOrder = createAction(constants.UPDATE_ARTICLES_ORDER)
export const saveArticlesOrder = createAction(constants.SAVE_ARTICLES_ORDER)
export const deleteKnowledgeCenterArticle = createAction(constants.DELETE_KNOWLEDGE_CENTER_ARTICLE)
export const resetCreateKnowledgeCenterArticleState = createAction(
  constants.RESET_CREATE_KNOWLEDGE_CENTER_ARTICLE_STATE,
)

export const createNewIntroductionMaterial = createAction(constants.CREATE_NEW_INTRODUCTION_MATERIAL)
export const updateIntroductionMaterial = createAction(constants.UPDATE_INTRODUCTION_MATERIAL)
export const updateIntroductionMaterialOrder = createAction(constants.UPDATE_INTRODUCTION_MATERIAL_ORDER)
export const saveIntroductionMaterialOrder = createAction(constants.SAVE_INTRODUCTION_MATERIAL_ORDER)
export const deleteIntroductionMaterial = createAction(constants.DELETE_INTRODUCTION_MATERIAL)
export const resetCreateIntroductionMaterialState = createAction(constants.RESET_CREATE_INTRODUCTION_MATERIAL_STATE)
export const fetchIntroductionMaterialsAndDownload = createAction(constants.FETCH_INTRODUCTION_MATERIALS_AND_DOWNLOAD)

export const setSelectedItem = createAction(constants.SET_SELECTED_ITEM)
export const setSelectedItemErrors = createAction(constants.SET_SELECTED_ITEM_ERRORS)

export const changeEditModalState = createAction(constants.CHANGE_EDIT_MODAL_STATE)

export const setActivePage = createAction(constants.SET_ACTIVE_PAGE)

export const shareIntroductionMaterial = createAction(constants.SHARE_INTRODUCTION_MATERIAL)
export const shareKnowledgeCenterArticle = createAction(constants.SHARE_KNOWLEDGE_CENTER_ARTICLE)
export const shareKnowledgeCenterVideo = createAction(constants.SHARE_KNOWLEDGE_CENTER_VIDEO)

import { filter, isEmpty, mergeWith, unionBy, groupBy, pick } from 'lodash'
import { listItemTypes } from '../constants'
import { getRowNodeIdProcessItem } from '@shared/DayOne/utils/mapDayOneData'

const getChildren = (selectedItem: $TSFixMe, childType: $TSFixMe, allItems: $TSFixMe) => {
  const { id, type, teamId } = selectedItem
  const keyToFilter = type === listItemTypes.KEY_PROCESS ? 'keyProcessId' : 'projectId'

  if (childType === listItemTypes.PROJECT) {
    return filter(allItems, (item) => teamId === item.teamId && item.type === childType && item.keyProcessId === id)
  } else if (childType === listItemTypes.TASK) {
    return filter(allItems, (item) => teamId === item.teamId && item.type === childType && item[keyToFilter] === id)
  }
}

const mergeProjects = (objValue: $TSFixMe, srcValue: $TSFixMe) => unionBy(objValue, srcValue, getRowNodeIdProcessItem)

// To delete row show in confirmation its name and names of its children.
// Data about parents isn't needed.
// E.g. if selected row is project, return project data and all its childs
export const getDeletionData = (selectedRows: $TSFixMe, allRows: $TSFixMe) => {
  const dataToDelete = {
    keyProcesses: [],
    projects: [],
    tasks: [],
    rowsToDelete: [],
  } as $TSFixMe

  selectedRows.forEach((row: $TSFixMe) => {
    if (isEmpty(row) || row.isIncoming) {
      return dataToDelete
    }

    dataToDelete.rowsToDelete.push(row)

    switch (row.type) {
      case listItemTypes.KEY_PROCESS:
        return mergeWith(
          dataToDelete,
          {
            keyProcesses: [row],
            projects: getChildren(row, listItemTypes.PROJECT, allRows),
            tasks: getChildren(row, listItemTypes.TASK, allRows),
          },
          mergeProjects,
        )
      case listItemTypes.PROJECT:
        return mergeWith(
          dataToDelete,
          {
            projects: [row],
            tasks: getChildren(row, listItemTypes.TASK, allRows),
          },
          mergeProjects,
        )
      default:
        return mergeWith(dataToDelete, { tasks: [row] }, mergeProjects)
    }
  })

  return dataToDelete
}

export const getDeleteMultipleItemsData = (selectedRows: $TSFixMe) => {
  return groupBy(
    selectedRows
      .filter(({ isIncoming }: $TSFixMe) => !isIncoming)
      .map((item: $TSFixMe) => pick(item, ['teamId', 'id', 'type', 'projectListId'])),
    'teamId',
  )
}

import { get, isNil, isNull, toNumber } from 'lodash'

export const caseInsensitiveComparator = (valueA, valueB, locale = 'en') =>
  valueA.localeCompare(valueB, locale, { caseFirst: 'upper' })

// TODO: get rid of stringComparator and start using it from ui-toolkit.
export const stringComparator = (valueA, valueB) => {
  if (isNil(valueA)) return -1

  if (isNil(valueB)) return 1

  const LOCALE = undefined

  return caseInsensitiveComparator(valueA.toString(), valueB.toString(), LOCALE)
}

export const arrayComparator =
  (selector) =>
  (arrayA = [], arrayB = []) => {
    const valuesA = arrayA.map(selector)
    const valuesB = arrayB.map(selector)

    return stringComparator(valuesA.toString(), valuesB.toString())
  }

export const numberComparator = (valueA, valueB) => {
  if (isNil(valueA)) return -1

  if (isNil(valueB)) return 1

  const valA = toNumber(valueA)
  const valB = toNumber(valueB)

  return valA - valB
}

export const customStringComparator = (valueA = '', valueB = '', nodeA, nodeB) => {
  const parentA = get(nodeA, ['parent', 'key'])
  const parentB = get(nodeB, ['parent', 'key'])

  if (!isNull(parentA) && !isNull(parentB)) {
    return stringComparator(valueA, valueB)
  }

  return null
}

export const stagesComparator = (options) => (valueA, valueB) => {
  if (!valueA || !valueB) return 0
  const valA = valueA.stage ?? valueA
  const valB = valueB.stage ?? valueB
  const valAIndex = valueA.unlocked ? options.indexOf(valA) : -1
  const valBIndex = valueB.unlocked ? options.indexOf(valB) : -1
  return valAIndex - valBIndex
}

export const statusComparator = (options) => (valueA, valueB) => {
  if (!valueA || !valueB) return 0
  const valA = valueA.label ?? valueA
  const valB = valueB.label ?? valueB
  return options.indexOf(valA.toLowerCase()) - options.indexOf(valB.toLowerCase())
}

export const booleanComparator = (valueA, valueB) => {
  if (!valueA || !valueB) return 0
  const valAIndex = valueA.unlocked ? Number(valueA.value) : -1
  const valBIndex = valueB.unlocked ? Number(valueB.value) : -1
  return valAIndex - valBIndex
}

const prefix = '@teamDeliverables/'

export const FETCH_KNOWLEDGE_CENTER_DATA = `${prefix}FETCH_KNOWLEDGE_CENTER_DATA`

export const FETCH_READ_MODE_ARTICLES = `${prefix}FETCH_READ_MODE_ARTICLES`

export const SET_KNOWLEDGE_CENTER_VIDEO = `${prefix}SET_KNOWLEDGE_CENTER_VIDEO`
export const ADD_KNOWLEDGE_CENTER_VIDEO = `${prefix}ADD_KNOWLEDGE_CENTER_VIDEO`
export const SET_KNOWLEDGE_CENTER_VIDEO_STATE = `${prefix}SET_KNOWLEDGE_CENTER_VIDEO_STATE`

export const SET_KNOWLEDGE_CENTER_ARTICLES = `${prefix}SET_KNOWLEDGE_CENTER_ARTICLES`
export const SET_KNOWLEDGE_CENTER_ARTICLES_STATE = `${prefix}SET_KNOWLEDGE_CENTER_ARTICLES_STATE`

export const SET_KNOWLEDGE_CENTER_INTRODUCTION_MATERIALS = `${prefix}SET_KNOWLEDGE_CENTER_INTRODUCTION_MATERIALS`
export const SET_KNOWLEDGE_CENTER_INTRODUCTION_MATERIALS_STATE = `${prefix}SET_KNOWLEDGE_CENTER_INTRODUCTION_MATERIALS_STATE`

export const CREATE_NEW_KNOWLEDGE_CENTER_VIDEO = `${prefix}CREATE_NEW_KNOWLEDGE_CENTER_VIDEO`
export const CREATE_NEW_KNOWLEDGE_CENTER_VIDEO_STATE = `${prefix}CREATE_NEW_KNOWLEDGE_CENTER_VIDEO_STATE`
export const UPDATE_KNOWLEDGE_CENTER_VIDEO = `${prefix}UPDATE_KNOWLEDGE_CENTER_VIDEO`
export const UPDATE_KNOWLEDGE_CENTER_VIDEO_STATE = `${prefix}UPDATE_KNOWLEDGE_CENTER_VIDEO_STATE`
export const UPDATE_VIDEOS_ORDER = `${prefix}UPDATE_VIDEOS_ORDER`
export const SAVE_VIDEOS_ORDER = `${prefix}SAVE_VIDEOS_ORDER`
export const DELETE_KNOWLEDGE_CENTER_VIDEO = `${prefix}DELETE_KNOWLEDGE_CENTER_VIDEO`
export const RESET_CREATE_KNOWLEDGE_CENTER_VIDEO_STATE = `${prefix}RESET_CREATE_KNOWLEDGE_CENTER_VIDEO_STATE`

export const CREATE_NEW_KNOWLEDGE_CENTER_ARTICLE = `${prefix}CREATE_NEW_KNOWLEDGE_CENTER_ARTICLE`
export const CREATE_NEW_KNOWLEDGE_CENTER_ARTICLE_STATE = `${prefix}CREATE_NEW_KNOWLEDGE_CENTER_ARTICLE_STATE`
export const UPDATE_KNOWLEDGE_CENTER_ARTICLE = `${prefix}UPDATE_KNOWLEDGE_CENTER_ARTICLE`
export const UPDATE_KNOWLEDGE_CENTER_ARTICLE_STATE = `${prefix}UPDATE_KNOWLEDGE_CENTER_ARTICLE_STATE`
export const UPDATE_ARTICLES_ORDER = `${prefix}UPDATE_ARTICLES_ORDER`
export const SAVE_ARTICLES_ORDER = `${prefix}SAVE_ARTICLES_ORDER`
export const DELETE_KNOWLEDGE_CENTER_ARTICLE = `${prefix}DELETE_KNOWLEDGE_CENTER_ARTICLE`
export const RESET_CREATE_KNOWLEDGE_CENTER_ARTICLE_STATE = `${prefix}RESET_CREATE_KNOWLEDGE_CENTER_ARTICLE_STATE`

export const CREATE_NEW_INTRODUCTION_MATERIAL = `${prefix}CREATE_NEW_INTRODUCTION_MATERIAL`
export const CREATE_NEW_INTRODUCTION_MATERIAL_STATE = `${prefix}CREATE_NEW_INTRODUCTION_MATERIAL_STATE`
export const UPDATE_INTRODUCTION_MATERIAL = `${prefix}UPDATE_INTRODUCTION_MATERIAL`
export const UPDATE_INTRODUCTION_MATERIAL_STATE = `${prefix}UPDATE_INTRODUCTION_MATERIAL_STATE`
export const UPDATE_INTRODUCTION_MATERIAL_ORDER = `${prefix}UPDATE_INTRODUCTION_MATERIAL_ORDER`
export const SAVE_INTRODUCTION_MATERIAL_ORDER = `${prefix}SAVE_INTRODUCTION_MATERIAL_ORDER`
export const DELETE_INTRODUCTION_MATERIAL = `${prefix}DELETE_INTRODUCTION_MATERIAL`
export const RESET_CREATE_INTRODUCTION_MATERIAL_STATE = `${prefix}RESET_CREATE_INTRODUCTION_MATERIAL_STATE`
export const FETCH_INTRODUCTION_MATERIALS_AND_DOWNLOAD = `${prefix}FETCH_INTRODUCTION_MATERIALS_AND_DOWNLOAD`

export const SET_SELECTED_ITEM = `${prefix}SET_SELECTED_ITEM`
export const SET_SELECTED_ITEM_ERRORS = `${prefix}SET_SELECTED_ITEM_ERRORS`

export const CHANGE_EDIT_MODAL_STATE = `${prefix}CHANGE_EDIT_MODAL_STATE`

export const SET_ACTIVE_PAGE = `${prefix}SET_ACTIVE_PAGE`

export const SHARE_INTRODUCTION_MATERIAL = `${prefix}SHARE_INTRODUCTION_MATERIAL`
export const SHARE_KNOWLEDGE_CENTER_ARTICLE = `${prefix}SHARE_KNOWLEDGE_CENTER_ARTICLE`
export const SHARE_KNOWLEDGE_CENTER_VIDEO = `${prefix}SHARE_KNOWLEDGE_CENTER_VIDEO`

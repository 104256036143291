export const loginErrors = {
  A00: 'You have entered an invalid username or password. Please try again.',
  A01: 'Your account is currently locked due to too many incorrect sign in attempts. Please try again later.',
  A02: 'Your password has expired. Please reset your password to continue.',
  A03: 'Multi factor authentication was unsuccessful. Please try again.',
  B00: 'You have entered an invalid username or password. Please try again.',
  B01: 'Connection to the identity verification platform was unsuccessful. Please try again.',
  B02: 'You are attempting to login too frequently. Please try again late.',
  B03: 'Connection to the identity verification platform was unsuccessful. Please try again.',
  B04: 'Connection to the identity verification platform was unsuccessful. Please try again.',
  B05: 'Connection to the identity verification platform was unsuccessful. Please try again.',
  B06: 'Connection to the identity verification platform was unsuccessful. Please try again.',
  F00: 'Connection to the identity verification platform was unsuccessful. Please try again.',
  F01: 'Connection to the identity verification platform was unsuccessful. Please try again.',
  F02: 'Connection to the identity verification platform was unsuccessful. Please try again.',
  F03: 'Connection to the identity verification platform was unsuccessful. Please try again.',
  F04: 'Connection to the identity verification platform was unsuccessful. Please try again.',
}

// Libs
import React from 'react'
import styled from 'styled-components'

// Assets
import myImoLogo from '@assets/img/logo/my-imo-main-logo.png'

const BaseLoginHeader = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 380px;
  width: 380px;
  height: 235px;
`

const ImoLogo = styled(BaseLoginHeader)`
  background-image: url(${myImoLogo});
`

const LoginHeader = () => <ImoLogo />

export default LoginHeader

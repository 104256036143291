import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'

import { fetchInitiativeWatchers, watchInitiative, unwatchInitiative } from './watchers'

export default function* initiativeWatchersSaga() {
  yield all([
    takeLatest(constants.WATCH_INITIATIVE, watchInitiative),
    takeLatest(constants.UNWATCH_INITIATIVE, unwatchInitiative),
    takeLatest(constants.FETCH_INITIATIVE_WATCHERS, fetchInitiativeWatchers),
  ])
}

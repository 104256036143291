import { handleActions } from 'redux-actions'
import * as constants from '@myImoConfigActions/projectTaxonomy/actionTypes'
import { orderBy } from 'lodash'

interface IProjectTaxonomyState {
  taxonomyFunctions: $TSFixMe[]
  taxonomyGeographies: $TSFixMe[]
  taxonomyBusinessUnits: $TSFixMe[]
}

const initialState: IProjectTaxonomyState = {
  taxonomyFunctions: [],
  taxonomyGeographies: [],
  taxonomyBusinessUnits: [],
}

export const projectTaxonomyReducer = handleActions<IProjectTaxonomyState, $TSFixMe>(
  {
    [constants.GET_TAXONOMY_SUCCESS]: (state, action) => {
      return {
        ...state,
        taxonomyFunctions: action.payload.taxonomyFunctions,
        taxonomyGeographies: orderBy(
          action.payload.taxonomyGeographies,
          ['level0Name', 'level1Name', 'level2Name', 'level3Name', 'level4Name'],
          'asc',
        ),
        taxonomyBusinessUnits: action.payload.taxonomyBusinessUnits,
      }
    },
    [constants.UPDATE_TAXONOMY_FUNCTIONS]: (state, action) => {
      return { ...state, taxonomyFunctions: action.payload.taxonomyFunctions }
    },
    [constants.UPDATE_TAXONOMY_GEOGRAPHIES]: (state, action) => {
      return { ...state, taxonomyGeographies: action.payload.taxonomyGeographies }
    },
    [constants.UPDATE_TAXONOMY_BUSINESS_UNITS]: (state, action) => {
      return { ...state, taxonomyBusinessUnits: action.payload.taxonomyBusinessUnits }
    },
  },
  initialState,
)

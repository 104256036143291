import React from 'react'
import { Breadcrumb, Colors, IconName, MaybeElement } from '@blueprintjs/core'
import { Breadcrumbs2 } from '@blueprintjs/popover2'
import { UIMatch, useMatches, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const StyledBreadcrumb = styled(Breadcrumb)<{ $isActiveItem?: boolean }>`
  color: ${({ $isActiveItem }) => ($isActiveItem ? Colors.DARK_GRAY1 : Colors.GRAY1)};
  font-weight: ${({ $isActiveItem }) => ($isActiveItem ? `500` : `400`)};
  &:hover {
    color: ${({ $isActiveItem }) => ($isActiveItem ? Colors.DARK_GRAY1 : Colors.GRAY1)};
  }
`

export interface BreadcrumbType {
  icon?: IconName | MaybeElement
  title: string | React.ReactNode
}

export const Breadcrumbs = ({
  preventCollapseCrumbs = false,
  withoutCrumbs = [],
  appendCrumbs = [],
}: {
  preventCollapseCrumbs?: boolean
  withoutCrumbs?: string[]
  appendCrumbs?: (BreadcrumbType & { url: string })[]
}) => {
  const matches = useMatches()
  const navigate = useNavigate()

  const crumbs = (matches as UIMatch<unknown, { crumb: BreadcrumbType }>[])
    .filter((match) => Boolean(match.handle?.crumb) && !withoutCrumbs.includes(match.pathname))
    .map((match) => ({ ...match.handle.crumb, url: match.pathname }))

  if (appendCrumbs.length) {
    crumbs.push(...appendCrumbs)
  }

  return (
    <Breadcrumbs2
      // Allow non-collapsing variant for tests
      overflowListProps={preventCollapseCrumbs ? { minVisibleItems: 100 } : undefined}
      breadcrumbRenderer={({ icon, text, current, onClick }) => (
        <StyledBreadcrumb icon={icon} text={text} onClick={onClick} $isActiveItem={current} />
      )}
      items={crumbs.map((crumb, index) => ({
        icon: crumb.icon,
        text: crumb.title,
        onClick: () => navigate(crumb.url),
        current: index === crumbs.length - 1,
      }))}
    />
  )
}

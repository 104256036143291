import { call, put } from 'redux-saga/effects'
import { getInitiativeListV2Api } from '@common/net'
import { fetchValueCaptureStatus } from '../actions'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getUpdateStatusRequestBody } from '../utils'

export const updateValueCaptureStatuses = createSaga(function* updateValueCaptureStatuses({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const body = getUpdateStatusRequestBody(data)

  const api = yield getInitiativeListV2Api()
  yield call(
    api.request('updateValueCaptureStatusV2', {
      query: {
        teamId,
      },
      body,
    }),
  )

  yield put(fetchValueCaptureStatus({ teamId }))
})

import { put, call, select } from 'redux-saga/effects'
import {
  fetchTeamStructure as fetchTeamStructureAction,
  setTeamStructure,
} from '@teamResources/teamStructure/actions/actions'
import { getGenericApi, getTeamResourcesApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getTenantId } from '@generic/selectors'
import { runDownloadByUrl } from '@helpers/utils'
import { getTeamStructure } from '@teamResources/teamStructure/selectors'

export const fetchTeamStructure = createSaga(function* fetchTeamStructure(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const directory = yield select(getTenantId)
  const api = yield getTeamResourcesApi()
  const genericApi = yield getGenericApi()
  const { key } = yield call(api.request('getTeamStructure'))

  if (key) {
    const { signedUrl } = yield call(genericApi.request('getFileSignedUrl', { query: { key, directory } }))

    yield put(setTeamStructure(signedUrl))
  }
})

export const downloadTeamStructure = createSaga(function* downloadTeamStructure(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  // @ts-expect-error checkTypes
  yield call(fetchTeamStructure)
  const teamStructure = yield select(getTeamStructure)

  runDownloadByUrl({ url: teamStructure, name: 'teamStructure' })
})

export const updateTeamStructure = createSaga(function* updateTeamStructure(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { file } = action.payload
  const formData = new FormData()

  formData.append('attachment', file)

  const api = yield getTeamResourcesApi()

  yield call(
    api.request('updateTeamStructure', {
      body: formData,
    }),
  )

  yield put(fetchTeamStructureAction())
})

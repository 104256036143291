import { call, put } from 'redux-saga/effects'
import { setTeamMembers } from '../actions/actions'
import { getTeamCharterApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import * as actions from '../actions/actions'

export const fetchTeamMembers = createSaga(function* fetchTeamMembers({
  teamId,
  date,
}: {
  teamId: string
  date: string
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamCharterApi()
  const teamMembers = yield call(api.request('getTeamMembers', { query: { teamId, date } }))

  yield put(setTeamMembers(teamMembers))
})

export const deleteTeamMemberData = createSaga(function* deleteTeamMemberData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { id, teamId } = payload
  const api = yield getTeamCharterApi()

  yield call(api.request('deleteTeamMember', { query: { teamId, id } }))

  yield put(actions.removeTeamMember({ id }))
})

export const knowledgeBlocksNames = {
  hearFromExperts: 'hearFromExperts',
  readMore: 'readMore',
  introductionMaterials: 'introductionMaterials',
}

export const knowledgeBlocks = {
  [knowledgeBlocksNames.hearFromExperts]: {
    title: 'Hear from the experts',
    className: 'hear-from-expert',
    id: 'hear-from-expert',
  },
  [knowledgeBlocksNames.readMore]: {
    title: 'Read more on this topic',
    className: 'read-more',
    id: 'read-more',
  },
  [knowledgeBlocksNames.introductionMaterials]: {
    title: 'Introduction materials on topics typically in-scope for your workstream',
    className: 'introduction-materials',
    id: 'introduction-materials',
  },
}

export const introductionMaterialTypesOptions = [
  {
    id: 'HowToGuide',
    name: `"How-to" guide`,
  },
  {
    id: 'Example',
    name: 'Example',
  },
  {
    id: 'ExampleWorkplan',
    name: 'Example workplan',
  },
]

export const interactiveCardIcons = {
  Example: 'shapes',
  ExampleWorkplan: 'gantt-chart',
  HowToGuide: 'lightbulb',
}

export const introductionMaterialsTypes = {
  HowToGuide: `"How-to" guide`,
  Example: 'Example',
  ExampleWorkplan: 'Example workplan',
}

export const importButtonsConstants = {
  leftButton: {
    icon: 'add',
    title: 'Add new',
    id: 'add_new',
    description: 'Create new content from scratch',
  },
  rightButton: {
    icon: 'book',
    title: 'Import from Library',
    id: 'import',
    description: 'Pull in content from the McKinsey Library',
  },
}

export const UPLOADING_FILE_REQUIREMENTS = {
  pdf: ['.pdf'],
  ppt: ['.ppt', '.pptx'],
  size: 50,
}

export const articleTitleLength = 500

export const introductionMaterialDescriptionLength = 10000

/**
 * @param {Object} query - key-value pairs to convert to URLSearchParams
 * @returns {URLSearchParams} each entry in the query object is added to the search params. Where an entry value is an array, the key is appended once for each value in the array
 *
 * @example
 * const query = { a: true, b: 7, c: ["one", "two", "three"] };
 * const params = constructUrlSearchParams(query);
 * assertEqual(params.toString(), "a=true&b=7&c=one&c=two&c=three") // true
 */
export function toUrlSearchParams(query: Record<string, unknown>): URLSearchParams {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(query)) {
    if (value === undefined) continue
    else if (Array.isArray(value)) value.forEach((v) => params.append(key, v))
    else params.set(key, value as $TSFixMe)
  }

  return params
}

import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'
import { linkItems, mapLinkedItems, unlinkItems } from '@teamValueCaptureV2/projectMap/utils'

export interface IProjectMapState {
  linkedItems: $TSFixMe[]
}

export const initialState = {
  linkedItems: [],
}

const projectMapReducer = handleActions<IProjectMapState, $TSFixMe>(
  {
    [constants.SET_PROJECT_MAP]: (state, action) => {
      const { data } = action.payload

      return { linkedItems: mapLinkedItems(data) }
    },

    [constants.LINK_ITEM_SUCCESS]: (state, action) => {
      const { sourceType, sourceId, linkedItemType, linkedItemId, shouldRemovePrevious } = action.payload

      return {
        linkedItems: linkItems(state.linkedItems, {
          sourceType,
          sourceId,
          linkedItemType,
          linkedItemId,
          shouldRemovePrevious,
        }),
      }
    },

    [constants.UNLINK_ITEM_SUCCESS]: (state, action) => {
      const { linkedItemType, linkedItemId, shouldRemovePrevious, shouldRemoveParent } = action.payload

      return {
        linkedItems: unlinkItems(
          state.linkedItems,
          { linkedItemType, linkedItemId },
          shouldRemovePrevious,
          shouldRemoveParent,
        ),
      }
    },
  },
  initialState,
)

export default projectMapReducer

import { createSaga } from '@common/sagaCreator/createSaga'
import { getKnowledgeCenterApi } from '@common/net'
import { call, cancelled, put, select } from 'redux-saga/effects'
import { buildUrlPath } from '@imo/imo-ui-toolkit/dist/helpers/utils'
import Config from '@config/index'
import { loadingState } from '@imo/imo-ui-toolkit/dist/helpers/constants'
import { getExpertVideos, getSelectedItem } from '@domain/myImoClient/myTeam/knowledgeCenter/selectors'
import { getMidCore, getXTenant } from '@generic/selectors'
import { makeUniqueName } from '@myImoClient/components/KnowledgeCenter/utils'
import { sortBy } from 'lodash/fp'
import * as actionTypes from '@domain/myImoClient/myTeam/knowledgeCenter/actions/actionTypes'
import * as actions from '@domain/myImoClient/myTeam/knowledgeCenter/actions/actions'
import { expertVideosTitleLength } from '@myImoClient/components/KnowledgeCenter/ExpertVideos/utils'

export const fetchVideos = createSaga(function* fetchVideos(teamId: string): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  const videos = yield call(api.request('getKnowledgeCenterVideos', { query: { teamId } }))

  yield put(actions.setKnowledgeCenterVideo(sortBy('index', videos)))
}, actionTypes.SET_KNOWLEDGE_CENTER_VIDEO_STATE)

export const createNewVideo = createSaga(function* createNewVideo({
  payload: teamId,
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const abortController = new AbortController()
  try {
    const api = yield getKnowledgeCenterApi()
    const videos = yield select(getExpertVideos)
    const mid = yield select(getMidCore)
    const xTenant = yield select(getXTenant)
    const { title, byline, description, file } = yield select(getSelectedItem)
    const headers = {
      authorization: `bearer ${mid.accessToken()}`,
      'x-tenant': xTenant,
    }

    const uploadVideoParams = {
      headers,
      signal: abortController.signal,
      body: file,
      method: 'POST',
    }

    yield fetch(
      buildUrlPath(`teams/${teamId}/knowledge-center/video/upload`, { key: file.name }, Config.API_SERVER),
      uploadVideoParams,
    )

    const incrementedTitle = makeUniqueName(videos, title, 'title', expertVideosTitleLength)

    const body = {
      key: file.name,
      title: incrementedTitle,
      byline,
      description,
      libraryFunctionId: null,
    }

    const video = yield call(api.request('createKnowledgeCenterVideos', { query: { teamId }, body }))

    yield put(actions.addKnowledgeCenterVideo(video))
  } finally {
    if (yield cancelled()) {
      abortController.abort()
    }
  }
},
actionTypes.CREATE_NEW_KNOWLEDGE_CENTER_VIDEO_STATE)

export const updateVideo = createSaga(function* updateVideo({
  payload: teamId,
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const abortController = new AbortController()
  try {
    const api = yield getKnowledgeCenterApi()
    const { title, byline, originalTitle, description, file, index, key, id, isShared } = yield select(getSelectedItem)
    const mid = yield select(getMidCore)
    const xTenant = yield select(getXTenant)
    const videos = yield select(getExpertVideos)

    const headers = {
      authorization: `bearer ${mid.accessToken()}`,
      'x-tenant': xTenant,
      'Content-Type': 'application/json',
    }

    let filePath = key

    if (file?.name) {
      const uploadVideoParams = {
        headers,
        signal: abortController.signal,
        body: file,
        method: 'POST',
      }

      yield fetch(
        buildUrlPath(`teams/${teamId}/knowledge-center/video/upload`, { key: file.name }, Config.API_SERVER),
        uploadVideoParams,
      )
        .then((data) => data.json())
        .then((data) => (filePath = data.filePath))
    }

    const isTitleWasChanged = title !== originalTitle
    const incrementedTitle = isTitleWasChanged ? makeUniqueName(videos, title, 'title', expertVideosTitleLength) : title

    const body = {
      key: filePath,
      title: incrementedTitle,
      byline,
      description,
      index,
      libraryFunctionId: null,
      isShared,
    }

    yield call(api.request('updateKnowledgeCenterVideos', { query: { teamId, videoId: id }, body }))

    yield call(fetchVideos, teamId)
  } finally {
    if (yield cancelled()) {
      abortController.abort()
    }
  }
},
actionTypes.UPDATE_KNOWLEDGE_CENTER_VIDEO_STATE)

export const deleteVideo = createSaga(function* deleteVideo({
  payload: { teamId, videoId },
}: {
  payload: { teamId: string; videoId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  yield call(api.request('deleteKnowledgeCenterVideos', { query: { teamId, videoId } }))
  yield call(fetchVideos, teamId)
})

export const saveVideosOrder = createSaga(function* saveVideosOrder({
  payload: { teamId },
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()
  const expertVideos = yield select(getExpertVideos)
  const videos = expertVideos.map(({ id }: $TSFixMe) => id)
  yield call(api.request('saveVideosOrder', { query: { teamId, videos } }))
})

export const resetVideoLoadingState = createSaga(function* resetVideoLoadingState({
  payload: state,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  if (state === loadingState.SUCCESS) {
    yield put(actions.resetCreateKnowledgeCenterVideosState())
    yield put(actions.setSelectedItem({}))
    yield put(actions.setSelectedItemErrors({}))
    yield put(actions.changeEditModalState({ isModalOpen: false }))
  }
})

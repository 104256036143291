import { isArray, map } from 'lodash/fp'
import { put, call } from 'redux-saga/effects'
import { setControlPanelData, setMetricData } from '@controlPanel/actions/actions'
import { getImoControlTowerApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

type Query = {
  teamId: string
  includeConfidential: boolean
  priority?: $TSFixMe
  metric?: $TSFixMe
  selectedView?: $TSFixMe
  targetTeamId?: number
}

export const fetchControlPanelData = createSaga(function* fetchControlPanelData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, priorityIds, includeConfidential, view } = action.payload

  const query: Query = { teamId, includeConfidential }

  if (isArray(priorityIds) && priorityIds.length) {
    query.priority = map(JSON.stringify, priorityIds).toString()
  }

  const api = yield getImoControlTowerApi()
  const apiMethod = view === 'Project' ? 'getControlPanelProjectData' : 'getControlPanelData'
  const data = yield call(api.request(apiMethod, { query }))

  yield put(setControlPanelData(data))
},
true)

export const fetchMetricData = createSaga(function* fetchMetricData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, metric, priorityIds, includeConfidential, selectedView, targetTeamId } = action.payload

  const query: Query = { teamId, metric, includeConfidential, selectedView, targetTeamId }

  if (isArray(priorityIds) && priorityIds.length) {
    query.priority = map(JSON.stringify, priorityIds).toString()
  }

  const api = yield getImoControlTowerApi()
  const data = yield call(api.request('getMetricData', { query }))

  yield put(setMetricData(data))
},
true)

export const updateTaskMetricData = createSaga(function* updateTaskMetricData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, taskId, read } = action.payload

  const api = yield getImoControlTowerApi()
  yield call(
    api.request('updateTaskMetricData', {
      query: { teamId, taskId },
      body: {
        read,
      },
    }),
  )
},
false)

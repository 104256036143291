import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchInitialData = createAction(constants.FETCH_INITIAL_DATA)

export const fetchWhiteboard = createAction(constants.FETCH_WHITEBOARD)
export const fetchWhiteboardWithLoader = createAction(constants.FETCH_WHITEBOARD, null, getMetaWithActionLoading)
export const setWhiteboard = createAction(constants.SET_WHITEBOARD)
export const createWhiteboard = createAction(constants.CREATE_WHITEBOARD)
export const addWhiteboard = createAction(constants.ADD_WHITEBOARD)
export const updateWhiteboard = createAction(constants.UPDATE_WHITEBOARD)
export const deleteWhiteboard = createAction(constants.DELETE_WHITEBOARD)
export const removeWhiteboard = createAction(constants.REMOVE_WHITEBOARD)

export const fetchTeamActions = createAction(constants.FETCH_TEAM_ACTIONS)
export const fetchTeamActionsWithLoader = createAction(constants.FETCH_TEAM_ACTIONS, null, getMetaWithActionLoading)
export const setTeamActions = createAction(constants.SET_TEAM_ACTIONS)
export const createTeamAction = createAction(constants.CREATE_TEAM_ACTION)
export const addTeamAction = createAction(constants.ADD_TEAM_ACTION)
export const updateTeamAction = createAction(constants.UPDATE_TEAM_ACTION)
export const updateTeamActionSuccess = createAction(constants.UPDATE_TEAM_ACTION_SUCCESS)
export const deleteTeamAction = createAction(constants.DELETE_TEAM_ACTION)
export const removeTeamAction = createAction(constants.REMOVE_TEAM_ACTION)

export const fetchDecisionsLog = createAction(constants.FETCH_DECISIONS_LOG)
export const fetchDecisionsLogWithLoader = createAction(constants.FETCH_DECISIONS_LOG, null, getMetaWithActionLoading)
export const setDecisionsLog = createAction(constants.SET_DECISIONS_LOG)
export const createDecisionsLog = createAction(constants.CREATE_DECISIONS_LOG)
export const addDecisionsLog = createAction(constants.ADD_DECISIONS_LOG)
export const updateDecisionsLog = createAction(constants.UPDATE_DECISIONS_LOG)
export const updateDecisionsLogSuccess = createAction(constants.UPDATE_DECISIONS_LOG_SUCCESS)
export const deleteDecisionsLog = createAction(constants.DELETE_DECISIONS_LOG)
export const removeDecisionsLog = createAction(constants.REMOVE_DECISIONS_LOG)

export const fetchRisksLog = createAction(constants.FETCH_RISKS_LOG)
export const fetchRisksLogWithLoader = createAction(constants.FETCH_RISKS_LOG, null, getMetaWithActionLoading)
export const setRisksLog = createAction(constants.SET_RISKS_LOG)
export const createRisksLog = createAction(constants.CREATE_RISKS_LOG)
export const addRisksLog = createAction(constants.ADD_RISKS_LOG)
export const updateRisksLog = createAction(constants.UPDATE_RISKS_LOG)
export const updateRisksLogSuccess = createAction(constants.UPDATE_RISKS_LOG_SUCCESS)
export const deleteRisksLog = createAction(constants.DELETE_RISKS_LOG)
export const removeRisksLog = createAction(constants.REMOVE_RISKS_LOG)

export const fetchCalendar = createAction(constants.FETCH_CALENDAR)
export const fetchCalendarWithLoader = createAction(constants.FETCH_CALENDAR, null, getMetaWithActionLoading)
export const setCalendar = createAction(constants.SET_CALENDAR)
export const createCalendar = createAction(constants.CREATE_CALENDAR)
export const addCalendar = createAction(constants.ADD_CALENDAR)
export const updateCalendar = createAction(constants.UPDATE_CALENDAR)
export const deleteCalendar = createAction(constants.DELETE_CALENDAR)
export const removeCalendar = createAction(constants.REMOVE_CALENDAR)

export const fetchTeamTopics = createAction(constants.FETCH_TEAM_TOPICS)
export const fetchTeamTopicsWithLoader = createAction(constants.FETCH_TEAM_TOPICS, null, getMetaWithActionLoading)
export const setTeamTopics = createAction(constants.SET_TEAM_TOPICS)
export const createTeamTopics = createAction(constants.CREATE_TEAM_TOPICS)
export const addTeamTopics = createAction(constants.ADD_TEAM_TOPICS)
export const updateTeamTopics = createAction(constants.UPDATE_TEAM_TOPICS)
export const deleteTeamTopics = createAction(constants.DELETE_TEAM_TOPICS)
export const removeTeamTopics = createAction(constants.REMOVE_TEAM_TOPICS)
export const setImoTopics = createAction(constants.SET_IMO_TOPICS)

import { call, put } from 'redux-saga/effects'
import { fetchDecisionsLog, setDecisionsLog, updateDecisionsLogSuccess } from '@teamWorkspace/actions/actions'
import { getTeamWorkspaceApi } from '@common/net'
import { omit } from 'lodash'
import { createSaga } from '@common/sagaCreator/createSaga'

export const getDecisions = createSaga(function* getDecisions({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamWorkspaceApi()
  const decisionsData = yield call(api.request('getDecisions', { query: { teamId: payload } }))

  yield put(setDecisionsLog(decisionsData))
})

export const createDecisions = createSaga(function* createDecisions({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  const { id } = yield call(
    api.request('createDecision', { query: { teamId }, body: omit(data, 'isNew', 'id', 'team') }),
  )

  yield put(updateDecisionsLogSuccess({ id: data.id, data: { ...data, id, isNew: false } }))
},
true)

export const updateDecisions = createSaga(function* updateDecisions({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data, body } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(
    api.request('updateDecision', {
      query: { teamId: data.team.id, decisionId: data.id },
      body: body,
    }),
  )

  yield put(updateDecisionsLogSuccess({ id: data.id, data }))
})

export const deleteDecisions = createSaga(function* deleteDecisions({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(api.request('deleteDecision', { query: { teamId, decisionId: data.id } }))

  yield put(fetchDecisionsLog(teamId))
})

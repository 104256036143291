// Libs
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

// Components
import LookupSelector from '@shared/Lookup'
import { filter } from 'lodash/fp'

function isGroupNode(node) {
  return node && node.group && node.level === 0
}

const OwnerCellEditor = (params, ref) => {
  const { formatValue, value, context, parseValue, rowsToShow, inputProps = {}, data: rowData, node } = params

  const data = isGroupNode(node) ? node.allLeafChildren[0].data : rowData

  const userList = context.filterUsersByTam
    ? context.users.find(({ teamId }) => teamId === data.teamId)?.userList || []
    : context.users

  const [state, setState] = useState({
    selectedValue: formatValue(value),
    activeItemText: formatValue(value).name,
  })

  // return the final value to the grid, the result of the editing
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return parseValue ? parseValue(state.selectedValue) : state.selectedValue
      },
    }
  })

  // Stop editing right after selection of a new value
  // Need to compare prevoius name with selected one
  useEffect(() => {
    const valueName = formatValue(value).name
    const selectedValueName = state.selectedValue.name

    if (valueName !== selectedValueName) {
      params.stopEditing()
    }
  }, [state.selectedValue])

  const handleSelect = (item) => {
    const itemName = item.surname ? `${item.name} ${item.surname}` : item.name
    const updatedValue = {
      name: itemName,
      id: item.id || null,
    }

    // Update state to return this value in getValue()
    setState({
      ...state,
      selectedValue: updatedValue,
      activeItemText: itemName,
    })
  }

  const handleClearValue = () => {
    // Update state to return this value in getValue()
    setState({
      selectedValue: { name: '', id: null },
      activeItemText: null,
    })
  }

  const checkIfSelected = (item) =>
    !!state.selectedValue && (item.surname ? `${item.name} ${item.surname}` : item.name) === state.selectedValue.name

  const activeUsers = filter('isActive', Object.values(userList))

  return (
    <LookupSelector
      checkIfSelected={checkIfSelected}
      rowsToShow={rowsToShow}
      itemsList={activeUsers}
      selectedValues={[state.selectedValue]}
      activeItemText={state.activeItemText}
      handleSelectValue={handleSelect}
      handleDeselectValue={handleClearValue}
      customInputProps={{
        maxLength: 30,
        ...inputProps,
      }}
      isCellEditorComponent={true}
    />
  )
}

export default forwardRef(OwnerCellEditor)

import { useCallback, useState } from 'react'

export type ReactifiedSet<T> = {
  /**
   * adds a new entry to the set if it does not already exist
   * (note, this is O(n) if the set does not contain the entry, O(1) otherwise)
   * can trigger a rerender
   */
  add(entry: T): void
  /**
   * checks to see if the candidate value exists in the set (O(1))
   * does not trigger a rerender
   */
  has(candidate: T): boolean
  /**
   * removes the candidate value from the set if it already exists there, otherwise does nothing
   * (note this is O(n) if the set contains the candidate value, O(1) otherwise)
   * can trigger a rerender
   */
  remove(candidate: T): void
  /**
   * resets the state of the set using the provided iterable
   * if no arguments or `null` are provided, this clears the set
   * will trigger a rerender
   */
  reset(iterable?: Iterable<T> | null): void
  /**
   * the number of entries that exist in the set
   */
  size: number
  /**
   * returns an array containing copies of the set contents
   * as sets are inherently unordered, there are no guarantees to the ordering of this array
   * does not trigger a rerender
   */
  toArray(): T[]
  /**
   * checks the set contains exactly the elements in the provided iterable and no more
   * note that duplicate values in the iterable would cause this method to return false
   * does not trigger a rerender
   */
  equals(iterable: Iterable<T>): boolean
}

/**
 * reactified version of a `Set`, which works around the fact that js sets are mutable (and so don't ordinarily trigger react rerenders on changes) by creating new set instances when mutating the set - this requires copying all entries in the set across (so is O(n))
 * use this only when you need constant time lookup, otherwise just use an array in state
 */
export function useSet<T = unknown>(iterable?: Iterable<T> | null): ReactifiedSet<T> {
  const [entries, setEntries] = useState(new Set(iterable))

  const add = useCallback(
    (entry: T) =>
      setEntries((current) => {
        if (current.has(entry)) return current
        const next = new Set(current)
        next.add(entry)
        return next
      }),
    [],
  )

  const has = useCallback((candidate: T) => entries.has(candidate), [entries])

  const remove = useCallback(
    (candidate: T) =>
      setEntries((current) => {
        if (!current.has(candidate)) return current
        const next = new Set(current)
        next.delete(candidate)
        return next
      }),
    [],
  )

  const reset = useCallback((newIterable: Iterable<T> | null) => setEntries(new Set(newIterable)), [])

  const toArray = useCallback(() => Array.from(entries), [entries])

  const equals = useCallback(
    (iterable: Iterable<T>) => {
      let iterableSize = 0
      for (const candidate of iterable) {
        if (!entries.has(candidate)) return false
        iterableSize += 1
      }
      return iterableSize === entries.size
    },
    [entries],
  )

  return { add, has, remove, reset, size: entries.size, toArray, equals }
}

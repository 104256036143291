import { put, call } from 'redux-saga/effects'
import { getVCTrackerV2Api } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { setCxODashboardData } from '../actions/actions'

export const fetchCxODashboardData = createSaga(function* fetchCxODashboardData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, valueType, subtype, year } = payload

  const api = yield getVCTrackerV2Api()
  const data = yield call(
    api.request('getCxODashboardData', {
      query: {
        teamId,
        valueType,
        subtype,
        year,
      },
    }),
  )

  yield put(setCxODashboardData(data))
},
true)

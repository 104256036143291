import * as constants from '@hypercareLog/actions/actionTypes'
import { actionTypes } from '../../utils'

export default {
  [constants.FETCH_HYPERCARE_LOG_DATA]: {
    [actionTypes.FAIL]: ['We were unable to load the Hypercare Log issue'],
  },
  [constants.SAVE_ISSUE]: {
    [actionTypes.FAIL]: ['We were unable to create a new Hypercare Log issue'],
  },
  [constants.DELETE_ISSUE]: {
    [actionTypes.FAIL]: ['We were unable to delete the Hypercare Log issue'],
  },
  [constants.UPDATE_ISSUE]: {
    [actionTypes.FAIL]: ['We were unable to update the Hypercare Log issue'],
  },
}

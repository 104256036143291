import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@teamResources/dataRequestTracker/actions/actionTypes'
import { fetchDataRequests, createDataRequest, updateDataRequest, deleteDataRequest } from './dataRequestSagas'

export default function* dataRequestsSaga() {
  yield all([
    takeLatest(constants.FETCH_DATA_REQUESTS, fetchDataRequests),
    takeLatest(constants.CREATE_DATA_REQUEST, createDataRequest),
    takeLatest(constants.UPDATE_DATA_REQUEST, updateDataRequest),
    takeLatest(constants.DELETE_DATA_REQUEST, deleteDataRequest),
  ])
}

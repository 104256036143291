import { call, put } from 'redux-saga/effects'
import { setCommunicationsData } from '@communications/actions/actions'
import { getCommunicationsApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { sortBy } from 'lodash'

export const fetchCommunicationsData = createSaga(function* fetchCommunicationsData(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const api = yield getCommunicationsApi()
  const communications = yield call(api.request('getCommunicationsGridData'))

  yield put(
    setCommunicationsData({
      communicationsGridData: sortBy(communications, ['firstColumn.id']),
    }),
  )
},
true)

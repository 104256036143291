const prefix = '@teamDeliverables/'

export const INITIAL_VIDEO_KNOWLEDGE_CENTER_FETCH = `${prefix}INITIAL_VIDEO_KNOWLEDGE_CENTER_FETCH`
export const SAVE_LIBRARIES_FOR_VIDEO_IMPORT = `${prefix}SAVE_LIBRARIES_FOR_VIDEO_IMPORT`
export const IMPORT_VIDEOS = `${prefix}IMPORT_VIDEOS`

export const INITIAL_ARTICLE_KNOWLEDGE_CENTER_FETCH = `${prefix}INITIAL_ARTICLE_KNOWLEDGE_CENTER_FETCH`
export const SAVE_LIBRARIES_FOR_ARTICLE_IMPORT = `${prefix}SAVE_LIBRARIES_FOR_ARTICLE_IMPORT`
export const IMPORT_ARTICLES = `${prefix}IMPORT_ARTICLES`

export const INITIAL_INTRODUCTION_MATERIAL_KNOWLEDGE_CENTER_FETCH = `${prefix}INITIAL_INTRODUCTION_MATERIAL_KNOWLEDGE_CENTER_FETCH`
export const SAVE_LIBRARIES_FOR_INTRODUCTION_MATERIAL_IMPORT = `${prefix}SAVE_LIBRARIES_FOR_INTRODUCTION_MATERIAL_IMPORT`
export const IMPORT_INTRODUCTION_MATERIALS = `${prefix}IMPORT_INTRODUCTION_MATERIALS`

export const SET_LIBRARIES_FOR_IMPORT = `${prefix}SET_LIBRARIES_FOR_IMPORT`
export const SET_LIBRARY_ASSETS = `${prefix}SET_LIBRARY_ASSETS`
export const SET_LIBRARY_ASSETS_WITH_TIME = `${prefix}SET_LIBRARY_ASSETS_WITH_TIME`
export const FETCH_INTRODUCTION_MATERIALS_ASSETS_AND_DOWNLOAD = `${prefix}FETCH_INTRODUCTION_MATERIALS_ASSETS_AND_DOWNLOAD`

export const CHANGE_ASSET_STATUS = `${prefix}CHANGE_ASSET_STATUS`

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@functionalProcessDesign/actions/actionTypes'
import {
  fetchFuncDesignProcesses,
  createFuncDesignProcess,
  updateFuncDesignProcess,
  deleteFuncDesignProcess,
} from './functionalDesignProcesses'

export default function* functionalActivitySaga() {
  yield all([
    takeLatest(constants.FETCH_FUNC_DESIGN_PROCESSES, fetchFuncDesignProcesses),
    takeLatest(constants.CREATE_FUNC_DESIGN_PROCESS, createFuncDesignProcess),
    takeLatest(constants.UPDATE_FUNC_DESIGN_PROCESS, updateFuncDesignProcess),
    takeLatest(constants.DELETE_FUNC_DESIGN_PROCESS, deleteFuncDesignProcess),
  ])
}

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class HypercareLogApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch, '')

    this.host = Config.API_SERVER
  }

  getHypercareLogData = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${query.teamId}/hyper-care-log`,
    })
  }

  getImoHypercareLogData = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${query.teamId}/control-tower-hyper-care-log`,
    })
  }

  saveIssue = ({ query, body }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/hyper-care-log` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateIssue = ({ query, body }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/hyper-care-log/${query.issueId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteIssue = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/hyper-care-log/${query.issueId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }
}

import { handleActions } from 'redux-actions'
import * as constants from '@dayOneReadiness/actions/actionTypes'
import { loadingState } from '@imo/imo-ui-toolkit/dist/helpers/constants'

export interface IDayOneReadinessState {
  scenarios: $TSFixMe[]
  libraryData: $TSFixMe[]
  libraryDataState?: string
}

export const initialState = {
  scenarios: [],
  libraryData: [],
  libraryDataState: loadingState.INITIAL,
}

const dayOneReadinessReducer = handleActions<IDayOneReadinessState, $TSFixMe>(
  {
    [constants.SET_SCENARIOS]: (state, action) => {
      const { scenarios } = action.payload

      return {
        ...state,
        scenarios,
      }
    },

    [constants.RESET_SCENARIOS_FROM_LIBRARY]: (state) => ({
      ...state,
      libraryDataState: loadingState.INITIAL,
      libraryData: [],
    }),

    [constants.SET_SCENARIOS_FROM_LIBRARY]: (state, action) => {
      const { scenarios } = action.payload

      return {
        ...state,
        libraryData: scenarios.map((scenario: $TSFixMe) => ({ ...scenario, disabled: scenario.selected })),
      }
    },

    [constants.UPDATE_SCENARIO_FROM_LIBRARY]: (state, action) => {
      const { scenarioId, data } = action.payload
      const { libraryData } = state

      return {
        ...state,
        libraryData: libraryData.map((scenario) =>
          scenario.scenarioId === scenarioId ? { ...scenario, ...data } : scenario,
        ),
      }
    },

    [constants.CREATE_SCENARIO_SUCCESS]: (state, action) => {
      const { data } = action.payload

      return {
        ...state,
        scenarios: [...state.scenarios, data],
      }
    },

    [constants.UPDATE_SCENARIO_SUCCESS]: (state, action) => {
      const { id, data } = action.payload
      const { scenarios } = state

      const updatedScenarios = scenarios.map((scenario) => {
        if (scenario.id === id) {
          return data
        }

        return scenario
      })

      return {
        ...state,
        scenarios: updatedScenarios,
      }
    },

    [constants.DELETE_SCENARIO_SUCCESS]: (state, action) => {
      const { id } = action.payload
      const { scenarios } = state

      const filteredScenarios = scenarios.filter((scenario) => scenario.id !== id)

      return {
        ...state,
        scenarios: filteredScenarios,
      }
    },
  },
  initialState,
)

export default dayOneReadinessReducer

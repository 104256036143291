import React, { useRef } from 'react'
import { isNil } from 'lodash/fp'
import { useSmartTooltip } from '@imo/imo-ui-toolkit'
import { TextWrapper } from './MenuItemText.styles'

interface Props {
  text: string
  customTooltip?: string
}

const MenuItemText = ({ text, customTooltip }: Props) => {
  const titleRef = useRef<$TSFixMe>()

  useSmartTooltip({
    ref: titleRef,
    value: isNil(customTooltip) ? text : customTooltip,
  })

  return <TextWrapper ref={titleRef}>{text}</TextWrapper>
}

MenuItemText.defaultProps = {
  customTooltip: null,
}

export default MenuItemText

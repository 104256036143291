import styled from 'styled-components'

export const StyledIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: nowrap;
  cursor: pointer;
`

export const TextRowTitle = styled.div`
  margin-bottom: 10px;
`

import { actionTypes } from '../../utils'
import * as constants from '@teamResources/resourcesHome/actions/actionTypes'

export default {
  [constants.FETCH_MC_EVENTS]: {
    [actionTypes.FAIL]: ['We were unable to load the calendar events'],
  },
  [constants.CREATE_MC_EVENT]: {
    [actionTypes.FAIL]: ['We were unable to create a calendar event'],
  },
  [constants.UPDATE_MC_EVENT]: {
    [actionTypes.FAIL]: ['We were unable to update the calendar event'],
    [actionTypes.VALIDATION_FAIL]: [
      'We were unable to save the event. Please check the fields highlighted in red and try again.',
    ],
  },
  [constants.DELETE_MC_EVENT]: {
    [actionTypes.FAIL]: ['We were unable to delete the calendar event'],
  },
  [constants.FETCH_MC_LABELS]: {
    [actionTypes.FAIL]: ['We were unable to load the calendar labels'],
  },
  [constants.CREATE_MC_LABEL]: {
    [actionTypes.FAIL]: ['We were unable to create a calendar label'],
  },
  [constants.UPDATE_MC_LABEL]: {
    [actionTypes.FAIL]: ['We were unable to update the calendar label'],
  },
  [constants.DELETE_MC_LABEL]: {
    [actionTypes.FAIL]: ['We were unable to delete the calendar label'],
  },
  [constants.UPLOAD_MC_ATTACHMENT]: {
    [actionTypes.FAIL]: ['We were unable to upload the file attachment'],
  },
  [constants.DELETE_MC_ATTACHMENT]: {
    [actionTypes.FAIL]: ['We were unable to delete the file attachment'],
  },
}

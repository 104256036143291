import { StyledFilterItem } from '@shared/Grid/HierarchyFilter/styles'
import classnames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { BLANKS_FILTER_LABEL } from '@helpers/constants'

//TODO: move to ui-toolkit after testing
export const FilterItem = ({ onChange, value, itemKey, name, isIndeterminate }) => (
  <StyledFilterItem className="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
    <div className="ag-input-field-label ag-label ag-checkbox-label" key={'label'}>
      {name || BLANKS_FILTER_LABEL}
    </div>
    <div
      className={classnames({
        'ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper': true,
        'ag-checked': value,
        'ag-indeterminate': isIndeterminate,
      })}
      key={'checkbox'}
    >
      <input
        className="ag-input-field-input ag-checkbox-input"
        type="checkbox"
        data-path={itemKey}
        onChange={onChange}
        checked={value}
      />
    </div>
  </StyledFilterItem>
)

FilterItem.defaultProps = {
  value: false,
  name: null,
  isIndeterminate: false,
}

FilterItem.propTypes = {
  value: PropTypes.bool,
  itemKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.node,
  isIndeterminate: PropTypes.bool,
}

import styled from 'styled-components'
import { Colors } from '@blueprintjs/core'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
export const Title = styled.span`
  margin-top: 6px;
  margin-bottom: 4px;
  color: ${Colors.DARK_GRAY5};
  font-weight: 300;
  font-size: 12px;
`

export const FilterName = styled.span`
  color: ${Colors.DARK_GRAY5};
  font-weight: 400;
  font-size: 12px;
`

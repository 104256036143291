type AnimationState = {
  entering: (timeout: number) => React.CSSProperties
  entered: (timeout: number) => React.CSSProperties
  exiting: (timeout: number) => React.CSSProperties
  exited: (timeout: number) => React.CSSProperties
}

type SlideAnimationState = {
  topDown: AnimationState
  downTop: AnimationState
  leftRight: AnimationState
  rightLeft: AnimationState
}

type FadeScaleAnimationState = {
  in: AnimationState
  out: AnimationState
}

type AnimationStyles = {
  slide: SlideAnimationState
  fade: FadeScaleAnimationState
  scale: FadeScaleAnimationState
}

export const animationStyles: AnimationStyles = {
  slide: {
    topDown: {
      entering: (timeout: number) => ({
        transform: 'translateY(-100%)',
        opacity: 0,
        transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
      }),
      entered: (timeout: number) => ({
        transform: 'translateY(0)',
        opacity: 1,
        transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
      }),
      exiting: (timeout: number) => ({
        transform: 'translateY(0)',
        opacity: 1,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
      exited: (timeout: number) => ({
        transform: 'translateY(-100%)',
        opacity: 0,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
    },
    downTop: {
      entering: (timeout: number) => ({
        transform: 'translateY(100%)',
        opacity: 0,
        transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
      }),
      entered: (timeout: number) => ({
        transform: 'translateY(0)',
        opacity: 1,
        transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
      }),
      exiting: (timeout: number) => ({
        transform: 'translateY(0)',
        opacity: 1,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
      exited: (timeout: number) => ({
        transform: 'translateY(100%)',
        opacity: 0,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
    },
    leftRight: {
      entering: (timeout: number) => ({
        transform: 'translateX(-100%)',
        opacity: 0,
        transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
      }),
      entered: (timeout: number) => ({
        transform: 'translateX(0)',
        opacity: 1,
        transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
      }),
      exiting: (timeout: number) => ({
        transform: 'translateX(0)',
        opacity: 1,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
      exited: (timeout: number) => ({
        transform: 'translateX(-100%)',
        opacity: 0,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
    },
    rightLeft: {
      entering: (timeout: number) => ({
        transform: 'translateX(100%)',
        opacity: 0,
        transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
      }),
      entered: (timeout: number) => ({
        transform: 'translateX(0)',
        opacity: 1,
        transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
      }),
      exiting: (timeout: number) => ({
        transform: 'translateX(0)',
        opacity: 1,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
      exited: (timeout: number) => ({
        transform: 'translateX(100%)',
        opacity: 0,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
    },
  },
  fade: {
    in: {
      entering: (timeout: number) => ({
        opacity: 0,
        transition: `opacity ${timeout}ms ease-in-out`,
      }),
      entered: (timeout: number) => ({
        opacity: 1,
        transition: `opacity ${timeout}ms ease-in-out`,
      }),
      exiting: (timeout: number) => ({
        opacity: 1,
        transition: `opacity ${timeout}ms ease-in-out`,
      }),
      exited: (timeout: number) => ({
        opacity: 0,
        transition: `opacity ${timeout}ms ease-in-out`,
      }),
    },
    out: {
      entering: (timeout: number) => ({
        opacity: 1,
        transition: `opacity ${timeout}ms ease-in-out`,
      }),
      entered: (timeout: number) => ({
        opacity: 0,
        transition: `opacity ${timeout}ms ease-in-out`,
      }),
      exiting: (timeout: number) => ({
        opacity: 0,
        transition: `opacity ${timeout}ms ease-in-out`,
      }),
      exited: (timeout: number) => ({
        opacity: 1,
        transition: `opacity ${timeout}ms ease-in-out`,
      }),
    },
  },
  scale: {
    in: {
      entering: (timeout: number) => ({
        transform: 'scale(0.8)',
        opacity: 0,
        transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
      }),
      entered: (timeout: number) => ({
        transform: 'scale(1)',
        opacity: 1,
        transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
      }),
      exiting: (timeout: number) => ({
        transform: 'scale(1)',
        opacity: 1,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
      exited: (timeout: number) => ({
        transform: 'scale(0.8)',
        opacity: 0,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
    },
    out: {
      entering: (timeout: number) => ({
        transform: 'scale(1)',
        opacity: 1,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
      entered: (timeout: number) => ({
        transform: 'scale(0.8)',
        opacity: 0,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
      exiting: (timeout: number) => ({
        transform: 'scale(0.8)',
        opacity: 0,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
      exited: (timeout: number) => ({
        transform: 'scale(1)',
        opacity: 1,
        transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
      }),
    },
  },
}

const prefix = '@steerCo/'

export const FETCH_STEER_CO_ACTIONS_DATA = `${prefix}FETCH_STEER_CO_ACTIONS_DATA`
export const SET_STEER_CO_ACTIONS_DATA = `${prefix}SET_STEER_CO_ACTIONS_DATA`

export const UPDATE_STEER_CO_ACTIONS_DATA = `${prefix}UPDATE_STEER_CO_ACTIONS_DATA`
export const UPDATE_STEER_CO_ACTIONS_DATA_SUCCESS = `${prefix}UPDATE_STEER_CO_ACTIONS_DATA_SUCCESS`
export const UPDATE_STEER_CO_GRID_DATA = `${prefix}UPDATE_STEER_CO_GRID_DATA`
export const UPDATE_STEER_CO_GRID_DATA_SUCCESS = `${prefix}UPDATE_STEER_CO_GRID_DATA_SUCCESS`

export const FETCH_PARKING_LOT_DATA = `${prefix}FETCH_PARKING_LOT_DATA`
export const SET_PARKING_LOT_DATA = `${prefix}SET_PARKING_LOT_DATA`

export const UPDATE_PARKING_LOT_DATA = `${prefix}UPDATE_PARKING_LOT_DATA`
export const UPDATE_PARKING_LOT_DATA_SUCCESS = `${prefix}UPDATE_PARKING_LOT_DATA_SUCCESS`

export const FETCH_COLUMNS_DATA = `${prefix}FETCH_COLUMNS_DATA`
export const SET_COLUMNS_DATA = `${prefix}SET_COLUMNS_DATA`
export const UPDATE_COLUMNS_DATA = `${prefix}UPDATE_COLUMNS_DATA`
export const DELETE_COLUMNS_DATA = `${prefix}DELETE_COLUMNS_DATA`

import { createSaga } from '@common/sagaCreator/createSaga'
import { getVCTrackerV2Api } from '@common/net'
import { call, put } from 'redux-saga/effects'
import {
  setPipelineByIntegrationTeamData,
  setPipelineByYearOfExecutionData,
} from '@valueCaptureTrackerV2/pipelineByTeam/actions/actions'

export const fetchPipelineByIntegrationTeam = createSaga(function* fetchPipelineByIntegrationTeam({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, synergyType, calculationType } = payload
  const { value } = synergyType

  const api = yield getVCTrackerV2Api()
  const data = yield call(
    api.request('getPipelineByIntegrationTeamData', {
      query: {
        teamId,
        value,
        calculationType: calculationType.value,
      },
    }),
  )

  yield put(setPipelineByIntegrationTeamData(data))
},
true)

export const fetchPipelineByYearOfExecution = createSaga(function* fetchPipelineByYearOfExecution({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getVCTrackerV2Api()
  const data = yield call(
    api.request('getPipelineByYearOfExecution', {
      query: payload,
    }),
  )

  yield put(setPipelineByYearOfExecutionData(data))
},
true)

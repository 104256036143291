import { handleActions } from 'redux-actions'
import * as constants from '@teamResources/scenarioReport/actions/actionTypes'

export interface IScenarioReportState {
  scenarioReport: $TSFixMe[]
}

export const initialState = {
  scenarioReport: [],
}

const scenarioReportReducer = handleActions<IScenarioReportState, $TSFixMe>(
  {
    [constants.SET_SCENARIO_REPORT]: (state, action) => ({
      ...state,
      scenarioReport: action.payload,
    }),
  },
  initialState,
)

export default scenarioReportReducer

import React from 'react'
import { get, getOr, isEmpty } from 'lodash/fp'
import { useSelector } from 'react-redux'
import {
  getPermissions,
  userInfo as getUser,
  getExceptionUrls,
  hasUserFullAdminPermissions,
  getIsFirstTimeUser,
} from '@generic/selectors'
import { getIsLaunched, getModules } from '@domain/instanceConfig/selectors'
import { isImoTeamMember, getRedirect } from '@generic/selectors/permissions'
import InsufficientPermissionsPage from '@shared/InsufficientPermissionsPage'
import { generatePath, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

const defaultArgs = {
  moduleName: '',
  hasComponentAccess: true,
}

type AccessArgs = { moduleName?: string; hasAccess?: boolean }

/** Used to render an element with access control.
 * Ported directly from old withAccessControl HOC.
 */
export const AccessControl = ({ component: Component, ...props }: AccessArgs & { component: React.ElementType }) => {
  const moduleName = props?.moduleName || defaultArgs.moduleName
  const hasComponentAccess = props?.hasAccess || defaultArgs.hasComponentAccess

  const routerParams = useParams()
  const { pathname } = useLocation()
  const isFirstTimeUser = useSelector(getIsFirstTimeUser)
  const userInfo = useSelector(getUser)
  const isImoMember = useSelector(isImoTeamMember)
  const isLaunchedInstance = useSelector(getIsLaunched)
  const permissions = useSelector(getPermissions)
  const modules = useSelector(getModules)
  const isModuleActivated = (moduleName: string) => getOr(false, [moduleName, 'active'], modules)
  // @ts-expect-error fix selector types
  const exceptions = useSelector((state) => getExceptionUrls(state, get('teamId', routerParams)))
  const isUserAdminRole = useSelector(hasUserFullAdminPermissions)
  const navigate = useNavigate()
  const redirect = useSelector((state) => getRedirect(state, pathname, routerParams))

  // for modules disabled on step 8
  if (moduleName && !isModuleActivated(moduleName) && !isUserAdminRole) {
    return <InsufficientPermissionsPage />
  }

  const { firstTime } = userInfo

  // TODO: look at this solution
  const currPagePermission = permissions.find(
    (permission: { route: { baseRoute: $TSFixMe; innerRoute: $TSFixMe; hasInnerRoute: $TSFixMe } }) => {
      const {
        route: { baseRoute, innerRoute, hasInnerRoute },
      } = permission

      return pathname.includes(baseRoute) && pathname.includes(innerRoute) && !hasInnerRoute
    },
  )

  const hasAccess = permissions.length && Boolean(currPagePermission)

  if (firstTime && isLaunchedInstance && !pathname.match(/^\/config/u)) {
    navigate('/dashboard/terms-conditions')
  }

  // do not redirect user if he is a member of G-IMO team
  if (redirect && !isImoMember) {
    return <Navigate to={redirect} />
  }

  const isException =
    (!isEmpty(routerParams) && exceptions.some((url: string) => generatePath(url, routerParams) === pathname)) ||
    isFirstTimeUser

  return hasAccess || isException ? <Component hasAccess={hasComponentAccess} /> : <InsufficientPermissionsPage />
}

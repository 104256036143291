import { createSaga } from '@common/sagaCreator/createSaga'
import { getKnowledgeCenterApi } from '@common/net'
import { call, cancelled, put, select } from 'redux-saga/effects'
import { buildUrlPath, loadingState } from '@imo/imo-ui-toolkit'
import Config from '@config/index'
import * as actions from '@domain/myImoClient/myTeam/knowledgeCenter/actions/actions'
import * as actionTypes from '@domain/myImoClient/myTeam/knowledgeCenter/actions/actionTypes'
import { getExpertArticles, getSelectedItem } from '@domain/myImoClient/myTeam/knowledgeCenter/selectors'
import { getMidCore, getXTenant } from '@generic/selectors'
import { makeUniqueName } from '@myImoClient/components/KnowledgeCenter/utils'
import { sortBy } from 'lodash/fp'
import { articleTitleLength } from '@domain/myImoClient/myTeam/knowledgeCenter/constants'

export const fetchArticles = createSaga(function* fetchArticles({
  payload: { teamId },
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  const articles = yield call(api.request('getKnowledgeCenterArticles', { query: { teamId } }))

  yield put(actions.setKnowledgeCenterArticles(sortBy('index', articles)))
},
actionTypes.SET_KNOWLEDGE_CENTER_ARTICLES_STATE)

export const createNewArticle = createSaga(function* createNewArticle({
  payload: teamId,
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const abortController = new AbortController()
  try {
    const api = yield getKnowledgeCenterApi()
    const articles = yield select(getExpertArticles)
    const mid = yield select(getMidCore)
    const xTenant = yield select(getXTenant)
    const { title, file } = yield select(getSelectedItem)
    const headers = {
      authorization: `bearer ${mid.accessToken()}`,
      'x-tenant': xTenant,
    }

    const uploadParams = {
      headers,
      signal: abortController.signal,
      body: file,
      method: 'POST',
    }

    yield fetch(
      buildUrlPath(`teams/${teamId}/knowledge-center/article/upload`, { key: file.name }, Config.API_SERVER),
      uploadParams,
    )

    const incrementedTitle = makeUniqueName(articles, title, 'title', articleTitleLength)

    const body = {
      key: file.name,
      title: incrementedTitle,
      libraryFunctionId: null,
    }

    yield call(api.request('createKnowledgeCenterArticles', { query: { teamId }, body }))

    yield call(fetchArticles, { payload: { teamId } })
  } finally {
    if (yield cancelled()) {
      abortController.abort()
    }
  }
},
actionTypes.CREATE_NEW_KNOWLEDGE_CENTER_ARTICLE_STATE)

export const updateArticle = createSaga(function* updateArticle({
  payload: teamId,
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const abortController = new AbortController()
  try {
    const api = yield getKnowledgeCenterApi()
    const { title, file, index, key, id, isShared, originalTitle } = yield select(getSelectedItem)
    const mid = yield select(getMidCore)
    const xTenant = yield select(getXTenant)
    const articles = yield select(getExpertArticles)

    const headers = {
      authorization: `bearer ${mid.accessToken()}`,
      'x-tenant': xTenant,
      'Content-Type': 'application/json',
    }

    let filePath = key

    if (file?.name) {
      const uploadParams = {
        headers,
        signal: abortController.signal,
        body: file,
        method: 'POST',
      }

      yield fetch(
        buildUrlPath(`teams/${teamId}/knowledge-center/article/upload`, { key: file.name }, Config.API_SERVER),
        uploadParams,
      )
        .then((data) => data.json())
        .then((data) => (filePath = data.filePath))
    }

    const isTitleWasChanged = title !== originalTitle
    const incrementedTitle = isTitleWasChanged ? makeUniqueName(articles, title, 'title', articleTitleLength) : title

    const body = {
      key: filePath,
      title: incrementedTitle,
      index,
      libraryFunctionId: null,
      isShared,
    }

    yield call(api.request('updateKnowledgeCenterArticles', { query: { teamId, articleId: id }, body }))

    yield call(fetchArticles, { payload: { teamId } })
  } finally {
    if (yield cancelled()) {
      abortController.abort()
    }
  }
},
actionTypes.UPDATE_KNOWLEDGE_CENTER_ARTICLE_STATE)

export const deleteArticle = createSaga(function* deleteArticle({
  payload: { teamId, articleId },
}: {
  payload: { teamId: string; articleId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  yield call(api.request('deleteKnowledgeCenterArticle', { query: { teamId, articleId } }))
  yield call(fetchArticles, { payload: { teamId } })
})

export const saveArticlesOrder = createSaga(function* saveArticlesOrder({
  payload: { teamId },
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()
  const expertArticles = yield select(getExpertArticles)
  const articles = expertArticles.map(({ id }: $TSFixMe) => id)
  yield call(api.request('saveArticlesOrder', { query: { teamId, articles } }))
})

export const resetArticleLoadingState = createSaga(function* resetArticleLoadingState({
  payload: state,
}: {
  payload: $TSFixMe
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  if (state === loadingState.SUCCESS) {
    yield put(actions.resetCreateKnowledgeCenterArticleState())
    yield put(actions.setSelectedItem({}))
    yield put(actions.setSelectedItemErrors({}))
    yield put(actions.changeEditModalState({ isModalOpen: false }))
  }
})

import { round } from 'lodash'
import { CURRENCY_ABBREVIATION } from '@helpers/constants'
import { ROUNDING_PARAMS, SEPARATOR_SYMBOL, CURRENCY_SYMBOL } from './utils'
import { ICurrency, CurrencyProps, RoundingParams } from './Currency.d'

export default class Currency implements ICurrency {
  readonly currency: string
  readonly separator: string
  readonly thousandsSeparator: string
  roundingType: string

  constructor({ currency, roundingType, separator, thousandsSeparator }: CurrencyProps) {
    this.currency = currency.toLowerCase() // do we need lowercase or just change constants?
    this.roundingType = roundingType
    this.separator = separator
    this.thousandsSeparator = thousandsSeparator
  }

  private setRoundingType(type: string): void {
    this.roundingType = type
  }

  addSeparatorsToNumber(value: number): string {
    if (value === undefined || value === null) return ''

    const separatorSymbol = this.getSeparatorSymbol()
    const thousandsSeparatorSymbol = this.getThousandsSeparatorSymbol()
    const [wholeNumber, decimalPart] = `${Math.abs(value)}`.split('.')
    const sign = value < 0 ? '-' : ''
    let wholePartResult = ''

    // add thousands separator to wholeNumber
    for (let index = wholeNumber.length - 1, count = 1; index >= 0; index--, count++) {
      wholePartResult = wholeNumber[index] + wholePartResult

      if (count % 3 === 0 && index !== 0) wholePartResult = `${thousandsSeparatorSymbol}${wholePartResult}`
    }

    return decimalPart ? `${sign}${wholePartResult}${separatorSymbol}${decimalPart}` : `${sign}${wholePartResult}`
  }

  getRoundingParams(): RoundingParams {
    const defaultRoundingParams: RoundingParams = { denominator: 100, word: '', digitsAfterPoint: 0 }

    return ROUNDING_PARAMS[this.roundingType] || defaultRoundingParams
  }

  getSeparatorSymbol(): string {
    return SEPARATOR_SYMBOL[this.separator] || '.'
  }

  getThousandsSeparatorSymbol(): string {
    return SEPARATOR_SYMBOL[this.thousandsSeparator] || ''
  }

  getCurrencySymbol(): string {
    return CURRENCY_SYMBOL[this.currency] || ''
  }

  roundByCurrency(value: number, isRound = false): number {
    if (!value) return value

    const { denominator } = this.getRoundingParams()
    const formattedValue = `${value}`.replace(',', '.')
    const amount = Number(formattedValue) * denominator

    return isRound ? round(amount) : amount
  }

  addCurrencySymbolToString(value: string): string {
    return this.getCurrencySymbol() ? `${value} (${this.getCurrencySymbol()})` : value
  }

  addCurrencySymbol(value: number | string): string {
    const currencySymbol = this.getCurrencySymbol()

    if (this.currency === CURRENCY_ABBREVIATION.EUR) return `${value} ${currencySymbol}`

    return `${currencySymbol}${value}`
  }

  combineCurrencySymbolWithRounding(value: number): string {
    const symbol = this.getCurrencySymbol()
    const { word } = this.getRoundingParams()

    if (symbol) return `${value} (${symbol}${word})`

    return `${value} (${this.currency.toUpperCase()})`
  }

  formatCentsToMoney(value: number): number | string {
    if (value === 0) return ''

    return value / 100
  }

  getCurrencyText(): string {
    return `${this.currency}, ${this.roundingType}`
  }

  roundWithDigits(value: number): number {
    const { denominator, digitsAfterPoint } = this.getRoundingParams()
    const result = round(value / denominator, digitsAfterPoint)

    return result
  }

  // return value -> 0, 1.3, 33.5, 83.9, ...
  roundCurrencyValue(value?: number | null): string {
    if (value === undefined || value === null) return ''

    const result = this.roundWithDigits(Number(value))

    return this.addSeparatorsToNumber(result)
  }

  // return value -> 0m, 1.6m, 12m, -123m
  roundCurrencyWithWord(value: number): string {
    const { word } = this.getRoundingParams()
    const result = this.roundWithDigits(Number(value))
    const resultWithSeparators = this.addSeparatorsToNumber(result)

    return `${resultWithSeparators}${word}`
  }

  // return value $121.4, $66.1, $0, $188.8
  roundCurrencyWithSymbol(value: number, withDefaultRoundingType?: boolean): string {
    const currencySymbol = CURRENCY_SYMBOL[this.currency] || ''

    if (withDefaultRoundingType) {
      this.setRoundingType('default')
    }

    const result = this.roundWithDigits(Number(value))
    const formattedValue = this.addSeparatorsToNumber(result)

    if (this.currency === CURRENCY_ABBREVIATION.EUR) return `${formattedValue} ${currencySymbol}`

    return `${currencySymbol}${formattedValue}`
  }

  // return value $36.7m or ($36.7m) if negative
  roundCurrencyWithSymbolAndWord(value: number): string {
    const { word } = this.getRoundingParams()
    const currencySymbol = CURRENCY_SYMBOL[this.currency] || ''
    const result = this.roundWithDigits(Number(Math.abs(value)))

    const formattedValue = this.addSeparatorsToNumber(result)

    if (this.currency === CURRENCY_ABBREVIATION.EUR)
      return value < 0 ? `(${formattedValue}${word} ${currencySymbol})` : `${formattedValue}${word} ${currencySymbol}`

    return value < 0 ? `(${currencySymbol}${formattedValue}${word})` : `${currencySymbol}${formattedValue}${word}`
  }

  /*
   * if configured isMillionCurrencyRounding the coins should not be displayed and manually entered.
   * To do this, round up to integers
   */
  formatMillionCurrency(value: number): number {
    const { word } = this.getRoundingParams()

    if (word === 'm') return round(value / 100) * 100

    return round(value)
  }

  getValueForCellEditorInput(value: number): string {
    const normalizedValue: number = this.formatMillionCurrency(value)
    const separatorSymbol = this.getSeparatorSymbol()
    const { denominator } = this.getRoundingParams()

    return `${normalizedValue / denominator}`.replace('.', separatorSymbol)
  }

  /*
   * if configured thousandCurrencyRounding the user should be able to enter thousandths of a cent,
   * but pennies should be rounded to hundredths
   */
  transformValueFromCellEditor(value: string) {
    if (value === '') return null

    const { word, denominator } = this.getRoundingParams()

    if (word === 'k') return round(Number(`${value}`.replace(',', '.')) * denominator) / denominator

    return value
  }
}

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class CommunicationsApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)
    this.host = Config.API_SERVER
    this.routePrefix = 'communication/'
  }

  getColumnsData = () => {
    return this.call({ pathname: `column` })
  }

  getCommunicationsGridData = () => {
    return this.call({ pathname: `event/grid` })
  }

  deleteColumnsData = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `column/bulk/[${query.columnIds.join(',')}]`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  updateColumnsData = ({ body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `column/bulk`,
      },
      {
        method: 'PUT',
        body,
      },
    )
  }

  addRow = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: `row` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateRow = ({ query, body }: $TSFixMe) => {
    return this.call(
      { pathname: `row/${query.rowId}` },
      {
        method: 'PUT',
        body,
      },
    )
  }

  deleteRows = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `row/bulk/[${query.rowIds}]`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  addRequest = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: `request` },
      {
        method: 'POST',
        body,
      },
    )
  }

  getRequests = () => {
    return this.call({ pathname: `request` })
  }

  updateRequest = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `request/${query.requestId}` },
      {
        method: 'PUT',
        body,
      },
    )
  }

  deleteRequest = ({ query }: $TSFixMe) => {
    return this.call(
      { pathname: `request/${query.requestId}` },
      {
        method: 'DELETE',
      },
    )
  }

  addEvent = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: `event` },
      {
        method: 'POST',
        body,
      },
    )
  }

  getEvent = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `event/${query.eventId}` })
  }

  updateEvent = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `event/${query.eventId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteEvents = ({ query }: $TSFixMe) => {
    return this.call(
      { pathname: `event/bulk/[${query.eventIds}]` },
      {
        method: 'DELETE',
      },
    )
  }

  uploadEventAttachment = ({ body, query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `event/${query.eventId}/attachments`,
      },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  deleteEventAttachment = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `event/${query.eventId}/attachments/${query.fileId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }
}

import {
  InputGroupWithErrorTooltip,
  AsideTextarea,
  getVideoType,
  uploadLabelLineHeight,
  uploadLabelMarginTop,
} from '@imo/imo-ui-toolkit'

export const videoPlayerDefaultOptions = {
  autoplay: false,
  controls: true,
  responsive: true,
}

export const expertVideosTitleLength = 500
export const expertVideosBylineLength = 250
export const expertVideosDescriptionLength = 10000

export const contentHeight = 210
export const labelHeight = uploadLabelLineHeight + uploadLabelMarginTop

export const placeholder = 'Supported formats include mp4, mov, mkv under 500MB in size'

export const initialFormParams = [
  {
    label: 'Title',
    Component: InputGroupWithErrorTooltip,
    maxLength: expertVideosTitleLength,
    placeholder: 'Enter text here',
    fieldType: 'title',
    required: true,
  },
  {
    label: 'Byline',
    Component: InputGroupWithErrorTooltip,
    maxLength: expertVideosBylineLength,
    placeholder: 'Enter text here',
    fieldType: 'byline',
    required: true,
  },
  {
    label: 'Description',
    Component: AsideTextarea,
    maxLength: expertVideosDescriptionLength,
    placeholder: 'Enter text here',
    fieldType: 'description',
    required: true,
  },
]

export const videoValidationFields = ['fileUrl', 'fileType', 'title', 'byline', 'description']

export const videoDataTransformer = (data) => {
  return { ...data, fileUrl: data.url, fileType: getVideoType(data.url) }
}

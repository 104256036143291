import {
  GLOBAL_IMO,
  GLOBAL_SMO,
  teams as teamConstants,
  teams,
  teamTitles,
  VC_DELIVERABLE_TYPE_ID,
  OD_DELIVERABLE_TYPE_ID,
} from '@common/accessController/constants'
import { mutateTeamsForSMOCase } from '@helpers/smoTransformations'
import { filter as filterFp, sortBy as sortByFp } from 'lodash/fp'
import { cloneDeep, each, find, flatten, flow, get, includes, isEmpty, pick, size, sortBy, values } from 'lodash'
import { createSelector } from 'reselect'
import { getAllTeams, getLocationParams, getTeamsTree, hasConfigAdminRole } from '@generic/selectors'
import { getManagementType } from '@domain/instanceConfig/selectors'
import { getUserRolesInfo } from '@generic/selectors/permissions'
import { isSMO } from '@myImoConfig/containers/ConfigSteps/utils'
import { checkIsRoleWithOD, checkIsRoleWithVC, getReportersByDeliverableTypesInReview } from '@generic/selectors/utils'
import {
  checkIsActiveDeliverable,
  getIdsByCondition,
  getTSAMasterListTeamList,
  groupByResponsibleIMO,
  isNotRestrictedTeam,
  isOrgDesignTrackerTeam,
  isVcTrackerTeam,
} from '@common/teamFilter/helpers'

export const getCurrentTeamId = (locationParams: $TSFixMe, selectedTeamIds: $TSFixMe) => {
  const { isImoControlTower, isVcTracker } = locationParams

  if (isImoControlTower) {
    return selectedTeamIds.imoTeam
  } else if (isVcTracker) {
    return selectedTeamIds.centralTeam
  } else if (!isImoControlTower && !isVcTracker) {
    return selectedTeamIds.team
  }
}

export const filterKnowledgeCenterTeams = (teams: $TSFixMe) => {
  const newTeams = cloneDeep(teams)

  return each(newTeams, (value, key) => {
    newTeams[key] = value.filter(({ isKnowledgeCenterActive }: $TSFixMe) => isKnowledgeCenterActive)
  })
}

export const filterTeamsByType = (teams: $TSFixMe, teamType: $TSFixMe, teamsWithoutVC: $TSFixMe) => {
  return each(teams, (value, key) => {
    teams[key] = value.filter((team: $TSFixMe) => {
      return team.teamType === teamType && !find(teamsWithoutVC, { id: team.id })
    })
  })
}

export function getTeamById(teams: $TSFixMe, teamsCollection: $TSFixMe, teamId: $TSFixMe) {
  const flattenTeams = flatten(values(teams))
  const teamFound = find(flattenTeams, ({ id }) => id === teamId)

  if (teamFound) return teamFound

  return teamsCollection.find((team: $TSFixMe) => team.id === teamId)
}

export const isActiveDeliverable = (
  activeDeliverableIds: $TSFixMe,
  deliverableId: $TSFixMe,
  fromTeam: $TSFixMe,
  fromImoTeam: $TSFixMe,
) => {
  const centralTeamDeliverableIds = get(activeDeliverableIds, `${fromTeam}`)

  if (fromTeam && centralTeamDeliverableIds) {
    return centralTeamDeliverableIds.some((id: $TSFixMe) => id === deliverableId)
  }

  if ((fromImoTeam || (!fromTeam && !fromImoTeam)) && activeDeliverableIds) {
    return checkIsActiveDeliverable(activeDeliverableIds, deliverableId)
  }

  return false
}

// To be refactored after team-tree EP rework
// too complex active teams tree filter
export const getActiveTeams = (teams: $TSFixMe, teamsCollection: $TSFixMe) => {
  const newTeams = cloneDeep(teams)

  return each(newTeams, (value, key) => {
    const filteredGroup = value.filter((team: $TSFixMe) => {
      return teamsCollection.find((teamFromC: $TSFixMe) => teamFromC.id === team.id && teamFromC.active)
    })

    newTeams[key] = filteredGroup

    if (!filteredGroup || !size(filteredGroup)) {
      delete newTeams[key]
    }
  })
}

const getTeamsByActiveDeliverableIds = (teams: $TSFixMe, teamsCollection: $TSFixMe, locationParams: $TSFixMe) => {
  const newTeams = cloneDeep(teams)
  const { deliverableId, valueCaptureDelId, fromTeam, fromImoTeam } = locationParams

  /*
          additionally filter using 'activeDeliverableIds'
        */
  return each(newTeams, (value, key) => {
    const filteredGroup = value.filter((team: $TSFixMe) => {
      const teamDetails = teamsCollection.find((teamFromC: $TSFixMe) => teamFromC.id === team.id && teamFromC.active)

      if (!get(teamDetails, 'activeDeliverableIds')) return false

      const { activeDeliverableIds } = teamDetails

      return isActiveDeliverable(activeDeliverableIds, deliverableId || valueCaptureDelId, fromTeam, fromImoTeam)
    })

    newTeams[key] = filteredGroup

    if (!filteredGroup || !size(filteredGroup)) {
      delete newTeams[key]
    }
  })
}

const getTeamsByRelations = (
  teamRelations: $TSFixMe,
  teams: $TSFixMe,
  rootTeamName: $TSFixMe,
  teamsCollection: $TSFixMe,
  locationParams: $TSFixMe,
) => {
  if (isEmpty(teamRelations)) return getTeamsByActiveDeliverableIds(teams, teamsCollection, locationParams)

  const relationTeams = {
    [rootTeamName]: teamRelations.map((teamId: $TSFixMe) => {
      return teamsCollection.find((team: $TSFixMe) => team.id === teamId)
    }),
  }

  return getTeamsByActiveDeliverableIds(relationTeams, teamsCollection, locationParams)
}

const getDeliverableTeams = (
  teams: $TSFixMe,
  teamsCollection: $TSFixMe,
  teamsTree: $TSFixMe,
  locationParams: $TSFixMe,
) => {
  const { fromTeam, fromImoTeam } = locationParams

  /*
          when opening deliverable from my team deliverable tracker (central team is selected in filter)
          fromTeam - id of corresponding central team
          we should see team select with integration teams responsible to central team
          searching by 'revieweeTeams'
        */
  if (fromTeam) {
    const teamDetails = getTeamById(teamsTree, teamsCollection, fromTeam)
    const teamRelations = get(teamDetails, 'revieweeTeams', []).map((i: $TSFixMe) => i.id)

    return getTeamsByRelations(teamRelations, teams, teamDetails.longName, teamsCollection, locationParams)
  }

  /*
          when opening deliverable from imo deliverable tracker (imo team is selected in filter)
          fromImoTeam - id of corresponding imo team
          we should see team select with responsible imo children
          searching by 'reporterIds'
        */
  if (fromImoTeam) {
    const teamDetails = getTeamById(teamsTree, teamsCollection, fromImoTeam)
    const teamRelations = get(teamDetails, 'reporterIds', [])
    const secondaryTeamRelations = get(teamDetails, 'secondaryReporterIds', [])

    return getTeamsByRelations(
      [...teamRelations, ...secondaryTeamRelations],
      teams,
      teamDetails.longName,
      teamsCollection,
      locationParams,
    )
  }

  return getTeamsByActiveDeliverableIds(teams, teamsCollection, locationParams)
}

const applyTeamsListSorting = (teamsList: $TSFixMe, withSmo: $TSFixMe) => {
  const groups = {} as $TSFixMe

  Object.keys(teamsList).forEach((group) => {
    groups[group] = flow([
      sortByFp([
        (o: $TSFixMe) => !includes(o.longName, withSmo ? GLOBAL_SMO : GLOBAL_IMO),
        (o: $TSFixMe) => !includes(o.teamType, [teamConstants.SMO_GLOBAL, teamConstants.IMO_GLOBAL]),
        'teamType',
        (o: $TSFixMe) => o.longName.toLowerCase(),
        'longName',
      ]),
      filterFp((item) => item !== null),
    ])(teamsList[group])
  })

  return groups
}

export const getOwnAndVCReportersIntegrationTeams = (groupedRoles: $TSFixMe) => {
  const userReporterTeams = groupedRoles[teams.REPORTER]
  const reportersByDeliverableType = getReportersByDeliverableTypesInReview(userReporterTeams).filter(
    ({ deliverableTypeIds }: $TSFixMe) => deliverableTypeIds.includes(VC_DELIVERABLE_TYPE_ID),
  )

  const userIntegrationTeams = userReporterTeams.filter(({ teamType }: $TSFixMe) => teamType === teams.INTEGRATION)

  return [...userIntegrationTeams, ...reportersByDeliverableType]
}

export const getOwnAndODReportersIntegrationTeams = (groupedRoles: $TSFixMe) => {
  const userReporterTeams = groupedRoles[teams.REPORTER]
  const reportersByDeliverableType = getReportersByDeliverableTypesInReview(userReporterTeams).filter(
    ({ deliverableTypeIds }: $TSFixMe) => deliverableTypeIds.includes(OD_DELIVERABLE_TYPE_ID),
  )

  const userIntegrationTeams = userReporterTeams.filter(({ teamType }: $TSFixMe) => teamType === teams.INTEGRATION)

  return [...userIntegrationTeams, ...reportersByDeliverableType]
}

export const getUserVCTeamsList = ({ groupedRoles }: $TSFixMe, allTeams: $TSFixMe) => {
  const allReportersIntegrationTeam = getOwnAndVCReportersIntegrationTeams(groupedRoles)

  return getIdsByCondition(allReportersIntegrationTeam, ({ role, parentRole, id }: $TSFixMe) => {
    const mainRole = role || parentRole

    return find(allTeams, { id })?.active && checkIsRoleWithVC(mainRole)
  })
}

export const getUserODTeamsList = ({ groupedRoles }: $TSFixMe, allTeams: $TSFixMe) => {
  const allReportersIntegrationTeam = getOwnAndODReportersIntegrationTeams(groupedRoles)

  return getIdsByCondition(allReportersIntegrationTeam, ({ role, parentRole, id, connection }: $TSFixMe) => {
    const mainRole = role || parentRole
    const team = find(allTeams, { id })

    if (!team) {
      return false
    }

    return team.active && checkIsRoleWithOD(mainRole, connection)
  })
}

export const getVCReportersForSelector = ({ userRolesInfo, teamsCollection }: $TSFixMe) => {
  const vcTeamsIds = getUserVCTeamsList(userRolesInfo, teamsCollection)

  return groupByResponsibleIMO(teamsCollection.filter(({ id }: $TSFixMe) => vcTeamsIds.includes(id)))
}

export const getODReportersForSelector = ({
  userRolesInfo,
  teamsCollection,
}: {
  userRolesInfo: $TSFixMe
  teamsCollection: $TSFixMe
}) => {
  const odTeamsIds = getUserODTeamsList(userRolesInfo, teamsCollection)

  return groupByResponsibleIMO(teamsCollection.filter(({ id }: $TSFixMe) => odTeamsIds.includes(id)))
}

export const getVCTrackerTeams = ({ userRolesInfo, teamsCollection }: $TSFixMe) => {
  const { groupedRoles } = userRolesInfo
  const vcTrackerTeamsIds = getIdsByCondition(groupedRoles[teams.REPORTER], isVcTrackerTeam)

  return groupByResponsibleIMO(teamsCollection.filter(({ id }: $TSFixMe) => vcTrackerTeamsIds.includes(id)))
}

export const getOrgDesignTrackerTeams = ({ userRolesInfo, teamsCollection }: $TSFixMe) => {
  const { groupedRoles } = userRolesInfo
  const orgDesignTrackerTeamsIds = getIdsByCondition(groupedRoles[teams.REPORTER], isOrgDesignTrackerTeam)

  return groupByResponsibleIMO(teamsCollection.filter(({ id }: $TSFixMe) => orgDesignTrackerTeamsIds.includes(id)))
}

export const getUserCentIntTeams = ({ userRolesInfo, teamsCollection }: $TSFixMe) => {
  const { groupedRoles } = userRolesInfo

  const centIntTeamsIds = getIdsByCondition(groupedRoles[teams.REPORTER], isNotRestrictedTeam)

  return groupByResponsibleIMO(teamsCollection.filter(({ id }: $TSFixMe) => centIntTeamsIds.includes(id)))
}

interface GetTeamsByPageArgs {
  teamsTree: $TSFixMe
  teamsCollection: $TSFixMe
  locationParams: $TSFixMe
  userRolesInfo: $TSFixMe
  isAdminConfig: boolean
}

const getTeamsByPage = ({
  teamsTree,
  teamsCollection,
  locationParams,
  userRolesInfo,
  isAdminConfig,
}: GetTeamsByPageArgs) => {
  const {
    isImoControlTower,
    isVcTracker,
    isTeamVc,
    isMyTeamHome,
    isTeamWorkspace,
    isTeamStatusUpdate,
    isDay1Deliverable,
    isKnowledgeCenter,
    isOrgDesignTracker,
    isTeamOrgDesign,
    isRiskDecisionLogs,
  } = locationParams

  const imoTeams = pick(teamsTree, [teamTitles.IMO])

  if (isImoControlTower) {
    return imoTeams
  } else if (isVcTracker) {
    return getVCTrackerTeams({ userRolesInfo, teamsCollection })
  } else if (isOrgDesignTracker) {
    return getOrgDesignTrackerTeams({ userRolesInfo, teamsCollection })
  } else if (isTeamVc) {
    return getVCReportersForSelector({ teamsTree, teamsCollection, userRolesInfo })
  } else if (isTeamOrgDesign) {
    return getODReportersForSelector({ teamsCollection, userRolesInfo })
  }

  const isEnabledRestrictedUserPages = isRiskDecisionLogs || isTeamWorkspace
  const isEnabledRestrictedUserWeeklyStatusAccessPages = isTeamStatusUpdate
  if (
    isMyTeamHome ||
    isDay1Deliverable ||
    isEnabledRestrictedUserPages ||
    isEnabledRestrictedUserWeeklyStatusAccessPages
  ) {
    return teamsTree[teams.REPORTER]
  }

  const filteredCentIntTeams = getUserCentIntTeams({ userRolesInfo, teamsCollection })

  if (!isAdminConfig && isKnowledgeCenter) return filterKnowledgeCenterTeams(filteredCentIntTeams)

  return filteredCentIntTeams
}

const getCenterTeam = (teamsList: $TSFixMe) => {
  return Object.keys(teamsList).reduce((centerTeamList, teamKey) => {
    const centralTeams = teamsList[teamKey].filter((item: $TSFixMe) => item.teamType === teams.CENTRAL)
    if (!isEmpty(centralTeams)) {
      centerTeamList[teamKey] = centralTeams
    }

    return centerTeamList
  }, {} as $TSFixMe)
}

export const getTeamsList = () =>
  createSelector(
    getTeamsTree as $TSFixMe,
    getAllTeams as $TSFixMe,
    getUserRolesInfo as $TSFixMe,
    getLocationParams as $TSFixMe,
    getManagementType as $TSFixMe,
    hasConfigAdminRole as $TSFixMe,
    getAllTeams as $TSFixMe,
    (teamsTree, teamsCollection, userRolesInfo, locationParams, managementType, isAdminConfig, allTeams) => {
      const withSmo = isSMO(managementType)

      const { valueCaptureDelId, isDeliverable, isIMODeliverablesTracker, isTSAv2MasterList, isTeamOrgDesign } =
        locationParams

      // TODO minimize use of teamsCollection
      // To be refactored after team-tree EP rework
      const teamsByPage = getTeamsByPage({
        teamsTree,
        teamsCollection,
        userRolesInfo,
        locationParams,
        isAdminConfig,
      })

      // valueCaptureDelId is possible when we go from deliverables tracker to VC deliverable
      const teamsList =
        isDeliverable && !valueCaptureDelId && !isTeamOrgDesign
          ? getDeliverableTeams(teamsByPage, teamsCollection, teamsTree, locationParams)
          : getActiveTeams(teamsByPage, teamsCollection)

      //team list with only central team on Integration team deliverables tracker and TSAv2MasterList pages
      const teamsListForDropdown = isIMODeliverablesTracker || isTSAv2MasterList ? getCenterTeam(teamsList) : teamsList

      const filteredTeamList = isTSAv2MasterList
        ? getTSAMasterListTeamList(teamsListForDropdown, allTeams)
        : teamsListForDropdown

      // apply smo mutations on result teams list
      const transformedTeamList = withSmo ? mutateTeamsForSMOCase(filteredTeamList) : filteredTeamList

      // apply sorting for groups and inner teams
      return applyTeamsListSorting(transformedTeamList, withSmo)
    },
  )

export const sortTeamListByName = (teamList: $TSFixMe) => {
  return sortBy(teamList, (team: $TSFixMe) => team.longName.toLowerCase())
}

export const groupAndSortTeamListByParentTeam = (teamsList: $TSFixMe, shouldBeMerged: boolean) => {
  if (!shouldBeMerged) return teamsList
  const flattenTeamList = flatten(Object.values(teamsList))
  const sortedTeamList = sortTeamListByName(flattenTeamList)
  return { allTeams: sortedTeamList }
}

import React from 'react'
import { isNil } from 'lodash'
import { TitleBlock, TutorialText, UserGuideSMOLogo, UserGuideIMOLogo, LinkBlock } from './TutorialTooltip.styles'
import { Button, Icon } from '@blueprintjs/core'
import { isSMO } from '@myImoConfig/containers/ConfigSteps/utils'
import { ACTIVE_SIDER_ICON_COLOR } from '@helpers/constants'
import { PopoverWrapper } from '@shared/Sider/components/index.styles'

const emailIcon = <Icon icon="envelope" size={15} color={ACTIVE_SIDER_ICON_COLOR} />

interface Props {
  managementType: string
  supportEmail?: string
}

const TutorialTooltip = ({ managementType, supportEmail }: Props) => {
  const handleSupportEmail = () => {
    if (!isNil(supportEmail)) window.open(`mailto:${supportEmail}`)
  }

  return (
    <PopoverWrapper>
      <TitleBlock>
        {isSMO(managementType) ? <UserGuideSMOLogo /> : <UserGuideIMOLogo />}
        <span>Support</span>
      </TitleBlock>
      <TutorialText>
        Get in touch with us at the email address below with any questions, comments or feedback.
      </TutorialText>
      <LinkBlock>
        <Button onClick={handleSupportEmail} icon={emailIcon} text={supportEmail} minimal />
      </LinkBlock>
    </PopoverWrapper>
  )
}

TutorialTooltip.defaultProps = {
  supportEmail: null,
}

export default TutorialTooltip

import React from 'react'
import classnames from 'classnames'
import { Intent } from '@blueprintjs/core'
import { MenuItem, LockIcon, ErrorIcon } from './index.styles'
import { CustomPopover, Placement } from '@shared/Popover'
import { noop } from 'lodash/fp'

const errorIcon = <ErrorIcon icon="error" intent={Intent.DANGER} size={12} />
const lockIcon = <LockIcon icon="lock" size={7} />

interface Props {
  path?: string
  children?: React.ReactElement | React.ReactElement[]
  tooltipMessage?: string | React.ReactElement | React.ReactElement[]
  isDisabled?: boolean
  isShown?: boolean
  onClick?: (...args: $TSFixMe[]) => $TSFixMe
  isPopover?: boolean
  id?: string
  contentStyles?: $TSFixMe
  isHasError?: boolean
  isActive: boolean
  forceClickable?: boolean
}

const Button = ({
  children,
  tooltipMessage,
  onClick,
  isDisabled,
  isPopover,
  isHasError,
  id,
  path,
  isShown,
  contentStyles,
  isActive,
  forceClickable,
}: Props) => {
  if (isPopover)
    return (
      <CustomPopover
        content={tooltipMessage}
        placement={Placement.RIGHT_END}
        interactionKind={'hover'}
        contentStyles={contentStyles}
        popoverClassName="bp4-dark"
        hoverOpenDelay={0}
        hoverCloseDelay={100}
      >
        <MenuItem
          role="menuitem"
          className={classnames({
            'menu-item': true,
            disabled: isDisabled,
            active: isActive,
          })}
          onClick={!isDisabled ? onClick : null}
          id={id}
          data-cy={`sidebar-${id}-icon`}
          tabIndex="-1"
          data-testid={id}
          data-disabled={isDisabled}
          path={path}
          $isDisabled={isDisabled}
          $isShown={isShown}
          forceClickable={forceClickable}
        >
          {children}
          {isDisabled && lockIcon}
          {isHasError && errorIcon}
        </MenuItem>
      </CustomPopover>
    )

  return (
    <MenuItem
      role="menuitem"
      className={classnames({
        'menu-item': true,
        disabled: isDisabled,
        active: isActive,
      })}
      onClick={!isDisabled ? onClick : null}
      id={id}
      data-cy={`sidebar-${id}-icon`}
      data-testid={id}
      data-disabled={isDisabled}
      path={path}
      $isDisabled={isDisabled}
      tabIndex={isDisabled ? '-1' : '0'}
      $isShown={isShown}
    >
      {children}
      {isDisabled && lockIcon}
      {isHasError && errorIcon}
      <span className="css-tooltip">{tooltipMessage}</span>
    </MenuItem>
  )
}

Button.defaultProps = {
  children: <span />,
  tooltipMessage: '',
  id: '',
  isDisabled: false,
  isShown: true,
  onClick: noop,
  isPopover: false,
  path: '',
  contentStyles: {},
  isHasError: false,
}

export default Button

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@generic/actions/actionTypes'

import { fetchInitialData } from './fetchInitialData'
import { setSelectedTeams } from './setSelectedTeams'
import { fetchLibs, fetchLibsV2, fetchLinkedLibrariesByTeam } from './fetchLibs'
import { updateUserInfo } from './fetchUser'
import { downloadFile, uploadFile, deleteFile } from './filesManagement'
import { showWarningNotification } from './showWarningNotification'
// TODO - add proper types for mid-react
// eslint-disable-next-line
// @ts-ignore
import { LOGIN_SUCCESS } from '@nvt/mid-react/dist/store/constants'
import { fetchConfig, fetchConfigAndUser } from './fetchConfig'
import { startRefresher } from './startRefresher'
import { fetchTeamsList } from '@generic/sagas/fetchTeamsList'
import { fetchDeliverableStatus } from './fetchDeliverableStatus'
import { setKnowledgeCenterVisibilityStatus } from '@generic/sagas/setKnowledgeCenterVisibilityStatus'
import { fetchSnapshotMinDate } from './fetchSnapshotMinDate'
import { userLoginTimeTracker } from './userLoginTimeTracker'
import { fetchToggles, setTeamUserSettings } from '@generic/sagas/setTeamValueCaptureToggles'
import { logout } from '@generic/sagas/logout'

export default function* genericSaga() {
  yield all([
    takeLatest(LOGIN_SUCCESS, fetchConfigAndUser),
    takeLatest(LOGIN_SUCCESS, startRefresher),
    takeLatest(LOGIN_SUCCESS, userLoginTimeTracker),
    takeLatest(constants.FETCH_INITIAL_CLIENT_DATA, fetchInitialData, undefined),
    takeLatest(constants.FETCH_INITIAL_CLIENT_DATA_SUCCESS, setSelectedTeams),
    takeLatest(constants.FETCH_CONFIG, fetchConfig),
    takeLatest(constants.FETCH_TEAMS_LIST, fetchTeamsList),
    takeLatest(constants.SET_KNOWLEDGE_CENTER_VISIBILITY_STATUS, setKnowledgeCenterVisibilityStatus),
    takeLatest(constants.FETCH_LIBRARIES, fetchLibs),
    takeLatest(constants.FETCH_LIBRARIES_V2, fetchLibsV2),
    takeLatest(constants.FETCH_TEAM_LINKED_LIBRARIES, fetchLinkedLibrariesByTeam),
    takeLatest(constants.DOWNLOAD_FILE, downloadFile),
    takeLatest(constants.UPLOAD_FILE, uploadFile),
    takeLatest(constants.DELETE_FILE, deleteFile),
    takeLatest(constants.SET_IS_FIRST_TIME_USER, updateUserInfo),
    takeLatest(constants.SHOW_WARNING_MESSAGE, showWarningNotification),
    takeLatest(constants.FETCH_TEAM_DELIVERABLE_STATUS, fetchDeliverableStatus),
    takeLatest(constants.GET_SNAPSHOT_MIN_DATE, fetchSnapshotMinDate),
    takeLatest(
      [
        constants.SET_FTE_DASHBOARD_VISIBILITY_STATUS,
        constants.SET_MIRRORING_INITIATIVE_VISIBILITY_STATUS,
        constants.SET_SHARED_TARGETS_VISIBILITY_STATUS,
        constants.SET_CONFIDENTIAL_FILTER,
      ],
      setTeamUserSettings,
    ),
    takeLatest(constants.FETCH_TOGGLES, fetchToggles),
    takeLatest(constants.LOGOUT, logout),
  ])
}

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'

export const getDeliverables = createAction(constants.GET_DELIVERABLES)
export const updateDeliverableTypes = createAction(constants.UPDATE_DELIVERABLE_TYPES)
export const getDeliverableTypes = createAction(constants.GET_DELIVERABLE_TYPES)
export const createDeliverable = createAction(constants.CREATE_DELIVERABLE)
export const updateDeliverable = createAction(constants.UPDATE_DELIVERABLE)
export const deleteDeliverable = createAction(constants.DELETE_DELIVERABLE)
export const changeDeliverableType = createAction(constants.CHANGE_DELIVERABLE_TYPE)
export const onDeliverablesSuccess = createAction(constants.ON_DELIVERABLES_SUCCESS)
export const onDeliverableTypeSuccess = createAction(constants.ON_DELIVERABLE_TYPE_SUCCESS)
export const getDeliverablesData = createAction(constants.GET_DELIVERABLES_DATA)
export const createDeliverableFiles = createAction(constants.CREATE_DELIVERABLE_FILES)
export const deleteDeliverableFile = createAction(constants.DELETE_DELIVERABLE_FILE)

export const storeNumberOfOrgDesignLayers = createAction(constants.STORE_NUMBER_OF_ORG_DESIGN_LAYERS)

import { actionTypes } from '../utils'
import { FETCH_CONFIG_AND_USER } from '@generic/actions/actionTypes'
import * as constants from '@generic/actions/actionTypes'

export default {
  [constants.FETCH_CONFIG]: {
    [actionTypes.FAIL]: ['We were unable to load the admin configuration'],
  },
  [FETCH_CONFIG_AND_USER]: {
    [actionTypes.FAIL]: [
      'You are not currently assigned to any workstreams on this instance, please check with your program administrator for further assistance',
      2,
    ],
  },
  [constants.FETCH_USERS_LIST]: {
    [actionTypes.FAIL]: ['We were unable to load the user database', 3],
  },
  [constants.FETCH_LIBRARIES]: {
    [actionTypes.FAIL]: ['We were unable to load the McKinsey Library', 1],
  },
  [constants.DOWNLOAD_FILE]: {
    [actionTypes.FAIL]: ['We were unable to download the file'],
  },
  [constants.DOWNLOAD_FILE_XLS]: {
    [actionTypes.FAIL]: ['Sorry, something went wrong. Please try again later.'],
    [actionTypes.VALIDATION_FAIL]: ["You don't have access to download this file"],
  },
  [constants.UPLOAD_FILE]: {
    [actionTypes.VALIDATION_FAIL]: ['Please upload a valid file'],
  },
  [constants.UPLOAD_XLS_FILE]: {
    [actionTypes.VALIDATION_FAIL]: ['Please upload a valid .xls or .xlsx filetype', 42],
  },
  [constants.SET_IS_FIRST_TIME_USER]: {
    [actionTypes.FAIL]: ['We were unable to update the Terms and Conditions'],
  },
  [constants.FETCH_TEAMS_LIST]: {
    [actionTypes.FAIL]: ['We were unable to load the list of teams'],
  },
  [constants.GRID_CELL_UPDATE]: {
    [actionTypes.VALIDATION_FAIL]: ["Data doesn't met requirements"],
  },
  [constants.FETCH_TOGGLES]: {
    [actionTypes.FAIL]: ['We were unable to get FTE state'],
  },
}

import * as Yup from 'yup'

export const ExitPlansTSASchema = Yup.array().of(
  Yup.object().shape({
    keyProcess: Yup.string().nullable().required('This field cannot be empty'),
    SmoApproved: Yup.bool().nullable(),
    nameOfService: Yup.string()
      .nullable()
      .max(500, 'Name of Service must be less than 500 characters')
      .required('This field cannot be empty'),
    descriptionOfService: Yup.string()
      .nullable()
      .max(10000, 'Description of Service must be less than 10000 characters')
      .required('This field cannot be empty'),
    scopeOfService: Yup.string().nullable().max(500, 'Geography / Scope of Service must be less than 500 characters'),
    serviceLevel: Yup.string().nullable().max(500, 'Service level must be less than 500 characters'),
    servicePeriod: Yup.string()
      .nullable()
      .max(500, 'Service period must be less than 500 characters')
      .required('This field cannot be empty'),
    typeOfService: Yup.string()
      .nullable()
      .max(500, 'Type of Service must be less than 500 characters')
      .required('This field cannot be empty'),
    serviceFee: Yup.string().nullable().max(500, 'Service fee must be less than 500 characters'),
    costAssumptions: Yup.string().nullable().max(500, 'Cost Accumption must be less than 500 characters'),
    agreedTsaEndDate: Yup.mixed().nullable().required('This field cannot be empty'),
    targetTsaExitDate: Yup.mixed().nullable().required('This field cannot be empty'),
    extensionStatus: Yup.string().nullable(),
    linkedProject: Yup.mixed().nullable().required('This field cannot be empty'),
    comments: Yup.string().nullable().max(10000, 'Comments must be less than 10001 characters'),
  }),
)

import React from 'react'
import { StyledNavigation } from '@shared/TabLink/TabLink.styles'
import TabLink from '@shared/TabLink'
import { generatePageElementId } from '@helpers/utils'
import { routesList } from '@common/accessController/routesList'
import { useSelector } from 'react-redux'
import { getDeliverableStatusByImoTeamId } from '@domain/generic/selectors'
import { getDealType, getManagementType, getModules, getTSAVersion } from '@domain/instanceConfig/selectors'
import { TSA_VERSION } from '@myImoClient/components/TSAv2/shared/constants'
import { isEntanglementDealType } from '@myImoClient/components/PlanningAssumptionsReportGrid/utils'
import { Placement } from '@shared/Popover'
import { Icon, Intent, Position } from '@blueprintjs/core'
import { generatePath, useMatch } from 'react-router-dom'
import { BreadcrumbsHeader } from '@views/shared/Breadcrumbs/BreadcrumbsHeader'

type Props = {
  teamId: string | number
}

const getControlTowerId: (arg: string) => string = generatePageElementId('control-tower') as unknown as (
  arg: string,
) => string

const lockedIcon = <Icon icon="lock" intent={Intent.NONE} size={10} />

export const ImoControlTowerHeader = ({ teamId }: Props) => {
  const deliverableStatus = useSelector((state) => (getDeliverableStatusByImoTeamId as $TSFixMe)(state, teamId))
  const { isAllProjectsTabLocked, isAllTSAsTabLocked } = deliverableStatus
  const managementType = useSelector(getManagementType)
  const { weeklyStatusUpdate, day1HypercareLog } = useSelector(getModules)
  const tsaVersion = useSelector(getTSAVersion)
  const dealType = useSelector(getDealType)
  const homeTitle = `${managementType} Home`
  const hasProjectPlan = !isAllProjectsTabLocked // TODO: maybe we should return this from the API?
  const isIMOHome = useMatch(routesList.IMO_CONTROL_TOWER.TEAM_HOME.route)

  return (
    <>
      <BreadcrumbsHeader />
      {isIMOHome && (
        <StyledNavigation role="menubar" aria-label="Control tower menu">
          <TabLink
            id={getControlTowerId('global-home')}
            to={generatePath(routesList.IMO_CONTROL_TOWER.TEAM_HOME.route, { teamId })}
            title={homeTitle}
          />
          <TabLink
            id={getControlTowerId('control-panel')}
            to={generatePath(routesList.IMO_CONTROL_TOWER.CONTROL_PANEL.route, { teamId })}
            title="Control Panel"
          />
          {hasProjectPlan && (
            <TabLink
              id={getControlTowerId('project-analytics')}
              to={generatePath(routesList.IMO_CONTROL_TOWER.PROJECT_ANALYTICS.ROOT.route, { teamId })}
              title="Project Analytics"
            />
          )}
          <TabLink
            id={getControlTowerId('all-projects')}
            to={generatePath(routesList.IMO_CONTROL_TOWER.ALL_PROJECTS.route, { teamId })}
            title="All Projects"
            disabled={isAllProjectsTabLocked}
            icon={isAllProjectsTabLocked ? lockedIcon : null}
          />
          {tsaVersion === TSA_VERSION.TWO && isEntanglementDealType(dealType) && (
            <TabLink
              id={getControlTowerId('all-tsas')}
              to={generatePath(routesList.IMO_CONTROL_TOWER.ALL_TSAS.route, { teamId })}
              title="All TSAs"
              tooltipPlacement={Placement.TOP}
              tooltipContent="There are currently no active TSA deliverables"
              disabled={isAllTSAsTabLocked}
              icon={isAllTSAsTabLocked ? lockedIcon : null}
            />
          )}
          <TabLink
            id={getControlTowerId('team-workspace')}
            to={generatePath(routesList.IMO_CONTROL_TOWER.TEAM_WORKSPACE.route, { teamId })}
            title="Team Workspace"
          />
          {weeklyStatusUpdate.active && (
            <TabLink
              id={getControlTowerId('weekly-status')}
              to={generatePath(routesList.IMO_CONTROL_TOWER.WEEKLY_STATUS.ROOT.route, { teamId })}
              title="Weekly Status"
              tooltipContent="This has been disabled by your program administrator"
            />
          )}
          <TabLink
            id={getControlTowerId('steerco-roadmap')}
            to={generatePath(routesList.IMO_CONTROL_TOWER.STEER_CO_ROADMAP.route, { teamId })}
            title="SteerCo Roadmap"
          />
          {day1HypercareLog.active && (
            <TabLink
              id={getControlTowerId('hypercare-log')}
              to={generatePath(routesList.IMO_CONTROL_TOWER.IMO_TEAM_HYPERCARE.ROOT.route, { teamId })}
              title="Day 1 Hypercare"
              tooltipContent="This has been disabled by your program administrator"
              tooltipPlacement={Position.BOTTOM}
            />
          )}
        </StyledNavigation>
      )}
    </>
  )
}

const prefix = '@teamValueCaptureV2/projectMap/'

export const FETCH_PROJECT_MAP = `${prefix}FETCH_PROJECT_MAP`
export const SET_PROJECT_MAP = `${prefix}SET_PROJECT_MAP`

export const LINK_ITEM = `${prefix}LINK_ITEM`
export const LINK_ITEM_SUCCESS = `${prefix}LINK_ITEM_SUCCESS`

export const UNLINK_ITEM = `${prefix}UNLINK_ITEM `
export const UNLINK_ITEM_SUCCESS = `${prefix}UNLINK_ITEM_SUCCESS`

import { call, put, select } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getBatchUploadActualsApi } from '@common/net'
import { getMidCore, getXTenant } from '@generic/selectors'
import { getBulkUploadActualsFile } from '../selectors'
import {
  updateBulkUploadSendingStatus,
  sendBulkUploadActualsFileSuccess,
  sendBulkUploadActualsFileFail,
  fetchAmountOfInitiativesSuccess,
  fetchAmountOfInitiativesFail,
  downloadBulkUploadTemplateSuccess,
  downloadBulkUploadTemplateFail,
  updateDownloadBulkUploadTemplateStatus,
} from '../actions/actions'
import { SendingState } from '../constants'

const generateBulkUploadFile = function* generateBulkUploadFile({
  response,
  responseStream,
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getBatchUploadActualsApi()

  if (!responseStream.ok) {
    yield put(updateBulkUploadSendingStatus({ sending: SendingState.FAILED, errorMessage: response.message }))
    throw new Error(response.message)
  }

  const { statusId, ...uploadState } = response

  if (statusId) {
    yield put(updateBulkUploadSendingStatus({ sending: 'PENDING' }))
    let uploadState: $TSFixMe = {}

    do {
      uploadState = yield call(api.request('checkBulkUploadFile', { query: { teamId: payload.teamId, statusId } }))
    } while (uploadState.status === 'In progress')

    yield put(sendBulkUploadActualsFileSuccess(uploadState))

    return
  }

  yield put(sendBulkUploadActualsFileFail(uploadState))
}

export const sendBulkUploadActualsFilePending = createSaga(function* sendBulkUploadActualsFilePending({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const mid = yield select(getMidCore)
  const xTenant = yield select(getXTenant)

  try {
    const api = yield getBatchUploadActualsApi()
    const file = yield select(getBulkUploadActualsFile)
    const formData = new FormData()
    formData.append('file', file)

    const uploadParams = {
      headers: {
        authorization: `bearer ${mid.accessToken()}`,
        'x-tenant': xTenant,
      },
      body: formData,
      method: 'POST',
    }

    const responseStream = yield call(api.request('sendBulkUploadActualsFile', { query: payload, body: uploadParams }))
    const response = yield responseStream.json()

    yield call(generateBulkUploadFile, { response, responseStream, payload })
  } catch (e: $TSFixMe) {
    yield put(updateBulkUploadSendingStatus({ sending: SendingState.FAILED, errorMessage: 'Something went wrong' }))
    throw new Error(e.message)
  }
})

export const sendBulkUploadFilePending = createSaga(function* sendBulkUploadFilePending({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const mid = yield select(getMidCore)
  const xTenant = yield select(getXTenant)

  try {
    const api = yield getBatchUploadActualsApi()
    const file = yield select(getBulkUploadActualsFile)
    const formData = new FormData()
    formData.append('file', file)

    const uploadParams = {
      headers: {
        authorization: `bearer ${mid.accessToken()}`,
        'x-tenant': xTenant,
      },
      body: formData,
      method: 'POST',
    }

    const responseStream = yield call(api.request('sendBulkUploadFile', { query: payload, body: uploadParams }))
    const response = yield responseStream.json()

    yield call(generateBulkUploadFile, { response, responseStream, payload })
  } catch (e: $TSFixMe) {
    yield put(updateBulkUploadSendingStatus({ sending: SendingState.FAILED, errorMessage: 'Something went wrong' }))
    throw new Error(e.message)
  }
})

export const generateDownloadTemplateLink = function* generateDownloadTemplateLink({
  response,
  fileName,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const blob = yield response.blob()
  const buffer = yield blob.arrayBuffer()
  const link = document.createElement('a')
  const url = window.URL.createObjectURL(new Blob([buffer]))

  link.href = url
  link.download = fileName
  link.click()
  window.URL.revokeObjectURL(url)
}

export const getDownloadTemplate = createSaga(function* getDownloadTemplate({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, synergyType, fileName } = payload
  const api = yield getBatchUploadActualsApi()
  const mid = yield select(getMidCore)
  const xTenant = yield select(getXTenant)

  const body = {
    headers: {
      authorization: `bearer ${mid.accessToken()}`,
      'x-tenant': xTenant,
    },
    method: 'GET',
  }

  const response = yield call(api.request('getBulkUploadDownloadTemplate', { query: { teamId, synergyType }, body }))

  yield call(generateDownloadTemplateLink, { response, fileName })
})

export const fetchDownloadTemplate = function* fetchDownloadTemplate({
  fileId,
  teamId,
  fileName,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getBatchUploadActualsApi()
  let response
  let counter = 0

  const mid = yield select(getMidCore)
  const xTenant = yield select(getXTenant)

  const body = {
    headers: {
      authorization: `bearer ${mid.accessToken()}`,
      'x-tenant': xTenant,
    },
    method: 'GET',
  }

  do {
    try {
      response = yield call(api.request('fetchBulkUploadDownloadTemplate', { query: { teamId, fileId }, body }))
      counter++

      yield put(downloadBulkUploadTemplateSuccess())
    } catch (e: $TSFixMe) {
      response = e

      yield put(downloadBulkUploadTemplateFail({ downloadErrorMessage: e.message }))
    }
  } while (response?.status === 404 || counter === 10)

  yield call(generateDownloadTemplateLink, { response, fileName })

  yield delay(500)

  yield put(updateDownloadBulkUploadTemplateStatus({ downloading: null }))
}

export const downloadBulkUploadTemplate = createSaga(function* downloadBulkUploadTemplate({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  try {
    const api = yield getBatchUploadActualsApi()
    const { url, body, teamId, fileName } = payload

    const { fileId } = yield call(api.request('getBulkUploadFileId', { query: { url }, body }))
    yield call(fetchDownloadTemplate, { fileId, teamId, fileName })
  } catch (e) {
    global.console.error('Error from downloadBulkUploadTemplate', e)

    throw e
  }
})

export const fetchAmountOfInitiatives = createSaga(function* fetchAmountOfInitiatives({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  try {
    const { teamId, synergyType } = payload
    const api = yield getBatchUploadActualsApi()
    const file = yield select(getBulkUploadActualsFile)
    const mid = yield select(getMidCore)
    const xTenant = yield select(getXTenant)
    const formData = new FormData()

    formData.append('file', file)

    const uploadParams = {
      headers: {
        authorization: `bearer ${mid.accessToken()}`,
        'x-tenant': xTenant,
      },
      body: formData,
      method: 'POST',
    }

    const responseStream = yield call(
      api.request('getAmountOfInitiatives', { query: { teamId, synergyType }, body: uploadParams }),
    )
    const response = yield responseStream.json()

    if (!responseStream.ok) {
      yield put(updateBulkUploadSendingStatus({ sending: 'FAILED', errorMessage: response.message }))
      throw new Error(response.message)
    }

    const { count, errors } = response

    if (errors) {
      yield put(fetchAmountOfInitiativesFail({ errors }))
    }

    yield put(fetchAmountOfInitiativesSuccess({ count }))
  } catch (e) {
    global.console.error('Error from fetchAmountOfInitiatives', e)

    throw e
  }
})

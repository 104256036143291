import { handleActions } from 'redux-actions'
import * as constants from '@myImoConfigActions/users/actionTypes'

export interface IUsersState {
  list: $TSFixMe[]
  allTeams: {
    [teamName: string]: {
      [roleName: string]: boolean
    }
  }
  restrictedDomains?: $TSFixMe[]
  whoLockedData?: $TSFixMe | null
  isViewLocked?: boolean
  isReadOnly?: boolean
  usersLoading: boolean
}

export const initialState: IUsersState = {
  list: [],
  allTeams: {},
  restrictedDomains: [],
  whoLockedData: null,
  isViewLocked: true,
  isReadOnly: false,
  usersLoading: false,
}

export const usersReducer = handleActions<IUsersState, $TSFixMe>(
  {
    [constants.UPDATE_USERS]: (state) => {
      return {
        ...state,
        usersLoading: true,
      }
    },
    [constants.GET_USERS_SUCCESS]: (state, { payload }) => {
      const { list, allTeams, restrictedDomains } = payload

      return {
        ...state,
        list,
        allTeams,
        restrictedDomains,
        usersLoading: false,
      }
    },
    [constants.SET_USERS]: (state, action) => {
      const { list } = action.payload

      return {
        ...state,
        list,
      }
    },
    [constants.SET_WHO_LOCKED_DATA]: (state, action) => {
      const { whoLockedData } = action.payload

      return {
        ...state,
        whoLockedData,
      }
    },
    [constants.SET_IS_VIEW_LOCKED]: (state, action) => {
      return {
        ...state,
        isViewLocked: action.payload,
      }
    },
    [constants.SET_IS_READ_ONLY]: (state, { payload: isReadOnly }) => {
      return {
        ...state,
        isReadOnly,
      }
    },
  },
  initialState,
)

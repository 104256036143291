import { COLUMN_TYPE } from '@shared/Grid/constants'
import React from 'react'
import { bulletSymbol } from '@helpers/constants'
import { flatMap, omit, last } from 'lodash'
import InputCellEditor from '@imo/imo-ui-toolkit/dist/components/Grid/CellEditors/InputCellEditor/InputCellEditor'
import CheckboxWithTextRenderer from '@shared/Grid/CellRenderers/CheckboxWithTextRenderer'

export const macroPlanningColumnDefs = [{ field: 'name', hide: true }]

export const getDataPath = ({ name, parentAssumption }) => (parentAssumption ? [parentAssumption.name, name] : [name])

export const treeDataProps = {
  treeData: true,
  getDataPath: getDataPath,
  groupDefaultExpanded: -1,
}

export const interdependenciesColDefs = (headerName) => [
  {
    editable: false,
    headerName,
    field: 'name',
    cellEditor: InputCellEditor,
  },
]

export const interdependenciesGridParams = {
  minWidth: 140,
  width: 140,
}

export const checkBoxColDefs = (
  headerName,
  additionalColDefs = [],
  checkboxField = 'selected',
  field = 'name',
  additionalParams = {},
) => [
  {
    headerName,
    type: COLUMN_TYPE.checkboxWithText,
    cellRenderer: CheckboxWithTextRenderer,
    field,
    checkboxField,
    tooltipValueGetter: ({ value }) => value.text,
    tooltipParams: {
      truncateTargetSelector: 'div > span',
    },
    minWidth: 235,
    flex: 1,
    suppressSizeToFit: true,
    editable: false,
    cellRendererParams: ({ data }) => ({
      disabled: data.disabled,
    }),
    ...additionalParams,
  },
  ...additionalColDefs,
]

export const getAutoGroup = (headerName) => ({
  headerName,
  field: 'name',
  editable: false,
  flex: 1,
  cellRendererParams: {
    checkbox: true,
    suppressCount: true,
    suppressDoubleClickExpand: true,
    suppressEnterExpand: true,
  },
})

export const getDuplicatesErrorMessage = (duplicates = [], meta) => {
  const message =
    duplicates.length === 1
      ? 'An item with the same name has already been added to the deliverable. Please unselect it in order to proceed:'
      : 'Items with the same names have already been added to the deliverable. Please unselect them in order to proceed:'

  return (
    <>
      <p>{message}</p>
      <>
        {Object.entries(duplicates).map(([key, values]) => {
          if (!values.length) {
            return null
          }

          return (
            <div key={key}>
              <b>{meta[key]}:</b>
              <br />
              {values.map((val) => (
                <React.Fragment key={val}>
                  {bulletSymbol} {val}
                  <br />
                </React.Fragment>
              ))}
            </div>
          )
        })}
      </>
    </>
  )
}

export const rowClassRules = {
  locked: ({ data, context, node: { key } }) => {
    if (!data) {
      const group = context.assumptions.find(({ name }) => name === key)

      return group.disabled
    }

    return data?.disabled
  },
  disabled: ({ context }) => context?.readonly,
}

export const getTeamCharterRowId = ({ data: { name } }) => name

export const initialErrorsState = {
  decisions: {},
  risks: {},
  inScopeProcesses: {},
  outScopeProcesses: {},
  keyInterdependencies: {},
}

export const formatItemSelection = (item) => ({
  ...item,
  disabled: item.selected,
})

export const flattenAssumptionsFromLibrary = (list) =>
  flatMap(list, (assumption) => [
    formatItemSelection(assumption),
    ...assumption.macroPlanningItems.map((subItems) => ({
      ...formatItemSelection(subItems),
      parentAssumption: assumption,
    })),
  ])

export const transformFromFlatAssumption = (flatAssumption) => {
  return flatAssumption.reduce((acc, assumption) => {
    if (assumption.macroPlanningItems) {
      const newAssumption = {
        ...assumption,
        macroPlanningItems: [],
      }

      return [...acc, omit(newAssumption, 'disabled')]
    }

    last(acc).macroPlanningItems.push({
      name: assumption.name,
      selected: assumption.selected,
    })

    return acc
  }, [])
}

export const getKeyInterdependenciesRowId = ({ data: { name, team } }) => `${name}-${team}`

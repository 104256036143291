import { omit } from 'lodash/fp'
import { CustomHttpClientErrorType } from '@common/net/HttpClient'

type TeamDeliverable = {
  id: number
  startDate: string
  dueDate: string
  active: boolean
  teamId: number
  longName: string
  teamName: string
  deliverableType?: string
  orgDesignTeamLayer?: {
    dueDateDesign: string
    dueDateSelection: string
  }
}

export const handleForbiddenError = (e: CustomHttpClientErrorType) =>
  e.status === 403 && !!e.message ? { action: null } : null

export const getTransformedDeliverables = (deliverableTypes: $TSFixMe[]) =>
  deliverableTypes.map((type) => {
    const deliverables = type.deliverables.map((item: $TSFixMe, index: number) => {
      const { isNew, ...d } = item
      const currentOrder = index + 1

      return isNew ? { ...omit(['id'], d), order: currentOrder } : d
    })

    return {
      ...type,
      deliverables,
    }
  })

export const updateOrder = (deliverableTypes: $TSFixMe[]) => {
  return deliverableTypes.map((deliverableType, index) => ({ ...deliverableType, order: index + 1 }))
}

export const preparePayloadTeams = ({ centralTeams, integrationTeams }: $TSFixMe) => {
  const integrationDeliverableKeys = Object.keys(omit(['id', 'team'], integrationTeams[0]))
  const centralDeliverableKeys = Object.keys(omit(['id', 'team'], centralTeams[0]))
  const accumulator: TeamDeliverable[] = []

  integrationTeams.reduce(
    (
      integrationTeamDeliverables: TeamDeliverable[],
      team: { team: string; id: number } & { [key: string]: TeamDeliverable },
    ) => {
      integrationDeliverableKeys.forEach((key) => {
        const deliverable = team[key]

        const teamDeliverable: TeamDeliverable = {
          id: deliverable.id,
          startDate: deliverable.startDate,
          dueDate: deliverable.dueDate,
          active: deliverable.active,
          teamId: Number(team.id),
          longName: deliverable.longName,
          teamName: team.team,
          deliverableType: deliverable.deliverableType,
        }

        if (
          deliverable.orgDesignTeamLayer &&
          (deliverable.orgDesignTeamLayer.dueDateDesign || deliverable.orgDesignTeamLayer.dueDateSelection)
        ) {
          teamDeliverable.orgDesignTeamLayer = {
            dueDateDesign: deliverable.orgDesignTeamLayer.dueDateDesign,
            dueDateSelection: deliverable.orgDesignTeamLayer.dueDateSelection,
          }
        }

        integrationTeamDeliverables.push(teamDeliverable)
      })

      return integrationTeamDeliverables
    },
    accumulator,
  )

  centralTeams.reduce(
    (
      centralTeamDeliverables: Omit<TeamDeliverable, 'teamId'>[],
      teamDeliverable: { [key: string]: TeamDeliverable } & { team: { team: string } },
    ) => {
      centralDeliverableKeys.forEach((key) => {
        const deliverable = teamDeliverable[key]

        centralTeamDeliverables.push({
          id: deliverable.id,
          startDate: deliverable.startDate,
          dueDate: deliverable.dueDate,
          active: deliverable.active,
          longName: deliverable.longName,
          teamName: teamDeliverable.team.team,
        })
      })

      return centralTeamDeliverables
    },
    accumulator,
  )

  return accumulator
}

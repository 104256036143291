const prefix = '@dayOne/'

export const UPDATE_TASK_LIST_IDS_AND_PROJECT = `${prefix}UPDATE_TASK_LIST_IDS_AND_PROJECT`
export const UPDATE_PROJECT_LIST_IDS = `${prefix}UPDATE_PROJECT_LIST_IDS`
export const UPDATE_PROCESS_LIST_IDS = `${prefix}UPDATE_PROCESS_LIST_IDS`

export const FETCH_PROGRAM_PROJECT_PLAN_DATA = `${prefix}FETCH_PROGRAM_PROJECT_PLAN_DATA`
export const FETCH_PROJECT_LIST = `${prefix}FETCH_PROJECT_LIST`
export const SET_PROJECT_LIST = `${prefix}SET_PROJECT_LIST`

export const SET_PROJECT_LIST_FILTER = `${prefix}SET_PROJECT_LIST_FILTER`

export const FETCH_LIBRARY_PROJECT_LIST = `${prefix}FETCH_LIBRARY_PROJECT_LIST`
export const SET_LIBRARY_PROJECT_LIST = `${prefix}SET_LIBRARY_PROJECT_LIST`
export const UPDATE_LIBRARY_PROJECT_LIST = `${prefix}UPDATE_LIBRARY_PROJECT_LIST`
export const SET_LIBRARY_PROJECT_LIST_STATE = `${prefix}SET_LIBRARY_PROJECT_LIST_STATE`

export const CREATE_PROCESS_FROM_LIB = `${prefix}CREATE_PROCESS_FROM_LIB`

export const SET_SELECTED_PROCESS_ID = `${prefix}SET_SELECTED_PROCESS_ID`

export const SET_VISIBLE_ROW_ID = `${prefix}SET_VISIBLE_ROW_ID`

export const CREATE_KEY_PROCESS = `${prefix}CREATE_KEY_PROCESS`
export const UPDATE_KEY_PROCESS = `${prefix}UPDATE_KEY_PROCESS`
export const DELETE_KEY_PROCESS = `${prefix}DELETE_KEY_PROCESS`

export const CREATE_PROJECT = `${prefix}CREATE_PROJECT`
export const UPDATE_PROJECT = `${prefix}UPDATE_PROJECT`
export const DELETE_PROJECT = `${prefix}DELETE_PROJECT`

export const CREATE_TASK = `${prefix}CREATE_TASK`
export const UPDATE_TASK = `${prefix}UPDATE_TASK`
export const DELETE_TASK = `${prefix}DELETE_TASK`

export const DELETE_DAY_ONE_ITEMS_LIST = `${prefix}DELETE_DAY_ONE_ITEMS_LIST`

export const CREATE_DAY_ONE_PROCESS_ITEM = `${prefix}CREATE_DAY_ONE_PROCESS_ITEM`
export const DELETE_DAY_ONE_PROCESS_ITEM = `${prefix}DELETE_DAY_ONE_PROCESS_ITEM`
export const UPDATE_DAY_ONE_PROCESS_ITEM = `${prefix}UPDATE_DAY_ONE_PROCESS_ITEM`

export const UPDATE_DAY_ONE_ITEM_SUCCESS = `${prefix}UPDATE_DAY_ONE_ITEM_SUCCESS`
export const UPDATE_PREDECESSOR_FOLLOWER_SUCCESS = `${prefix}UPDATE_PREDECESSOR_FOLLOWER_SUCCESS`

export const UPDATE_DAY_ONE_ITEM_ID = `${prefix}UPDATE_DAY_ONE_ITEM_ID`
export const UPDATE_DAY_ONE_ITEM_ID_SUCCESS = `${prefix}UPDATE_DAY_ONE_ITEM_ID_SUCCESS`

export const ADD_LIST_ITEM = `${prefix}ADD_LIST_ITEM`
export const REMOVE_LIST_ITEM = `${prefix}REMOVE_LIST_ITEM`

export const UPLOAD_TASK_FILE = `${prefix}UPLOAD_TASK_FILE`
export const UPLOAD_PROJECT_FILE = `${prefix}UPLOAD_PROJECT_FILE`

export const DELETE_DAY_ONE_PROCESS_ITEM_FILE = `${prefix}DELETE_DAY_ONE_PROCESS_ITEM_FILE`
export const UPLOAD_DAY_ONE_PROCESS_ITEM_FILE = `${prefix}UPLOAD_DAY_ONE_PROCESS_ITEM_FILE`

export const DELETE_DAY_ONE_PROCESS_ITEM_FILE_SUCCESS = `${prefix}DELETE_DAY_ONE_PROCESS_ITEM_FILE_SUCCESS`
export const UPLOAD_DAY_ONE_PROCESS_ITEM_FILE_SUCCESS = `${prefix}UPLOAD_DAY_ONE_PROCESS_ITEM_FILE_SUCCESS`

export const DELETE_TASK_FILE = `${prefix}DELETE_TASK_FILE`
export const DELETE_PROJECT_FILE = `${prefix}DELETE_PROJECT_FILE`

// INTERDEPENDENCIES ACTION TYPES
export const LAUNCH_INTERDEPENDENCY = `${prefix}LAUNCH_INTERDEPENDENCY`
export const LAUNCH_INTERDEPENDENCY_SUCCESS = `${prefix}LAUNCH_INTERDEPENDENCY_SUCCESS`

export const CANCEL_INTERDEPENDENCY = `${prefix}CANCEL_INTERDEPENDENCY`
export const CANCEL_INTERDEPENDENCY_SUCCESS = `${prefix}CANCEL_INTERDEPENDENCY_SUCCESS`

export const UPDATE_INTERDEPENDENCY_TASK = `${prefix}UPDATE_INTERDEPENDENCY_TASK`
export const UPDATE_INTERDEPENDENCY_TASK_SUCCESS = `${prefix}UPDATE_INTERDEPENDENCY_TASK_SUCCESS`

export const UPDATE_INTERDEPENDENCY_TASK_STATUS = `${prefix}UPDATE_INTERDEPENDENCY_TASK_STATUS`

export const FETCH_IMO_LINKED_LIBRARIES_STATE = `${prefix}FETCH_IMO_LINKED_LIBRARIES_STATE`
export const SET_IMO_LINKED_LIBRARIES_STATE = `${prefix}SET_IMO_LINKED_LIBRARIES_STATE`

export const GET_SNAPSHOT_MIN_DATE = `${prefix}GET_SNAPSHOT_MIN_DATE`
export const SET_SNAPSHOT_MIN_DATE = `${prefix}SET_SNAPSHOT_MIN_DATE`

//PROJECT MIRRORING ACTION TYPES
export const ADD_PROJECT_MIRRORING_TEAM = `${prefix}/ADD_PROJECT_MIRRORING_TEAM`
export const DELETE_PROJECT_MIRRORING_TEAM = `${prefix}/DELETE_PROJECT_MIRRORING_TEAM`
export const DELETE_ALL_PROJECT_MIRRORING_TEAMS = `${prefix}/DELETE_ALL_PROJECT_MIRRORING_TEAMS`

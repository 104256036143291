import styled from 'styled-components'
import { Button, Colors, Icon } from '@blueprintjs/core'
import { siderWidth, ACTIVE_SIDER_ICON_COLOR } from '@helpers/constants'
import { PopoverStyle } from '@imo/imo-ui-toolkit/dist/components/Popover'
import { white } from '@imo/imo-ui-toolkit'
import { UserAvatar } from '@shared/UserAvatar'

export const LogoButton = styled.button<{ highlighted: boolean }>`
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;

  background-image: ${({ highlighted }) =>
    highlighted
      ? `linear-gradient(159.93deg, #7961db 2.52%, rgba(17, 20, 217, 0.750417) 70.44%, rgba(21, 100, 217, 0.75) 92.46%)`
      : 'none'};

  &:hover {
    cursor: pointer;
    ${({ highlighted }) =>
      !highlighted &&
      `background-image: linear-gradient(
      159.93deg,
      rgba(121, 97, 219, 0.5) 2.52%,
      rgba(17, 20, 217, 0.375208) 70.44%,
      rgba(21, 100, 217, 0.375) 92.46%
    );`}
  }

  > div {
    margin: 0;
  }
`

export const StyledSider = styled.div<$TSFixMe>`
  width: ${siderWidth}px;
  background: #293742;
  height: 100%;
  top: 0;
  left: 0;
`

export const MainMenu = styled.div`
  width: 45px;
  margin-top: 10px;
`

export const UserMenu = styled(MainMenu)`
  position: absolute;
  bottom: 0px;

  .menu-item {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  @media (max-height: 480px) {
    position: relative;
  }
`

export const MenuItem = styled(Button)<$TSFixMe>`
  cursor: ${({ path, forceClickable, $isDisabled }) => {
    if ($isDisabled) return 'no-drop'

    return path || forceClickable ? 'pointer' : 'unset'
  }};
  width: 45px;
  height: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
  display: ${(props) => (props.$isShown ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: relative;

  &:focus {
    outline-offset: -3px;
  }

  &.bp4-button {
    background-color: #293742 !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;

    &.active {
      background-color: ${Colors.DARK_GRAY2} !important;
    }
  }

  &:hover {
    &.bp4-button {
      background-color: #293742 !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;

      &.active {
        background-color: ${Colors.DARK_GRAY2} !important;
      }
    }

    svg path {
      fill: ${({ path, forceClickable, $isDisabled }) =>
        (!!path || forceClickable) && !$isDisabled ? ACTIVE_SIDER_ICON_COLOR : 'inherited'} !important;
    }

    .css-tooltip {
      opacity: 1;
    }
  }

  &.disabled {
    cursor: no-drop;
  }

  .css-tooltip {
    position: absolute;
    z-index: 100;
    left: calc(100% + 8px);
    top: 50%;
    transform: translate3d(0, -50%, 0);
    white-space: nowrap;
    background-color: ${PopoverStyle.BACKGROUND_COLOR};
    color: ${PopoverStyle.COLOR};
    padding: 10px 12px;
    box-shadow: 0 8px 24px 0 rgba(16, 22, 26, 0.2), 0 2px 4px 0 rgba(16, 22, 26, 0.2), 0 0 0 1px rgba(16, 22, 26, 0.1);
    border-radius: 3px;
    max-width: 300px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      left: -7px;
      top: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 8px 0;
      margin-top: -8px;
      border-color: transparent ${PopoverStyle.BACKGROUND_COLOR} transparent transparent;
    }
  }
`

export const LockIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #ffc940 !important;
`

export const ErrorIcon = styled(Icon)`
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: ${white};
  border-radius: 50%;
`

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PopoverWrapper = styled.div`
  width: auto;
  font-weight: 300;
`

export const PopoverMain = styled.div`
  margin-bottom: 10px;
`

export const UserName = styled.div`
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const UserEmail = styled.div`
  color: ${ACTIVE_SIDER_ICON_COLOR};
  margin: 5px 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PopoverFooterSpaceBetween = styled.div`
  border-top: 1px solid #293742;
  display: flex;
  justify-content: end;
  margin: 0 -${PopoverStyle.HORIZONTAL_PADDING} -${PopoverStyle.VERTICAL_PADDING};
  padding: 0 ${PopoverStyle.HORIZONTAL_PADDING};
`

export const PopoverFooter = styled.div`
  border-top: 1px solid #293742;
  display: flex;
  justify-content: end;
  margin: 0 -${PopoverStyle.HORIZONTAL_PADDING} -${PopoverStyle.VERTICAL_PADDING};
  padding: 0 ${PopoverStyle.HORIZONTAL_PADDING};
`

export const LogoutButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 35px;
`

export const DiagnosticLabel = styled.span`
  margin-left: 5px;
`

export const DiagnosticButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 35px;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  padding-right: 20px;
`

export const LogoutLabel = styled.span`
  margin-left: 5px;
`

export const UserInfoContainer = styled.div`
  display: flex;
`

export const StyledUserAvatar = styled(UserAvatar)`
  width: 41px;
  height: 41px;
  flex-shrink: 0;
  margin-right: 11px;
  font-size: 16px;
  font-weight: 500;
`

export const UserInfo = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
`

export const Divider = styled.div`
  border-bottom: solid 1px #293742;
  margin: 8px -12px;
`

export const TeamItem = styled.div`
  font-size: 12px;
  line-height: 18px;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`

export const TeamName = styled.span<$TSFixMe>`
  color: ${white};
  background: ${(props) => props.typeColor || '#1F40E6'};
  padding: 2px 5px;
  border-radius: 2px;
  margin-right: 4px;
`

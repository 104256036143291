import { routesList } from './routesList'
import { DAY_ONE_ID } from '@helpers/constants'

// User roles in order by weight
export const userRoles = {
  LAUNCHPAD_ADMIN: 'launchpad admin',
  CONFIG_ADMIN: 'config admin',
  USER_ADMIN: 'user admin',
  INSTANCE_ADMIN: 'instance admin',
  GLOBAL_IMO: 'global imo',
  RESPONSIBLE_IMO: 'responsible imo',
  LEAD: 'team lead',
  MEMBER_WITH_VC_AND_ORG_DESIGN: 'team member (with value capture and org design)',
  MEMBER_WITH_VC: 'team member with vc',
  MEMBER: 'team member',
  MEMBER_WITHOUT_VC: 'team member without vc',
  MEMBER_WITHOUT_VC_READ_ONLY: 'team member without vc (read-only)',
  MEMBER_READ_ONLY: 'team member (read-only)',
  RESTRICTED_WITH_VC: 'restricted user (with value capture)',
  RESTRICTED: 'restricted user',
}

export const userRolesOrder = {
  [userRoles.LEAD]: 0,
  [userRoles.MEMBER]: 1,
  [userRoles.MEMBER_WITH_VC]: 2,
  [userRoles.MEMBER_WITHOUT_VC]: 3,
  [userRoles.MEMBER_READ_ONLY]: 4,
  [userRoles.MEMBER_WITHOUT_VC_READ_ONLY]: 5,
  [userRoles.MEMBER_WITH_VC_AND_ORG_DESIGN]: 6,
  [userRoles.RESTRICTED_WITH_VC]: 7,
  [userRoles.RESTRICTED]: 8,
}

// Teams for permissions controller
export const teams = {
  IMO_GLOBAL: 'IMO-Global',
  IMO: 'IMO',
  SMO_GLOBAL: 'SMO-Global',
  SMO: 'SMO',
  CENTRAL: 'Central',
  CENTRAL_VC: 'Central_valueCapture',
  CENTRAL_ORG_DESIGN: 'Central_orgDesignAndTalent',
  INTEGRATION: 'Integration',
  SEPARATION: 'Separation',
  REPORTER: 'Reporter',
}

export const teamTypesOrder = [
  teams.IMO_GLOBAL,
  teams.SMO_GLOBAL,
  teams.IMO,
  teams.SMO,
  teams.CENTRAL_ORG_DESIGN,
  teams.CENTRAL_VC,
  teams.CENTRAL,
  teams.INTEGRATION,
  teams.SEPARATION,
]

export const transformTeamType = (teamType: string) => {
  if ([teams.IMO_GLOBAL, teams.SMO_GLOBAL].includes(teamType)) {
    return teams.IMO_GLOBAL
  }

  if ([teams.IMO, teams.SMO].includes(teamType)) {
    return teams.IMO
  }

  if ([teams.INTEGRATION, teams.SEPARATION].includes(teamType)) {
    return teams.INTEGRATION
  }

  return teamType
}

// Teams for team selects
export const teamTitles = {
  IMO: 'IMO',
  INTEGRATION: 'Integration',
  SMO: 'SMO',
  SEPARATION: 'Separation',
  CENTRAL: 'Central',
  CENTRAL_VC: 'ValueCaptureCentralTeams',
  CENTRAL_ORG_DESIGN: 'OrgDesignCentralTeams',
}

export const accessRights = {
  READ_WRITE: 'read-write',
  READ_ONLY: 'read-only',
  READ_WRITE_REVIEW: 'read-write-review',
  RESTRICTED: 'restricted',
}

export const getDayOneDeliverablePath = (day1DeliverableId: number) => {
  return `${routesList.DASHBOARD.DELIVERABLE.ROOT.route}/${day1DeliverableId || DAY_ONE_ID}/team/:teamId`
}

export const GLOBAL_IMO = 'Global IMO'

export const GLOBAL_IMO_SHORT_NAME = 'G-IMO'

export const GLOBAL_SMO = 'Global SMO'

export const GLOBAL_SMO_SHORT_NAME = 'G-SMO'

export const VALUE_CAPTURE = 'Value Capture'
export const ORG_DESIGN = 'Org Design and Talent'

export const libraryGroups = ['central', 'separation', 'integration']

export const VC_DELIVERABLE_TYPE_ID = 3
export const OD_DELIVERABLE_TYPE_ID = 2

// classification field for communication calendar rights
export const COMMUNICATIONS_CLASSIFICATION = 'Communications'

export const PERMISSION_ACTION = {
  EDIT: 'edit',
  CONFIGURE: 'configure',
  VIEW: 'view',
  ADD: 'add',
  DELETE: 'delete',
  SUBMIT: 'submit',
  REVIEW: 'review',
  UPLOAD: 'upload',
  IMPORT: 'import',
  COMPLETE: 'complete',
}

export const PERMISSION_STATE = {
  READ: 'read',
  WRITE: 'write',
}

export const GANTT_PERMISSION = {
  VIEW: 'view gantt',
}

export const BASIC_PERMISSIONS = [PERMISSION_ACTION.EDIT]

export const MIRROR_PERMISSION = {
  SHOW_BUTTON: 'show button',
}

export const RENAME_HEADER_STATUS_UPDATE_PERMISSION = {
  AVAILABLE_EDIT: 'available_edit',
}

import { Container } from './AskContainer.styles'
import { Chat, useUserInfo } from '@imo/chat'
import { toggleChat } from '@generic/actions/actions'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hasChatBeenOpened, isChatOpen } from '@generic/selectors'
import { useLocation } from 'react-router-dom'

export const AskContainer = () => {
  const { isStandardUser } = useUserInfo()
  const location = useLocation()
  const [currentLocation, setCurrentLocation] = useState(location)
  const dispatch = useDispatch()

  const isOpen = useSelector(isChatOpen)
  const hasBeenOpened = useSelector(hasChatBeenOpened)

  const messagesContainerRef = useRef<HTMLDivElement & { scrollToBottom: () => void }>(null)

  useEffect(() => {
    if (location.pathname !== currentLocation.pathname && isOpen) {
      dispatch(toggleChat())
    }
    setCurrentLocation(location)
  }, [location.pathname, currentLocation.pathname, isOpen])

  const yPos = () => {
    // Get all elements that compose the header and return the total height
    const navAreas = document.getElementsByClassName('header-component')
    return Array.from(navAreas).reduce((acc, a) => acc + a.getBoundingClientRect().height, 0)
  }

  useEffect(() => {
    if (isOpen && messagesContainerRef.current) {
      // Ensure messages are always scrolled to bottom when chat is opened
      messagesContainerRef.current.scrollToBottom()
    }
  }, [isOpen])

  if (!hasBeenOpened || !isStandardUser) {
    return null
  }

  return (
    <Container data-testid="main-content-chat-container" isOpen={isOpen} yPos={yPos()}>
      <Chat onClose={() => dispatch(toggleChat())} ref={messagesContainerRef} />
    </Container>
  )
}

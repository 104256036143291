import { createSaga } from '@common/sagaCreator/createSaga'
import { getGenericApi } from '@common/net'
import { call, put } from 'redux-saga/effects'
import { setKnowledgeCenterVisibilityStatusSuccess } from '@generic/actions/actions'

export const setKnowledgeCenterVisibilityStatus = createSaga(function* setKnowledgeCenterVisibilityStatus({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, status } = payload
  const api = yield getGenericApi()

  yield put(setKnowledgeCenterVisibilityStatusSuccess({ teamId, status }))

  try {
    yield call(api.request('setKnowledgeCenterVisibilityStatus', { query: { teamId }, body: { status } }))
  } catch (e) {
    yield put(setKnowledgeCenterVisibilityStatusSuccess({ teamId, status: !status }))

    throw e
  }
})

import { Container } from './AskContainer.styles'
import { SupportBot, useUserInfo } from '@imo/chat'
import { toggleSupportBot } from '@generic/actions/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAccessToken,
  getRefreshedToken,
  getTenantId,
  hasSupportBotBeenOpened,
  isSupportBotOpen,
} from '@generic/selectors'
import Config from '@config/index'
import { useLocation } from 'react-router-dom'

export const SupportContainer = () => {
  const { isAdmin } = useUserInfo()
  const location = useLocation()
  const [currentLocation, setCurrentLocation] = useState(location)
  const dispatch = useDispatch()

  const isOpen = useSelector(isSupportBotOpen)
  const hasBeenOpened = useSelector(hasSupportBotBeenOpened)

  const initialToken = useSelector(getAccessToken)
  const refreshedToken = useSelector(getRefreshedToken)
  const activeToken = refreshedToken || initialToken
  const tenantId = useSelector(getTenantId)

  //   const messagesContainerRef = useRef<HTMLDivElement & { scrollToBottom: () => void }>(null)

  useEffect(() => {
    if (location.pathname !== currentLocation.pathname && isOpen) {
      dispatch(toggleSupportBot())
    }
    setCurrentLocation(location)
  }, [location.pathname, currentLocation.pathname, isOpen])

  const yPos = () => {
    // Get all elements that compose the header and return the total height
    const navAreas = document.getElementsByClassName('header-component')
    return Array.from(navAreas).reduce((acc, a) => acc + a.getBoundingClientRect().height, 0)
  }

  // TODO: Scroll to bottom imperative handle needs to be implemented for lite chat.
  //   useEffect(() => {
  //     if (isOpen && messagesContainerRef.current) {
  //       // Ensure messages are always scrolled to bottom when chat is opened
  //       messagesContainerRef.current.scrollToBottom()
  //     }
  //   }, [isOpen])

  if (!hasBeenOpened || !isAdmin) {
    return null
  }

  return (
    <Container data-testid="support-bot-chat-container" isOpen={isOpen} yPos={yPos()}>
      <SupportBot.Provider token={activeToken} tenantId={tenantId} proxyBaseURL={Config.ASK_WS_SERVER}>
        <SupportBot.Chat onClose={() => dispatch(toggleSupportBot())} />
      </SupportBot.Provider>
    </Container>
  )
}

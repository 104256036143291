import { accessRights, teams } from '../constants'
import { routesList } from '../routesList'
import { CENTRAL_LEAD_PERMISSIONS } from './centralLead'

export const CENTRAL_VC_LEAD_PERMISSIONS = {
  ...CENTRAL_LEAD_PERMISSIONS,
  priority: 2,
  name: teams.CENTRAL_VC,
  teamsPermissions: {
    deliverable: accessRights.READ_WRITE,
  },
  permissions: [
    ...CENTRAL_LEAD_PERMISSIONS.permissions,
    {
      route: routesList.VALUE_CAPTURE_TRACKER_V2.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
  ],
}

import * as Yup from 'yup'

export const deliverablesSchema = Yup.array().of(
  Yup.object().shape({
    deliverables: Yup.array().of(
      Yup.object({
        dueDate: Yup.date()
          .nullable()
          .when('active', { is: true, then: Yup.date().required('All dates should be set') }),
      }),
    ),
  }),
)

import { createSaga } from '@common/sagaCreator/createSaga'
import { getKnowledgeCenterApi } from '@common/net'
import { call, cancelled, put, select } from 'redux-saga/effects'
import { buildUrlPath } from '@imo/imo-ui-toolkit/dist/helpers/utils'
import Config from '@config/index'
import { loadingState } from '@imo/imo-ui-toolkit/dist/helpers/constants'
import * as actions from '@domain/myImoClient/myTeam/knowledgeCenter/actions/actions'
import * as actionTypes from '@domain/myImoClient/myTeam/knowledgeCenter/actions/actionTypes'
import {
  getIntroductionMaterials,
  getSelectedItem,
  getIntroductionMaterialsFetchDate,
} from '@domain/myImoClient/myTeam/knowledgeCenter/selectors'
import { getMidCore, getXTenant } from '@generic/selectors'
import { makeUniqueName } from '@myImoClient/components/KnowledgeCenter/utils'
import { sortBy, flow, get, find } from 'lodash/fp'
import { URL_EXPIRE_MINUTES } from '@helpers/constants'

export const fetchIntroductionMaterials = createSaga(function* fetchIntroductionMaterials({
  payload: { teamId, date },
}: {
  payload: { teamId: string; date: $TSFixMe }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  const introductionMaterials = yield call(
    api.request('getKnowledgeCenterIntroductionMaterials', { query: { teamId } }),
  )

  yield put(
    actions.setKnowledgeCenterIntroductionMaterials({
      introductionMaterialsFetchDate: date ?? new Date(),
      introductionMaterials: sortBy('index', introductionMaterials),
    }),
  )
},
actionTypes.SET_KNOWLEDGE_CENTER_INTRODUCTION_MATERIALS_STATE)

export const fetchIntroductionMaterialsAndDownload = createSaga(
  function* fetchIntroductionMaterialsUpdateAndFileDownload({
    payload: { item, teamId },
  }: {
    payload: { teamId: string; item: $TSFixMe }
  }): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const introductionMaterialsFetchDate = yield select(getIntroductionMaterialsFetchDate)
    const fetchTime = introductionMaterialsFetchDate.getTime()
    const currentTime = new Date().getTime()
    const minutesPast = (currentTime - fetchTime) / (1000 * 60)
    const link = document.createElement('a')

    link.href = item.url
    link.download = item.fileName

    if (minutesPast > URL_EXPIRE_MINUTES) {
      const api = yield getKnowledgeCenterApi()
      const introductionMaterials = yield call(
        api.request('getKnowledgeCenterIntroductionMaterials', { query: { teamId } }),
      )

      yield put(
        actions.setKnowledgeCenterIntroductionMaterials({
          introductionMaterialsFetchDate: new Date(),
          introductionMaterials: sortBy('index', introductionMaterials),
        }),
      )

      const updatedUrl = flow(find({ id: item.id }), get('url'))(introductionMaterials)

      link.href = updatedUrl
    }

    link.click()
  },
)

export const createNewIntroductionMaterial = createSaga(function* createNewIntroductionMaterial({
  payload: teamId,
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const abortController = new AbortController()

  try {
    const api = yield getKnowledgeCenterApi()
    const introductionMaterials = yield select(getIntroductionMaterials)
    const mid = yield select(getMidCore)
    const xTenant = yield select(getXTenant)
    const { title, description, type, file, isShared } = yield select(getSelectedItem)

    const headers = {
      authorization: `bearer ${mid.accessToken()}`,
      'x-tenant': xTenant,
    }

    const uploadParams = {
      headers,
      signal: abortController.signal,
      body: file,
      method: 'POST',
    }

    yield fetch(
      buildUrlPath(
        `teams/${teamId}/knowledge-center/introduction-material/upload`,
        { key: file.name },
        Config.API_SERVER,
      ),
      uploadParams,
    )

    const incrementedTitle = makeUniqueName(introductionMaterials, title, 'title')

    const body = {
      key: file.name,
      title: incrementedTitle,
      description,
      type,
      libraryFunctionId: null,
      isShared,
    }

    yield call(api.request('createKnowledgeCenterIntroductionMaterial', { query: { teamId }, body }))

    yield call(fetchIntroductionMaterials, { payload: { teamId } })
  } finally {
    if (yield cancelled()) {
      abortController.abort()
    }
  }
},
actionTypes.CREATE_NEW_INTRODUCTION_MATERIAL_STATE)

export const updateIntroductionMaterial = createSaga(function* updateIntroductionMaterial({
  payload: teamId,
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const abortController = new AbortController()
  try {
    const api = yield getKnowledgeCenterApi()
    const { title, description, type, file, index, key, id, isShared, originalTitle } = yield select(getSelectedItem)
    const mid = yield select(getMidCore)
    const xTenant = yield select(getXTenant)
    const introductionMaterials = yield select(getIntroductionMaterials)

    const headers = {
      authorization: `bearer ${mid.accessToken()}`,
      'x-tenant': xTenant,
      'Content-Type': 'application/json',
    }

    let filePath = key

    if (file?.name) {
      const uploadParams = {
        headers,
        signal: abortController.signal,
        body: file,
        method: 'POST',
      }

      yield fetch(
        buildUrlPath(
          `teams/${teamId}/knowledge-center/introduction-material/upload`,
          { key: file.name },
          Config.API_SERVER,
        ),
        uploadParams,
      )
        .then((data) => data.json())
        .then((data) => (filePath = data.filePath))
    }

    const isTitleWasChanged = title !== originalTitle
    const incrementedTitle = isTitleWasChanged ? makeUniqueName(introductionMaterials, title, 'title') : title

    const body = {
      key: filePath,
      title: incrementedTitle,
      description,
      index,
      type,
      libraryFunctionId: null,
      isShared,
    }

    yield call(
      api.request('updateKnowledgeCenterIntroductionMaterial', {
        query: { teamId, materialId: id },
        body,
      }),
    )

    yield call(fetchIntroductionMaterials, { payload: { teamId } })
  } finally {
    if (yield cancelled()) {
      abortController.abort()
    }
  }
},
actionTypes.UPDATE_INTRODUCTION_MATERIAL_STATE)

export const deleteIntroductionMaterial = createSaga(function* deleteIntroductionMaterial({
  payload: { teamId, materialId },
}: {
  payload: { teamId: string; materialId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  yield call(api.request('deleteIntroductionMaterial', { query: { teamId, materialId } }))
  yield call(fetchIntroductionMaterials, { payload: { teamId, date: 0 } })
})

export const saveIntroductionMaterialsOrder = createSaga(function* saveIntroductionMaterialsOrder({
  payload: { teamId },
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()
  const introductionMaterials = yield select(getIntroductionMaterials)
  const materials = introductionMaterials.map(({ id }: $TSFixMe) => id)

  yield call(api.request('saveIntroductionMaterialsOrder', { query: { teamId, materials } }))
})

export const resetIntroductionMaterialLoadingState = createSaga(function* resetIntroductionMaterialLoadingState({
  payload: state,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  if (state === loadingState.SUCCESS) {
    yield put(actions.resetCreateIntroductionMaterialState())
    yield put(actions.setSelectedItem({}))
    yield put(actions.setSelectedItemErrors({}))
    yield put(actions.changeEditModalState({ isModalOpen: false }))
  }
})

import { call, put } from 'redux-saga/effects'
import { setKeyIssues } from '@teamHome/actions/actions'
import { getMyTeamApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchKeyIssues = createSaga(function* fetchKeyIssues({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const myTeamApi = yield getMyTeamApi()
  const keyIssuesData = yield call(myTeamApi.request('getKeyIssues', { query: { teamId: payload } }))

  yield put(setKeyIssues(keyIssuesData))
})

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class FunctionalProcessDesignApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  getFuncDesignProcesses = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `teams/${query.teamId}/functional-process-design` })
  }

  createFuncDesignProcess = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/functional-process-design`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateFuncDesignProcess = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/functional-process-design/${query.processId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteFuncDesignProcess = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/functional-process-design/${query.processId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }
}

/* eslint-disable react/require-default-props */
import React, { memo, useState } from 'react'
import { SelectorButton, StyledSelect } from './index.styles'
import { MenuItem } from '@blueprintjs/core'

interface Props {
  itemsList: {
    name?: string
    id?: string | number
  }[]
  handleSelect?: (...args: $TSFixMe[]) => $TSFixMe
  placeholder?: string
  buttonProps?: $TSFixMe
}

const Selector = ({ handleSelect = () => {}, itemsList, placeholder, buttonProps, ...selectProps }: Props) => {
  const [selectedItem, setSelectedItem] = useState(null)

  const handleOnItemSelect = (item: $TSFixMe) => {
    setSelectedItem(item)
    handleSelect(item)
  }

  const isItemSelected = (team: $TSFixMe) => selectedItem && (selectedItem as $TSFixMe).id === team.id

  const renderItem = (itemData: $TSFixMe, { handleClick }: $TSFixMe) => {
    return (
      <MenuItem
        active={isItemSelected(itemData) as $TSFixMe}
        key={itemData.id}
        onClick={handleClick}
        text={itemData.longName}
      />
    )
  }

  return (
    <StyledSelect items={itemsList} itemRenderer={renderItem} onItemSelect={handleOnItemSelect} {...selectProps}>
      <SelectorButton
        rightIcon="caret-down"
        text={!!selectedItem ? (selectedItem as $TSFixMe).longName : placeholder}
        isSelected={!!selectedItem}
        disabled={false}
        usePortal={false}
        {...buttonProps}
      />
    </StyledSelect>
  )
}

Selector.defaultProps = {
  placeholder: 'Select item...',
  buttonProps: {},
}

export default memo(Selector)

import { handleActions } from 'redux-actions'
import {
  FETCH_DATA_PENDING,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAIL,
  SET_DATA_INTERDEPENDENCY_POPUP_DATA,
  UPDATE_INTERDEPENDENCY_POPUP_DATA,
  CLEAR_STATE_INTERDEPENDENCY_POPUP_DATA,
  SET_INTERDEPENDENCY_SUMMARY,
  SET_INTERDEPENDENCY_SUMMARY_TOTALS,
  SET_INTERDEPENDENCY_FREQUENCY,
} from '../actions/actionTypes'
import { isMatch } from 'lodash'

export interface IInterdependenciesState {
  loading: boolean
  data: $TSFixMe[]
  popupData: $TSFixMe[]
  locked: $TSFixMe | null
  summary: {
    list: $TSFixMe[]
    totals: $TSFixMe
    frequency: $TSFixMe[]
  }
}

export const initialState = {
  loading: false,
  data: [],
  popupData: [],
  locked: null,
  summary: {
    list: [],
    totals: {},
    frequency: [],
  },
}

const interdependenciesReducer = handleActions<IInterdependenciesState, $TSFixMe>(
  {
    [FETCH_DATA_PENDING]: (state) => ({
      ...state,
      loading: true,
    }),
    [FETCH_DATA_SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload.data,
      locked: payload.locked,
    }),
    [FETCH_DATA_FAIL]: (state) => ({
      ...state,
      loading: false,
    }),
    [SET_DATA_INTERDEPENDENCY_POPUP_DATA]: (state, { payload }) => {
      const { data } = payload

      return {
        ...state,
        popupData: data,
      }
    },
    [UPDATE_INTERDEPENDENCY_POPUP_DATA]: (state, { payload }) => {
      const { senderTeamId, taskId, interdependencyId, status, isReceiverCase } = payload

      return {
        ...state,
        popupData: state.popupData.map((data) => {
          const source = !isReceiverCase
            ? { senderTeamId, taskId, interdependencyId }
            : { receiverTeamId: senderTeamId, taskId, interdependencyId }

          return isMatch(data, source) ? { ...data, status } : data
        }),
      }
    },
    [CLEAR_STATE_INTERDEPENDENCY_POPUP_DATA]: (state) => ({
      ...state,
      popupData: [],
    }),
    [SET_INTERDEPENDENCY_SUMMARY]: (state, { payload: { list } }) => ({
      ...state,
      summary: {
        ...state.summary,
        list,
      },
    }),
    [SET_INTERDEPENDENCY_SUMMARY_TOTALS]: (state, { payload: { totals } }) => ({
      ...state,
      summary: {
        ...state.summary,
        totals,
      },
    }),
    [SET_INTERDEPENDENCY_FREQUENCY]: (state, { payload: { frequency } }) => ({
      ...state,
      summary: {
        ...state.summary,
        frequency,
      },
    }),
  },
  initialState,
)

export default interdependenciesReducer

import { call } from 'redux-saga/effects'
import { getCommunicationsApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const deleteEventAttachment = createSaga(function* deleteEventAttachment({
  eventId,
  fileId,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getCommunicationsApi()
  yield call(api.request('deleteEventAttachment', { query: { eventId, fileId } }))
})

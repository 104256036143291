import { createSaga } from '@common/sagaCreator/createSaga'
import { all, put } from 'redux-saga/effects'
import {
  fetchTeamCharterDataWithLoader,
  setInScopeKeyProcesses,
  setKeyDecisionToMake,
  setKeyInterdependencies,
  setKeyRiskToMitigate,
  setMacroPlanningAssumption,
  setOutScopeKeyProcesses,
  setTeamCharterData,
  setTeamMembers,
} from '../actions/actions'

export const refetchTeamCharterData = createSaga(function* refetchTeamCharterData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  yield all([
    put(setTeamMembers([])),
    put(setInScopeKeyProcesses([])),
    put(setOutScopeKeyProcesses([])),
    put(setMacroPlanningAssumption([])),
    put(setKeyInterdependencies([])),
    put(setKeyRiskToMitigate([])),
    put(setKeyDecisionToMake([])),
    put(setTeamCharterData([])),
  ])

  yield put(fetchTeamCharterDataWithLoader(action.payload))
},
false)

import { Action, handleActions } from 'redux-actions'
import { FETCH_INSTANCE_CONFIG_PENDING, FETCH_INSTANCE_CONFIG_SUCCESS, FETCH_INSTANCE_CONFIG_FAIL } from './actions'
import { InstanceConfigReducerType } from './types.d'
import { deepmerge } from '@common/deepmerge'

const modules = {
  revenueSynergies: {
    active: false,
    locked: false,
  },
  mirroringRestrictions: {
    active: false,
    locked: false,
  },
  mutualDiscovery: {
    active: false,
    locked: false,
  },
  documentSharing: {
    active: false,
    locked: false,
  },
  valueCapture: {
    active: false,
    locked: false,
  },
  communications: {
    active: false,
    locked: false,
  },
  dataRequestTracker: {
    active: false,
    locked: false,
  },
  imoDeliverablesPanel: {
    active: false,
    locked: false,
  },
  teamCalendar: {
    active: false,
    locked: false,
  },
  teamResources: {
    active: false,
    locked: false,
  },
  fileAttachments: {
    active: false,
    locked: false,
  },
  NWCSynergies: {
    active: false,
    locked: false,
  },
  contentVault: {
    active: false,
    locked: false,
  },
  valueCaptureInitiativesUpload: {
    active: false,
    locked: false,
  },
  day1HypercareLog: {
    active: false,
    locked: false,
  },
  mcKinseyLibrary: {
    active: false,
    locked: false,
  },
  notifications: {
    active: false,
    locked: false,
  },
  knowledgeCenter: {
    active: false,
    locked: false,
  },
  weeklyStatusUpdate: {
    active: false,
    locked: false,
  },
  advancedFinancials: {
    active: false,
    locked: false,
  },
  unreleasedFeatures: {
    active: false,
    locked: false,
  },
  orgDesign: {
    active: false,
    locked: false,
  },
  askMyIMO: {
    active: false,
    locked: false,
  },
}

const modulesSelect = {
  mirroringTeams: {
    value: '',
  },
  publishDay: {
    value: '',
  },
  stageGatesCalculationType: {
    value: '',
  },
  lockDueDateD1: {
    value: {
      isActive: false,
      allowedPrioritiesList: null,
    },
  },
  targetBase: {
    value: '',
  },
  initiativeColumnsCustomNames: {
    value: '',
  },
  projectTaskStatusDate: {
    value: '',
  },
  initiativeStatusDate: {
    value: '',
  },
  statusV3SectionNames: {
    value: '',
  },
  calendarView: {
    value: '',
  },
}

const tenantCustomization = {
  TSAOptions: [],
  macroPlanningTitles: {
    integration: {
      main: '',
      day1: '',
      day100: '',
      futureState: '',
    },
    separation: {
      main: '',
      day1: '',
      day100: '',
      futureState: '',
    },
  },
  potentialImpact: [],
  isEnabledKeyIssues: false,
  mdpaTSAColumnName: 'TSA',
  dataRequestTrackerConfig: undefined,
}

const initialState: InstanceConfigReducerType = {
  currency: '',
  currencyRounding: '',
  separator: '',
  thousandsSeparator: '',
  plannedEndDate: '',
  isLaunched: false,
  legalDisclaimer: '',
  welcomeNote: '',
  dealType: '',
  weekDay: '',
  timeHour: '',
  supportEmail: '',
  TSAVersion: -1,
  statusUpdateVersion: -1,
  isResetStarted: false,
  termsConditions: '',
  companyA: {
    name: '',
    logo: '',
  },
  companyB: {
    name: '',
    logo: '',
  },
  dayOneDate: '',
  modules,
  modulesSelect,
  tenantCreationDate: '',
  tenantCustomization,
  lockResetButtonColumnId: '',
  activeRestrictions: false,
  fiscalYear: '',
}

// https://thanarie.gitbooks.io/typescript-webpack-book/content/redux-actions.html
const instanceConfigReducer = handleActions<InstanceConfigReducerType, Action<InstanceConfigReducerType>>(
  {
    [FETCH_INSTANCE_CONFIG_PENDING]: (state) => state,
    [FETCH_INSTANCE_CONFIG_SUCCESS]: (state, { payload }) => deepmerge(state, payload),
    [FETCH_INSTANCE_CONFIG_FAIL]: (state) => state,
  },
  initialState,
)

export default instanceConfigReducer

import { handleActions } from 'redux-actions'
import * as constants from '@teamResources/fullProjectPlan/actions/actionTypes'

export interface IFullProjectPlanState {
  fullProjectPlanData: $TSFixMe[]
  locked: boolean
}

export const initialState = {
  fullProjectPlanData: [],
  locked: false,
}

const fullProjectPlanReducer = handleActions<IFullProjectPlanState, $TSFixMe>(
  {
    [constants.SET_FULL_PROJECT_PLAN]: (state, action) => {
      const { items, locked } = action.payload

      return {
        ...state,
        fullProjectPlanData: items,
        locked,
      }
    },
  },
  initialState,
)

export default fullProjectPlanReducer

import { handleActions } from 'redux-actions'
import * as constants from '@projectWatchers/actions/actionTypes'

interface IProjectWatchersState {
  [key: string]: $TSFixMe[]
}

export const initialState = {}

const projectWatchersReducer = handleActions<IProjectWatchersState, $TSFixMe>(
  {
    [constants.SET_PROJECT_WATCHERS]: (state, action) => {
      const { keyProcessProjectId, watchers } = action.payload

      return { ...state, [keyProcessProjectId]: watchers }
    },

    [constants.ADD_PROJECT_WATCHER]: (state, action) => {
      const { entityId, watcher } = action.payload

      return {
        ...state,
        [entityId]: state[entityId] ? [...state[entityId], watcher] : [watcher],
      }
    },

    [constants.REMOVE_PROJECT_WATCHER]: (state, action) => {
      const { entityId, userId } = action.payload

      if (!state[entityId]) {
        return state
      }

      const updatedWatchers = state[entityId].filter((watcher) => watcher.userId !== userId)

      return { ...state, [entityId]: updatedWatchers }
    },
  },
  initialState,
)

export default projectWatchersReducer

// Libs
import React from 'react'

// Components
import { Divider } from '@blueprintjs/core'
import { Container, Content, Header, HeaderIcon, HeaderTitle, Message } from './NotFoundPage.styles'

const NotFoundPage = () => (
  <Container>
    <Content>
      <Header>
        <HeaderIcon icon="geosearch" size={60} />
        <HeaderTitle>Page not found</HeaderTitle>
      </Header>
      <Divider className="header-divider" />
      <Message>
        We were not able to locate the page you are looking for. Please contact your program administrator if you
        require further assistance.
      </Message>
    </Content>
  </Container>
)

export default NotFoundPage

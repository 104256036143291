import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@teamDeliverables/actions/actionTypes'

import { fetchTeamDeliverableData } from './fetchTeamDeliverableData'
import { setTeamDeliverableStatus } from './setTeamDeliverableStatus'
import { fetchTeamDeliverables } from './fetchTeamDeliverablesData'
import { fetchTeamDeliverablesTracker } from './fetchTeamDeliverablesTrackerData'
import { fetchCommentsList } from './fetchCommentsList'
import { createComment } from './createComment'
import { updateDeliverableMeta } from './updateDeliverableMeta'

export default function* deliverablesSaga() {
  yield all([
    takeLatest(constants.FETCH_TEAM_DELIVERABLE_DATA, fetchTeamDeliverableData),
    takeLatest(constants.SET_DELIVERABLE_STATUS, setTeamDeliverableStatus),
    takeLatest(constants.FETCH_TEAM_DELIVERABLES_DATA, fetchTeamDeliverables),
    takeLatest(constants.FETCH_DELIVERABLES_TRACKER_DATA, fetchTeamDeliverablesTracker),
    takeLatest(constants.FETCH_COMMENTS, fetchCommentsList),
    takeLatest(constants.CREATE_COMMENT, createComment),
    takeLatest(constants.UPDATE_DELIVERABLE_META, updateDeliverableMeta),
  ])
}

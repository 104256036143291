import { createAction } from 'redux-actions'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'
import * as constants from './actionTypes'

export const fetchModuleOptionsPending = createAction(
  constants.FETCH_MODULE_OPTIONS_PENDING,
  null,
  getMetaWithActionLoading,
)

export const fetchModuleOptionsSuccess = createAction(constants.FETCH_MODULE_OPTIONS_SUCCESS)
export const fetchModuleOptionsFail = createAction(constants.FETCH_MODULE_OPTIONS_FAIL)

export const saveModuleOptionsPending = createAction(constants.SAVE_MODULE_OPTIONS_PENDING)
export const saveModuleOptionsSuccess = createAction(constants.SAVE_MODULE_OPTIONS_SUCCESS)
export const saveModuleOptionsFail = createAction(constants.SAVE_MODULE_OPTIONS_FAIL)

export const updateDictionaries = createAction(constants.UPDATE_DICTIONARIES)
export const updateModules = createAction(constants.UPDATE_MODULES)
export const updateModulesSelect = createAction(constants.UPDATE_MODULES_SELECT)

export const setAirtableCustomColumnId = createAction(constants.SET_AIRTABLE_CUSTOM_COLUMN_ID)

export const addAirtableIntegrationTeam = createAction(constants.ADD_AIRTABLE_INTEGRATION_TEAM)

export const updateAirtableIntegrationTeam = createAction(constants.UPDATE_AIRTABLE_INTEGRATION_TEAM)
export const deleteAirtableIntegrationTeam = createAction(constants.DELETE_AIRTABLE_INTEGRATION_TEAM)
export const deleteDictionary = createAction(constants.DELETE_DICTIONARY)
export const hideAirtableDictionary = createAction(constants.DEACTIVATE_AIRTABLE_DICTIONARY)
export const unsetAirtableDictionary = createAction(constants.UNSET_AIRTABLE_DICTIONARY)

export const bulkUpdateModuleOptions = createAction(constants.BULK_UPDATE_MODULE_OPTIONS)

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class InitiativeListApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  // Initiatives
  getInitiativeList = ({ query }: $TSFixMe) => {
    const { teamId, synergyType } = query

    return this.call({
      pathname: `teams/${teamId}/value-capture/v2/initiatives-v2?synergyType=${synergyType}`,
    })
  }

  getInfinityInitiativeList = ({ query: { teamId }, body }: $TSFixMe) => {
    return this.call({ pathname: `teams/${teamId}/value-capture/v2/initiatives-v2/list` }, { method: 'POST', body })
  }

  getInitiativeFiltersAndCount = ({ query, body }: $TSFixMe) => {
    const { teamId } = query

    return this.call({ pathname: `teams/${teamId}/value-capture/v2/initiatives-v2/filters` }, { method: 'POST', body })
  }

  getUserInitiativeFilter = ({ query }: $TSFixMe) => {
    const { teamId, synergyType } = query

    return this.call({ pathname: `teams/${teamId}/value-capture/v2/user-initiative-filter?synergyType=${synergyType}` })
  }

  saveUserInitiativeFilter = ({ query, body }: $TSFixMe) => {
    const { teamId, synergyType } = query

    return this.call(
      { pathname: `teams/${teamId}/value-capture/v2/user-initiative-filter?synergyType=${synergyType}` },
      { method: 'POST', body },
    )
  }

  changeUserInitiativeFilter = ({ query, body }: $TSFixMe) => {
    const { teamId, filterId, synergyType } = query

    return this.call(
      { pathname: `/teams/${teamId}/value-capture/v2/user-initiative-filter/${filterId}?synergyType=${synergyType}` },
      { method: 'PATCH', body },
    )
  }

  deleteUserInitiativeFilter = ({ query }: $TSFixMe) => {
    const { teamId, filterId } = query

    return this.call(
      { pathname: `/teams/${teamId}/value-capture/v2/user-initiative-filter/${filterId}` },
      { method: 'DELETE' },
    )
  }

  getInitiativesMetadata = ({ query, body }: $TSFixMe) => {
    const { teamId } = query

    return this.call(
      { pathname: `teams/${teamId}/value-capture/v2/initiatives-v2/initiative-list-metadata` },
      { method: 'POST', body },
    )
  }

  getInitiative = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/value-capture/v2/initiatives-v2/${query.initiativeId}?synergyType=${query.synergyType}`,
    })
  }

  createInitiative = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/value-capture/v2/initiatives-v2?synergyType=${query.synergyType}`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateInitiative = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/value-capture/v2/initiatives-v2/${query.initiativeId}`,
      },
      {
        method: 'PUT',
        body,
      },
    )
  }

  deleteInitiative = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/value-capture/v2/initiatives-v2/${query.initiativeId}?synergyType=${query.synergyType}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  // Stage gate
  updateStageGate = ({ query, body }: $TSFixMe) => {
    const { synergyType, initiativeId, isManual } = query
    const queryParams = { synergyType } as { synergyType: $TSFixMe; isManual?: boolean }

    if (isManual) queryParams.isManual = isManual

    return this.call(
      {
        pathname: `/teams/${query.teamId}/value-capture/v2/initiatives-v2/${initiativeId}/stage-gate`,
        query: queryParams,
      },
      {
        method: 'PUT',
        body,
      },
    )
  }

  getStageGateValidationStatus = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${query.teamId}/value-capture/v2/initiatives-v2/${query.initiativeId}/stage-gate-status`,
    })
  }

  getInitiativeCommentsList = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${query.teamId}/value-capture/v2/initiatives/${query.initiativeId}/action-comments`,
      query: { offset: query.offset },
    })
  }

  createInitiativeComment = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/value-capture/v2/initiatives/${query.initiativeId}/action-comments`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  getFinancialsData = ({ query }: $TSFixMe) => {
    const { teamId, id, synergyType, subtype1, subtype2, subtype3 } = query
    const params = `synergyType=${synergyType}&subtype1=${subtype1}&subtype2=${subtype2}&subtype3=${subtype3}`

    return this.call({
      pathname: `teams/${teamId}/value-capture/v2/initiatives/${id}/financials?${params}`,
    })
  }

  updateFinancialsData = ({ query, body }: $TSFixMe) => {
    const { teamId, id } = query

    return this.call(
      {
        pathname: `teams/${teamId}/value-capture/v2/initiatives/${id}/financials/bulk`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  populateInitiative = ({ query }: $TSFixMe) => {
    const { teamId, id, synergyType, subtype1, subtype2, subtype3 } = query
    const params = `synergyType=${synergyType}&subtype1=${subtype1}&subtype2=${subtype2}&subtype3=${subtype3}`

    return this.call(
      {
        pathname: `/teams/${teamId}/value-capture/v2/initiatives/${id}/financials?${params}`,
      },
      {
        method: 'POST',
      },
    )
  }

  uploadInitiativeAttachments = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/value-capture/v2/initiatives-v2/${query.initiativeId}/attachments`,
      },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  deleteInitiativeAttachment = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/value-capture/v2/initiatives-v2/${query.initiativeId}/attachments/${query.fileId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  getValueCaptureStatusV2 = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/value-capture/v2/status`,
    })
  }

  updateValueCaptureStatusV2 = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/value-capture/v2/status`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  getInitiativesCategories = ({ query: { teamId, synergyTypes } }: $TSFixMe) => {
    const query = {} as { synergyTypes: $TSFixMe }

    if (synergyTypes) {
      query.synergyTypes = JSON.stringify(synergyTypes)
    }

    return this.call({
      pathname: `teams/${teamId}/synergy-category/used`,
      query,
    })
  }

  getMonthByMonthStatus = ({ query: { teamId, ...otherQuery } }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${teamId}/value-capture/v2/monthly-pipeline`,
      query: otherQuery,
    })
  }

  getMonthByMonthActualsV2 = ({ query: { teamId, ...otherQuery } }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${teamId}/value-capture/v2/monthly-pipeline/actuals`,
      query: otherQuery,
    })
  }

  getMonthByMonthPipelinesV2 = ({ query }: $TSFixMe) => {
    const { teamId, startDate, endDate, synergyType, frequency, subtype, teamIds, categoryId } = query

    return this.call({
      pathname: `teams/${teamId}/value-capture/v2/monthly-pipeline/pipelines`,
      query: {
        startDate,
        endDate,
        synergyType,
        frequency,
        subtype,
        categoryId,
        ...(teamIds ? { teamIds } : {}),
      },
    })
  }

  getHeadcountReductionData = ({ query: { teamId, ...otherQuery } }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${teamId}/value-capture/v2/headcount-reduction`,
      query: otherQuery,
    })
  }

  getOneTimeImplementationCostsData = ({ query: { teamId, ...otherQuery } }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${teamId}/value-capture/v2/one-time-implementation-cost`,
      query: otherQuery,
    })
  }

  getDetailedFinancialsData = ({ query: { teamId, initiativeId } }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${teamId}/value-capture/v2/initiatives/${initiativeId}/detailed-financial-data`,
    })
  }

  createDetailedFinancials = ({ query: { initiativeId, teamId }, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${teamId}/value-capture/v2/initiatives/${initiativeId}/detailed-financial-data/item`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateDetailedFinancialsNameOrCategory = ({ query: { initiativeId, teamId, id }, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${teamId}/value-capture/v2/initiatives/${initiativeId}/detailed-financial-data/item/${id}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteDetailedFinancials = ({ query: { initiativeId, teamId, id } }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${teamId}/value-capture/v2/initiatives/${initiativeId}/detailed-financial-data/item/${id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  updateFinancialData = ({ query: { initiativeId, teamId }, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${teamId}/value-capture/v2/initiatives/${initiativeId}/detailed-financial-data/monthly-values`,
      },
      {
        method: 'PUT',
        body,
      },
    )
  }

  updateRunRate = ({ query: { initiativeId, teamId, ...otherQuery }, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${teamId}/value-capture/v2/initiatives/${initiativeId}/detailed-financial-data/run-rate`,
        query: otherQuery,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  updateRunRateImpact = ({ query: { initiativeId, teamId }, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${teamId}/value-capture/v2/initiatives/${initiativeId}/detailed-financial-data/item-additional`,
      },
      {
        method: 'PUT',
        body,
      },
    )
  }

  updateMirrorTeam = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/value-capture/v2/initiatives-v2/${query.initiativeId}/mirror`,
      },
      { method: 'PUT', body },
    )
  }

  subLinesForMirroring = ({ query: { parentInitiativeId, teamId } }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${teamId}/value-capture/v2/initiatives-v2/${parentInitiativeId}/mirror-info`,
    })
  }

  batchFileUpload = ({ query, body }: $TSFixMe) => {
    const { teamId, synergyType } = query

    return this.call(
      {
        pathname: `/teams/${teamId}/value-capture/v2/initiatives-v2/upload?synergyType=${synergyType}`,
      },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  fetchDeltaTrackerData = ({ query, body }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${body.teamId}/value-capture/v2/monthly-pipeline/deltas`,
      query,
    })
  }

  fetchDeltaTrackerV2Data = ({ query, body }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${body.teamId}/value-capture/v2/monthly-pipeline/monthly-variance`,
      query,
    })
  }
}

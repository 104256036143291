import { put, takeEvery } from 'redux-saga/effects'
import {
  deleteDayOneProcessItemFileSuccess,
  deleteProjectFile,
  deleteTaskFile,
  uploadDayOneProcessItemFileSuccess,
  uploadProjectFile,
  uploadTaskFile,
} from '@dayOne/actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'
import { isProject } from '@shared/DayOne/utils'
import * as constants from '@dayOne/actions/actionTypes'

export const uploadDayOneProcessItemFile = createSaga(function* uploadDayOneProcessItemFile(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { rowNodeId, type, withInterdependency } = action.payload

  const onSuccess = function* (file: $TSFixMe) {
    yield put(
      uploadDayOneProcessItemFileSuccess({
        rowNodeId,
        file,
      }),
    )

    if (withInterdependency)
      yield put(
        uploadDayOneProcessItemFileSuccess({
          rowNodeId: withInterdependency,
          file,
        }),
      )
  }

  const payload = { ...action.payload, onSuccess }

  if (isProject({ type })) {
    return yield put(uploadProjectFile(payload))
  }

  return yield put(uploadTaskFile(payload))
},
true)

export const deleteDayOneProcessItemFile = createSaga(function* deleteDayOneProcessItemFile(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { rowNodeId, fileId, type, withInterdependency } = action.payload

  const onSuccess = function* () {
    yield put(deleteDayOneProcessItemFileSuccess({ rowNodeId, fileId }))

    if (withInterdependency) yield put(deleteDayOneProcessItemFileSuccess({ rowNodeId: withInterdependency, fileId }))
  }

  const payload = { ...action.payload, onSuccess }

  if (isProject({ type })) {
    return yield put(deleteProjectFile(payload))
  }

  return yield put(deleteTaskFile(payload))
},
true)

export default [
  takeEvery(constants.UPLOAD_DAY_ONE_PROCESS_ITEM_FILE, uploadDayOneProcessItemFile),
  takeEvery(constants.DELETE_DAY_ONE_PROCESS_ITEM_FILE, deleteDayOneProcessItemFile),
]

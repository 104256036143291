import { initialState, NotificationsState } from '../reducers/reducer'
import { getOr, sortBy } from 'lodash/fp'
import { createSelector } from 'reselect'
import { formatComplexKeyProcessDeleteDetails, formatKeyProcessDeleteDetails } from './utils'
import { isDataLoadingState } from '@helpers/utils'

export const clientState = getOr(initialState, ['client', 'myTeam'])
export const getTeams = (state: $TSFixMe) => clientState(state).teams
export const getProjectPlanList = (state: $TSFixMe) => clientState(state).projectPlanList || []
export const getNotifications = (state: $TSFixMe): NotificationsState => clientState(state).notifications
export const getIsLockedProjectPlanList = (state: $TSFixMe) => clientState(state).isLockedProjectPlanList
export const getKeyProcessDeleteDetails = (state: $TSFixMe) => clientState(state).keyProcessDeleteDetails
export const getFormattedKeyProcessDeleteDetails = createSelector(
  getKeyProcessDeleteDetails,
  formatKeyProcessDeleteDetails,
)
export const getFormattedComplexKeyProcessDeleteDetails = createSelector(
  getKeyProcessDeleteDetails,
  formatComplexKeyProcessDeleteDetails,
)
export const keyProcessDeleteDetailsState = (state: $TSFixMe) => clientState(state).keyProcessDeleteDetailsState
export const getKeyProcessDeleteDetailsState = createSelector(keyProcessDeleteDetailsState, isDataLoadingState)
export const getTeamWeekStatus = (state: $TSFixMe) => clientState(state).teamStatus.currWeekStatus
export const getIsUpdated = (state: $TSFixMe) => clientState(state).teamStatus.updated
export const getTeamStatusCreatedDate = (state: $TSFixMe) => clientState(state).teamStatus.createdDate
export const getTeamLastWeekStatus = (state: $TSFixMe) => clientState(state).teamStatus.lastWeekStatus || ''
export const getTeamStatusUpdatedAt = (state: $TSFixMe) => clientState(state).teamStatus.updatedAt
export const getStatusRationale = (state: $TSFixMe) => clientState(state).teamStatus.rationale || ''
export const getEscalations = (state: $TSFixMe) => clientState(state).teamStatus.escalations || ''
export const getDeliverableTypesStatuses = createSelector(clientState, ({ deliverableTypesStatuses }) =>
  sortBy('order', deliverableTypesStatuses),
)
export const getTeamProjectsStatuses = (state: $TSFixMe) => clientState(state).teamProjectsStatuses
export const getKeyIssues = (state: $TSFixMe) => clientState(state).teamStatus.keyIssues
export const getSortedKeyIssues = createSelector(getKeyIssues, sortBy('id'))
export const getOtherTeamUpdates = createSelector(clientState, getOr([], 'otherTeamUpdates'))
export const getFlaggedRisksAndDecisions = createSelector(clientState, getOr([], 'flaggedRisksAndDecisions'))

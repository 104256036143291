import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'
import { assoc } from 'lodash/fp'

export interface IValueCaptureState {
  lateOrAtRiskData: $TSFixMe[]
  metricDetails: $TSFixMe[]
}

export const initialState = {
  lateOrAtRiskData: [],
  metricDetails: [],
}

const valueCaptureReducer = handleActions<IValueCaptureState, $TSFixMe>(
  {
    [constants.SET_LATE_OR_AT_RISK_DATA]: (state, action) => {
      return assoc('lateOrAtRiskData', action.payload, state)
    },

    [constants.SET_METRIC_DETAILS]: (state, action) => {
      return assoc('metricDetails', action.payload, state)
    },
  },
  initialState,
)

export default valueCaptureReducer

import * as Yup from 'yup'

export const teamSetupSchema = Yup.object().shape({
  integrationTeams: Yup.array().of(
    Yup.object().shape({
      longName: Yup.string().required(),
      shortName: Yup.string().required(),
      taxonomyFunctionId: Yup.number().nullable().required(),
      taxonomyGeographyId: Yup.number().nullable().required(),
      taxonomyBusinessUnitId: Yup.number().nullable().required(),
      libraryId: Yup.string().nullable(),
      teamType: Yup.string().required(),
    }),
  ),
  imoTeams: Yup.array().of(
    Yup.object().shape({
      longName: Yup.string().required(),
      shortName: Yup.string().required(),
      teamType: Yup.string().required(),
    }),
  ),
  centralTeams: Yup.array().of(
    Yup.object().shape({
      longName: Yup.string().required(),
      shortName: Yup.string().required(),
      libraryId: Yup.string().nullable(),
      teamType: Yup.string().required(),
    }),
  ),
})

import HttpClient from '../HttpClient'
import Config from '@root/src/config'
import { isBoolean } from 'lodash'

type ControlPanelQueryParams = {
  includeConfidential?: boolean
  priority: $TSFixMe
  level?: $TSFixMe
  metricType?: $TSFixMe
  targetTeamId?: number
}

export default class ImoControlTowerApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch, '')

    this.host = Config.API_SERVER
  }

  getTeamWeeklyStatuses = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${query.teamId}/weekly-status-summary-page`,
    })
  }

  // Control panel
  getControlPanelData = ({ query }: $TSFixMe) => {
    const { teamId, priority, includeConfidential } = query
    const queryParams = {} as ControlPanelQueryParams

    if (isBoolean(includeConfidential)) queryParams.includeConfidential = includeConfidential

    if (priority) queryParams.priority = priority

    return this.call({
      pathname: `/teams/${teamId}/master-planner-control-panel`,
      query: queryParams,
    })
  }

  getControlPanelProjectData = ({ query }: $TSFixMe) => {
    const { teamId, priority, includeConfidential } = query
    const queryParams = {} as ControlPanelQueryParams

    if (isBoolean(includeConfidential)) queryParams.includeConfidential = includeConfidential

    if (priority) queryParams.priority = priority

    return this.call({
      pathname: `/teams/${teamId}/master-planner-control-panel/count-projects`,
      query: queryParams,
    })
  }

  getMetricData = ({ query }: $TSFixMe) => {
    const { teamId, metric, priority, includeConfidential, selectedView, targetTeamId } = query
    const queryParams = { level: selectedView } as ControlPanelQueryParams

    if (isBoolean(includeConfidential)) queryParams.includeConfidential = includeConfidential

    if (priority) queryParams.priority = priority

    if (metric) queryParams.metricType = metric

    if (targetTeamId) queryParams.targetTeamId = targetTeamId

    return this.call({
      pathname: `/teams/${teamId}/master-planner-control-panel/metric-state`,
      query: queryParams,
    })
  }

  updateTaskMetricData = ({ query, body }: $TSFixMe) => {
    const { teamId, taskId } = query

    return this.call(
      {
        pathname: `/teams/${teamId}/master-planner-control-panel/metric-state/${taskId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  //SteerCo data
  getSteerCoActionsData = ({ query }: $TSFixMe) => {
    const { teamId } = query

    return this.call({
      pathname: `/teams/${teamId}/steer-co-roadmap`,
    })
  }

  updateSteerCoActionsData = ({ query, body }: $TSFixMe) => {
    const { teamId, rowId, columnId } = query

    return this.call(
      {
        pathname: `/teams/${teamId}/steer-co-roadmap/row/${rowId}/column/${columnId}`,
      },
      {
        method: 'PUT',
        body,
      },
    )
  }

  getParkingLotData = ({ query }: $TSFixMe) => {
    const { teamId } = query

    return this.call({
      pathname: `/teams/${teamId}/steer-co-roadmap/parking-lot`,
    })
  }

  updateParkingLotData = ({ query, body }: $TSFixMe) => {
    const { teamId } = query
    const { value } = body

    return this.call(
      {
        pathname: `/teams/${teamId}/steer-co-roadmap/parking-lot`,
      },
      {
        method: 'PUT',
        body: value,
      },
    )
  }

  getColumnsData = ({ query }: $TSFixMe) => {
    const { teamId } = query

    return this.call({ pathname: `/teams/${teamId}/steer-co-roadmap/columns` })
  }

  updateColumnsData = ({ body, query }: $TSFixMe) => {
    const { teamId } = query

    return this.call(
      {
        pathname: `/teams/${teamId}/steer-co-roadmap/columns/bulk`,
      },
      {
        method: 'PUT',
        body: body,
      },
    )
  }

  deleteColumnsData = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/steer-co-roadmap/columns/bulk/[${query.columnIds.join(',')}]`,
      },
      {
        method: 'DELETE',
      },
    )
  }
}

import styled from 'styled-components'

export const Container = styled.div`
  margin: 0 10px;
  display: flex;
  align-items: center;
  & > * {
    margin: 0 5px;
  }
  & > svg {
    margin: 0 1px;
  }
`

export const IconText = styled.span`
  color: ${({ color }) => color};
  font-size: 12px;
  line-height: 16px;
  margin-left: 2px;
`

import { put, call } from 'redux-saga/effects'
import { setTeamStatusTrackerData } from '@teamStatusTracker/actions/actions'
import { getImoControlTowerApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchTeamStatusTrackerData = createSaga(function* fetchTeamStatusTrackerData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = action.payload

  const api = yield getImoControlTowerApi()
  const data = yield call(
    api.request('getTeamWeeklyStatuses', {
      query: { teamId },
    }),
  )

  yield put(setTeamStatusTrackerData(data))
},
true)

import { call, put } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import {
  setTaskWatchers,
  addTaskWatcher,
  removeTaskWatcher,
  watchTask as watchTaskActionCreator,
  unwatchTask as unwatchTaskActionCreator,
  fetchTaskWatchers as fetchTaskWatchersActionCreator,
  watchInterdependentTask as watchInterdependentTaskActionCreator,
  unwatchInterdependentTask as unwatchInterdependentTaskActionCreator,
  fetchInterdependentTaskWatchers as fetchInterdependentTaskWatchersActionCreator,
} from '../actions/actions'
import { updateNotificationWatchStatus } from '@teamHome/actions/actions'
import { getInterdependentTaskWatchersApi, getTaskWatchersApi } from '@common/net'

export const watchTask = createSaga(function* watchTask(
  action: ReturnType<typeof watchTaskActionCreator>,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, taskId, projectId, keyProcessId, entityType } = action.payload

  const taskWatchersApi = yield getTaskWatchersApi()

  const data = yield call(taskWatchersApi.request('watchTask', { query: { teamId, taskId, projectId, keyProcessId } }))

  const teams = data.user.userTeamRoles.map((teamRole: $TSFixMe) => ({
    role: teamRole.role.name,
    teamName: teamRole.team.longName,
    teamType: teamRole.team.teamType.name,
  }))

  const watcher = {
    name: `${data.user.name} ${data.user.surname}`,
    teams,
    userId: data.user.id,
  }

  yield put(addTaskWatcher({ taskId, watcher, teamId, projectId }))

  yield put(
    updateNotificationWatchStatus({
      entityId: taskId,
      entityType: entityType,
      isWatching: true,
    }),
  )
},
false)

export const unwatchTask = createSaga(function* unwatchTask(
  action: ReturnType<typeof unwatchTaskActionCreator>,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, taskId, userId, projectId, keyProcessId, entityType } = action.payload

  const taskWatchersApi = yield getTaskWatchersApi()

  yield call(taskWatchersApi.request('unwatchTask', { query: { teamId, taskId, projectId, keyProcessId } }))

  yield put(removeTaskWatcher({ taskId, userId }))

  yield put(
    updateNotificationWatchStatus({
      entityId: taskId,
      entityType: entityType,
      isWatching: false,
    }),
  )
},
false)

export const fetchTaskWatchers = createSaga(function* fetchTaskWatchers(
  action: ReturnType<typeof fetchTaskWatchersActionCreator>,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, keyProcessId, projectId, taskId } = action.payload

  const taskWatchersApi = yield getTaskWatchersApi()

  const data = yield call(
    taskWatchersApi.request('fetchTaskWatchers', {
      query: { teamId, keyProcessId, projectId, taskId },
    }),
  )

  const watchers = data.map(({ user }: $TSFixMe) => {
    const name = `${user.name} ${user.surname}`

    const teams = user.userTeamRoles.map((teamRole: $TSFixMe) => ({
      role: teamRole.role.name,
      teamName: teamRole.team.longName,
      teamType: teamRole.team.teamType.name,
    }))

    return {
      userId: user.id,
      name,
      teams,
    }
  })

  yield put(setTaskWatchers({ taskId, watchers }))
},
false)

export const watchInterdependentTask = createSaga(function* watchInterdependentTask(
  action: ReturnType<typeof watchInterdependentTaskActionCreator>,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, taskId, projectId, interdependencyId, entityType } = action.payload

  const taskWatchersApi = yield getInterdependentTaskWatchersApi()

  const data = yield call(
    taskWatchersApi.request('watchInterdependentTask', { query: { teamId, taskId, interdependencyId } }),
  )

  const teams = data.user.userTeamRoles.map((teamRole: $TSFixMe) => ({
    role: teamRole.role.name,
    teamName: teamRole.team.longName,
    teamType: teamRole.team.teamType.name,
  }))

  const watcher = {
    name: `${data.user.name} ${data.user.surname}`,
    teams,
    userId: data.user.id,
  }

  yield put(addTaskWatcher({ taskId, watcher, teamId, projectId }))

  yield put(
    updateNotificationWatchStatus({
      taskId,
      entityType,
      isWatching: true,
    }),
  )
},
false)

export const unwatchInterdependentTask = createSaga(function* unwatchInterdependentTask(
  action: ReturnType<typeof unwatchInterdependentTaskActionCreator>,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, taskId, userId, projectId, interdependencyId, entityType } = action.payload

  const taskWatchersApi = yield getInterdependentTaskWatchersApi()

  yield call(
    taskWatchersApi.request('unwatchInterdependentTask', { query: { teamId, taskId, projectId, interdependencyId } }),
  )

  yield put(removeTaskWatcher({ taskId, userId }))

  yield put(
    updateNotificationWatchStatus({
      taskId,
      entityType,
      isWatching: false,
    }),
  )
},
false)

export const fetchInterdependentTaskWatchers = createSaga(function* fetchInterdependentTaskWatchers(
  action: ReturnType<typeof fetchInterdependentTaskWatchersActionCreator>,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, projectId, taskId, interdependencyId } = action.payload

  const taskWatchersApi = yield getInterdependentTaskWatchersApi()

  const data = yield call(
    taskWatchersApi.request('fetchInterdependentTaskWatchers', {
      query: { teamId, projectId, taskId, interdependencyId },
    }),
  )

  const watchers = data.map(({ user }: $TSFixMe) => {
    const name = `${user.name} ${user.surname}`

    const teams = user.userTeamRoles.map((teamRole: $TSFixMe) => ({
      role: teamRole.role.name,
      teamName: teamRole.team.longName,
      teamType: teamRole.team.teamType.name,
    }))

    return {
      userId: user.id,
      name,
      teams,
    }
  })

  yield put(setTaskWatchers({ taskId, watchers }))
},
false)

import { handleActions } from 'redux-actions'
import * as constants from '@functionalProcessDesign/actions/actionTypes'

export interface IFunctionalProcessDesignState {
  functionalProcessDesigns: $TSFixMe[]
  locked: boolean
}

export const initialState = {
  functionalProcessDesigns: [],
  locked: false,
}

const functionalProcessDesignReducer = handleActions<IFunctionalProcessDesignState, $TSFixMe>(
  {
    [constants.SET_FUNC_DESIGN_PROCESSES]: (state, action) => {
      const { functionalProcessDesigns, locked } = action.payload

      return {
        ...state,
        functionalProcessDesigns,
        locked,
      }
    },

    [constants.CREATE_FUNC_DESIGN_PROCESS_SUCCESS]: (state, action) => {
      const { data } = action.payload
      const { functionalProcessDesigns, locked } = state

      return {
        ...state,
        functionalProcessDesigns: [...functionalProcessDesigns, data],
        locked,
      }
    },

    [constants.UPDATE_FUNC_DESIGN_PROCESS_SUCCESS]: (state, action) => {
      const { id, data } = action.payload
      const { functionalProcessDesigns, locked } = state

      const updatedKeyProcesses = functionalProcessDesigns.map((keyProcess) =>
        keyProcess.id === id ? { ...keyProcess, ...data } : keyProcess,
      )

      return {
        ...state,
        functionalProcessDesigns: updatedKeyProcesses,
        locked,
      }
    },

    [constants.DELETE_FUNC_DESIGN_PROCESS_SUCCESS]: (state, action) => {
      const { id } = action.payload
      const { functionalProcessDesigns, locked } = state

      const filteredKeyProcesses = functionalProcessDesigns.filter((keyProcess) => keyProcess.id !== id)

      return {
        ...state,
        functionalProcessDesigns: filteredKeyProcesses,
        locked,
      }
    },
  },
  initialState,
)

export default functionalProcessDesignReducer

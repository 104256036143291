import { Socket } from 'socket.io-client'
import {
  ActiveCellCoords,
  ActiveUserSocketData,
  ClientToServerEvents,
  Rooms,
  ServerToClientEvents,
  DataUpdateTypes,
  DataUpdatedPayload,
} from '../types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { wsDataCreated, wsDataDeleted, wsDataRefetch, wsDataUpdated } from '../actions/actions'
import { getUserId } from '../../generic/selectors'
import {
  addProcess,
  deleteProcessSuccess,
  refetchMutualDiscoveryData,
  updateProcessSuccess,
} from '@mutualDiscovery/actions/actions'
import { UPDATE_PROCESS_LIST_IDS, UPDATE_PROJECT_LIST_IDS } from '@dayOne/actions/actionTypes'
import {
  createInScopeKeyProcessSuccess,
  deleteInScopeKeyProcessSuccess,
  setUpdateInScopeKeyProcess,
  fetchTeamCharterData,
  setUpdateOutScopeKeyProcess,
  addOutScopeKeyProcess,
  removeOutScopeKeyProcess,
  addKeyInterdependency,
  setUpdateKeyInterdependency,
  removeKeyInterdependency,
  setUpdateKeyRiskToMitigate,
  addKeyRiskToMitigate,
  removeKeyRiskToMitigate,
  setUpdateKeyDecisionToMake,
  addKeyDecisionToMake,
  removeKeyDecisionToMake,
  updateTeamMember,
  addTeamMember,
  removeTeamMember,
  setUpdateMacroPlanningAssumption,
  addMacroPlanningAssumption,
  removeMacroPlanningAssumption,
} from '@teamCharter/actions/actions'
import { updateDeliverableMetaSuccess } from '@teamDeliverables/actions/actions'
import {
  updateRiskLogDataSuccess,
  deleteRiskLogDataSuccess,
  addRiskLogDataSuccess,
} from '@domain/myImoClient/myTeam/riskLog/actions'
import { prepareUpdatedData } from '@domain/myImoClient/myTeam/decisionLog/utils'
import {
  addDecisionLogDataSuccess,
  deleteDecisionLogDataSuccess,
  updateDecisionLogDataSuccess,
} from '@domain/myImoClient/myTeam/decisionLog/actions'

export const useActiveUsersEvents = (
  updateActiveUsers: ({ data }: { data: ActiveUserSocketData[] }) => void,
  currentRoom: Rooms | null,
  socket?: Socket,
) => {
  const userId = useSelector(getUserId)
  const dispatch = useDispatch()

  const updateDayOneProjectList = ({
    teamId,
    type,
    payload,
  }: {
    teamId: number
    type: DataUpdateTypes
    payload: DataUpdatedPayload
  }) => {
    switch (type) {
      case DataUpdateTypes.KeyProcessCreated:
      case DataUpdateTypes.ProjectCreated:
      case DataUpdateTypes.TaskCreated:
        dispatch(wsDataCreated(payload))
        break
      case DataUpdateTypes.KeyProcessUpdated:
      case DataUpdateTypes.ProjectUpdated:
      case DataUpdateTypes.TaskUpdated:
      case DataUpdateTypes.AttachmentUpdated:
        dispatch(wsDataUpdated(payload))
        break
      case DataUpdateTypes.KeyProcessDeleted:
      case DataUpdateTypes.ProjectDeleted:
      case DataUpdateTypes.TaskDeleted:
        dispatch(wsDataDeleted(payload))
        break
      case DataUpdateTypes.ProjectListIdUpdated:
        dispatch({ type: UPDATE_PROJECT_LIST_IDS, payload: { updates: payload } })
        break
      case DataUpdateTypes.ProjectListUpdated:
      case DataUpdateTypes.ProjectListBulkCreate:
      case DataUpdateTypes.KeyProcessBulkCreate:
      case DataUpdateTypes.KeyProcessBulkDelete:
      case DataUpdateTypes.MDPAKeyProcessUpdated:
      case DataUpdateTypes.TeamCharterBulkUpdate:
        dispatch(wsDataRefetch({ teamId }))
        break
      case DataUpdateTypes.ProcessListIdUpdated:
        dispatch({
          type: UPDATE_PROCESS_LIST_IDS,
          payload: { updates: payload },
        })
        break
      default:
        break
    }
  }

  const updateMDPAList = ({
    teamId,
    type,
    payload,
  }: {
    teamId: number
    type: DataUpdateTypes
    payload: DataUpdatedPayload
  }) => {
    switch (type) {
      case DataUpdateTypes.SubKeyProcessCreated:
        dispatch(addProcess({ data: payload }))
        break
      case DataUpdateTypes.SubKeyProcessDeleted:
        dispatch(deleteProcessSuccess(payload))
        break
      case DataUpdateTypes.SubKeyProcessUpdated:
      case DataUpdateTypes.MDPAKeyProcessUpdated:
        if ('id' in payload) {
          if ('keyProcessId' in payload) {
            dispatch(updateProcessSuccess({ id: payload.id, keyProcessId: payload.keyProcessId, data: payload }))
          } else {
            dispatch(updateProcessSuccess({ id: payload.id, data: payload }))
          }
        }
        break
      case DataUpdateTypes.KeyProcessCreated:
      case DataUpdateTypes.KeyProcessDeleted:
      case DataUpdateTypes.KeyProcessUpdated:
      case DataUpdateTypes.KeyProcessBulkCreate:
      case DataUpdateTypes.KeyProcessBulkDelete:
      case DataUpdateTypes.ProjectListBulkCreate:
      case DataUpdateTypes.TeamCharterBulkUpdate:
        dispatch(refetchMutualDiscoveryData({ teamId }))
        break
      default:
        break
    }
  }

  const updateTeamCharterList = ({
    teamId,
    type,
    payload,
  }: {
    teamId: number
    type: DataUpdateTypes
    payload: DataUpdatedPayload
  }) => {
    switch (type) {
      case DataUpdateTypes.TeamCharterKeyProcessUpdated:
      case DataUpdateTypes.MDPAKeyProcessUpdated:
      case DataUpdateTypes.KeyProcessUpdated:
        if ('id' in payload) {
          dispatch(setUpdateInScopeKeyProcess({ id: payload.id, data: payload }))
        }
        break
      case DataUpdateTypes.TeamCharterKeyProcessCreated:
      case DataUpdateTypes.KeyProcessCreated:
        dispatch(createInScopeKeyProcessSuccess(payload))
        break
      case DataUpdateTypes.TeamCharterKeyProcessDeleted:
      case DataUpdateTypes.KeyProcessDeleted:
        if ('id' in payload) {
          dispatch(deleteInScopeKeyProcessSuccess({ id: payload.id }))
        }
        break
      case DataUpdateTypes.TeamCharterBulkUpdate:
      case DataUpdateTypes.KeyProcessBulkCreate:
      case DataUpdateTypes.KeyProcessBulkDelete:
      case DataUpdateTypes.ProcessListIdUpdated:
        dispatch(fetchTeamCharterData({ teamId: teamId }))
        break
      case DataUpdateTypes.TeamCharterKeyProcessOutScopeUpdated:
        if ('keyProcessId' in payload) {
          dispatch(setUpdateOutScopeKeyProcess({ keyProcessId: payload.keyProcessId, data: payload }))
        }
        break
      case DataUpdateTypes.TeamCharterKeyProcessOutScopeCreated:
        dispatch(addOutScopeKeyProcess(payload))
        break
      case DataUpdateTypes.TeamCharterKeyProcessOutScopeDeleted:
        if ('keyProcessId' in payload) {
          dispatch(removeOutScopeKeyProcess({ keyProcessId: payload.keyProcessId }))
        }
        break
      case DataUpdateTypes.TeamCharterInterdependencyUpdated:
        if ('id' in payload) {
          dispatch(setUpdateKeyInterdependency({ data: payload, id: payload.id }))
        }
        break
      case DataUpdateTypes.TeamCharterInterdependencyCreated:
        dispatch(addKeyInterdependency(payload))
        break
      case DataUpdateTypes.TeamCharterInterdependencyDeleted:
        if ('id' in payload) {
          dispatch(removeKeyInterdependency({ id: payload.id }))
        }
        break
      case DataUpdateTypes.TeamCharterKeyRiskUpdated:
        if ('id' in payload) {
          dispatch(setUpdateKeyRiskToMitigate({ data: payload, id: payload.id }))
        }
        break
      case DataUpdateTypes.TeamCharterKeyRiskCreated:
        dispatch(addKeyRiskToMitigate(payload))
        break
      case DataUpdateTypes.TeamCharterKeyRiskDeleted:
        if ('id' in payload) {
          dispatch(removeKeyRiskToMitigate({ id: payload.id }))
        }
        break
      case DataUpdateTypes.TeamCharterKeyDecisionUpdated:
        if ('id' in payload) {
          dispatch(setUpdateKeyDecisionToMake({ data: payload, id: payload.id }))
        }
        break
      case DataUpdateTypes.TeamCharterKeyDecisionCreated:
        dispatch(addKeyDecisionToMake(payload))
        break
      case DataUpdateTypes.TeamCharterKeyDecisionDeleted:
        if ('id' in payload) {
          dispatch(removeKeyDecisionToMake({ id: payload.id }))
        }
        break
      case DataUpdateTypes.TeamCharterTeamMemberUpdated:
        if ('id' in payload) {
          dispatch(updateTeamMember({ data: payload, id: payload.id }))
        }
        break
      case DataUpdateTypes.TeamCharterTeamMemberCreated:
        dispatch(addTeamMember(payload))
        break
      case DataUpdateTypes.TeamCharterTeamMemberDeleted:
        if ('id' in payload) {
          dispatch(removeTeamMember({ id: payload.id }))
        }
        break
      case DataUpdateTypes.TeamCharterMacroPlanningAssumptionUpdated:
        if ('id' in payload && 'parentId' in payload) {
          dispatch(
            setUpdateMacroPlanningAssumption({ assumptionData: payload, id: payload.id, parentId: payload.parentId }),
          )
        }
        break
      case DataUpdateTypes.TeamCharterMacroPlanningAssumptionCreated:
        dispatch(addMacroPlanningAssumption(payload))
        break
      case DataUpdateTypes.TeamCharterMacroPlanningAssumptionDeleted:
        if ('id' in payload) {
          dispatch(removeMacroPlanningAssumption({ id: payload.id }))
        }
        break
      case DataUpdateTypes.TeamCharterSectionTitleUpdated:
        dispatch(updateDeliverableMetaSuccess({ meta: payload }))
        break
      default:
        break
    }
  }

  const updateRiskLog = ({ type, payload }: { type: DataUpdateTypes; payload: DataUpdatedPayload }) => {
    switch (type) {
      case DataUpdateTypes.RiskLogUpdated:
        if ('id' in payload) {
          dispatch(updateRiskLogDataSuccess(prepareUpdatedData({ id: payload.id, body: payload })))
        }
        break
      case DataUpdateTypes.RiskLogCreated:
        if ('id' in payload) {
          dispatch(addRiskLogDataSuccess({ body: payload, sortOrder: ['status', 'dueDate'] }))
        }
        break
      case DataUpdateTypes.RiskLogDeleted:
        if ('id' in payload) {
          dispatch(deleteRiskLogDataSuccess(payload.id))
        }
        break
      default:
        break
    }
  }

  const updateDecisionLog = ({ type, payload }: { type: DataUpdateTypes; payload: DataUpdatedPayload }) => {
    switch (type) {
      case DataUpdateTypes.DecisionLogUpdated:
        if ('id' in payload) {
          dispatch(updateDecisionLogDataSuccess(prepareUpdatedData({ id: payload.id, body: payload })))
        }
        break
      case DataUpdateTypes.DecisionLogCreated:
        if ('id' in payload) {
          dispatch(addDecisionLogDataSuccess({ body: payload, sortOrder: ['status', 'dueDate'] }))
        }
        break
      case DataUpdateTypes.DecisionLogDeleted:
        if ('id' in payload) {
          dispatch(deleteDecisionLogDataSuccess(payload.id))
        }
        break
      default:
        break
    }
  }

  const updateData = ({
    teamId,
    initiatorId,
    type,
    payload,
  }: {
    teamId: number
    initiatorId: number
    type: DataUpdateTypes
    payload: DataUpdatedPayload
  }) => {
    if (userId === initiatorId) {
      return
    }

    switch (currentRoom) {
      case Rooms.DayOneProjectList:
        return updateDayOneProjectList({ teamId, type, payload })
      case Rooms.MDPA:
        return updateMDPAList({ teamId, type, payload })
      case Rooms.TeamCharter:
        return updateTeamCharterList({ teamId, type, payload })
      case Rooms.RiskLog:
        return updateRiskLog({ type, payload })
      case Rooms.DecisionLog:
        return updateDecisionLog({ type, payload })
      default:
        break
    }
  }

  const sendFocusEvent = (cellCords: ActiveCellCoords, room: Rooms) => {
    socket?.emit(ClientToServerEvents.Focus, { focus: { ...cellCords, room } })
  }

  const sendEditingStartedEvent = () => {
    socket?.emit(ClientToServerEvents.EditingStarted)
  }

  const sendEditingStoppedEvent = () => {
    socket?.emit(ClientToServerEvents.EditingStopped)
  }

  const sendBlurEvent = () => {
    socket?.emit(ClientToServerEvents.Blur)
  }

  useEffect(() => {
    if (socket && userId && currentRoom) {
      socket.on(ServerToClientEvents.FocusSuccess, updateActiveUsers)
      socket.on(ServerToClientEvents.BlurSuccess, updateActiveUsers)
      socket.on(ServerToClientEvents.EditingStartedSuccess, updateActiveUsers)
      socket.on(ServerToClientEvents.EditingStoppedSucces, updateActiveUsers)
      socket.on(ServerToClientEvents.DayOneProjectListUpdated, updateData)
      socket.on(ServerToClientEvents.MDPAUpdated, updateData)
      socket.on(ServerToClientEvents.TeamCharterUpdated, updateData)
      socket.on(ServerToClientEvents.RiskLogUpdated, updateData)
      socket.on(ServerToClientEvents.DecisionLogUpdated, updateData)
      return () => {
        socket.off(ServerToClientEvents.FocusSuccess, updateActiveUsers)
        socket.off(ServerToClientEvents.BlurSuccess, updateActiveUsers)
        socket.off(ServerToClientEvents.EditingStartedSuccess, updateActiveUsers)
        socket.off(ServerToClientEvents.EditingStoppedSucces, updateActiveUsers)
        socket.off(ServerToClientEvents.DayOneProjectListUpdated, updateData)
        socket.off(ServerToClientEvents.MDPAUpdated, updateData)
        socket.off(ServerToClientEvents.TeamCharterUpdated, updateData)
      }
    }
  }, [socket, userId, currentRoom])

  return { sendEditingStartedEvent, sendEditingStoppedEvent, sendFocusEvent, sendBlurEvent }
}

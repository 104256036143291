import { call, put } from 'redux-saga/effects'
import { setColumnsData } from '@communications/actions/actions'
import { getCommunicationsApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchColumnsData = createSaga(function* fetchColumnsData(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getCommunicationsApi()
  const columns = yield call(api.request('getColumnsData'))

  yield put(setColumnsData({ columns: columns.sort((col1: $TSFixMe, col2: $TSFixMe) => col1.orderId - col2.orderId) }))
}, true)

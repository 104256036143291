import React, { Component } from 'react'
import ErrorDialog from './ErrorDialog'
import { RenderError } from '@helpers/customErrors'
import { IServerRespondingState } from './types.d'

const initialState = {
  hasError: false,
  message: null,
  errorStack: null,
  componentStack: null,
}

interface Props {
  children: JSX.Element
  serverRespondingState: IServerRespondingState | null
}

type State = $TSFixMe

export default class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError(error: $TSFixMe) {
    return { hasError: true, message: error.message }
  }

  state = initialState

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.serverRespondingState) {
      return {
        hasError: true,
        message: `${props.serverRespondingState.message}. Server isn't responding`,
        errorStack: props.serverRespondingState.sagaStack,
        componentStack: 'Root Saga',
      }
    }

    return state
  }

  componentDidCatch(error: $TSFixMe, errorInfo: $TSFixMe) {
    this.setState({
      hasError: true,
      message: error.message,
      errorStack: error.stack,
      componentStack: errorInfo?.componentStack,
    })

    // without timer the component wil not be rendered
    setTimeout(() => {
      throw new RenderError(error)
    }, 1000)
  }

  handleErrorClose() {
    location.reload()
  }

  resetErrorState = () => this.setState(initialState)

  render() {
    const { hasError, message, errorStack, componentStack } = this.state

    if (hasError) {
      return (
        <ErrorDialog
          hasError={hasError}
          message={message}
          errorStack={errorStack}
          componentStack={componentStack}
          onClose={this.handleErrorClose}
          resetErrorState={this.resetErrorState}
        />
      )
    }

    return this.props.children
  }
}

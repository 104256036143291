import { put, call } from 'redux-saga/effects'
import * as actions from '../actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getTeamCharterApi } from '@common/net'
import { getMacroPlanningAssumptionsData } from '../helpers'

export const fetchMacroPlanningAssumption = createSaga(function* fetchMacroPlanningAssumption({
  teamId,
  date,
}: {
  teamId: string
  date: string
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamCharterApi()
  const macroPlanningAssumption = yield call(api.request('getMacroPlanningAssumption', { query: { teamId, date } }))

  const treeDataMacroPlanningAssumption = getMacroPlanningAssumptionsData(macroPlanningAssumption)

  yield put(actions.setMacroPlanningAssumption(treeDataMacroPlanningAssumption))
})

export const createMacroPlanningAssumptionData = createSaga(function* createMacroPlanningAssumptionData({
  payload: { data, teamId, parentId, initialId },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamCharterApi()
  const assumptionData = yield call(
    api.request('createMacroPlanningAssumption', { body: { ...data, teamId, parentId }, query: { teamId } }),
  )

  yield put(actions.setUpdateMacroPlanningAssumption({ assumptionData, id: initialId, parentId }))
})

export const updateMacroPlanningAssumptionData = createSaga(function* updateMacroPlanningAssumptionData({
  payload: { data, teamId, parentId, id },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamCharterApi()
  yield put(actions.setUpdateMacroPlanningAssumption({ assumptionData: data, id, parentId }))

  yield call(api.request('updateMacroPlanningAssumption', { body: { ...data, parentId }, query: { id, teamId } }))
})

export const deleteMacroPlanningAssumptionData = createSaga(function* deleteMacroPlanningAssumptionData({
  payload: { selectedRow, teamId },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamCharterApi()
  yield put(actions.removeMacroPlanningAssumption(selectedRow))

  yield call(api.request('deleteMacroPlanningAssumption', { query: { teamId }, body: { id: selectedRow.id } }))
})

const prefix = '@teamResources/'

export const FETCH_TEAM_RESOURCES_UPDATES = `${prefix}FETCH_TEAM_RESOURCES_UPDATES`
export const UPDATE_TEAM_RESOURCES_UPDATES = `${prefix}UPDATE_TEAM_RESOURCES_UPDATES`
export const SET_TEAM_RESOURCES_UPDATES = `${prefix}SET_TEAM_RESOURCES_UPDATES`

export const FETCH_MC_EVENTS = `${prefix}FETCH_MC_EVENTS`
export const SET_MC_EVENTS = `${prefix}SET_MC_EVENTS`

export const CREATE_MC_EVENT = `${prefix}CREATE_MC_EVENT`
export const CREATE_MC_EVENT_SUCCESS = `${prefix}CREATE_MC_EVENT_SUCCESS`

export const UPDATE_MC_EVENT = `${prefix}UPDATE_MC_EVENT`
export const UPDATE_MC_EVENT_SUCCESS = `${prefix}UPDATE_MC_EVENT_SUCCESS`

export const UPLOAD_MC_ATTACHMENT = `${prefix}UPLOAD_MC_ATTACHMENT`
export const DELETE_MC_ATTACHMENT = `${prefix}DELETE_MC_ATTACHMENT`

export const DELETE_MC_EVENT = `${prefix}DELETE_MC_EVENT`
export const DELETE_MC_EVENT_SUCCESS = `${prefix}DELETE_MC_EVENT_SUCCESS`

export const FETCH_MC_LABELS = `${prefix}FETCH_MC_LABELS`
export const SET_MC_LABELS = `${prefix}SET_MC_LABELS`

export const CREATE_MC_LABEL = `${prefix}CREATE_MC_LABEL`
export const CREATE_MC_LABEL_SUCCESS = `${prefix}CREATE_MC_LABEL_SUCCESS`

export const UPDATE_MC_LABEL = `${prefix}UPDATE_MC_LABEL`
export const UPDATE_MC_LABEL_SUCCESS = `${prefix}UPDATE_MC_LABEL_SUCCESS`

export const DELETE_MC_LABEL = `${prefix}DELETE_MC_LABEL`
export const DELETE_MC_LABEL_SUCCESS = `${prefix}DELETE_MC_LABEL_SUCCESS`

export const FETCH_DOCUMENT_SHARING = `${prefix}FETCH_DOCUMENT_SHARING`
export const SET_DOCUMENT_SHARING = `${prefix}SET_DOCUMENT_SHARING`
export const DOCUMENT_SHARING_CREATE_FOLDER = `${prefix}DOCUMENT_SHARING_CREATE_FOLDER`
export const DOCUMENT_SHARING_UPDATE_FOLDER = `${prefix}DOCUMENT_SHARING_UPDATE_FOLDER`
export const DOCUMENT_SHARING_CREATE_FILE = `${prefix}DOCUMENT_SHARING_CREATE_FILE`
export const DOCUMENT_SHARING_DELETE_NODES = `${prefix}DOCUMENT_SHARING_DELETE_NODES`
export const DOCUMENT_SHARING_DOWNLOAD_FILES = `${prefix}DOCUMENT_SHARING_DOWNLOAD_FILES`

import { notSelectedOption } from '@helpers/constants'
import CssTruncateTextRenderer from '@imo/imo-ui-toolkit/dist/components/Grid/CellRenderers/CssTruncatedTextRenderer'
import { TextCellEditor } from '@imo/imo-ui-toolkit'

export const defaultColDefs = {
  sortable: true,
  filter: true,
  floatingFilter: true,
  resizable: true,
  editable: true,
  enableCellChangeFlash: true,
}

export const entanglementOptions = [
  { label: 'High', value: 'high' },
  { label: 'Low', value: 'low' },
  { label: 'None', value: 'none' },
]

export const entanglementCategoryOptions = [
  { label: 'Assets', value: 'assets' },
  { label: 'Contracts', value: 'contracts' },
  { label: 'People', value: 'people' },
  { label: 'Processes', value: 'processes' },
  { label: 'Systems', value: 'systems' },
  { label: 'Other', value: 'other' },
]

export const changingDayOneOptions = [notSelectedOption, { label: 'No', value: false }, { label: 'Yes', value: true }]

export const tsaOptions = [
  { label: 'No', value: false },
  { label: 'Yes', value: true },
]

export const selectionOptions = {
  changingDayOne: changingDayOneOptions,
  entanglement: entanglementOptions,
  entanglementCategory: entanglementCategoryOptions,
  tsa: tsaOptions,
  tsaBuySide: [],
}

export const largeTextCellEditorParams = {
  cellEditor: TextCellEditor,
  cellEditorParams: {
    maxLength: 500,
  },
  cellClassRules: {
    multiline_cell: 'true',
  },
  cellRenderer: CssTruncateTextRenderer,
  minWidth: 300,
  flex: 2,
}

export const defaultSubProcessData = {
  complete: false,
  custom: true,
  owners: null,
  companyOne: null,
  companyTwo: null,
  entanglement: null,
  changingDayOne: null,
  tsa: null,
  dayOneAssumptions: null,
  postCloseAssumptions: null,
}

export const dayOneDemergerSolutionOptions = [
  { label: 'TSA', value: 'TSA' },
  { label: 'Separate', value: 'Separate' },
  { label: 'Build/outsource', value: 'Build/outsource' },
  { label: 'Not required', value: 'Not required' },
  { label: 'Not entangled', value: 'Not entangled' },
]

export const dayOneDivestitureSolutionOptions = [
  { label: 'Buyer responsible', value: 'Buyer responsible' },
  ...dayOneDemergerSolutionOptions,
]

export const PARENT_ROW_HEIGHT = 64
export const DEFAULT_ROW_HEIGHT = 50

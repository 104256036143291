import { call, put } from 'redux-saga/effects'
import { fetchKeyIssues, fetchTeamWeekStatus } from '@teamHome/actions/actions'
import { getMyTeamApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { parseOwner } from '@shared/Grid/utils/ownerCellUtils'

export const updateKeyIssue = createSaga(function* updateKeyIssue({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, keyIssue } = payload
  const { id, ...keyIssueData } = keyIssue
  const myTeamApi = yield getMyTeamApi()
  yield call(
    myTeamApi.request('updateKeyIssue', {
      query: { teamId, keyIssueId: id },
      body: { ...keyIssueData, owner: parseOwner(keyIssueData.owner) },
    }),
  )

  yield put(fetchKeyIssues(teamId))
  yield put(fetchTeamWeekStatus({ teamId }))
})

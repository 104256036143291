import styled from 'styled-components'
import MenuSelect from '@shared/MenuSelect'
import MultipleMenuSelect from '@shared/MultipleMenuSelect'

export const StyledMenuSelect = styled(MenuSelect)`
  .bp4-icon-pulse {
    margin-right: 5px;
  }

  .bp4-button {
    width: 100%;
    justify-content: flex-start;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    font-size: 12px;

    &.bp4-active,
    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }

    .bp4-button-text {
      width: 100%;
      margin-right: 0;
      white-space: nowrap;

      span {
        font-weight: normal;
      }
    }

    & > .bp4-icon-dollar {
      margin-right: 2px;
    }
  }
`
export const StyledMultipleMenuSelect = styled(MultipleMenuSelect)`
  button {
    padding: 0 10px;
  }

  .bp4-button-text {
    display: block;
    width: 100%;
    max-width: 112px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const NonOverflowWrapper = styled.div`
  display: flex;
  margin: 0 2px;
`

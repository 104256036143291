import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchTeamDeliverableData = createAction(
  constants.FETCH_TEAM_DELIVERABLE_DATA,
  null,
  getMetaWithActionLoading,
)
export const setTeamDeliverableData = createAction(constants.SET_TEAM_DELIVERABLE_DATA)
export const setDeliverableStatus = createAction(constants.SET_DELIVERABLE_STATUS)
export const setDeliverableStatusSuccess = createAction(constants.SET_DELIVERABLE_STATUS_SUCCESS)

export const setIsDeliverableLocked = createAction(constants.SET_IS_DELIVERABLE_LOCKED)

export const fetchTeamDeliverablesData = createAction(constants.FETCH_TEAM_DELIVERABLES_DATA)
export const fetchTeamDeliverablesDataWithLoader = createAction(
  constants.FETCH_TEAM_DELIVERABLES_DATA,
  null,
  getMetaWithActionLoading,
)
export const setTeamDeliverablesData = createAction(constants.SET_TEAM_DELIVERABLES_DATA)

export const fetchTeamDeliverablesTrackerData = createAction(
  constants.FETCH_DELIVERABLES_TRACKER_DATA,
  null,
  getMetaWithActionLoading,
)
export const setTeamDeliverablesTrackerData = createAction(constants.SET_DELIVERABLES_TRACKER_DATA)

export const fetchComments = createAction(constants.FETCH_COMMENTS)
export const fetchCommentsWithLoader = createAction(constants.FETCH_COMMENTS, null, getMetaWithActionLoading)
export const setComments = createAction(constants.SET_COMMENTS)
export const resetComments = createAction(constants.RESET_COMMENTS)
export const createComment = createAction(constants.CREATE_COMMENT)
export const createCommentSuccess = createAction(constants.CREATE_COMMENT_SUCCESS)

export const setDeliverableAcknowledgeData = createAction(constants.SET_DELIVERABLE_ACKNOWLEDGE_DATA)

export const updateDeliverableMeta = createAction(constants.UPDATE_DELIVERABLE_META)
export const updateDeliverableMetaSuccess = createAction(constants.UPDATE_DELIVERABLE_META_SUCCESS)

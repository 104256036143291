import { isFunction, isNil, isObject } from 'lodash'
import { get } from 'lodash/fp'
import { stringComparator } from '@helpers/utilsComparators'

import { defaultEditorOptions } from '@shared/Grid/constants'
import LookupCellEditor from '@shared/Grid/CellEditors/LookupCellEditor'

// eslint-disable-next-line react/prop-types
export const getLookupValueFormatter =
  (getLabel) =>
  ({ value }) => {
    return !isNil(getLabel(value)) ? getLabel(value) : null
  }

export const parseLookupValue = (labelAccessor) => (value) => {
  if (!value || !isObject(value)) return null

  return value.id ? { id: value.id } : { [labelAccessor]: value[labelAccessor], id: null }
}

export const getLookupComparator = (getLabel) => (item1, item2) => {
  const valueA = getLabel(item1) || ''
  const valueB = getLabel(item2) || ''

  return stringComparator(valueA, valueB)
}

export const getParamsForLookupCellEditor = (
  { withEdit, withSort, withFilter } = defaultEditorOptions,
  { labelAccessor, cellEditorParams },
) => {
  const getLabel = get(labelAccessor)
  const valueFormatter = getLookupValueFormatter(getLabel)
  const comparator = getLookupComparator(getLabel)

  const params = {
    valueFormatter: valueFormatter,
    processCellCallback: valueFormatter,
  }

  if (withEdit) {
    params.cellEditor = LookupCellEditor
    params.cellEditorParams = (props) => {
      return { labelAccessor, ...(isFunction(cellEditorParams) ? cellEditorParams(props) : cellEditorParams) }
    }
  }

  if (withSort) params.comparator = comparator

  if (withFilter) {
    params.keyCreator = valueFormatter
    params.filterParams = {
      comparator: stringComparator,
      caseSensitive: false,
      suppressAndOrCondition: true,
      debounce: 700,
    }
  }

  return params
}

import { put, call } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { setKeyProcesses, updateKeyProcessSuccess, deleteKeyProcessSuccess } from '@functionalActivity/actions/actions'
import { setIsDeliverableLocked } from '@teamDeliverables/actions/actions'
import { getFunctionalActivityMappingApi } from '@common/net'
import { omit } from 'lodash'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchKeyProcesses = createSaga(function* fetchKeyProcesses({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload

  const api = yield getFunctionalActivityMappingApi()
  const functionalActivity = yield call(
    api.request('getKeyProcesses', {
      query: {
        teamId,
      },
    }),
  )

  yield put(setKeyProcesses({ functionalActivity }))

  yield call(delay, 100)

  yield put(setIsDeliverableLocked({ isDeliverableLocked: functionalActivity.locked }))
})

export const createKeyProcess = createSaga(function* createKeyProcess({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload

  const api = yield getFunctionalActivityMappingApi()
  const { id } = yield call(
    api.request('createKeyProcess', {
      query: {
        teamId,
      },
      body: omit(data, ['id', 'isNew']),
    }),
  )

  yield put(updateKeyProcessSuccess({ id: data.id, data: { ...data, id, isNew: false } }))
},
true)

export const updateKeyProcess = createSaga(function* updateKeyProcess({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, keyProcessId, data } = payload

  for (const field in data) {
    if (data.hasOwnProperty(field) && data[field] === '') {
      data[field] = null
    }
  }

  const api = yield getFunctionalActivityMappingApi()
  yield call(
    api.request('updateKeyProcess', {
      query: {
        teamId,
        keyProcessId,
      },
      body: omit(data, ['id', 'isNew']),
    }),
  )

  yield put(updateKeyProcessSuccess({ id: keyProcessId, data }))
})

export const deleteKeyProcess = createSaga(function* deleteKeyProcess({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, keyProcessId } = payload

  const api = yield getFunctionalActivityMappingApi()
  yield call(
    api.request('deleteKeyProcess', {
      query: {
        teamId,
        keyProcessId,
      },
    }),
  )

  yield put(deleteKeyProcessSuccess({ id: keyProcessId }))
})

import { getDeliverableDisplayStatus } from '@helpers/statuses'
import { flow, sortBy, map } from 'lodash/fp'
import { mapValues } from 'lodash'
import { teams } from '@common/accessController/constants'


const teamTypesOrders = {
  [teams.INTEGRATION]: 1,
  [teams.SEPARATION]: 1,
  [teams.CENTRAL]: 2,
}
export const transformDeliverable = (deliverable: $TSFixMe) => ({
  ...deliverable,
  displayStatus: getDeliverableDisplayStatus(deliverable),
})

export const transformDeliverablesTracker = (teamsDeliverables: $TSFixMe[]) =>
  flow(
    sortBy([(team: $TSFixMe) => teamTypesOrders[team.teamType] || 0, 'longName']),
    map((team: $TSFixMe) => ({
      ...team,
      deliverableTypes: mapValues(team.deliverableTypes, (deliverables) => deliverables.map(transformDeliverable)),
    })),
  )(teamsDeliverables)

import { actionTypes } from '../../utils'
import * as constants from '@functionalProcessDesign/actions/actionTypes'

export default {
  [constants.FETCH_FUNC_DESIGN_PROCESSES]: {
    [actionTypes.FAIL]: ['We were unable to load the functional design processes'],
  },
  [constants.CREATE_FUNC_DESIGN_PROCESS]: {
    [actionTypes.FAIL]: ['We were unable to create a functional design process'],
  },
  [constants.UPDATE_FUNC_DESIGN_PROCESS]: {
    [actionTypes.FAIL]: ['We were unable to update the functional design process'],
  },
  [constants.DELETE_FUNC_DESIGN_PROCESS]: {
    [actionTypes.FAIL]: ['We were unable to delete the functional design process'],
  },
}

import { find, size } from 'lodash/fp'

export const customStyles = {
  width: '100%',
  height: '100%',
  overflowY: 'auto',
}

export const contentStyles = {
  padding: '0',
  overflowY: 'visible',
}

export const containerPopupDeleteStyles = { zIndex: '101' }

export const mutualDiscoveryDeliverableIds = {
  mutualDiscoveryPlanningAssumptions: 2,
  postClosePlanningAssumption: 6,
  entanglementsAndPlanningAssumptions: 21,
}

export const dayOneListDeliverableIds = {
  dayOneProjectList: 3,
  detailedTaskPlans: 4,
  postCloseProjectAndTask: 7,
}

export const teamCharterDeliverableIds = {
  teamCharter: 1,
}

export const planningAssumptionOrder = [
  mutualDiscoveryDeliverableIds.mutualDiscoveryPlanningAssumptions,
  mutualDiscoveryDeliverableIds.entanglementsAndPlanningAssumptions,
  mutualDiscoveryDeliverableIds.postClosePlanningAssumption,
]

export const dayOneDeliverablesOrder = [
  dayOneListDeliverableIds.dayOneProjectList,
  dayOneListDeliverableIds.detailedTaskPlans,
  dayOneListDeliverableIds.postCloseProjectAndTask,
]

export const teamCharterOrder = [teamCharterDeliverableIds.teamCharter]

export const getTitleDeliverable = (order, data) => {
  return order.reduce((prev, current) => {
    const deliverable = find({ id: current, active: true }, data)

    if (!prev && deliverable) {
      return deliverable?.name
    }

    return prev
  }, '')
}

export const hasFewDeliverablesForDeletionProcess = (dataToDelete) => {
  const { mutualDiscoveryTitle, dayOneProjectTitle, teamCharterTitle } = dataToDelete

  const count = [mutualDiscoveryTitle, dayOneProjectTitle, teamCharterTitle].reduce(
    (count, title) => (size(title) ? ++count : count),
    0,
  )

  return count > 1
}

import React from 'react'
import { AccessControl } from '../../views/shared/AccessControl/AccessControl'
import OrgDesignTrackerRoot from '../../views/myImoClient/pages/OrgDesignTracker/OrgDesignTrackerRoot'
import { Trans } from 'react-i18next'
import { RouteObject } from 'react-router-dom'

export const orgDesignTrackerRouter: RouteObject[] = [
  {
    path: '/org-design-tracker',
    handle: { crumb: { icon: 'diagram-tree', title: <Trans i18nKey="ORG_DESIGN_TRACKER.PAGES.ROOT" /> } },
    element: <AccessControl component={OrgDesignTrackerRoot} />,
    children: [
      {
        path: 'team/:teamId',
        lazy: async () => {
          const { OrgDesignTrackerLayout } = await import(
            /* webpackChunkName: "Org Design Tracker" */
            /* webpackPrefetch: true */
            '../../views/myImoClient/pages/OrgDesignTracker/OrgDesignTracker'
          )
          return { element: <OrgDesignTrackerLayout /> }
        },
        children: [
          {
            path: 'dashboard',
            lazy: async () => {
              const { Dashboard } = await import(
                /* webpackChunkName: "Org Design Tracker" */
                '../../views/myImoClient/pages/OrgDesignTracker/SummaryStatus/Dashboard/Dashboard'
              )
              return { element: <Dashboard /> }
            },
          },
          {
            path: 'summary-status',
            lazy: async () => {
              const { Overview } = await import(
                /* webpackChunkName: "Org Design Tracker" */
                '../../views/myImoClient/pages/OrgDesignTracker/SummaryStatus/Overview/Overview'
              )

              return { element: <Overview /> }
            },
            handle: {
              crumb: { title: <Trans i18nKey="ORG_DESIGN_TRACKER.PAGES.SUMMARY_STATUS" /> },
            },
          },
          {
            path: 'all-layers',
            lazy: async () => {
              const { AllLayersLayout } = await import(
                /* webpackChunkName: "Org Design Tracker" */
                '../../views/myImoClient/pages/OrgDesignTracker/AllLayers/AllLayers'
              )
              return { element: <AllLayersLayout /> }
            },
            handle: {
              crumb: { title: <Trans i18nKey="ORG_DESIGN_TRACKER.PAGES.ALL_LAYERS" /> },
            },
            children: [
              {
                path: 'design',
                lazy: async () => {
                  const { AllLayersDesign } = await import(
                    /* webpackChunkName: "Org Design Tracker" */
                    '../../views/myImoClient/pages/OrgDesignTracker/AllLayers/AllLayers'
                  )
                  return { element: <AllLayersDesign /> }
                },
              },
              {
                path: 'selection',
                lazy: async () => {
                  const { AllLayersSelection } = await import(
                    /* webpackChunkName: "Org Design Tracker" */
                    '../../views/myImoClient/pages/OrgDesignTracker/AllLayers/AllLayers'
                  )
                  return { element: <AllLayersSelection /> }
                },
                handle: {
                  crumb: { title: <Trans i18nKey="ORG_DESIGN_TRACKER.PAGES.SELECTION" /> },
                },
              },
              {
                path: 'implementation',
                lazy: async () => {
                  const { AllLayersImplementation } = await import(
                    /* webpackChunkName: "Org Design Tracker" */
                    '../../views/myImoClient/pages/OrgDesignTracker/AllLayers/AllLayers'
                  )
                  return { element: <AllLayersImplementation /> }
                },
                handle: {
                  crumb: { title: <Trans i18nKey="ORG_DESIGN_TRACKER.PAGES.IMPLEMENTATION" /> },
                },
              },
            ],
          },
          {
            path: 'timeline',
            lazy: async () => {
              const { Timeline } = await import(
                /* webpackChunkName: "Org Design Tracker" */ '../../views/myImoClient/pages/OrgDesignTracker/TimelIne/Timeline'
              )
              return { element: <Timeline /> }
            },
            handle: {
              crumb: { title: <Trans i18nKey="ORG_DESIGN_TRACKER.PAGES.TIMELINE" /> },
            },
          },
          {
            path: 'setup',
            lazy: async () => {
              const { TeamSetup } = await import(
                /* webpackChunkName: "Org Design Tracker" */ '../../views/myImoClient/pages/OrgDesignTracker/TeamSetup/TeamSetup'
              )
              return { element: <TeamSetup /> }
            },
            handle: { crumb: { title: <Trans i18nKey="ORG_DESIGN_TRACKER.PAGES.TEAM_SETUP" /> } },
          },
        ],
      },
    ],
  },
]

export const allowedXlsTypes = ['.xls', '.xlsx']

export const xlsMimeTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]

export const UPLOAD_TYPES = {
  PROJECT_PLAN: 'project_plan',
  TSA: 'tsa',
}

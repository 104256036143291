import { isArray, map } from 'lodash/fp'
import { call, put } from 'redux-saga/effects'
import { getInitiativeListV2Api } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { synergyTypes } from '@helpers/constants'
import { setMonthlyPipelineStatusV2, setMonthlyActualsStatusV2 } from '@teamValueCaptureV2/monthByMonth/actions/actions'
import { SET_MONTHLY_ACTUALS_STATUS_V2_LOADING_STATE, SET_MONTHLY_PIPELINE_STATUS_V2_LOADING_STATE } from '@teamValueCaptureV2/monthByMonth/actions/actionTypes'
import {
  GenerateSaga,
  MonthByMonthPayload,
  MonthByMonthGenerator,
  MonthlyActualsStatusesPayload,
  MonthlyPipelineStatusesPayload,
} from '../types/sagas.d'

const generateSaga = function* generateSaga({ payload, method, action }: GenerateSaga): MonthByMonthGenerator {
  const { teamId, subtype, startDate, endDate, synergyType, categoryIds, frequency, calculationType, teamIds } = payload

  const query = {
    teamId,
    startDate,
    endDate,
    synergyType,
    frequency,
    calculationType,
    ...(teamIds ? { teamIds } : {}),
  } as MonthByMonthPayload

  if (isArray(categoryIds) && categoryIds.length) {
    query.categoryId = map(JSON.stringify, categoryIds).toString()
  }

  if (synergyType === synergyTypes.REVENUE) {
    query.subtype = subtype
  }

  const api = yield getInitiativeListV2Api()
  const data = yield call(
    api.request(method, {
      query,
    }),
  )

  yield put(action(data))
}

export const fetchMonthlyPipelineStatusesV2 = createSaga(function* fetchMonthlyPipelineStatuses({
  payload,
}: MonthlyPipelineStatusesPayload) {
  yield call(generateSaga, { payload, method: 'getMonthByMonthPipelinesV2', action: setMonthlyPipelineStatusV2 })
},
SET_MONTHLY_PIPELINE_STATUS_V2_LOADING_STATE)

export const fetchMonthlyActualsStatusesV2 = createSaga(function* fetchMonthlyActualsStatuses({
  payload,
}: MonthlyActualsStatusesPayload) {
  yield call(generateSaga, { payload, method: 'getMonthByMonthActualsV2', action: setMonthlyActualsStatusV2 })
},
SET_MONTHLY_ACTUALS_STATUS_V2_LOADING_STATE)

import React from 'react'
import { RouteObject } from 'react-router-dom'
import { ImoConfigLayout } from '../ImoConfig'

export const configRouter: RouteObject[] = [
  {
    path: '/config',
    element: <ImoConfigLayout />,
    children: [
      {
        path: '',
        lazy: async () => {
          const { ConfigStepsWithAccessControl } = await import(
            /* webpackChunkName: "imo-config" */
            /* webpackPrefetch: true */
            '@views/myImoConfig/containers/ConfigSteps/ConfigSteps'
          )
          return { element: <ConfigStepsWithAccessControl /> }
        },
      },
      {
        path: 'program-setup',
        lazy: async () => {
          const { ProgramSetupWithAccessControl } = await import(
            /* webpackChunkName: "imo-config" */
            '@views/myImoConfig/containers/StepOne/ProgramSetup'
          )
          return { element: <ProgramSetupWithAccessControl /> }
        },
      },
      {
        path: 'imo-deliverables-deadlines',
        lazy: async () => {
          const { StepTwoWithAccessControl } = await import(
            /* webpackChunkName: "imo-config" */
            '@views/myImoConfig/containers/StepTwo/StepTwo'
          )
          return { element: <StepTwoWithAccessControl /> }
        },
      },
      {
        path: 'project-taxonomy',
        lazy: async () => {
          const { ProjectTaxonomyWithAccessControl } = await import(
            /* webpackChunkName: "imo-config" */
            '@views/myImoConfig/containers/StepThree/ProjectTaxonomy'
          )
          return { element: <ProjectTaxonomyWithAccessControl /> }
        },
      },
      {
        path: 'team-setup',
        lazy: async () => {
          const { TeamSetupWithAccessControl } = await import(
            /* webpackChunkName: "imo-config" */
            '@views/myImoConfig/containers/StepFour/TeamSetup'
          )
          return { element: <TeamSetupWithAccessControl /> }
        },
      },
      {
        path: 'team-imo-hierarchy',
        lazy: async () => {
          const { TeamImoHierarchyWithAccessControl } = await import(
            /* webpackChunkName: "imo-config" */
            '@views/myImoConfig/containers/StepFive/TeamImoHierarchy'
          )
          return { element: <TeamImoHierarchyWithAccessControl /> }
        },
      },
      {
        path: 'team-deliverable-setup',
        lazy: async () => {
          const { TeamDeliverableSetupWithAccessControl } = await import(
            /* webpackChunkName: "imo-config" */
            '@views/myImoConfig/containers/StepSix/TeamDeliverableSetup'
          )
          return { element: <TeamDeliverableSetupWithAccessControl /> }
        },
      },
      {
        path: 'user-accounts-access-rights',
        lazy: async () => {
          const { UserAccountsAccessRightsWithAccessControl } = await import(
            /* webpackChunkName: "imo-config" */
            '@views/myImoConfig/containers/StepSeven/UserAccountsAccessRights'
          )
          return { element: <UserAccountsAccessRightsWithAccessControl /> }
        },
      },
      {
        path: 'module-options',
        lazy: async () => {
          const { ModuleOptionsWithAccessControl } = await import(
            /* webpackChunkName: "imo-config" */
            '@views/myImoConfig/containers/StepEight/ModuleOptions'
          )
          return { element: <ModuleOptionsWithAccessControl /> }
        },
      },
      {
        path: 'welcome-note',
        lazy: async () => {
          const { WelcomeNoteWithAccessControl } = await import(
            /* webpackChunkName: "imo-config" */
            '@views/myImoConfig/containers/StepNine/WelcomeNote'
          )
          return { element: <WelcomeNoteWithAccessControl /> }
        },
      },
      {
        path: 'user-management',
        lazy: async () => {
          const { UserManagementWithAccessControl } = await import(
            /* webpackChunkName: "imo-config" */
            '@views/myImoConfig/containers/UserManagement/UserManagement'
          )
          return { element: <UserManagementWithAccessControl /> }
        },
      },
      {
        path: 'terms-conditions',
        lazy: async () => {
          const { TermsAndConditionsWithAccessControl } = await import(
            /* webpackChunkName: "imo-config" */
            '@views/myImoClient/components/TermsAndConditions/TermsAndConditions'
          )
          return { element: <TermsAndConditionsWithAccessControl /> }
        },
      },
    ],
  },
]

import React, { memo } from 'react'
import ListGroupName from './ListGroupName'
import { TeamsListWrapper } from './TeamSelector.styles'
import { teamTitles } from '@common/accessController/constants'
import { find, includes, map } from 'lodash'

interface Props {
  teamType: string
  itemListProps: $TSFixMe
  teamsList: $TSFixMe
}

const TeamsList = ({ itemListProps, teamsList, teamType }: Props) => {
  const { itemsParentRef, query, renderItem } = itemListProps

  const renderedItems = (teamGroup: $TSFixMe) => {
    const teams = teamsList[teamGroup]

    return teams.map((teamItem: $TSFixMe) => (
      <React.Fragment key={`${teamItem.teamType}-${teamItem.longName}`}>{renderItem(teamItem)}</React.Fragment>
    ))
  }

  // If there is no matches, group shouldn't be rendered
  const displayGroup = (groupTeams: $TSFixMe) => {
    if (query) {
      const normalizedQuery = query.toLowerCase()

      return find(groupTeams, (team) => includes(team.longName?.toLowerCase(), normalizedQuery))
    }

    return true
  }

  return (
    <TeamsListWrapper ulRef={itemsParentRef}>
      {map(teamsList, (team, teamGroup) =>
        displayGroup(team) ? (
          <React.Fragment key={teamGroup}>
            {teamType === teamTitles.INTEGRATION || teamType === teamTitles.SEPARATION ? (
              <ListGroupName groupName={teamGroup} />
            ) : null}
            {renderedItems(teamGroup)}
          </React.Fragment>
        ) : null,
      )}
    </TeamsListWrapper>
  )
}

export default memo(TeamsList)

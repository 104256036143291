import React from 'react'
import { CustomSearch, darkGrey, mckCyan, mckRed } from '@imo/imo-ui-toolkit'
import { stringComparator } from '@helpers/utilsComparators'

import { Colors, Icon, Intent } from '@blueprintjs/core'

export const imoPostfix = 'myimo.com'
export const dateFormat = 'MMM DD, YYYY'

export const NOT_SELECTED_LABEL = 'Not selected'

export const MAX_INPUT_VALID_NUMBER = 1000000
export const MAX_INPUT_DECIMAL_COUNT = 4

export const siderWidth = 45
export const statusIndicatorHeight = 8
export const deliverableHeaderHeight = 40
export const gridNavbarHeight = 41

export const usersColumnsMap = {
  A: 'name',
  B: 'surname',
  C: 'role',
  D: 'organization',
  E: 'email',
}

export const geographiesColumnsMap = {
  A: 'level1Name',
  B: 'level2Name',
  C: 'level3Name',
  D: 'level4Name',
}

export const cellPlaceholders = {
  privileges: {
    admin: 'Master admin',
    error: 'Unsupportable field format',
    empty: 'Not assigned',
  },
  statuses: {
    admin: 'add created on date/time stamps',
    empty: 'Not created',
    emptyLogin: 'No last login detected',
  },
}

export const CURRENCY_ROUNDING = {
  THOUSAND_NO_DECIMAL: 'Thousands with no decimal',
  MILLION_TWO_DECIMAL: 'Millions with two decimals',
  MILLION_ONE_DECIMAL: 'Millions with one decimal',
  MILLION_NO_DECIMAL: 'Millions with no decimals',
}

export const CURRENCY_ABBREVIATION = {
  USD: 'usd',
  EUR: 'eur',
  GBP: 'gbp',
  JPY: 'jpy',
  CAD: 'cad',
}

export const SEPARATOR = {
  PERIOD: 'Period separator',
  COMMA: 'Comma separator',
  NONE: 'No separator',
}

export const VC_DELIVERABLES_IDS = [13, 14, 15]

export const docFilesFormats = ['.pdf', '.xls', '.xlsx', '.odt', '.ppt', '.pptx', '.txt', '.doc', '.docx', '.rtf']

export const eventFilesFormats = [...docFilesFormats, '.jpeg', '.jpg']

export const managementTypes = {
  imo: 'IMO',
  smo: 'SMO',
  integration: 'integration',
  separation: 'separation',
}

export const customTypes = {
  entanglements: 'Entanglements',
  mutualDiscovery: 'Mutual Discovery',
}

// team ids
export const GLOBAL_IMO_TEAM_ID = 1

export const TEAM_CHARTER_ID = 1
export const MUTUAL_DISCOVERY_ID = 2
export const DAY_ONE_ID = 3
export const DAY_ONE_READINESS_ID = 5
export const DETAILED_TASK_PLANS = 4
export const POST_CLOSE_PROJECT_AND_TASK_PLAN_UPDATE = 7
export const POST_CLOSE_PLANNING_ASSUMPTION_UPDATE = 6
export const ENTANGLEMENTS_AND_PLANNING_ASSUMPTION_ID = 21

export const TSA_V2_SCOPE = 24
export const TSA_V2_COST = 25
export const TSA_V2_CONTACTS = 26
export const TSA_V2_EXIT_PLANS = 27
export const TSA_V2_MANAGEMENT = 28

export const TSA_DELIVERABLES_TYPE_ID = 4
export const TSA_V2_DELIVERABLES_TYPE_ID = 5

export const CENTRAL_FUNCTIONAL_TSA_ID = 17
export const REGIONAL_LOCAL_TSA_ID = 18
export const EXIT_PLANS_TSA_ID = 20

export const TASK_PROJECT_DELIVERABLES_IDS = [DAY_ONE_ID, DETAILED_TASK_PLANS, POST_CLOSE_PROJECT_AND_TASK_PLAN_UPDATE]
export const TSA_DELIVERABLES_IDS = [CENTRAL_FUNCTIONAL_TSA_ID, REGIONAL_LOCAL_TSA_ID, EXIT_PLANS_TSA_ID]
export const TSA_V2_DELIVERABLES_IDS = [
  TSA_V2_SCOPE,
  TSA_V2_COST,
  TSA_V2_CONTACTS,
  TSA_V2_EXIT_PLANS,
  TSA_V2_MANAGEMENT,
]

// Deliverables that associated with a central team appear in the activeDeliverableIds object with a key of -1.
export const CENTRAL_TEAM_DELIVERABLES_GROUP_ID = -1

export const MASTER_PLANNING_DELIVERABLE_TYPE_ID = 1
export const ORG_DESIGN_DELIVERABLE_TYPE_ID = 2
export const VALUE_CAPTURE_DELIVERABLE_TYPE_ID = 3

export const steerCoRowsList = ['Key decisions needed', 'Agenda topics', 'Guest attendees']

export const PROGRAM_PROJECT_PLAN = 'Program Project Plan'
export const useAsteriskPlaceholder = 'Use the asterisk "*" symbol to add bullets here'

export const bulletSymbol = '•'

export const ASIDE_PANEL_WIDTH = '342px'
export const INITIATIVE_LIST_ASIDE_WIDTH_V2 = '408px'
export const VC_TRACKER_ASIDE_PANEL_WIDTH_V2 = '313px'

export const integrationDealTypes = {
  BUY_SIDE_CURVE_OUT: 'Buy-side carve out',
  INTEGRATION: 'Integration',
  MERGER: 'Merger',
}
export const separationDealTypes = {
  DIVESTITURE: 'Divestiture (sale)',
  DEMERGER: 'Demerger (spin, split, IPO)',
}

export const teamClassification = {
  SELECT_CLASSIFICATION: 'Select classification',
  COMMUNICATIONS: 'Communications',
  ORG_DESIGN_AND_TALENT: 'Org Design and Talent',
  VALUE_CAPTURE: 'Value Capture',
}

export const labelColors = [
  '#2354ab',
  Colors.GREEN3,
  '#edbe35',
  Colors.ORANGE3,
  '#975c40',
  mckCyan,
  darkGrey,
  mckRed,
  '#e6a0c8',
  '#8c5ac8',
]

export const defaultFont = '12px sans-serif'

export const ENTER_KEY_CODE = 13

export const ENTER_KEY = 'Enter'

export const FILTER_OPTIONS_ORDER = [
  'contains',
  'equals',
  'notEqual',
  'notContains',
  'startsWith',
  'endsWith',
  'blank',
  'notBlank',
]

export const FILTER_NUMBER_OPTIONS_ORDER = [
  'equals',
  'notEqual',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'inRange',
  'blank',
  'notBlank',
] as const

export const ARROW_UNICODE = '→'

export const REQUIRED_FIELD_MESSAGE = 'This field is required'
export const ASK_TO_UPDATE_MESSAGE = 'Please update this field'
export const SPECIAL_CHARS_MESSAGE =
  'We were unable to save the value. Value shouldn’t contain special characters <>[\\]^#'
export const UPLOAD_ONE_OF_THE_ACCEPTED_TYPES = 'Please upload one of the following accepted file type: {{types}}'
export const FILE_SHOULD_EXCEED_SIZE = `File should not exceed {{size}} MB`

export const synergyTypes = {
  COST: 'Cost',
  REVENUE: 'Revenue',
  NWC: 'NWC',
  ONE_TIME_COST: 'One-time Cost',
} as const

export const frequencyTypes = {
  MONTHLY: 'Monthly',
  WEEKLY: 'Weekly',
} as const

export const titleMonthByMonth = {
  [frequencyTypes.WEEKLY]: 'week-by-week',
  [frequencyTypes.MONTHLY]: 'month-by-month',
}

export const coreModule = {
  ASK_MYIMO: 'askMyIMO',
  IMO_DELIVERABLES_PANEL: 'imoDeliverablesPanel',
  MUTUAL_DISCOVERY: 'mutualDiscovery',
  DOCUMENT_SHARING: 'documentSharing',
  VALUE_CAPTURE: 'valueCapture',
  VALUE_CAPTURE_V2: 'valueCaptureV2',
  COMMUNICATIONS: 'communications',
  DATA_REQUEST_TRACKER: 'dataRequestTracker',
  DAY1_HYPERCARE_LOG: 'day1HypercareLog',
  TEAM_CALENDAR: 'teamCalendar',
  NOTIFICATIONS: 'notifications',
  TEAM_RESOURCES: 'teamResources',
  FILE_ATTACHMENTS: 'fileAttachments',
  MCKINSEY_LIBRARY: 'mcKinseyLibrary',
  REVENUE_SYNERGIES: 'revenueSynergies',
  NWC_SYNERGIES: 'NWCSynergies',
  MIRRORING_RESTRICTIONS: 'mirroringRestrictions',
  KNOWLEDGE_CENTER: 'knowledgeCenter',
  WEEKLY_STATUS_UPDATE: 'weeklyStatusUpdate',
  ADVANCED_FINANCIALS: 'advancedFinancials',
  PUBLISH_DAY: 'publishDay',
  UNRELEASED_FEATURES: 'unreleasedFeatures',
  AIRTABLE_INTEGRATION: 'airtableIntegration',
  INITIATIVE_STATUS: 'initiativesStatus',
  ORG_DESIGN: 'orgDesign',
  EXECUTIVE_DASHBOARD: 'executiveDashboard',
}

export const loadingState = {
  LOADING: 'loading',
  INITIAL: 'initial',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export const LOADING_ACTION = 'loading action'

export const levelMapping = {
  L1: 'Identified',
  L2: 'Validated',
  L3: 'Planned',
  L4: 'Executed',
  L5: 'Realized',
}

export const STAGE_GATE_IDS = {
  L1: 1,
  L2_SUBMITTED: 2,
  L2_APPROVED: 3,
  L3_SUBMITTED: 4,
  L3_APPROVED: 5,
  L4_SUBMITTED: 6,
  L4_APPROVED: 7,
  L5_SUBMITTED: 8,
  L5_APPROVED: 9,
}

export const SPECIAL_ATTRIBUTES_FILTER_VALUES = {
  BLANKS: '(Blanks)',
  MIRRORED_TO: 'Mirrored to',
  MIRRORED_FROM: 'Mirrored from',
  ONE_TIME_BENEFIT: 'One-time benefit',
  ORG_DESIGN: 'Linked to org layer',
}

export const MediaSizes = {
  EXTRA_SMALL: '(max-width: 575px)', // Extra Small Devices (Phones)
  SMALL: '(min-width: 576px) and (max-width: 767px)', // Small Devices (Tablets)
  MEDIUM: '(min-width: 768px) and (max-width: 991px)', // Medium Devices (Tablets)
  LARGE: '(min-width: 992px) and (max-width: 1199px)', // Large Devices (Desktops)
  EXTRA_LARGE: '(min-width: 1200px)', // Extra Large Devices (Large Desktops)
}

export const stageGateOptions = [
  { id: STAGE_GATE_IDS.L1, label: 'L1', value: STAGE_GATE_IDS.L1 },
  { id: STAGE_GATE_IDS.L2_SUBMITTED, label: 'L2 Submitted', value: STAGE_GATE_IDS.L2_SUBMITTED },
  { id: STAGE_GATE_IDS.L2_APPROVED, label: 'L2 Approved', value: STAGE_GATE_IDS.L2_APPROVED },
  { id: STAGE_GATE_IDS.L3_SUBMITTED, label: 'L3 Submitted', value: STAGE_GATE_IDS.L3_SUBMITTED },
  { id: STAGE_GATE_IDS.L3_APPROVED, label: 'L3 Approved', value: STAGE_GATE_IDS.L3_APPROVED },
  { id: STAGE_GATE_IDS.L4_SUBMITTED, label: 'L4 Submitted', value: STAGE_GATE_IDS.L4_SUBMITTED },
  { id: STAGE_GATE_IDS.L4_APPROVED, label: 'L4 Approved', value: STAGE_GATE_IDS.L4_APPROVED },
  { id: STAGE_GATE_IDS.L5_SUBMITTED, label: 'L5 Submitted', value: STAGE_GATE_IDS.L5_SUBMITTED },
  { id: STAGE_GATE_IDS.L5_APPROVED, label: 'L5 Approved', value: STAGE_GATE_IDS.L5_APPROVED },
]

export const getStageGateLabel = (stageGateId: number) => stageGateOptions.find(({ id }) => id === stageGateId)?.label

export const submittedStageGateIdFilter = [
  STAGE_GATE_IDS.L2_SUBMITTED.toString(),
  STAGE_GATE_IDS.L3_SUBMITTED.toString(),
  STAGE_GATE_IDS.L4_SUBMITTED.toString(),
  STAGE_GATE_IDS.L5_SUBMITTED.toString(),
]

export const getSpecialAttributesFilter = ({
  includeOrgLayerInitiatives,
  includeOneTimeBenefitInitiatives,
}: {
  includeOrgLayerInitiatives?: boolean
  includeOneTimeBenefitInitiatives?: boolean
}) => {
  const include = [SPECIAL_ATTRIBUTES_FILTER_VALUES.BLANKS, SPECIAL_ATTRIBUTES_FILTER_VALUES.MIRRORED_TO]
  if (includeOrgLayerInitiatives) {
    include.push(SPECIAL_ATTRIBUTES_FILTER_VALUES.ORG_DESIGN)
  }
  if (includeOneTimeBenefitInitiatives) {
    include.push(SPECIAL_ATTRIBUTES_FILTER_VALUES.ONE_TIME_BENEFIT)
  }
  return include
}

export const defaultCategories = {
  ALL_CATEGORIES: 'All categories',
  NO_CATEGORY: 'No category',
}

export const frequencyTypeDateMap = {
  monthly: 'MMM YYYY',
  weekly: 'MMM DD',
  daily: 'MMM DD',
}

export const notSelectedOption = {
  label: NOT_SELECTED_LABEL,
  value: null,
}

export const BLANKS_FILTER_LABEL = '(Blanks)'
export const ALL_FILTER_LABEL = '(Select All)'

export const importAssetTypes = {
  IMPORT_VIDEO: 'IMPORT_VIDEO_MODULE',
  IMPORT_ARTICLE: 'IMPORT_ARTICLE_MODULE',
  IMPORT_INTRODUCTION_MATERIAL: 'IMPORT_INTRODUCTION_MATERIAL',
}

export const dictionariesNames = {
  PROJECT_PLAN: 'projectPlan',
  VALUE_CAPTURE: 'valueCapture',
  TSA: 'tsa',
  RISK_LOG: 'riskLog',
  DECISION_LOG: 'decisionLog',
}

export const guideKey = {
  INTERDEPENDENCIES: 'Interdependencies',
  PLANNING_ASSUMPTION: 'Planning Assumptions',
  INITIATIVES: 'Synergy Initiatives',
}

export const sourceDeliverables = {
  FROM_TEAM: 'fromTeam',
  FROM_IMO_TEAM: 'fromImoTeam',
}

export const TIME_FLAG = {
  FORECASTING_LAG: 'Forecasting lag',
  DUE_DATE_CHANGED: 'Due date changed',
  COMING_DUE: 'Coming due',
  LATE_TO_START: 'Late to start',
  LATE_TO_FINISH: 'Late to finish',
}

export const WIDTH_TO_APPLY_MIN_THEME = 1280
// Used to determine when to allow scrollbars on dashboard pages
// ~600px means that scrollbars will not appear on the lowest supported resolution of 1280x720
// See here: https://confluence.mso.mckinsey.com/pages/viewpage.action?pageId=102366305
export const HEIGHT_TO_APPLY_MIN_THEME = 600

export const customColumnsValueSelectors = {
  INITIATIVES: 'initiativeType',
  ADVANCED_FINANCIALS: 'advancedFinancialsType',
}

export const DEFAULT_MAX_LENGTH = 10000

export const WIDTH_POPUP = `(${siderWidth}px * 2 + 30px)`

export const URL_EXPIRE_MINUTES = 4.5
export const SPECIAL_ATTRIBUTES = 'Special attributes'

export const PIPELINE_SOURCE = {
  PIPELINE: 'Pipeline',
  ACTUALS_PLANNED_FORECAST: 'ActualsPlannedForecast',
}

export const deltaStatusAdaptor = {
  new: 'New',
  deleted: 'Deleted',
  increased: 'Increase',
  decreased: 'Decrease',
  equal: 'Equal',
  onHold: 'On hold',
}

export const appropriateSynergyTypesToResetToL1 = [synergyTypes.COST, synergyTypes.REVENUE, synergyTypes.NWC]

export const appropriateStageGateIdsToResetToL1 = [
  STAGE_GATE_IDS.L2_SUBMITTED,
  STAGE_GATE_IDS.L2_APPROVED,
  STAGE_GATE_IDS.L3_SUBMITTED,
  STAGE_GATE_IDS.L3_APPROVED,
]

export const filterOptions = {
  floatingFilterComponent: CustomSearch,
  filter: 'agTextColumnFilter',
  filterParams: {
    filterOptions: FILTER_OPTIONS_ORDER,
    debounceMs: 700,
    caseSensitive: false,
    suppressAndOrCondition: true,
    comparator: stringComparator,
  },
}

export const deliverablesIds = {
  postClosePlanningAssumptionsUpdate: 6,
  entanglementsAndPlanningAssumptions: 21,
  mutualDiscoveryAndPlanningAssumptions: 2,
}

export const LOCK_ICON = <Icon icon="lock" intent={Intent.NONE} size={10}></Icon>

export const CUSTOM_COLUMN_OPTION = '1/5/2023 Enterprise plan'

export const allowedTSADealTypes = [
  integrationDealTypes.BUY_SIDE_CURVE_OUT,
  separationDealTypes.DEMERGER,
  separationDealTypes.DIVESTITURE,
]

export const ACTIVE_SIDER_ICON_COLOR = '#00A9F4'
export const DIAGNOSTIC_ICON_COLOR = '#00A9F4'
// NOTIFICATIONS

export enum NotificationEntityTypes {
  ValueCaptureInitiativeV2 = 'ValueCaptureInitiativeV2',
  TeamKeyProcessL2Project = 'TeamKeyProcessL2Project',
  TeamKeyProcessL2ProjectTask = 'TeamKeyProcessL2ProjectTask',
  TSADeliverableV2 = 'TSADeliverableV2',
  InterdependencyTask = 'InterdependencyTask',
  OutgoingInterdependencyTask = 'OutgoingInterdependencyTask',
  TeamKeyProcessProjectImport = 'TeamKeyProcessProjectImport',
  TeamKeyProcessProjectTaskImport = 'TeamKeyProcessProjectTaskImport',
  TeamKeyProcessProjectUpload = 'TeamKeyProcessProjectUpload',
  TeamKeyProcessProjectTaskUpload = 'TeamKeyProcessProjectTaskUpload',
}

// TYPES

export type EntityName =
  | 'Initiative'
  | 'Project'
  | 'Task'
  | 'TSADeliverableV2'
  | 'Incoming Interdependency'
  | 'Outgoing Interdependency'
  | 'Project Library Import'
  | 'Task Library Import'
  | 'Project Upload'
  | 'Task Upload'

export type EntityType = `${NotificationEntityTypes}`
export type SynergyTypes = (typeof synergyTypes)[keyof typeof synergyTypes]

export const TabIdsMap = {
  details: 'compensation-details-tab',
  financials: 'compensation-financials-tab',
  stageGate: 'stage-gate-tab',
  watchers: 'watchers',
}

export const ASIDE_BLOCK_STATUSES = {
  INITIAL: 'initial',
  OPEN: 'open',
  COLLAPSED: 'collapsed',
}

export const COMMENTS_MAX_VALUE_LENGTH = 10000

export const initialTabs = {
  Cost: {
    locked: false,
    enabled: false,
  },
  Revenue: {
    locked: false,
    enabled: false,
  },
  NWC: {
    locked: false,
    enabled: false,
  },
}

export enum CalendarViewOptions {
  CalendarYear = 'Calendar year',
  FiscalYear = 'Fiscal year',
  Year1 = 'Year 1',
}

import { put, takeLatest, all, call, select } from 'redux-saga/effects'
import * as teamDeliverablesConstants from '@myImoConfigActions/teamDeliverables/actionTypes'
import * as configConstants from '@myImoConfigActions/config/actionTypes'
import { getTeamDeliverablesApi } from '@common/net'
import { getBuiltDeliverableCentralTeams, getBuiltDeliverableIntegrationTeams } from '@domain/myImoConfig/selectors'
import { createSaga } from '@common/sagaCreator/createSaga'
import { validateTeamDeliverables } from '@helpers/validatorsConfig'
import { handleForbiddenError, preparePayloadTeams } from '../utils'
import { NavigateFunction } from 'react-router-dom'

export const getTeamDeliverables = createSaga(
  function* getTeamDeliverables(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const teamDeliverablesApi = yield getTeamDeliverablesApi()
    const teamDeliverablesData = yield call(teamDeliverablesApi.request('getTeamDeliverables'))

    yield put({ type: teamDeliverablesConstants.SET_TEAM_DELIVERABLES, payload: teamDeliverablesData })
  },
  true,
  handleForbiddenError,
)

export const saveTeamDeliverables = createSaga(function* saveTeamDeliverables({
  payload: { navigate },
}: {
  payload: { navigate: NavigateFunction }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const teamDeliverablesApi = yield getTeamDeliverablesApi()
  const centralTeams = yield select(getBuiltDeliverableCentralTeams)
  const integrationTeams = yield select(getBuiltDeliverableIntegrationTeams)
  const preparedPayload = preparePayloadTeams({ centralTeams, integrationTeams })

  yield call(validateTeamDeliverables, preparedPayload)
  yield call(teamDeliverablesApi.request('createTeamDeliverables', { body: preparedPayload }))
  yield put({ type: configConstants.SET_STEP_COMPLETED, payload: { no: 6 } })
  navigate('/config/user-accounts-access-rights')
},
true)

export function* teamDeliverablesWatcher() {
  yield all([
    takeLatest(teamDeliverablesConstants.GET_TEAM_DELIVERABLES, getTeamDeliverables),
    takeLatest(teamDeliverablesConstants.SAVE_TEAM_DELIVERABLES, saveTeamDeliverables),
  ])
}

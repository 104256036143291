import { StageGateCalculationOption, CalendarViewOption } from '@domain/myImoConfig/moduleOptions/reducer/types.d'

export const selectNamesMapping: { [Key in StageGateCalculationOption]: string } = {
  stageGateSubmitted: 'In the stage gate submitted',
  priorApprovedStageGate: 'In the prior approved stage gate',
}

export const calendarViewMapping: { [Key in CalendarViewOption]: string } = {
  'Calendar year': 'Calendar year',
  'Fiscal year': 'Fiscal year',
  'Year 1': 'Year 1',
}

export const INITIATIVE_STATUS_BASED_ON = {
  FORECAST_DATE: 'Forecast date',
  PLAN_DATE: 'Plan date',
}

export const STAGE_GATE_PRIOR_OPTION = {
  SUBMITTED: 'stageGateSubmitted',
  APPROVED: 'priorApprovedStageGate',
}

export const STAGE_GATE_STATUS = {
  APPROVED: 'Approved',
  SUBMITTED: 'Submitted',
}

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchProjectMap = createAction(constants.FETCH_PROJECT_MAP, null, getMetaWithActionLoading)
export const setProjectMap = createAction(constants.SET_PROJECT_MAP)

export const linkProjectMapItems = createAction(constants.LINK_ITEM)
export const linkProjectMapItemsSuccess = createAction(constants.LINK_ITEM_SUCCESS)

export const unlinkProjectMapItems = createAction(constants.UNLINK_ITEM)
export const unlinkProjectMapItemsSuccess = createAction(constants.UNLINK_ITEM_SUCCESS)

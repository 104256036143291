import { get } from 'lodash'

export const isModuleDisabled = (page: string, modulesList: $TSFixMe) => {
  const pagesToModulesMap: Record<string, string> = {
    'value capture tracker': 'valueCapture',
    'org design tracker': 'orgDesign',
    communications: 'communications',
    'team resources': 'teamResources',
    'executive dashboard': 'executiveDashboard',
  }

  const pageModule = pagesToModulesMap[page.toLowerCase()]

  if (!pageModule) {
    return false
  }

  return !get(modulesList[pageModule], 'active', true)
}

import React, { ReactNode, useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../common/net'
import {
  createDefaultTenantFeatureFlags,
  FeatureFlag,
  FeatureFlagContext,
  FlagValue,
  TenantFeatureFlags,
} from './feature-flag-context'

/**
 * Add headless controls to window interface
 */
declare global {
  interface Window {
    enableFlag?: (flag: FeatureFlag) => void
    disableFlag?: (flag: FeatureFlag) => void
    getFlags?: () => void
  }
}

async function fetchFlags() {
  const { data, status } = await api.get<TenantFeatureFlags<FeatureFlag>>({ url: 'flags/tenant' })

  if (!data || status !== 200) {
    return createDefaultTenantFeatureFlags()
  }
  return data
}

const isProdEnv = () => !['development', 'qa', 'staging'].includes(window.NODE_ENV)

function useFetchedFeatureFlags() {
  const tenant = JSON.parse(sessionStorage.getItem('_mid-tenant') ?? '{}').tenantId
  const result = useQuery<TenantFeatureFlags<FeatureFlag>>(['feature-flags'], fetchFlags, {
    cacheTime: 0,
    useErrorBoundary: false,
  })
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async ({ flag, tenant, enabled }: { flag: string; tenant: string; enabled: boolean }) => {
      const response = await api.put({
        url: `flags?access-key=${window.NODE_ENV}`,
        data: { tenant, flag, enabled },
      })
      if (response.status !== 200) {
        throw new Error(`got status code ${response.status}`)
      }
      return response.data as FlagValue
    },
    onSuccess: (data, { flag }) => {
      queryClient.setQueryData<TenantFeatureFlags<FeatureFlag>>(
        ['feature-flags'],
        (flags = createDefaultTenantFeatureFlags()) => {
          return {
            ...flags,
            [flag]: {
              ...data,
            },
          }
        },
      )
    },
    useErrorBoundary: false,
  })

  // TODO remove this once a UI has been made for feature flags
  useEffect(() => {
    if (isProdEnv()) {
      return
    }
    if (!result.isLoading) {
      const getFlags = () => {
        const flags = queryClient.getQueryData<TenantFeatureFlags<FeatureFlag>>(['feature-flags'])
        if (flags) {
          const sortedFlags = Object.keys(flags)
            .sort()
            .reduce((obj, key) => {
              obj[key as FeatureFlag] = flags[key as FeatureFlag]
              return obj
            }, {} as TenantFeatureFlags<FeatureFlag>)
          // eslint-disable-next-line
          console.table(sortedFlags)
        }
      }

      window.getFlags = getFlags

      window.enableFlag = async (flag: FeatureFlag) => {
        try {
          await mutation.mutateAsync({ flag, tenant, enabled: true })
          getFlags()
        } catch {
          getFlags()
        }
      }

      window.disableFlag = async (flag: FeatureFlag) => {
        try {
          await mutation.mutateAsync({ flag, tenant, enabled: false })
          getFlags()
        } catch {
          getFlags()
        }
      }

      return () => {
        delete window.getFlags
        delete window.enableFlag
        delete window.disableFlag
      }
    }
  }, [result.isLoading])

  if (result.isLoading) {
    return null
  }

  if (result.isSuccess && result.data != null) {
    return result.data
  }
  return createDefaultTenantFeatureFlags()
}

export default function FeatureFlagProvider(props: { children: ReactNode }) {
  const flags = useFetchedFeatureFlags()
  // let's not render the application until we have flags
  if (!flags) {
    return null
  }

  return <FeatureFlagContext.Provider value={flags}>{props.children}</FeatureFlagContext.Provider>
}

import { handleActions } from 'redux-actions'
import {
  SET_BULK_UPLOAD_ACTUALS_FILE,
  SEND_BULK_UPLOAD_ACTUALS_FILE_PENDING,
  SEND_BULK_UPLOAD_ACTUALS_FILE_SUCCESS,
  SEND_BULK_UPLOAD_ACTUALS_FILE_FAIL,
  FLUSH_BULK_UPLOAD_ACTUALS,
  UPDATE_BULK_UPLOAD_SENDING_STATUS,
  FETCH_AMOUNT_OF_INITIATIVES_PENDING,
  FETCH_AMOUNT_OF_INITIATIVES_SUCCESS,
  FETCH_AMOUNT_OF_INITIATIVES_FAIL,
  SEND_BULK_UPLOAD_FILE_PENDING,
  DOWNLOAD_BULK_UPLOAD_TEMPLATE_PENDING,
  DOWNLOAD_BULK_UPLOAD_TEMPLATE_SUCCESS,
  DOWNLOAD_BULK_UPLOAD_TEMPLATE_FAIL,
  UPDATE_DOWNLOAD_BULK_UPLOAD_TEMPLATE_STATUS,
} from '../actions/actionTypes'
import { IBatchUploadActuals } from '../types/index.d'
import { SendingState, SendingStateType } from '../constants'

type InitialStateType = IBatchUploadActuals<SendingStateType>

const initialState: InitialStateType = {
  file: null,
  uploadState: {},
  sending: null,
  downloading: null,
  errorMessage: null,
  downloadErrorMessage: null,
}

const batchUploadActualsReducer = handleActions<InitialStateType, $TSFixMe>(
  {
    [SET_BULK_UPLOAD_ACTUALS_FILE]: (state, { payload }) => {
      return {
        ...state,
        file: payload,
        sending: null,
      }
    },
    [UPDATE_BULK_UPLOAD_SENDING_STATUS]: (state, { payload }) => {
      return {
        ...state,
        errorMessage: payload.errorMessage,
        sending: payload.sending,
        amountOfInitiatives: null,
      }
    },
    [SEND_BULK_UPLOAD_ACTUALS_FILE_PENDING]: (state) => {
      return {
        ...state,
        errorMessage: null,
        sending: SendingState.INITIAL,
      }
    },
    [SEND_BULK_UPLOAD_ACTUALS_FILE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        errorMessage: null,
        uploadState: payload,
        sending: SendingState.FINISHED,
      }
    },
    [SEND_BULK_UPLOAD_ACTUALS_FILE_FAIL]: (state, { payload }) => {
      return {
        ...state,
        uploadState: payload,
        sending: SendingState.FINISHED,
      }
    },
    [FLUSH_BULK_UPLOAD_ACTUALS]: () => initialState,
    [FETCH_AMOUNT_OF_INITIATIVES_PENDING]: (state) => {
      return {
        ...state,
        errorMessage: null,
        sending: SendingState.INITIAL,
      }
    },
    [FETCH_AMOUNT_OF_INITIATIVES_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        amountOfInitiatives: payload,
        sending: null,
      }
    },
    [FETCH_AMOUNT_OF_INITIATIVES_FAIL]: (state, { payload }) => {
      return {
        ...state,
        uploadState: payload,
        sending: SendingState.FINISHED,
      }
    },
    [SEND_BULK_UPLOAD_FILE_PENDING]: (state) => {
      return {
        ...state,
        errorMessage: null,
        sending: SendingState.INITIAL,
      }
    },
    [DOWNLOAD_BULK_UPLOAD_TEMPLATE_PENDING]: (state) => {
      return {
        ...state,
        downloadErrorMessage: null,
        downloading: SendingState.INITIAL,
      }
    },
    [DOWNLOAD_BULK_UPLOAD_TEMPLATE_SUCCESS]: (state) => {
      return {
        ...state,
        downloadErrorMessage: null,
        downloading: SendingState.FINISHED,
      }
    },
    [DOWNLOAD_BULK_UPLOAD_TEMPLATE_FAIL]: (state, { payload }) => {
      return {
        ...state,
        downloadErrorMessage: payload.downloadErrorMessage,
        downloading: SendingState.FAILED,
      }
    },
    [UPDATE_DOWNLOAD_BULK_UPLOAD_TEMPLATE_STATUS]: (state, { payload }) => {
      return {
        ...state,
        downloadErrorMessage: payload.downloadErrorMessage,
        downloading: payload.downloading,
      }
    },
  },
  initialState,
)

export default batchUploadActualsReducer

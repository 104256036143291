import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithIgnoreLoading, getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchHeadcountReduction = createAction(constants.FETCH_HEADCOUNT_REDUCTION)
export const refetchHeadcountReduction = createAction(
  constants.FETCH_HEADCOUNT_REDUCTION,
  null,
  getMetaWithIgnoreLoading,
)
export const setHeadcountReduction = createAction(constants.SET_HEADCOUNT_REDUCTION)

export const fetchOneTimeImplementationCosts = createAction(constants.FETCH_ONE_TIME_IMPLEMENTATION_COSTS)
export const setOneTimeImplementationCosts = createAction(constants.SET_ONE_TIME_IMPLEMENTATION_COSTS)

export const fetchMonthlyPipelineStatus = createAction(constants.FETCH_MONTHLY_PIPELINE_STATUS)
export const setMonthlyPipelineStatus = createAction(constants.SET_MONTHLY_PIPELINE_STATUS)

export const fetchDeltaTrackerData = createAction(constants.FETCH_DELTA_TRACKER_DATA, null, getMetaWithActionLoading)
export const setDeltaTrackerData = createAction(constants.SET_DELTA_TRACKER_DATA)

// Actions for Month By Month VC 2.1
export const fetchMonthlyActualsStatusV2 = createAction(constants.FETCH_MONTHLY_ACTUALS_STATUS_V2)
export const setMonthlyActualsStatusV2 = createAction(constants.SET_MONTHLY_ACTUALS_STATUS_V2)

export const fetchMonthlyPipelineStatusV2 = createAction(constants.FETCH_MONTHLY_PIPELINE_STATUS_V2)
export const setMonthlyPipelineStatusV2 = createAction(constants.SET_MONTHLY_PIPELINE_STATUS_V2)

export const fetchDeltaTrackerV2Data = createAction(
  constants.FETCH_DELTA_TRACKER_V2_DATA,
  null,
  getMetaWithActionLoading,
)
export const setDeltaTrackerV2Data = createAction(constants.SET_DELTA_TRACKER_V2_DATA)

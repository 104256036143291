import { put, call, all } from 'redux-saga/effects'
import { setDocumentSharing, fetchDocumentSharing as fetchAction } from '@teamResources/resourcesHome/actions/actions'
import { getTeamResourcesApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { runDownloadByUrl } from '@helpers/utils'

export const fetchDocumentSharing = createSaga(function* fetchDocumentSharing(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const api = yield getTeamResourcesApi()
  const data = yield call(api.request('getDocumentSharing'))

  yield put(setDocumentSharing(data))
})

export const createDocumentSharingFolder = createSaga(function* createDocumentSharingFolder({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { path } = payload
  const api = yield getTeamResourcesApi()

  yield call(
    api.request('createFolder', {
      query: { path },
    }),
  )

  yield put(fetchAction())
})

export const updateDocumentSharingFolder = createSaga(function* updateDocumentSharingFolder({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { path, id } = payload
  const api = yield getTeamResourcesApi()

  yield call(
    api.request('updateFolder', {
      query: { id, path },
    }),
  )

  yield put(fetchAction())
})

export const createDocumentSharingFile = createSaga(function* createDocumentSharingFile({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { path, file } = payload
  const api = yield getTeamResourcesApi()

  try {
    yield call(
      api.request('createFile', {
        query: {
          path,
        },
        body: file,
      }),
    )

    yield put(fetchAction())
  } catch (e) {
    yield put(fetchAction())

    throw e
  }
})

export const documentSharingDeleteNodes = createSaga(function* documentSharingDeleteNodes({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { keys } = payload
  const api = yield getTeamResourcesApi()

  yield call(
    api.request('deleteNodes', {
      query: {
        keys: keys.join(','),
      },
    }),
  )

  yield put(fetchAction())
})

export const documentSharingDownloadFiles = createSaga(function* documentSharingDownloadFiles({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { keys } = payload
  const api = yield getTeamResourcesApi()

  const urlsList = yield call(
    api.request('downloadFiles', {
      query: {
        keys: keys.join(','),
      },
    }),
  )

  yield all(
    urlsList.forEach(({ signedUrl, error, name = 'kek' }: $TSFixMe) => {
      if (signedUrl && !error) {
        runDownloadByUrl({ url: signedUrl, name })
      }
    }),
  )
})

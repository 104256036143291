import { MDPAProcess } from '@common/types/dtos/MDPAKeyProcessDto'
import { TeamKeyProcessProjectOrTask } from '@common/types/dtos/DayOneProjectListDto'

import { ACTIVE_USER_COLORS } from './utils/constants'

export enum RealTimeEvents {
  Connect = 'connect',
  Disconnect = 'disconnect',
  ConnectionError = 'connection_error',
}

export enum ServerToClientEvents {
  AuthenticationSuccess = 'authenticationSuccess',
  Error = 'error',
  SubscribeSuccess = 'subscribeSuccess',
  UnsubscribeSuccess = 'unsubscribeSuccess',
  FocusSuccess = 'focusSuccess',
  BlurSuccess = 'blurSuccess',
  EditingStartedSuccess = 'editingStartedSuccess',
  EditingStoppedSucces = 'editingStoppedSuccess',
  DisconnectSuccess = 'disconnectSuccess',
  DayOneProjectListUpdated = 'dayOneProjectListUpdated',
  MDPAUpdated = 'MDPAUpdated',
  TeamCharterUpdated = 'teamCharterUpdated',
  RiskLogUpdated = 'riskLogUpdated',
  DecisionLogUpdated = 'decisionLogUpdated',
}

export enum ClientToServerEvents {
  Authenticate = 'authenticate',
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
  RefreshToken = 'refreshToken',
  Blur = 'blur',
  Focus = 'focus',
  EditingStarted = 'editingStarted',
  EditingStopped = 'editingStopped',
}

export enum Rooms {
  DayOneProjectList = 'dayOneProjectList',
  MDPA = 'MDPA',
  TeamValueCaptureCost = 'teamValueCaptureCost',
  TeamCharter = 'teamCharter',
  RiskLog = 'riskLog',
  DecisionLog = 'decisionLog',
}

export const VALUE_CAPTURE_ROOMS = [Rooms.TeamValueCaptureCost]

type DayOneProjectListCellCoords = {
  id: number
  type: string
  field: string
  isCustomField: boolean
  projectListId: string
  rowId: string
}

type MDPACellCoords = {
  id: number
  field: string
  rowId: string
  name: string
}

type TeamCharterCellCoords = {
  id: number
  field: string
  rowId: string
  type: string
  sectionTitle: string
}

type TeamCharterTitleCoords = {
  field: string
  type: string
  sectionTitle: string
}

type RiskDecisionLogCellCoords = {
  id: number
  field: string
  rowId: string
  logId: number
}

export type ActiveCellCoords =
  | DayOneProjectListCellCoords
  | MDPACellCoords
  | TeamCharterCellCoords
  | TeamCharterTitleCoords
  | RiskDecisionLogCellCoords

type BaseActiveUserFocus = {
  room: Rooms
  cellKey: string
  editing?: boolean
}

export type DayOneProjectListUserFocus = BaseActiveUserFocus & DayOneProjectListCellCoords

export type MDPAUserFocus = BaseActiveUserFocus & MDPACellCoords

export type TeamCharterUserFocus = BaseActiveUserFocus & TeamCharterCellCoords

export type RiskDecisionLogUserFocus = BaseActiveUserFocus & RiskDecisionLogCellCoords

export type ActiveUserFocus =
  | DayOneProjectListUserFocus
  | MDPAUserFocus
  | TeamCharterUserFocus
  | RiskDecisionLogUserFocus

export enum DataUpdateTypes {
  KeyProcessCreated = 'keyProcessCreated',
  KeyProcessDeleted = 'keyProcessDeleted',
  KeyProcessUpdated = 'keyProcessUpdated',
  KeyProcessBulkCreate = 'keyProcessBulkCreate',
  KeyProcessBulkDelete = 'keyProcessBulkDelete',

  ProjectCreated = 'projectCreated',
  ProjectDeleted = 'projectDeleted',
  ProjectUpdated = 'projectUpdated',

  AttachmentUpdated = 'attachmentUpdated',

  TaskCreated = 'taskCreated',
  TaskDeleted = 'taskDeleted',
  TaskUpdated = 'taskUpdated',

  ProjectListUpdated = 'projectListUpdated',
  ProjectListIdUpdated = 'projectListIdUpdated',
  ProcessListIdUpdated = 'processListIdUpdated',
  ProjectListBulkCreate = 'projectListBbulkCreate',

  MDPAKeyProcessUpdated = 'MDPAKeyProcessUpdated',

  SubKeyProcessCreated = 'subKeyProcessCreated',
  SubKeyProcessDeleted = 'subKeyProcessDeleted',
  SubKeyProcessUpdated = 'subKeyProcessUpdated',

  TeamCharterBulkUpdate = 'TeamCharterBulkUpdate',

  TeamCharterKeyProcessUpdated = 'teamCharterKeyProcessUpdated',
  TeamCharterKeyProcessCreated = 'teamCharterKeyProcessCreated',
  TeamCharterKeyProcessDeleted = 'teamCharterKeyProcessDeleted',

  TeamCharterKeyProcessOutScopeUpdated = 'teamCharterKeyProcessOutScopeUpdated',
  TeamCharterKeyProcessOutScopeCreated = 'teamCharterKeyProcessOutScopeCreated',
  TeamCharterKeyProcessOutScopeDeleted = 'teamCharterKeyProcessOutScopeDeleted',

  TeamCharterInterdependencyUpdated = 'teamCharterInterdependencyUpdated',
  TeamCharterInterdependencyCreated = 'teamCharterInterdependencyCreated',
  TeamCharterInterdependencyDeleted = 'teamCharterInterdependencyDeleted',

  TeamCharterKeyRiskUpdated = 'teamCharterKeyRiskUpdated',
  TeamCharterKeyRiskCreated = 'teamCharterKeyRiskCreated',
  TeamCharterKeyRiskDeleted = 'teamCharterKeyRiskDeleted',

  TeamCharterKeyDecisionUpdated = 'teamCharterKeyDecisionUpdated',
  TeamCharterKeyDecisionCreated = 'teamCharterKeyDecisionCreated',
  TeamCharterKeyDecisionDeleted = 'teamCharterKeyDecisionDeleted',

  TeamCharterTeamMemberUpdated = 'teamCharterTeamMemberUpdated',
  TeamCharterTeamMemberCreated = 'teamCharterTeamMemberCreated',
  TeamCharterTeamMemberDeleted = 'teamCharterTeamMemberDeleted',

  TeamCharterMacroPlanningAssumptionUpdated = 'teamCharterMacroPlanningAssumptionUpdated',
  TeamCharterMacroPlanningAssumptionCreated = 'teamCharterMacroPlanningAssumptionCreated',
  TeamCharterMacroPlanningAssumptionDeleted = 'teamCharterMacroPlanningAssumptionDeleted',

  TeamCharterSectionTitleUpdated = 'teamCharterSectionTitleUpdated',

  RiskLogUpdated = 'riskLogUpdated',
  RiskLogCreated = 'riskLogCreated',
  RiskLogDeleted = 'riskLogDeleted',

  DecisionLogUpdated = 'decisionLogUpdated',
  DecisionLogCreated = 'decisionLogCreated',
  DecisionLogDeleted = 'decisionLogDeleted',
}

export enum TeamCharterDataTypes {
  KeyProcess = 'keyProcess',
  KeyProcessOutScope = 'keyProcessOutScope',
  Interdependencies = 'interdependencies',
  KeyRisk = 'keyRisk',
  KeyDecision = 'keyDecision',
  TeamMember = 'teamMember',
  MacroPlanningAssumption = 'macroPlanningAssumption',
  SectionTitle = 'sectionTitle',
}

export type ActiveUserSocketData = {
  tenant: string
  user: {
    id: number
    email: string
    name: string
    surname: string
  }
  roomName: string
  focus: ActiveUserFocus
  color: ActiveUserColor
}

export type ActiveUserColor = (typeof ACTIVE_USER_COLORS)[number]

type ProjectListIdUpdateData = { projectId: number; projectListId: number; processListId: number; keyProcessId: number }

export type DayOneProjectListUpdatePayload = TeamKeyProcessProjectOrTask | ProjectListIdUpdateData[]

export type MDPAListUpdatePayload = MDPAProcess | { id: number; keyProcessId: number; name: string }

export type TeamCharterKeyProcessListUpdatePayload = { id: number; name: string }

export type DataUpdatedPayload =
  | DayOneProjectListUpdatePayload
  | MDPAListUpdatePayload
  | TeamCharterKeyProcessListUpdatePayload

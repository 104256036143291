import styled from 'styled-components'
import { Button, Colors } from '@blueprintjs/core'
import MyIMOLogoGrey from '@assets/img/logo/logo-guidance.svg'
import { FONT_FAMILY, white } from '@imo/imo-ui-toolkit'
import { WIDTH_TO_APPLY_MIN_THEME } from '@helpers/constants'
import { ImoGrid } from '@shared/Grid/ImoGrid'

export const StyledGrid = styled(ImoGrid)`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${WIDTH_TO_APPLY_MIN_THEME}px) {
    .bp4-icon + div,
    .bp4-button-group .bp4-small .bp4-button-text {
      display: none;
    }
  }

  .primary-header {
    padding-right: 3px;
  }

  .ag-theme-balham {
    .ag-side-buttons {
      padding-top: 0;
      width: 23px;
    }
    .ag-ltr .ag-side-bar-right .ag-side-button-button {
      border-bottom: none;
    }
    .ag-selected .ag-side-button-button {
      background-color: ${white};
    }
    .ag-side-button-icon-wrapper {
      display: none;
    }
  }
`

export const InitiativeListWrapper = styled.div`
  width: 100%;
  height: 100%;

  .ag-header-viewport .ag-header-cell {
    padding: 0 7px;
  }

  .ag-theme-balham .ag-center-cols-clipper .ag-cell {
    padding: 0 6px;
  }

  .bp4-icon {
    vertical-align: middle;
  }

  .ag-cell-value.ag-cell-focus.ag-cell-inline-editing {
    padding: 0;
  }
`

export const LogoGrey = styled.div`
  background-image: url(${MyIMOLogoGrey});
  background-attachment: unset;
  background-repeat: no-repeat;
  background-size: cover;
  width: 17px;
  height: 16px;
  margin: 0 1px 0 0;
`

export const StyledText = styled.div`
  font-family: ${FONT_FAMILY};
  font-size: 14px;
  line-height: 20px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  b {
    font-family: ${FONT_FAMILY};
    font-weight: 500;
  }
`

export const StyledSubHeader = styled.div`
  color: ${white};
  background-color: ${Colors.BLUE2};
  height: 40px;
  width: 100%;
  margin: 10px;
  padding: 5px;
`

export const StyledCollapse = styled(Button)`
  @media (max-width: ${WIDTH_TO_APPLY_MIN_THEME}px) {
    .bp4-button-text {
      display: none;
    }
  }
`

import { combineActions, handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'
import { sortBy } from 'lodash/fp'
import { loadingState } from '@helpers/constants'
import { updateOrder } from '../utils'

export interface IKnowledgeCenterState {
  videos: $TSFixMe[]
  videosStatus: string
  createVideoStatus?: string
  articles: $TSFixMe[]
  articlesStatus: string
  createArticleStatus?: string
  introductionMaterialsFetchDate: number | string
  introductionMaterials: $TSFixMe[]
  introductionMaterialsStatus: string
  createIntroductionMaterialStatus?: string
  selectedItem?: $TSFixMe
  selectedItemErrors?: $TSFixMe
  editModalState?: { isModalOpen: boolean; isNew: boolean }
  activePage?: $TSFixMe | null
}

export const initialState = {
  videos: [],
  videosStatus: loadingState.INITIAL,
  createVideoStatus: loadingState.INITIAL,

  articles: [],
  articlesStatus: loadingState.INITIAL,
  createArticleStatus: loadingState.INITIAL,

  introductionMaterialsFetchDate: 0,
  introductionMaterials: [],
  introductionMaterialsStatus: loadingState.INITIAL,
  createIntroductionMaterialStatus: loadingState.INITIAL,

  selectedItem: {},
  selectedItemErrors: {},

  editModalState: { isModalOpen: false, isNew: true },
  activePage: null,
}

const teamWorkspace = handleActions<IKnowledgeCenterState, $TSFixMe>(
  {
    [constants.SET_KNOWLEDGE_CENTER_VIDEO_STATE]: (state, action) => ({
      ...state,
      videosStatus: action.payload,
    }),
    [constants.SET_KNOWLEDGE_CENTER_VIDEO]: (state, action) => {
      return {
        ...state,
        videos: action.payload.map((it: $TSFixMe) => ({ ...it, originalTitle: it.title })),
      }
    },
    [constants.ADD_KNOWLEDGE_CENTER_VIDEO]: (state, { payload }) => ({
      ...state,
      videos: sortBy('index', [...state.videos, { ...payload, originalTitle: payload.title }]),
    }),
    [constants.SET_KNOWLEDGE_CENTER_ARTICLES_STATE]: (state, action) => ({
      ...state,
      articlesStatus: action.payload,
    }),
    [constants.SET_KNOWLEDGE_CENTER_ARTICLES]: (state, action) => {
      return {
        ...state,
        articles: action.payload.map((it: $TSFixMe) => ({ ...it, originalTitle: it.title })),
      }
    },
    [constants.SET_KNOWLEDGE_CENTER_INTRODUCTION_MATERIALS_STATE]: (state, action) => ({
      ...state,
      introductionMaterialsStatus: action.payload,
    }),
    [constants.SET_KNOWLEDGE_CENTER_INTRODUCTION_MATERIALS]: (state, action) => {
      const { introductionMaterialsFetchDate, introductionMaterials } = action.payload

      return {
        ...state,
        introductionMaterialsFetchDate,
        introductionMaterials: introductionMaterials.map((it: $TSFixMe) => ({ ...it, originalTitle: it.title })),
      }
    },
    [constants.SET_SELECTED_ITEM]: (state, action) => ({ ...state, selectedItem: action.payload }),
    [constants.SET_SELECTED_ITEM_ERRORS]: (state, action) => ({ ...state, selectedItemErrors: action.payload }),
    [`${combineActions(
      constants.CREATE_NEW_KNOWLEDGE_CENTER_VIDEO_STATE,
      constants.UPDATE_KNOWLEDGE_CENTER_VIDEO_STATE,
    )}`]: (state, action) => ({
      ...state,
      createVideoStatus: action.payload,
    }),
    [constants.RESET_CREATE_KNOWLEDGE_CENTER_VIDEO_STATE]: (state) => ({
      ...state,
      createVideoStatus: initialState.createVideoStatus,
    }),
    [`${combineActions(
      constants.CREATE_NEW_KNOWLEDGE_CENTER_ARTICLE_STATE,
      constants.UPDATE_KNOWLEDGE_CENTER_ARTICLE_STATE,
    )}`]: (state, action) => ({
      ...state,
      createArticleStatus: action.payload,
    }),
    [constants.RESET_CREATE_KNOWLEDGE_CENTER_ARTICLE_STATE]: (state) => ({
      ...state,
      createArticleStatus: initialState.createArticleStatus,
    }),
    [`${combineActions(
      constants.CREATE_NEW_INTRODUCTION_MATERIAL_STATE,
      constants.UPDATE_INTRODUCTION_MATERIAL_STATE,
    )}`]: (state, action) => ({
      ...state,
      createIntroductionMaterialStatus: action.payload,
    }),
    [constants.RESET_CREATE_INTRODUCTION_MATERIAL_STATE]: (state) => ({
      ...state,
      createIntroductionMaterialStatus: initialState.createIntroductionMaterialStatus,
    }),
    [constants.SET_ACTIVE_PAGE]: (state, { payload }) => ({
      ...state,
      activePage: payload,
    }),
    [constants.CHANGE_EDIT_MODAL_STATE]: (state, action) => ({
      ...state,
      editModalState: {
        ...state.editModalState,
        ...action.payload,
      },
    }),
    [constants.UPDATE_VIDEOS_ORDER]: (state, { payload: { dragId, hoverId } }) => {
      return {
        ...state,
        videos: updateOrder(state.videos, dragId, hoverId),
      }
    },
    [constants.UPDATE_ARTICLES_ORDER]: (state, { payload: { dragId, hoverId } }) => {
      return {
        ...state,
        articles: updateOrder(state.articles, dragId, hoverId),
      }
    },
    [constants.UPDATE_INTRODUCTION_MATERIAL_ORDER]: (state, { payload: { dragId, hoverId } }) => {
      return {
        ...state,
        introductionMaterials: updateOrder(state.introductionMaterials, dragId, hoverId),
      }
    },
  },
  initialState,
)

export default teamWorkspace

import { put, takeLatest, all, call, takeEvery, select } from 'redux-saga/effects'
import { map, omit } from 'lodash/fp'
import * as deliverableFormConstants from '@myImoConfigActions/deliverables/actionTypes'
import * as configConstants from '@myImoConfigActions/config/actionTypes'
import { getGenericApi, getDeliverableTypeApi } from '@common/net'
import { mapDeliverableTypes } from '@helpers/utils'
import { updateDeliverable } from '@myImoConfigActions/deliverables/actions'
import { createSaga } from '@common/sagaCreator/createSaga'
import { validateDeliverables } from '@helpers/validatorsConfig'
import { getTenantId } from '@generic/selectors'
import { actionTypes } from '@common/notifications/utils'
import { CREATE_DELIVERABLE, UPDATE_DELIVERABLE } from '@myImoConfigActions/deliverables/actionTypes'
import { showWarningMessage } from '@generic/actions/actions'
import { getTransformedDeliverables, handleForbiddenError, updateOrder } from '../utils'
import { NavigateFunction } from 'react-router-dom'

export const updateDeliverableType = createSaga(function* updateDeliverableType({
  payload,
}: {
  payload: { deliverableTypes: $TSFixMe; navigate: NavigateFunction; numberOfOrgDesignLayers?: number }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const deliverableFormApi = yield getDeliverableTypeApi()
  const deliverableTypes = mapDeliverableTypes(updateOrder(payload.deliverableTypes))
  const transformedData = getTransformedDeliverables(deliverableTypes)

  yield call(validateDeliverables, transformedData)

  yield call(
    deliverableFormApi.request('bulkUpdateDeliverableType', {
      body: map(omit('custom'), transformedData),
    }),
  )

  const deliverablesData = yield call(deliverableFormApi.request('getDeliverableTypeWithDeliverables'))

  yield put({ type: configConstants.SET_STEP_COMPLETED, payload: { no: 2 } })
  yield put({ type: deliverableFormConstants.ON_DELIVERABLE_TYPE_SUCCESS, payload: deliverablesData })
  payload.navigate('/config/project-taxonomy')
},
true)

export const getDeliverables = createSaga(
  function* getDeliverables(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const deliverableFormApi = yield getDeliverableTypeApi()
    const data = yield call(deliverableFormApi.request('getDeliverableTypeWithDeliverables'))

    yield put({ type: deliverableFormConstants.ON_DELIVERABLE_TYPE_SUCCESS, payload: data })
  },
  true,
  handleForbiddenError,
)

export const createDeliverableFiles = createSaga(function* createDeliverableFiles({
  payload: { files, deliverableData },
}: {
  payload: { deliverableData: $TSFixMe; files: { fileKey: string; fileType: string; file: File }[] }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  try {
    const genericApi = yield getGenericApi()

    const fileUrls: Record<string, string> = {}

    for (const { fileKey, fileType, file } of files) {
      yield call(genericApi.request('createFile', { body: file, query: { key: fileKey, isFileUnique: false } }))
      fileUrls[`${fileType}URL`] = fileKey
    }

    yield put(updateDeliverable({ ...deliverableData, ...fileUrls }))

    yield put(
      showWarningMessage({
        actionType: actionTypes.SUCCESS,
        action: deliverableData.origin === 'Add' ? CREATE_DELIVERABLE : UPDATE_DELIVERABLE,
        props: { longName: deliverableData.longName },
      }),
    )
  } catch (err) {
    const blankUrls: Record<string, string> = {}
    for (const { fileType } of files) {
      blankUrls[`${fileType}URL`] = ''
    }
    yield put({
      type: deliverableFormConstants.UPDATE_DELIVERABLE,
      payload: { ...deliverableData, ...blankUrls },
    })

    throw err
  }
},
true)

export const deleteDeliverableFile = createSaga(function* deleteDeliverableFile({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { fileKey, fileType, deliverableData } = payload
  const directory = yield select(getTenantId)

  const genericApi = yield getGenericApi()
  yield call(genericApi.request('deleteFile', { query: { key: fileKey, directory } }))

  yield put({
    type: deliverableFormConstants.UPDATE_DELIVERABLE,
    payload: { ...deliverableData, [`${fileType}URL`]: '' },
  })
})

export function* deliverableTypeWatcher() {
  yield all([
    takeLatest(deliverableFormConstants.UPDATE_DELIVERABLE_TYPES, updateDeliverableType),
    takeLatest(deliverableFormConstants.GET_DELIVERABLE_TYPES, getDeliverables),
    takeEvery(deliverableFormConstants.CREATE_DELIVERABLE_FILES, createDeliverableFiles),
    takeEvery(deliverableFormConstants.DELETE_DELIVERABLE_FILE, deleteDeliverableFile),
  ])
}

import { isEmpty } from 'lodash'

export function getConfirmTitle(dataToDelete: $TSFixMe) {
  const { keyProcesses, projects, tasks } = dataToDelete

  if (!isEmpty(keyProcesses) && isEmpty(projects) && isEmpty(tasks)) {
    return keyProcesses[0].name
  } else if (!isEmpty(projects) && isEmpty(tasks)) {
    return projects[0].name
  } else if (!isEmpty(tasks)) {
    return tasks[0].name
  }
}

export function isSimpleConfirm(dataToDelete: $TSFixMe) {
  const { keyProcesses, projects, tasks } = dataToDelete

  return keyProcesses?.length + projects?.length + tasks?.length === 1
}

import { call } from 'redux-saga/effects'

// Api
import { getCommunicationsApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const createRequest = createSaga(function* createRequest({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getCommunicationsApi()

  yield call(
    api.request('addRequest', {
      body: payload,
    }),
  )
},
true)

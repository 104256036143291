import styled from 'styled-components'
import { Button, Colors } from '@blueprintjs/core'
import { Select2 } from '@blueprintjs/select'
import { FONT_FAMILY, greyForBtn, greyIconColor, white, black } from '@imo/imo-ui-toolkit'

export const StyledSelect = styled(Select2)`
  font: 13px ${FONT_FAMILY} !important;
  color: ${white} !important;
  width: 100%;
  ${(props) => (props as $TSFixMe).style}

  .bp4-popover-target {
    height: 100%;
    width: 100%;
  }
`

export const SelectorButton = styled(Button)<$TSFixMe>`
  height: 32px;
  padding: 0 16px 0 8px;
  box-sizing: border-box;
  border-radius: 3px;
  color: ${(props) => (!!props.isSelected ? black : `${greyIconColor} !important`)};
  position: relative;
  ${(props) => props.style}

  .bp4-button-text {
    font: 12px ${FONT_FAMILY};
    height: 30px;
    line-height: 30px;
    width: 100%;
  }

  .bp4-icon {
    color: ${greyForBtn};
    position: absolute;
    top: 6px;
    right: 8px;
    font-size: 14px;
  }

  &.danger {
    color: ${Colors.RED3};
    border: 1px solid ${Colors.RED3};
  }
`

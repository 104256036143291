import { put, call } from 'redux-saga/effects'
import { pick } from 'lodash/fp'
import * as actions from '../actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getTeamCharterApi } from '@common/net'

export const fetchInScopeProcesses = createSaga(function* fetchInScopeProcess({
  teamId,
  date,
}: {
  teamId: string
  date: string
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamCharterApi()

  const inScopeKeyProcesses = yield call(api.request('getInScopeKeyProcesses', { query: { teamId, date } }))

  yield put(actions.setInScopeKeyProcesses(inScopeKeyProcesses))
})

export const createInScopeKeyProcessData = createSaga(function* createInScopeKeyProcessData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data } = payload
  const api = yield getTeamCharterApi()
  const { id } = yield call(
    api.request('createInScopeKeyProcess', {
      query: { teamId: data.teamId },
      body: pick(['name'], data),
    }),
  )

  const newData = { ...data, id, isNew: false }
  yield put(actions.setUpdateInScopeKeyProcess({ data: newData, id: data.id }))
})

export const updateInScopeKeyProcessData = createSaga(function* updateInScopeKeyProcessData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data } = payload
  const api = yield getTeamCharterApi()
  yield call(
    api.request('updateInScopeKeyProcess', {
      query: { teamId: data.teamId, id: data.id },
      body: pick(['name'], data),
    }),
  )

  yield put(actions.setUpdateInScopeKeyProcess({ data, id: data.id }))
})

export const deleteInScopeKeyProcessData = createSaga(function* deleteInScopeKeyProcessData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { id, teamId } = payload
  const api = yield getTeamCharterApi()

  yield call(api.request('deleteInScopeKeyProcess', { query: { teamId, id } }))

  yield put(actions.deleteInScopeKeyProcessSuccess({ id }))
})

import { handleActions } from 'redux-actions'
import { first } from 'lodash/fp'
import * as constants from '@myImoConfigActions/teamDeliverables/actionTypes'
import { SET_STEP_COMPLETED } from '@domain/myImoConfig/actions/config/actionTypes'
import { buildIntegrationTeamsDataStructure, buildCentralTeamsDataStructure, updateDeliverable } from './utils'

export interface ITeamDeliverablesState {
  integrationTeams: $TSFixMe[]
  centralTeams: $TSFixMe[]
  integrationDeliverablesTemplate: $TSFixMe
  centralDeliverablesTemplate: $TSFixMe
  builtIntegrationTeams: $TSFixMe
  builtCentralTeams: $TSFixMe
}

const initialState: ITeamDeliverablesState = {
  integrationTeams: [],
  centralTeams: [],
  integrationDeliverablesTemplate: {},
  centralDeliverablesTemplate: {},
  builtIntegrationTeams: [],
  builtCentralTeams: [],
}

export const teamDeliverablesReducer = handleActions<ITeamDeliverablesState, $TSFixMe>(
  {
    [constants.SET_TEAM_DELIVERABLES]: (state, action) => {
      const { integrationTeams, centralTeams } = action.payload

      return {
        ...state,
        integrationTeams,
        centralTeams,
        centralDeliverablesTemplate: first(centralTeams) || {},
        integrationDeliverablesTemplate: first(integrationTeams) || {},
        builtIntegrationTeams: buildIntegrationTeamsDataStructure(integrationTeams),
        builtCentralTeams: buildCentralTeamsDataStructure(centralTeams),
      }
    },
    [constants.UPDATE_INTEGRATION_TEAM_DELIVERABLES]: (state, { payload }) => {
      const updatedIntegrationTeams = state.builtIntegrationTeams.map((item: $TSFixMe) => {
        return item.team === payload.team ? updateDeliverable(item, payload) : item
      })

      return { ...state, builtIntegrationTeams: updatedIntegrationTeams }
    },
    [constants.UPDATE_CENTRAL_TEAM_DELIVERABLES]: (state, { payload }) => {
      const updatedCentralTeam = state.builtCentralTeams.map((item: $TSFixMe) => {
        return item.team === payload.team ? updateDeliverable(item, payload) : item
      })

      return { ...state, builtCentralTeams: updatedCentralTeam }
    },
    [SET_STEP_COMPLETED]: (state) => {
      return {
        ...state,
        centralTeams: [],
        integrationTeams: [],
        builtIntegrationTeams: [],
        builtCentralTeams: [],
      }
    },
  },
  initialState,
)

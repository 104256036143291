import { call, put, select, takeLatest, all } from 'redux-saga/effects'
import { getMidCore } from '@generic/selectors'
import { logout, refreshToken, refreshTokenSuccess } from '../actions/actions'
import * as constants from '../actions/actionTypes'
import { delay } from 'redux-saga'

type MidCore = {
  logout: () => void
  refresh: () => void
  accessToken: () => string
}

const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000
const FIFTEEN_MINUTES_IN_MILLISECONDS = 15 * 60 * 1000

const getTokenPayload = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

const refreshTokenHandler = function* ({ mid, initial }: { mid: MidCore; initial: boolean }) {
  if (!mid) {
    throw new Error('Mid is not initialized.')
  }

  const accessToken = mid.accessToken()
  const payload = getTokenPayload(accessToken)

  if (!payload || !payload.exp || !payload.iat) {
    yield put(
      logout({
        isUserLogout: false,
        context: {
          reason: 'JWT',
          issuer: payload?.iss,
          issuedAt: payload?.iat,
          expiry: payload?.exp,
          currentTime: Date.now(),
          userAgent: window?.navigator?.userAgent,
        },
      }),
    )

    // Once we've logged out we don't want to continue
    return
  }

  const expiryTimeInSeconds = Number.parseInt(payload.exp)
  const expiryTimeInMilliseconds = expiryTimeInSeconds * 1000
  const initialRefreshTimeInMilliseconds = expiryTimeInMilliseconds - FIVE_MINUTES_IN_MILLISECONDS
  const tokenRefreshInterval = initial ? initialRefreshTimeInMilliseconds - Date.now() : FIFTEEN_MINUTES_IN_MILLISECONDS
  yield delay(tokenRefreshInterval)

  // Actually do the refresh, should result in new access token.
  yield call(mid.refresh)

  // Persist the new access token.
  sessionStorage.setItem('_mid-exp', `${expiryTimeInMilliseconds}`)
  document.cookie = `accessToken=${accessToken};path=/`

  // Some event to indicate success, with the token, if needed.
  yield put(refreshTokenSuccess({ token: accessToken }))

  // Refresh the access token again after some time.
  yield put(refreshToken())
}

export const startRefresher = function* (): Generator {
  const midCoreResult = yield select(getMidCore)
  const mid = midCoreResult as unknown as MidCore

  if (!mid || !mid.accessToken()) {
    throw new Error('Mid is not initialized or JWT is not present.')
  }

  // Start the refresher initially, and then await REFRESH_TOKEN action.
  yield all([
    call(refreshTokenHandler, { mid, initial: true }),
    takeLatest(constants.REFRESH_TOKEN, refreshTokenHandler, { mid, initial: false }),
  ])
}

import { put, call } from 'redux-saga/effects'
import { setMutualDiscoveryData } from '../actions/actions'
import { getMutualDiscoveryApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { transformMDPAData } from '../utils'

function* fetchMutualDiscoveryDataSaga(action: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = action.payload

  const api = yield getMutualDiscoveryApi()
  const data = yield call(
    api.request('getMutualDiscoveryData', {
      query: { teamId },
    }),
  )

  yield put(setMutualDiscoveryData(transformMDPAData(data)))
}

export const fetchMutualDiscoveryData = createSaga(fetchMutualDiscoveryDataSaga)

export const fetchMutualDiscoveryDataWithLoader = createSaga(fetchMutualDiscoveryDataSaga, true)

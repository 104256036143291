import { isArray, isEmpty, isNumber, get } from 'lodash'

export const isValidValue = (value) => (isArray(value) ? !isEmpty(value) : isNumber(value))

export const validateTeamsRequest = (data) => !Boolean(data.find((it) => !it.responsibleIMOId))

export const mapTeams = (teams = [], deliverableTypes = [], isIntegrationTeams = false) => {
  return teams.reduce((mappedData, team) => {
    const responsibleIMOId = get(team, 'hierarchy.responsibleImo.id', null)
    const secondaryIMOId = get(team, 'hierarchy.secondaryImo.id', null)
    const responsibleTeams = get(team, 'hierarchy.responsibleCentralTeams') || []

    const mappedTeamData = {
      teamId: team.id,
      active: team.active,
      responsibleIMOId,
      secondaryIMOId,
    }

    if (isIntegrationTeams) {
      mappedTeamData.deliverableTeamIds = responsibleTeams
        .map((deliverableTeam) => ({
          centralTeamId: deliverableTeam.centralTeamId,
          deliverableTypeId: deliverableTypes.find((type) => type.name === deliverableTeam.typeName).id,
        }))
        .filter((item) => item.centralTeamId)
    }

    return mappedData.concat(mappedTeamData)
  }, [])
}

export const mapTeamsHierarchyData = (data, deliverableTypes) => {
  const { integrationTeams, imoTeams, centralTeams } = data
  const imoTeamsRequestData = mapTeams(imoTeams, deliverableTypes)
  const centralTeamsRequestData = mapTeams(centralTeams, deliverableTypes)
  const integrationTeamsRequestData = mapTeams(integrationTeams, deliverableTypes, true)

  return [...imoTeamsRequestData, ...centralTeamsRequestData, ...integrationTeamsRequestData]
}

export const mapTeamsHierarchyResponse = (data) => {
  const { deliverableTypes, ...teamsData } = data

  return {
    ...teamsData,
    deliverableTypes: deliverableTypes.filter((type) => type.id !== 1),
  }
}

export const updateImoTeams = (imoTeamsFromState, teams) => {
  let teamChanged = false
  const updatedTeams = imoTeamsFromState.map((team) => {
    const isUsed = teams.some(
      (item) => item.active && get(item, 'hierarchy.responsibleImo.longName') === get(team, 'longName'),
    )

    if (isUsed && !team.active) {
      teamChanged = true
    }

    return { ...team, active: isUsed || team.active }
  })

  if (teamChanged) {
    return updateImoTeams(updatedTeams, updatedTeams)
  }

  return updatedTeams
}

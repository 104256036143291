import { synergyTypes } from '@helpers/constants'

export const defaultInitiativeFields = [
  'name',
  'companyId',
  'impact',
  'categoryId',
  'owners',
  'plannedL4Date',
  'plannedL5Date',
  'description',
  'comments',
  'confidential',
  'forecastL4Date',
  'forecastL5Date',
  'listId',
]

export const initiativeFields = {
  [synergyTypes.COST]: [...defaultInitiativeFields, 'FTE', 'oneTimeBenefit'],
  [synergyTypes.REVENUE]: [...defaultInitiativeFields, 'marginImpact', 'oneTimeBenefit'],
  [synergyTypes.NWC]: defaultInitiativeFields,
  [synergyTypes.ONE_TIME_COST]: defaultInitiativeFields,
}

export const INFINITE_SCROLL_LIMIT = 200

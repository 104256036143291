import { all, takeLatest, takeEvery } from 'redux-saga/effects'
import * as constants from '@dayOne/actions/actionTypes'

import { fetchDayOneProjectList, reFetchDayOneProjectListForSocket } from './fetchDayOneProjectList'
import { fetchAddLibraryButtonState, fetchLibraryProjectList } from './fetchLibraryProjectList'
import {
  launchInterdependency,
  cancelInterdependency,
  updateInterdependencyTask,
  updateInterdependencyTaskStatus,
} from './interdependencies'
import cudProjectListKeyProcessSagas from './byTypes/cudProjectListKeyProcess'
import cudProjectListProjectsSagas from './byTypes/cudProjectListProjects'
import cudProjectListTaskSagas from './byTypes/cudProjectListTask'
import { uploadProjectFile } from './byTypes/uploadProjectFile'
import { uploadTaskFile } from './byTypes/uploadTaskFile'
import { deleteProjectFile } from './byTypes/deleteProjectFile'
import { deleteTaskFile } from './byTypes/deleteTaskFile'
import { createProcessFromLib } from './createProcessFromLib'
import dayOneItemSaga from '@dayOne/sagas/cudDayOneProcessItem'
import dayOneItemFilesSaga from './cdDayOneProcessItemFiles'
import { fetchProgramProjectPlanData } from '@dayOne/sagas/programProjectPlanSaga'
import { fetchSnapshotMinDate } from '@dayOne/sagas/fetchSnapshotMinDate'
import { WS_DATA_REFETCH } from '@domain/realTime/actions/actionTypes'

export default function* dayOneSaga() {
  yield all([
    takeLatest(constants.FETCH_PROJECT_LIST, fetchDayOneProjectList),
    takeLatest(WS_DATA_REFETCH, reFetchDayOneProjectListForSocket),
    takeLatest(constants.FETCH_PROGRAM_PROJECT_PLAN_DATA, fetchProgramProjectPlanData),
    takeLatest(constants.FETCH_LIBRARY_PROJECT_LIST, fetchLibraryProjectList),
    takeLatest(constants.LAUNCH_INTERDEPENDENCY, launchInterdependency),
    takeLatest(constants.CANCEL_INTERDEPENDENCY, cancelInterdependency),
    takeLatest(constants.UPDATE_INTERDEPENDENCY_TASK, updateInterdependencyTask),
    takeLatest(constants.UPDATE_INTERDEPENDENCY_TASK_STATUS, updateInterdependencyTaskStatus),
    takeLatest(constants.FETCH_IMO_LINKED_LIBRARIES_STATE, fetchAddLibraryButtonState),
    takeLatest(constants.GET_SNAPSHOT_MIN_DATE, fetchSnapshotMinDate),

    takeEvery(constants.UPLOAD_PROJECT_FILE, uploadProjectFile),
    takeEvery(constants.UPLOAD_TASK_FILE, uploadTaskFile),
    takeEvery(constants.DELETE_PROJECT_FILE, deleteProjectFile),
    takeEvery(constants.DELETE_TASK_FILE, deleteTaskFile),

    ...dayOneItemFilesSaga,

    takeLatest(constants.CREATE_PROCESS_FROM_LIB, createProcessFromLib),

    ...cudProjectListKeyProcessSagas,
    ...cudProjectListTaskSagas,
    ...cudProjectListProjectsSagas,
    ...dayOneItemSaga,
  ])
}

export const TeamTypeId = {
  GLOBAL_IMO: 1,
  IMO: 2,
  CENTRAL: 3,
  INTEGRATION: 4,
}

export const AuthDrivers = {
  OKTA: 'okta',
}

import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'
import { assoc } from 'lodash/fp'

export interface IPipelineByTeamState {
  pipelineByIntegrationTeam: {
    labels: $TSFixMe[]
    runRates: $TSFixMe[]
    total: $TSFixMe[]
  }
  pipelineByYearOfExecution: {
    labels: $TSFixMe[]
    runRates: $TSFixMe[]
    targets: $TSFixMe[]
    teams: $TSFixMe
  }
}

export const initialState = {
  pipelineByIntegrationTeam: {
    labels: [],
    runRates: [],
    total: [],
  },
  pipelineByYearOfExecution: {
    labels: [],
    runRates: [],
    targets: [],
    teams: {},
  },
}

const pipelineByTeamReducer = handleActions(
  {
    [constants.SET_PIPELINE_BY_INTEGRATION_TEAM_DATA]: (state, action) => {
      return assoc('pipelineByIntegrationTeam', action.payload, state)
    },
    [constants.SET_PIPELINE_BY_YEAR_OF_EXECUTION_DATA]: (state, action) => {
      return assoc('pipelineByYearOfExecution', action.payload, state)
    },
  },
  initialState,
)

export default pipelineByTeamReducer

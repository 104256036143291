import { handleActions } from 'redux-actions'
import * as constants from '@valueCaptureTrackerV2/summaryStatus/actions/actionTypes'

const initialState = [] as $TSFixMe[]

const summaryStatusReducer = handleActions(
  {
    [constants.SET_SUMMARY_STATUS]: (state, { payload }) => {
      return [...payload]
    },

    [constants.CLEAR_SUMMARY_STATUS]: () => {
      return [...initialState]
    },
  },
  initialState,
)

export default summaryStatusReducer

import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'
import { loadingState } from '@helpers/constants'

export interface IMonthByMonthState {
  headcountReduction: $TSFixMe[]
  headcountReductionDataState: string
  oneTimeImplementationCosts: $TSFixMe[]
  monthlyPipelines: $TSFixMe[]
  monthlyActual: $TSFixMe[]
  monthlyPipelineLoadingState: string
  oneTimeCostsLoadingState: string
  deltaTrackerData: $TSFixMe[]
  monthlyActualsV2: $TSFixMe[]
  monthlyPipelinesV2: $TSFixMe[]
  deltaTrackerV2Data: $TSFixMe[]
}

export const initialState = {
  headcountReduction: [],
  headcountReductionDataState: loadingState.INITIAL,
  oneTimeImplementationCosts: [],
  monthlyPipelines: [],
  monthlyActual: [],
  monthlyPipelineLoadingState: loadingState.INITIAL,
  oneTimeCostsLoadingState: loadingState.INITIAL,
  deltaTrackerData: [],
  monthlyActualsV2: [],
  monthlyPipelinesV2: [],
  monthlyActualV2LoadingState: loadingState.INITIAL,
  monthlyPipelinesV2LoadingState: loadingState.INITIAL,
  deltaTrackerV2Data: [],
}

const monthByMonthReducer = handleActions<IMonthByMonthState, $TSFixMe>(
  {
    [constants.SET_HEADCOUNT_REDUCTION]: (state, action) => {
      const { headcountReduction } = action.payload

      return {
        ...state,
        headcountReduction,
      }
    },

    [constants.SET_ONE_TIME_IMPLEMENTATION_COSTS]: (state, action) => {
      const { oneTimeImplementationCosts } = action.payload

      return {
        ...state,
        oneTimeImplementationCosts,
      }
    },

    [constants.SET_MONTHLY_PIPELINE_STATUS]: (state, action) => {
      const { monthlyPipelines } = action.payload

      return {
        ...state,
        monthlyPipelines: monthlyPipelines.pipelines,
        monthlyActual: monthlyPipelines.actual,
      }
    },
    [constants.SET_MONTHLY_PIPELINE_LOADING_STATE]: (state, { payload }) => ({
      ...state,
      monthlyPipelineLoadingState: payload,
    }),
    [constants.SET_HEADCOUNT_REDUCTION_DATA_STATE]: (state, { payload }) => ({
      ...state,
      headcountReductionDataState: payload,
    }),
    [constants.SET_ONE_TIME_IMPLEMENTATION_COSTS_LOADING_STATE]: (state, { payload }) => ({
      ...state,
      oneTimeCostsLoadingState: payload,
    }),
    [constants.FETCH_DELTA_TRACKER_DATA]: (state) => ({
      ...state,
      deltaTrackerData: [],
    }),
    [constants.SET_DELTA_TRACKER_DATA]: (state, { payload }) => ({
      ...state,
      deltaTrackerData: payload,
    }),
    [constants.SET_MONTHLY_ACTUALS_STATUS_V2]: (state, { payload }) => ({
      ...state,
      monthlyActualsV2: payload,
    }),
    [constants.SET_MONTHLY_PIPELINE_STATUS_V2]: (state, { payload }) => ({
      ...state,
      monthlyPipelinesV2: payload,
    }),
    [constants.SET_MONTHLY_ACTUALS_STATUS_V2_LOADING_STATE]: (state, { payload }) => ({
      ...state,
      monthlyActualV2LoadingState: payload,
    }),
    [constants.SET_MONTHLY_PIPELINE_STATUS_V2_LOADING_STATE]: (state, { payload }) => ({
      ...state,
      monthlyPipelinesV2LoadingState: payload,
    }),
    [constants.FETCH_DELTA_TRACKER_V2_DATA]: (state) => ({
      ...state,
      deltaTrackerV2Data: [],
    }),
    [constants.SET_DELTA_TRACKER_V2_DATA]: (state, { payload }) => ({
      ...state,
      deltaTrackerV2Data: payload,
    }),
  },
  initialState,
)

export default monthByMonthReducer

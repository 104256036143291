import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  getUserRolesInfo,
  getUserGlobalPermissionState,
  getUserTeamRelationsInfo,
} from '@generic/selectors/permissions'
import { userInfo as getUserInfo } from '@generic/selectors'
import { applyStrategy } from '@common/accessController/hooks/utils'
import { getGroupedTeamRoles } from '@generic/selectors/getters'

export type PermissionsByRoleStrategyFactory = (...args: unknown[]) => $TSFixMe
export type PermissionsCheckerOpts = {
  teamId?: number | null
  hierarchyLevel?: unknown | null
  deliverableId?: number | null
  deliverableTypeId?: number | null
  fromTeam?: unknown | null
}

export default function usePermissionsChecker(
  getPermissionsByRoleStrategy: PermissionsByRoleStrategyFactory,
  { teamId, hierarchyLevel, deliverableId, deliverableTypeId, fromTeam }: PermissionsCheckerOpts = {},
) {
  const normalizedTeamId = teamId ? Number(teamId) : teamId
  const userRolesInfo = useSelector(getUserRolesInfo)
  const userRolesMetaInfo = useSelector(getUserGlobalPermissionState)
  const userTeamRelationsInfo = useSelector(getUserTeamRelationsInfo)

  const userInfo = useSelector(getUserInfo)

  const userTeamRolesInfo = useMemo(
    () =>
      getGroupedTeamRoles({
        userTeamRelationsInfo,
        relationParams: { hierarchyLevel, deliverableId, deliverableTypeId, fromTeam, teamId: normalizedTeamId },
      }),
    [userTeamRelationsInfo, normalizedTeamId, hierarchyLevel, deliverableId, deliverableTypeId, fromTeam],
  )

  const checkPermission = useCallback(
    applyStrategy({ userInfo, userRolesInfo, userTeamRolesInfo, userRolesMetaInfo, getPermissionsByRoleStrategy }),
    [
      teamId,
      hierarchyLevel,
      deliverableId,
      getPermissionsByRoleStrategy,
      userRolesInfo,
      userTeamRolesInfo,
      userRolesMetaInfo,
    ],
  )

  return {
    checkPermission,
    userRolesMetaInfo,
    userRolesInfo,
    userTeamRolesInfo,
    userInfo,
  }
}

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading, getMetaWithIgnoreLoading } from '@common/sagaCreator/utils'
import { listItemTypes } from '@shared/DayOne/constants'
import { GET_USERS } from '@myImoConfigActions/users/actionTypes'

export const fetchProgramProjectPlanData = createAction(constants.FETCH_PROGRAM_PROJECT_PLAN_DATA)
export const fetchProgramProjectPlanDataWithLoader = createAction(
  constants.FETCH_PROGRAM_PROJECT_PLAN_DATA,
  null,
  getMetaWithActionLoading,
)

export const setProjectListFilter = createAction(constants.SET_PROJECT_LIST_FILTER)

export const fetchProjectList = createAction(constants.FETCH_PROJECT_LIST)
export const fetchProjectListWithLoader = createAction(constants.FETCH_PROJECT_LIST, null, getMetaWithActionLoading)
export const fetchStepSevenUsers = createAction(GET_USERS, null, getMetaWithActionLoading)
export const setProjectList = createAction(constants.SET_PROJECT_LIST)

export const fetchLibraryProjectList = createAction(
  constants.FETCH_LIBRARY_PROJECT_LIST,
  null,
  getMetaWithActionLoading,
)
export const setLibraryProjectList = createAction(constants.SET_LIBRARY_PROJECT_LIST)
export const updateLibraryProjectList = createAction(constants.UPDATE_LIBRARY_PROJECT_LIST)

export const createProcessFromLib = createAction(constants.CREATE_PROCESS_FROM_LIB)

export const setSelectedProcessId = createAction(constants.SET_SELECTED_PROCESS_ID)

export const setVisibleRowId = createAction(constants.SET_VISIBLE_ROW_ID)

export const createKeyProcess = createAction(constants.CREATE_KEY_PROCESS)
export const updateKeyProcess = createAction(constants.UPDATE_KEY_PROCESS)
export const updateKeyProcessSilent = createAction(constants.UPDATE_KEY_PROCESS, null, getMetaWithIgnoreLoading)
export const deleteKeyProcess = createAction(constants.DELETE_KEY_PROCESS)

export const createProject = createAction(constants.CREATE_PROJECT)
export const updateProject = createAction(constants.UPDATE_PROJECT)
export const updateProjectSilent = createAction(constants.UPDATE_PROJECT, null, getMetaWithIgnoreLoading)
export const deleteProject = createAction(constants.DELETE_PROJECT)

export const createTask = createAction(constants.CREATE_TASK)
export const updateTask = createAction(constants.UPDATE_TASK)
export const updateTaskSilent = createAction(constants.UPDATE_TASK, null, getMetaWithIgnoreLoading)
export const deleteTask = createAction(constants.DELETE_TASK)

export const updateDayOneItemSuccess = createAction(constants.UPDATE_DAY_ONE_ITEM_SUCCESS)

export const updatePredecessorFollowerSuccess = createAction(constants.UPDATE_PREDECESSOR_FOLLOWER_SUCCESS)

export const updateDayOneItemIDSuccess = createAction(constants.UPDATE_DAY_ONE_ITEM_ID_SUCCESS)
export const updateDayOneItemID = createAction(constants.UPDATE_DAY_ONE_ITEM_ID)

export const updateDayOneProcessItem = createAction(constants.UPDATE_DAY_ONE_PROCESS_ITEM)
export const createDayOneProcessItem = createAction(constants.CREATE_DAY_ONE_PROCESS_ITEM)
export const deleteDayOneProcessItem = createAction(constants.DELETE_DAY_ONE_PROCESS_ITEM)

export const deleteDayOneItemsList = createAction(constants.DELETE_DAY_ONE_ITEMS_LIST)

export const addListItem = createAction(constants.ADD_LIST_ITEM)
export const removeListItem = createAction(constants.REMOVE_LIST_ITEM)

export const deleteDayOneProcessItemFile = createAction(constants.DELETE_DAY_ONE_PROCESS_ITEM_FILE)
export const uploadDayOneProcessItemFile = createAction(constants.UPLOAD_DAY_ONE_PROCESS_ITEM_FILE)

export const deleteDayOneProcessItemFileSuccess = createAction(constants.DELETE_DAY_ONE_PROCESS_ITEM_FILE_SUCCESS)
export const uploadDayOneProcessItemFileSuccess = createAction(constants.UPLOAD_DAY_ONE_PROCESS_ITEM_FILE_SUCCESS)

export const uploadProjectFile = createAction(constants.UPLOAD_PROJECT_FILE)
export const uploadTaskFile = createAction(constants.UPLOAD_TASK_FILE)

export const deleteProjectFile = createAction(constants.DELETE_PROJECT_FILE)
export const deleteTaskFile = createAction(constants.DELETE_TASK_FILE)

// INTERDEPENDENCIES ACTIONS
export const launchInterdependency = createAction(constants.LAUNCH_INTERDEPENDENCY)
export const launchInterdependencySuccess = createAction(constants.LAUNCH_INTERDEPENDENCY_SUCCESS)

export const cancelInterdependency = createAction(constants.CANCEL_INTERDEPENDENCY)
export const cancelInterdependencySuccess = createAction(constants.CANCEL_INTERDEPENDENCY_SUCCESS)

export const updateInterdependencyTask = createAction(constants.UPDATE_INTERDEPENDENCY_TASK)
export const updateInterdependencyTaskSuccess = createAction(constants.UPDATE_INTERDEPENDENCY_TASK_SUCCESS)

export const updateInterdependencyTaskStatus = createAction(constants.UPDATE_INTERDEPENDENCY_TASK_STATUS)

//PROJECT MIRRORING ACTIONS
export const deleteProjectMirroringTeam = createAction(constants.DELETE_PROJECT_MIRRORING_TEAM)
export const deleteAllProjectMirroringTeams = createAction(constants.DELETE_ALL_PROJECT_MIRRORING_TEAMS)

export const getSnapshotMinDate = createAction(constants.GET_SNAPSHOT_MIN_DATE)
export const setSnapshotMinDate = createAction(constants.SET_SNAPSHOT_MIN_DATE)

export const createActionMap = {
  [listItemTypes.KEY_PROCESS]: createKeyProcess,
  [listItemTypes.PROJECT]: createProject,
  [listItemTypes.TASK]: createTask,
}

export const deleteActionMap = {
  [listItemTypes.KEY_PROCESS]: deleteKeyProcess,
  [listItemTypes.PROJECT]: deleteProject,
  [listItemTypes.TASK]: deleteTask,
}

export const updateActionMap = {
  [listItemTypes.KEY_PROCESS]: updateKeyProcess,
  [listItemTypes.PROJECT]: updateProject,
  [listItemTypes.TASK]: updateTask,
}

export const silentUpdateActionMap = {
  [listItemTypes.KEY_PROCESS]: updateKeyProcessSilent,
  [listItemTypes.PROJECT]: updateProjectSilent,
  [listItemTypes.TASK]: updateTaskSilent,
}

export const fetchIMOLinkedLibrariesState = createAction(constants.FETCH_IMO_LINKED_LIBRARIES_STATE)
export const setIMOLinkedLibrariesState = createAction(constants.SET_IMO_LINKED_LIBRARIES_STATE)

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTeamsList } from '@generic/actions/actions'
import MainContent from '@shared/MainContentContainer'
import { getIsFirstTimeUser } from '@generic/selectors'
import { Outlet } from 'react-router-dom'

export const ImoConfigLayout = () => {
  const dispatch = useDispatch()
  const isFirstTimeUser = useSelector(getIsFirstTimeUser)

  useEffect(() => {
    if (!isFirstTimeUser) {
      dispatch(fetchTeamsList())
    }
  }, [isFirstTimeUser])

  return (
    <MainContent>
      <Outlet />
    </MainContent>
  )
}

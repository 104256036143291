import { handleActions } from 'redux-actions'
import * as constants from '@teamResources/statusReport/actions/actionTypes'

export interface IStatusReportState {
  statusReport: $TSFixMe[]
}

export const initialState = {
  statusReport: [],
}

const statusReportReducer = handleActions<IStatusReportState, $TSFixMe>(
  {
    [constants.SET_STATUS_REPORT_DATA]: (state, action) => ({
      ...state,
      statusReport: action.payload,
    }),
  },
  initialState,
)

export default statusReportReducer

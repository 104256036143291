export const USER_ROLES_HIERARCHY_LEVELS = 3

export const ADMINS = 'Admins'

export const unsavedUsersTooltip = 'These actions are not available for unsaved users'

export const companyUsersTooltip = 'These actions are not available for McKinsey users'

export const inactiveUsersTooltip = 'These actions are not available for inactive users'

export const LAST_LOGIN = 'lastLogin'

export const privilegesEditorTitle = 'Teams and privileges'

export const unlockConfirmationText =
  'Are you sure you wish to unlock the users list for editing? Once you unlock ' +
  'the table, no other users will be able to edit the users list until you lock the table and save all changes.'

export const getLockedButtonTooltip = (user) =>
  `The users list is currently being edited by ${user} and cannot be unlocked until that user locks the table and saves all changes.`

export const unlockPopupConstants = {
  text: 'Select an option below to unlock the table for read-only access or for editing. Once you unlock the table, no other users will be able to edit the users list until you lock the table and save all changes.',
  title: 'This table is locked',
  headIcon: 'lock',
  buttonIcon: 'unlock',
  buttonText: 'Unlock',
}

export const disabledToggleTooltip = 'To activate user, please fill all required fields and assign user role'

export const validationError = 'Please check all fields marked in red'

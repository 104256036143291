import { get, getOr, toLower, last, sortBy, flow, some, isNil } from 'lodash/fp'
import { createSelector } from 'reselect'
import { getYearList, getAllTeams } from '@generic/selectors'
import {
  getCalendarViewSelect,
  getDayOneDate,
  getFiscalYear,
  getInitiativeStatusDate,
} from '@domain/instanceConfig/selectors'
import { INITIATIVE_STATUS_BASED_ON } from '@helpers/moduleOptions'
import { formattedCategories, setupFinancialRows, getTransformedTeamRoles } from './utils'
import { IInitiativeListState, InitiativesColumnsCustomNames } from '../reducers/reducer'
import { CalendarViewOptions } from '@helpers/constants'

export const clientState = get(['@teamValueCaptureV2', 'initiativeList'])
export const getInitiativeList = flow(clientState, get('initiativeList'))
export const getTeamId = flow(clientState, get('teamId'))
export const getSynergyType = flow(clientState, get('synergyType'))
export const getInitiativeListLength = flow(clientState, get('initiativeListLength'))
export const getInitiativeFilters = flow(clientState, get('initiativeFilters'))
export const getSortedFilters = createSelector(getInitiativeFilters, (filters) => {
  if (!filters) {
    return null
  }
  return {
    ...filters,
    specialAttributes: filters.specialAttributes ? [...filters.specialAttributes].sort() : [],
  }
})
export const getInitiativeDeliverableInfo = flow(clientState, get('deliverableInfo'))
export const getIsAllInitiative = flow(clientState, get('isAllInitiative'))
export const getInitiativeColumnsCustomNames: (state: IInitiativeListState) => InitiativesColumnsCustomNames = flow(
  clientState,
  get('initiativeColumnsCustomNames'),
)
export const getCategories = flow(clientState, get('categories'))
export const getAttachments = flow(clientState, get('attachments'))

// stage gate
export const getInitiativeCommentsList = flow(clientState, get('initiativeCommentsList'))
export const getStageGateValidationStatus = flow(clientState, get('stageGateValidationStatus'))
export const getTransformedComments = createSelector(getInitiativeCommentsList, (commentsList) => {
  return commentsList.map((comment: $TSFixMe) => ({
    ...comment,
    text: comment?.comment,
    action: toLower(comment?.actionType),
    createdDate: comment?.createdAt,
    sender: {
      name: comment?.userName,
      role: comment?.highestRole,
      teams: getTransformedTeamRoles(comment?.userRoles),
    },
  }))
})

export const checkNewInitiativesInList = createSelector(getInitiativeList, some(get('isNew')))
export const getStatusLockedTabs = flow(clientState, get('lockedTabs'))
export const getSelectedInitiativeId = flow(clientState, get('selectedInitiativeId'))
export const getFinancialsData = flow(clientState, get('financialsData'))
export const getFinancialsDataLoading = flow(clientState, get('financialsDataLoading'))
export const getSelectedInitiativeById = createSelector(
  getInitiativeList,
  getSelectedInitiativeId,
  (list, selectedInitiativeId) => list.find((item: $TSFixMe) => item.id === selectedInitiativeId) || {},
)

export const getFinancialsDataRows = createSelector(getFinancialsData, setupFinancialRows)
export const getValuesForValidation = createSelector(
  getYearList,
  getSelectedInitiativeById,
  getFinancialsDataRows,
  getInitiativeStatusDate,
  getFiscalYear,
  getCalendarViewSelect,
  getDayOneDate,
  (yearList, selectedInitiative, rows, statusBasedOn, fiscalYear, calendarView, dayOneDate) => {
    const lastYear = Number(last(yearList) ?? new Date(dayOneDate).getFullYear())
    let endDateYear = lastYear

    if (
      (calendarView === CalendarViewOptions.FiscalYear && fiscalYear && Number(fiscalYear) > 0) ||
      (calendarView === CalendarViewOptions.Year1 && new Date(dayOneDate).getMonth() > 0)
    ) {
      endDateYear += 1
    }

    if (!selectedInitiative)
      return {
        lastYear, // Corresponds to the year of the financials object (e.g. 2027 for year 2027/8)
        endDateYear, // Corresponds to the calendar year the financials end (e.g. 2028 for year 2027/8)
      }

    const lastRow = last(rows) as $TSFixMe

    return {
      selectedId: selectedInitiative.id,
      lastYear,
      endDateYear,
      lastFinancialsCellValue: lastRow[lastYear],
      subtype1: lastRow.subtype1,
      subtype2: lastRow.subtype2,
      subtype3: lastRow.subtype3,
      impact: selectedInitiative.impact,
      FTE: selectedInitiative.FTE,
      isStatusBasedOnForecast: statusBasedOn === INITIATIVE_STATUS_BASED_ON.FORECAST_DATE,
    }
  },
)

export const getInitiativesCategories = flow(clientState, get('initiativesCategories'))
export const getFormattedInitiativesCategoriesByType = createSelector(
  getInitiativesCategories,
  (_: unknown, synergyType: $TSFixMe) => synergyType,
  formattedCategories,
)

export const hasLinkedAttachments = createSelector(getInitiativeList, (list) => {
  return list.some(getOr(null, ['attachments', 'length']))
})

export const hasLinkedAttachmentsInfinityList = createSelector(clientState, get(['hasAttachments']))

export const getActiveCostFilters = flow(clientState, get('activeCostFilters'))

export const isOnlyDefaultLinesPresent = createSelector(getSelectedInitiativeById, (selectedInitiative) =>
  isNil(selectedInitiative.hasOnlyDefaultSublines) ? true : selectedInitiative.hasOnlyDefaultSublines,
)

export const isAdvancedFinancialsSet = createSelector(
  getSelectedInitiativeById,
  (selectedInitiative) => selectedInitiative.hasSublines,
)

export const getNetImpactItems = flow(clientState, get(['subLinesMirroring', 'netImpactItems']))
export const getMirroredInitiatives = flow(clientState, get(['subLinesMirroring', 'mirroredInitiatives']))
export const getMirrorEditLoadingState = flow(clientState, get('mirrorEditLoadingState'))
const getMirroredInitiativeState = createSelector(getSelectedInitiativeById, (selectedInitiative) => {
  if (!selectedInitiative.mirroredInitiatives) return {}

  return selectedInitiative.mirroredInitiatives.reduce((acc: $TSFixMe, item: $TSFixMe) => {
    acc[item.teamId] = item

    return acc
  }, {})
})

export const getInitiativeFetchParams = createSelector(clientState, get(['initiativeFetchParams']))
export const getFilters = createSelector(clientState, getOr({}, ['initiativeFetchParams', 'filters']))
export const getSorting = createSelector(clientState, getOr([], ['initiativeFetchParams', 'sorting']))

export const getTeamsForInitiatives = createSelector(
  getAllTeams,
  (_: unknown, options: $TSFixMe) => options,
  getMirroredInitiativeState,
  (teams: $TSFixMe, options: $TSFixMe, mirrorTeamsShape: $TSFixMe) => {
    const { synergyType, teamId } = options

    return sortBy(
      ({ longName }) => longName.toLowerCase(),
      teams.reduce((acc: $TSFixMe, team: $TSFixMe) => {
        if (!team.valueCaptureTargetSetup || !team.active || Number(teamId) === Number(team.id)) return acc

        acc.push({
          id: team.id,
          longName: team.longName,
          disabled: !team.valueCaptureTargetSetup[synergyType],
          selected: Boolean(mirrorTeamsShape[team.id]),
          teamType: team.teamType,
        })

        return acc
      }, []),
    )
  },
)

export const getLockStateForResetButton = createSelector(
  getSelectedInitiativeById,
  (_: unknown, options: $TSFixMe) => options,
  (initiative: $TSFixMe, options: $TSFixMe) => {
    const { lockResetButtonColumnId, optionToLock } = options

    const customColumn = initiative.customColumns?.find(
      ({ id }: $TSFixMe) => !isNil(lockResetButtonColumnId) && id.toString() === lockResetButtonColumnId,
    )

    return customColumn?.option?.name?.toLowerCase() === optionToLock.toLowerCase()
  },
)

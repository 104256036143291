import { TeamKeyProcessProjectOrTask } from '@common/types/dtos/DayOneProjectListDto'

export type ParentMap = Map<string, { parentId: null | number; item: TeamKeyProcessProjectOrTask }>

const doesIdExist = (id: number | null | undefined): id is number => {
  return id !== null && id !== undefined
}

const isControlTowerMirroredProject = (keyProcessId: number | undefined) => {
  return keyProcessId !== undefined && keyProcessId > 0
}

const getKey = (item: Partial<TeamKeyProcessProjectOrTask>): string => {
  if (item.type === 'task' && item.isIncoming && !item.mirror) {
    return `incoming-interdependency-${item.id}`
  } else if (item.type === 'task' && item.isOutgoing && !item.mirror) {
    return `outgoing-interdependency-${item.id}`
  } else if (item.mirror) {
    if (item.type === 'keyProcess' && isControlTowerMirroredProject(item.keyProcessId)) {
      return `mirrored-${item.type}-${item.teamId}`
    } else if (item.type === 'keyProcess') {
      return `mirrored-${item.type}`
    } else {
      return `mirrored-${item.type}-${item.id}-${item.teamId}`
    }
  }
  return `${item.type}-${item.id}`
}

/** Work out the parent type of a given node */
const ParentTypeKey = { keyProcess: null, task: 'project', project: 'keyProcess' } as const

/**
  Returns a map of the all the nodes and their parents to quickly look up the parents of any node
**/
export const getParentMap = (data: TeamKeyProcessProjectOrTask[]): ParentMap => {
  const parentMap: ParentMap = new Map()
  for (const item of data) {
    const key = getKey(item)

    if (item.type === 'keyProcess') {
      parentMap.set(key, { parentId: null, item })
    } else if (item.type === 'project') {
      parentMap.set(key, { parentId: doesIdExist(item.keyProcessId) ? item.keyProcessId : null, item })
    } else if (item.type === 'task') {
      parentMap.set(key, { parentId: doesIdExist(item.projectId) ? item.projectId : null, item })
    }
  }
  return parentMap
}

/**
  Given a map of parent nodes, figures out and returns the parent node for a given node
**/
export function getParentNode(node: TeamKeyProcessProjectOrTask, parentMap: ParentMap) {
  // Get parent node from the map
  let parentNode = null
  if (node.type !== 'keyProcess') {
    const mapNode = parentMap.get(getKey(node))
    if (mapNode && doesIdExist(mapNode.parentId)) {
      const parentType = ParentTypeKey[node.type]
      const parentId = mapNode.parentId as number
      const parentKey = getKey({
        id: parentId,
        type: parentType,
        teamId: node.teamId,
        mirror: node.mirror,
        keyProcessId: node.keyProcessId,
      })
      const parentMapNode = parentMap.get(parentKey)

      // Is is possible for they key to exist,
      // but not the item so we need to check here
      if (parentMapNode && parentMapNode.item) {
        parentNode = parentMapNode.item
      }
    }
  }

  return parentNode
}

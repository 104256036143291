const prefix = '@teamCharter/'

export const FETCH_TEAM_CHARTER_DATA = `${prefix}FETCH_TEAM_CHARTER_DATA`
export const REFETCH_TEAM_CHARTER_DATA = `${prefix}REFETCH_TEAM_CHARTER_DATA`
export const SET_TEAM_CHARTER_DATA = `${prefix}SET_TEAM_CHARTER_DATA`
export const UPDATE_TEAM_CHARTER_DATA = `${prefix}UPDATE_TEAM_CHARTER_DATA`

export const SET_IN_SCOPE_KEY_PROCESSES = `${prefix}SET_IN_SCOPE_KEY_PROCESSES`
export const CREATE_IN_SCOPE_KEY_PROCESS_SUCCESS = `${prefix}CREATE_IN_SCOPE_KEY_PROCESS_SUCCESS`
export const CREATE_IN_SCOPE_KEY_PROCESS_PENDING = `${prefix}CREATE_IN_SCOPE_KEY_PROCESS_PENDING`
export const SET_UPDATE_IN_SCOPE_KEY_PROCESS = `${prefix}SET_UPDATE_IN_SCOPE_KEY_PROCESS`
export const UPDATE_IN_SCOPE_KEY_PROCESS_PENDING = `${prefix}UPDATE_IN_SCOPE_KEY_PROCESS_PENDING`
export const DELETE_IN_SCOPE_KEY_PROCESS_SUCCESS = `${prefix}DELETE_IN_SCOPE_KEY_PROCESS_SUCCESS`
export const DELETE_IN_SCOPE_KEY_PROCESS_PENDING = `${prefix}DELETE_IN_SCOPE_KEY_PROCESS_PENDING`

export const SET_OUT_SCOPE_KEY_PROCESSES = `${prefix}SET_OUT_SCOPE_KEY_PROCESSES`
export const ADD_OUT_SCOPE_KEY_PROCESS = `${prefix}ADD_OUT_SCOPE_KEY_PROCESS`
export const CREATE_OUT_SCOPE_KEY_PROCESS = `${prefix}CREATE_OUT_SCOPE_KEY_PROCESS`
export const SET_UPDATE_OUT_SCOPE_KEY_PROCESS = `${prefix}SET_UPDATE_OUT_SCOPE_KEY_PROCESS`
export const UPDATE_OUT_SCOPE_KEY_PROCESS = `${prefix}UPDATE_OUT_SCOPE_KEY_PROCESS`
export const REMOVE_OUT_SCOPE_KEY_PROCESS = `${prefix}REMOVE_OUT_SCOPE_KEY_PROCESS`
export const DELETE_OUT_SCOPE_KEY_PROCESS = `${prefix}DELETE_OUT_SCOPE_KEY_PROCESS`

export const SET_MACRO_PLANNING_ASSUMPTION = `${prefix}SET_MACRO_PLANNING_ASSUMPTION`
export const ADD_MACRO_PLANNING_ASSUMPTION = `${prefix}ADD_MACRO_PLANNING_ASSUMPTION`
export const CREATE_MACRO_PLANNING_ASSUMPTION = `${prefix}CREATE_MACRO_PLANNING_ASSUMPTION`
export const SET_UPDATE_MACRO_PLANNING_ASSUMPTION = `${prefix}SET_UPDATE_MACRO_PLANNING_ASSUMPTION`
export const UPDATE_MACRO_PLANNING_ASSUMPTION = `${prefix}UPDATE_MACRO_PLANNING_ASSUMPTION`
export const REMOVE_MACRO_PLANNING_ASSUMPTION = `${prefix}REMOVE_MACRO_PLANNING_ASSUMPTION`
export const DELETE_MACRO_PLANNING_ASSUMPTION = `${prefix}DELETE_MACRO_PLANNING_ASSUMPTION`

export const SET_KEY_INTERDEPENDENCIES = `${prefix}SET_KEY_INTERDEPENDENCIES`
export const ADD_KEY_INTERDEPENDENCY = `${prefix}ADD_KEY_INTERDEPENDENCY`
export const CREATE_KEY_INTERDEPENDENCY = `${prefix}CREATE_KEY_INTERDEPENDENCY`
export const UPDATE_KEY_INTERDEPENDENCY = `${prefix}UPDATE_KEY_INTERDEPENDENCY`
export const SET_UPDATE_KEY_INTERDEPENDENCY = `${prefix}SET_UPDATE_KEY_INTERDEPENDENCY`
export const REMOVE_KEY_INTERDEPENDENCY = `${prefix}REMOVE_KEY_INTERDEPENDENCY`
export const DELETE_KEY_INTERDEPENDENCY = `${prefix}DELETE_KEY_INTERDEPENDENCY`

export const SET_KEY_RISK_TO_MITIGATE = `${prefix}SET_KEY_RISK_TO_MITIGATE`
export const ADD_KEY_RISK_TO_MITIGATE = `${prefix}ADD_KEY_RISK_TO_MITIGATE`
export const CREATE_KEY_RISK_TO_MITIGATE = `${prefix}CREATE_KEY_RISK_TO_MITIGATE`
export const SET_UPDATE_KEY_RISK_TO_MITIGATE = `${prefix}SET_UPDATE_KEY_RISK_TO_MITIGATE`
export const UPDATE_KEY_RISK_TO_MITIGATE = `${prefix}UPDATE_KEY_RISK_TO_MITIGATE`
export const REMOVE_KEY_RISK_TO_MITIGATE = `${prefix}REMOVE_KEY_RISK_TO_MITIGATE`
export const DELETE_KEY_RISK_TO_MITIGATE = `${prefix}DELETE_KEY_RISK_TO_MITIGATE`

export const SET_KEY_DECISION_TO_MAKE = `${prefix}SET_KEY_DECISION_TO_MAKE`
export const ADD_KEY_DECISION_TO_MAKE = `${prefix}ADD_KEY_DECISION_TO_MAKE`
export const CREATE_KEY_DECISION_TO_MAKE = `${prefix}CREATE_KEY_DECISION_TO_MAKE`
export const SET_UPDATE_KEY_DECISION_TO_MAKE = `${prefix}SET_UPDATE_KEY_DECISION_TO_MAKE`
export const UPDATE_KEY_DECISION_TO_MAKE = `${prefix}UPDATE_KEY_DECISION_TO_MAKE`
export const REMOVE_KEY_DECISION_TO_MAKE = `${prefix}REMOVE_KEY_DECISION_TO_MAKE`
export const DELETE_KEY_DECISION_TO_MAKE = `${prefix}DELETE_KEY_DECISION_TO_MAKE`

export const FETCH_TEAM_MEMBERS = `${prefix}FETCH_TEAM_MEMBERS`
export const SET_TEAM_MEMBERS = `${prefix}SET_TEAM_MEMBERS`
export const ADD_TEAM_MEMBER = `${prefix}ADD_TEAM_MEMBER`
export const UPDATE_TEAM_MEMBER = `${prefix}UPDATE_TEAM_MEMBER`
export const REMOVE_TEAM_MEMBER = `${prefix}REMOVE_TEAM_MEMBER`
export const DELETE_TEAM_MEMBER = `${prefix}DELETE_TEAM_MEMBER`
export const SAVE_TEAM_MEMBERS = `${prefix}SAVE_TEAM_MEMBERS`

export const FETCH_TEAM_CHARTER_LIBRARY_DATA = `${prefix}FETCH_TEAM_CHARTER_LIBRARY_DATA`
export const SET_TEAM_CHARTER_LIBRARY_DATA = `${prefix}SET_TEAM_CHARTER_LIBRARY_DATA`
export const UPDATE_TEAM_CHARTER_LIBRARY_DATA = `${prefix}UPDATE_TEAM_CHARTER_LIBRARY_DATA`
export const SAVE_TEAM_CHARTER_LIBRARY_DATA = `${prefix}SAVE_TEAM_CHARTER_LIBRARY_DATA`
export const UPDATE_LIBRARY_ASSUMPTIONS_GROUP = `${prefix}UPDATE_ASSUMPTIONS_GROUP`

import { call, put } from 'redux-saga/effects'
import { setEvent } from '@communications/actions/actions'
import { getCommunicationsApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchEvent = createSaga(function* fetchEvent({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getCommunicationsApi()
  const event = yield call(api.request('getEvent', { query: { eventId: payload } }))

  yield put(setEvent({ event }))
},
true)

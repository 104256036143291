import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class TeamValueCaptureStatusUpdateApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  getTeamValueCaptureStatusUpdateV3 = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `teams/${query.teamId}/value-capture/v2/status-v3` })
  }

  changeTeamValueCaptureStatusUpdateV3 = ({ query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/value-capture/v2/status-v3` },
      { method: 'PATCH', body: query.body },
    )
  }
}

import * as constants from '@teamHome/actions/actionTypes'
import { actionTypes } from '../../utils'

export default {
  [constants.FETCH_PROJECT_PLAN_LIST]: {
    [actionTypes.FAIL]: ['We were unable to load the Team Project Plan'],
  },
  [constants.FETCH_NOTIFICATIONS]: {
    [actionTypes.FAIL]: ['We were unable to load the notifications'],
  },
  [constants.UPDATE_INBOX_NOTIFICATIONS]: {
    [actionTypes.FAIL]: ['We were unable to load more notifications'],
  },
  [constants.UPDATE_ARCHIVE_NOTIFICATIONS]: {
    [actionTypes.FAIL]: ['We were unable to load more notifications'],
  },
  [constants.ARCHIVE_ENTITY_NOTIFICATIONS]: {
    [actionTypes.FAIL]: ["We were unable to archive the entity's notifications"],
  },
  [constants.UNARCHIVE_ENTITY_NOTIFICATIONS]: {
    [actionTypes.FAIL]: ["We were unable to unarchive the entity's notifications"],
  },
  [constants.ARCHIVE_ALL_NOTIFICATIONS]: {
    [actionTypes.FAIL]: ['We were unable to archive all notifications'],
  },
  [constants.FETCH_TEAM_WEEK_STATUS]: {
    [actionTypes.FAIL]: ['We were unable to load the Team Weekly Status'],
  },
  [constants.UPDATE_TEAM_WEEKLY_STATUS]: {
    [actionTypes.FAIL]: ['We were unable to update the Team Weekly Status'],
  },
  [constants.FETCH_DELIVERABLE_TYPES_STATUSES]: {
    [actionTypes.FAIL]: ['We were unable to load the Deliverables Status'],
  },
  [constants.FETCH_TEAM_PROJECTS_STATUSES]: {
    [actionTypes.FAIL]: ['We were unable to load the Team Project Status'],
  },
  [constants.FETCH_KEY_ISSUES]: {
    [actionTypes.FAIL]: ['We were unable to load the Key Issues'],
  },
  [constants.CREATE_KEY_ISSUE]: {
    [actionTypes.FAIL]: ['We were unable to create a Key Issues item'],
  },
  [constants.UPDATE_KEY_ISSUE]: {
    [actionTypes.FAIL]: ['We were unable to delete a Key Issues item'],
  },
  [constants.DELETE_KEY_ISSUE]: {
    [actionTypes.FAIL]: ['We were unable to update the Key Issues'],
  },
}

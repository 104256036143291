import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'
import { fetchPipelineByIntegrationTeam, fetchPipelineByYearOfExecution } from './pipelineByTeam'

export default function* pipelineByTeamSaga() {
  yield all([
    takeLatest(constants.FETCH_PIPELINE_BY_INTEGRATION_TEAM_DATA, fetchPipelineByIntegrationTeam),
    takeLatest(constants.FETCH_PIPELINE_BY_YEAR_OF_EXECUTION_DATA, fetchPipelineByYearOfExecution),
  ])
}

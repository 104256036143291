import * as Yup from 'yup'
import { hasProjectsProcess } from '../utils'
import { isEqual } from 'lodash/fp'

export const DayOneSubmitSchema = Yup.array().of(
  Yup.object().shape().test('hasProjectsProcess', 'Required process can not be empty', hasProjectsProcess),
)

const getDataToCompare = ({ name, keyProcess, libraryFunctionId }) => ({
  name,
  keyProcessName: keyProcess?.name,
  libraryFunctionId,
})

function validateSelectedProcess() {
  const { path, options, parent } = this
  const { list, keyProcessList } = options.context
  const itemWithSameName = list.find(
    (item) => isEqual(getDataToCompare(item), getDataToCompare(parent)) && isEqual(item.teamId, parent.teamId),
  )

  let wholeHierarchyEqual = false

  if (itemWithSameName) {
    const fullParentHierarchy = { name: parent.name }
    const originProcessHierarchy = { name: itemWithSameName.name }
    if (parent.keyProcessId) {
      fullParentHierarchy.keyProcessName = keyProcessList.find(({ id }) => id === parent.keyProcessId)?.name
      originProcessHierarchy.keyProcessName = list.find(({ id }) => id === itemWithSameName.keyProcessId)?.name
    }

    if (parent.projectId) {
      fullParentHierarchy.projectName = keyProcessList.find(({ id }) => id === parent.projectId)?.name
      originProcessHierarchy.projectName = list.find(({ id }) => id === itemWithSameName.projectId)?.name
    }

    wholeHierarchyEqual = isEqual(fullParentHierarchy, originProcessHierarchy)
  }

  const shouldCreateError =
    !parent.disabled &&
    parent.selected &&
    itemWithSameName &&
    wholeHierarchyEqual &&
    !itemWithSameName.libraryFunctionId

  if (shouldCreateError) {
    return this.createError({ path, message: itemWithSameName?.name, params: { data: parent } })
  }

  return true
}

export const ImportLibraryDayOneSchema = Yup.array().of(
  Yup.object().shape({
    name: Yup.mixed().nullable().test('checkSelectedProcess', validateSelectedProcess),
  }),
)

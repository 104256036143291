import styled from 'styled-components'
import { Button, Colors } from '@blueprintjs/core'
import AlertPopup from '@shared/AlertPopup'
import { LogoWhite } from '@myImoClient/components/Header/Header.styles'

export const StyledText = styled.p`
  font-size: 14px;
  line-height: 16px;

  &:first-child {
    margin-top: 0;
    margin-bottom: 15px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const StyledLibraryButton = styled(Button)`
  width: 79px;

  &.bp4-disabled {
    &:hover {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .bp4-button-text {
    line-height: 1;
  }

  ${LogoWhite} {
    width: 16px;
    height: 15px;
  }
`

export const StyledActionButton = styled(Button)`
  margin: 0 2px;
`

export const Content = styled.div`
  font-size: 14px;
  line-height: 18px;

  .bp4-callout {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

export const StyledWarningLabel = styled.div`
  font-weight: 500;
  color: ${Colors.ORANGE3};
`

export const StyledAlertPopup = styled(AlertPopup)`
  .overlay-content-wrapper {
    padding: 16px 20px;
  }
`

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@teamResources/teamStructure/actions/actionTypes'
import { downloadTeamStructure, fetchTeamStructure, updateTeamStructure } from './teamStructureSagas'

export default function* resourcesHomeSaga() {
  yield all([
    takeLatest(constants.FETCH_TEAM_STRUCTURE, fetchTeamStructure),
    takeLatest(constants.UPDATE_TEAM_STRUCTURE, updateTeamStructure),
    takeLatest(constants.DOWNLOAD_TEAM_STRUCTURE, downloadTeamStructure),
  ])
}

import { teams } from '@common/accessController/constants'
import {
  getGroupedRolesByLevel,
  getTeamMentionsAsReporterByDeliverableId,
  getTeamMentionsAsReporterByDeliverableTypeId,
  getUniqTeamsIds,
  isOrgDesignTeam,
  isValueCaptureTeam,
} from '@generic/selectors/utils'
import * as teamConstants from '@generic/selectors/teamConstants'
import { groupBy, isEmpty, orderBy, find } from 'lodash/fp'

export const getImoTeams = (allReporters: $TSFixMe) => {
  return allReporters.filter((team: $TSFixMe) => teamConstants.ALL_IMO_ROLES.includes(team?.teamType))
}

export const getTeamGroups = (allReporters: $TSFixMe) => {
  return allReporters?.reduce((result: $TSFixMe, team: $TSFixMe) => {
    if (teamConstants.ALL_IMO_ROLES.includes(team?.teamType)) {
      result.imoTeams = result.imoTeams ? [...result.imoTeams, team] : [team]
    }

    if ([...teamConstants.INTEGRATION_ROLES, teams.CENTRAL].includes(team?.teamType)) {
      result.centIntTeams = result.centIntTeams ? [...result.centIntTeams, team] : [team]
    }

    if (isValueCaptureTeam(team)) {
      result.centralVCTeams = result.centralVCTeams ? [...result.centralVCTeams, team] : [team]
    }

    if (isOrgDesignTeam(team)) {
      result.centralOrgDesignTeams = result.centralOrgDesignTeams ? [...result.centralOrgDesignTeams, team] : [team]
    }

    if (team?.isAssignedToTeam) {
      result.userTeams = result.userTeams ? [...result.userTeams, team] : [team]
    }

    return result
  }, {})
}

export const getOnlyLinkedLibraries = (libraries: $TSFixMe) => libraries.filter((l: $TSFixMe) => !isEmpty(l?.teams))

const sortLibraryByIndustry = (libs: $TSFixMe) => {
  const { custom, standard } = groupBy(({ L1, L2 }) => (L1 && L2 ? 'custom' : 'standard'), libs)

  return [...orderBy('name', ['asc', 'desc'], standard), ...orderBy('name', ['asc', 'desc'], custom)]
}

export const getFilteredLibraries = (libraries: $TSFixMe) => {
  if (isEmpty(libraries)) return []

  const { central, integration } = libraries

  const availableLibraries = getOnlyLinkedLibraries([...central, ...integration])

  return sortLibraryByIndustry(availableLibraries)
}

export const getLinkedTeams = (libraries: $TSFixMe, allTeams: $TSFixMe) => {
  if (isEmpty(libraries)) return []

  const { central, integration } = libraries
  const linearLibraries = [...central, ...integration]
  const combinedTeams = linearLibraries.reduce((acc, item) => {
    if (!isEmpty(item.teams)) acc.push(item.teams)

    return acc
  }, [])

  const linkedTeamsIds = getUniqTeamsIds(combinedTeams)

  return allTeams.filter((team: $TSFixMe) => linkedTeamsIds.includes(team.id))
}

export const getGroupedTeamRoles = ({ relationParams, userTeamRelationsInfo }: $TSFixMe) => {
  const { teamId, hierarchyLevel, deliverableId, deliverableTypeId, fromTeam } = relationParams

  const { roles, reportersToImo, allReportersByDeliverable, allReportersByDeliverableType } = userTeamRelationsInfo

  if (!teamId) return null

  const reportersToImoByTeamId = reportersToImo.filter(
    ({ id, parentLevel }: $TSFixMe) => id === teamId && (!hierarchyLevel || parentLevel <= hierarchyLevel),
  )

  const teamInReviewByDeliverable = getTeamMentionsAsReporterByDeliverableId({
    allReportersByDeliverable,
    teamId,
    deliverableId,
  })

  const teamInReviewByDeliverableType = getTeamMentionsAsReporterByDeliverableTypeId({
    allReportersByDeliverableType,
    teamId,
    deliverableTypeId,
  })

  let rolesInTeam = [
    ...roles.filter(({ id }: $TSFixMe) => id === teamId),
    ...reportersToImoByTeamId,
    ...teamInReviewByDeliverable,
    ...teamInReviewByDeliverableType,
  ]

  if (fromTeam) {
    rolesInTeam = rolesInTeam.filter(({ role, parents }) => role || find({ id: fromTeam }, parents))
  }

  if (isEmpty(rolesInTeam)) return null

  const groupedRoles = getGroupedRolesByLevel(rolesInTeam)

  return { groupedRoles }
}

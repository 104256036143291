import { put, call } from 'redux-saga/effects'
import { setDay1IssueKPIsData } from '@day1IssueKPIs/actions/actions'
import { getDay1IssueKPIsApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchDay1IssueKPIsData = createSaga(function* fetchDay1IssueKPIsData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = action.payload

  const api = yield getDay1IssueKPIsApi()
  const data = yield call(
    api.request('getIssueKPIs', {
      query: { teamId },
    }),
  )

  yield put(setDay1IssueKPIsData(data))
})

import { mapKeys } from 'lodash'
import { put, call } from 'redux-saga/effects'
import { setUsersList } from '@generic/actions/actions'
import { getGenericApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchUsers = createSaga(function* fetchUsers(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getGenericApi()
  const users = yield call(api.request('getUsers'))

  yield put(setUsersList({ users: mapKeys(users, 'id') }))
})

import React from 'react'
import { StyledPaginator } from './Paginator.styles'

const Paginator: React.FC<{
  children?: React.ReactNode
  onClick?: (step: number) => void
  pages: { active: boolean; link: number }[]
}> = ({ children, onClick, pages = [] }) => (
  <>
    <StyledPaginator>
      <nav role="navigation" aria-label="Pagination">
        {pages.length > 0 && (
          <ul>
            {pages.map((page) => (
              <li
                className={`dot ${page.active ? 'dot-current' : ''}`}
                key={`key-${page.link}`}
                onClick={
                  onClick && page.link
                    ? (e) => {
                        e.preventDefault()
                        onClick(page.link)
                      }
                    : undefined
                }
                onKeyPress={(e) => {
                  if (onClick && (e.key === 'Enter' || e.key === ' ')) {
                    e.preventDefault()
                    onClick(page.link)
                  }
                }}
              >
                <a aria-current={page.active} aria-label={page.link ? `Page ${page.link}` : undefined} href="#">
                  <span className="visually-hidden">Page {page.link}</span>
                </a>
              </li>
            ))}
          </ul>
        )}
      </nav>
    </StyledPaginator>
    {children}
  </>
)

export default Paginator

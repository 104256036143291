import React from 'react'
import { useSelector } from 'react-redux'
import { getSelectedTeamId } from '@generic/selectors/selectedTeams'
import InsufficientPermissionsPage from '@shared/InsufficientPermissionsPage'
import { userInfo, hasAnyTeams, getIsLoadedInitialData } from '@generic/selectors'
import { getIsLaunched } from '@domain/instanceConfig/selectors'
import { Navigate } from 'react-router-dom'

export const DashboardRedirection = () => {
  const teamId = useSelector(getSelectedTeamId)
  const hasTeams = useSelector(hasAnyTeams)
  const isLaunchedInstance = useSelector(getIsLaunched)
  const info = useSelector(userInfo) as $TSFixMe
  const isLoadedInitialData = useSelector(getIsLoadedInitialData)

  if (info.firstTime) {
    return <Navigate to={{ pathname: 'terms-conditions' }} />
  }

  if (!isLoadedInitialData) {
    return null
  }

  if (!hasTeams || !teamId || !isLaunchedInstance) {
    return <InsufficientPermissionsPage />
  }

  return (
    <Navigate
      to={{
        pathname: `/dashboard/team/${teamId}/team-home`,
      }}
    />
  )
}

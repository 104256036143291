import { flow, get, getOr, map, orderBy, sortBy } from 'lodash/fp'
import { YEAR_ONE } from '@myImoClient/components/VCTrackerV2/TeamSetup/constants'
import { getDateIgnoreTimezone, getNextYears } from '@shared/Grid/utils/dates'
import moment from 'moment'
import { CalendarViewOption } from '@domain/myImoConfig/moduleOptions/reducer/types.d'

type TargetDTO = {
  year: string
  value: number | null
  FTE?: number | null
  gross_margin?: number | null
}

type Target = Omit<TargetDTO, 'year'>

type TimelineTargets = {
  [year: string]: Target
}

export const getAggregatedTarget = (baseline: Target, baselineDivestCo: Target): Target => {
  // For whichever keys exist in the baseline (value, FTE, gross_margin), sum the values
  // across both companies, preserving null entries if they exist in both companies. Avoid
  // adding the field to the aggregate if it didn't exist in either company.
  const keysToAggregate = Array.from(
    new Set([...Object.keys(baseline), ...Object.keys(baselineDivestCo)]),
  ) as (keyof Target)[]

  return keysToAggregate.reduce((obj: Partial<Target>, key: keyof Target) => {
    if (typeof baseline[key] === 'number' || typeof baselineDivestCo[key] === 'number') {
      obj[key] = ((baseline[key] as number) ?? 0) + ((baselineDivestCo[key] as number) ?? 0)
    } else if (baseline[key] === null && baselineDivestCo[key] === null) {
      obj[key] = null
    }
    return obj
  }, {} as Partial<Target>) as Target
}

export const mapTargetDTOToTimelineTargets = (target: TargetDTO[]): TimelineTargets => {
  if (!target) return {}

  return target.reduce((obj: TimelineTargets, { year, ...value }) => {
    obj[year] = value

    return obj
  }, {} as TimelineTargets)
}

export const getAggregatedTargetObject = (target: TargetDTO[], targetDivestCo: TargetDTO[]): TimelineTargets => {
  const resultA = mapTargetDTOToTimelineTargets(target)
  const resultB = mapTargetDTOToTimelineTargets(targetDivestCo)

  return Object.keys(resultA).reduce((obj: TimelineTargets, key) => {
    obj[key] = getAggregatedTarget(resultA[key], resultB[key])
    return obj
  }, {})
}

export const getSortedTargetsListForGrid = flow([
  get('targetList'),
  sortBy([({ team }) => !team, ({ team }) => getOr('', 'longName', team).toLowerCase()]),
  map(
    ({
      baseline,
      baselineDivestCo,
      runRateTarget,
      inPeriodTarget,
      npvTarget,
      npvTargetDivestCo,
      runRateTargetDivestCo,
      inPeriodTargetDivestCo,
      isTargetsHidden,
      isTargetsHiddenDivestCo,
      ...target
    }) => {
      return {
        ...target,
        baseline,
        baselineDivestCo,
        baselineWholeCo: getAggregatedTarget(baseline, baselineDivestCo),
        runRateTarget: mapTargetDTOToTimelineTargets(runRateTarget),
        runRateTargetDivestCo: mapTargetDTOToTimelineTargets(runRateTargetDivestCo),
        runRateTargetWholeCo: getAggregatedTargetObject(runRateTarget, runRateTargetDivestCo),
        inPeriodTarget: mapTargetDTOToTimelineTargets(inPeriodTarget),
        inPeriodTargetDivestCo: mapTargetDTOToTimelineTargets(inPeriodTargetDivestCo),
        inPeriodTargetWholeCo: getAggregatedTargetObject(inPeriodTarget, inPeriodTargetDivestCo),
        npvTarget,
        npvTargetDivestCo,
        npvTargetWholeCo: ((npvTarget as number) ?? 0) + ((npvTargetDivestCo as number) ?? 0),
        isTargetsHidden,
        isTargetsHiddenDivestCo,
        isTargetsHiddenWholeCo: isTargetsHidden || isTargetsHiddenDivestCo,
      }
    },
  ),
])

export const getSortedVCTeamsFromState = flow([
  get('valueCaptureTeams'),
  sortBy(({ longName }) => longName.toLowerCase()),
])

export const getYear = (dayOneDate: string) => {
  const date = getDateIgnoreTimezone(moment.utc(new Date(dayOneDate))).toDate()

  return date.getFullYear()
}

export const getMonthName = (dayOneDate: string, monthFormat: string) => {
  const date = getDateIgnoreTimezone(moment.utc(new Date(dayOneDate)))

  return date.format(monthFormat)
}

export const getFormedYearsList = (
  dayOneDate: $TSFixMe,
  fiscalYear: number,
  calendarView: CalendarViewOption,
  financialYearsToShow: number,
) => {
  if (!dayOneDate) return []

  let startYear = getYear(dayOneDate)

  if (calendarView === 'Fiscal year' && fiscalYear > new Date(dayOneDate).getMonth()) {
    startYear = startYear - 1
  }

  return [startYear, YEAR_ONE, ...getNextYears(startYear + 1, financialYearsToShow - 1)]
}

export const sortCategoriesComparator = orderBy(
  [({ name }) => Boolean(name), ({ name }) => (name ? name.toLowerCase() : '')],
  ['desc', 'asc'],
)

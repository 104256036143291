const prefix = '@batchUpload/'

export const BATCH_UPLOAD_FILE = `${prefix}BATCH_UPLOAD_FILE`
export const BATCH_UPLOAD_VC_INITIATIVES_FILE = `${prefix}BATCH_UPLOAD_VC_INITIATIVES_FILE`
export const SET_BATCH_UPLOAD_RESULT = `${prefix}SET_BATCH_UPLOAD_RESULT`
export const BATCH_UPLOAD_FILE_SUCCESS = `${prefix}BATCH_UPLOAD_FILE_SUCCESS`
export const SET_BATCH_FILE_UPLOAD_ERRORS = `${prefix}SET_BATCH_FILE_UPLOAD_ERRORS`
export const BATCH_FILE_UPLOAD_RESET = `${prefix}BATCH_FILE_UPLOAD_RESET`
export const SET_BATCH_FILE = `${prefix}SET_BATCH_FILE`
export const SET_FILE_LOADED = `${prefix}SET_FILE_LOADED`
export const DOWNLOAD_BATCH_UPLOAD_TEMPLATE = `${prefix}DOWNLOAD_BATCH_UPLOAD_TEMPLATE`

import { get, getOr, flow, find, isEmpty } from 'lodash/fp'
import { createSelector } from 'reselect'
import { initialState, TSAActivityLogInitialState } from '../reducers/reducer'
import { getTSAVersion, getDealType } from '@domain/instanceConfig/selectors'
import { getAllTeams } from '@generic/selectors'
import { TSA_VERSION } from '@myImoClient/components/TSAv2/shared/constants'
import { allowedTSADealTypes } from '@helpers/constants'

export const clientState = getOr(initialState, '@TSAv2')
export const getTSAv2Data = flow(clientState, get('tsa'))
export const getTsaProcessesFromLibrary = flow(clientState, get('tsaProcessesFromLibrary'))
export const getIsLocked = flow(clientState, get('isLocked'))
export const getSelectedTsaID = flow(clientState, get('selectedTsaID'))
export const getSelectedTSA = createSelector(getTSAv2Data, getSelectedTsaID, (data = [], selectedTsaID) =>
  find(({ id }) => id === selectedTsaID, data),
)

export const getSnapshotMinDate = createSelector(clientState, ({ snapshotMinDate }: $TSFixMe) =>
  snapshotMinDate ? new Date(snapshotMinDate) : new Date(),
)

export const getTsaMasterListTabStatus = flow(clientState, get('tsaMasterListTabStatus'))

export const getMasterListTSATabPermission = createSelector(getTsaMasterListTabStatus, (tabStatus: $TSFixMe) => {
  const getStatus = (teams: [], accessor: string) =>
    isEmpty(teams) ? true : teams.every((team: $TSFixMe) => team[accessor])

  return {
    isTSAMasterListTabHidden: getStatus(tabStatus, 'isTSAMasterListTabHidden'),
    isTSAMasterListTabLocked: getStatus(tabStatus, 'isTSAMasterListTabLocked'),
  }
})

export const getTSAIntegrationTeamsList = createSelector(
  getTsaMasterListTabStatus,
  getAllTeams,
  (teamsTabPermissions: $TSFixMe, allTeams: $TSFixMe) => {
    if (!teamsTabPermissions || isEmpty(teamsTabPermissions)) return []

    const availableTeams = teamsTabPermissions
      .filter((team: $TSFixMe) => !team.isTSAMasterListTabHidden && !team.isTSAMasterListTabLocked)
      .map((team: $TSFixMe) => team.teamId)

    return allTeams.filter((team: $TSFixMe) => availableTeams.includes(team.id))
  },
)

export const getTSAActivityLogs = flow(clientState, get('tsaV2ActivityLog'))

export const getSelectedTSAActivityLogs = createSelector(
  clientState,
  (_: unknown, selectedTSAId: $TSFixMe) => selectedTSAId,
  // @ts-ignore
  (state, selectedTSAId) => state['tsaV2ActivityLog'][selectedTSAId] || TSAActivityLogInitialState,
)

export const getShowLinkedTSA = createSelector(getDealType, getTSAVersion, (dealType: string, TSAVersion: number) => {
  const isTSADealType = allowedTSADealTypes.includes(dealType)

  return isTSADealType && TSAVersion === TSA_VERSION.TWO
})

import { put, select, takeLatest, all, call } from 'redux-saga/effects'
import * as configConstants from '@myImoConfigActions/config/actionTypes'
import * as teamHierarchyConstants from '@myImoConfigActions/teamImoHierarchy/actionTypes'
import { teamHierarchyApi } from '@common/net'
import * as selectors from '../../selectors'
import { validateTeamsRequest, mapTeamsHierarchyData, mapTeamsHierarchyResponse } from '@helpers/teamHierarchy'
import { validateTeamsHierarchy } from '@helpers/validatorsConfig'
import { createSaga } from '@common/sagaCreator/createSaga'
import { ValidationError } from '../../../../helpers/customErrors'
import { handleForbiddenError } from '../utils'
import { NavigateFunction } from 'react-router-dom'

export const getTeamHierarchy = createSaga(
  function* getTeamHierarchy(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const TeamHierarchyApi = yield teamHierarchyApi()
    const data = yield call(TeamHierarchyApi.request('getTeamHierarchy'))
    yield put({ type: teamHierarchyConstants.ON_TEAM_DATA_SUCCESS, payload: mapTeamsHierarchyResponse(data) })
  },
  true,
  handleForbiddenError,
)

export const createTeamHierarchy = createSaga(function* createTeamHierarchy({
  payload: { navigate },
}: {
  payload: { navigate: NavigateFunction }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { deliverableTypes, ...teams } = yield select(selectors.getTeamHierarchy)
  const requestData = mapTeamsHierarchyData(teams, deliverableTypes)
  yield call(validateTeamsHierarchy, requestData)

  if (!validateTeamsRequest(requestData)) throw new ValidationError()

  const TeamHierarchyApi = yield teamHierarchyApi()
  yield call(TeamHierarchyApi.request('createTeamHierarchy', { body: requestData }))

  const data = yield call(TeamHierarchyApi.request('getTeamHierarchy'))
  yield put({ type: configConstants.SET_STEP_COMPLETED, payload: { no: 5 } })
  yield put({ type: teamHierarchyConstants.ON_TEAM_DATA_SUCCESS, payload: data })
  navigate('/config/team-deliverable-setup')
},
true)

export function* teamImoHierarchyWatcher() {
  yield all([
    takeLatest(teamHierarchyConstants.GET_TEAM_DATA, getTeamHierarchy),
    takeLatest(teamHierarchyConstants.CREATE_TEAM_HIERARCHY, createTeamHierarchy),
  ])
}

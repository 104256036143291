import { BASIC_PERMISSIONS } from '@common/accessController/constants'
import { isVcTrackerTeam } from '@common/teamFilter/helpers'

export const getVCTrackerChecker = (params: $TSFixMe) => () => {
  if (isVcTrackerTeam(params)) {
    return BASIC_PERMISSIONS
  }

  return []
}

import { get, isEqual, isFunction, isObject, set } from 'lodash'
import { processStatusSortOrder } from '@helpers/statuses/processStatuses'
import { basicStatusList, implementationDetailsStatusList } from '@helpers/statuses'

export const statusMap = {
  OPEN: 'open',
  ISSUES: 'issues',
  MITIGATED: 'mitigated',
  DECIDED: 'decided',
}

export const defaultStatusValueOrder = {
  [statusMap.ISSUES]: 1,
  [statusMap.OPEN]: 2,
  [statusMap.MITIGATED]: 3,
  [statusMap.DECIDED]: 4,
}

export const statusRiskOptions = [statusMap.OPEN, statusMap.ISSUES, statusMap.MITIGATED]

export const statusDecisionOptions = [statusMap.OPEN, statusMap.ISSUES, statusMap.DECIDED]

export const toLowercaseSafe = (status) => (status && isFunction(status.toLowerCase) ? status.toLowerCase() : status)
const transformStatus = (status, options) =>
  options?.findIndex((s) => toLowercaseSafe(s) === toLowercaseSafe(status)) + 1

export const createStatusComparator = (options) => (status1, status2) => {
  const formattedStatus1 = transformStatus(status1, options)
  const formattedStatus2 = transformStatus(status2, options)

  if (!formattedStatus1 && !formattedStatus2) {
    return 0
  }

  if (!formattedStatus1) {
    return -1
  }

  if (!formattedStatus2) {
    return 1
  }

  return formattedStatus1 - formattedStatus2
}

export const statusComparator = createStatusComparator(processStatusSortOrder)
export const basicStatusComparator = createStatusComparator(basicStatusList)
export const implementationDetailsStatusComparator = createStatusComparator(implementationDetailsStatusList)

export const statusLabelComparator = (item1, item2) => {
  const label1 = get(item1, 'label', '')
  const label2 = get(item2, 'label', '')

  return label1.toLowerCase().localeCompare(label2.toLowerCase())
}

export const logStatusComparator = (value1, value2) => {
  if (!value1 && !value2) {
    return 0
  }

  if (!value1) {
    return -1
  }

  if (!value2) {
    return 1
  }

  return defaultStatusValueOrder[value1.toLowerCase()] - defaultStatusValueOrder[value2.toLowerCase()]
}

export const labelLogStatusComparator = (param1, param2) => logStatusComparator(param1.label, param2.label)

export const statusValueSetter = ({ newValue, oldValue, data, colDef }) => {
  const label = isObject(newValue) ? newValue.label : newValue

  if (isEqual(label, oldValue.label)) return false

  // explicit data mutation
  set(data, colDef.field, label)

  return true
}

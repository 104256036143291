/* eslint-disable react/require-default-props */
import React from 'react'
import { Icon, MenuItem } from '@blueprintjs/core'
import { StyledLockedItems, StyledUnlockedItems, StyledMenu } from './index.styles'
import CustomTooltip from '../Popover/CustomTooltip'
import { IconName } from '@blueprintjs/icons'

interface Props {
  id?: string
  ariaLabel?: string
  ariaLabelledBy?: string
  className?: string
  list: $TSFixMe
  setActiveItem: (...args: $TSFixMe[]) => $TSFixMe
  activeItem?: $TSFixMe
  getOptionLabel: (...args: $TSFixMe[]) => $TSFixMe
  getOptionValue: (...args: $TSFixMe[]) => $TSFixMe
  styles?: $TSFixMe
  suppressItemSelection?: boolean
  elementToScroll?: string
  iconSize?: number
}

const Menu = ({
  id,
  ariaLabel,
  list,
  className,
  styles,
  setActiveItem,
  activeItem,
  getOptionLabel,
  getOptionValue,
  suppressItemSelection,
  elementToScroll,
  iconSize,
  ariaLabelledBy,
  ...other
}: Props) => {
  const onClick = (item: $TSFixMe) => () => {
    setActiveItem(item)
  }

  const getIcon = (item: $TSFixMe): IconName | null | '' => {
    if (suppressItemSelection) return null

    return getOptionLabel(item) === getOptionLabel(activeItem) ? 'tick' : ''
  }

  const scrollParams = { behavior: 'instant', block: 'center', inline: 'nearest' } as $TSFixMe

  if (elementToScroll) {
    const element = document.querySelector(`.bp4-menu :nth-child(${elementToScroll})`)
    if (element) {
      element.scrollIntoView(scrollParams)
    }
  }

  const renderMenuItem = (item: $TSFixMe) => {
    const menuItem = (
      <MenuItem
        disabled={item.disabled}
        onClick={onClick(item)}
        key={getOptionValue(item) || getOptionLabel(item)}
        text={getOptionLabel(item)}
        icon={
          item.locked ? (
            item.selected ? (
              <StyledLockedItems>
                <Icon size={iconSize} icon={getIcon(item) as IconName} />
                <Icon size={iconSize} icon="lock" />
              </StyledLockedItems>
            ) : (
              <StyledUnlockedItems>
                <Icon size={iconSize} icon={getIcon(item) as IconName} />
                <Icon size={iconSize} icon="lock" />
              </StyledUnlockedItems>
            )
          ) : (
            <Icon size={iconSize} icon={getIcon(item) as IconName} />
          )
        }
        // @ts-expect-error fix tooltip type
        tooltip={item?.tooltip}
        {...other}
      />
    )

    if (!item.tooltip) return menuItem

    return (
      <CustomTooltip content={item?.tooltip} key={getOptionValue(item)}>
        {menuItem}
      </CustomTooltip>
    )
  }

  return (
    <StyledMenu id={id} styles={styles} aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} className={className}>
      {list.map(renderMenuItem)}
    </StyledMenu>
  )
}

Menu.defaultProps = {
  ariaLabel: undefined,
  className: '',
  styles: {},
  activeItem: null,
  suppressItemSelection: false,
  elementToScroll: null,
  iconSize: undefined,
}

export default Menu

import React from 'react'
import { Colors, Icon, Spinner } from '@blueprintjs/core'
import { ACTIVE_SIDER_ICON_COLOR, DIAGNOSTIC_ICON_COLOR } from '@helpers/constants'
import {
  LogoutButton,
  LogoutLabel,
  PopoverMain,
  PopoverFooter,
  PopoverWrapper,
  UserName,
  UserEmail,
  TeamName,
  TeamItem,
  UserInfoContainer,
  UserInfo,
  StyledUserAvatar,
  Divider,
  DiagnosticButton,
  PopoverFooterSpaceBetween,
} from './index.styles'
import { mutateValueForSMOCase } from '@helpers/smoTransformations'
import { isSMO } from '@myImoConfig/containers/ConfigSteps/utils'
import { getTypeColor } from '@imo/imo-ui-toolkit'
import { sortBy } from 'lodash/fp'
import { sortTeamRoles } from '@common/accessController/utils'
import { useTranslation } from 'react-i18next'
import { transformTeamTypeNameIntoTeamType } from '@myImoConfig/components/UserAccounts/utils'
import { en } from '@common/translation/en'
import { teams } from '@common/accessController/constants'

interface Props {
  userInfo: { name: string; surname: string; email: string; teams: $TSFixMe; roles: $TSFixMe }
  onLogDiagnostics?: (...args: $TSFixMe[]) => $TSFixMe
  onLogout?: (...args: $TSFixMe[]) => $TSFixMe
  managementType: string
  isLoggingDiagnostics: boolean
}

const getRoleKey = (
  teamType: string,
  role: string,
):
  | `ROLES.IMO.${keyof typeof en.COMMON.ROLES.IMO}`
  | `ROLES.INTEGRATION.${keyof typeof en.COMMON.ROLES.INTEGRATION}`
  | `ROLES.CENTRAL.${keyof typeof en.COMMON.ROLES.CENTRAL}`
  | `ROLES.ADMIN.${keyof typeof en.COMMON.ROLES.ADMIN}` => {
  return `ROLES.${teamType}.${role}` as
    | `ROLES.IMO.${keyof typeof en.COMMON.ROLES.IMO}`
    | `ROLES.INTEGRATION.${keyof typeof en.COMMON.ROLES.INTEGRATION}`
    | `ROLES.CENTRAL.${keyof typeof en.COMMON.ROLES.CENTRAL}`
    | `ROLES.ADMIN.${keyof typeof en.COMMON.ROLES.ADMIN}`
}

// TODO: replace when ui-toolkit is merged into this repo.
const getTeamTypeColor = (teamType: string) => {
  if (teamType === teams.CENTRAL_ORG_DESIGN) {
    return Colors.ORANGE3
  }
  return getTypeColor({ teamType })
}

const UserTooltip = ({ userInfo, onLogout, managementType, onLogDiagnostics, isLoggingDiagnostics }: Props) => {
  const { t } = useTranslation()

  const { name, surname, email, teams = [], roles = [] } = userInfo

  const sortedTeams = sortTeamRoles(teams)
  const sortedRoles = sortBy('name', roles)

  const getTeamName = (teamName: $TSFixMe) => (isSMO(managementType) ? mutateValueForSMOCase(teamName) : teamName)

  return (
    <PopoverWrapper>
      <PopoverMain>
        <UserInfoContainer>
          <StyledUserAvatar user={userInfo} />
          <UserInfo>
            <UserName>
              {name} {surname}
            </UserName>
            <UserEmail title={email}>{email}</UserEmail>
          </UserInfo>
        </UserInfoContainer>
        <Divider />
        <>
          {sortedTeams.map((team: $TSFixMe) => (
            <TeamItem key={team.id}>
              <TeamName typeColor={getTeamTypeColor(team.teamType)}>{getTeamName(team.longName)}</TeamName>
              {t(getRoleKey(transformTeamTypeNameIntoTeamType(team.teamType).toUpperCase(), team.role))}
            </TeamItem>
          ))}
          <TeamItem>{sortedRoles.map((role) => t(getRoleKey('ADMIN', role.name))).join('; ')}</TeamItem>
        </>
      </PopoverMain>

      {onLogDiagnostics ? (
        <PopoverFooterSpaceBetween>
          <DiagnosticButton onClick={onLogDiagnostics} disabled={isLoggingDiagnostics}>
            {isLoggingDiagnostics ? <Spinner size={16} /> : <Icon icon="wrench" color={DIAGNOSTIC_ICON_COLOR} />}
            <LogoutLabel>Log diagnostics</LogoutLabel>
          </DiagnosticButton>
          <LogoutButton onClick={onLogout}>
            <Icon icon="log-out" color={ACTIVE_SIDER_ICON_COLOR} />
            <LogoutLabel>Log out</LogoutLabel>
          </LogoutButton>
        </PopoverFooterSpaceBetween>
      ) : (
        <PopoverFooter>
          <LogoutButton onClick={onLogout}>
            <Icon icon="log-out" color={ACTIVE_SIDER_ICON_COLOR} />
            <LogoutLabel>Log out</LogoutLabel>
          </LogoutButton>
        </PopoverFooter>
      )}
    </PopoverWrapper>
  )
}

UserTooltip.defaultProps = {
  onLogout: () => {},
}

export default UserTooltip

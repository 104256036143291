import { actionTypes } from '../../utils'
import * as constants from '@teamResources/resourcesHome/actions/actionTypes'

export default {
  [constants.DOCUMENT_SHARING_CREATE_FILE]: {
    [actionTypes.VALIDATION_FAIL]: ['The file size exceeds 25 MB attachment limit.'],
    [actionTypes.FAIL]: ['Some of the files were not uploaded. Please check the folder and upload them again.'],
  },
  [constants.DOCUMENT_SHARING_DELETE_NODES]: {
    [actionTypes.FAIL]: [
      'Some of the files or folders were not deleted. Please check the folder and delete them again',
    ],
  },
  [constants.DOCUMENT_SHARING_UPDATE_FOLDER]: {
    [actionTypes.VALIDATION_FAIL]: ['Path name should be uniq'],
  },
}

import * as Yup from 'yup'

export const FunctionalActivityMappingSchema = Yup.array().of(
  Yup.object().shape({
    keyProcess: Yup.string().required('Key process is required').typeError('This field cannot be empty'),
    corporateCompanyA: Yup.string()
      .required('Corporate/Global Company A is required')
      .typeError('This field cannot be empty'),
    corporateCompanyB: Yup.string()
      .required('Corporate/Global Company B is required')
      .typeError('This field cannot be empty'),
    globalSharedServiceCompanyA: Yup.string()
      .required('Global Shared Services/Outsourcing Company A is required')
      .typeError('This field cannot be empty'),
    globalSharedServiceCompanyB: Yup.string()
      .required('Global Shared Services/Outsourcing Company B is required')
      .typeError('This field cannot be empty'),
    businessUnitCompanyA: Yup.string()
      .required('Business Unit Company A is required')
      .typeError('This field cannot be empty'),
    businessUnitCompanyB: Yup.string()
      .required('Business Unit Company B is required')
      .typeError('This field cannot be empty'),
  }),
)

import { handleActions } from 'redux-actions'
import * as constants from '@myImoConfigActions/config/actionTypes'
import { assoc, getOr, omit } from 'lodash/fp'
import { loadingState } from '@imo/imo-ui-toolkit'
import { valueCaptureSettings } from '@helpers/utils'

export interface IConfigState {
  steps: $TSFixMe[]
  stepsState?: string
  coreComponents?: $TSFixMe
  valueCaptureModulesSelect: $TSFixMe[]
  welcomeNoteData: $TSFixMe
  loader: boolean
  warningMessage: string | null
  isUserManagementHasChanges?: boolean
  dictionary?: $TSFixMe
  configWarningWasShowed?: boolean
}

export const initialState: IConfigState = {
  steps: [
    {
      no: 1,
      status: 'incompleted',
    },
    {
      no: 2,
      status: 'disabled',
    },
    {
      no: 3,
      status: 'enabled',
    },
    {
      no: 4,
      status: 'disabled',
    },
    {
      no: 5,
      status: 'disabled',
    },
    {
      no: 6,
      status: 'disabled',
    },
    {
      no: 7,
      status: 'disabled',
    },
    {
      no: 8,
      status: 'disabled',
    },
    {
      no: 9,
      status: 'disabled',
    },
  ],
  stepsState: loadingState.INITIAL,
  // TODO: same data is placed in @generic/reducers/reducer -> `config.modules`.
  coreComponents: {
    mutualDiscovery: {
      active: false,
      locked: true,
    },
    documentSharing: {
      active: false,
      locked: true,
    },
    valueCapture: {
      active: false,
      locked: true,
    },
    communications: {
      active: false,
      locked: true,
    },
    dataRequestTracker: {
      active: false,
      locked: true,
    },
    day1HypercareLog: {
      active: false,
      locked: true,
    },
    imoDeliverablesPanel: {
      active: false,
      locked: true,
    },
    teamCalendar: {
      active: false,
      locked: true,
    },
    notifications: {
      active: false,
      locked: false,
    },
    teamResources: {
      active: false,
      locked: true,
    },
  },
  valueCaptureModulesSelect: [],
  welcomeNoteData: {
    welcomeNote: null,
    legalDisclaimer: null,
    terms: null,
  },
  loader: false,
  warningMessage: null,
  isUserManagementHasChanges: false,
  dictionary: {},
  configWarningWasShowed: false,
}

export const configReducer = handleActions<IConfigState, $TSFixMe>(
  {
    [constants.SET_STEP_COMPLETED]: (state, action) => {
      const { no } = action.payload

      const newSteps = state.steps.map((step, index, steps) => {
        if (step.no === no) {
          const next = steps[index + 1]

          if (next) {
            next.status = 'incompleted'
          }

          step.status = 'completed'
        }

        return step
      })

      return { ...state, steps: newSteps }
    },
    [constants.FETCH_COMPLETENESS_STATUSES_SUCCESS]: (state, action) => {
      return { ...state, steps: action.payload }
    },
    [constants.FETCH_COMPLETENESS_STATUSES_STATE]: (state, action) => {
      return { ...state, stepsState: action.payload }
    },
    [constants.SET_CORE_COMPONENT_STATUS]: (state, action) => {
      const { title, active, locked } = action.payload
      const coreComponentsUpdate = { ...state.coreComponents, [title]: { active: !active, locked: !!locked } }

      return { ...state, coreComponents: coreComponentsUpdate }
    },
    [constants.SET_MODULE_SELECT_VALUE]: (state, { payload: { value, key } }) => {
      return {
        ...state,
        valueCaptureModulesSelect: state.valueCaptureModulesSelect.map((it) =>
          it.key === key ? { ...it, value } : it,
        ),
      }
    },
    [constants.UPDATE_CUSTOM_COLUMN_NAME]: (state, action) => {
      const { value, column, initiative } = action.payload
      const updatedItem = state.valueCaptureModulesSelect.find(
        ({ key }) => key === valueCaptureSettings.INITIATIVE_COLUMNS_CUSTOM_NAME,
      ) as $TSFixMe
      updatedItem.value[initiative][column] = value

      return {
        ...state,
        valueCaptureModulesSelect: [
          ...state.valueCaptureModulesSelect.filter(
            ({ key }) => key !== valueCaptureSettings.INITIATIVE_COLUMNS_CUSTOM_NAME,
          ),
          updatedItem,
        ],
      }
    },

    [constants.SET_CORE_COMPONENT_STATUSES]: (state, action) => {
      const { coreComponents, valueCaptureModulesSelect, dictionary } = action.payload

      return {
        ...state,
        coreComponents: {
          ...state.coreComponents,
          ...coreComponents,
        },
        valueCaptureModulesSelect,
        dictionary,
      }
    },
    [constants.ADD_DICTIONARY_WORDING]: (state, action) => {
      const { data, field, module } = action.payload
      const index = state.dictionary[module]?.findIndex(({ name }: $TSFixMe) => name === field)
      const valuePath = [module, index, 'options']
      const wordings = [...getOr([], valuePath, state.dictionary), data]
      const newDictionary = assoc(valuePath, wordings, state.dictionary)

      return {
        ...state,
        dictionary: newDictionary,
      }
    },
    [constants.DELETE_DICTIONARY_WORDING]: (state, action) => {
      const { field, module, id } = action.payload
      const index = state.dictionary[module]?.findIndex(({ name }: $TSFixMe) => name === field)
      const valuePath = [module, index, 'options']
      const wordings = getOr([], valuePath, state.dictionary).filter(({ id: wordId }: $TSFixMe) => wordId !== id)
      const newDictionary = assoc(valuePath, wordings, state.dictionary)

      return {
        ...state,
        dictionary: newDictionary,
      }
    },
    [constants.UPDATE_DICTIONARY_WORDING]: (state, action) => {
      const { field, module, data } = action.payload
      const index = state.dictionary[module]?.findIndex(({ name }: $TSFixMe) => name === field)
      const valuePath = [module, index, 'options']
      const options = getOr([], valuePath, state.dictionary).map((option: $TSFixMe) => {
        const { id: optionId } = option

        if (optionId !== data.id) return option

        return { ...option, ...data }
      })

      return {
        ...state,
        dictionary: assoc(valuePath, options, state.dictionary),
      }
    },
    [constants.SET_WELCOME_NOTE_DATA]: (state, action) => {
      return { ...state, welcomeNoteData: action.payload.welcomeNoteData }
    },
    [constants.SET_HAS_CHANGES]: (state, action) => {
      return { ...state, isUserManagementHasChanges: action.payload }
    },
    [constants.SET_CONFIG_WARNING_WAS_SHOWED]: (state, action) => {
      return { ...state, configWarningWasShowed: action.payload }
    },
    [constants.ADD_DICTIONARY_CUSTOM_COLUMN]: (state, action) => {
      const { module } = action.payload
      const index = state.dictionary[module].length
      const valuePath = [module, index]

      return {
        ...state,
        dictionary: assoc(valuePath, action.payload, state.dictionary),
      }
    },
    [constants.UPDATE_DICTIONARY_CUSTOM_COLUMN]: (state, action) => {
      const { module, id: columnId } = action.payload
      const index = state.dictionary[module]?.findIndex(({ id }: $TSFixMe) => id === columnId)
      const valuePath = [module, index]
      const data = omit('module', action.payload)

      return {
        ...state,
        dictionary: assoc(valuePath, data, state.dictionary),
      }
    },
    [constants.DELETE_DICTIONARY_CUSTOM_COLUMN]: (state, action) => {
      const { module, columnId } = action.payload
      const valuePath = [module]
      const newData = state.dictionary[module].filter((item: $TSFixMe) => item.id !== columnId)

      return {
        ...state,
        dictionary: assoc(valuePath, newData, state.dictionary),
      }
    },
  },
  initialState,
)

import moment from 'moment'

/**
 * - 'ns ago' 1-59 seconds
 * - 'nm ago' 1-59 minutes
 * - 'nh ago' 1-24 hours
 * - 'nd ago' 1+ days
 *
 * @param {string} utcDateString UTC date string
 * @returns {string} Formatted for time unit (s, min, h or day)
 */
export function timeUnitsSinceDate(utcDateString) {
  const parsedDate = new Date(utcDateString)

  if (isNaN(parsedDate.getTime())) {
    return ''
  }

  const msDifference = Date.now() - parsedDate

  if (msDifference < 0) {
    // No specifications how to handle future formatting
    return '0s ago'
  }

  const secondDifference = msDifference / 1000
  const roundedSecondDifference = Math.floor(secondDifference)
  if (roundedSecondDifference < 60) return `${roundedSecondDifference}s ago`

  const minuteDifference = secondDifference / 60
  const roundedMinuteDifference = Math.floor(minuteDifference)
  if (roundedMinuteDifference < 60) return `${roundedMinuteDifference}m ago`

  const hourDifference = minuteDifference / 60
  const roundedhourDifference = Math.floor(hourDifference)
  if (roundedhourDifference < 24) return `${roundedhourDifference}h ago`

  const dayDifference = hourDifference / 24
  const roundedDayDifference = Math.floor(dayDifference)

  return `${roundedDayDifference}d ago`
}

/**
 *
 * @param {string} utcDateString UTC date string
 * @returns {string} formatted in h:mma D MMM (GMT)
 */
export function tooltipDate(utcDateString) {
  const momentDate = moment(utcDateString, true).utc()

  if (!momentDate.isValid()) {
    return ''
  }

  return `${momentDate.format('h:mma D MMM')} (GMT)`
}

export const hasStarted = (startDate) => moment().isAfter(startDate)

import { handleActions } from 'redux-actions'
import * as constants from '@communications/actions/actionTypes'
import { get } from 'lodash'

export interface ICommunicationsReducerState {
  communicationsGridData: $TSFixMe[]
  columns: $TSFixMe[]
  event: $TSFixMe | null
}

export const initialState = {
  communicationsGridData: [],
  columns: [],
  event: null,
}

const communicationsReducer = handleActions<ICommunicationsReducerState, $TSFixMe>(
  {
    [constants.SET_COMMUNICATIONS_DATA]: (state, action) => ({
      ...state,
      ...action.payload,
    }),

    [constants.SET_COLUMNS_DATA]: (state, action) => ({
      ...state,
      ...action.payload,
    }),

    [constants.UPDATE_ROW]: (state, { payload }) => {
      const { id, data } = payload
      let updated = false
      const updatedData = state.communicationsGridData.map((r) => {
        if (r.firstColumn && r.firstColumn.id === id) {
          updated = true

          return { ...r, firstColumn: data }
        }

        return r
      })

      if (!updated) {
        updatedData.push({ firstColumn: data })
      }

      return {
        ...state,
        communicationsGridData: updatedData,
      }
    },

    [constants.DELETE_ROW]: (state, action) => {
      const { payload } = action

      return {
        ...state,
        communicationsGridData: state.communicationsGridData.filter((r) => get(r, 'firstColumn.id') !== payload.id),
      }
    },
    [constants.SET_REQUESTS]: (state, action) => ({
      ...state,
      ...action.payload,
    }),

    [constants.DELETE_ROWS_SUCCESS]: (state, action) => {
      const { payload } = action

      return {
        ...state,
        communicationsGridData: state.communicationsGridData.filter((r) => !payload.includes(get(r, 'firstColumn.id'))),
      }
    },
    [constants.UPDATE_REQUEST]: (state, action) => ({
      ...state,
      ...action.payload,
    }),

    [constants.SET_EVENT]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState,
)

export default communicationsReducer

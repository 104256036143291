import HttpClient from '../HttpClient'
import Config from '@root/src/config'
import { isEmpty, omit } from 'lodash'

export default class TeamResourcesApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch, '')

    this.host = Config.API_SERVER
  }

  // TR updates

  getTeamResourcesUpdates = () => {
    return this.call({ pathname: `team-resources/latest-updates` })
  }

  updateTeamResourcesUpdates = ({ body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `team-resources/latest-updates`,
      },
      {
        method: 'PUT',
        body,
      },
    )
  }

  // TR team calendar

  getCalendarEvents = ({ query }: $TSFixMe) => {
    const requestQuery = {} as { label?: string }

    if (query.labels) {
      requestQuery.label = query.labels.join(',')
    }

    return this.call({ pathname: `/team-resources/master-calendar/events`, query: requestQuery })
  }

  createCalendarEvent = ({ body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/team-resources/master-calendar/events`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateCalendarEvent = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/team-resources/master-calendar/events/${query.eventId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteCalendarEvent = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/team-resources/master-calendar/events/${query.eventId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  uploadCalendarAttachment = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/team-resources/master-calendar/${query.eventId}/attachments`,
      },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  deleteCalendarAttachment = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/team-resources/master-calendar/${query.eventId}/attachments/${query.fileId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  getCalendarLabels = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `/team-resources/master-calendar/labels`, query })
  }

  createCalendarLabel = ({ body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/team-resources/master-calendar/labels`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateCalendarLabel = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/team-resources/master-calendar/labels/${query.eventId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteCalendarLabel = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/team-resources/master-calendar/labels/${query.eventId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  // TR masterplan

  getTeamResourcesMasterplan = () => {
    return this.call({ pathname: `team-resources/master-plan` })
  }

  updateTeamResourcesMasterplan = ({ body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `team-resources/master-plan`,
      },
      {
        method: 'PUT',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  // TR planning assumptions report

  getPlanningAssumptionsReport = () => {
    const query = {
      fields:
        'id,name,libraryFunctionId,complete,owners,companyOne,companyTwo,entanglement,entanglementCategory,changingDayOne,tsa,dayOneSolution,dayOneAssumptions,postCloseAssumptions',
      include: 'subKeyProcesses,team',
    }

    return this.call({
      pathname: '/team-resources/planning-assumptions-report/v2',
      query,
    })
  }

  // TR scenario report

  getScenarioReport = () => {
    return this.call({ pathname: '/team-resources/scenario-report' })
  }

  // TR scope and team members

  getScopeTeamMembers = () => {
    return this.call({ pathname: '/team-resources/team-charter-scope/v2' })
  }

  // TR interdependency summary / matrix

  fetchMatrixData = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: '/team-resources/interdependency-matrix',
      query,
    })
  }

  getMatrixPopupData = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/team-resources/interdependency-matrix/team/${query.teamId}/interdependency-tasks`,
      query: query.additionalParams,
    })
  }

  getTotalReceived = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/team-resources/interdependency-matrix/team/${query.teamId}/total-received/interdependency-tasks`,
      query: omit(query.additionalParams, ['dependantTeams']),
    })
  }

  // TR team contacts

  getTeamContacts = () => {
    return this.call({ pathname: `team-resources/contacts` })
  }

  createTeamContact = ({ body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `team-resources/contacts`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateTeamContact = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `team-resources/contacts/${query.id}`,
      },
      {
        method: 'PUT',
        body,
      },
    )
  }

  deleteTeamContact = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `team-resources/contacts/${query.id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  // TR document sharing

  getDocumentSharing = () => {
    return this.call({
      pathname: `/files/document-sharing/folder`,
      query: {
        scope: 'team-resources',
      },
    })
  }

  createFolder = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/files/document-sharing/folder`,
        query: {
          scope: 'team-resources',
          ...query,
        },
      },
      {
        method: 'POST',
      },
    )
  }

  updateFolder = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/files/document-sharing/folder/${query.id}`,
        query: {
          scope: 'team-resources',
          path: query.path,
        },
      },
      {
        method: 'PUT',
      },
    )
  }

  createFile = ({ body, query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/files/document-sharing/file`,
        routePrefix: '',
        query: {
          scope: 'team-resources',
          ...query,
        },
      },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  deleteNodes = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/files/document-sharing/batchDelete/`,
        query: {
          scope: 'team-resources',
          ...query,
        },
      },
      {
        method: 'DELETE',
      },
    )
  }

  downloadFiles = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/files/document-sharing/`,
      query: {
        scope: 'team-resources',
        ...query,
      },
    })
  }

  // TR team structure

  getTeamStructure = () => {
    return this.call({ pathname: `team-resources/team-structure` })
  }

  updateTeamStructure = ({ body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `team-resources/team-structure`,
      },
      {
        method: 'PUT',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  // TR full project plan

  getFullProjectPlanData = () => {
    return this.call({
      pathname: `team-resources/full-project-plan-v2`,
    })
  }

  // TR Status Report Chart
  getStatusReport = ({ query }: $TSFixMe) => {
    const directionParam =
      !isEmpty(query.companyA) && !isEmpty(query.companyB) ? `&from=${query.companyA}&to=${query.companyB}` : ''

    const priorityParam = query.priority ? `&priority=${query.priority}` : ''

    return this.call({
      pathname: `/team-resources/data-request-tracker-report?type=${query.type}${priorityParam}${directionParam}`,
    })
  }

  getInterdependencySummary = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/team-resources/interdependency-summary`,
      query: {
        type: query.type,
      },
    })
  }

  getInterdependencyFrequency = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/team-resources/interdependency-summary/frequency-chart`,
      query: {
        type: query.frequency,
      },
    })
  }

  // Notifications

  getNotifications = ({ query }: $TSFixMe) => {
    const { teamId, offset = 0, archived = false, filterBy } = query

    return this.call({
      pathname: `/teams/${teamId}/user-notifications`,
      query: { offset, limit: 10, archived, filterBy },
    })
  }

  getEntityNotifications = ({ query }: $TSFixMe) => {
    const { teamId, type, id, offset = 0, archived = false, filterBy } = query

    return this.call({
      pathname: `/teams/${teamId}/user-notifications/entity`,
      query: { type, id, offset, limit: 5, archived, filterBy },
    })
  }

  archiveEntityNotifications = ({ query: { teamId, entityId, entityType } }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${teamId}/user-notifications/${entityId}/archive/`,
        query: { entity_type: entityType },
      },
      { method: 'POST' },
    )
  }

  unarchiveEntityNotifications = ({ query: { teamId, entityId, entityType } }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${teamId}/user-notifications/${entityId}/unarchive`,
        query: { entity_type: entityType },
      },
      { method: 'POST' },
    )
  }

  archiveAllNotifications = ({ query: { teamId } }: $TSFixMe) => {
    return this.call({ pathname: `/teams/${teamId}/user-notifications/archive-all` }, { method: 'POST' })
  }
}

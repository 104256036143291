import { put, call } from 'redux-saga/effects'
import { setFullProjectPlan } from '@teamResources/fullProjectPlan/actions/actions'
import { getTeamResourcesApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchFullProjectPlanData = createSaga(function* fetchFullProjectPlanData(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const api = yield getTeamResourcesApi()
  const data = yield call(api.request('getFullProjectPlanData'))

  yield put(setFullProjectPlan(data))
},
true)

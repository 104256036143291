import { actionTypes } from '../../utils'
import * as constants from '@dayOne/actions/actionTypes'

export default {
  [constants.FETCH_PROJECT_LIST]: {
    [actionTypes.FAIL]: ['We were unable to load the Projects and Tasks '],
  },
  [constants.FETCH_LIBRARY_PROJECT_LIST]: {
    [actionTypes.FAIL]: ['We were unable to load the projects and tasks for the McKinsey Library'],
  },
  [constants.LAUNCH_INTERDEPENDENCY]: {
    [actionTypes.FAIL]: ['We were unable to launch the interdependency request'],
  },
  [constants.CANCEL_INTERDEPENDENCY]: {
    [actionTypes.FAIL]: ['We were unable to cancel the interdependency request'],
  },
  [constants.UPDATE_INTERDEPENDENCY_TASK]: {
    [actionTypes.FAIL]: ['We were unable to update the interdependency'],
  },
  [constants.CREATE_KEY_PROCESS]: {
    [actionTypes.FAIL]: ['We were unable to add a new key process'],
  },
  [constants.UPDATE_KEY_PROCESS]: {
    [actionTypes.FAIL]: ['We were unable to update the project'],
  },
  [constants.DELETE_KEY_PROCESS]: {
    [actionTypes.FAIL]: ['We were unable to delete the key process'],
  },
  [constants.CREATE_PROJECT]: {
    [actionTypes.FAIL]: ['We were unable to create a new project'],
  },
  [constants.UPDATE_PROJECT]: {
    [actionTypes.FAIL]: ['We were unable to update the project'],
  },
  [constants.DELETE_PROJECT]: {
    [actionTypes.FAIL]: ['We were unable to delete the project'],
  },
  [constants.CREATE_TASK]: {
    [actionTypes.FAIL]: ['We were unable to create a new task'],
  },
  [constants.UPDATE_TASK]: {
    [actionTypes.FAIL]: ['We were unable to update the task'],
  },
  [constants.DELETE_TASK]: {
    [actionTypes.FAIL]: ['We were unable to delete the task'],
  },
  [constants.DELETE_DAY_ONE_ITEMS_LIST]: {
    [actionTypes.FAIL]: ['We were unable to delete selected rows'],
  },
  [constants.UPLOAD_PROJECT_FILE]: {
    [actionTypes.FAIL]: ['We were unable to upload the file attachment'],
    [actionTypes.VALIDATION_FAIL]: ['File does not meet requirements'],
  },
  [constants.DELETE_PROJECT_FILE]: {
    [actionTypes.FAIL]: ['We were unable to delete the file attachment'],
  },
  [constants.UPLOAD_TASK_FILE]: {
    [actionTypes.FAIL]: ['We were unable to upload the file attachment'],
  },
  [constants.DELETE_TASK_FILE]: {
    [actionTypes.FAIL]: ['We were unable to delete the file attachment'],
  },
  [constants.CREATE_PROCESS_FROM_LIB]: {
    [actionTypes.FAIL]: ['We were unable to import new items from the McKinsey Library'],
    [actionTypes.SUCCESS]: ['You have successfully updated the list of selected key processes'],
  },
}

const prefix = '@initiativeWatchers/'

// api calls
export const FETCH_INITIATIVE_WATCHERS = `${prefix}FETCH_INITIATIVE_WATCHERS`
export const WATCH_INITIATIVE = `${prefix}WATCH_INITIATIVE`
export const UNWATCH_INITIATIVE = `${prefix}UNWATCH_INITIATIVE`

// store updates
export const SET_INITIATIVE_WATCHERS = `${prefix}SET_INITIATIVE_WATCHERS`
export const ADD_INITIATIVE_WATCHER = `${prefix}ADD_INITIATIVE_WATCHER`
export const REMOVE_INITIATIVE_WATCHER = `${prefix}REMOVE_INITIATIVE_WATCHER`

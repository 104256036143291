import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@teamWorkspace/actions/actionTypes'
import { fetchInitialData } from './fetchInitialData'
import { getWhiteboard, createWhiteboard, updateWhiteboard, deleteWhiteboard } from './whiteboardSaga'
import { getTeamActions, createTeamAction, updateTeamAction, deleteTeamAction } from './teamActionsSaga'
import { getDecisions, createDecisions, updateDecisions, deleteDecisions } from './decisionsSaga'
import { getRisks, createRisks, updateRisks, deleteRisks } from './risksSaga'
import { getCalendar, createCalendar, updateCalendar, deleteCalendar } from './calendarSaga'
import { createTeamTopic, deleteTeamTopic, getTeamTopics, updateTeamTopic } from './teamTopicsSaga'

export default function* teamWorkspaceSaga() {
  yield all([
    takeLatest(constants.FETCH_INITIAL_DATA, fetchInitialData),

    takeLatest(constants.FETCH_WHITEBOARD, getWhiteboard),
    takeLatest(constants.CREATE_WHITEBOARD, createWhiteboard),
    takeLatest(constants.UPDATE_WHITEBOARD, updateWhiteboard),
    takeLatest(constants.DELETE_WHITEBOARD, deleteWhiteboard),

    takeLatest(constants.FETCH_TEAM_ACTIONS, getTeamActions),
    takeLatest(constants.CREATE_TEAM_ACTION, createTeamAction),
    takeLatest(constants.UPDATE_TEAM_ACTION, updateTeamAction),
    takeLatest(constants.DELETE_TEAM_ACTION, deleteTeamAction),

    takeLatest(constants.FETCH_DECISIONS_LOG, getDecisions),
    takeLatest(constants.CREATE_DECISIONS_LOG, createDecisions),
    takeLatest(constants.UPDATE_DECISIONS_LOG, updateDecisions),
    takeLatest(constants.DELETE_DECISIONS_LOG, deleteDecisions),

    takeLatest(constants.FETCH_RISKS_LOG, getRisks),
    takeLatest(constants.CREATE_RISKS_LOG, createRisks),
    takeLatest(constants.UPDATE_RISKS_LOG, updateRisks),
    takeLatest(constants.DELETE_RISKS_LOG, deleteRisks),

    takeLatest(constants.FETCH_CALENDAR, getCalendar),
    takeLatest(constants.CREATE_CALENDAR, createCalendar),
    takeLatest(constants.UPDATE_CALENDAR, updateCalendar),
    takeLatest(constants.DELETE_CALENDAR, deleteCalendar),

    takeLatest(constants.FETCH_TEAM_TOPICS, getTeamTopics),
    takeLatest(constants.CREATE_TEAM_TOPICS, createTeamTopic),
    takeLatest(constants.UPDATE_TEAM_TOPICS, updateTeamTopic),
    takeLatest(constants.DELETE_TEAM_TOPICS, deleteTeamTopic),
  ])
}

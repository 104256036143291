import styled from 'styled-components'
import { Colors } from '@blueprintjs/core'
import { white } from '@imo/imo-ui-toolkit'

const LEFT_CONTENT_DISTANCE = 16
const RIGHT_CONTENT_DISTANCE = 20
const BOTTOM_CONTENT_DISTANCE = 20
const ICON_SIZE = 30
const HEADER_HEIGHT = 40

export const ActionBtnContainer = styled.div<$TSFixMe>`
  display: flex;
  justify-content: ${({ justify }: { justify: string }) => justify || 'space-between'};
  align-items: center;
  width: 100%;
  padding: 0 ${RIGHT_CONTENT_DISTANCE}px ${BOTTOM_CONTENT_DISTANCE}px;

  .action-btn {
    margin-left: 10px;
    padding: 0;
    height: 30px;
    line-height: 30px;
    font-size: 14px;

    &:last-child {
      width: auto;
      padding: 0 10px;
    }

    &:first-child {
      width: auto;
      padding: 0 12px;
    }
  }
`

export const StyledLabel = styled.span`
  font-size: 14px;
`

export const LightLabel = styled.div`
  font-weight: 400;
  line-height: 1.43;
`

export const OverlayHeader = styled.div<$TSFixMe>`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  height: 40px;
  width: 100%;
  padding: 0 16px;
  border-bottom: 1px solid rgba(16, 22, 26, 0.15);
  box-sizing: border-box;
  ${(props) => props.styles}
`

export const OverlayContainer = styled.div<$TSFixMe>`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${white};
  border-radius: 4px;
  width: 50vw;
  max-width: 585px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${(props) => props.containerStyles}
`

export const OverlayContentWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: ${LEFT_CONTENT_DISTANCE}px;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  overflow-x: hidden;

  p {
    margin: 0;
  }

  .bp4-form-group label.bp4-label {
    margin-bottom: 10px;
  }

  .bp4-input-group input {
    font-size: 12px;
    width: calc(100% - 4px);
    margin: 4px 0;
  }

  .bp4-form-group {
    margin-bottom: 0;
  }

  .alert-text {
    color: ${Colors.RED3};
  }
`

export const OverlayContent = styled.div<$TSFixMe>`
  padding-left: ${LEFT_CONTENT_DISTANCE + ICON_SIZE}px;
  width: 100%;
  overflow-x: hidden;
  overflow-wrap: break-word;

  br {
    line-height: 20px;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  top: ${LEFT_CONTENT_DISTANCE + HEADER_HEIGHT}px;
  left: ${LEFT_CONTENT_DISTANCE}px;
`

import { handleActions } from 'redux-actions'
import * as constants from '@centralFunctionalTSA/actions/actionTypes'

export interface ICentralFunctionalTSAState {
  functionalListDrafts: $TSFixMe[]
  projectPlanList: $TSFixMe[]
  locked: boolean
}

export const initialState = {
  functionalListDrafts: [],
  projectPlanList: [],
  locked: false,
}

const centralFunctionaTSAReducer = handleActions<ICentralFunctionalTSAState, $TSFixMe>(
  {
    [constants.SET_CENTRAL_FUNCTIONAL_TSA]: (state, action) => {
      const { functionalListDrafts, locked } = action.payload

      return {
        ...state,
        functionalListDrafts: functionalListDrafts,
        locked,
      }
    },

    [constants.SET_PROJECT_LIST]: (state, action) => {
      const { projectPlanList } = action.payload

      return {
        ...state,
        projectPlanList: projectPlanList,
      }
    },

    [constants.CREATE_CENTRAL_FUNCTIONAL_TSA_SUCCESS]: (state, action) => {
      const { data } = action.payload
      const { functionalListDrafts, locked } = state

      return {
        ...state,
        functionalListDrafts: [...functionalListDrafts, data],
        locked,
      }
    },

    [constants.UPDATE_CENTRAL_FUNCTIONAL_TSA_SUCCESS]: (state, action) => {
      const { id, data } = action.payload
      const { functionalListDrafts, locked } = state

      const updatedFunctionalListDrafts = functionalListDrafts.map((keyProcess) =>
        keyProcess.id === id ? { ...keyProcess, ...data } : keyProcess,
      )

      return {
        ...state,
        functionalListDrafts: updatedFunctionalListDrafts,
        locked,
      }
    },

    [constants.DELETE_CENTRAL_FUNCTIONAL_TSA_SUCCESS]: (state, action) => {
      const { id } = action.payload
      const { functionalListDrafts, locked } = state

      const filteredFunctionalListDrafts = functionalListDrafts.filter((keyProcess) => keyProcess.id !== id)

      return {
        ...state,
        functionalListDrafts: filteredFunctionalListDrafts,
        locked,
      }
    },
  },
  initialState,
)

export default centralFunctionaTSAReducer

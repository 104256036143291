import styled from 'styled-components'
import { MultiSelect, Suggest } from '@blueprintjs/select'
import { Icon, MenuItem } from '@blueprintjs/core'
import { FONT_FAMILY, greyLightBackColor } from '@imo/imo-ui-toolkit'

export const StyledSuggest = styled(Suggest)<$TSFixMe>`
  width: 100%;
  height: 100%;

  .bp4-popover-wrapper {
    overflow-y: scroll;
    height: 40px;
  }

  .bp4-popover-target {
    width: 100%;
    height: 100%;
  }

  .bp4-input-group {
    height: 100%;
  }

  .bp4-input {
    display: block;
    height: 100%;
    overflow: auto;
    font: 12px ${FONT_FAMILY} !important;
    line-height: 12px;
    position: relative;
    border-radius: 0;
    box-shadow: none;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  font: 12px/14px ${FONT_FAMILY};
  align-items: center;

  .bp4-icon {
    margin-top: 0;

    &:not(.bp4-icon-add) {
      margin-top: 0;
      display: ${({ multi }: { multi?: boolean }) => (multi ? 'block' : 'none')};
    }

    &.bp4-icon-add svg {
      width: 14px;
      height: 14px;
    }
  }
`

export const ClearButton = styled(Icon)`
  top: 50%;
  transform: translate3d(0, -50%, 0);
  position: absolute;
  right: 0;
  padding: 2px;
`

export const StyLedMultiSelect = styled(MultiSelect)<$TSFixMe>`
  width: 100%;

  &,
  div {
    ${({ isCellEditorComponent }) => (isCellEditorComponent ? { height: '100%' } : {})};
  }

  .multiselect-lookup {
    max-width: 100%;
    min-height: auto;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px 5px 0;
    background-color: ${({ disabled }) => (disabled ? greyLightBackColor : 'none')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    -webkit-box-shadow: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    box-shadow: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    ${({ isCellEditorComponent }) => (isCellEditorComponent ? { boxShadow: 'none' } : {})};
    ${(props) => (props as $TSFixMe).customStyles}

    .bp4-tag-input-values {
      max-width: ${(props) => ((props as $TSFixMe).isMultiple ? 'calc(100% - 24px)' : '100%')};
      margin: 0;
      padding-bottom: 0;
      margin-top: 0;
    }

    .bp4-input {
      ${({ isCellEditorComponent }) => (isCellEditorComponent ? { borderRadius: 0 } : {})}
      pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    }

    input {
      pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
      visibility: ${({ disabled }) => (disabled ? 'hidden' : 'visible')};
    }

    span {
      pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    }

    .bp4-input-ghost {
      font: 12px ${FONT_FAMILY} !important;
      width: 0;
    }

    .bp4-button {
      margin: 0;
      pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
      margin-top: -2px;
    }
  }
`

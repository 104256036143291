import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class DayOneApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  getDayOneData = ({ query }: $TSFixMe) => {
    const { teamId, ...queryParams } = query

    return this.call({
      pathname: `teams/${teamId}/team-key-process-v2`,
      query: { source: 'ProjectList', ...queryParams },
    })
  }

  //TODO UPDATE WHEN LIBRARY FUNCTIONALITY WILL BE ADDED
  getLibraryProjectList = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/libraries/${query.libId}/key-processes?include=projects,tasks&type=plain`,
    })
  }

  getFlagLibraryRelatedToIMOTeam = ({ query: { imoTeamId } }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${imoTeamId}/has-linked-library`,
    })
  }

  createKeyProcess = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-key-process-v2`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateKeyProcess = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-key-process-v2/${query.processId}?source=ProjectList`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteKeyProcess = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-key-process-v2/${query.processId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  createProject = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-key-process-v2/${query.keyProcessId}/projects`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateProject = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-key-process-v2/${query.keyProcessId}/projects/${query.projectId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteProject = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-key-process-v2/${query.keyProcessId}/projects/${query.projectId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  createTask = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-key-process-v2/${query.keyProcessId}/projects/${query.projectId}/tasks`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateTask = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-key-process-v2/${query.keyProcessId}/projects/${query.projectId}/tasks/${query.taskId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteTask = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-key-process-v2/${query.keyProcessId}/projects/${query.projectId}/tasks/${query.taskId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  uploadProjectFile = ({ body, query }: $TSFixMe) => {
    const { teamId, keyProcessId, projectId } = query

    return this.call(
      {
        pathname: `/teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${projectId}/attachments`,
      },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  deleteProjectFile = ({ query }: $TSFixMe) => {
    const { teamId, keyProcessId, projectId, fileId } = query

    return this.call(
      {
        pathname: `/teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${projectId}/attachments/${fileId}`,
      },
      { method: 'DELETE' },
    )
  }

  uploadTaskFile = ({ body, query }: $TSFixMe) => {
    const { teamId, keyProcessId, projectId, taskId } = query

    return this.call(
      {
        pathname: `/teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${projectId}/tasks/${taskId}/attachments`,
      },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  deleteTaskFile = ({ query }: $TSFixMe) => {
    const { teamId, keyProcessId, projectId, taskId, fileId } = query

    return this.call(
      {
        pathname: `/teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${projectId}/tasks/${taskId}/attachments/${fileId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  //TODO UPDATE WHEN LIBRARY FUNCTIONALITY WILL BE ADDED
  createProcessFromLib = ({ query, body }: $TSFixMe) => {
    const { teamId } = query

    return this.call(
      {
        pathname: `/teams/${teamId}/team-key-process-v2/bulk?source=ProjectList`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  getInterdependencyData = ({ query }: $TSFixMe) => {
    const { senderTeamId, taskId, interdependencyId } = query

    return this.call({
      pathname: `teams/${senderTeamId}/tasks/${taskId}/interdependency-tasks/${interdependencyId}`,
    })
  }

  launchInterdependency = ({ query, body }: $TSFixMe) => {
    const { senderTeamId, taskId } = query

    return this.call(
      { pathname: `teams/${senderTeamId}/tasks/${taskId}/interdependency-tasks` },
      {
        method: 'POST',
        body,
      },
    )
  }

  cancelInterdependency = ({ query }: $TSFixMe) => {
    const { senderTeamId, taskId, interdependencyId } = query

    return this.call(
      { pathname: `teams/${senderTeamId}/tasks/${taskId}/interdependency-tasks/${interdependencyId}` },
      {
        method: 'DELETE',
      },
    )
  }

  updateInterdependencyTask = ({ query, body }: $TSFixMe) => {
    const { senderTeamId, taskId, interdependencyId } = query

    return this.call(
      { pathname: `teams/${senderTeamId}/tasks/${taskId}/interdependency-tasks/${interdependencyId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  batchFileUpload = ({ query, body }: $TSFixMe) => {
    const { teamId } = query

    return this.call(
      { pathname: `files/team/${teamId}/project-list-batch-upload-v2` },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  batchFileUploadByTeam = ({ query, body }: $TSFixMe) => {
    const { teamId } = query

    return this.call(
      { pathname: `files/team/${teamId}/project-list-batch-upload-by-team-v2` },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  deleteItemsList = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-key-process-v2/bulkDelete`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  getBatchUploadTemplate = ({ query }: $TSFixMe) => {
    const { teamId, deliverableId } = query
    let url = `${this.host}/files/team/${teamId}/project-list-template`
    if (deliverableId) {
      url += `?deliverableId=${deliverableId}`
    }

    return this.fetch(url, { method: 'GET', headers: {} })
  }

  getSnapshotMinDate = ({ query: { teamId } }: $TSFixMe) => {
    return this.call({ pathname: `files/team/${teamId}/imo-program-project-plan-v2/min-date` })
  }
}

import React from 'react'
import { RoundedWrapper } from '@shared/UserAvatar/index.styles'

interface Props {
  user: $TSFixMe
  className?: string
}

export const UserAvatar = ({ user, className }: Props) => {
  const letters = `${user.name.substring(0, 1).toUpperCase()} ${user.surname.substring(0, 1).toUpperCase()}`

  return <RoundedWrapper className={className}>{letters}</RoundedWrapper>
}

UserAvatar.defaultProps = {
  className: '',
}

import i18n from 'i18next'
import { en } from './en'

export const resources = {
  en,
} as const

i18n.init({
  lng: 'en',
  defaultNS: 'COMMON',
  resources,
})

i18n.services.formatter?.add('stronglist', (value: string[]) => {
  value = value.map((v: string) => `<strong>${v}</strong>`)
  return new Intl.ListFormat('en-GB', {
    // Ensures consistency with en-GB for now, use locale if we want greater i18n in future
    style: 'long',
    type: 'conjunction',
  }).format(value)
})

export const t = i18n.t

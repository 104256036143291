import { handleActions } from 'redux-actions'
import { sortBy } from 'lodash/fp'
import * as constants from '../actions/actionTypes'

export const initialState = {
  userInitiativeFilter: [],
  filterState: null,
  selectedUserInitiativeFilter: null,
  tableFilters: {},
  isRedirect: false,
}

const initiativeListReducer = handleActions(
  {
    [constants.SET_INITIATIVE_LIST_FILTER_STATE]: (state, { payload }) => {
      return {
        ...state,
        filterState: payload,
      }
    },
    [constants.SET_USER_INITIATIVE_FILTER]: (state, { payload }) => {
      return {
        ...state,
        userInitiativeFilter: sortBy(({ filterName }) => filterName.toLowerCase(), payload),
      }
    },

    // explicit render of grid without filters
    [constants.FLUSH_TABLE_FILTER]: (state) => ({
      ...state,
      filterState: null,
      selectedUserInitiativeFilter: null,
    }),

    [constants.SET_SELECTED_USER_INITIATIVE_FILTER]: (state, { payload }) => {
      const filterState = payload?.filter || {}

      return {
        ...state,
        filterState,
        selectedUserInitiativeFilter: payload,
      }
    },
    [constants.SET_TABLE_FILTERS]: (state, { payload }) => ({
      ...state,
      tableFilters: payload,
    }),
    [constants.SET_IS_REDIRECT]: (state, { payload }) => ({
      ...state,
      isRedirect: payload,
    }),
  },
  initialState,
)

export default initiativeListReducer

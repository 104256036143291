import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchPlanningAssumptionsReport = createAction(
  constants.FETCH_PLANNING_ASSUMPTIONS_REPORT,
  null,
  getMetaWithActionLoading,
)
export const setPlanningAssumptionsReport = createAction(constants.SET_PLANNING_ASSUMPTIONS_REPORT)

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class ProjectActivityLogApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  fetchProjectActivityLog = ({ query }: $TSFixMe) => {
    const { teamId, keyProcessId, keyProcessProjectId, limit, offset } = query

    return this.call({
      pathname: `/teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/activity-log`,
      query: {
        limit,
        offset,
      },
    })
  }
}

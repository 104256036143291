import { paleGray } from '@imo/imo-ui-toolkit/dist/helpers/colors'

export const NO_LABEL = 'No label'

export const defaultLabel = {
  id: NO_LABEL,
  color: paleGray,
  name: 'No label',
}

export const eventFields = ['title', 'date', 'comments', 'allDay', 'labelId', 'description', 'confidential', 'duration']

export const labelFields = ['name', 'color', 'isDefault']

const RECIPIENT_LIST = [
  'Marc_Lang@mckinsey.com',
  'Iurii_Musiienko@external.mckinsey.com',
  'Ben_Wynett@mckinsey.com',
  'Ashley_Marshall@mckinsey.com',
]

const MAX_EMAIL_STRING_LENGTH = 2000

export const minifyStack = (stack: $TSFixMe) => {
  const locationExp = new RegExp(location.origin, 'gu')

  try {
    return stack
      .split('\n')
      .map((a: $TSFixMe) => a.trim())
      .join('\n ')
      .replace(locationExp, '')
  } catch (e) {
    return stack
  }
}

export const reduceStack = (stack: $TSFixMe) => {
  try {
    const stackArray = stack.split('\n').map((a: $TSFixMe) => a.trim())
    stackArray.pop()

    return stackArray.join('\n ')
  } catch (e) {
    return stack
  }
}

export const createEmailMessageString = ({
  recipients,
  subject,
  message,
  errorStack,
  componentStack,
  errorId,
  pageUrl,
}: $TSFixMe) => {
  const body = encodeURIComponent(`
  ${message};

  errorStack: ${errorStack};

  componentStack: ${componentStack};

  Page URL: ${pageUrl};

  Error id: ${errorId}
  `)

  return `mailto:${encodeURIComponent(recipients)}?subject=${encodeURIComponent(subject)}&body=${body}`
}

const sendErrorMail = ({ message, errorStack, componentStack, errorId, pageUrl, tenantName }: $TSFixMe) => {
  const recipients = RECIPIENT_LIST.join('; ')
  const subject = `myIMO Error Report (${tenantName})`
  let minifiedErrorStack = minifyStack(errorStack)
  let minifiedComponentStack = minifyStack(componentStack)
  let messageString

  do {
    messageString = createEmailMessageString({
      recipients,
      subject,
      message,
      errorStack: minifiedErrorStack,
      componentStack: minifiedComponentStack,
      errorId,
      pageUrl,
    })

    minifiedErrorStack = reduceStack(minifiedErrorStack)
    minifiedComponentStack = reduceStack(minifiedComponentStack)
  } while (messageString.length > MAX_EMAIL_STRING_LENGTH)

  document.location.href = messageString
}

export default sendErrorMail

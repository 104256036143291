import React from 'react'
import { FileIcon } from '@assets/icons/FileIcon'
import { FileContainer } from '../index.styles'
import { getFileName } from '@shared/FilesGrid/utils'

interface Props {
  withExtension?: boolean
  value?: {
    fileType?: string
    fileName?: string
  }
}

const FileCellRenderer = ({ value: { fileType, fileName } = {}, withExtension }: Props) => {
  const file = getFileName({ fileType, fileName, withExtension })

  return (
    <FileContainer>
      <FileIcon extension={fileType?.toLowerCase()} iconSize={24} />
      <span>{file}</span>
    </FileContainer>
  )
}

FileCellRenderer.defaultProps = {
  withExtension: true,
  value: { fileType: '' },
}

export default FileCellRenderer

import { isEmpty, mapKeys } from 'lodash'

export function getUpdateStatusRequestBody(data: $TSFixMe) {
  const transformedData = { ...data }

  mapKeys(data, (value, key) => {
    if (['actions', 'keyUpdates', 'supportDependencies'].includes(key)) {
      transformedData[key] = value.map(({ statusComment }: $TSFixMe) => statusComment)
    }
  })

  return transformedData
}

// TODO: need to change this mapping to more simple way or try to avoid it
export function getMappedCurrentStatusData(data: $TSFixMe) {
  const mappedCurrentData = { actions: [], keyUpdates: [], supportDependencies: [], ...data }

  if (!isEmpty(data)) {
    mapKeys(data, (value, key) => {
      if (['actions', 'keyUpdates', 'supportDependencies'].includes(key)) {
        mappedCurrentData[key] = value.map((statusComment: $TSFixMe, index: $TSFixMe) => ({
          id: index + 1,
          statusComment,
        }))
      }
    })
  }

  return mappedCurrentData
}

import { handleActions } from 'redux-actions'
import * as constants from '@steerCoRoadmap/actions/actionTypes'

export interface ISteerCoRoadmapState {
  steerCoActions: $TSFixMe[] | null
  parkingLotdata?: string
  columns?: $TSFixMe[]
}

export const initialState = {
  steerCoActions: null,
  parkingLotdata: '',
  columns: [],
}

const steerCoRoadmapReducer = handleActions<ISteerCoRoadmapState, $TSFixMe>(
  {
    [constants.SET_STEER_CO_ACTIONS_DATA]: (state, action) => ({ ...state, steerCoActions: action.payload }),

    [constants.UPDATE_STEER_CO_ACTIONS_DATA_SUCCESS]: (state, action) => {
      const { value, columnId, rowId } = action.payload

      return {
        ...state,
        steerCoActions: state.steerCoActions?.map((column) =>
          column.id === columnId
            ? {
                ...column,
                steerCoRoadMapActionItems: column.steerCoRoadMapActionItems.map((item: $TSFixMe) =>
                  item.rowId === rowId ? { ...item, value } : item,
                ),
              }
            : column,
        ),
      }
    },

    [constants.FETCH_PARKING_LOT_DATA]: (state, action) => {
      const { value } = action.payload

      return {
        ...state,
        parkingLotdata: value,
      }
    },

    [constants.SET_PARKING_LOT_DATA]: (state, action) => {
      const { value } = action.payload

      return {
        ...state,
        parkingLotdata: value,
      }
    },

    [constants.UPDATE_PARKING_LOT_DATA]: (state, action) => {
      const { value } = action.payload

      return {
        ...state,
        parkingLotdata: value,
      }
    },

    [constants.SET_COLUMNS_DATA]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
  initialState,
)

export default steerCoRoadmapReducer

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchInitiativeList = createAction(constants.FETCH_INITIATIVE_LIST)
export const fetchInitiativeListWithLoader = createAction(
  constants.FETCH_INITIATIVE_LIST,
  null,
  getMetaWithActionLoading,
)

export const fetchInfiniteInitiativeList = createAction(
  constants.FETCH_INFINITE_INITIATIVE_LIST,
  null,
  getMetaWithActionLoading,
)

export const updateSelectedAndMirroredInitiatives = createAction(
  constants.UPDATE_SELECTED_TEAM_AND_MIRRORED_INITIATIVES,
)

export const updateSeveralInitiatives = createAction(constants.UPDATE_SEVERAL_INITIATIVES)

export const resetInitiativeList = createAction(constants.RESET_INITIATIVE_LIST)
export const fetchInitiativeFiltersAndLengthSilent = createAction(constants.FETCH_INITIATIVE_FILTERS_AND_LENGTH)
export const fetchInitiativeFiltersAndLength = createAction(
  constants.FETCH_INITIATIVE_FILTERS_AND_LENGTH,
  null,
  getMetaWithActionLoading,
)
export const setInitiativeFiltersAndLength = createAction(constants.SET_INITIATIVE_FILTERS_AND_LENGTH)
export const fetchAllInitiativeMetadata = createAction(
  constants.FETCH_ALL_INITIATIVE_METADATA,
  null,
  getMetaWithActionLoading,
)
export const fetchAllInitiativeMetadataSilent = createAction(constants.FETCH_ALL_INITIATIVE_METADATA)
export const setAllInitiativeMetadata = createAction(constants.SET_ALL_INITIATIVE_METADATA)

export const setInitiativeList = createAction(constants.SET_INITIATIVE_LIST)

export const addInitiativeToInitiativeList = createAction(constants.ADD_INITIATIVE_TO_INITIATIVE_LIST)

export const setSelectedInitiativeId = createAction(constants.SET_SELECTED_INITIATIVE_ID)

export const setTeamId = createAction(constants.SET_TEAM_ID)
export const setSynergyType = createAction(constants.SET_SYNERGY_TYPE)

// Stage gate comments
export const fetchInitiativeCommentsList = createAction(
  constants.FETCH_INITIATIVE_COMMENTS_LIST,
  null,
  getMetaWithActionLoading,
)
export const setInitiativeCommentsList = createAction(constants.SET_INITIATIVE_COMMENTS_LIST)

export const fetchStageGateValidationStatus = createAction(constants.FETCH_STAGE_GATE_VALIDATION_STATUS)
export const fetchStageGateValidationStatusWithLoader = createAction(
  constants.FETCH_STAGE_GATE_VALIDATION_STATUS,
  null,
  getMetaWithActionLoading,
)
export const setStageGateValidationStatus = createAction(constants.SET_STAGE_GATE_VALIDATION_STATUS)

export const createInitiativeComment = createAction(constants.CREATE_INITIATIVE_COMMENT)
export const createInitiativeCommentSuccess = createAction(constants.CREATE_INITIATIVE_COMMENT_SUCCESS)

export const updateStageGate = createAction(constants.UPDATE_STAGE_GATE)
export const updateStageGateSuccess = createAction(constants.UPDATE_STAGE_GATE_SUCCESS)

export const createInitiative = createAction(constants.CREATE_INITIATIVE)
export const createInitiativeSuccess = createAction(constants.CREATE_INITIATIVE_SUCCESS)
export const createInitiativeSuccessForInfinityList = createAction(
  constants.CREATE_INITIATIVE_SUCCESS_FOR_INFINITY_LIST,
)

export const updateInitiative = createAction(constants.UPDATE_INITIATIVE)
export const updateInitiativeSuccess = createAction(constants.UPDATE_INITIATIVE_SUCCESS)
export const setIsAllInitiative = createAction(constants.SET_IS_ALL_INITIATIVE)

export const deleteInitiative = createAction(constants.DELETE_INITIATIVE)
export const deleteInitiativeSuccess = createAction(constants.DELETE_INITIATIVE_SUCCESS)

export const fetchFinancials = createAction(constants.FETCH_FINANCIALS)
export const setFinancials = createAction(constants.SET_FINANCIALS)
export const setFinancialsFail = createAction(constants.SET_FINANCIALS_FAIL)

export const updateFinancials = createAction(constants.UPDATE_FINANCIALS_PENDING)
export const updateFinancialsSuccess = createAction(constants.UPDATE_FINANCIALS_SUCCESS)
export const updateFinancialsFail = createAction(constants.UPDATE_FINANCIALS_FAIL)

export const uploadInitiativeAttachments = createAction(constants.UPLOAD_INITIATIVE_ATTACHMENTS)
export const fetchInitiativeAttachments = createAction(constants.FETCH_INITIATIVE_ATTACHMENTS)
export const deleteInitiativeAttachment = createAction(constants.DELETE_INITIATIVE_ATTACHMENT)
export const uploadInitiativeAttachmentSuccess = createAction(constants.UPLOAD_INITIATIVE_ATTACHMENT_SUCCESS)
export const deleteInitiativeAttachmentSuccess = createAction(constants.DELETE_INITIATIVE_ATTACHMENT_SUCCESS)
export const populateInitiative = createAction(constants.POPULATE_INITIATIVE)

export const fetchInitiativesCategoriesList = createAction(constants.FETCH_INITIATIVES_CATEGORIES)
export const setInitiativesCategoriesList = createAction(constants.SET_INITIATIVES_CATEGORIES)

export const setActiveCostFilters = createAction(constants.SET_ACTIVE_COST_FILTERS)

export const setMirrorTeams = createAction(constants.SET_MIRROR_TEAMS)
export const setMirrorEditLoadingState = createAction(constants.SET_MIRROR_EDIT_LOADING_STATE)

export const getSubLinesForMirroring = createAction(constants.GET_SUB_LINES_FOR_MIRRORING)
export const setSubLinesForMirroring = createAction(constants.SET_SUB_LINES_FOR_MIRRORING)

export const saveInitiativeFetchParams = createAction(constants.SAVE_INITIATIVE_FETCH_PARAMS)
export const resetAllInitiativeFilters = createAction(constants.RESET_ALL_INITIATIVE_FILTERS)

// Libs
import { map, every, reject } from 'lodash'
import { filter, groupBy } from 'lodash/fp'
import { listItemTypes } from '../constants'
import { valueGetterWithInterdependency } from '@shared/DayOne/utils/mapDayOneData'
import { processItemFields } from '@common/accessController/strategies/deliverables/constants'

const covertProcessesToRequest = (data: $TSFixMe) =>
  map(data, (item) => ({
    keyProcessId: item.id,
    projectId: null,
    tasks: [],
  }))

export const formatSelectedProcesses = (rowNodes: $TSFixMe) => {
  const allData = map(rowNodes, ({ data }) => data)

  // If only Key Processes are selected (without children)
  // -> return an array of objects with filled keyProcessId
  if (every(allData, ['type', listItemTypes.KEY_PROCESS])) {
    return covertProcessesToRequest(allData)
  }

  const selectedKeyProcesses = filter(['type', listItemTypes.KEY_PROCESS], allData)

  const dataWOProcesses = reject(allData, ['type', listItemTypes.KEY_PROCESS])
  const processesIdWithChildren = Object.keys(groupBy('keyProcessId', dataWOProcesses))
  const orphansKeyProcesses = filter(
    (data) => !processesIdWithChildren.includes(data.id.toString()),
    selectedKeyProcesses,
  )

  const projectTaskMap = new Map()

  dataWOProcesses.forEach((item) => {
    const id = item.projectId || item.id
    if (id) {
      if (projectTaskMap.has(id)) {
        return projectTaskMap.set(id, [...projectTaskMap.get(id), item])
      }

      return projectTaskMap.set(id, [item])
    }
  })

  const formattedKeyProcess = [...covertProcessesToRequest(orphansKeyProcesses)] as $TSFixMe[]
  projectTaskMap.forEach((item) => {
    if (every(item, ['type', listItemTypes.PROJECT])) {
      return formattedKeyProcess.push({
        keyProcessId: item[0].keyProcessId,
        projectId: item[0].id,
        tasks: [],
      })
    }

    const tasks = reject(item, ['type', listItemTypes.PROJECT])

    return formattedKeyProcess.push({
      keyProcessId: tasks[0].keyProcessId,
      projectId: tasks[0].projectId,
      tasks: map(tasks, ({ id }) => id),
    })
  })

  return formattedKeyProcess
}

export const getSuggestItemInputValue = (data: $TSFixMe) => {
  const { name } = data

  const projectListId = valueGetterWithInterdependency(processItemFields.PROJECT_LIST_ID)({ data })

  return name ? `${projectListId} ${name}` : ''
}

const prefix = '@dataRequestTracker/'

export const FETCH_DATA_REQUESTS = `${prefix}FETCH_DATA_REQUESTS`
export const SET_DATA_REQUESTS = `${prefix}SET_DATA_REQUESTS`

export const CREATE_DATA_REQUEST = `${prefix}CREATE_DATA_REQUESTS`
export const CREATE_DATA_REQUEST_SUCCESS = `${prefix}CREATE_DATA_REQUESTS_SUCCESS`

export const DELETE_DATA_REQUEST = `${prefix}DELETE_DATA_REQUESTS`
export const DELETE_DATA_REQUEST_SUCCESS = `${prefix}DELETE_DATA_REQUESTS_SUCCESS`

export const UPDATE_DATA_REQUEST = `${prefix}UPDATE_DATA_REQUEST`
export const UPDATE_DATA_REQUEST_SUCCESS = `${prefix}UPDATE_DATA_REQUESTS_SUCCESS`

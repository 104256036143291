import * as deliverableActions from '@teamDeliverables/actions/actionTypes'
import * as customDeliverableActions from '@customDeliverable/actions/actionTypes'
import { actionTypes } from '../../utils'

const constants = {
  ...deliverableActions,
  ...customDeliverableActions,
}

export default {
  [constants.FETCH_TEAM_DELIVERABLE_DATA]: {
    [actionTypes.FAIL]: ['We were unable to load the data for this deliverable'],
  },
  [constants.SET_DELIVERABLE_STATUS]: {
    [actionTypes.SUCCESS]: ['You have successfully updated the status of this deliverable'],
    [actionTypes.FAIL]: ['We were unable to update the status of this deliverable'],
    [actionTypes.VALIDATION_FAIL]: ['Please confirm the values in the fields highlighted in red before proceeding'],
  },
  [constants.FETCH_TEAM_DELIVERABLES_DATA]: {
    [actionTypes.FAIL]: ['We were unable to load the deliverables'],
  },
  [constants.FETCH_DELIVERABLES_TRACKER_DATA]: {
    [actionTypes.FAIL]: ['We were unable to load the Deliverables Tracker'],
  },
  [constants.FETCH_COMMENTS]: {
    [actionTypes.FAIL]: ['We were unable to load the comments on this deliverable'],
  },
  [constants.CREATE_COMMENT]: {
    [actionTypes.FAIL]: ["Comment hasn't been saved"],
  },
  [constants.FETCH_ATTACHMENTS]: {
    [actionTypes.FAIL]: ['We were unable to load the file attachment'],
  },
  [constants.DELETE_ATTACHMENT]: {
    [actionTypes.FAIL]: ['We were unable to delete the file attachment'],
  },
  [constants.CREATE_ATTACHMENT]: {
    [actionTypes.FAIL]: ['We were unable to upload the file attachment'],
  },
  [constants.SET_CUSTOM_DELIVERABLE_STATUS]: {
    [actionTypes.VALIDATION_FAIL]: ['Please upload a template below in order to submit this deliverable'],
  },
  [constants.SET_ONE_DAY_DELIVERABLE_STATUS]: {
    [actionTypes.VALIDATION_FAIL]: [
      'To submit this deliverable, please add at least one project under each of the required key processes highlighted in red',
    ],
  },
  [constants.SET_ONE_DAY_COPY_DELIVERABLE_STATUS]: {
    [actionTypes.VALIDATION_FAIL]: [
      'To submit this deliverable, please add at least two tasks under each of the required projects highlighted in red',
    ],
  },
}

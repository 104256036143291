import { put, call } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getProgramProjectPlanApi } from '@common/net'
import { setProjectList } from '@dayOne/actions/actions'

export const fetchProgramProjectPlanData = createSaga(function* fetchProgramProjectPlanData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = action.payload

  const programProjectPlanApi = yield getProgramProjectPlanApi()
  const data = yield call(programProjectPlanApi.request('getProgramProjectPlanData', { query: { teamId } }))

  yield put(
    setProjectList({
      dayOneProjectList: data,
      teamId,
    }),
  )
},
true)

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchDay1IssueKPIsData = createAction(constants.FETCH_DAY1_ISSUE_KPI_DATA, null, getMetaWithActionLoading)
export const setDay1IssueKPIsData = createAction(constants.SET_DAY1_ISSUE_KPI_DATA)

export const fetchDay1IssueKPIsByTeamsData = createAction(
  constants.FETCH_DAY1_ISSUE_KPI_BY_TEAMS_DATA,
  null,
  getMetaWithActionLoading,
)
export const setDay1IssueKPIsByTeamsData = createAction(constants.SET_DAY1_ISSUE_KPI_BY_TEAMS_DATA)

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class StatusUpdateApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  getYear5PipelineSummary = ({ query }: $TSFixMe) => {
    const { teamId, subtype1, subtype2, subtype3, year } = query

    const queryPayload: $TSFixMe = { synergyType: subtype1, subtype1: subtype2, subtype3: subtype3 }

    if (year) queryPayload.year = year

    return this.call({
      pathname: `teams/${teamId}/value-capture/v2/pipeline-summary`,
      query: queryPayload,
    })
  }

  getYearlyImpactChart = ({ query }: $TSFixMe) => {
    const { teamId, subtype1, subtype2, subtype3 } = query

    return this.call({
      pathname: `teams/${teamId}/value-capture/v2/yearly-pipeline-execution`,
      query: { synergyType: subtype1, subtype1: subtype2, subtype3: subtype3 },
    })
  }

  getStatusUpdateOneTimeCosts = ({ query: { teamId, categoryIds } }: $TSFixMe) => {
    const query = categoryIds ? { categoryIds: JSON.stringify(categoryIds) } : {}

    return this.call({
      pathname: `teams/${teamId}/status-update/one-time-cost`,
      query,
    })
  }
}

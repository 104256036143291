import { isKeyProcess } from './getProcessStatus'
import { isEqual, isObject, pick, lowerCase } from 'lodash/fp'
import { defaultWindowWidth } from '../constants'
import { combineGridValidators, validateHierarchyValueUniqueness } from '@helpers/gridValidations'
import { lengthValidation } from '@helpers/validators'
import { getIndentClassName, textFilterParamsWithIncludedChildren } from '@shared/Grid/utils/general'
import { isEditableField } from './getColumnDefs'
import { processItemFields } from '@common/accessController/strategies/deliverables/constants'
import { getTooltipFromValueGetter } from '@views/shared/Grid/utils/realTimeUtils'
import { defaultClassRules, InputCellEditor } from '@imo/imo-ui-toolkit'
import KeyProcessCell from '@shared/DayOne/components/KeyProcessCell'
import { TeamKeyProcessProjectOrTask } from '@common/types/dtos/DayOneProjectListDto'

const KP_WIDTH_WITH_ASIDE_PANEL = 425
const KP_WIDTH_WITHOUT_ASIDE_PANEL = 543
const KP_DEFAULT_WIDTH_WITHOUT_ASIDE_PANEL = 270
export const DEFAULT_KEY_PROCESS_PROJECT_TASK_WIDTH = 340

type Props = {
  data: TeamKeyProcessProjectOrTask
}

export const nameValueGetter = (props: Props) => {
  const { data } = props

  return {
    name: data.name,
    required: isKeyProcess(data) && data?.required,
    mirror: isKeyProcess(data) && data.mirror,
    toString() {
      return data.name
    },
  }
}

export const nameValueSetter = (props: $TSFixMe) => {
  const { newValue, data } = props

  if (isObject(newValue)) return false

  const validators = [validateHierarchyValueUniqueness(false, lowerCase(data?.type))]

  if (!data.isNew) {
    validators.push(lengthValidation(null, 1, 'This field cannot be empty'))
  }

  return combineGridValidators(props, processItemFields.NAME, validators)
}

export const nameValueEqualCheck = (value1: $TSFixMe, value2: $TSFixMe) => {
  const pickValue = pick([processItemFields.NAME, 'isOutgoing', 'required', 'uncompleted'])

  return isEqual(pickValue(value1), pickValue(value2))
}

const autoGroupColumn = {
  headerName: 'Key process/project/task',
  field: processItemFields.NAME,
  valueGetter: nameValueGetter,
  equals: nameValueEqualCheck,
  cellClass: getIndentClassName,
  cellEditor: InputCellEditor,
  cellEditorParams: {
    maxLength: 400,
    placeholder: 'Enter title here',
  },
  minWidth: 170,
  editable: isEditableField,
  valueSetter: nameValueSetter,
  cellClassRules: defaultClassRules,
  headerClass: 'required',
  // FIXME: temporary solution to pass tests from src/views/myImoClient/components/VCTrackerV2/utils.spec.js
  ...(textFilterParamsWithIncludedChildren &&
    textFilterParamsWithIncludedChildren({
      withHeaderSearch: true,
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
        suppressEnterExpand: true,
        innerRenderer: KeyProcessCell,
      },
    })),
  pinned: true,
  resizable: true,
  tooltipParams: {
    truncateTargetSelector: '.ag-cell-tooltip-class-anchor .ag-group-value > div > div',
  },
}

export const dayOneAutoGroupColumnDef = (userWidth?: number) => ({
  ...autoGroupColumn,
  width: userWidth ?? DEFAULT_KEY_PROCESS_PROJECT_TASK_WIDTH,
  cellClassRules: defaultClassRules,
  tooltipValueGetter: getTooltipFromValueGetter,
  tooltipParams: {
    alwaysShowTooltip: (params: $TSFixMe) => Boolean(getTooltipFromValueGetter(params)),
    truncateTargetSelector: '.ag-cell-tooltip-class-anchor .ag-group-value > div > div',
  },
})

export const getAutoGroupColDef = (gridWidth: $TSFixMe, isShowAsidePanel = true) => {
  const coefficient = isShowAsidePanel ? KP_WIDTH_WITH_ASIDE_PANEL : KP_WIDTH_WITHOUT_ASIDE_PANEL
  const width = gridWidth ? (gridWidth * coefficient) / defaultWindowWidth : KP_DEFAULT_WIDTH_WITHOUT_ASIDE_PANEL

  return {
    ...autoGroupColumn,
    width: width,
    minWidth: width,
    pinned: false,
  }
}

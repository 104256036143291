const prefix = '@decisionLog/'

export const FETCH_TR_DECISION_LOG_DATA = `${prefix}FETCH_TR_DECISION_LOG_DATA`
export const UPDATE_TR_DECISION_LOG_DATA = `${prefix}UPDATE_TR_DECISION_LOG_DATA`
export const FETCH_DECISION_LOG_DATA_PENDING = `${prefix}FETCH_DECISION_LOG_DATA_PENDING`
export const FETCH_DECISION_LOG_DATA_SUCCESS = `${prefix}FETCH_DECISION_LOG_DATA_SUCCESS`
export const FETCH_DECISION_LOG_DATA_FAIL = `${prefix}FETCH_DECISION_LOG_DATA_FAIL`

export const UPDATE_DECISION_LOG_DATA_PENDING = `${prefix}UPDATE_DECISION_LOG_DATA_PENDING`
export const UPDATE_DECISION_LOG_DATA_SUCCESS = `${prefix}UPDATE_DECISION_LOG_DATA_SUCCESS`
export const UPDATE_DECISION_LOG_DATA_FAIL = `${prefix}UPDATE_DECISION_LOG_DATA_FAIL`
export const ADD_DECISION_LOG_DATA = `${prefix}ADD_DECISION_LOG_DATA`
export const ADD_DECISION_LOG_DATA_SUCCESS = `${prefix}ADD_DECISION_LOG_DATA_SUCCESS`
export const DELETE_DECISION_LOG_DATA_PENDING = `${prefix}DELETE_DECISION_LOG_DATA_PENDING`
export const DELETE_DECISION_LOG_DATA_SUCCESS = `${prefix}DELETE_DECISION_LOG_DATA_SUCCESS`
export const DELETE_DECISION_LOG_DATA_FAIL = `${prefix}DELETE_DECISION_LOG_DATA_SUCCESS`

export const FETCH_CONTROL_TOWER_DECISION_LOG_DATA = `${prefix}FETCH_CONTROL_TOWER_DECISION_LOG_DATA`
export const UPDATE_CONTROL_TOWER_DECISION_LOG_DATA = `${prefix}UPDATE_CONTROL_TOWER_DECISION_LOG_DATA`
export const FETCH_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS = `${prefix}FETCH_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS`

export const UPDATE_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS = `${prefix}UPDATE_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS`

import { call } from 'redux-saga/effects'
import { getGenericApi } from '@common/net'
import { runDownloadByUrl, getCompanyLogoPath } from '@helpers/utils'
import { createSaga } from '@common/sagaCreator/createSaga'

export const downloadFile = createSaga(function* downloadFile(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { key, directory, name } = action.payload
  const api = yield getGenericApi()
  const { signedUrl } = yield call(api.request('getFileSignedUrl', { query: { key, directory } }))

  yield call(runDownloadByUrl, { url: signedUrl, name })
},
true)

export const uploadFile = createSaga(function* uploadFile({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { fileKey, file } = payload

  const api = yield getGenericApi()
  yield call(api.request('createFile', { body: file, query: { key: fileKey } }))
},
true)

export const deleteFile = createSaga(function* deleteFile({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { fileKey, directory } = payload
  const { companyLogoPath } = getCompanyLogoPath(fileKey)

  const api = yield getGenericApi()
  yield call(api.request('deleteFile', { query: { key: companyLogoPath, directory } }))
},
true)

import { handleActions } from 'redux-actions'
import * as constants from '@myImoConfigActions/teamImoHierarchy/actionTypes'
import { updateImoTeams } from '@helpers/teamHierarchy'

export interface ITeamIMOHierarchyState {
  deliverableTypes: $TSFixMe[]
  integrationTeams: $TSFixMe[]
  centralTeams: $TSFixMe[]
  imoTeams: $TSFixMe[]
}

const initialState: ITeamIMOHierarchyState = {
  deliverableTypes: [],
  integrationTeams: [],
  centralTeams: [],
  imoTeams: [],
}

export const teamImoHierarchyReducer = handleActions<ITeamIMOHierarchyState, $TSFixMe>(
  {
    [constants.GET_TEAM_DATA]: (state) => {
      return { ...state }
    },
    [constants.CREATE_TEAM_HIERARCHY]: (state, action) => {
      const payload = action.payload

      return { ...state, payload }
    },
    [constants.ON_TEAM_DATA_SUCCESS]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    [constants.UPDATE_INTEGRATION_TEAM_HIERARCHY]: (state, action) => {
      const { integrationTeams } = action.payload
      const imoTeams = updateImoTeams(state.imoTeams, [...integrationTeams])

      return { ...state, integrationTeams, imoTeams }
    },
    [constants.UPDATE_INTEGRATION_TEAM]: (state, action) => {
      const { id } = action.payload
      const updatedIntegrationTeams = state.integrationTeams.map((team) => (team.id === id ? action.payload : team))

      return { ...state, integrationTeams: updatedIntegrationTeams }
    },
    [constants.UPDATE_CENTRAL_TEAM_HIERARCHY]: (state, action) => {
      const { centralTeams } = action.payload
      const imoTeams = updateImoTeams(state.imoTeams, [...centralTeams])

      return { ...state, centralTeams, imoTeams }
    },
    [constants.UPDATE_CENTRAL_TEAM]: (state, action) => {
      const { id } = action.payload
      const updatedcentralTeams = state.centralTeams.map((team) => (team.id === id ? action.payload : team))

      return { ...state, centralTeams: updatedcentralTeams }
    },
    [constants.UPDATE_IMO_TEAM_HIERARCHY]: (state, action) => {
      const { imoTeams } = action.payload
      const updateImoTeam = updateImoTeams(imoTeams, [...imoTeams])

      return { ...state, imoTeams: updateImoTeam }
    },
    [constants.UPDATE_IMO_TEAM]: (state, action) => {
      const { id } = action.payload
      const updatedImoTeams = state.imoTeams.map((team) => (team.id === id ? action.payload : team))

      return { ...state, imoTeams: updatedImoTeams }
    },
  },
  initialState,
)

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class ProjectMapApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  getProjectMapItems = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${query.teamId}/project-map/v2`,
    })
  }

  linkProjectMapItem = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/project-map/link`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  unlinkProjectMapItem = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/project-map/unlink`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }
}

import { put, select } from 'redux-saga/effects'
import { setSelectedTeamIds } from '@generic/actions/actions'
import { getAllReporters, getLocationParams } from '@generic/selectors'
import { calculateDefaultTeams } from './utils'

export function* setSelectedTeams(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const teams = yield select(getAllReporters)
  const { teamId: locationTeamId } = yield select(getLocationParams)
  const selectedTeamIds = calculateDefaultTeams(teams, locationTeamId)

  yield put(setSelectedTeamIds(selectedTeamIds))
}

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class VCSynergyCategoryApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  getSynergyCategory = ({ query }: $TSFixMe) => {
    const { teamId, synergyType } = query

    return this.call({
      pathname: `teams/${teamId}/synergy-category`,
      query: {
        synergyType,
      },
    })
  }

  createSynergyCategory = ({ body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `synergy-category`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  getBaselineAssumption = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/team-baseline-assumption?synergyType=${query.synergyType}`,
    })
  }

  updateBaselineAssumptions = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/team-baseline-assumption/${query.assumptionId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  updateSynergyCategory = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `synergy-category/${query.categoryId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteSynergyCategory = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `synergy-category/${query.categoryId}?synergyType=${query.synergyType}`,
      },
      { method: 'DELETE' },
    )
  }
}

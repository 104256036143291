import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchVCTeams = createAction(constants.FETCH_VC_TEAMS)
export const setVCTeams = createAction(constants.SET_VC_TEAMS)

export const fetchTargetList = createAction(constants.FETCH_TARGET_LIST, null, getMetaWithActionLoading)
export const setTargetList = createAction(constants.SET_TARGET_LIST)

export const createTarget = createAction(constants.CREATE_TARGET)
export const createTargetSuccess = createAction(constants.CREATE_TARGET_SUCCESS)

export const updateTarget = createAction(constants.UPDATE_TARGET)
export const updateTargetSuccess = createAction(constants.UPDATE_TARGET_SUCCESS)

export const deleteTarget = createAction(constants.DELETE_TARGET)
export const deleteTargetSuccess = createAction(constants.DELETE_TARGET_SUCCESS)

export const setSelectedTargetId = createAction(constants.SET_SELECTED_TARGET)

export const fetchVCBaselineAssumption = createAction(
  constants.FETCH_VC_BASELINE_ASSUMPTIONS,
  null,
  getMetaWithActionLoading,
)
export const setVCBaselineAssumption = createAction(constants.SET_VC_BASELINE_ASSUMPTIONS)

export const updateVCBaselineAssumption = createAction(constants.UPDATE_VC_BASELINE_ASSUMPTIONS)
export const updateVCBaselineAssumptionSuccess = createAction(constants.UPDATE_VC_BASELINE_ASSUMPTIONS_SUCCESS)

export const fetchVCSynergyCategories = createAction(
  constants.FETCH_VC_SYNERGY_CATEGORY,
  null,
  getMetaWithActionLoading,
)
export const setVCSynergyCategories = createAction(constants.SET_VC_SYNERGY_CATEGORY)

export const createVCSynergyCategories = createAction(constants.CREATE_VC_SYNERGY_CATEGORY)
export const createVCSynergyCategoriesSuccess = createAction(constants.CREATE_VC_SYNERGY_CATEGORY_SUCCESS)

export const updateVCSynergyCategory = createAction(constants.UPDATE_VC_SYNERGY_CATEGORY)
export const updateVCSynergyCategoriesSuccess = createAction(constants.UPDATE_VC_SYNERGY_CATEGORY_SUCCESS)

export const deleteVCSynergyCategory = createAction(constants.DELETE_VC_SYNERGY_CATEGORY)
export const deleteVCSynergyCategoriesSuccess = createAction(constants.DELETE_VC_SYNERGY_CATEGORY_SUCCESS)

export const setVCOneTimeCosts = createAction(constants.SET_VC_ONE_TIME_COSTS)
export const createVCOneTimeCostsSuccess = createAction(constants.CREATE_VC_ONE_TIME_COSTS_SUCCESS)

export const updateVCOneTimeCostsSuccess = createAction(constants.UPDATE_VC_ONE_TIME_COSTS_SUCCESS)

export const deleteVCOneTimeCostsSuccess = createAction(constants.DELETE_VC_ONE_TIME_COSTS_SUCCESS)

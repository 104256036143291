import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import * as constants from '@valueCaptureTrackerV2/summaryStatus/actions/actionTypes'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getVCTrackerV2Api } from '@common/net'
import { setSummaryStatus } from '@valueCaptureTrackerV2/summaryStatus/actions'
import { getIsDayOneMissed } from '@generic/selectors'

export const fetchSummaryStatus = createSaga(function* fetchSummaryStatus({
  payload: { teamId, synergyType },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const isDayOneMissed = yield select(getIsDayOneMissed)

  if (isDayOneMissed) return

  const api = yield getVCTrackerV2Api()
  const summaryStatus = yield call(api.request('getTeamVCStatusV2', { query: { teamId, synergyType, version: 3 } }))

  yield put(setSummaryStatus(summaryStatus))
})

export const saveTeamOrder = createSaga(function* saveTeamOrder({
  payload: { teamId, teamIds },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getVCTrackerV2Api()

  yield call(api.request('saveTeamOrder', { query: { teamId }, body: { teamIds } }))
})

export default function* summaryStatusSaga() {
  yield all([
    takeLatest(constants.FETCH_SUMMARY_STATUS, fetchSummaryStatus),
    takeLatest(constants.SAVE_TEAM_ORDER, saveTeamOrder),
  ])
}

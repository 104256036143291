import { accessRights, teams } from '../constants'
import { routesList } from '../routesList'
import { CENTRAL_LEAD_PERMISSIONS } from './centralLead'

export const CENTRAL_ORG_DESIGN_LEAD_PERMISSIONS = {
  ...CENTRAL_LEAD_PERMISSIONS,
  priority: 2,
  name: teams.CENTRAL_ORG_DESIGN,
  teamsPermissions: {
    deliverable: accessRights.READ_WRITE,
  },
  permissions: [
    ...CENTRAL_LEAD_PERMISSIONS.permissions,
    {
      route: routesList.ORG_DESIGN_TRACKER.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
  ],
}

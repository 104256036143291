import { has, map, find, get } from 'lodash'
import { stringComparator } from '@helpers/utilsComparators'

// TODO: redundant??
export const getOwnerObject = (owner, user) =>
  user ? { ...owner, name: `${user.name} ${user.surname}` } : { name: owner.displayName, id: null }

// For single owner value
export const transformOwnerToString = ({ valueFormatted }) => valueFormatted.name

export const formatOwner = ({ context: { users }, value: owner }) => {
  if (!owner) {
    return { name: '', id: null }
  }

  const userByOwnerId = has(owner, 'id') ? find(users, { id: owner.id }) : null

  return getOwnerObject(owner, userByOwnerId)
}

export const getInitialOwnerObject = (owner) =>
  owner.id ? { id: owner.id } : { displayName: owner.name || owner.displayName }

// For multiple owner values
export const formatOwners = ({ context: { users }, value: owners }) =>
  map(owners, (owner) => getOwnerObject(owner, find(users, { id: owner.id })))

export const pointOfContactValueGetter = ({ context: { users }, data: { pointOfContact } }) =>
  map(pointOfContact, (contact) => {
    const userByName = get(contact, 'displayName')
    const userById = find(users, { id: contact.id })

    return { ...contact, name: userById ? `${userById.name} ${userById.surname}` : userByName }
  })

export const pointOfContactStringComparator = (valueA, valueB) => {
  const valueAName = valueA[0]?.name
  const valueBName = valueB[0]?.name

  return stringComparator(valueAName, valueBName)
}

export const transformOwnersToString = ({ valueFormatted }) => valueFormatted.map(({ name }) => name).join(', ')

export const parseOwners = (owners) => owners.map((owner) => getInitialOwnerObject(owner))

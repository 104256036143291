import { get, isFunction, isString } from 'lodash'
import { call, put } from 'redux-saga/effects'
import { setActionState, setLoader, showWarningMessage, logout } from '@generic/actions/actions'
import { actionTypes } from '@common/notifications/utils'
import { getActionType, getErrorMessage, isExtendedNotification } from '@common/notifications'
import { LOADING_ACTION, loadingState } from '@helpers/constants'
import { isDataLoadingState } from '@helpers/utils'
import { CustomHttpClientErrorType } from '../net/HttpClient'

const getLoadingAction = ({ action, loadingAction, meta = {} }: $TSFixMe) => {
  const { ignoreLoading, globalLoader } = meta
  const loader = globalLoader || loadingAction
  const shouldSaveActionState = meta[LOADING_ACTION]
  const isPresentLoader = loader || shouldSaveActionState

  //Don't set loader if we pass to meta ignoreLoading
  if (ignoreLoading) {
    return null
  }

  //Don't set loader if there is no loader param added through createSaga and meta param to save loading state to reducer
  if (!isPresentLoader) {
    return null
  }

  return (state: $TSFixMe) => {
    if (shouldSaveActionState || loader === LOADING_ACTION) {
      return setActionState({ type: action, state })
    }

    if (!isString(loader)) {
      return setLoader({ loader: isDataLoadingState(state) })
    }

    return { type: loader, payload: state }
  }
}

export const createSaga = function createSaga(
  saga: $TSFixMe,
  withLoader?: $TSFixMe,
  errorNotificationHandler?:
    | ((error: CustomHttpClientErrorType) => CustomHttpClientErrorType | null)
    | null
    | undefined,
  onCatch?: $TSFixMe,
  onFinally?: $TSFixMe,
) {
  return function* sagaFunction(params: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const action = get(params, 'type')
    const meta = get(params, 'meta', {})
    //TODO refactor for old loading actions
    const loadingAction = getLoadingAction({ action, loadingAction: withLoader, meta })

    try {
      if (loadingAction) {
        yield put(loadingAction(loadingState.LOADING))
      }
      const props = yield call(saga, params)
      if (loadingAction) {
        yield put(loadingAction(loadingState.SUCCESS))
      }

      if (action)
        yield put(
          showWarningMessage({
            action,
            actionType: actionTypes.SUCCESS,
            props,
          }),
        )

      return props
    } catch (e: $TSFixMe) {
      const { props, name, status, message } = e as $TSFixMe
      const refresh = get(params, ['payload', 'refresh'])
      const timeout = get(params, ['payload', 'timeout'])
      const extendedNotification = isExtendedNotification(status, action)

      if (loadingAction) yield put(loadingAction(loadingState.FAILURE))

      const refreshPageAction = {
        onClick: refresh || null,
        text: 'Refresh',
      }

      const error = {
        action,
        actionType: getActionType(name, extendedNotification),
        props,
        errorMessage: getErrorMessage(e),
        additionalAction: extendedNotification ? refreshPageAction : null,
        isExtendedNotification: extendedNotification,
        timeout: timeout,
      }

      if (action) {
        yield put(
          showWarningMessage(
            isFunction(errorNotificationHandler) ? Object.assign(error, errorNotificationHandler(e)) : error,
          ),
        )
      }

      if (status === 403 && message === 'Forbidden') {
        // catching standard NVT 403 response, myIMO 403s have custom messages
        yield put(
          logout({
            isUserLogout: false,
            context: {
              reason: 'FORBIDDEN',
              status,
              message,
              action,
              currentTime: Date.now(),
              userAgent: window?.navigator?.userAgent,
            },
          }),
        )
      }

      if (onCatch) {
        yield onCatch(e)
      }

      return null
    } finally {
      if (onFinally) {
        yield onFinally()
      }
    }
  }
}

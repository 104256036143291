import { put, call } from 'redux-saga/effects'
import { pick } from 'lodash/fp'
import * as actions from '../actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getTeamCharterApi } from '@common/net'

export const fetchOutScopeProcesses = createSaga(function* fetchOutScopeProcess({
  teamId,
  date,
}: {
  teamId: string
  date: string
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamCharterApi()
  const outScopeProcesses = yield call(api.request('getOutScopeKeyProcesses', { query: { teamId, date } }))

  yield put(actions.setOutScopeKeyProcesses(outScopeProcesses))
})

export const createOutScopeKeyProcessData = createSaga(function* createOutScopeKeyProcessData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data } = payload
  const api = yield getTeamCharterApi()
  const { id, keyProcessId } = yield call(
    api.request('createOutScopeKeyProcess', {
      query: { teamId: data.teamId },
      body: pick(['name'], data),
    }),
  )

  const newData = { ...data, id, keyProcessId, isNew: false }
  yield put(actions.setUpdateOutScopeKeyProcess({ data: newData, keyProcessId: data.keyProcessId }))
})

export const updateOutScopeKeyProcessData = createSaga(function* updateOutScopeKeyProcessData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data } = payload
  const api = yield getTeamCharterApi()
  yield call(
    api.request('updateOutScopeKeyProcess', {
      query: { teamId: data.teamId, id: data.keyProcessId },
      body: pick(['name'], data),
    }),
  )

  yield put(actions.setUpdateOutScopeKeyProcess({ data, keyProcessId: data.keyProcessId }))
})

export const deleteOutScopeKeyProcessData = createSaga(function* deleteOutScopeKeyProcessData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { keyProcessId, teamId } = payload
  const api = yield getTeamCharterApi()

  yield call(api.request('deleteOutScopeKeyProcess', { query: { teamId, id: keyProcessId } }))

  yield put(actions.removeOutScopeKeyProcess({ keyProcessId }))
})

import { getOwner } from '../../utils/ownerCellUtils'
import Truncate from 'react-truncate'
import { CssTruncate } from './OwnerCellRenderer.style'
import React from 'react'

const OwnerCellRenderer = (params) => {
  const {
    value,
    context: { users },
    getLines,
    lines,
    placeholder = '',
  } = params

  const owner = !!value && Object.values(value).some((v) => !!v) ? getOwner(value, users) : placeholder
  const totalLines = getLines ? getLines(params) : lines

  return totalLines > 1 ? (
    <Truncate trimWhitespace={true} style={{ width: '100%' }} lines={totalLines}>
      {owner}
    </Truncate>
  ) : (
    <CssTruncate>{owner}</CssTruncate>
  )
}

export default OwnerCellRenderer

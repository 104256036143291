import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchTSA = createAction(constants.FETCH_TSA, null, getMetaWithActionLoading)
export const fetchTeamResourcesTSA = createAction(constants.FETCH_TEAM_RESOURCES_TSA, null, getMetaWithActionLoading)
export const setTSA = createAction(constants.SET_TSA)
export const resetTSA = createAction(constants.RESET_TSA)

export const createTSA = createAction(constants.CREATE_TSA)
export const createTSASuccess = createAction(constants.CREATE_TSA_SUCCESS)

export const updateTSA = createAction(constants.UPDATE_TSA)
export const updateTSASuccess = createAction(constants.UPDATE_TSA_SUCCESS)

export const deleteTSA = createAction(constants.DELETE_TSA)
export const deleteTSASuccess = createAction(constants.DELETE_TSA_SUCCESS)

export const fetchTSAProcessesFromLibrary = createAction(
  constants.FETCH_TSA_PROCESSES_FROM_LIBRARY,
  null,
  getMetaWithActionLoading,
)
export const setTSAProcessesFromLibrary = createAction(constants.SET_TSA_PROCESSES_FROM_LIBRARY)
export const updateTSAProcessesFromLibrary = createAction(constants.UPDATE_TSA_PROCESSES_FROM_LIBRARY)
export const resetTSAProcessesFromLibrary = createAction(constants.RESET_TSA_PROCESSES_FROM_LIBRARY)
export const addTSAProcessesFromLibrary = createAction(
  constants.ADD_TSA_PROCESSES_FROM_LIBRARY,
  null,
  getMetaWithActionLoading,
)

export const getSnapshotMinDate = createAction(constants.GET_SNAPSHOT_MIN_DATE)
export const setSnapshotMinDate = createAction(constants.SET_SNAPSHOT_MIN_DATE)

export const setSelectedTSA = createAction(constants.SET_SELECTED_TSA)

export const setMasterListTSAStatus = createAction(constants.SET_MASTER_LIST_TSA_STATUS)
export const getMasterListTSAStatus = createAction(constants.GET_MASTER_LIST_TSA_STATUS)
export const resetMasterListTSAStatus = createAction(constants.RESET_MASTER_LIST_TSA_STATUS)

export const fetchActivityLogPending = createAction(constants.FETCH_ACTIVITY_LOG_PENDING)
export const fetchActivityLogSuccess = createAction(constants.FETCH_ACTIVITY_LOG_SUCCESS)

export const fetchMoreActivityLogPending = createAction(constants.FETCH_MORE_ACTIVITY_LOG_PENDING)

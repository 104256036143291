import { createSaga } from '@common/sagaCreator/createSaga'
import { getImoControlTowerApi } from '@common/net'
import { call, put } from 'redux-saga/effects'
import { setParkingLotData, updateParkingLotDataSuccess } from '@steerCoRoadmap/actions/actions'

export const fetchParkingLotData = createSaga(function* fetchParkingLotData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = action.payload

  const api = yield getImoControlTowerApi()
  const data = yield call(
    api.request('getParkingLotData', {
      query: { teamId },
    }),
  )

  yield put(setParkingLotData(data))
},
true)

export const updateParkingLotData = createSaga(function* updateParkingLotData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, value } = action.payload

  const api = yield getImoControlTowerApi()
  yield call(
    api.request('updateParkingLotData', {
      query: { teamId },
      body: {
        value: {
          value,
        },
      },
    }),
  )

  yield put(updateParkingLotDataSuccess({ value }))
},
false)

interface Heap {
  track: (event: string, properties?: Object) => void
  identify: (identity: string) => void
  resetIdentity: () => void
  addUserProperties: (properties: Object) => void
  addEventProperties: (properties: Object) => void
  removeEventProperty: (property: string) => void
  clearEventProperties: () => void
  appid: string
  userId: string
  identity: string | null
  config: any
}

declare global {
  interface Window {
    JOB_SERVER: string
    API_SERVER: string
    WS_SERVER: string
    ASK_SERVER: string
    ASK_WS_SERVER: string
    IMO_PUB_SUB_SERVER: string
    SOCKET_IO_CLIENT_PATH: string
    ENABLE_COOKIE_AUTH: string
    NODE_ENV: string
    heap?: Heap
  }
}

const routerBase = () => {
  const path = new URL(document.baseURI).pathname

  return path ? path : '/'
}

const isTest = process.env.NODE_ENV === 'test'

export default {
  API_SERVER: window.API_SERVER || (isTest ? 'http://localhost/api' : '/api'),
  JOB_SERVER: window.JOB_SERVER || window.API_SERVER || (isTest ? 'http://localhost/api' : '/api'),
  WS_SERVER: window.WS_SERVER || (isTest ? 'ws://localhost/ws' : '/ws'),
  IMO_PUB_SUB_SERVER: window.IMO_PUB_SUB_SERVER,
  SOCKET_IO_CLIENT_PATH: window.SOCKET_IO_CLIENT_PATH,
  ASK_SERVER: window.ASK_SERVER,
  ASK_WS_SERVER: window.ASK_WS_SERVER,
  BASE_PATH: routerBase(),
  ENABLE_COOKIE_AUTH: window.ENABLE_COOKIE_AUTH === 'true',
  OWNERSHIP_FILTERS: false, // TODO: remove during https://mckinsey.atlassian.net/browse/IMO-6728,
  PRINT_ENABLE: localStorage.getItem('PRINT_ENABLE') === 'true',
  VC3_FEATURE: localStorage.getItem('VC3_FEATURE') === 'true',
}

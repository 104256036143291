import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchTeamResourcesMasterplan = createAction(
  constants.FETCH_TEAM_RESOURCES_MASTERPLAN,
  null,
  getMetaWithActionLoading,
)
export const updateTeamResourcesMasterplan = createAction(
  constants.UPDATE_TEAM_RESOURCES_MASTERPLAN,
  null,
  getMetaWithActionLoading,
)
export const setTeamResourcesMasterplan = createAction(constants.SET_TEAM_RESOURCES_MASTERPLAN)
export const downloadTeamResourcesMasterplan = createAction(constants.DOWNLOAD_TEAM_RESOURCES_MASTERPLAN)

import { all, takeLatest, takeEvery } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'
import { createTarget, deleteTarget, fetchTargetList, fetchVCTeams, updateTarget } from './targetList'
import {
  fetchVCSynergyCategories,
  createVCSynergyCategories,
  fetchVCBaselineAssumption,
  updateVCBaselineAssumption,
  updateVCSynergyCategory,
  deleteVCSynergyCategory,
} from './synergyCategories'

export default function* teamSetupSaga() {
  yield all([
    takeLatest(constants.FETCH_VC_TEAMS, fetchVCTeams),
    takeLatest(constants.FETCH_TARGET_LIST, fetchTargetList),
    takeLatest(constants.CREATE_TARGET, createTarget),
    takeLatest(constants.UPDATE_TARGET, updateTarget),
    takeLatest(constants.DELETE_TARGET, deleteTarget),
    takeEvery(constants.FETCH_VC_SYNERGY_CATEGORY, fetchVCSynergyCategories),
    takeLatest(constants.CREATE_VC_SYNERGY_CATEGORY, createVCSynergyCategories),
    takeLatest(constants.FETCH_VC_BASELINE_ASSUMPTIONS, fetchVCBaselineAssumption),
    takeLatest(constants.UPDATE_VC_BASELINE_ASSUMPTIONS, updateVCBaselineAssumption),
    takeLatest(constants.UPDATE_VC_SYNERGY_CATEGORY, updateVCSynergyCategory),
    takeLatest(constants.DELETE_VC_SYNERGY_CATEGORY, deleteVCSynergyCategory),
  ])
}

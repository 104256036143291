import { PHASES, TSAv2ColumnNames } from './constants'
import { cloneDeep, toNumber, flow, find, get, isEmpty, isNil, map, getOr, intersection, first } from 'lodash/fp'
import nanoid from 'nanoid'
import {
  deliverablesIds,
  TSA_V2_DELIVERABLES_IDS,
  TSA_V2_EXIT_PLANS as DEFAULT_TSA_TO_REDIRECT,
} from '@helpers/constants'
import { initiativeStatuses } from '@helpers/statuses'
import {
  isUniqueCombination,
  isUniqTsaId,
  suppressZeroAsTsaId,
  isRequiredField,
  isUniqKeyProcess,
} from '@helpers/schemas'
import { combineGridValidators } from '@imo/imo-ui-toolkit'
import { hasStarted } from '@helpers/dateFormatters'

export const getDefaultTSA = () => {
  const newTSA = cloneDeep(TSAv2ColumnNames)
  Object.keys(TSAv2ColumnNames).forEach((key) => {
    newTSA[key] = null
  })

  // set default values differ from null
  newTSA.id = nanoid()
  newTSA.isNew = true
  newTSA.isTsaPriority = false
  newTSA.isAllLocked = false
  newTSA.isPhase1Completed = false
  newTSA.isPhase1Locked = false
  newTSA.isPhase2Completed = false
  newTSA.isPhase2Locked = false
  newTSA.isPhase3Completed = false
  newTSA.isPhase3Locked = false
  newTSA.isPhase4Completed = false
  newTSA.isPhase4Locked = false
  newTSA.isPhase5Locked = false
  newTSA.otherLinkedProjects = []

  return newTSA
}

export const getOptions = (values = []) => {
  return values.map((value) => ({
    label: value,
    value,
  }))
}

export const transformTsaProcessFromLibraryToAdd = (data) => {
  const keyProcessesObject = {}

  data.forEach((process) => {
    if (!process.keyProcessId && process.selected) {
      keyProcessesObject[process.id] = []
    }
  })

  data.forEach((process) => {
    if (process.keyProcessId && process.selected) {
      keyProcessesObject[process.keyProcessId].push(process.id)
    }
  })

  return Object.keys(keyProcessesObject).map((keyProcessId) => ({
    teamKeyProcessV2Id: toNumber(keyProcessId),
    teamSubKeyProcessesIds: keyProcessesObject[keyProcessId],
  }))
}

export const isTeamHasActiveDeliverable = ({ teamId, teamDeliverables }) => {
  const deliverables = flow([
    find(({ id }) => `${id}` === `${teamId}`),
    get(['deliverableTypes', 'Master Planning', 'teamDeliverables']),
  ])(teamDeliverables?.integrationTeams)

  const connectedDeliverableIds = [
    deliverablesIds.postClosePlanningAssumptionsUpdate,
    deliverablesIds.mutualDiscoveryAndPlanningAssumptions,
    deliverablesIds.entanglementsAndPlanningAssumptions,
  ]

  return deliverables?.some(
    ({ deliverableId, active, status, startDate }) =>
      connectedDeliverableIds.includes(deliverableId) &&
      active &&
      status?.toLowerCase() !== initiativeStatuses.NOT_STARTED &&
      hasStarted(startDate),
  )
}

export const checkIsImportButtonVisible = ({ teamId, teamDeliverables, teams }) => {
  if (!isEmpty(teams)) {
    return true
  }

  return isTeamHasActiveDeliverable({ teamId, teamDeliverables })
}

export const getFileUrl = (teamId) => (date) => {
  const today = new Date()

  if (today.toDateString() === date.toDateString()) {
    return `teams/${teamId}/tsa/v2/deliverable/export`
  }

  return `teams/${teamId}/tsa/v2/deliverable/export?date=${date.toISOString()}`
}

export const getIntegrationTeamsWithActiveDeliverables = ({ teamDeliverables, deliverableKey }) =>
  teamDeliverables.integrationTeams.map((integrationTeam) => {
    const deliverableType = getOr({ teamDeliverables: [] }, deliverableKey, integrationTeam.deliverableTypes)

    return deliverableType.teamDeliverables.filter((teamDeliverables) => teamDeliverables.active)
  })

export const getIntegrationTeamsList = ({ integrationTeamsWithActiveDeliverables, teamDeliverables }) =>
  integrationTeamsWithActiveDeliverables
    .map((array, index) => (array.length ? teamDeliverables.integrationTeams[index] : null))
    .filter(Boolean)

export const getTeamId = ({ teamsList, teamName }) => {
  const integrationTeam = teamsList?.integrationTeams.find(({ team }) => team === teamName)

  return integrationTeam?.id
}

export const getFirstOptionValue = (options) => {
  const firstPreselectedOption = options?.[1]?.value

  return !isNil(firstPreselectedOption) ? firstPreselectedOption : null
}

export const valueSetterWithUniquenessChecking = (params) => {
  const {
    api,
    data,
    context,
    node,
    colDef: { field },
    newValue,
  } = params

  const rowsToTest = context.allData
  const rowToTest = node.data

  const validators = [isUniqueCombination(rowsToTest, field, rowToTest)]
  const value = getOr(newValue, 'name', newValue)

  return combineGridValidators({ newValue: value, api, data, node }, field, validators, false)
}

export const getDealDependentHeaderName = ({ column, dealType }) => column.dealTypeParams?.[dealType]?.headerName

export const getDealHeaderName = ({ column, dealType }) =>
  isNil(getDealDependentHeaderName({ column, dealType }))
    ? column.headerName
    : getDealDependentHeaderName({
        column,
        dealType,
      })

export const hasChildren = (column) => !isNil(column.children)

export const customizeColDefs = ({ columnDefs, dealType }) => {
  return map((column) => {
    const customizedColumn = {
      ...column,
      headerName: getDealHeaderName({ column, dealType }),
    }

    return hasChildren(column)
      ? {
          ...customizedColumn,
          children: customizeColDefs({ columnDefs: column.children, dealType }),
        }
      : customizedColumn
  })(columnDefs)
}

export const getPhaseCompleteColumnNames = () => Object.values(PHASES).map((phase) => `isPhase${phase}Completed`)

export const getActiveTSADeliverableToRedirect = (activeDeliverables) => {
  const activeTSADeliverables = intersection(activeDeliverables, TSA_V2_DELIVERABLES_IDS)

  if (isEmpty(activeTSADeliverables)) return null

  return activeDeliverables.includes(DEFAULT_TSA_TO_REDIRECT) ? DEFAULT_TSA_TO_REDIRECT : first(activeTSADeliverables)
}

export const valueSetterWithTsaIdUniqueness = (params) => {
  const {
    api,
    data,
    context,
    node,
    colDef: { field },
    newValue,
  } = params

  const validators = [isUniqTsaId(context.allData, data), suppressZeroAsTsaId, isRequiredField]
  const value = getOr(newValue, 'name', newValue)

  combineGridValidators({ newValue: value, api, data, node }, field, validators, false)
}

export const valueSetterWithKeyProcessUniqueness = (params) => {
  const {
    api,
    data,
    node,
    context,
    colDef: { field },
    newValue,
    oldValue,
  } = params

  if (oldValue.displayName === newValue.displayName) return false

  const validators = [isUniqKeyProcess(context.allData, data)]
  const isValid = combineGridValidators(
    {
      newValue: {
        keyProcess: newValue.displayName,
        teamKeyProcessV2Id: newValue.id,
      },
      api,
      data,
      node,
    },
    field,
    validators,
    false,
  )

  if (isValid) {
    data.teamKeyProcessV2Id = newValue.id
    data.keyProcess = newValue.displayName
  }
}

// Libs
import { put, call } from 'redux-saga/effects'

// Actions
import { setIMOLinkedLibrariesState, setLibraryProjectList } from '@dayOne/actions/actions'

// Utils
import { getDayOneApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { SET_LIBRARY_PROJECT_LIST_STATE } from '@dayOne/actions/actionTypes'

export const fetchAddLibraryButtonState = createSaga(function* fetchAddLibraryButtonState({
  payload: { imoTeamId },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getDayOneApi()
  const hasLinkedLibrary = yield call(api.request('getFlagLibraryRelatedToIMOTeam', { query: { imoTeamId } }))
  yield put(setIMOLinkedLibrariesState(hasLinkedLibrary))
})

export const fetchLibraryProjectList = createSaga(function* fetchLibraryProjectList({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { libFunctionId, libId, teamId } = payload

  const api = yield getDayOneApi()
  const libraryProjectList = yield call(api.request('getLibraryProjectList', { query: { libId } }))
  yield put(setLibraryProjectList({ libraryProjectList, isInitial: true, libFunctionId, teamId }))
},
SET_LIBRARY_PROJECT_LIST_STATE)

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@centralFunctionalTSA/actions/actionTypes'
import {
  fetchCentralFunctionalTSA,
  createCentralFunctionalTSA,
  updateCentralFunctionalTSA,
  deleteCentralFunctionalTSA,
  fetchProjectList,
} from './centralFunctionalTSA'

export default function* functionalActivitySaga() {
  yield all([takeLatest(constants.FETCH_CENTRAL_FUNCTIONAL_TSA, fetchCentralFunctionalTSA)])
  yield all([takeLatest(constants.FETCH_PROJECT_LIST, fetchProjectList)])
  yield all([takeLatest(constants.CREATE_CENTRAL_FUNCTIONAL_TSA, createCentralFunctionalTSA)])
  yield all([takeLatest(constants.UPDATE_CENTRAL_FUNCTIONAL_TSA, updateCentralFunctionalTSA)])
  yield all([takeLatest(constants.DELETE_CENTRAL_FUNCTIONAL_TSA, deleteCentralFunctionalTSA)])
}

import { handleActions } from 'redux-actions'
import * as constants from '@hypercareLog/actions/actionTypes'

export interface IHypercareLogState {
  issues: $TSFixMe[]
}

export const initialState = {
  issues: [],
}

const hypercareLogReducer = handleActions<IHypercareLogState, $TSFixMe>(
  {
    [constants.SET_HYPERCARE_LOG_DATA]: (state, action) => ({ issues: action.payload }),
    [constants.RESET_HYPERCARE_LOG_DATA]: () => ({ issues: initialState.issues }),

    [constants.ADD_ISSUE]: (state, action) => {
      const issues = [...state.issues]
      issues.push(action.payload)

      return { issues }
    },

    [constants.UPDATE_ISSUE_SUCCESS]: (state, action) => {
      const { id, issueId } = action.payload

      return {
        issues: state.issues.map((issue) => {
          return issue.id === id || issue.isNew ? { ...issue, id, issueId, isNew: undefined } : issue
        }),
      }
    },

    [constants.DELETE_ISSUE_SUCCESS]: (state, action) => {
      const { id } = action.payload

      return {
        issues: state.issues.filter((issue) => issue.id !== id),
      }
    },
  },
  initialState,
)

export default hypercareLogReducer

import * as constants from '@teamWorkspace/actions/actionTypes'
import { actionTypes } from '../../utils'

export default {
  [constants.FETCH_INITIAL_DATA]: {
    [actionTypes.FAIL]: ['We were unable to load the Team Workspace'],
  },

  [constants.FETCH_WHITEBOARD]: {
    [actionTypes.FAIL]: ['We were unable to load the Team Whiteboard'],
  },
  [constants.CREATE_WHITEBOARD]: {
    [actionTypes.FAIL]: ['We were unable to create a Team Whiteboard item'],
  },
  [constants.UPDATE_WHITEBOARD]: {
    [actionTypes.FAIL]: ['We were unable to update the Team Whiteboard'],
  },
  [constants.DELETE_WHITEBOARD]: {
    [actionTypes.FAIL]: ['We were unable to delete a Team Whiteboard item'],
  },

  [constants.FETCH_TEAM_ACTIONS]: {
    [actionTypes.FAIL]: ['We were unable to load the Team Actions'],
  },
  [constants.CREATE_TEAM_ACTION]: {
    [actionTypes.FAIL]: ['We were unable to create a Team Actions item'],
  },
  [constants.UPDATE_TEAM_ACTION]: {
    [actionTypes.FAIL]: ['We were unable to update the Team Actions'],
  },
  [constants.DELETE_TEAM_ACTION]: {
    [actionTypes.FAIL]: ['We were unable to delete a Team Actions item'],
  },

  [constants.FETCH_DECISIONS_LOG]: {
    [actionTypes.FAIL]: ['We were unable to load the Team Decision Log'],
  },
  [constants.CREATE_DECISIONS_LOG]: {
    [actionTypes.FAIL]: ['We were unable to create a  Team Decision Log item'],
  },
  [constants.UPDATE_DECISIONS_LOG]: {
    [actionTypes.FAIL]: ['We were unable to update the Team Decision Log'],
  },
  [constants.DELETE_DECISIONS_LOG]: {
    [actionTypes.FAIL]: ['We were unable to delete a Team Decision Log item'],
  },
  [constants.DELETE_DECISION_LOG_DATA_FAIL]: {
    [actionTypes.FAIL]: ['We were unable to delete a Team Decision Log item'],
  },

  [constants.FETCH_RISKS_LOG]: {
    [actionTypes.FAIL]: ['We were unable to load the Team Risk Log'],
  },
  [constants.CREATE_RISKS_LOG]: {
    [actionTypes.FAIL]: ['We were unable to create a  Team Risk Log item'],
  },
  [constants.UPDATE_RISKS_LOG]: {
    [actionTypes.FAIL]: ['We were unable to update the Team Risk Log'],
  },
  [constants.DELETE_RISKS_LOG]: {
    [actionTypes.FAIL]: ['We were unable to delete a Team Risk Log item'],
  },
  [constants.DELETE_RISK_LOG_DATA_FAIL]: {
    [actionTypes.FAIL]: ['We were unable to delete a Team Risk Log item'],
  },
  [constants.FETCH_CALENDAR]: {
    [actionTypes.FAIL]: ['We were unable to load the Team Calendar'],
  },
  [constants.CREATE_CALENDAR]: {
    [actionTypes.FAIL]: ['We were unable to create a Team Calendar event'],
  },
  [constants.UPDATE_CALENDAR]: {
    [actionTypes.FAIL]: ['We were unable to update the Team Calendar'],
  },
  [constants.DELETE_CALENDAR]: {
    [actionTypes.FAIL]: ['We were unable to delete a Team Calendar event'],
  },

  [constants.FETCH_TEAM_TOPICS]: {
    [actionTypes.FAIL]: ['We were unable to load the Weekly Topics'],
  },
  [constants.CREATE_TEAM_TOPICS]: {
    [actionTypes.FAIL]: ['We were unable to create a Weekly Topics item'],
  },
  [constants.UPDATE_TEAM_TOPICS]: {
    [actionTypes.FAIL]: ['We were unable to update the Weekly Topics'],
  },
  [constants.DELETE_TEAM_TOPICS]: {
    [actionTypes.FAIL]: ['We were unable to delete a Team Topic'],
  },
}

// Libs
import { includes, noop } from 'lodash'
import React from 'react'

// Components
import MultiSelectLookup, { LookupItem } from './MultiSelectLookup'
import SelectLookup from './SelectLookup'
import { getUserDisplayName } from '@shared/Lookup/utils'
import { IPopoverProps, TagInputProps } from '@blueprintjs/core'

type LookupSelectorProps = {
  isMultiple?: boolean
  isMinimal?: boolean
  shouldCreateFromQuery?: boolean
  itemsList: LookupItem[]
  selectedValues: LookupItem[]
  checkIfSelected?: (...args: $TSFixMe[]) => boolean
  handleSelectValue: (...args: $TSFixMe[]) => void
  handleDeselectValue: (...args: $TSFixMe[]) => void
  handleClearAll?: () => void
  getFilterByValue?: (...args: $TSFixMe) => string
  customInputProps?: Partial<TagInputProps['inputProps'] & Record<'data-cy', string>>
  activeItemText?: string
  customStyles?: React.CSSProperties
  multiSelectPlaceholder?: string
  rowsToShow?: 3 | 5 | 10
  isCellEditorComponent?: boolean
  itemRenderer?: (isSelected?: (...args: $TSFixMe) => boolean) => (...args: $TSFixMe[]) => JSX.Element | null
  popoverProps?: Partial<IPopoverProps> & object
  className?: string
  disabled?: boolean
}

const LookupSelector = ({
  isMultiple,
  isMinimal,
  itemsList,
  selectedValues,
  handleSelectValue,
  handleDeselectValue,
  handleClearAll = () => {},
  customInputProps,
  checkIfSelected = () => false,
  activeItemText,
  shouldCreateFromQuery,
  customStyles,
  multiSelectPlaceholder,
  rowsToShow,
  isCellEditorComponent,
  itemRenderer,
  popoverProps,
  className,
  disabled,
  getFilterByValue,
}: LookupSelectorProps) => {
  const filterItems = (query: string, item: LookupItem, _index: number, exactMatch: boolean): boolean => {
    const normalizedName = getFilterByValue
      ? getFilterByValue(item).toLowerCase()
      : getUserDisplayName(item).toLowerCase()
    const normalizedQuery = query.toLowerCase()

    if (exactMatch) {
      return normalizedName === normalizedQuery
    } else {
      return includes(normalizedName, normalizedQuery)
    }
  }

  const handleAddValue = (itemsList: LookupItem[], query: string): Partial<LookupItem>[] => {
    const queryRegExp = new RegExp(query.trim(), 'ui')

    return itemsList?.filter((item: LookupItem) => queryRegExp.test(getUserDisplayName(item).trim()))
  }

  const createItem = (value: string): Partial<LookupItem> | void => {
    if (shouldCreateFromQuery) {
      return {
        name: value,
        id: undefined,
        email: undefined,
      }
    }
  }

  return isMultiple ? (
    <MultiSelectLookup
      rowsToShow={rowsToShow}
      isMinimal={isMinimal}
      itemsList={itemsList}
      filterItems={filterItems}
      createNewItem={shouldCreateFromQuery ? createItem : undefined}
      selectedValues={selectedValues}
      onSelect={handleSelectValue}
      onDeselect={handleDeselectValue}
      onClearAll={handleClearAll}
      isSelected={checkIfSelected}
      customInputProps={customInputProps}
      customStyles={customStyles}
      multiSelectPlaceholder={multiSelectPlaceholder}
      isCellEditorComponent={isCellEditorComponent}
      itemRenderer={itemRenderer}
      handleAddValue={handleAddValue}
      popoverProps={popoverProps}
      className={className}
      disabled={disabled}
    />
  ) : (
    <SelectLookup
      isSelected={checkIfSelected}
      rowsToShow={rowsToShow}
      isMinimal={isMinimal}
      itemsList={itemsList}
      selectedValue={selectedValues[0]}
      activeItemText={activeItemText}
      filterItems={filterItems}
      createNewItem={createItem as $TSFixMe}
      handleSelectValue={handleSelectValue}
      handleDeselectValue={handleDeselectValue}
      customInputProps={customInputProps}
      customStyles={customStyles}
      isCellEditorComponent={isCellEditorComponent}
      handleAddValue={handleAddValue}
    />
  )
}

LookupSelector.defaultProps = {
  customInputProps: {},
  isMultiple: false,
  isMinimal: true,
  shouldCreateFromQuery: true,
  activeItemText: '',
  handleClearAll: () => {},
  checkIfSelected: () => false,
  customStyles: {},
  multiSelectPlaceholder: 'Search...',
  rowsToShow: 10,
  isCellEditorComponent: false,
  itemRenderer: noop,
  popoverProps: undefined,
  className: '',
  disabled: false,
  getFilterByValue: undefined,
}

export default LookupSelector

import { put, call } from 'redux-saga/effects'
import { getDeliverablesApi } from '@common/net'
import { fetchAttachments, setAttachments } from '../actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'

export const getAttachments = createSaga(function* getAttachments(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, deliverableId } = action.payload
  const teamDeliverablesApi = yield getDeliverablesApi()

  const { attachments } = yield call(
    teamDeliverablesApi.request('getTeamDeliverableData', {
      query: { teamId, deliverableId, include: 'attachments' },
    }),
  )

  yield put(setAttachments({ attachments }))
},
true)

export const createAttachment = createSaga(function* createAttachment(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, deliverableId, file } = action.payload
  const teamDeliverablesApi = yield getDeliverablesApi()
  const formData = new FormData()

  formData.append('attachment', file)

  yield call(
    teamDeliverablesApi.request('createAttachment', {
      query: { teamId, deliverableId, key: file.name },
      body: formData,
    }),
  )

  yield put(fetchAttachments({ teamId, deliverableId }))
})

//
export const deleteAttachment = createSaga(function* deleteAttachment(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, deliverableId, attachmentId } = action.payload
  const teamDeliverablesApi = yield getDeliverablesApi()

  yield call(
    teamDeliverablesApi.request('deleteAttachment', {
      query: { teamId, deliverableId, attachmentId },
    }),
  )

  yield put(fetchAttachments({ teamId, deliverableId }))
})

import { call, put, select } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getProjectActivityLogApi } from '@common/net'
import { setProjectActivityLog } from '../actions/actions'
import { ACTIVITY_LOG_PAGE_SIZE } from '@domain/myImoClient/common/constants'
import { getProjectActivityLogs } from '@domain/myImoClient/common/projectActivityLog/selectors'

export const fetchProjectActivityLogSaga = createSaga(function* fetchProjectActivityLogSaga(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const state: ReturnType<typeof getProjectActivityLogs> = yield select(getProjectActivityLogs)

  const { teamId, keyProcessId, keyProcessProjectId } = action.payload

  const currentProjectLog = state[keyProcessProjectId]

  if (currentProjectLog && currentProjectLog.hasMore === false) {
    return
  }

  const pageNo = currentProjectLog ? currentProjectLog.items.length : 0

  const projectActivityLogApi = yield getProjectActivityLogApi()

  const data = yield call(
    projectActivityLogApi.request('fetchProjectActivityLog', {
      query: { teamId, keyProcessId, keyProcessProjectId, limit: ACTIVITY_LOG_PAGE_SIZE, offset: pageNo },
    }),
  )

  yield put(setProjectActivityLog({ keyProcessProjectId, data }))
},
false)

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchDataRequests = createAction(constants.FETCH_DATA_REQUESTS, null, getMetaWithActionLoading)
export const setDataRequests = createAction(constants.SET_DATA_REQUESTS)

export const createDataRequest = createAction(constants.CREATE_DATA_REQUEST)
export const createDataRequestSuccess = createAction(constants.CREATE_DATA_REQUEST_SUCCESS)

export const updateDataRequest = createAction(constants.UPDATE_DATA_REQUEST)
export const updateDataRequestSuccess = createAction(constants.UPDATE_DATA_REQUEST_SUCCESS)

export const deleteDataRequest = createAction(constants.DELETE_DATA_REQUEST)
export const deleteDataRequestSuccess = createAction(constants.DELETE_DATA_REQUEST_SUCCESS)

import { all, takeLatest, takeEvery } from 'redux-saga/effects'
import * as constants from '@teamResources/resourcesHome/actions/actionTypes'
import { fetchTeamResourcesUpdates, updateTeamResourcesUpdates } from './resourcesHomeSagas'
import {
  createMasterCalendarEvent,
  deleteMasterCalendarEvent,
  fetchMasterCalendarEvents,
  updateMasterCalendarEvent,
} from '@teamResources/resourcesHome/sagas/masterCalendarEvents'
import {
  createMasterCalendarLabel,
  deleteMasterCalendarLabel,
  fetchMasterCalendarLabels,
  updateMasterCalendarLabel,
} from '@teamResources/resourcesHome/sagas/masterCalendarLabels'
import {
  createDocumentSharingFile,
  createDocumentSharingFolder,
  fetchDocumentSharing,
  documentSharingDeleteNodes,
  updateDocumentSharingFolder,
  documentSharingDownloadFiles,
} from '@teamResources/resourcesHome/sagas/documentSharingSagas'

export default function* resourcesHomeSaga() {
  yield all([
    takeLatest(constants.FETCH_TEAM_RESOURCES_UPDATES, fetchTeamResourcesUpdates),
    takeLatest(constants.UPDATE_TEAM_RESOURCES_UPDATES, updateTeamResourcesUpdates),
    takeLatest(constants.FETCH_MC_EVENTS, fetchMasterCalendarEvents),
    takeLatest(constants.CREATE_MC_EVENT, createMasterCalendarEvent),
    takeLatest(constants.UPDATE_MC_EVENT, updateMasterCalendarEvent),
    takeLatest(constants.DELETE_MC_EVENT, deleteMasterCalendarEvent),
    takeLatest(constants.FETCH_MC_LABELS, fetchMasterCalendarLabels),
    takeLatest(constants.CREATE_MC_LABEL, createMasterCalendarLabel),
    takeLatest(constants.UPDATE_MC_LABEL, updateMasterCalendarLabel),
    takeLatest(constants.DELETE_MC_LABEL, deleteMasterCalendarLabel),
    takeLatest(constants.FETCH_DOCUMENT_SHARING, fetchDocumentSharing),
    takeLatest(constants.DOCUMENT_SHARING_CREATE_FOLDER, createDocumentSharingFolder),
    takeLatest(constants.DOCUMENT_SHARING_DELETE_NODES, documentSharingDeleteNodes),
    takeLatest(constants.DOCUMENT_SHARING_UPDATE_FOLDER, updateDocumentSharingFolder),
    takeLatest(constants.DOCUMENT_SHARING_DOWNLOAD_FILES, documentSharingDownloadFiles),
    takeEvery(constants.DOCUMENT_SHARING_CREATE_FILE, createDocumentSharingFile),
  ])
}

// Libs
import React, { useState } from 'react'
import { isNil, noop, isEmpty, first } from 'lodash/fp'
import classNames from 'classnames'
import { getUserDisplayName, portalContainer } from '@shared/Lookup/utils'

// Components
import { Button, Position, IPopoverProps, TagInputProps } from '@blueprintjs/core'
import { CreateItemOption, ItemRenderer } from './ItemRenderer'
import { StyLedMultiSelect } from './Lookup.styles'
import { ENTER_KEY } from '@helpers/constants'
export type LookupItem = Record<string, string | number | boolean>

function clearButton(itemsList: LookupItem[], onClick: $TSFixMe) {
  return itemsList.length > 0 ? (
    <Button icon="cross" minimal={true} onClick={onClick} type="button" aria-label="Clear all" />
  ) : null
}

const getRenderer = (renderer: $TSFixMe, isSelected: $TSFixMe) => {
  return isNil(renderer())
    ? (item: $TSFixMe, itemProps: $TSFixMe) => (
        <ItemRenderer item={item} itemProps={itemProps} isSelected={isSelected} key={getUserDisplayName(item)} />
      )
    : renderer(isSelected)
}

type MultiSelectLookupProps = {
  itemsList: LookupItem[]
  selectedValues: LookupItem[]
  filterItems: (query: string, item: LookupItem, _index: number, exactMatch: boolean) => boolean
  createNewItem?: (value: string) => Partial<LookupItem> | void
  onSelect: (...args: $TSFixMe[]) => void
  onDeselect: (...args: $TSFixMe[]) => void
  onClearAll: () => void
  isSelected: (...args: $TSFixMe[]) => boolean
  customInputProps?: Partial<TagInputProps['inputProps'] & Record<'data-cy', string>>
  customStyles?: React.CSSProperties
  multiSelectPlaceholder?: string
  isMinimal?: boolean
  rowsToShow?: 3 | 5 | 10
  isCellEditorComponent?: boolean
  itemRenderer?: (isSelected: (...args: $TSFixMe) => boolean) => (...args: $TSFixMe[]) => JSX.Element | null
  handleAddValue: (itemsList: LookupItem[], query: string) => Partial<LookupItem>[]
  popoverProps?: Partial<IPopoverProps> & object
  className?: string
  disabled?: boolean
}

const MultiSelectLookup = ({
  itemsList,
  selectedValues,
  filterItems,
  createNewItem,
  onSelect,
  onDeselect,
  onClearAll,
  isSelected,
  customInputProps,
  customStyles,
  multiSelectPlaceholder,
  isMinimal,
  rowsToShow,
  isCellEditorComponent,
  itemRenderer,
  handleAddValue,
  popoverProps,
  className,
  disabled,
}: MultiSelectLookupProps) => {
  const [query, setQuery] = useState('')

  const handleSelectValue = (value: Partial<LookupItem> | void) => {
    setQuery('')
    onSelect(value)
  }

  const createNewItemFromQuery = () => {
    const value = query?.trim()
    if (value && createNewItem) handleSelectValue(createNewItem(value))
  }

  const onKeyDownInputProps = {
    onKeyDown: (e: React.KeyboardEvent) => {
      if (e.key === ENTER_KEY) {
        const result = handleAddValue(itemsList, query)

        if (!isEmpty(result)) {
          return handleSelectValue(first(result))
        }

        createNewItem && createNewItemFromQuery()
      }
    },
  }

  return (
    <StyLedMultiSelect
      className={className}
      activeItem={null}
      items={itemsList}
      fill={true}
      isCellEditorComponent={isCellEditorComponent}
      itemRenderer={getRenderer(itemRenderer, isSelected)}
      createNewItemRenderer={CreateItemOption}
      createNewItemFromQuery={createNewItem}
      itemPredicate={filterItems}
      onItemSelect={handleSelectValue}
      //TODO remove name and surname when all owners will have same view
      tagRenderer={(item: LookupItem) => item.name || item.displayName || item.longName}
      selectedItems={selectedValues}
      onQueryChange={setQuery}
      query={query}
      tagInputProps={{
        onRemove: onDeselect,
        rightElement: clearButton(selectedValues, onClearAll),
        className: 'multiselect-lookup',
        inputProps: {
          ...customInputProps,
          onKeyDown: onKeyDownInputProps.onKeyDown,
        },
      }}
      popoverProps={{
        boundary: 'viewport',
        minimal: isMinimal,
        usePortal: true,
        position: Position.BOTTOM,
        wrapperTagName: 'div',
        targetTagName: 'div',
        portalClassName: classNames('multiselect-lookup-popover', `height_${rowsToShow}`),
        portalContainer,
        ...popoverProps,
      }}
      data-cy={'lookup-multiselect'}
      customStyles={customStyles}
      placeholder={multiSelectPlaceholder}
      disabled={disabled}
    />
  )
}

MultiSelectLookup.defaultProps = {
  customInputProps: {},
  customStyles: null,
  multiSelectPlaceholder: 'Search...',
  isMinimal: false,
  rowsToShow: 10,
  isCellEditorComponent: false,
  itemRenderer: noop,
  createNewItem: null,
  popoverProps: undefined,
  className: '',
  disabled: false,
}

export default MultiSelectLookup

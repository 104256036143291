import { simpleSorterWithMapper } from '@imo/imo-ui-toolkit'
import { find, get, pick } from 'lodash'
import { permissionsMap } from './permissions/root'
import {
  hasRestrictedWithTSA,
  hasResponsibleImoTeam,
  hasResponsibleCentralTeam,
  checkIsImoWithoutVCRole,
  checkIsIMOTeamType,
  checkIsRestricted,
} from '@generic/selectors/utils'
import { getUserTeamsWithoutVC } from '@generic/selectors/permissions'
import { findPermissionByRoles, getHighestUserRoleInTeams } from './utils'
import { userRolesOrder } from './constants'

class AccessController {
  static instance: $TSFixMe

  static getInstance() {
    if (!AccessController.instance) {
      AccessController.instance = new AccessController()
    }

    return AccessController.instance
  }

  getUserRoles = (user: $TSFixMe) => get(user, 'teams', [])

  getUserAdminsRoles = (user: $TSFixMe) => get(user, 'roles', [])

  getTeamsPermissions = (teams: $TSFixMe, permissionsList: $TSFixMe) => {
    return teams.reduce((obj: $TSFixMe, { teamType, role, id }: $TSFixMe) => {
      const permission = find(permissionsList, { name: teamType, role: role?.toLowerCase() })

      if (permission)
        obj[id] = { ...get(permission, 'teamsPermissions'), ...pick(permission, ['redirects', 'exceptions']) }

      return obj
    }, {})
  }

  hasImoWithoutVCRole = (roles: $TSFixMe) =>
    roles.some(({ teamType, role }: $TSFixMe) => checkIsIMOTeamType(teamType) && checkIsImoWithoutVCRole(role))

  getPermissions = (user: $TSFixMe) => {
    const userRoles = this.getUserRoles(user)

    // if true - unlock team home for restricted user (show Day1 and TSA deliverables)
    const restrictedWithTSA = hasRestrictedWithTSA(userRoles)

    const sortedRolesFromHighest = [...userRoles].sort((a, b) =>
      simpleSorterWithMapper(userRolesOrder)(a.role.toLowerCase(), b.role.toLowerCase()),
    )

    const teamRole = sortedRolesFromHighest[0] || {}

    const dependencies = {
      isRestrictedWithTSA: restrictedWithTSA,
      isHighestRoleRestricted: checkIsRestricted(teamRole?.role),
    }

    const permissionsList = permissionsMap(dependencies)
    const userRole = getHighestUserRoleInTeams(user, permissionsList)

    // get the list of user's teams without vc access
    let teamsWithoutVC = getUserTeamsWithoutVC(userRoles)

    teamsWithoutVC = teamsWithoutVC.filter((team: $TSFixMe) => {
      return (
        (!hasResponsibleCentralTeam(team.id, userRoles) && !hasResponsibleImoTeam(team.id, userRoles)) ||
        this.hasImoWithoutVCRole(userRoles)
      )
    })

    // union of permissions of all user roles
    const rolePrivileges = findPermissionByRoles(permissionsList, user)

    return {
      permissions: get(rolePrivileges, 'permissions', []),
      exceptions: get(rolePrivileges, 'exceptions', []),
      redirects: get(rolePrivileges, 'redirects', {}),
      role: userRole.role ? userRole : {},
      roles: userRoles,
      teamsPermissions: this.getTeamsPermissions(user.teams, permissionsList),
      teamsWithoutVC,
    }
  }
}

export const accessController = AccessController.getInstance()

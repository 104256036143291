import { put, call } from 'redux-saga/effects'
import {
  setScenarios,
  updateScenarioSuccess,
  deleteScenarioSuccess,
  fetchScenarios as fetchScenariosAction,
  setScenariosFromLibrary,
} from '@dayOneReadiness/actions/actions'
import { getDayOneReadinessApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { omit } from 'lodash/fp'

export const fetchScenarios = createSaga(function* fetchScenarios({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload

  const api = yield getDayOneReadinessApi()
  const scenarios = yield call(
    api.request('getScenarios', {
      query: {
        teamId,
      },
    }),
  )

  yield put(setScenarios({ scenarios }))
},
true)

export const fetchScenariosFromLibrary = createSaga(function* fetchScenariosFromLibrary({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { libraryId, teamId } = payload

  const api = yield getDayOneReadinessApi()
  const scenarios = yield call(
    api.request('getScenariosFromLibrary', {
      query: {
        libraryId,
        teamId,
      },
    }),
  )

  yield put(setScenariosFromLibrary({ scenarios }))
})

export const createScenario = createSaga(function* createScenario({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload

  const api = yield getDayOneReadinessApi()
  const { id } = yield call(
    api.request('createScenario', {
      query: {
        teamId,
      },
      body: omit(['id', 'isNew'], data),
    }),
  )

  yield put(updateScenarioSuccess({ id: data.id, data: { ...data, id, isNew: false } }))
},
true)

export const addScenariosFromLibrary = createSaga(function* addScenariosFromLibrary({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { libraryId, teamId, scenarios } = payload

  const api = yield getDayOneReadinessApi()
  yield call(
    api.request('addScenariosFromLibrary', {
      query: {
        teamId,
        libraryId,
      },
      body: scenarios.map(omit(['disabled', 'id'])),
    }),
  )

  yield put(fetchScenariosAction({ teamId }))
},
true)

export const updateScenario = createSaga(function* updateScenario({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, scenarioId, data } = payload

  for (const field in data) {
    if (data.hasOwnProperty(field) && data[field] === '') {
      data[field] = null
    }
  }

  const api = yield getDayOneReadinessApi()
  yield call(
    api.request('updateScenario', {
      query: {
        teamId,
        scenarioId,
      },
      body: omit(['id', 'isNew'], data),
    }),
  )

  yield put(updateScenarioSuccess({ id: scenarioId, data }))
})

export const deleteScenario = createSaga(function* deleteScenario({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, scenarioId } = payload

  const api = yield getDayOneReadinessApi()
  yield call(
    api.request('deleteScenario', {
      query: {
        teamId,
        scenarioId,
      },
    }),
  )

  yield put(deleteScenarioSuccess({ id: scenarioId }))
})

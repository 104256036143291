import { find, first, get, getOr, isEmpty, isFunction, isNil, isString, pick } from 'lodash/fp'
import { PHASES, TSA_PHASE_REQUIRED_FIELDS } from '../constants'
import moment from 'moment'
import { valueGetterWithInterdependency } from '@shared/DayOne/utils/mapDayOneData'
import { processItemFields } from '@common/accessController/strategies/deliverables/constants'
import { itemFields, LOCK_PHASE_FIELDS } from '@common/accessController/strategies/constants'
import { inRange, set } from 'lodash'
import { getMinDate, isSameDay } from '@shared/Grid/utils/dates'

const daysInMonths = 30

export const isEmptyRequiredFields = (data, phase) => {
  if (phase === PHASES.TWO) {
    const oneOfRequiredData = pick([...TSA_PHASE_REQUIRED_FIELDS[PHASES.TWO].slice(1)], data)
    const isBothRequiredEmpty = Object.values(oneOfRequiredData).every((value) => isNil(value))

    return isNil(get(first(TSA_PHASE_REQUIRED_FIELDS[PHASES.TWO]), data)) || isBothRequiredEmpty
  }

  const requiredData = pick(TSA_PHASE_REQUIRED_FIELDS[phase] || [], data)
  const hasEmptyRequiredData =
    isEmpty(requiredData) ||
    Object.values(requiredData).some((value) => (isString(value) ? isEmpty(value) : isNil(value)))

  return hasEmptyRequiredData
}

export const requiredFieldsPhaseMapper = () =>
  Object.entries(TSA_PHASE_REQUIRED_FIELDS).reduce((acc, [phase, fields]) => {
    for (let field of fields) {
      acc[field] = +phase
    }

    return acc
  }, {})

export const isAfter = (date1, date2) => moment(date1).isAfter(moment(date2), 'day')

export const isDateAfterEndDate = ({ value: d1, data: { originalContractualTSAEndDate: d2 } }) => isAfter(d1, d2)

export const isDateAfterEndDateTooltip =
  (message) =>
  ({ value: d1, data: { originalContractualTSAEndDate: d2 } }) =>
    isAfter(d1, d2) ? message : null

export const isPhaseExpanded = (phase, currentPhase) => phase <= currentPhase

export const getSelectedProjectName = (data) => `${data.projectListId} ${data.displayName}`

export const getSuggestItemInputValue = (data) => {
  if (!data?.name) return ''

  const projectListId = valueGetterWithInterdependency(processItemFields.PROJECT_LIST_ID)({ data })

  return `${projectListId} ${data.name}`
}

export const isColumnLocked = ({ data, phase }) => {
  const isRowLocked = data.isAllLocked
  const isColumnWithPhase = Boolean(phase)
  const isPhaseLocked = data[`isPhase${phase}Locked`]

  if (isColumnWithPhase) return isRowLocked || isPhaseLocked

  return isRowLocked
}

export const isFirstThreePhasesLocked = (data) =>
  data && [data.isPhase1Locked, data.isPhase2Locked, data.isPhase3Locked].every(Boolean)

export const getLabel = (value, valueKey, defaultValueKey = 'displayName') => {
  if (isNil(value) || isString(value)) return value

  return !isNil(get(valueKey, value)) ? get(valueKey, value) : get(defaultValueKey, value)
}

export const primaryLinkedProjectValueSetter = ({ newValue, oldValue, data }) => {
  if (getLabel(newValue, 'name') === getLabel(oldValue, 'name')) return false

  data.primaryLinkedProject = newValue
  data.primaryLinkedProjectId = getOr(null, 'id', newValue)

  return true
}

export const getPermissions = ({ checkPermission, isReadonly }) =>
  isReadonly ? checkPermission() : checkPermission(itemFields.IS_ALL_LOCKED)

export const isColumnEditable = ({ data, context, phase }) => {
  return !isColumnLocked({ data, phase }) && context?.checkPermission()
}

export const isLockColumnEditable = ({ data, context, phase }) => {
  return !isColumnLocked({ data, phase }) && context?.checkPermission(...Object.values(LOCK_PHASE_FIELDS))
}

export const serviceDurationValueFormatter = ({ value }) => {
  if (!value) return value

  return value > 1 ? `${value} months` : `${value} month`
}

export const projectValueFormatter = (params) => (params.value ? getSelectedProjectName(params.value) : '')

export const multipleProjectsValueFormatter = ({ data: { otherLinkedProjects } }) => {
  return otherLinkedProjects.map((item) => `${item.projectListId} ${item.displayName}`).join(', ')
}

export const multipleProjectsTooltipValueGetter = ({ data }) => {
  return data.otherLinkedProjects.map((item) => `${item.projectListId} ${item.displayName}`).join(', ')
}

export const getPinedColumn = ({ field, columns }) => {
  const columnDefinition = find((column) => column.children[0].field === field, columns)

  return {
    ...columnDefinition,
    children: [
      ...columnDefinition.children.map((columnDefinition) => ({ ...columnDefinition, headerClass: undefined })),
    ],
  }
}

export const getColumn = ({ field, columns, withHeader }) => {
  const column = find({ field }, columns)
  const notRequiredColumn = { ...column, headerClass: undefined }

  if (withHeader) {
    return {
      headerName: '',
      groupId: column.field,
      excelColumnWidth: column.width,
      children: [notRequiredColumn],
    }
  }

  return notRequiredColumn
}

export const getServiceDuration = (exitDate, dayOneDate) => {
  const diff = moment.duration(new Date(exitDate).getTime() - new Date(dayOneDate).getTime())

  const monthsCount = diff.asDays() / daysInMonths

  const integerPart = Math.trunc(monthsCount)
  const decimalPart = Number((monthsCount % 1).toFixed(1))

  switch (true) {
    case decimalPart <= 0.2:
      return integerPart

    case inRange(decimalPart, 0.3, 0.8):
      return integerPart + 0.5

    case decimalPart >= 0.8:
    default:
      return integerPart + 1
  }
}

export const getDatePlusHalfMonth = (dayOneDate) => {
  const dayOneDateObj = moment(dayOneDate)
  const halfOfMonth = Math.ceil(dayOneDateObj.daysInMonth() / 2)

  return getMinDate(null, dayOneDateObj.add(halfOfMonth, 'day'))
}

export const impliedDateValueSetter = ({ newValue, oldValue, context, data, colDef }) => {
  if (!newValue || isSameDay(newValue, oldValue)) return false

  if (!oldValue || !isFunction(context.setEditsToApprove)) {
    data[colDef.field] = newValue

    return true
  }

  const newData = {
    ...data,
    [colDef.field]: newValue,
    serviceDuration: getServiceDuration(newValue, context.dayOneDate),
  }

  context.setEditsToApprove({ data: newData, field: colDef.field })

  return false
}

export const setConfirmDayOneValue = ({ newValue, data, colDef: { field }, context }) => {
  if (!isFunction(context.setConfirmationDayOneToApprove) || !newValue) {
    set(data, field, newValue)

    return true
  }

  context.setConfirmationDayOneToApprove({ data: { ...data, [field]: newValue }, field })
  return false
}

const otherProjectValuesAreEqual = ({ oldValue, newValue }) => {
  return oldValue.length === newValue.length && oldValue.every((item, index) => item.id === newValue[index]?.id)
}

export const otherLinkedProjectsValueSetter = ({ newValue, oldValue, data, colDef }) => {
  if (otherProjectValuesAreEqual({ newValue, oldValue })) return false

  data[colDef.field] = [...newValue].sort((v1, v2) => Number(v1.projectListId) - Number(v2.projectListId))

  return true
}

export const MAX_FTE_CELL_NUMBER_VALUE = 1_000_000_000_000
export const MIN_FTE_CELL_NUMBER_VALUE = -1_000_000_000_000

import React from 'react'
import { StyledNoResults, StyledNoResultsContent } from './index.styles'
import { Icon } from '@blueprintjs/core'

interface Props {
  content?: React.ReactNode
  style?: $TSFixMe
  className?: string
}

const NoResults = ({ content, style, className }: Props) => {
  return (
    <StyledNoResults style={style} className={className}>
      <StyledNoResultsContent>{content}</StyledNoResultsContent>
    </StyledNoResults>
  )
}

NoResults.defaultProps = {
  content: (
    <>
      <div>
        <Icon icon={'search'} size={23} />
      </div>
      <div>
        There is currently no data to display here.
        <br /> Please try again later.
      </div>
    </>
  ),
  style: {},
  className: null,
}

export default NoResults

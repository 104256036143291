import { useContext, useCallback } from 'react'
import {
  FeatureFlag,
  FeatureFlagContext,
  TenantFeatureFlags,
  createDefaultTenantFeatureFlags,
} from '../../providers/feature-flag-context'
import { createQueryClient } from '../../providers/query-client'

export const queryClient = createQueryClient()

export function getFeatureFlags() {
  return (
    queryClient.getQueryData<TenantFeatureFlags<FeatureFlag>>(['feature-flags']) || createDefaultTenantFeatureFlags()
  )
}

export default function useFeatureFlags() {
  const flags = useContext(FeatureFlagContext)

  const isEnabled = useCallback(
    (flag: FeatureFlag) => {
      return Boolean(flags[flag]?.enabled)
    },
    [flags],
  )

  const isDisabled = useCallback(
    (flag: FeatureFlag) => {
      return !Boolean(flags[flag]?.enabled)
    },
    [flags],
  )

  return { isEnabled, isDisabled }
}

export { useFeatureFlags }

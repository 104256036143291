import styled from 'styled-components'
import imoLogo from '@assets/img/logo/siderLogo/logo@2x.png'
import smoLogo from '@assets/img/logo/siderLogo/logo-smo@2x.png'
import mckLogo from '@assets/img/logo/siderLogo/mck-logo@2x.png'
import { FONT_FAMILY } from '@imo/imo-ui-toolkit'

export const SharedLogo = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`

const Logo = styled(SharedLogo)`
  width: 71px;
  height: 22px;
  margin: 5px 25px;
`

export const ImoLogo = styled(Logo)`
  background-image: url(${imoLogo});
`
export const SmoLogo = styled(Logo)`
  background-image: url(${smoLogo});
`

export const McKinseyLogo = styled(SharedLogo)`
  background-image: url(${mckLogo});
  width: 100px;
  height: 30px;
  margin: 10px;
`

export const ImoVersion = styled.div`
  margin: 5px auto;
  font: 11px ${FONT_FAMILY};
`

export const VersionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 119px;
`

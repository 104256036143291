import React from 'react'
import { ImoIcon } from '@imo/imo-ui-toolkit'
import { RouteObject } from 'react-router-dom'
import { AccessControl } from '@root/src/views/shared/AccessControl/AccessControl'

export const notificationRouter: RouteObject[] = [
  {
    path: '/notifications',
    lazy: async () => {
      const { NotificationsHubLayout } = await import(
        /* webpackChunkName: "NotificationsHub" */ /* webpackPrefetch: true */ '../../views/myImoClient/pages/NotificationsHub/NotificationsHubLayout'
      )
      return { element: <AccessControl component={NotificationsHubLayout} /> }
    },
    handle: { crumb: { title: 'Notifications Hub', icon: <ImoIcon icon="notifications" size={16} /> } },
  },
]

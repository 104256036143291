import styled from 'styled-components'
import { siderWidth } from '@helpers/constants'

type ContainerProps = {
  isOpen: boolean
  yPos: number
}

export const Container = styled.div<ContainerProps>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: ${({ yPos }) => yPos}px;
  left: ${siderWidth}px;
  height: calc(100% - ${({ yPos }) => yPos}px);
  width: calc(100% - ${siderWidth}px);
  z-index: 100;
`

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchCommunicationsData = createAction(constants.FETCH_COMMUNICATIONS_DATA)
export const fetchCommunicationsDataWithLoader = createAction(
  constants.FETCH_COMMUNICATIONS_DATA,
  null,
  getMetaWithActionLoading,
)
export const setCommunicationsData = createAction(constants.SET_COMMUNICATIONS_DATA)

export const fetchColumnsData = createAction(constants.FETCH_COLUMNS_DATA)
export const fetchColumnsDataWithLoader = createAction(constants.FETCH_COLUMNS_DATA, null, getMetaWithActionLoading)
export const setColumnsData = createAction(constants.SET_COLUMNS_DATA)
export const updateColumnsData = createAction(constants.UPDATE_COLUMNS_DATA)
export const deleteColumnsData = createAction(constants.DELETE_COLUMNS_DATA)

export const saveRow = createAction(constants.SAVE_ROW)
export const updateRow = createAction(constants.UPDATE_ROW)
export const deleteRows = createAction(constants.DELETE_ROWS)
export const deleteRow = createAction(constants.DELETE_ROW)
export const deleteRowsSuccess = createAction(constants.DELETE_ROWS_SUCCESS)

export const createRequest = createAction(constants.CREATE_REQUEST)
export const fetchRequests = createAction(constants.FETCH_REQUESTS)
export const fetchRequestsWithLoader = createAction(constants.FETCH_REQUESTS, null, getMetaWithActionLoading)
export const setRequests = createAction(constants.SET_REQUESTS)
export const updateRequest = createAction(constants.UPDATE_REQUEST)
export const deleteRequest = createAction(constants.DELETE_REQUEST)

export const saveEvent = createAction(constants.SAVE_EVENT)
export const fetchEvent = createAction(constants.FETCH_EVENT)
export const setEvent = createAction(constants.SET_EVENT)
export const updateEvent = createAction(constants.UPDATE_EVENT)
export const deleteEvents = createAction(constants.DELETE_EVENTS)

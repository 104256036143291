import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class TeamSetupApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
  }

  teamSetup = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: '/team/bulk' },
      {
        method: 'PUT',
        body,
      },
    )
  }

  getTeams = () => {
    return this.call({ pathname: '/team' })
  }
}

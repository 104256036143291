import * as constantsV2 from '@mutualDiscovery/actions/actionTypes'
import { actionTypes } from '../../utils'

export default {
  [constantsV2.SAVE_PROCESS]: {
    [actionTypes.FAIL]: ['We were unable to add a new process', 7],
  },
  [constantsV2.DELETE_PROCESS]: {
    [actionTypes.FAIL]: ['We were unable to delete the process', 5],
  },
  [constantsV2.UPDATE_PROCESS]: {
    [actionTypes.FAIL]: ['We were unable to update the process', 8],
  },
  [constantsV2.ADD_PROCESSES_FROM_LIBRARY]: {
    [actionTypes.FAIL]: ['We were unable to add processes', 8],
  },
}

import { createSaga } from '@common/sagaCreator/createSaga'
import { call, put } from 'redux-saga/effects'
import { getMyTeamApi } from '@common/net'
import {
  deleteOtherTeamUpdateSuccess,
  setOtherTeamUpdates,
  submitWeeklyStatusUpdateSuccess,
  updateOtherTeamUpdateSuccess,
} from '@teamHome/actions/actions'
import { pick } from 'lodash/fp'

const formatDataForRequest = pick('description')

export const fetchOtherTeamUpdates = createSaga(function* fetchOtherTeamUpdates({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getMyTeamApi()

  const data = yield call(
    api.request('getOtherTeamUpdates', {
      query: { teamId: payload.teamId, historyDate: payload.historyDate },
    }),
  )

  yield put(setOtherTeamUpdates(data))
})

export const createOtherTeamUpdate = createSaga(function* createOtherTeamUpdate({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data, teamId } = payload
  const api = yield getMyTeamApi()

  const { id } = yield call(
    api.request('createOtherTeamUpdate', {
      body: formatDataForRequest(data),
      query: { teamId },
    }),
  )

  const newData = { ...data, id, isNew: false }

  yield put(updateOtherTeamUpdateSuccess({ data: newData, id: data.id }))
},
true)

export const updateOtherTeamUpdate = createSaga(function* updateOtherTeamUpdate({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data, id, teamId } = payload
  const api = yield getMyTeamApi()

  yield call(
    api.request('updateOtherTeamUpdate', {
      query: { id, teamId },
      body: formatDataForRequest(data),
    }),
  )
  yield put(updateOtherTeamUpdateSuccess({ data, id }))
})

export const deleteOtherTeamUpdate = createSaga(function* deleteOtherTeamUpdate({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { id, teamId } = payload

  yield put(deleteOtherTeamUpdateSuccess({ id }))

  const api = yield getMyTeamApi()

  yield call(
    api.request('deleteOtherTeamUpdate', {
      query: { id, teamId },
    }),
  )
})

export const submitWeeklyStatusUpdate = createSaga(function* submitWeeklyStatusUpdate({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload
  const api = yield getMyTeamApi()

  const updatedAt = yield call(
    api.request('submitWeeklyStatusUpdate', {
      query: { teamId },
    }),
  )

  yield put(submitWeeklyStatusUpdateSuccess(updatedAt))
})

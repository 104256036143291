import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import NotFoundPage from '@shared/NotFoundPage'
import { fetchTeamDeliverableStatus } from '@generic/actions/actions'
import { ImoControlTowerHeader } from '@myImoClient/pages/ImoControlTower/ImoControlTowerHeader'
import { AccessControl } from '@views/shared/AccessControl/AccessControl'
import { Outlet, useParams } from 'react-router-dom'

const ControlTowerNav = () => {
  const dispatch = useDispatch()
  const { teamId } = useParams<{ teamId: string }>()

  useEffect(() => {
    if (teamId) {
      dispatch(fetchTeamDeliverableStatus())
    }
  }, [teamId])

  if (!teamId) {
    return <NotFoundPage />
  }

  return (
    <>
      <ImoControlTowerHeader teamId={teamId} />
      <Outlet />
    </>
  )
}

export const ControlTowerWithAccessControl = () => {
  return <AccessControl component={ControlTowerNav} />
}

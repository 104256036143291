import styled from 'styled-components'
import { Button, Menu } from '@blueprintjs/core'
import { Select2 } from '@blueprintjs/select'
import { greyForBtn, greyIconColor, textDarkGrey, white } from '@imo/imo-ui-toolkit'

export const StyledSelect = styled(Select2)`
  font-size: 13px;
  color: ${white} !important;
`

export const SelectorButton = styled(Button)`
  position: relative;
  min-height: 20px;
  height: 20px;
  padding: 0 16px 0 9px;
  margin-left: 8px;
  box-sizing: border-box;
  background: ${greyForBtn} !important;
  color: ${white} !important;
  font-size: 13px;
  line-height: 1;

  .bp4-icon {
    color: ${white};
    position: absolute;
    top: 2px;
    right: 2px;
  }
`

export const TeamsListWrapper = styled(Menu)`
  padding: 0;
  max-height: calc(85vh - 42px);
  overflow-y: auto;

  .item-divider {
    margin-top: 0;
    height: 1px;
    margin-bottom: 3px;
    border-color: #ebf1f5;
  }

  .item-divider:first-child {
    display: none;
  }
`

export const TeamItem = styled.div`
  width: 430px;
  height: 42px;
  padding: 4px 5px 0 10px;
  box-sizing: border-box;
  user-select: none;

  &:hover {
    background: #ebf1f5;
  }

  &.selected {
    background: #ebf1f5;
  }
`

export const TeamItemTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const TeamItemBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const TeamItemGroupName = styled.div`
  display: flex;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;

  &:last-child {
    display: none;
  }

  .group-divider {
    border-color: #979797;
    flex-grow: 20;
    margin: 0 0 8px 0;
    border-right: 0;

    &.right {
      right: 0;
    }
  }

  + .item-divider {
    display: none;
  }
`

export const GroupName = styled.div`
  font-size: 12px;
  color: #4d4d4d;
  line-height: 16px;
  margin: 0 auto;
  flex-grow: 2;
  text-align: center;
  white-space: nowrap;
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
`

export const TeamItemName = styled.div`
  font-size: 13px;
  color: ${textDarkGrey};
  margin-bottom: 8px;
  line-height: 14px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
`

export const TeamItemType = styled.div<$TSFixMe>`
  font-size: 10px;
  color: ${white};
  background: ${(props) => props.typeColor || '#1F40E6'};
  height: 16px;
  width: fit-content;
  max-width: 90px;
  padding: 0 7px;
  box-sizing: border-box;
  line-height: 18px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 2px;
  margin-bottom: 6px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
`

export const TeamItemNote = styled.div`
  font-size: 9px;
  color: ${greyIconColor};
  text-transform: uppercase;
  box-sizing: border-box;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
`

export const TeamItemNoteRight = styled(TeamItemNote)`
  flex-shrink: 0;
  max-width: 90px;
`

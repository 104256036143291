/* eslint-disable max-classes-per-file */
import { httpStatusesHandler } from './statuses'

export const errorType = {
  HTTP: 'http error',
  VALIDATION: 'validation error',
  ERROR_IN_COMPONENT: 'error in react component',
}

export class HttpStatusError extends Error {
  constructor({ message, statusCode } = {}) {
    // error - object with transformed error message
    const error = httpStatusesHandler({ message, statusCode })
    super(error.message)
    this.fromServer = error.fromServer
    this.name = errorType.HTTP
    this.status = statusCode
    // basic error message from backend
    this.errorMessage = message
  }
}

export class ValidationError extends Error {
  constructor({ message, props } = {}) {
    super(message)
    this.name = errorType.VALIDATION
    this.props = props || {}
  }
}

export class RenderError extends Error {
  constructor(error) {
    super(error)
    this.name = errorType.ERROR_IN_COMPONENT
    this.message = `(Error in react component) ${error.message}`
    this.stack = error.stack
  }
}

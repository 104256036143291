import { handleActions } from 'redux-actions'
import * as constants from '@teamResources/dataRequestTracker/actions/actionTypes'

export interface IDataRequestTracker {
  dataRequests: $TSFixMe[]
}

export const initialState = {
  dataRequests: [],
}

const dataRequestTrackerReducer = handleActions<IDataRequestTracker, $TSFixMe>(
  {
    [constants.SET_DATA_REQUESTS]: (state, action) => {
      return {
        ...state,
        dataRequests: action.payload,
      }
    },

    [constants.CREATE_DATA_REQUEST_SUCCESS]: (state, action) => {
      const { data } = action.payload
      const { dataRequests } = state

      return {
        ...state,
        dataRequests: [...dataRequests, data],
      }
    },

    [constants.UPDATE_DATA_REQUEST_SUCCESS]: (state, action) => {
      const { data, id } = action.payload

      return {
        ...state,
        dataRequests: state.dataRequests.map((request) => (request.id === id ? { ...request, ...data } : request)),
      }
    },

    [constants.DELETE_DATA_REQUEST_SUCCESS]: (state, action) => {
      const { id } = action.payload
      const { dataRequests } = state

      const filteredDataRequests = dataRequests.filter((contact) => contact.id !== id)

      return {
        ...state,
        dataRequests: filteredDataRequests,
      }
    },
  },
  initialState,
)

export default dataRequestTrackerReducer

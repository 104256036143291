import { createSaga } from '@common/sagaCreator/createSaga'
import { getMyTeamApi } from '@common/net'
import { call, put } from 'redux-saga/effects'
import { setFlaggedRisksAndDecisions } from '@teamHome/actions/actions'

export const fetchFlaggedRisksAndDecisions = createSaga(function* fetchFlaggedRisksAndDecisions({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getMyTeamApi()

  const data = yield call(
    api.request('getFlaggedRisksAndDecisions', {
      query: { teamId: payload.teamId, historyDate: payload.historyDate },
    }),
  )

  yield put(setFlaggedRisksAndDecisions(data))
},
true)

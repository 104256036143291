import { all, takeLatest } from 'redux-saga/effects'
import {
  SEND_BULK_UPLOAD_ACTUALS_FILE_PENDING,
  DOWNLOAD_BULK_UPLOAD_TEMPLATE_PENDING,
  FETCH_AMOUNT_OF_INITIATIVES_PENDING,
  GET_BULK_UPLOAD_DOWNLOAD_TEMPLATE,
  SEND_BULK_UPLOAD_FILE_PENDING,
} from '../actions/actionTypes'
import {
  downloadBulkUploadTemplate,
  fetchAmountOfInitiatives,
  getDownloadTemplate,
  sendBulkUploadActualsFilePending,
  sendBulkUploadFilePending,
} from './bulkUpload'

export default function* batchUploadActualsSaga() {
  yield all([
    takeLatest(SEND_BULK_UPLOAD_ACTUALS_FILE_PENDING, sendBulkUploadActualsFilePending),
    takeLatest(DOWNLOAD_BULK_UPLOAD_TEMPLATE_PENDING, downloadBulkUploadTemplate),
    takeLatest(FETCH_AMOUNT_OF_INITIATIVES_PENDING, fetchAmountOfInitiatives),
    takeLatest(GET_BULK_UPLOAD_DOWNLOAD_TEMPLATE, getDownloadTemplate),
    takeLatest(SEND_BULK_UPLOAD_FILE_PENDING, sendBulkUploadFilePending),
  ])
}

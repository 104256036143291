import { createAction } from 'redux-actions'
import {
  SET_BULK_UPLOAD_ACTUALS_FILE,
  SEND_BULK_UPLOAD_ACTUALS_FILE_PENDING,
  SEND_BULK_UPLOAD_ACTUALS_FILE_SUCCESS,
  SEND_BULK_UPLOAD_ACTUALS_FILE_FAIL,
  UPDATE_BULK_UPLOAD_SENDING_STATUS,
  FLUSH_BULK_UPLOAD_ACTUALS,
  CANCEL_BULK_UPLOAD_ACTUALS_FILE,
  DOWNLOAD_BULK_UPLOAD_TEMPLATE_PENDING,
  FETCH_AMOUNT_OF_INITIATIVES_PENDING,
  FETCH_AMOUNT_OF_INITIATIVES_SUCCESS,
  FETCH_AMOUNT_OF_INITIATIVES_FAIL,
  GET_BULK_UPLOAD_DOWNLOAD_TEMPLATE,
  SEND_BULK_UPLOAD_FILE_PENDING,
  DOWNLOAD_BULK_UPLOAD_TEMPLATE_SUCCESS,
  DOWNLOAD_BULK_UPLOAD_TEMPLATE_FAIL,
  UPDATE_DOWNLOAD_BULK_UPLOAD_TEMPLATE_STATUS,
} from './actionTypes'

export const setBulkUploadActualsFile = createAction(SET_BULK_UPLOAD_ACTUALS_FILE)
export const sendBulkUploadActualsFilePending = createAction(SEND_BULK_UPLOAD_ACTUALS_FILE_PENDING)
export const sendBulkUploadActualsFileSuccess = createAction(SEND_BULK_UPLOAD_ACTUALS_FILE_SUCCESS)
export const sendBulkUploadActualsFileFail = createAction(SEND_BULK_UPLOAD_ACTUALS_FILE_FAIL)

export const sendBulkUploadFilePending = createAction(SEND_BULK_UPLOAD_FILE_PENDING)

export const updateBulkUploadSendingStatus = createAction(UPDATE_BULK_UPLOAD_SENDING_STATUS)
export const flushBulkUploadActuals = createAction(FLUSH_BULK_UPLOAD_ACTUALS)
export const cancelBulkUploadActualsFile = createAction(CANCEL_BULK_UPLOAD_ACTUALS_FILE)

export const downloadBulkUploadTemplatePending = createAction(DOWNLOAD_BULK_UPLOAD_TEMPLATE_PENDING)
export const downloadBulkUploadTemplateSuccess = createAction(DOWNLOAD_BULK_UPLOAD_TEMPLATE_SUCCESS)
export const downloadBulkUploadTemplateFail = createAction(DOWNLOAD_BULK_UPLOAD_TEMPLATE_FAIL)
export const updateDownloadBulkUploadTemplateStatus = createAction(UPDATE_DOWNLOAD_BULK_UPLOAD_TEMPLATE_STATUS)

export const fetchAmountOfInitiatives = createAction(FETCH_AMOUNT_OF_INITIATIVES_PENDING)
export const fetchAmountOfInitiativesSuccess = createAction(FETCH_AMOUNT_OF_INITIATIVES_SUCCESS)
export const fetchAmountOfInitiativesFail = createAction(FETCH_AMOUNT_OF_INITIATIVES_FAIL)

export const getBulkUploadDownloadTemplate = createAction(GET_BULK_UPLOAD_DOWNLOAD_TEMPLATE)

import { userRoles, accessRights } from '../constants'
import { routesList } from '../routesList'

export const LAUNCHPAD_ADMIN_PERMISSIONS = {
  name: userRoles.LAUNCHPAD_ADMIN,
  priority: 8,
  permissions: [
    {
      route: routesList.LAUNCHPAD,
      accessLevel: accessRights.READ_WRITE,
    },
  ],
}

import styled from 'styled-components'
import { Button, Menu } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { backgroundGreyDark } from '@imo/imo-ui-toolkit'

export const StyledLockedItems = styled.div`
  display: flex;
  justify-content: space-between;
  width: 46px;
`

export const StyledUnlockedItems = styled.div`
  display: flex;
  justify-content: space-between;
  width: 41px;
`

export const StyledButton = styled(Button)`
  font-size: 12px;
  white-space: nowrap;

  .bp4-icon.bp4-icon-caret-down {
    margin-right: 0;
  }

  &.single-item {
    cursor: not-allowed;
  }
`

export const Title = styled.span`
  font-size: 12px;
  font-weight: 500;
`

export const StyledMenu = styled(Menu)<$TSFixMe>`
  min-width: 120px;
  font-size: 12px;
  max-width: 200px;

  .bp4-menu-item:hover {
    background: ${backgroundGreyDark};
  }

  ${({ styles }) => styles}
`

export const StyledPopover = styled(Popover2)`
  &.transparent {
    .bp4-button {
      max-width: 100%;
    }

    .bp4-button-text {
      margin-right: 2px;
    }
  }
`

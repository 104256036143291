import { call, put, fork, select, all } from 'redux-saga/effects'
import { setCachedLogos, setConfig, showWarningMessage } from '@generic/actions/actions'
import { actionTypes } from '@common/notifications/utils'
import { isUnauthorized } from '@common/notifications'
import { FETCH_CONFIG_AND_USER } from '@generic/actions/actionTypes'
import { getConfig, getTenantId } from '@generic/selectors'
import { getGenericApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import * as genericConstants from '@generic/actions/actionTypes'
import { fetchUserGenerator } from './fetchUser'
import { getCompanyLogosPath } from '@helpers/utils'
import { fetchInstanceConfigSuccess } from '../../instanceConfig/actions'
import { fetchInitialData } from './fetchInitialData'

/* istanbul ignore file */
export function* saveImageBlobToStorage(url: string, key: string): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  try {
    const image = yield fetch(url)
    const blobObject = yield image.blob()
    const urlCreator = window.URL || window.webkitURL
    const blobUrl = urlCreator.createObjectURL(blobObject)
    const cachedLink = blobObject.type.includes('image/') ? blobUrl : null
    yield put(setCachedLogos({ [key]: cachedLink }))

    return true
  } catch (e) {
    return false
  }
}

export const fetchLogoLinks = createSaga(function* fetchLogoLinks(
  data: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const config = yield select(getConfig)
  const { companyALogoPath, companyBLogoPath } = getCompanyLogosPath(config.companyA.logo, config.companyB.logo)

  const companyALogo = data?.payload?.companyALogo || companyALogoPath
  const companyBLogo = data?.payload?.companyBLogo || companyBLogoPath

  const directory = yield select(getTenantId)
  const genericApi = yield getGenericApi()

  try {
    if (!companyALogo) throw new Error()

    const { signedUrl } = yield call(
      genericApi.request('getFileSignedUrl', { query: { key: companyALogo, directory } }),
    )

    yield put({ type: genericConstants.SET_COMPANY_A_LOGO, payload: { companyALogo: signedUrl } })
    yield fork(saveImageBlobToStorage, signedUrl, 'blobCompanyLogoA')
  } catch (e) {
    // handle image load error
  }

  try {
    if (!companyBLogo) throw new Error()

    const { signedUrl } = yield call(
      genericApi.request('getFileSignedUrl', { query: { key: companyBLogo, directory } }),
    )

    yield put({ type: genericConstants.SET_COMPANY_B_LOGO, payload: { companyBLogo: signedUrl } })
    yield fork(saveImageBlobToStorage, signedUrl, 'blobCompanyLogoB')
  } catch (e) {
    // handle image load error
  }
},
true)

export const fetchConfigGenerator = function* fetchConfigGenerator(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getGenericApi()
  const config = yield call(api.request('getClientConfig'))

  yield put(fetchInstanceConfigSuccess(config))

  //TODO: obsolete store for instance config response
  yield put(setConfig({ config }))
}

export const fetchConfig = createSaga(fetchConfigGenerator)

export const fetchConfigAndUser = createSaga(function* fetchConfigAndUser(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  try {
    const [, user] = yield all([call(fetchConfigGenerator), call(fetchUserGenerator)])
    if (!user.firstTime) {
      yield call(fetchInitialData, { withLoader: false })
    }
    yield fork(fetchLogoLinks, { meta: { ignoreLoading: true } })
  } catch (error) {
    const { status } = error as $TSFixMe
    if (isUnauthorized(status)) {
      yield put(
        showWarningMessage({
          actionType: actionTypes.FAIL,
          action: FETCH_CONFIG_AND_USER,
        }),
      )
    }

    throw error
  }
}, true)

import React from 'react'
import { Session } from '@nvt/mid-react'
import LoginHeader from '@shared/LoginHeader/LoginHeader'

type AuthProviderTypes = {
  mid: $TSFixMe
  children: JSX.Element
}

export const AuthProvider = ({ mid, children }: AuthProviderTypes) => {
  return (
    <Session mid={mid} customHeader={LoginHeader}>
      {children}
    </Session>
  )
}

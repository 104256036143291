import { pick } from 'lodash'

export const getRowRequestQuery = (isNewRow: boolean, rowId: string) => (isNewRow ? {} : { rowId })

export const getEventRequestQuery = (isNew: boolean, eventId: string) => (isNew ? {} : { eventId })

export const formatRowRequestBody = (row: { isNew?: boolean; name: string; orderId?: string }) => {
  if (!row.isNew) {
    return { name: row.name }
  }

  return pick(row, ['name', 'orderId'])
}

export const formatRequestBodyUpdateRequest = (request: $TSFixMe) => pick(request, ['complete'])

export const calculateTotalEventsCounter = (communicationRows: $TSFixMe[]) => {
  return communicationRows.reduce((acc, row) => {
    const rowEventsCounter = Object.values(row).reduce((accumulator: number, column) => {
      if (!Array.isArray(column)) return accumulator

      accumulator += column.length

      return accumulator
    }, 0)

    acc += rowEventsCounter

    return acc
  }, 0)
}

export const getEventCounterInRow = (row: $TSFixMe) => {
  let counter = 1

  Object.values(row).forEach((column) => {
    if (Array.isArray(column) && column.length > counter) {
      counter = column.length
    }
  })

  return counter
}

export const generateStateOfMaxEventsInRow = (communicationRows: $TSFixMe[]) => {
  return communicationRows.reduce((acc, row, index) => {
    acc[index] = getEventCounterInRow(row)

    return acc
  }, {})
}

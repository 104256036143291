import { call, put } from 'redux-saga/effects'
import { setRequests } from '@communications/actions/actions'
import { getCommunicationsApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchRequests = createSaga(function* fetchRequests(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getCommunicationsApi()
  const requests = yield call(api.request('getRequests'))

  yield put(setRequests({ requests }))
})

import React from 'react'
import { ColoredStatus } from '@myImoClient/components/TeamStatusUpdate/index.styles'
import { ProcessStatus } from '@imo/imo-ui-toolkit'
import { TooltipContent } from '@shared/Popover'
import { SPECIAL_ATTRIBUTES, synergyTypes } from '@helpers/constants'
import { nameManagementType } from '@myImoConfig/containers/ConfigSteps/utils'
import { StyledText } from './InitiativeList.styles'
import { CONST_FINANCIALS_COMBINATIONS } from '@helpers/financialsConstants'
import { isNil } from 'lodash/fp'

export const SIX_DECIMAL_CELLS = 6

// If you're adding new field, make sure that it's been added to columnOrder.js too, to be part of column sorting
export const fieldNames = {
  NAME: 'name',
  LIST_ID: 'listId',
  CATEGORY: 'category.name',
  STATUS: 'status',
  STAGE_GATE: 'stageGate.id',
  L4_DATE: 'plannedL4Date',
  L5_DATE: 'plannedL5Date',
  FORECAST_L4_DATE: 'forecastL4Date',
  FORECAST_L5_DATE: 'forecastL5Date',
  FTE: 'FTE',
  ONE_TIME_COST: 'linkedOneTimeCost.impact',
  MARGIN: 'margin',
  MARGIN_IMPACT: 'marginImpact',
  EXIT_MARGIN_IMPACT: 'exitMarginImpact',
  IMPACT: 'impact',
  EXIT_REVENUE: 'exitRevenue',
  EXIT_RUN_RATE: 'exitRunRate',
  EXIT_FTE: 'exitFTE',
  LINKED_PROJECT: 'linkedProject',
  LINKED_PROJECT_DUE_DATE: 'linkedProject.dueDate',
  LINKED_PROJECT_STATUS: 'linkedProject.status',
  LINKED_PROJECT_NAME: 'linkedProject.name',
  FORECAST_COST: 'forecastCost',
  FORECAST_FTE: 'forecastFTE',
  FORECAST_MARGIN: 'forecastMargin',
  FORECAST_REVENUE: 'forecastRevenue',
  FORECAST_NWC: 'forecastNWC',
  TOWARD_TARGET: 'parentInitiative.mirroredImpact',
  ORIGINATING_COLUMNS: 'parentInitiative',
  SPECIAL_ATTRIBUTES,
  CUSTOM_COLUMN: 'CUSTOM_COLUMN',
  OWNERS: 'owners',
  SYNERGY_IN_PERIOD: 'SYNERGY_IN_PERIOD',
  SYNERGY_IN_PERIOD_FTE: 'SYNERGY_IN_PERIOD_FTE',
  SYNERGY_IN_PERIOD_MARGIN: 'SYNERGY_IN_PERIOD_MARGIN',
  CONFIDENTIAL: 'confidential',
  DESCRIPTION: 'description',
  LINKED_ONE_TIME_COST: 'linkedOneTimeCost.name',
  COMMENTS: 'comments',
  NPV_PLANNED: 'npvPlanned',
  NPV_FORECAST: 'npvForecast',
  PLANNED_AVERAGE_IMPACT: 'plannedAverageImpact',
}

export const handleInitiativeColumnEditing = ({ data, colDef }) => {
  if (data.parentInitiative && fieldNames.LIST_ID === colDef.field) return true

  if (data.mirroredInitiatives) return true

  return !data.parentInitiative && !data.mirroredInitiatives
}

export const getInitiativeColumnContentState = (impact = null, marginImpact = null) => {
  return {
    stageGate: (
      <TooltipContent>
        <p>
          <b>L2 Submitted</b>
          <br />
          The planned impact detailed financials must be fully populated before moving to this stage gate
        </p>
        <p>
          <b>L2 Approved</b>
          <br />
          Once the initiative has been approved for L2, the planned impact detailed financials will be locked and can no
          longer be edited (the forecast and actuals will be unlocked at this point)
        </p>
        <p>
          <b>L3 Submitted</b>
          <br />
          The initiative must have a linked project before moving to this stage gate
        </p>
        <p>
          <b>L4 Submitted</b>
          <br />
          The linked project for this initiative must have a{' '}
          <ColoredStatus status={ProcessStatus.COMPLETED}>{ProcessStatus.COMPLETED}</ColoredStatus> status before the
          initiative can move to this stage gate
        </p>
      </TooltipContent>
    ),
    costImpact: 'Annualized run-rate (plan)',
    plannedL4Date: (
      <>
        This date will lock when you are in <b>L3 Approved</b>. After this, to change the <b>L4 date (plan)</b> you will
        need to revert the stage gate back to <b>L3 Submitted</b>
      </>
    ),
    FTE: 'Expected FTE reduction (plan)',
    marginImpact: (
      <>
        Enter the <b>{isNil(marginImpact) ? 'Margin' : marginImpact}</b> representing the total currency value that
        translates the <b>{isNil(impact) ? 'Revenue' : impact}</b> into bottom line impact
      </>
    ),
    margin: (
      <>
        This value is automatically calculated based on the inputs for <b>{isNil(impact) ? 'Revenue' : impact}</b> and{' '}
        <b>{isNil(marginImpact) ? 'Margin' : marginImpact}</b> and is not directly editable
      </>
    ),
    oneTimeCost: (
      <>
        The <b>one-time cost</b> required for implementation of the <b>value capture initiative</b> to achieve the
        planned cost synergies
      </>
    ),
  }
}

export const defaultColumnDefs = {
  sortable: true,
  filter: true,
  floatingFilter: true,
  editable: handleInitiativeColumnEditing,
  headerTooltipComponentParams: {
    allowHTML: true,
  },
  tooltipComponentParams: {
    allowHTML: true,
  },
  resizable: true,
}

export const defaultColumnNames = {
  [synergyTypes.COST]: {
    [fieldNames.IMPACT]: 'Cost impact',
    [fieldNames.FTE]: 'FTE',
    [fieldNames.L4_DATE]: 'L4 date (plan)',
  },
  [synergyTypes.REVENUE]: {
    [fieldNames.IMPACT]: 'Revenue impact',
    [fieldNames.MARGIN_IMPACT]: 'Margin impact',
    [fieldNames.L4_DATE]: 'L4 date (plan)',
  },
  [synergyTypes.NWC]: {
    [fieldNames.IMPACT]: 'NWC impact',
    [fieldNames.L4_DATE]: 'L4 date (plan)',
  },
  [synergyTypes.ONE_TIME_COST]: {
    [fieldNames.IMPACT]: 'One-time cost impact',
    [fieldNames.L4_DATE]: 'L4 date (plan)',
  },
}

//TODO: need to simplified and refactored
export const synergyTypesCustomParamsMapping = (
  getCustomColumnsName,
  isAdvancedFinancials,
  finalMonthName,
  lowercaseDefaultName = false,
  isSMO = false,
) => {
  const formatDefaultName = (name) => (lowercaseDefaultName ? name?.toLowerCase() : name)

  return {
    [synergyTypes.COST]: {
      gridHeader: `Recurring cost ${isSMO ? '' : 'synergy '}initiatives`,
      impactColumn: {
        headerName: isNil(getCustomColumnsName?.impact)
          ? formatDefaultName('Cost impact')
          : getCustomColumnsName.impact,
        isCustomName: !isNil(getCustomColumnsName?.impact),
        field: fieldNames.IMPACT,
        fieldToCompare: 'exitRunRate',
        subtypeKey: 'cost.currency',
        financialsRedirectCombination: CONST_FINANCIALS_COMBINATIONS[synergyTypes.COST].costImpact,
        isAdvancedFinancials,
        finalMonthName,
      },
      forecastCostColumn: {
        headerName: isNil(getCustomColumnsName?.impact)
          ? formatDefaultName('Cost impact')
          : getCustomColumnsName.impact,
        isCustomName: !isNil(getCustomColumnsName?.impact),
        field: fieldNames.FORECAST_COST,
        isAdvancedFinancials,
      },
      forecastFTEColumn: {
        field: fieldNames.FORECAST_FTE,
        isAdvancedFinancials,
      },
      fteColumn: {
        field: fieldNames.FTE,
        fieldToCompare: 'exitFTE',
        isAdvancedFinancials,
        finalMonthName,
      },
    },
    [synergyTypes.REVENUE]: {
      gridHeader: `Revenue ${isSMO ? '' : 'synergy '}initiatives`,
      impactColumn: {
        headerName: isNil(getCustomColumnsName?.impact)
          ? formatDefaultName('Revenue impact')
          : getCustomColumnsName.impact,
        isCustomName: !isNil(getCustomColumnsName?.impact),
        width: 125,
        minWidth: 125,
        field: fieldNames.IMPACT,
        fieldToCompare: 'exitRevenue',
        subtypeKey: 'revenue',
        financialsRedirectCombination: CONST_FINANCIALS_COMBINATIONS[synergyTypes.REVENUE].revenueImpact,
        isAdvancedFinancials,
        finalMonthName,
      },
      forecastRevenueColumn: {
        headerName: isNil(getCustomColumnsName?.impact)
          ? formatDefaultName('Revenue impact')
          : getCustomColumnsName.impact,
        isCustomName: !isNil(getCustomColumnsName?.impact),
        field: fieldNames.FORECAST_REVENUE,
        isAdvancedFinancials,
      },
      forecastMarginColumn: {
        headerName: isNil(getCustomColumnsName?.marginImpact)
          ? formatDefaultName('Margin impact')
          : getCustomColumnsName.marginImpact,
        isCustomName: !isNil(getCustomColumnsName?.marginImpact),
        field: fieldNames.FORECAST_MARGIN,
        isAdvancedFinancials,
      },
      marginImpactColumn: {
        field: fieldNames.MARGIN_IMPACT,
        headerName: isNil(getCustomColumnsName?.marginImpact)
          ? formatDefaultName('Margin impact')
          : getCustomColumnsName.marginImpact,
        isCustomName: !isNil(getCustomColumnsName?.marginImpact),
        marginImpact: getCustomColumnsName?.marginImpact,
        impact: getCustomColumnsName?.impact,
        fieldToCompare: 'exitMarginImpact',
        subtypeKey: 'revenue.margin',
        isAdvancedFinancials,
        finalMonthName,
      },
      marginColumn: {
        field: fieldNames.MARGIN,
        headerName: isNil(getCustomColumnsName?.margin) ? 'Margin (%)' : `${getCustomColumnsName.margin} (%)`,
        isCustomName: !isNil(getCustomColumnsName?.margin),
        marginImpact: getCustomColumnsName?.marginImpact,
        impact: getCustomColumnsName?.impact,
        fieldToCompare: 'exitMarginImpact',
        subtypeKey: 'revenue.margin',
      },
    },
    [synergyTypes.NWC]: {
      gridHeader: `NWC/Capex ${isSMO ? '' : 'synergy '}initiatives`,
      impactColumn: {
        headerName: getCustomColumnsName?.impact || 'NWC/Capex impact',
        field: fieldNames.IMPACT,
        fieldToCompare: 'exitRunRate',
        subtypeKey: 'nwc',
        financialsRedirectCombination: CONST_FINANCIALS_COMBINATIONS[synergyTypes.NWC].NWCImpact,
        isAdvancedFinancials,
        finalMonthName,
      },
      forecastNWCColumn: {
        headerName: getCustomColumnsName?.impact || 'NWC/Capex impact',
        field: fieldNames.FORECAST_NWC,
      },
    },
    [synergyTypes.ONE_TIME_COST]: {
      gridHeader: ({ managementType }) =>
        `One-time implementation costs and ${nameManagementType(managementType)} expenses`,
      impactColumn: {
        headerName: 'One-time cost impact',
        cellEditorParams: {
          min: -1000000,
          max: 1000000,
          maxDecimalCount: SIX_DECIMAL_CELLS,
        },
        headerTooltip: null,
        maxWidth: 150,
        width: 130,
        field: 'impact',
        fieldToCompare: 'exitRunRate',
        subtypeKey: 'oneTimeCost',
        financialsRedirectCombination: CONST_FINANCIALS_COMBINATIONS[synergyTypes.ONE_TIME_COST].oneTimeCostImpact,
        finalMonthName,
      },
    },
  }
}

export const getInitiativeLabel = (isOneTimeCost, isSMO) =>
  isOneTimeCost ? 'one-time cost' : isSMO ? 'initiative' : 'synergy initiative'

export const linkedItemsLabel = {
  linkedProject: 'project',
  linkedSynergyInitiative: 'synergy initiative',
  linkedSynergyInitiativeSMO: 'initiative',
  linkedOneTimeCost: 'cost',
}

export const getDependenciesLabel = (items, isSMO = false) => {
  const itemsList = Object.keys(items).map((key) => {
    const labelKey = key === 'linkedSynergyInitiative' && isSMO ? `${key}SMO` : key
    return (
      <>
        {linkedItemsLabel[labelKey]} <b>{items[key]}</b>
      </>
    )
  })

  if (itemsList.length === 1) return itemsList[0]

  const firsts = itemsList.slice(0, itemsList.length - 1)
  const last = itemsList[itemsList.length - 1]

  return (
    <>
      {firsts.map((item, i) => (
        <>
          {item}
          {i + 1 === firsts.length ? '' : ', '}
        </>
      ))}{' '}
      and {last}
    </>
  )
}

export const getDeletionPopupContent = (isOneTimeCost, initiativeName, linkedItems, isSMO) => (
  <>
    <StyledText>
      You are about to delete <b>{initiativeName}</b>. This{' '}
      {isOneTimeCost ? 'one-time cost' : isSMO ? 'initiative' : 'synergy initiative'} is linked to the{' '}
      {getDependenciesLabel(linkedItems, isSMO)}.
    </StyledText>
    <StyledText>
      We recommend deleting all linked projects and{' '}
      {isOneTimeCost ? 'one-time costs' : isSMO ? 'initiatives' : 'synergy initiatives'} when deleting a{' '}
      {isOneTimeCost ? (isSMO ? 'initiative.' : 'synergy initiative.') : 'one-time cost.'}
    </StyledText>
    <StyledText>
      Are you sure you want to continue?{' '}
      <span className="alert-text">
        <b>This action cannot be reversed.</b>
      </span>
    </StyledText>
  </>
)

export const requiredFieldEmptyError = 'This cell cannot have an ‘empty’ value'

import { handleActions } from 'redux-actions'
import * as constants from '@generic/actions/actionTypes'
import { loadingState } from '@imo/imo-ui-toolkit'
import { omit } from 'lodash/fp'
import { IGenericReducerState } from './types'

export const initialConfig = {
  modules: {},
  isLaunched: false,
  dealType: '',
  modulesSelect: {},
}

export const initialState = {
  config: initialConfig,
  user: {},
  users: {},
  libraries: [],
  librariesV2: {},
  linkedLibraries: [],
  linkedLibrariesState: loadingState.INITIAL,
  teams: [],
  permissions: [],
  teamsPermissions: {},
  teamsWithoutVC: [],
  redirects: {},
  exceptions: [],
  role: {},
  roles: [],
  selectedTeamIds: {
    team: null,
    imoTeam: null,
    centralTeam: null,
    centralOrgDesignTeam: null,
  },
  cachedCompanyLogos: {
    blobCompanyLogoA: null,
    blobCompanyLogoB: null,
  },
  companyLogos: {
    companyALogo: null,
    companyALogoToDeleting: null,
    companyBLogo: null,
    companyBLogoToDeleting: null,
  },
  loader: false,
  warningMessage: null,
  abortController: null,
  isPdfEnabled: false,
  dictionaries: [],
  deliverableStatuses: [],
  snapshotMinDate: new Date().getTime(),
  headerToggles: {},
  initiativeArgs: {},
  actionsState: {},
  isLoadedInitialData: false,
  isServerNotResponding: null,
  isChatOpen: false,
  hasChatBeenOpened: false,
  isSupportBotOpen: false,
  hasSupportBotBeenOpened: false,
  isDocsBotOpen: false,
  hasDocsBotBeenOpened: false,
  token: null,
  routerLocation: {
    pathname: '',
    search: '',
    hash: '',
    state: null,
    key: '',
  },
}

const genericReducer = handleActions<IGenericReducerState, $TSFixMe>(
  {
    [constants.FETCH_TEAM_LINKED_LIBRARIES_STATE]: (state, action) => {
      return {
        ...state,
        linkedLibrariesState: action.payload,
      }
    },
    [constants.SET_INITIAL_CLIENT_DATA_LOADED]: (state, action) => {
      return {
        ...state,
        isLoadedInitialData: action.payload,
      }
    },
    /**
     * @deprecated. Use data from src/domain/instanceConfig/reducer.ts
     */
    [constants.SET_CONFIG]: (state, action) => {
      const { config } = action.payload

      return {
        ...state,
        config: {
          ...state.config,
          ...config,
        },
      }
    },

    [constants.SET_USER]: (state, action) => {
      const { user } = action.payload

      return {
        ...state,
        user,
      }
    },

    [constants.SET_IS_FIRST_TIME_USER]: (state, action) => {
      const { isFirstTimeUser } = action.payload

      return {
        ...state,
        user: {
          ...state.user,
          firstTime: isFirstTimeUser,
        },
      }
    },

    [constants.SET_USERS_LIST]: (state, action) => {
      const { users } = action.payload

      return {
        ...state,
        users,
      }
    },

    [constants.SET_COMPANY_A_LOGO]: (state, action) => {
      const { companyALogo, companyALogoToDeleting = null } = action.payload

      return {
        ...state,
        companyLogos: {
          ...state.companyLogos,
          companyALogo,
          companyALogoToDeleting,
        },
      }
    },

    [constants.SET_COMPANY_B_LOGO]: (state, action) => {
      const { companyBLogo, companyBLogoToDeleting = null } = action.payload

      return {
        ...state,
        companyLogos: {
          ...state.companyLogos,
          companyBLogo,
          companyBLogoToDeleting,
        },
      }
    },

    [constants.SET_LIBRARIES]: (state, action) => {
      const { libraries } = action.payload

      return {
        ...state,
        libraries,
      }
    },

    [constants.SET_LIBRARIES_V2]: (state, action) => {
      const { librariesV2 } = action.payload

      return {
        ...state,
        librariesV2,
      }
    },

    // V2 linked libraries by team
    [constants.SET_TEAM_LINKED_LIBRARIES]: (state, action) => {
      const { linkedLibraries } = action.payload

      return {
        ...state,
        linkedLibraries,
      }
    },

    [constants.SET_TEAMS_LIST]: (state, action) => {
      const { teams } = action.payload

      return {
        ...state,
        teams,
      }
    },

    [constants.SET_TEAM_DATA]: (state, action) => {
      const { teamId, update } = action.payload
      const teamIndex = state.teams.findIndex((team) => team.id === teamId)
      state.teams[teamIndex] = { ...state.teams[teamIndex], ...update }
      return state
    },

    [constants.SET_KNOWLEDGE_CENTER_VISIBILITY_STATUS_SUCCESS]: (state, action) => {
      const { teamId, status } = action.payload

      return {
        ...state,
        teams: state.teams.map((team: $TSFixMe) =>
          team.id === Number(teamId) ? { ...team, isKnowledgeCenterActive: status } : team,
        ),
      }
    },

    [constants.SET_PERMISSIONS]: (state, action) => {
      const { permissions, teamsPermissions, exceptions, redirects, teamsWithoutVC } = action.payload

      return {
        ...state,
        permissions,
        teamsPermissions,
        teamsWithoutVC,
        exceptions,
        redirects,
      }
    },

    [constants.SET_ROLE]: (state, action) => {
      const { role } = action.payload

      return {
        ...state,
        role,
      }
    },

    [constants.SET_ROLES]: (state, action) => {
      const { roles } = action.payload

      return {
        ...state,
        roles,
      }
    },

    [constants.SET_SELECTED_TEAM_IDS]: (state, action) => {
      return {
        ...state,
        selectedTeamIds: action.payload,
      }
    },

    [constants.SET_SELECTED_TEAM_ID]: (state, action) => {
      return {
        ...state,
        selectedTeamIds: {
          ...state.selectedTeamIds,
          ...action.payload,
        },
      }
    },

    [constants.SET_LOADER]: (state, action) => {
      return { ...state, loader: action.payload.loader }
    },

    [constants.SET_ABORT_CONTROLLER]: (state, { payload: abortController }: { payload: $TSFixMe }) => {
      return { ...state, abortController }
    },

    [constants.SHOW_WARNING_MESSAGE]: (state, action) => {
      const { message } = action.payload

      return { ...state, warningMessage: message }
    },
    [constants.SET_DICTIONARIES]: (state, action) => {
      const { dictionaries } = action.payload

      return {
        ...state,
        dictionaries,
      }
    },
    [constants.SET_TEAM_DELIVERABLE_STATUS]: (state, action) => {
      const { deliverableStatuses } = action.payload

      return {
        ...state,
        deliverableStatuses,
      }
    },
    [constants.SET_PDF_MODE]: (state, action) => {
      return {
        ...state,
        isPdfEnabled: action.payload,
      }
    },
    [constants.FETCH_TOGGLES_RESET]: (state) => {
      return {
        ...state,
        headerToggles: {},
      }
    },
    [constants.FETCH_TOGGLES_SUCCESS]: (state, { payload: { toggles: headerToggles } }) => {
      return {
        ...state,
        headerToggles,
      }
    },
    [constants.SET_TOGGLES_STATUS_SUCCESS]: (state, { payload: { item, status } }) => {
      return {
        ...state,
        headerToggles: {
          ...state.headerToggles,
          [item]: status,
        },
      }
    },
    [constants.SET_CACHED_LOGOS]: (state, { payload }) => {
      return {
        ...state,
        cachedCompanyLogos: {
          ...state.cachedCompanyLogos,
          ...payload,
        },
      }
    },
    [constants.SET_SNAPSHOT_MIN_DATE]: (state, { payload: snapshotMinDate }) => ({
      ...state,
      snapshotMinDate,
    }),
    [constants.SAVE_INITIATIVE_ARGS]: (state, { payload: initiativeArgs }) => ({
      ...state,
      initiativeArgs,
    }),
    [constants.SET_ACTION_STATE]: (state, { payload }) => ({
      ...state,
      actionsState: {
        ...state.actionsState,
        [payload.type]: payload.state,
      },
    }),
    [constants.CLEAR_ACTION_STATE]: (state, { payload }) => ({
      ...state,
      actionsState: omit(payload.type, state.actionsState),
    }),
    /**
     * @deprecated. Use data from src/domain/instanceConfig/reducer.ts
     */
    [constants.UPDATE_WELCOME_POPUP_TEXT]: (state, { payload }) => ({
      ...state,
      config: {
        ...state.config,
        ...payload,
      },
    }),
    [constants.SERVER_NOT_RESPONDING]: (state, { payload }) => ({
      ...state,
      isServerNotResponding: payload,
    }),
    [constants.TOGGLE_CHAT]: (state) => ({
      ...state,
      isChatOpen: !state.isChatOpen,
      hasChatBeenOpened: !state.isChatOpen || state.hasChatBeenOpened,
    }),
    [constants.TOGGLE_SUPPORT_BOT]: (state) => ({
      ...state,
      isSupportBotOpen: !state.isSupportBotOpen,
      hasSupportBotBeenOpened: !state.isSupportBotOpen || state.hasSupportBotBeenOpened,
    }),
    [constants.TOGGLE_DOCS_BOT]: (state) => ({
      ...state,
      isDocsBotOpen: !state.isDocsBotOpen,
      hasDocsBotBeenOpened: !state.isDocsBotOpen || state.hasDocsBotBeenOpened,
    }),
    [constants.REFRESH_TOKEN_SUCCESS]: (state, { payload }) => ({
      ...state,
      token: payload.token,
    }),
    [constants.SET_ROUTER_LOCATION]: (state, { payload }) => ({
      ...state,
      routerLocation: payload,
    }),
  },
  initialState,
)

export default genericReducer

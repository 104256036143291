import { put, call } from 'redux-saga/effects'
import { getTeamResourcesApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { setScenarioReport } from '@teamResources/scenarioReport/actions/actions'

export const fetchScenarioReport = createSaga(function* fetchScenarioReport(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamResourcesApi()
  const data = yield call(api.request('getScenarioReport'))

  yield put(setScenarioReport(data || null))
}, true)

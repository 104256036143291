import { isArray, size } from 'lodash/fp'
import { call, put, select } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getStatusUpdateApi } from '@common/net'
import { setYear5PipelineSummary, setYearlyImpactChart, setStatusUpdateOneTimeCosts } from '../actions'
import { synergyTypes } from '@helpers/constants'
import { fetchInitiativeListWithLoader } from '@teamValueCaptureV2/initiativeList/actions/actions'
import { fetchInitiativesCategories } from '@teamValueCaptureV2/initiativeList/sagas/initiativesCategories'
import { getIsDayOneMissed } from '@generic/selectors'

export const fetchYear5PipelineSummary = createSaga(function* fetchYear5PipelineSummary({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const isDayOneMissed = yield select(getIsDayOneMissed)

  if (isDayOneMissed) return

  const api = yield getStatusUpdateApi()
  const year5PipelineSummary = yield call(
    api.request('getYear5PipelineSummary', {
      query: payload,
    }),
  )

  yield put(setYear5PipelineSummary({ year5PipelineSummary }))
})

export const fetchYearlyImpactChart = createSaga(function* fetchYearlyImpactChart({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getStatusUpdateApi()
  const yearlyImpactChart = yield call(
    api.request('getYearlyImpactChart', {
      query: payload,
    }),
  )

  yield put(setYearlyImpactChart({ yearlyImpactChart }))
})

export const fetchStatusUpdateOneTimeCosts = createSaga(function* fetchStatusUpdateOneTimeCosts({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, categoryIds } = payload

  const isDayOneMissed = yield select(getIsDayOneMissed)
  if (isDayOneMissed) {
    return
  }

  const query = { teamId } as { teamId: string; categoryIds?: $TSFixMe[] }

  if (isArray(categoryIds) && size(categoryIds)) {
    query.categoryIds = categoryIds
  }

  const api = yield getStatusUpdateApi()
  const statusUpdateOneTimeCost = yield call(api.request('getStatusUpdateOneTimeCosts', { query }))

  yield put(setStatusUpdateOneTimeCosts({ statusUpdateOneTimeCost }))
})

export const fetchInitialOneTimeCosts = createSaga(function* fetchInitialOneTimeCosts({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload
  const synergyType = synergyTypes.ONE_TIME_COST
  yield call(fetchInitiativesCategories, {
    payload: {
      synergyTypes: [synergyType],
      teamId,
    },
  })

  yield call(fetchStatusUpdateOneTimeCosts, {
    payload: {
      teamId,
    },
  })

  // TODO: delete this call, when back change response, and add call fetchStatusUpdateOneTimeCosts
  yield put(fetchInitiativeListWithLoader({ teamId: payload.teamId, synergyType: synergyTypes.ONE_TIME_COST }))
})

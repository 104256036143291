import { put, call } from 'redux-saga/effects'
import { getVCTrackerV2Api } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { setLateOrAtRiskData, setMetricDetails } from '../actions/actions'

export const fetchLateOrAtRiskData = createSaga(function* fetchLateOrAtRiskData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, valueType } = payload

  const api = yield getVCTrackerV2Api()
  const data = yield call(
    api.request('getLateOrAtRiskData', {
      query: {
        teamId,
        valueType,
      },
    }),
  )

  yield put(setLateOrAtRiskData(data))
},
true)

export const fetchMetricDetails = createSaga(function* fetchMetricDetails({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const {
    teamId,
    metric: { type, level },
    valueType,
  } = payload

  const api = yield getVCTrackerV2Api()
  const data = yield call(
    api.request('getMetricDetails', {
      query: {
        teamId,
        valueType,
        metricType: level,
        metricName: type,
      },
    }),
  )

  yield put(setMetricDetails(data))
})

export const updateInitiativeMetricDetails = createSaga(function* updateInitiativeMetricDetails({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, metricId, read } = payload

  const api = yield getVCTrackerV2Api()
  yield call(
    api.request('setMetricDetails', {
      query: { teamId, metricId },
      body: {
        read,
      },
    }),
  )
})

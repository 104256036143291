import { call, put } from 'redux-saga/effects'
import { setLibs, setLibsV2, setTeamLinkedLibraries } from '@generic/actions/actions'
import { getGenericApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { FETCH_TEAM_LINKED_LIBRARIES_STATE } from '@generic/actions/actionTypes'

export const fetchLibs = createSaga(function* fetchLibs(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getGenericApi()
  const libraries = yield call(api.request('getLibraries'))
  yield put(setLibs({ libraries }))
}, false)

export const fetchLibsV2 = createSaga(function* fetchLibsV2(action: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getGenericApi()
  const includeTeams = action?.payload?.includeTeams

  const query = {} as $TSFixMe

  if (includeTeams) query.includeTeams = includeTeams

  const librariesV2 = yield call(api.request('getLibrariesV2', { query }))
  yield put(setLibsV2({ librariesV2 }))
})

// V2 linked libraries by team
export const fetchLinkedLibrariesByTeam = createSaga(function* fetchLinkedLibrariesByTeam({
  payload: { teamId },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getGenericApi()
  const linkedLibraries = yield call(api.request('getLinkedLibrariesByTeam', { query: { teamId } }))
  yield put(setTeamLinkedLibraries({ linkedLibraries }))
},
FETCH_TEAM_LINKED_LIBRARIES_STATE)

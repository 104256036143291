import { accessRights, teams } from '../constants'
import { routesList } from '../routesList'
import { CENTRAL_MEMBER_PERMISSIONS } from './centralMember'

export const CENTRAL_ORG_DESIGN_MEMBER_PERMISSIONS = {
  ...CENTRAL_MEMBER_PERMISSIONS,
  name: teams.CENTRAL_ORG_DESIGN,
  priority: 3,
  permissions: [
    ...CENTRAL_MEMBER_PERMISSIONS.permissions,
    {
      route: routesList.ORG_DESIGN_TRACKER.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
  ],
}

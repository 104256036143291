import { call, put } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { getTeamResourcesApi } from '@common/net'
import { pick } from 'lodash'
import { createSaga } from '@common/sagaCreator/createSaga'
import { transformEmptyStringsToNull } from '@helpers/utils'
import {
  deleteMasterCalendarLabelSuccess,
  setMasterCalendarLabels,
  updateMasterCalendarLabelSuccess,
} from '@teamResources/resourcesHome/actions/actions'
import { labelFields } from '@teamResources/resourcesHome/selectors/constants'

export const fetchMasterCalendarLabels = createSaga(function* fetchMasterCalendarLabels(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const api = yield getTeamResourcesApi()
  const data = yield call(api.request('getCalendarLabels'))

  yield put(setMasterCalendarLabels(data))

  yield call(delay, 100)
},
true)

export const createMasterCalendarLabel = createSaga(function* createMasterCalendarLabel({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data } = payload

  const api = yield getTeamResourcesApi()
  const { id } = yield call(
    api.request('createCalendarLabel', {
      body: pick(data, labelFields),
    }),
  )

  yield put(updateMasterCalendarLabelSuccess({ id: data.id, data: { ...data, id, isNew: false } }))
},
true)

export const updateMasterCalendarLabel = createSaga(function* updateMasterCalendarLabel({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { eventId, data } = payload
  const transformedData = transformEmptyStringsToNull(data)

  const api = yield getTeamResourcesApi()
  yield call(
    api.request('updateCalendarLabel', {
      query: {
        eventId,
      },
      body: pick(transformedData, labelFields),
    }),
  )

  yield put(updateMasterCalendarLabelSuccess({ id: eventId, data }))
})

export const deleteMasterCalendarLabel = createSaga(function* deleteMasterCalendarLabel({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { eventId } = payload

  const api = yield getTeamResourcesApi()
  yield call(
    api.request('deleteCalendarLabel', {
      query: {
        eventId,
      },
    }),
  )

  yield put(deleteMasterCalendarLabelSuccess({ id: eventId }))
},
true)

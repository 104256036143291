import { getIsGridSuppressClickEdit, CheckboxRenderer, ActionIconRenderer } from '@imo/imo-ui-toolkit'
import ReviewRenderer from './ReviewRenderer'

export const USER_AVATAR_SIZE = 30
export const USER_AVATAR_RIGHT_MARGIN = 18

const setData = (newValue, rowData, colDef, context) => {
  rowData[colDef.field] = newValue
  context.handleUpdateRow(rowData)
}

export const columnDefs = [
  {
    headerName: 'Complete',
    field: 'complete',
    hide: false,
    editable: false,
    sortable: false,
    cellRenderer: CheckboxRenderer,
    width: 30,
    cellClassRules: {
      no_focus: 'true',
      'complete-toggle-cell': 'true',
    },
    headerClass: 'align-center',
    valueSetter: (params) => setData(params.newValue, params.data, params.colDef, params.context),
  },
  {
    headerName: 'Timing',
    field: 'user',
    sortable: false,
    editable: false,
    cellRenderer: ReviewRenderer,
    cellClass: 'review-description-cell',
    cellRendererParams: {
      lines: 2,
    },
    tooltipField: 'description',
    tooltipParams: {
      truncateTargetSelector: '.description',
    },
  },
  {
    headerName: 'Delete',
    field: 'id',
    sortable: false,
    editable: false,
    width: 20,
    cellRenderer: ActionIconRenderer,
    cellRendererParams: ({ api }) => {
      const isNotEditable = getIsGridSuppressClickEdit(api)

      return {
        icon: 'trash',
        onClick: ({ value, setValue }) => setValue(value),
        disabled: isNotEditable,
      }
    },
    headerClass: 'align-center',
    cellClass: 'button-cell',
    valueSetter: (params) => {
      params.context.handleDeleteRequest(params.newValue)
    },
  },
]

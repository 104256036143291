import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchProjectPlanList = createAction(constants.FETCH_PROJECT_PLAN_LIST)
export const fetchProjectPlanListWithLoader = createAction(
  constants.FETCH_PROJECT_PLAN_LIST,
  null,
  getMetaWithActionLoading,
)
export const setProjectPlanList = createAction(constants.SET_PROJECT_PLAN_LIST)
export const resetProjectPlanList = createAction(constants.RESET_PROJECT_PLAN_LIST)

export const removeNotificationFromLoading = createAction(constants.REMOVE_NOTIFICATION_FROM_LOADING)
export const addNotificationToLoading = createAction(constants.ADD_NOTIFICATION_TO_LOADING)
export const fetchNotifications = createAction(constants.FETCH_NOTIFICATIONS)
export const setNotifications = createAction(constants.SET_NOTIFICATIONS)
export const updateInbox = createAction(constants.UPDATE_INBOX_NOTIFICATIONS)
export const updateArchive = createAction(constants.UPDATE_ARCHIVE_NOTIFICATIONS)
export const appendInboxNotifications = createAction(constants.APPEND_INBOX_NOTIFICATIONS)
export const appendArchiveNotifications = createAction(constants.APPEND_ARCHIVE_NOTIFICATIONS)
export const removeNotificationsFromInbox = createAction(constants.REMOVE_NOTIFICATIONS_FROM_INBOX)
export const removeNotificationsFromArchive = createAction(constants.REMOVE_NOTIFICATIONS_FROM_ARCHIVE)
export const archiveEntityNotifications = createAction(constants.ARCHIVE_ENTITY_NOTIFICATIONS)
export const unarchiveEntityNotifications = createAction(constants.UNARCHIVE_ENTITY_NOTIFICATIONS)
export const archiveAllNotifications = createAction(constants.ARCHIVE_ALL_NOTIFICATIONS)

export const fetchTeamWeekStatus = createAction(constants.FETCH_TEAM_WEEK_STATUS)
export const fetchTeamWeekStatusWithLoader = createAction(
  constants.FETCH_TEAM_WEEK_STATUS,
  null,
  getMetaWithActionLoading,
)
export const setTeamWeeklyStatusData = createAction(constants.SET_TEAM_WEEKLY_STATUS_DATA)
export const updateTeamWeeklyStatusData = createAction(constants.UPDATE_TEAM_WEEKLY_STATUS)

export const fetchTeamProjectsStatuses = createAction(
  constants.FETCH_TEAM_PROJECTS_STATUSES,
  null,
  getMetaWithActionLoading,
)
export const setTeamProjectsStatuses = createAction(constants.SET_TEAM_PROJECTS_STATUSES)

export const fetchDeliverableTypesStatuses = createAction(
  constants.FETCH_DELIVERABLE_TYPES_STATUSES,
  null,
  getMetaWithActionLoading,
)
export const setDeliverableTypesStatuses = createAction(constants.SET_DELIVERABLE_TYPES_STATUSES)

export const fetchKeyIssues = createAction(constants.FETCH_KEY_ISSUES)
export const fetchKeyIssuesWithLoader = createAction(constants.FETCH_KEY_ISSUES, null, getMetaWithActionLoading)
export const setKeyIssues = createAction(constants.SET_KEY_ISSUES)
export const updateKeyIssue = createAction(constants.UPDATE_KEY_ISSUE)
export const createKeyIssue = createAction(constants.CREATE_KEY_ISSUE)
export const deleteKeyIssue = createAction(constants.DELETE_KEY_ISSUE)

export const fetchFlaggedRisksAndDecisions = createAction(
  constants.FETCH_FLAGGED_RISKS_AND_DECISIONS,
  null,
  getMetaWithActionLoading,
)
export const setFlaggedRisksAndDecisions = createAction(constants.SET_FLAGGED_RISKS_AND_DECISIONS)

export const fetchOtherTeamUpdates = createAction(constants.FETCH_OTHER_TEAM_UPDATES, null, getMetaWithActionLoading)
export const setOtherTeamUpdates = createAction(constants.SET_OTHER_TEAM_UPDATES)

export const createOtherTeamUpdate = createAction(constants.CREATE_OTHER_TEAM_UPDATE)
export const createOtherTeamUpdateSuccess = createAction(constants.CREATE_OTHER_TEAM_UPDATE_SUCCESS)

export const updateOtherTeamUpdate = createAction(constants.UPDATE_OTHER_TEAM_UPDATE)
export const updateOtherTeamUpdateSuccess = createAction(constants.UPDATE_OTHER_TEAM_UPDATE_SUCCESS)

export const deleteOtherTeamUpdate = createAction(constants.DELETE_OTHER_TEAM_UPDATE)
export const deleteOtherTeamUpdateSuccess = createAction(constants.DELETE_OTHER_TEAM_UPDATE_SUCCESS)

export const submitWeeklyStatusUpdate = createAction(constants.SUBMIT_WEEKLY_STATUS_UPDATE)
export const submitWeeklyStatusUpdateSuccess = createAction(constants.SUBMIT_WEEKLY_STATUS_UPDATE_SUCCESS)

export const fetchKeyProcessDeleteDetails = createAction(constants.FETCH_KEY_PROCESS_DELETE_DETAILS)
export const setKeyProcessDeleteDetails = createAction(constants.SET_KEY_PROCESS_DELETE_DETAILS)
export const setKeyProcessDeleteDetailsState = createAction(constants.SET_KEY_PROCESS_DELETE_DETAILS_STATE)

export const updateNotificationWatchStatus = createAction(constants.UPDATE_NOTIFICATION_WATCH_STATUS)

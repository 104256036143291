export const CONFIDENTIAL_OPTIONS_LIST = [
  {
    key: '1',
    text: 'All',
    value: 'All',
  },
  {
    key: '2',
    text: 'Confidential',
    value: 'Confidential',
  },
  {
    key: '3',
    text: 'Non-confidential',
    value: 'NonConfidential',
  },
]

export const CONFIDENTIAL_TOOLTIP_TEXT = {
  TEAM_VC: 'Use this dropdown menu to filter confidential initiatives in team value capture tabs for this team',
  VC_TRACKER: 'Use this dropdown menu to filter confidential initiatives in value capture dashboards',
}

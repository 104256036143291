import { put, call } from 'redux-saga/effects'
import { updateIssueSuccess } from '@hypercareLog/actions/actions'
import { getHypercareLogApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { parseOwners } from '@shared/Grid/utils/ownerCellUtils'
import { HyperCareLogDto, RequestPayloadHyperCareLogDto } from '@common/types/dtos/HyperCareLogDto'

const prepareRequestPayload = (data: HyperCareLogDto) => {
  const temp: RequestPayloadHyperCareLogDto = {
    issueName: data.issueName || null,
    description: data.description || null,
    criticality: data.criticality || null,
    status: data.status || null,
    dateRaised: data.dateRaised || null,
    dateResolved: data.dateResolved || null,
    forecastDate: data.forecastDate || null,
    comments: data.comments || null,
  }

  // My team (central integration teams) hypercare log has owners
  if (data.owners) {
    temp.owners = parseOwners(data.owners)
    temp.imoVisibility = data.imoVisibility || null
  }

  // Control tower (regional IMO/SMO teams) hypercare log has imoLeader
  if (data.imoLeader) {
    temp.imoLeader = parseOwners(data.imoLeader)
  }

  return temp
}

export const updateIssue = createSaga(function* updateIssue(action: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = action.payload

  yield put(updateIssueSuccess(data))

  const api = yield getHypercareLogApi()
  yield call(
    api.request('updateIssue', {
      body: prepareRequestPayload(data),
      query: { teamId, issueId: data.id },
    }),
  )
})

import { createSaga } from '@common/sagaCreator/createSaga'
import { put, call, select } from 'redux-saga/effects'
import * as actions from '@teamValueCaptureV2/advancedFinancials/actions/actions'
import { getInitiativeListV2Api } from '@common/net'
import {
  fetchFinancials,
  fetchInitiativeListWithLoader,
  updateSelectedAndMirroredInitiatives,
  updateInitiative,
  setFinancials,
} from '@teamValueCaptureV2/initiativeList/actions/actions'
import {
  getSelectedInitiativeById,
  getIsAllInitiative,
  getSynergyType,
} from '@teamValueCaptureV2/initiativeList/selectors'
import { FINANCIALS_FILTER } from '@helpers/financialsConstants'

export const fetchAdvancedFinancials = createSaga(function* fetchAdvancedFinancials({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId } = payload
  const synergyType = yield select(getSynergyType)
  const api = yield getInitiativeListV2Api()

  const advancedFinancials = yield call(
    api.request('getDetailedFinancialsData', {
      query: {
        teamId,
        initiativeId,
      },
    }),
  )

  if (synergyType === 'Revenue') {
    const revenueFinancialsData = yield call(
      api.request('getFinancialsData', {
        query: {
          subtype1: FINANCIALS_FILTER.SYNERGY_IN_PERIOD,
          subtype2: FINANCIALS_FILTER.REVENUE,
          subtype3: FINANCIALS_FILTER.PLANNED,
          teamId: payload.teamId,
          id: payload.initiativeId,
          synergyType,
        },
      }),
    )

    yield put(setFinancials({ financialsData: revenueFinancialsData }))
  }

  yield put(actions.setAdvancedFinancials(advancedFinancials))
})

export const createDetailedFinancials = createSaga(function* createDetailedFinancials({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId, name } = payload
  const api = yield getInitiativeListV2Api()

  yield call(
    api.request('createDetailedFinancials', {
      query: {
        teamId: teamId,
        initiativeId: initiativeId,
      },
      body: {
        name,
      },
    }),
  )

  yield put(actions.fetchAdvancedFinancials({ teamId, initiativeId }))
},
true)

export const deleteDetailedFinancials = createSaga(function* deleteDetailedFinancials({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId, id } = payload
  const api = yield getInitiativeListV2Api()

  yield call(
    api.request('deleteDetailedFinancials', {
      query: {
        teamId,
        initiativeId,
        id,
      },
    }),
  )

  yield put(actions.fetchAdvancedFinancials({ teamId, initiativeId }))
})

export const updateDetailedFinancialsNameOrCategory = createSaga(function* updateDetailedFinancialsNameOrCategory({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId, name, netImpactItem, fieldName } = payload
  const api = yield getInitiativeListV2Api()
  const body = {} as { name?: string; categoryId?: string; impactType?: string }

  if (fieldName === 'name') body.name = name

  if (fieldName === 'category.name') body.categoryId = netImpactItem.categoryId

  if (fieldName === 'netImpactItem.impactType' && netImpactItem.impactType) body.impactType = netImpactItem.impactType

  yield call(
    api.request('updateDetailedFinancialsNameOrCategory', {
      query: {
        teamId,
        initiativeId,
        id: netImpactItem.id,
      },
      body,
    }),
  )

  yield put(actions.fetchAdvancedFinancials({ teamId, initiativeId }))
},
true)

export const updateDetailedFinancials = createSaga(function* updateDetailedFinancials({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { initiativeTeamId, customColumn, synergyType, data } = payload
  const selectedInitiative = yield select(getSelectedInitiativeById)

  const newInitiativeData = {
    ...selectedInitiative,
    categoryId: data.categoryId,
    category: data.category,
    customColumns: data.customColumns,
  }

  yield put(
    updateInitiative({
      synergyType,
      initiativeTeamId,
      initiativeId: selectedInitiative.id,
      data: newInitiativeData,
      financialData: null,
      customColumn,
      netImpactItemId: data.netImpactItem?.id,
      refetchAF: true,
      groupId: payload.data?.netImpactGroup?.id,
    }),
  )
},
true)

export const onCloseAdvancedFinancials = createSaga(function* onCloseAdvancedFinancials({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, synergyType, initiativeId, financialFilters } = payload
  const isAllInitiative = yield select(getIsAllInitiative)

  if (isAllInitiative) {
    yield put(updateSelectedAndMirroredInitiatives())
  } else {
    yield put(fetchInitiativeListWithLoader({ teamId, synergyType }))
  }

  yield put(
    fetchFinancials({
      teamId,
      synergyType,
      id: initiativeId,
      ...financialFilters,
    }),
  )

  yield put(actions.setIsAdvancedFinancialsOpened(false))
})

export const updateFinancialData = createSaga(function* updateFinancialData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId, body } = payload
  const api = yield getInitiativeListV2Api()

  yield call(
    api.request('updateFinancialData', {
      query: {
        teamId,
        initiativeId,
      },
      body,
    }),
  )

  yield put(actions.fetchAdvancedFinancials({ teamId, initiativeId }))
})

export const updateRunRate = createSaga(function* updateRunRate({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId, netImpactGroupId, netImpactItemId, runRate } = payload
  const api = yield getInitiativeListV2Api()

  yield call(
    api.request('updateRunRate', {
      query: {
        teamId,
        initiativeId,
        itemId: netImpactItemId,
        groupId: netImpactGroupId,
      },
      body: {
        runRate,
      },
    }),
  )

  yield put(actions.fetchAdvancedFinancials({ teamId, initiativeId }))
})

export const updateRunRateImpact = createSaga(function* updateRunRate({
  payload: { reqData, gridData },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId } = reqData
  const api = yield getInitiativeListV2Api()

  yield call(
    api.request('updateRunRateImpact', {
      query: {
        teamId,
        initiativeId,
      },
      body: gridData,
    }),
  )

  yield put(actions.fetchAdvancedFinancials({ teamId, initiativeId }))
})

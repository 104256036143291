import { flow, map, sortBy } from 'lodash/fp'
import { USER_FIELD } from '@helpers/userConstants'
import { validateDomains } from '@helpers/validators'

export const formatUsersList = (usersList, restrictedDomains) => {
  return flow(
    sortBy(({ isAdmin }) => !isAdmin),
    map((user) => ({
      ...user,
      [USER_FIELD.IS_RESTRICTED]: validateDomains({ user, domains: restrictedDomains }),
    })),
  )(usersList)
}

export const targetBases = {
  revenue: 'revenue',
  margin: 'margin',
}

export const targetBaseDefaultValues = {
  [targetBases.revenue]: 'Revenue impact',
  [targetBases.margin]: 'Margin impact',
}

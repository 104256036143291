export type HttpMethod = 'get' | 'post' | 'put' | 'PATCH' | 'delete'

export type FetchOptions = {
  method: HttpMethod
  headers?: Record<string, unknown>
  body?: BodyInit | null | undefined
  signal?: AbortSignal
}

// TODO: use existing midfetch, or reimplement refresh token and retry on 401 functionality
// See https://git.mckinsey-solutions.com/mid/menshen/tree/develop/packages/mid-sdk
export function midfetch(url: string, options: FetchOptions) {
  const accessToken = sessionStorage.getItem('_mid-access-token')
  const tenant = sessionStorage.getItem('_mid-tenant')
  const tenantId = tenant ? JSON.parse(tenant)?.tenantId : undefined

  return fetch(url, {
    ...options,
    headers: { 'x-tenant': tenantId, Authorization: `Bearer ${accessToken}`, ...options.headers },
  })
}

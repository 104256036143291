import { getRestrictedTeams } from '@common/teamFilter/helpers'
import { find, getOr, flow, first } from 'lodash/fp'
import { teams } from '@common/accessController/constants'
import { getTeamGroups } from '@domain/generic/selectors/getters'
import { sortTeamListByName } from '@common/teamFilter/teamFilterConditions'

// calculate default selected teams for team filter on different pages
export const calculateDefaultTeams = (teamsList: $TSFixMe, locationTeamId: number) => {
  const { imoTeams, centIntTeams, centralVCTeams, centralOrgDesignTeams, userTeams } = getTeamGroups(teamsList)
  const restrictedTeams = getRestrictedTeams(userTeams)

  const defaultTeam =
    teamsList.find((team: $TSFixMe) => !restrictedTeams.includes(team.id)) || centIntTeams?.[0] || teamsList?.[0]

  const sortedCentralVCTeams = sortTeamListByName(centralVCTeams)
  const sortedCentralOrgDesignTeams = sortTeamListByName(centralOrgDesignTeams)

  const team = getOr(null, 'id', defaultTeam)
  const firstImoTeam = getOr(null, 'id', first(imoTeams))
  const imoTeam = flow(find({ teamType: teams.IMO_GLOBAL }), getOr(firstImoTeam, 'id'))(userTeams)
  const firstCentralVCTeamId = getOr(null, 'id', first(sortedCentralVCTeams))
  const firstCentralOrgDesignTeamId = getOr(null, 'id', first(sortedCentralOrgDesignTeams))
  const centralTeam = flow(find({ id: locationTeamId }), getOr(firstCentralVCTeamId, 'id'))(centralVCTeams)
  const centralOrgDesignTeam = flow(
    find({ id: locationTeamId }),
    getOr(firstCentralOrgDesignTeamId, 'id'),
  )(centralOrgDesignTeams)

  return {
    team,
    imoTeam,
    centralTeam,
    centralOrgDesignTeam,
  }
}

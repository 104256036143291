import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class Day1IssueKPIsApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch, '')
    this.host = Config.API_SERVER
  }

  getIssueKPIsByTeam = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `/teams/${query.teamId}/day-1-issue-kpi/issues-by-team` })
  }

  getIssueKPIs = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `/teams/${query.teamId}/day-1-issue-kpi` })
  }
}

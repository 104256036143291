import { handleActions } from 'redux-actions'
import * as constants from '@taskWatchers/actions/actionTypes'
import { Watcher } from '../actions/actions'

type WatchersState = {
  [key: string]: Watcher[]
}

export const initialState = {}

const taskWatchersReducer = handleActions<WatchersState, $TSFixMe>(
  {
    [constants.SET_TASK_WATCHERS]: (state, action) => {
      const { taskId, watchers } = action.payload

      return { ...state, [taskId]: watchers }
    },

    [constants.ADD_TASK_WATCHER]: (state, action) => {
      const { taskId, watcher } = action.payload

      return {
        ...state,
        [taskId]: state[taskId] ? [...state[taskId], watcher] : [watcher],
      }
    },

    [constants.REMOVE_TASK_WATCHER]: (state, action) => {
      const { taskId, userId } = action.payload

      if (!state[taskId]) {
        return state
      }

      const updatedWatchers = state[taskId].filter((watcher) => watcher.userId !== userId)

      return { ...state, [taskId]: updatedWatchers }
    },
  },
  initialState,
)

export default taskWatchersReducer

import './wdyr'
import './stylesheets/styles.css'
import './stylesheets/fonts.css'
import './stylesheets/print.css'
import { actions as midActions } from '@nvt/mid-react'
import React from 'react'
import ReactDOM from 'react-dom'
import { store } from './domain/store'
import { LicenseManager } from 'ag-grid-enterprise'
import { AppProviders } from './providers/app-providers'
import { queryClient } from './views/hooks/useFeatureFlags'
import { AgCharts } from 'ag-charts-enterprise'

export { REQUIRED_FIELD_MESSAGE } from './helpers/constants'

// This looks very odd but is actually the valid license key for AG Grid
const agGridKey = `Using_this_AG_Grid_Enterprise_key_( AG-050666 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( T/A Marc Lang )_is_granted_a_( Single Application )_Developer_License_for_the_application_( myimo )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( myimo )_need_to_be_licensed___( myimo )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 20 January 2025 )____[v2]_MTczNzMzMTIwMDAwMA==5650bd09457e1e138cc0a9fdc9dfb8c2`
LicenseManager.setLicenseKey(agGridKey)

const agChartKey = `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-053731}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{T/A_Marc_Lang}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{myimo}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{myimo}_need_to_be_licensed___{myimo}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{20_January_2025}____[v3]_[0102]_MTczNzMzMTIwMDAwMA==e305fee78a2e96f047b2b0a75dd4df58`
AgCharts.setLicenseKey(agChartKey)

store.dispatch(midActions.login())

ReactDOM.render(<AppProviders store={store} queryClient={queryClient} />, document.getElementById('index'))

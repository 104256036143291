// Libs
import { call, put } from 'redux-saga/effects'
import { isEmpty } from 'lodash'

// Actions
import { setBatchFileUploadErrors, setBatchUploadResult, batchUploadFileSuccess } from '@batchUpload/actions/actions'

// Utils
import { UploadState } from '@batchUpload/constants'
import { getDayOneApi, getInitiativeListV2Api, getTSAv2Api } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { UPLOAD_TYPES } from '@shared/BatchUploadPopup/constants'

export const batchUploadFile = createSaga(function* batchUploadFile({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  try {
    const { teamId, file, fetchUpdatedList, selectedTeamId, isProjectPlan } = payload
    const formData = new FormData()
    formData.append('file', file)

    const api = payload.type === UPLOAD_TYPES.TSA ? yield getTSAv2Api() : yield getDayOneApi()

    const data = yield call(
      api.request(isProjectPlan ? 'batchFileUploadByTeam' : 'batchFileUpload', {
        query: {
          teamId,
        },
        body: formData,
      }),
    )

    yield put(setBatchFileUploadErrors({ ...data, isValidFile: true }))

    yield put(batchUploadFileSuccess({ teamId: selectedTeamId, hasErrors: !isEmpty(data.errors), fetchUpdatedList }))
  } catch (e) {
    yield put(setBatchFileUploadErrors({ errors: [], rows: {}, isValidFile: false }))
  }
})

export const batchUploadInitiatives = createSaga(function* batchUploadInitiatives({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  try {
    const { teamId, synergyType, file, fetchUpdatedList } = payload

    const formData = new FormData()
    formData.append('file', file)

    const api = yield getInitiativeListV2Api()

    const data = yield call(
      api.request('batchFileUpload', {
        query: { teamId, synergyType },
        body: formData,
      }),
    )

    yield put(setBatchFileUploadErrors({ ...data, isValidFile: true }))

    yield put(batchUploadFileSuccess({ teamId, hasErrors: !isEmpty(data.errors), fetchUpdatedList }))
  } catch (e) {
    yield put(setBatchFileUploadErrors({ errors: [], rows: {}, isValidFile: false }))
  }
})

export const batchFileUploadSuccess = createSaga(function* batchFileUploadSuccess(action: $TSFixMe) {
  const { teamId, hasErrors, fetchUpdatedList } = action.payload

  yield put(setBatchUploadResult(hasErrors ? UploadState.STATUS : UploadState.SUCCESS))

  yield put(fetchUpdatedList({ teamId }))
})

export const downloadBatchUploadTemplate = createSaga(function* downloadBatchUploadTemplate({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  try {
    const api = payload.type === UPLOAD_TYPES.TSA ? yield getTSAv2Api() : yield getDayOneApi()
    const response = yield call(api.request('getBatchUploadTemplate', payload))
    const blob = yield response.blob()
    const buffer = yield blob.arrayBuffer()
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([buffer]))
    link.download = payload.filename
    link.click()
  } catch (e) {
    global.console.error('Error from downloadBatchUploadTemplate', e)

    throw e
  }
})

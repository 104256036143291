import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'
import { fetchInitiativeActivityLogSaga } from './fetchInitiativeActivityLogSaga'

export default function* initiativeActivityLogSaga() {
  yield all([
    takeLatest(
      // we run the same saga for both actions, the first type will reset the state however to ensure fresh data
      [constants.FETCH_INITIATIVE_ACTIVITY_LOG, constants.FETCH_MORE_INITIATIVE_ACTIVITY_LOGS],
      fetchInitiativeActivityLogSaga,
    ),
  ])
}

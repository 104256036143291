import { orderBy } from 'lodash/fp'
import { greyLight2 } from '@imo/imo-ui-toolkit'
import { isNil } from 'lodash'
import { NPVField, NPVValidationError } from './ValueCaptureSettingsV2/NPV/NPVSettings'

type OptionType = {
  text: string
  value: string
}

export const orderByOptions = orderBy(
  [({ name }) => Boolean(name), ({ name }) => (name ? name.toLowerCase() : '')],
  ['desc', 'asc'],
)

export const getOptionLabel = ({ text }: OptionType) => text
export const getOptionValue = ({ value }: OptionType) => value

export const menuStyles = { maxWidth: 422, width: 422 }
export const iconProps = { color: greyLight2 }
export const tooltipStyles = { marginRight: '10px', lineHeight: 1 }

const getNumericArray = (amount: number) => Array.from(Array(amount).keys()).map((i) => i + 1)

const getOption = (val: string | number, text?: string | number) => ({
  text: text || val.toString(),
  value: val.toString(),
})

export const getNumericOptions = (amount: number) => getNumericArray(amount).map((n) => getOption(n))

export const getNpvValidationError = (field: NPVField, value: unknown): NPVValidationError | null => {
  if (isNil(value) || (typeof value === 'string' && value.trim().length === 0)) {
    return 'MISSING_VALUES'
  }
  if (
    Number.isNaN(Number(value)) ||
    (typeof value === 'string' && value.includes('-')) ||
    (typeof value === 'string' && value.split('.')[1]?.length > 2) ||
    (typeof value === 'string' && value.trim()[value.length - 1] === '.')
  ) {
    return 'INVALID_VALUES'
  }
  if (field === 'wacc' && Number(value) === 0) {
    return 'ZERO_WACC'
  }
  return null
}

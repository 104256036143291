import { sortDeliverables } from '@helpers/sortDeliverables'
import { omit } from 'lodash/fp'

export const buildIntegrationTeamsDataStructure = (teamsList: $TSFixMe) => {
  if (!teamsList || teamsList.length === 0) return []

  return teamsList.map((team: $TSFixMe) => {
    const teamData: $TSFixMe = {
      team: team.longName,
      id: team.id,
    }

    Object.keys(team.deliverableTypes).forEach((type) => {
      const deliverables = sortDeliverables({
        deliverables: team.deliverableTypes[type].teamDeliverables,
        startDateAccessor: 'deliverableStartDate',
        dueDateAccessor: 'deliverableDueDate',
        orderAccessor: 'order',
        orgLayerAccessor: 'orgDesignTeamLayer',
      })

      deliverables.forEach((deliverable: $TSFixMe, index: number) => {
        teamData[`${deliverable.shortName}-${team.deliverableTypes[type].id}-${index}`] = {
          ...deliverable,
          deliverableType: type,
        }
      })
    })

    return teamData
  })
}

export const buildCentralTeamsDataStructure = (teamsList: $TSFixMe) => {
  if (!teamsList || teamsList.length === 0) return []

  return teamsList.map((team: $TSFixMe) => {
    if (!team || !team.teamDeliverables) return {}

    return team.teamDeliverables.reduce(
      (acc: $TSFixMe, deliverable: $TSFixMe) => {
        acc[deliverable.shortName] = deliverable

        return acc
      },
      {
        team: team.longName,
        id: team.id,
      },
    )
  })
}

export const updateDeliverable = (item: $TSFixMe, payload: $TSFixMe) => {
  const deliverablesShape = omit(['id', 'team'], item)
  const deliverableKeyToUpdate: $TSFixMe = Object.keys(deliverablesShape).filter((key) => {
    return deliverablesShape[key].id === payload.deliverable.id
  })

  return {
    ...item,
    [deliverableKeyToUpdate]: payload.deliverable,
  }
}

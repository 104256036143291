import { call } from 'redux-saga/effects'
import { getCommunicationsApi } from '@common/net'
import { formatRequestBodyUpdateRequest } from '../helpers'
import { createSaga } from '@common/sagaCreator/createSaga'

export const updateRequest = createSaga(function* updateRequest({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getCommunicationsApi()
  yield call(
    api.request('updateRequest', {
      body: formatRequestBodyUpdateRequest(payload),
      query: { requestId: payload.id },
    }),
  )
})

import { combineReducers } from 'redux'
import { all } from 'redux-saga/effects'

import { initiativeListReducer } from './initiativeList/reducers/reducer'
import projectMapReducer from '@teamValueCaptureV2/projectMap/reducers/reducer'
import statusUpdateReducer from '@teamValueCaptureV2/statusUpdate/reducers/reducer'
import teamValueCaptureStatusUpdateV3Reducer from '@teamValueCaptureV2/teamValueCaptureStatusUpdateV3/reducers/reducer'
import monthByMonthReducer from '@teamValueCaptureV2/monthByMonth/reducers/reducer'
import advancedFinancialsReducer from '@teamValueCaptureV2/advancedFinancials/reducers/reducer'
import userInitiativeFilterReducer from '@teamValueCaptureV2/userInitiativeFilter/reducers/reducer'

import initiativeSaga from './initiativeList/sagas'
import advancedFinancialsSaga from './advancedFinancials/sagas'
import projectMapSaga from '@teamValueCaptureV2/projectMap/sagas'
import monthByMonthSaga from '@teamValueCaptureV2/monthByMonth/sagas'
import statusUpdateSaga from '@teamValueCaptureV2/statusUpdate/sagas'
import teamValueCaptureStatusUpdateV3Saga from '@teamValueCaptureV2/teamValueCaptureStatusUpdateV3/sagas'
import userInitiativeFilterSaga from '@teamValueCaptureV2/userInitiativeFilter/sagas'

export const teamValueCaptureV2Reducer = combineReducers({
  initiativeList: initiativeListReducer,
  projectMap: projectMapReducer,
  statusUpdate: statusUpdateReducer,
  teamValueCaptureStatusUpdateV3: teamValueCaptureStatusUpdateV3Reducer,
  monthByMonth: monthByMonthReducer,
  advancedFinancials: advancedFinancialsReducer,
  userInitiativeFilter: userInitiativeFilterReducer,
})

export function* teamValueCaptureV2Sagas() {
  yield all([
    initiativeSaga(),
    projectMapSaga(),
    statusUpdateSaga(),
    teamValueCaptureStatusUpdateV3Saga(),
    monthByMonthSaga(),
    advancedFinancialsSaga(),
    userInitiativeFilterSaga(),
  ])
}

import { getFeatureFlags } from '@views/hooks/useFeatureFlags'
import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class ProgramProjectPlanApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  getProgramProjectPlanData = ({ query }: $TSFixMe) => {
    const { teamId } = query
    const { ALL_PROJECTS_SERVER_SIDE } = getFeatureFlags()

    if (ALL_PROJECTS_SERVER_SIDE?.enabled) {
      return this.call({
        pathname: `teams/${teamId}/team-key-process-v2/imo-all-projects`,
      })
    }

    return this.call({
      pathname: `teams/${teamId}/team-key-process-v2/imo-program-project-plan-v2`,
      query: { newQuery: true },
    })
  }
}

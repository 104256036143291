import React from 'react'
import { useSelector } from 'react-redux'
import { getSelectedCentralTeamId } from '@generic/selectors/selectedTeams'
import NoResults from '@shared/NoResults'
import { Icon } from '@blueprintjs/core'
import { Navigate } from 'react-router-dom'

const VCTrackerRootV2 = () => {
  const centralTeam = useSelector(getSelectedCentralTeamId)

  if (!centralTeam) {
    const content = (
      <>
        <Icon icon={'warning-sign'} size={23} />
        <br />
        The Value Capture Tracker is currently unavailable.
        <br />
        There are no central teams classified as Value Capture in the admin configuration.
      </>
    )

    return <NoResults content={content} />
  }

  return <Navigate to={{ pathname: `/value-capture-tracker/team/${centralTeam}/summary-status` }} />
}

export default VCTrackerRootV2

//@ts-nocheck
// TODO: remove and fix TS dependencies error
import { LAUNCHPAD_ADMIN_PERMISSIONS } from './launchpadAdmin'
import { INTEGRATION_RESTRICTED_PERMISSIONS } from './integrationRestricted'
import { INTEGRATION_MEMBER_WITH_VC_PERMISSIONS } from './integrationMemberWithVC'
import { INTEGRATION_MEMBER_WITHOUT_VC_PERMISSIONS } from './integrationMemberWithoutVC'
import { INTEGRATION_LEAD_PERMISSIONS } from './integrationLead'
import { INTEGRATION_MEMBER_WITH_VC_AND_ORG_DESIGN_PERMISSIONS } from './integrationMemberWithVCAndOrgDesign'
import { CENTRAL_RESTRICTED_PERMISSIONS } from './centralRestricted'
import { CENTRAL_MEMBER_PERMISSIONS } from './centralMember'
import { CENTRAL_LEAD_PERMISSIONS } from './centralLead'
import { CENTRAL_VC_LEAD_PERMISSIONS } from './centralVCLead'
import { CENTRAL_VC_MEMBER_PERMISSIONS } from './centralVCMember'
import { CENTRAL_ORG_DESIGN_LEAD_PERMISSIONS } from './centralOrgDesignLead'
import { CENTRAL_ORG_DESIGN_MEMBER_PERMISSIONS } from './centralOrgDesignMember'
import { IMO_MEMBER_PERMISSIONS } from './imoMember'
import { IMO_MEMBER_WITH_VC_AND_ORG_DESIGN_PERMISSIONS } from './imoMemberWithVCAndOrgDesign'
import { IMO_GLOBAL_MEMBER_PERMISSIONS } from './imoGlobalMember'
import { IMO_GLOBAL_MEMBER_WITH_VC_AND_ORG_DESIGN_PERMISSIONS } from './imoGlobalMemberWithVCAndOrgDesign'
import { INSTANCE_ADMIN_PERMISSIONS } from './instanceAdmin'
import { CONFIG_ADMIN_PERMISSIONS } from './admins/configAdmin'
import { USER_ADMIN_PERMISSIONS } from './admins/userAdmin'
import { IMO_GLOBAL_MEMBER_WITHOUT_VC_PERMISSIONS } from './imoGlobalMemberWithoutVC'
import { IMO_GLOBAL_MEMBER_WITHOUT_VC_READONLY_PERMISSIONS } from './imoGlobalMemberWithoutVCReadonly'
import { IMO_MEMBER_WITHOUT_VC_PERMISSIONS } from './imoMemberWithoutVC'
import { IMO_MEMBER_WITHOUT_VC_READ_ONLY_PERMISSIONS } from '@common/accessController/permissions/imoMemberWithoutVCReadOnly'
import { CENTRAL_MEMBER_WITHOUT_VC_READ_ONLY_PERMISSIONS } from '@common/accessController/permissions/centralMemberWithoutVCReadOnly'
import { INTEGRATION_MEMBER_WITHOUT_VC_READ_ONLY_PERMISSIONS } from '@common/accessController/permissions/integrationMemberWithoutVCReadOnly'
import { INTEGRATION_RESTRICTED_WITH_VC_PERMISSIONS } from '@common/accessController/permissions/integrationRestrictedWithVC'

export const permissionsMap = (dependencies: $TSFixMe) => [
  INSTANCE_ADMIN_PERMISSIONS,
  IMO_GLOBAL_MEMBER_PERMISSIONS,
  IMO_GLOBAL_MEMBER_WITHOUT_VC_PERMISSIONS,
  IMO_GLOBAL_MEMBER_WITHOUT_VC_READONLY_PERMISSIONS,
  IMO_GLOBAL_MEMBER_WITH_VC_AND_ORG_DESIGN_PERMISSIONS,
  IMO_MEMBER_PERMISSIONS,
  IMO_MEMBER_WITHOUT_VC_PERMISSIONS,
  IMO_MEMBER_WITHOUT_VC_READ_ONLY_PERMISSIONS,
  IMO_MEMBER_WITH_VC_AND_ORG_DESIGN_PERMISSIONS,
  CENTRAL_VC_LEAD_PERMISSIONS,
  CENTRAL_VC_MEMBER_PERMISSIONS,
  CENTRAL_ORG_DESIGN_LEAD_PERMISSIONS,
  CENTRAL_ORG_DESIGN_MEMBER_PERMISSIONS,
  CENTRAL_LEAD_PERMISSIONS,
  CENTRAL_MEMBER_PERMISSIONS,
  INTEGRATION_LEAD_PERMISSIONS,
  INTEGRATION_MEMBER_WITH_VC_PERMISSIONS,
  INTEGRATION_MEMBER_WITHOUT_VC_PERMISSIONS,
  INTEGRATION_MEMBER_WITH_VC_AND_ORG_DESIGN_PERMISSIONS,
  CENTRAL_MEMBER_WITHOUT_VC_READ_ONLY_PERMISSIONS,
  INTEGRATION_MEMBER_WITHOUT_VC_READ_ONLY_PERMISSIONS,
  CENTRAL_RESTRICTED_PERMISSIONS(dependencies),
  INTEGRATION_RESTRICTED_WITH_VC_PERMISSIONS,
  INTEGRATION_RESTRICTED_PERMISSIONS,
  CONFIG_ADMIN_PERMISSIONS,
  USER_ADMIN_PERMISSIONS,
  LAUNCHPAD_ADMIN_PERMISSIONS,
]

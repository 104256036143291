import { createAction } from 'redux-actions'
import * as constants from './actionTypes'

export const getSteps = createAction(constants.GET_STEPS)
export const setStepCompleted = createAction(constants.SET_STEP_COMPLETED)

export const resetAllSteps = createAction(constants.RESET_ALL_STEPS)
export const resetAllStepsSuccess = createAction(constants.RESET_ALL_STEPS_SUCCESS)

export const getWelcomeNote = createAction(constants.GET_WELCOME_NOTE)
export const setWelcomeNoteData = createAction(constants.SET_WELCOME_NOTE_DATA)
export const saveWelcomeNote = createAction(constants.SAVE_WELCOME_NOTE)

export const setCoreComponentStatus = createAction(constants.SET_CORE_COMPONENT_STATUS)
export const setCoreComponentStatuses = createAction(constants.SET_CORE_COMPONENT_STATUSES)
export const setModuleSelectValue = createAction(constants.SET_MODULE_SELECT_VALUE)
export const updateCustomColumnName = createAction(constants.UPDATE_CUSTOM_COLUMN_NAME)

export const addDictionaryWording = createAction(constants.ADD_DICTIONARY_WORDING)
export const updateDictionaryWording = createAction(constants.UPDATE_DICTIONARY_WORDING)
export const deleteDictionaryWording = createAction(constants.DELETE_DICTIONARY_WORDING)

export const addDictionaryCustomColumn = createAction(constants.ADD_DICTIONARY_CUSTOM_COLUMN)
export const updateDictionaryCustomColumn = createAction(constants.UPDATE_DICTIONARY_CUSTOM_COLUMN)
export const deleteDictionaryCustomColumn = createAction(constants.DELETE_DICTIONARY_CUSTOM_COLUMN)

export const fetchCompletenessStatuses = createAction(constants.FETCH_COMPLETENESS_STATUSES)
export const fetchCompletenessStatusesSuccess = createAction(constants.FETCH_COMPLETENESS_STATUSES_SUCCESS)

export const fetchUserAndLaunch = createAction(constants.FETCH_USER_AND_LAUNCH)
export const launchConfig = createAction(constants.LAUNCH_CONFIG)
export const launchConfigSuccess = createAction(constants.LAUNCH_CONFIG_SUCCESS)

export const setConfigWarningWasShowed = createAction(constants.SET_CONFIG_WARNING_WAS_SHOWED)

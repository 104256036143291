import * as Yup from 'yup'

export const teamsHierarchySchema = Yup.array()
  .of(
    Yup.object().shape({
      teamId: Yup.number().nullable().required('Team ID is required'),
    }),
  )
  .required()
  .min(1)

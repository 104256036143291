// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js!slick-carousel/slick/slick.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../node_modules/css-loader/dist/cjs.js!slick-carousel/slick/slick-theme.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/MckinseySans/McKinseySans-Italic.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/MckinseySans/McKinseySans-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/MckinseySans/McKinseySans-LightItalic.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/MckinseySans/McKinseySans-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./fonts/MckinseySans/McKinseySans-MediumItalic.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./fonts/MckinseySans/McKinseySans-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./fonts/Bower/Bower-Bold.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n\n@font-face {\n  font-family: 'MckinseySans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n  font-style: italic;\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: 'MckinseySans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\n  font-style: normal;\n  font-weight: 300;\n}\n\n@font-face {\n  font-family: 'MckinseySans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2');\n  font-style: italic;\n  font-weight: 300;\n}\n\n@font-face {\n  font-family: 'MckinseySans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff2');\n  font-style: normal;\n  font-weight: 500;\n}\n\n@font-face {\n  font-family: 'MckinseySans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff2');\n  font-style: italic;\n  font-weight: 500;\n}\n\n@font-face {\n  font-family: 'MckinseySans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('woff2');\n  font-style: normal;\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: 'BowerBold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff2');\n}\n", ""]);
// Exports
module.exports = exports;

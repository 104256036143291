import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import createSagaMiddleware from 'redux-saga'
import createReducer from '@domain/rootReducer'
import createRootSaga from '@domain/rootSaga'
import { serverNotResponding } from '@generic/actions/actions'

const composeEnhancers = composeWithDevTools({
  actionsDenylist: ['@generic/SHOW_WARNING_MESSAGE', '@generic/SET_LOADER'],
})

type StoreOptions = {
  initialState?: $TSFixMe
  isTest?: boolean
}

export const createImoStore = ({ initialState = {} }: StoreOptions) => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error: $TSFixMe) => {
      store.dispatch(serverNotResponding(error))
    },
  })

  const store = createStore(createReducer(), initialState, composeEnhancers(applyMiddleware(sagaMiddleware)))

  sagaMiddleware.run(createRootSaga())

  return store
}

/**
 * @deprecated
 *
 * Do not access store directly, use react-redux hooks (https://react-redux.js.org/api/hooks)
 */
export const store = createImoStore({})

// Infer the `RootState` from the store itself
export type RootState = ReturnType<typeof store.getState>

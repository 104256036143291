import { find as findFp, flow, get as getFp, getOr, identity, isEmpty, sortBy, filter } from 'lodash/fp'
import { find, get, isNil } from 'lodash'
import { createSelector } from 'reselect'
import { stepStatus } from '@helpers/statuses'
import { formatUsersList } from '@domain/myImoConfig/selectors/utils'
import { userInfo as getUser } from '@generic/selectors'
import { getSelectedTargetId, getTargetList } from '@valueCaptureTrackerV2/teamSetup/selectors'
import { ORG_DESIGN_DELIVERABLE_TYPE_ID } from '@helpers/constants'

const localState = getFp('config')

export const getTaxonomy = (state) => get(state, 'config.projectTaxonomy')
export const getTeamSetup = (state) => get(state, 'config.teamSetup')
export const getDeliverableTypes = (state) => get(state, 'config.deliverables.deliverableTypes')
export const getNumberOfOrgDesignLayers = (state) => get(state, 'config.deliverables.numberOfOrgDesignLayers')

export const getTeamHierarchy = (state) => get(state, 'config.teamImoHierarchy')

export const getWelcomeNote = (state) => get(state, 'config.config.welcomeNoteData')
export const getProgramSettings = (state) => get(state, 'config.programSetup.programSetup')
export const getConfigSteps = (state) => get(state, 'config.config.steps')
export const configStepsState = (state) => get(state, 'config.config.stepsState')
export const getConfigStepsState = createSelector(configStepsState, identity)

export const getStepStatus = (state, step) => flow(findFp({ no: step }), getFp('status'))(getConfigSteps(state))

export const getIsCompletedStep = (state, step) => {
  const status = getStepStatus(state, step)

  return [stepStatus.COMPLETED, stepStatus.LAUNCHED, stepStatus.RESUBMITTED].includes(status)
}

export const getDeliverableById = (state, { id, index, deliverableTypeId, deliverableTypeName }) => {
  if (!id && isNil(index)) return null

  const typeDeliverables = flow(
    findFp(deliverableTypeId ? { id: deliverableTypeId } : { typeName: deliverableTypeName }),
    getFp('deliverables'),
  )(getDeliverableTypes(state))

  return id ? find(typeDeliverables, { id }) : typeDeliverables[index]
}

export const getOrgDesignLayerDeliverables = createSelector(getDeliverableTypes, (deliverableTypes) =>
  flow(
    findFp({ id: ORG_DESIGN_DELIVERABLE_TYPE_ID }),
    getFp('deliverables'),
    filter(({ orgDesignLayer }) => orgDesignLayer != null),
    sortBy('order'),
  )(deliverableTypes),
)

export const getStepsIsLaunched = createSelector(
  getConfigSteps,
  (steps) => steps && steps.every((step) => step.status === stepStatus.LAUNCHED),
)

export const getRestrictedDomains = createSelector(localState, getFp(['users', 'restrictedDomains']))
export const getUsersList = createSelector(localState, getFp(['users', 'list']))

export const getGroupedUsers = createSelector(getUsersList, getRestrictedDomains, formatUsersList)

export const getIsUserManagementHasChanges = (state) => get(state, 'config.config.isUserManagementHasChanges')

export const getIsCurrentStepResubmitted = (state, step) => {
  return getStepStatus(state, step) === stepStatus.RESUBMITTED
}

export const isAnyConfigStepResubmitted = createSelector(getConfigSteps, (configSteps = []) => {
  return configSteps.some((step) => [stepStatus.RESUBMITTED].includes(get(step, 'status')))
})

export const getConfigWarningWasShowed = createSelector(localState, (config = []) =>
  get(config, ['config', 'configWarningWasShowed']),
)

export const getIMOTeams = createSelector(getTeamHierarchy, getFp('imoTeams'))
export const getCentralTeams = createSelector(getTeamHierarchy, getFp('centralTeams'))

export const getIntegrationTeams = createSelector(getTeamHierarchy, getFp('integrationTeams'))

export const getModifiedIMOTeams = createSelector(getTeamHierarchy, (teamHierarchy) => {
  const { centralTeams, integrationTeams, imoTeams } = teamHierarchy
  const allTeams = [...centralTeams, ...integrationTeams, ...imoTeams]

  return imoTeams.map((team) => {
    const isDisabled = allTeams.some(
      (item) => item.active && get(item, 'hierarchy.responsibleImo.longName') === team.longName,
    )

    if (isDisabled) return { ...team, isDisabled }

    return team
  })
})

export const getWhoLockedData = createSelector(localState, getFp(['users', 'whoLockedData']))

export const getIsDataLockedForCurrentUser = createSelector(
  getWhoLockedData,
  getUser,
  (lockedUserEmail, currentUser) => !isNil(lockedUserEmail) && lockedUserEmail !== currentUser.email,
)

export const getIsViewLocked = createSelector(localState, getFp(['users', 'isViewLocked']))
export const getIsReadOnly = createSelector(localState, getFp(['users', 'isReadOnly']))
export const getIsDataLocked = createSelector(getWhoLockedData, (lockedUser) => !isEmpty(lockedUser))

export const getSelectedTarget = createSelector(getTargetList, getSelectedTargetId, (items, targetId) =>
  targetId ? find({ id: targetId }, items) : null,
)

export const getIsCurrentUserLockedData = createSelector(
  getWhoLockedData,
  getUser,
  (lockedUserEmail, currentUser) => lockedUserEmail === currentUser.email,
)

export const getDeliverableIntegrationTeams = flow(localState, getFp(['teamDeliverables', 'integrationTeams']))
export const getDeliverableDeliverablesCentralTeams = flow(localState, getFp(['teamDeliverables', 'centralTeams']))
export const getTeamDeliverablesSelector = createSelector(
  getDeliverableIntegrationTeams,
  getDeliverableDeliverablesCentralTeams,
  (integrationTeams, centralTeams) => ({
    integrationTeams,
    centralTeams,
  }),
)

export const getBuiltDeliverableIntegrationTeams = flow(
  localState,
  getOr([], ['teamDeliverables', 'builtIntegrationTeams']),
)

export const getBuiltDeliverableCentralTeams = flow(localState, getOr([], ['teamDeliverables', 'builtCentralTeams']))

export const getCentralDeliverablesTemplate = flow(
  localState,
  getFp(['teamDeliverables', 'centralDeliverablesTemplate']),
)
export const getIntegrationDeliverablesTemplate = flow(
  localState,
  getFp(['teamDeliverables', 'integrationDeliverablesTemplate']),
)

export const getUsersLoading = flow(localState, getFp(['users', 'usersLoading']))

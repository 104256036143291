import { handleActions } from 'redux-actions'
import * as constants from '@teamResources/teamContacts/actions/actionTypes'

export interface ITeamContactsState {
  teamContacts: $TSFixMe[]
}

export const initialState = {
  teamContacts: [],
}

const teamContactsReducer = handleActions<ITeamContactsState, $TSFixMe>(
  {
    [constants.SET_TEAM_CONTACTS]: (state, action) => {
      return {
        ...state,
        teamContacts: action.payload,
      }
    },

    [constants.CREATE_TEAM_CONTACT_SUCCESS]: (state, action) => {
      const { data } = action.payload
      const { teamContacts } = state

      return {
        ...state,
        teamContacts: [...teamContacts, data],
      }
    },

    [constants.UPDATE_TEAM_CONTACT_SUCCESS]: (state, action) => {
      const { data } = action.payload

      return {
        ...state,
        teamContacts: data,
      }
    },

    [constants.DELETE_TEAM_CONTACT_SUCCESS]: (state, action) => {
      const { id } = action.payload
      const { teamContacts } = state

      const filteredTeamContacts = teamContacts.filter((contact) => contact.id !== id)

      return {
        ...state,
        teamContacts: filteredTeamContacts,
      }
    },
  },
  initialState,
)

export default teamContactsReducer

import { call, put } from 'redux-saga/effects'
import { setDictionaries } from '../actions/actions'
import { getGenericApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchDictionaries = createSaga(function* fetchDictionaries(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const genericApi = yield getGenericApi()
  const dictionaries = yield call(genericApi.request('getDictionaries'))

  yield put(setDictionaries({ dictionaries }))
})

import { put, select } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getAuthBaseDomain, getAuthDriver, getMidCore } from '@generic/selectors'
import { setLock } from '@myImoConfigActions/users/actions'
import { sendLogoutLog } from '@shared/ErrorBoundary/api'
import { AuthDrivers } from '@common/constants'
import { closeOktaSession } from '@generic/api/okta'

export type LogoutDiagnostics = {
  reason: 'JWT' | 'FORBIDDEN'
  [key: string]: unknown
}
const isTest = process.env.NODE_ENV === 'test'

export const logout = createSaga(function* logout({
  payload: { isUserLogout, context },
}: {
  type: string
  payload: {
    isUserLogout: boolean
    context?: LogoutDiagnostics
  }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  if (!isUserLogout && context && !isTest) {
    sendLogoutLog(context)
  }

  const mid = yield select(getMidCore)
  yield put(setLock({ lock: false }))

  const authDriver = yield select(getAuthDriver)

  if (authDriver === AuthDrivers.OKTA) {
    const oktaBaseDomain: string = yield select(getAuthBaseDomain)
    yield closeOktaSession(oktaBaseDomain)
  }

  mid.logout()
}, true)

const prefix = '@vcTeamSetup/'

export const FETCH_VC_TEAMS = `${prefix}FETCH_VC_TEAMS`
export const SET_VC_TEAMS = `${prefix}SET_VC_TEAMS`

export const FETCH_TARGET_LIST = `${prefix}FETCH_TARGET_LIST`
export const SET_TARGET_LIST = `${prefix}SET_TARGET_LIST`

export const CREATE_TARGET = `${prefix}CREATE_TARGET`
export const CREATE_TARGET_SUCCESS = `${prefix}CREATE_TARGET_SUCCESS`

export const UPDATE_TARGET = `${prefix}UPDATE_TARGET`
export const UPDATE_TARGET_SUCCESS = `${prefix}UPDATE_TARGET_SUCCESS`

export const DELETE_TARGET = `${prefix}DELETE_TARGET`
export const DELETE_TARGET_SUCCESS = `${prefix}DELETE_TARGET_SUCCESS`
export const SET_SELECTED_TARGET = `${prefix}SET_SELECTED_TARGET`

export const FETCH_VC_BASELINE_ASSUMPTIONS = `${prefix}FETCH_VC_BASELINE_ASSUMPTIONS`
export const SET_VC_BASELINE_ASSUMPTIONS = `${prefix}SET_VC_BASELINE_ASSUMPTIONS`

export const UPDATE_VC_BASELINE_ASSUMPTIONS = `${prefix}UPDATE_VC_BASELINE_ASSUMPTIONS`
export const UPDATE_VC_BASELINE_ASSUMPTIONS_SUCCESS = `${prefix}UPDATE_VC_BASELINE_ASSUMPTIONS_SUCCESS`

export const FETCH_VC_SYNERGY_CATEGORY = `${prefix}FETCH_VC_SYNERGY_CATEGORY`
export const SET_VC_SYNERGY_CATEGORY = `${prefix}SET_VC_SYNERGY_CATEGORY`

export const CREATE_VC_SYNERGY_CATEGORY = `${prefix}CREATE_VC_SYNERGY_CATEGORY`
export const CREATE_VC_SYNERGY_CATEGORY_SUCCESS = `${prefix}CREATE_VC_SYNERGY_CATEGORY_SUCCESS`

export const UPDATE_VC_SYNERGY_CATEGORY = `${prefix}UPDATE_VC_SYNERGY_CATEGORY`
export const UPDATE_VC_SYNERGY_CATEGORY_SUCCESS = `${prefix}UPDATE_VC_SYNERGY_CATEGORY_SUCCESS`

export const DELETE_VC_SYNERGY_CATEGORY = `${prefix}DELETE_VC_SYNERGY_CATEGORY`
export const DELETE_VC_SYNERGY_CATEGORY_SUCCESS = `${prefix}DELETE_VC_SYNERGY_CATEGORY_SUCCESS`

export const SET_VC_ONE_TIME_COSTS = `${prefix}SET_VC_ONE_TIME_COSTS`
export const CREATE_VC_ONE_TIME_COSTS_SUCCESS = `${prefix}CREATE_VC_ONE_TIME_COSTS_SUCCESS`

export const UPDATE_VC_ONE_TIME_COSTS_SUCCESS = `${prefix}UPDATE_VC_ONE_TIME_COSTS_SUCCESS`

export const DELETE_VC_ONE_TIME_COSTS_SUCCESS = `${prefix}DELETE_VC_ONE_TIME_COSTS_SUCCESS`

import styled from 'styled-components'
import { pdfDocumentBackgroundSecondary } from '@imo/imo-ui-toolkit'
import { StyledNavigation } from '@shared/TabLink/TabLink.styles'

const getStylesForPdf = ({ initialStyle, pdfStyle, isPdfEnabled }: $TSFixMe) => (isPdfEnabled ? pdfStyle : initialStyle)

export const MainContent = styled.div<$TSFixMe>`
  position: relative;
  left: 0;
  width: 100%;
  background: ${({ isPdfEnabled }) =>
    getStylesForPdf({ isPdfEnabled, pdfStyle: pdfDocumentBackgroundSecondary, initialStyle: 'transparent' })};
  overflow-y: auto;
  // TODO: replace 'display: flex' to 'display: grid'
  display: flex;
  flex-direction: column;

  ${StyledNavigation} {
    flex-shrink: 0;
  }
`

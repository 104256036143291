import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class MutualDiscoveryApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch, '')

    this.host = Config.API_SERVER
  }

  getMutualDiscoveryData = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${query.teamId}/team-key-process-v2?source=MDPA`,
    })
  }

  createSubProcess = ({ query, body }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/team-key-process-v2/${query.keyProcessId}/sub-key-processes` },
      {
        method: 'POST',
        body,
      },
    )
  }

  createKeyProcess = ({ query, body }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/team-key-process-v2` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateKeyProcess = ({ query, body }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/team-key-process-v2/${query.id}?source=MDPA` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  updateSubProcess = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/team-key-process-v2/${query.keyProcessId}/sub-key-processes/${query.id}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteKeyProcess = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/team-key-process-v2/${query.id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  deleteSubProcess = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/team-key-process-v2/${query.keyProcessId}/sub-key-processes/${query.id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  getLibraryProcessesList = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `/teams/${query.teamId}/team-key-process-v2/library/${query.libraryId}`,
    })
  }

  createProcessFromLib = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/team-key-process-v2/library/${query.libraryId}`,
      },
      {
        method: 'PUT',
        body,
      },
    )
  }
}

import { uniqBy } from 'lodash'
import { handleActions } from 'redux-actions'
import * as constants from '@teamDeliverables/actions/actionTypes'

export interface ITeamDeliverableDataState {
  status: 'init' | 'fetching' | 'ready'
  deliverable: $TSFixMe
  deliverables: $TSFixMe[]
  deliverablesTracker: $TSFixMe[]
  isDeliverableLocked: boolean
  comments: {
    commentsCount: number
    comments: $TSFixMe[]
  }
  keyProcessesToAcknowledge?: $TSFixMe[]
}

const initialState: ITeamDeliverableDataState = {
  status: 'init',
  deliverable: {},
  deliverables: [],
  deliverablesTracker: [],
  isDeliverableLocked: true,
  comments: {
    commentsCount: 0,
    comments: [],
  },
  keyProcessesToAcknowledge: [],
}

const teamDeliverablesReducer = handleActions<ITeamDeliverableDataState, $TSFixMe>(
  {
    [constants.SET_TEAM_DELIVERABLE_DATA]: (state, action) => ({
      ...state,
      deliverable: action.payload,
      isDeliverableLocked: false,
    }),

    [constants.SET_IS_DELIVERABLE_LOCKED]: (state, action) => {
      const { isDeliverableLocked } = action.payload

      return {
        ...state,
        isDeliverableLocked,
      }
    },

    [constants.SET_DELIVERABLE_STATUS_SUCCESS]: (state, action) => ({
      ...state,
      deliverable: { ...state.deliverable, ...action.payload },
    }),

    [constants.UPDATE_DELIVERABLE_META_SUCCESS]: (state, action) => {
      const { meta } = action.payload

      return {
        ...state,
        deliverable: { ...state.deliverable, meta },
      }
    },

    [constants.FETCH_TEAM_DELIVERABLES_DATA]: (state) => {
      return {
        ...state,
        status: 'fetching',
      }
    },

    [constants.SET_TEAM_DELIVERABLES_DATA]: (state, action) => {
      return {
        ...state,
        deliverables: action.payload.deliverables,
        status: 'ready',
      }
    },

    [constants.SET_DELIVERABLES_TRACKER_DATA]: (state, { payload }) => ({
      ...state,
      deliverablesTracker: payload,
    }),

    [constants.SET_COMMENTS]: (state, action) => {
      const { commentsCount, comments } = action.payload

      return {
        ...state,
        comments:
          commentsCount === 0
            ? { ...state.comments, comments: [] }
            : { commentsCount, comments: uniqBy([...state.comments.comments, ...comments], 'id') },
      }
    },

    [constants.RESET_COMMENTS]: (state) => {
      return {
        ...state,
        comments: { commentsCount: 0, comments: [] },
      }
    },

    [constants.CREATE_COMMENT_SUCCESS]: (state, action) => ({
      ...state,
      comments: {
        commentsCount: state.comments.commentsCount + 1,
        comments: uniqBy([action.payload, ...state.comments.comments], 'id'),
      },
    }),

    [constants.SET_DELIVERABLE_ACKNOWLEDGE_DATA]: (state, action) => {
      return {
        ...state,
        keyProcessesToAcknowledge: action.payload.keyProcesses,
      }
    },
  },
  initialState,
)

export default teamDeliverablesReducer

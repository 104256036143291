import * as programSetupConstants from '@myImoConfigActions/programSetup/actionTypes'
import * as configConstants from '@myImoConfigActions/config/actionTypes'
import * as genericConstants from '@generic/actions/actionTypes'
import { put, takeLatest, all, call, select, fork } from 'redux-saga/effects'
import { getConfigApi, getGenericApi } from '@common/net'
import { getCompanyLogos, getCompanyLogosPath } from '@helpers/utils'
import * as selectors from '../../selectors'
import { createSaga } from '@common/sagaCreator/createSaga'
import { fetchLogoLinks } from '@generic/sagas/fetchConfig'
import { fetchConfig } from '@generic/actions/actions'
import { getCompanyLogos as getCompanyLogosSelector } from '@generic/selectors'
import { isNil, get } from 'lodash/fp'
import { deleteFile } from '@generic/sagas/filesManagement'
import { NavigateFunction } from 'react-router-dom'

export const uploadLogoProgramSetup = createSaga(function* uploadLogoProgramSetup({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { fileKey, file, fieldName, directory } = payload

  const api = yield getGenericApi()
  const data = yield call(api.request('createFile', { body: file, query: { key: fileKey, isFileUnique: false } }))

  yield fork(fetchLogoLinks, {
    payload: {
      [fieldName]: data.filePath,
      directory,
    },
  })
},
true)

export const deleteLogo = createSaga(function* deleteLogo({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { fileKey, fieldName, directory } = payload

  if (fieldName === 'companyALogo') {
    yield put({
      type: genericConstants.SET_COMPANY_A_LOGO,
      payload: {
        companyALogo: null,
        companyALogoToDeleting: { fileKey, directory },
      },
    })
  }

  if (fieldName === 'companyBLogo') {
    yield put({
      type: genericConstants.SET_COMPANY_B_LOGO,
      payload: {
        companyBLogo: null,
        companyBLogoToDeleting: { fileKey, directory },
      },
    })
  }
})

export const fetchProgramSetup = createSaga(function* fetchProgramSetup(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const configApi = yield getConfigApi()
  const config = yield call(configApi.request('getProgramSetup'))

  yield put({ type: programSetupConstants.FETCH_PROGRAM_SETUP_SUCCESS, payload: { config } })

  const { companyALogo, companyBLogo } = getCompanyLogos(config)
  const { companyALogoPath, companyBLogoPath } = getCompanyLogosPath(companyALogo, companyBLogo)

  yield fork(fetchLogoLinks, {
    payload: {
      companyALogo: companyALogoPath,
      companyBLogo: companyBLogoPath,
    },
  })
}, true)

export const createProgramSetup = createSaga(function* createProgramSetup({
  payload: { navigate },
}: {
  payload: { navigate: NavigateFunction }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const configApi = yield getConfigApi()
  const data = yield select(selectors.getProgramSettings)
  const logos = yield select(getCompanyLogosSelector)
  const transformedData = Object.entries(data).map(([key, value]) => {
    let transformedValue = value
    const companyALogo = isNil(get('companyALogo', logos)) ? '' : get('companyALogo', logos)
    const companyBLogo = isNil(get('companyBLogo', logos)) ? '' : get('companyBLogo', logos)
    if (key === 'companyALogo') transformedValue = companyALogo

    if (key === 'companyBLogo') transformedValue = companyBLogo

    return { key, value: transformedValue, configType: 'PROJECT_SETUP' }
  })

  yield call(configApi.request('createProgramSetup', { body: transformedData }))

  yield put(fetchConfig())
  yield put({ type: configConstants.SET_STEP_COMPLETED, payload: { no: 1 } })

  navigate('/config/imo-deliverables-deadlines')

  const companyALogoToDeleting = get('companyALogoToDeleting', logos)
  const companyBLogoToDeleting = get('companyBLogoToDeleting', logos)
  const logosToDeleting = []

  if (companyALogoToDeleting) logosToDeleting.push(companyALogoToDeleting)

  if (companyBLogoToDeleting) logosToDeleting.push(companyBLogoToDeleting)

  const logoDeleteCalls = logosToDeleting.reduce((acc, { fileKey, directory }) => {
    acc.push(call(deleteFile, { payload: { fileKey, directory } }))

    return acc
  }, [])

  yield all(logoDeleteCalls)
},
true)

export function* programSetupWatcher() {
  yield all([
    takeLatest(programSetupConstants.DELETE_LOGO, deleteLogo),
    takeLatest(programSetupConstants.UPLOAD_LOGO_PROGRAM_SETUP, uploadLogoProgramSetup),
    takeLatest(programSetupConstants.FETCH_PROGRAM_SETUP, fetchProgramSetup),
    takeLatest(programSetupConstants.CREATE_PROGRAM_SETUP, createProgramSetup),
  ])
}

import { createAction } from 'redux-actions'
import * as constants from '@teamValueCaptureV2/advancedFinancials/actions/actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchAdvancedFinancials = createAction(constants.FETCH_ADVANCED_FINANCIALS)
// TODO: make sure that it's not needed
export const fetchAdvancedFinancialsWithLoader = createAction(
  constants.FETCH_ADVANCED_FINANCIALS,
  null,
  getMetaWithActionLoading,
)
export const setAdvancedFinancials = createAction(constants.SET_ADVANCED_FINANCIALS)
export const setIsAdvancedFinancialsOpened = createAction(constants.SET_IS_ADVANCED_FINANCIALS_OPENED)
export const closeAdvancedFinancials = createAction(constants.CLOSE_ADVANCED_FINANCIALS)

export const createDetailedFinancials = createAction(constants.CREATE_ADVANCED_FINANCIALS)
export const createAdvancedFinancialsSuccess = createAction(constants.CREATE_ADVANCED_FINANCIALS_SUCCESS)

export const deleteAdvancedFinancials = createAction(constants.DELETE_ADVANCED_FINANCIALS)
export const deleteAdvancedFinancialsSuccess = createAction(constants.DELETE_ADVANCED_FINANCIALS_SUCCESS)
export const updateAdvancedFinancialsNameOrCategory = createAction(
  constants.UPDATE_ADVANCED_FINANCIALS_NAME_OR_CATEGORY,
)
export const updateAdvancedFinancials = createAction(constants.UPDATE_ADVANCED_FINANCIALS)

export const updateFinancialData = createAction(constants.UPDATE_FINANCIAL_DATA)
export const updateRunRate = createAction(constants.UPDATE_RUN_RATE)

export const updateRunRateImpact = createAction(constants.UPDATE_RUN_RATE_IMPACT)

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class FunctionalActivityMappingApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  getKeyProcesses = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `teams/${query.teamId}/functional-activity-mapping` })
  }

  createKeyProcess = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/functional-activity-mapping`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateKeyProcess = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/functional-activity-mapping/${query.keyProcessId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteKeyProcess = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/functional-activity-mapping/${query.keyProcessId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }
}

const prefix = '@interdependencies/'

export const FETCH_DATA_PENDING = `${prefix}FETCH_DATA_PENDING`
export const FETCH_DATA_SUCCESS = `${prefix}FETCH_DATA_SUCCESS`
export const FETCH_DATA_FAIL = `${prefix}FETCH_DATA_FAIL`

export const FETCH_DATA_INTERDEPENDENCY_POPUP = `${prefix}FETCH_DATA_INTERDEPENDENCY_POPUP`
export const FETCH_INTERDEPENDENCY_SUMMARY = `${prefix}FETCH_INTERDEPENDENCY_SUMMARY`
export const SET_INTERDEPENDENCY_SUMMARY = `${prefix}SET_INTERDEPENDENCY_SUMMARY`
export const FETCH_INTERDEPENDENCY_SUMMARY_TOTALS = `${prefix}FETCH_INTERDEPENDENCY_SUMMARY_TOTALS`
export const SET_INTERDEPENDENCY_SUMMARY_TOTALS = `${prefix}SET_INTERDEPENDENCY_SUMMARY_TOTALS`
export const FETCH_INTERDEPENDENCY_FREQUENCY = `${prefix}FETCH_INTERDEPENDENCY_FREQUENCY`
export const SET_INTERDEPENDENCY_FREQUENCY = `${prefix}SET_INTERDEPENDENCY_FREQUENCY`
export const SET_DATA_INTERDEPENDENCY_POPUP_DATA = `${prefix}SET_DATA_INTERDEPENDENCY_POPUP_DATA`
export const UPDATE_INTERDEPENDENCY_POPUP_DATA = `${prefix}UPDATE_INTERDEPENDENCY_POPUP_DATA`
export const CLEAR_STATE_INTERDEPENDENCY_POPUP_DATA = `${prefix}CLEAR_STATE_INTERDEPENDENCY_POPUP_DATA`

import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'
import { assoc } from 'lodash/fp'

export interface ICXODashboardState {
  cxoDashboardData: $TSFixMe
}

export const initialState = {
  cxoDashboardData: {},
}

const cxoDashboardReducer = handleActions<ICXODashboardState, $TSFixMe>(
  {
    [constants.SET_CXO_DASHBOARD_DATA]: (state, action) => {
      return assoc('cxoDashboardData', action.payload, state)
    },
  },
  initialState,
)

export default cxoDashboardReducer

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading, getMetaWithIgnoreLoading } from '@common/sagaCreator/utils'

export const fetchMutualDiscoveryData = createAction(
  constants.FETCH_MUTUAL_DISCOVERY_DATA,
  null,
  getMetaWithActionLoading,
)

export const setMutualDiscoveryData = createAction(constants.SET_MUTUAL_DISCOVERY_DATA)
export const clearMutualDiscoveryData = createAction(constants.CLEAR_MUTUAL_DISCOVERY_DATA)
export const saveProcess = createAction(constants.SAVE_PROCESS)
export const addProcess = createAction(constants.ADD_PROCESS)
export const deleteProcess = createAction(constants.DELETE_PROCESS)
export const deleteProcessSuccess = createAction(constants.DELETE_PROCESS_SUCCESS)
export const deleteProcessItems = createAction(constants.DELETE_PROCESS_ITEMS)
export const deleteProcessItemsSuccess = createAction(constants.DELETE_PROCESS_ITEMS_SUCCESS)
export const updateProcess = createAction(constants.UPDATE_PROCESS)
export const updateProcessSuccess = createAction(constants.UPDATE_PROCESS_SUCCESS)

export const fetchProcessesFromLibrary = createAction(
  constants.FETCH_PROCESSES_FROM_LIBRARY,
  null,
  getMetaWithActionLoading,
)
export const setProcessesFromLibrary = createAction(constants.SET_PROCESSES_FROM_LIBRARY)
export const resetProcessesFromLibrary = createAction(constants.RESET_PROCESSES_FROM_LIBRARY)
export const updateProcessesFromLibrary = createAction(constants.UPDATE_PROCESSES_FROM_LIBRARY)

export const addProcessesFromLibrary = createAction(constants.ADD_PROCESSES_FROM_LIBRARY)

export const refetchMutualDiscoveryData = createAction(
  constants.FETCH_MUTUAL_DISCOVERY_DATA,
  null,
  getMetaWithIgnoreLoading,
)

export const setVisibleRowId = createAction(constants.SET_VISIBLE_ROW_ID)

import { all, put, call } from 'redux-saga/effects'
import { getTeamResourcesApi } from '@common/net'
import {
  appendInboxNotifications,
  appendArchiveNotifications,
  setNotifications,
  removeNotificationsFromInbox,
  removeNotificationsFromArchive,
  addNotificationToLoading,
  removeNotificationFromLoading,
} from '@teamHome/actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchNotifications = createSaga(function* fetchNotifications({
  payload: { teamId, filterBy },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamResourcesApi()

  const [inboxResponse, archivedResponse] = yield all([
    call(api.request('getNotifications', { query: { teamId, archived: false, filterBy } })),
    call(api.request('getNotifications', { query: { teamId, archived: true, filterBy } })),
  ])

  const { total: inboxTotal, notificationsEntities: inbox } = inboxResponse
  const { total: archivedTotal, notificationsEntities: archived } = archivedResponse

  yield put(setNotifications({ inbox, inboxTotal, archived, archivedTotal }))
})

export const fetchInbox = createSaga(function* fetchInbox({
  payload: { teamId, filterBy },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamResourcesApi()

  const inboxResponse = yield call(api.request('getNotifications', { query: { teamId, archived: false, filterBy } }))

  const { total: inboxTotal, notificationsEntities: inbox } = inboxResponse

  yield put(setNotifications({ inbox, inboxTotal }))
})

export const fetchArchive = createSaga(function* fetchArchive({
  payload: { teamId, filterBy },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamResourcesApi()

  const archivedResponse = yield call(api.request('getNotifications', { query: { teamId, archived: true, filterBy } }))

  const { total: archivedTotal, notificationsEntities: archived } = archivedResponse

  yield put(setNotifications({ archived, archivedTotal }))
})

export const updateInbox = createSaga(function* updateInbox({
  payload: { teamId, entity, offset, filterBy },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamResourcesApi()

  // This is the entity case i.e. we are loading for a specific entity
  if (entity) {
    yield put(addNotificationToLoading({ id: entity.id, type: entity.type, archived: false }))

    const notifications = yield call(
      api.request('getEntityNotifications', {
        query: { teamId, type: entity.type, id: entity.id, offset, archived: false, filterBy },
      }),
    )

    yield put(
      appendInboxNotifications({ inbox: notifications.notificationEvents, id: entity.id, entityType: entity.type }),
    )

    yield put(removeNotificationFromLoading({ id: entity.id, type: entity.type, archived: false }))
  } else {
    // This is top level case i.e. we are loading at the top level

    yield put(addNotificationToLoading({ id: 0, type: 'TOP_LEVEL_NOTIFICATIONS', archived: false }))

    const { notificationsEntities: inbox } = yield call(
      api.request('getNotifications', { query: { teamId, offset, archived: false, filterBy } }),
    )

    yield put(appendInboxNotifications({ inbox }))

    yield put(removeNotificationFromLoading({ id: 0, type: 'TOP_LEVEL_NOTIFICATIONS', archived: false }))
  }
})

export const updateArchive = createSaga(function* updateArchive({
  payload: { teamId, entity, offset, filterBy },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamResourcesApi()

  // This is the entity case i.e. we are loading for a specific entity
  if (entity) {
    yield put(addNotificationToLoading({ id: entity.id, type: entity.type, archived: true }))

    const notifications = yield call(
      api.request('getEntityNotifications', {
        query: { teamId, type: entity.type, id: entity.id, offset, archived: true, filterBy },
      }),
    )

    yield put(
      appendArchiveNotifications({
        archived: notifications.notificationEvents,
        id: entity.id,
        entityType: entity.type,
      }),
    )

    yield put(removeNotificationFromLoading({ id: entity.id, type: entity.type, archived: true }))
  } else {
    // This is top level case i.e. we are loading at the top level

    yield put(addNotificationToLoading({ id: 0, type: 'TOP_LEVEL_NOTIFICATIONS', archived: true }))

    const { notificationsEntities: archived } = yield call(
      api.request('getNotifications', { query: { teamId, offset, archived: true, filterBy } }),
    )

    yield put(appendArchiveNotifications({ archived }))

    yield put(removeNotificationFromLoading({ id: 0, type: 'TOP_LEVEL_NOTIFICATIONS', archived: true }))
  }
})

export const archiveEntityNotifications = createSaga(function* archiveEntityNotifications({
  payload: { teamId, entityId, entityType, filterBy },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamResourcesApi()
  yield call(api.request('archiveEntityNotifications', { query: { teamId, entityId, entityType } }))

  yield put(removeNotificationsFromInbox({ id: entityId, type: entityType }))

  yield call(fetchArchive, { payload: { teamId, filterBy } })
})

export const unarchiveEntityNotifications = createSaga(function* unarchiveEntityNotifications({
  payload: { teamId, entityId, entityType, filterBy },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamResourcesApi()
  yield call(api.request('unarchiveEntityNotifications', { query: { teamId, entityId, entityType } }))

  yield put(removeNotificationsFromArchive({ id: entityId, type: entityType }))

  yield call(fetchInbox, { payload: { teamId, filterBy } })
})

export const archiveAllNotifications = createSaga(function* archiveAllNotifications({
  payload: { teamId, filterBy },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamResourcesApi()
  yield call(api.request('archiveAllNotifications', { query: { teamId } }))

  yield put(removeNotificationsFromInbox())

  yield call(fetchArchive, { payload: { teamId, filterBy } })
})

const prefix = '@teamDeliverables/'

export const FETCH_TEAM_DELIVERABLE_DATA = `${prefix}FETCH_TEAM_DELIVERABLE_DATA`
export const SET_TEAM_DELIVERABLE_DATA = `${prefix}SET_TEAM_DELIVERABLE_DATA`
export const SET_DELIVERABLE_STATUS = `${prefix}SET_DELIVERABLE_STATUS`
export const SET_DELIVERABLE_STATUS_SUCCESS = `${prefix}SET_DELIVERABLE_STATUS_SUCCESS`

export const FETCH_TEAM_DELIVERABLES_DATA = `${prefix}FETCH_TEAM_DELIVERABLES_DATA`
export const SET_TEAM_DELIVERABLES_DATA = `${prefix}SET_TEAM_DELIVERABLES_DATA`

export const FETCH_DELIVERABLES_TRACKER_DATA = `${prefix}FETCH_DELIVERABLES_TRACKER_DATA`
export const SET_DELIVERABLES_TRACKER_DATA = `${prefix}SET_DELIVERABLES_TRACKER_DATA`

export const SET_IS_DELIVERABLE_LOCKED = `${prefix}SET_IS_DELIVERABLE_LOCKED`

export const FETCH_COMMENTS = `${prefix}FETCH_COMMENTS`
export const SET_COMMENTS = `${prefix}SET_COMMENTS`
export const RESET_COMMENTS = `${prefix}RESET_COMMENTS`
export const CREATE_COMMENT = `${prefix}CREATE_COMMENT`
export const CREATE_COMMENT_SUCCESS = `${prefix}CREATE_COMMENT_SUCCESS`

export const SET_DELIVERABLE_ACKNOWLEDGE_DATA = `${prefix}SET_DELIVERABLE_ACKNOWLEDGE_DATA`

export const UPDATE_DELIVERABLE_META = `${prefix}UPDATE_DELIVERABLE_META`
export const UPDATE_DELIVERABLE_META_SUCCESS = `${prefix}UPDATE_DELIVERABLE_META_SUCCESS`

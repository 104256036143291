import communications from './communications'
import dayOne from './dayOne'
import dayOneReadiness from './dayOneReadiness'
import hypercareLog from './hypercareLog'
import mutualDiscovery from './mutualDiscovery'
import teamCharter from './teamCharter'
import teamDeliverables from './teamDeliverables'
import teamHome from './teamHome'
import teamWorkspace from './teamWorkspace'
import teamStatusesTracker from './teamStatusesTracker'
import functionalProcessDesign from './functionalProcessDesign'
import masterCalendar from './masterCalendar'
import documentSharing from './documentSharing'
import knowledgeCenter from './knowledgeCenter'

export default {
  ...communications,
  ...dayOne,
  ...dayOneReadiness,
  ...hypercareLog,
  ...mutualDiscovery,
  ...teamCharter,
  ...teamDeliverables,
  ...teamHome,
  ...teamWorkspace,
  ...teamStatusesTracker,
  ...functionalProcessDesign,
  ...masterCalendar,
  ...documentSharing,
  ...knowledgeCenter,
}

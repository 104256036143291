import styled from 'styled-components'
import { Icon } from '@blueprintjs/core'
import { FONT_FAMILY, textDarkGrey, defaultGrey } from '@imo/imo-ui-toolkit'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgb(225, 232, 237);
`

export const Content = styled.div`
  width: 363px;
  height: 200px;
  margin: auto;
  padding-top: calc(50vh - 88px);

  .header-divider {
    width: 100%;
    margin-bottom: 15px;
  }
`

export const Header = styled.div`
  text-align: center;
  margin-bottom: 14px;
`

export const HeaderIcon = styled(Icon)`
  margin-bottom: 10px;
  color: ${defaultGrey};
`

export const HeaderTitle = styled.div`
  font: 16px ${FONT_FAMILY};
  line-height: 1.31;
  color: ${textDarkGrey};
`

export const Message = styled.div`
  width: 350px;
  height: 60px;
  font: 14px ${FONT_FAMILY};
  line-height: 1.43;
  text-align: center;
  color: ${textDarkGrey};
`

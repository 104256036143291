import { call, put } from 'redux-saga/effects'
import { fetchRequestsWithLoader } from '@communications/actions/actions'
import { getCommunicationsApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const deleteRequest = createSaga(function* deleteRequest({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getCommunicationsApi()
  yield call(api.request('deleteRequest', { query: { requestId: payload } }))

  yield put(fetchRequestsWithLoader())
})

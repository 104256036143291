import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class InitiativeActivityLogApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  fetchInitiativeActivityLog = ({
    query,
  }: {
    query: { teamId: number; initiativeId: number; limit?: number; offset?: number }
  }) => {
    const { teamId, initiativeId, limit, offset } = query

    return this.call({
      pathname: `/teams/${teamId}/value-capture/v2/initiatives-v2/${initiativeId}/activity-log`,
      query: {
        limit,
        offset,
      },
    })
  }
}

import * as Yup from 'yup'
import { noSpecialCharsRegex } from '@helpers/regex'
import { SPECIAL_CHARS_MESSAGE } from '@helpers/constants'

const setupFormFieldsValidation = {
  companyAName: Yup.string()
    .max(20, 'Company A name must be less than 20 characters')
    .matches(noSpecialCharsRegex, SPECIAL_CHARS_MESSAGE.replace('Value', 'Company A name'))
    .required('This field is required'),
  companyALogo: Yup.string().optional(),
  companyBName: Yup.string()
    .max(20, 'Company B name must be less than 20 characters')
    .matches(noSpecialCharsRegex, SPECIAL_CHARS_MESSAGE.replace('Value', 'Company B name'))
    .required('This field is required'),
  companyBLogo: Yup.string().optional(),
  industryL1: Yup.string().required('This field is required'),
  industryL2: Yup.string().required('This field is required'),
  dealType: Yup.string().required('This field is required'),
  weekDay: Yup.string().required('This field is required'),
  supportEmail: Yup.string()
    .max(50, 'Support email length should be up to 50 characters')
    .required('This field is required')
    .email(),
  timeHour: Yup.string().required('This field is required'),
  separator: Yup.string().required('This field is required'),
  thousandsSeparator: Yup.string().required('This field is required'),
  currency: Yup.string().max(5, 'Currency must be less or equal to 5 characters').required('This field is required'),
  currencyRounding: Yup.string().required('This field is required'),
}

export const setupFormSchemaWithV2Tracker = Yup.object().shape({
  ...setupFormFieldsValidation,
  dayOneDate: Yup.date().nullable().required('This field is required'),
})

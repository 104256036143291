import { put, call } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { omit } from 'lodash'
import {
  setCentralFunctionalTSA,
  updateCentralFunctionalTSASuccess,
  deleteCentralFunctionalTSASuccess,
  setProjectList,
} from '@centralFunctionalTSA/actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getCentralFunctionalTSAApi, getMyTeamApi, getRegionalLocalTSAApi, getTSAExitPlansApi } from '@common/net'
import { setIsDeliverableLocked } from '@teamDeliverables/actions/actions'
import { transformEmptyStringsToNull } from '@helpers/utils'

export const fetchCentralFunctionalTSA = createSaga(function* fetchCentralFunctionalTSA({
  payload,
}: {
  payload: $TSFixMe
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, isTsaExitPlans, isRegionalLocalTSA } = payload
  const requestBody = {
    query: {
      teamId,
      additionalParams: isTsaExitPlans ? { full: 'true' } : {},
    },
  }

  const api = isRegionalLocalTSA ? yield getRegionalLocalTSAApi() : yield getCentralFunctionalTSAApi()
  const data = yield call(api.request('getTSA', requestBody))

  yield put(setCentralFunctionalTSA(data))

  yield call(delay, 100)

  yield put(setIsDeliverableLocked({ isDeliverableLocked: data.locked }))
},
true)

export const createCentralFunctionalTSA = createSaga(function* createCentralFunctionalTSA({
  payload,
}: {
  payload: $TSFixMe
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data, isRegionalLocalTSA, isTsaExitPlans } = payload
  const api = isRegionalLocalTSA
    ? yield getRegionalLocalTSAApi()
    : isTsaExitPlans
    ? yield getTSAExitPlansApi()
    : yield getCentralFunctionalTSAApi()

  const { id, listDraftId } = yield call(
    api.request('createTSA', {
      query: {
        teamId,
      },
      body: omit(data, ['id', 'isNew', 'linkedProject', 'isLocked']),
    }),
  )

  yield put(updateCentralFunctionalTSASuccess({ id: data.id, data: { ...data, id, listDraftId, isNew: false } }))
},
true)

export const updateCentralFunctionalTSA = createSaga(function* updateCentralFunctionalTSA({
  payload,
}: {
  payload: $TSFixMe
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data, id, processId, isRegionalLocalTSA, isTsaExitPlans } = payload
  const transformedData = transformEmptyStringsToNull(data)

  const api = isRegionalLocalTSA
    ? yield getRegionalLocalTSAApi()
    : isTsaExitPlans
    ? yield getTSAExitPlansApi()
    : yield getCentralFunctionalTSAApi()

  yield call(
    api.request('updateTSA', {
      query: {
        teamId,
        processId: id,
      },
      body: omit(transformedData, ['id', 'isNew', 'teamId', 'listDraftId', 'createdAt', 'updatedAt', 'linkedProject']),
    }),
  )

  yield put(updateCentralFunctionalTSASuccess({ id: processId, data }))
})

export const deleteCentralFunctionalTSA = createSaga(function* deleteCentralFunctionalTSA({
  payload,
}: {
  payload: $TSFixMe
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, id, isRegionalLocalTSA, isTsaExitPlans } = payload

  const api = isRegionalLocalTSA
    ? yield getRegionalLocalTSAApi()
    : isTsaExitPlans
    ? yield getTSAExitPlansApi()
    : yield getCentralFunctionalTSAApi()

  yield call(
    api.request('deleteTSA', {
      query: {
        teamId,
        processId: id,
      },
    }),
  )

  yield put(deleteCentralFunctionalTSASuccess({ id: id }))
})

export const fetchProjectList = createSaga(function* fetchProjectPlanList(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, isTsaExitPlans } = action.payload

  const query = { teamId, isTsaExitPlans }

  const api = yield getMyTeamApi()
  const projectPlanList = yield call(api.request('getProjectPlanList', { query }))

  yield put(setProjectList({ projectPlanList }))
})

import { call, put } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { setProjectWatchers, addProjectWatcher, removeProjectWatcher } from '../actions/actions'
import { updateNotificationWatchStatus } from '@teamHome/actions/actions'
import { getProjectWatchersApi } from '@common/net'
import { NotificationEntityTypes } from '@helpers/constants'

export const watchProject = createSaga(function* watchProject(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, entityId } = action.payload

  const projectWatchersApi = yield getProjectWatchersApi()

  const data = yield call(projectWatchersApi.request('watchProject', { query: { teamId, entityId } }))

  const teams = data.user.userTeamRoles.map((teamRole: $TSFixMe) => ({
    role: teamRole.role.name,
    teamName: teamRole.team.longName,
    teamType: teamRole.team.teamType.name,
  }))

  const watcher = {
    name: `${data.user.name} ${data.user.surname}`,
    teams,
    userId: data.user.id,
  }

  yield put(addProjectWatcher({ entityId, watcher }))

  yield put(
    updateNotificationWatchStatus({
      entityId,
      entityType: NotificationEntityTypes.TeamKeyProcessL2Project,
      isWatching: true,
    }),
  )
},
false)

export const unwatchProject = createSaga(function* unwatchProject(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, entityId, userId } = action.payload

  const projectWatchersApi = yield getProjectWatchersApi()

  yield call(projectWatchersApi.request('unwatchProject', { query: { teamId, entityId } }))

  yield put(removeProjectWatcher({ entityId, userId }))

  yield put(
    updateNotificationWatchStatus({
      entityId,
      entityType: NotificationEntityTypes.TeamKeyProcessL2Project,
      isWatching: false,
    }),
  )
},
false)

export const fetchProjectWatchers = createSaga(function* fetchProjectWatchers(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, keyProcessId, keyProcessProjectId } = action.payload

  const projectWatchersApi = yield getProjectWatchersApi()

  const data = yield call(
    projectWatchersApi.request('fetchProjectWatchers', { query: { teamId, keyProcessId, keyProcessProjectId } }),
  )

  const watchers = data.map(({ user }: $TSFixMe) => {
    const name = `${user.name} ${user.surname}`

    const teams = user.userTeamRoles.map((teamRole: $TSFixMe) => ({
      role: teamRole.role.name,
      teamName: teamRole.team.longName,
      teamType: teamRole.team.teamType.name,
    }))

    return {
      userId: user.id,
      name,
      teams,
    }
  })

  yield put(setProjectWatchers({ keyProcessProjectId, watchers }))
},
false)

import styled from 'styled-components'
import { greyLight3 } from '@imo/imo-ui-toolkit'

const offsetTop = 56

export const Wrapper = styled.div<{ isPdfEnabled: boolean }>`
  height: ${({ isPdfEnabled }) => (isPdfEnabled ? `calc(100vh - ${offsetTop}px)` : '100vh')};
  background: ${greyLight3};
  min-height: ${({ isPdfEnabled }) => (isPdfEnabled ? `calc(100vh - ${offsetTop}px)` : '100vh')};
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  position: relative;
  top: ${({ isPdfEnabled }) => (isPdfEnabled ? `${offsetTop}px` : '0')};
`

import { all, call, race, take, takeLatest } from 'redux-saga/effects'
import * as actionTypes from '../actions/actionTypes'
import { createSaga } from '@common/sagaCreator/createSaga'
import * as sharedConstants from '@generic/actions/actionTypes'
import {
  fetchVideos,
  createNewVideo,
  updateVideo,
  deleteVideo,
  resetVideoLoadingState,
  saveVideosOrder,
} from './videoSagas/videoSagas'
import {
  fetchArticles,
  createNewArticle,
  updateArticle,
  deleteArticle,
  resetArticleLoadingState,
  saveArticlesOrder,
} from './articlesSagas/articlesSagas'
import {
  fetchIntroductionMaterials,
  fetchIntroductionMaterialsAndDownload,
  createNewIntroductionMaterial,
  updateIntroductionMaterial,
  deleteIntroductionMaterial,
  resetIntroductionMaterialLoadingState,
  saveIntroductionMaterialsOrder,
} from './introductionMaterialSagas/introductionMaterialSagas'

export const fetchKnowledgeCenterData = createSaga(function* fetchKnowledgeCenterData(action: $TSFixMe) {
  const teamId = action.payload

  yield all([
    call(fetchVideos, teamId),
    call(fetchArticles, { payload: { teamId } }),
    call(fetchIntroductionMaterials, { payload: { teamId } }),
  ])
})

export default function* KnowledgeCenterSaga() {
  yield all([
    takeLatest(actionTypes.FETCH_KNOWLEDGE_CENTER_DATA, fetchKnowledgeCenterData),
    takeLatest(actionTypes.FETCH_INTRODUCTION_MATERIALS_AND_DOWNLOAD, fetchIntroductionMaterialsAndDownload),
    // Videos
    takeLatest(actionTypes.CREATE_NEW_KNOWLEDGE_CENTER_VIDEO, function* createNewVideoWithCancel(...args) {
      // @ts-expect-error check types
      yield race([call(createNewVideo, ...args), take(sharedConstants.CANCEL_UPLOADING_FETCH)])
    }),
    takeLatest(actionTypes.UPDATE_KNOWLEDGE_CENTER_VIDEO, function* updateVideoWithCancel(...args) {
      // @ts-expect-error check types
      yield race([call(updateVideo, ...args), take(sharedConstants.CANCEL_UPLOADING_FETCH)])
    }),
    takeLatest(actionTypes.DELETE_KNOWLEDGE_CENTER_VIDEO, deleteVideo),
    takeLatest(
      [actionTypes.CREATE_NEW_KNOWLEDGE_CENTER_VIDEO_STATE, actionTypes.UPDATE_KNOWLEDGE_CENTER_VIDEO_STATE],
      resetVideoLoadingState,
    ),
    takeLatest(actionTypes.SAVE_VIDEOS_ORDER, saveVideosOrder),
    // Articles
    takeLatest(actionTypes.CREATE_NEW_KNOWLEDGE_CENTER_ARTICLE, function* createNewArticleWithCancel(...args) {
      // @ts-expect-error check types
      yield race([call(createNewArticle, ...args), take(sharedConstants.CANCEL_UPLOADING_FETCH)])
    }),
    takeLatest(actionTypes.UPDATE_KNOWLEDGE_CENTER_ARTICLE, function* updateArticleWithCancel(...args) {
      // @ts-expect-error check types
      yield race([call(updateArticle, ...args), take(sharedConstants.CANCEL_UPLOADING_FETCH)])
    }),
    takeLatest(actionTypes.DELETE_KNOWLEDGE_CENTER_ARTICLE, deleteArticle),
    takeLatest(
      [actionTypes.CREATE_NEW_KNOWLEDGE_CENTER_ARTICLE_STATE, actionTypes.UPDATE_KNOWLEDGE_CENTER_ARTICLE_STATE],
      resetArticleLoadingState,
    ),
    takeLatest(actionTypes.FETCH_READ_MODE_ARTICLES, fetchArticles),
    takeLatest(actionTypes.SAVE_ARTICLES_ORDER, saveArticlesOrder),
    // Introduction materials
    takeLatest(
      actionTypes.CREATE_NEW_INTRODUCTION_MATERIAL,
      function* createNewIntroductionMaterialWithCancel(...args) {
        // @ts-expect-error check types
        yield race([call(createNewIntroductionMaterial, ...args), take(sharedConstants.CANCEL_UPLOADING_FETCH)])
      },
    ),
    takeLatest(actionTypes.UPDATE_INTRODUCTION_MATERIAL, function* updateIntroductionMaterialWithCancel(...args) {
      // @ts-expect-error check types
      yield race([call(updateIntroductionMaterial, ...args), take(sharedConstants.CANCEL_UPLOADING_FETCH)])
    }),
    takeLatest(actionTypes.DELETE_INTRODUCTION_MATERIAL, deleteIntroductionMaterial),
    takeLatest(
      [actionTypes.CREATE_NEW_INTRODUCTION_MATERIAL_STATE, actionTypes.UPDATE_INTRODUCTION_MATERIAL_STATE],
      resetIntroductionMaterialLoadingState,
    ),
    takeLatest(actionTypes.SAVE_INTRODUCTION_MATERIAL_ORDER, saveIntroductionMaterialsOrder),

    takeLatest(actionTypes.SHARE_INTRODUCTION_MATERIAL, updateIntroductionMaterial),
    takeLatest(actionTypes.SHARE_KNOWLEDGE_CENTER_ARTICLE, updateArticle),
    takeLatest(actionTypes.SHARE_KNOWLEDGE_CENTER_VIDEO, updateVideo),
  ])
}

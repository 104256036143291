import { call } from 'redux-saga/effects'
import { getDayOneApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const uploadTaskFile = createSaga(function* uploadTaskFile(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, projectId, keyProcessId, id: taskId, file, onSuccess } = action.payload
  const formData = new FormData()
  formData.append('attachment', file)

  const api = yield getDayOneApi()
  const { key, id } = yield call(
    api.request('uploadTaskFile', { query: { teamId, projectId, keyProcessId, taskId }, body: formData }),
  )

  yield onSuccess && call(onSuccess, { key, id })
},
true)

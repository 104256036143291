import * as Yup from 'yup'
import { isPresentFunctions, isUnique } from '@helpers/schemas/utils'

export const taxonomySchema = Yup.object().shape({
  taxonomyFunctions: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required('This field is required.'),
        name: Yup.string().trim().required('This field is required.'),
        type: Yup.string().required('This field is required.'),
      }),
    )
    .test('isPresentFunctions', 'At least one function must be present for each of the 3 tables.', isPresentFunctions)
    .test('isUnique', 'Names are unique.', isUnique(['name'], false)),
  taxonomyGeographies: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        level0Name: Yup.string().trim().nullable(),
        level1Name: Yup.string().trim().nullable(),
        level2Name: Yup.string().trim().nullable(),
        level3Name: Yup.string().trim().nullable(),
        level4Name: Yup.string().trim().nullable(),
      }),
    )
    .test('isUnique', 'Names are unique.', isUnique(['level1Name', 'level2Name', 'level3Name', 'level4Name'], false)),
  taxonomyBusinessUnits: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string().trim().required('This field is required.'),
      }),
    )
    .min(1, 'At least one business unit must be present.')
    .test('isUnique', 'Names are unique.', isUnique(['name'], false)),
})

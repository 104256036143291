import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'
import { fetchProjectActivityLogSaga } from './fetchProjectActivityLogSaga'

export default function* projectActivityLogSaga() {
  yield all([
    takeLatest(
      [constants.FETCH_PROJECT_ACTIVITY_LOG, constants.FETCH_MORE_PROJECT_ACTIVITY_LOGS],
      fetchProjectActivityLogSaga,
    ),
  ])
}

const prefix = '@projectWatchers/'

// api calls
export const FETCH_PROJECT_WATCHERS = `${prefix}FETCH_PROJECT_WATCHERS`
export const WATCH_PROJECT = `${prefix}WATCH_PROJECT`
export const UNWATCH_PROJECT = `${prefix}UNWATCH_PROJECT`

// store updates
export const SET_PROJECT_WATCHERS = `${prefix}SET_PROJECT_WATCHERS`
export const ADD_PROJECT_WATCHER = `${prefix}ADD_PROJECT_WATCHER`
export const REMOVE_PROJECT_WATCHER = `${prefix}REMOVE_PROJECT_WATCHER`

import { handleActions } from 'redux-actions'
import * as constants from '@myImoConfigActions/programSetup/actionTypes'

export interface IProgramSetupState {
  programSetup: {
    codename: string
    companyAName: string
    companyALogo: string
    companyALogoUrl: string | null
    companyBName: string
    companyBLogo: string
    companyBLogoUrl: string | null
    industryL1: string
    industryL2: string
    partner: string
    dealType: string
    weekDay: string
    timeHour: string
    supportEmail: string
    separator: string
    currencyRounding: string
    currency: string
    dayOneDate: string
  }
}

const initialState: IProgramSetupState = {
  programSetup: {
    codename: '',
    companyAName: '',
    companyALogo: '',
    companyALogoUrl: null,
    companyBName: '',
    companyBLogo: '',
    companyBLogoUrl: null,
    industryL1: '',
    industryL2: '',
    partner: '',
    dealType: '',
    weekDay: '',
    timeHour: '',
    supportEmail: '',
    separator: '',
    currencyRounding: '',
    currency: '',
    dayOneDate: '',
  },
}

export const programSetupReducer = handleActions<IProgramSetupState, $TSFixMe>(
  {
    [constants.FETCH_PROGRAM_SETUP_SUCCESS]: (state, action) => {
      let newConf = {}
      action.payload.config.map((conf: { key: string; value: string }) => {
        const { key, value } = conf
        newConf = { ...newConf, [key]: value }

        return conf
      })

      return { ...state, programSetup: { ...state.programSetup, ...newConf } }
    },
    [constants.CREATE_PROGRAM_SETUP]: (state, action) => {
      return { ...state, programSetup: action.payload.programSetup }
    },
    [constants.UPDATE_PROGRAM_SETUP]: (state, action) => {
      return { ...state, programSetup: { ...action.payload.programSetup } }
    },
    [constants.RESET_PROGRAM_SETUP]: () => {
      return { ...initialState }
    },
  },
  initialState,
)

import { Container } from './AskContainer.styles'
import { AskDocsBot, useUserInfo } from '@imo/chat'
import { toggleDocsBot } from '@generic/actions/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccessToken, getRefreshedToken, getTenantId, hasDocsBotBeenOpened, isDocsBotOpen } from '@generic/selectors'
import Config from '@config/index'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { useLocation } from 'react-router-dom'

export const DocsContainer = () => {
  const { isAdmin } = useUserInfo()
  const location = useLocation()
  const [currentLocation, setCurrentLocation] = useState(location)
  const dispatch = useDispatch()

  const isOpen = useSelector(isDocsBotOpen)
  const hasBeenOpened = useSelector(hasDocsBotBeenOpened)

  const initialToken = useSelector(getAccessToken)
  const refreshedToken = useSelector(getRefreshedToken)
  const activeToken = refreshedToken || initialToken
  const tenantId = useSelector(getTenantId)

  const { isEnabled } = useFeatureFlags()

  //   const messagesContainerRef = useRef<HTMLDivElement & { scrollToBottom: () => void }>(null)

  useEffect(() => {
    if (location.pathname !== currentLocation.pathname && isOpen) {
      dispatch(toggleDocsBot())
    }
    setCurrentLocation(location)
  }, [location.pathname, currentLocation.pathname, isOpen])

  const yPos = () => {
    // Get all elements that compose the header and return the total height
    const navAreas = document.getElementsByClassName('header-component')
    return Array.from(navAreas).reduce((acc, a) => acc + a.getBoundingClientRect().height, 0)
  }

  // TODO: Scroll to bottom imperative handle needs to be implemented for docs.
  //   useEffect(() => {
  //     if (isOpen && messagesContainerRef.current) {
  //       // Ensure messages are always scrolled to bottom when chat is opened
  //       messagesContainerRef.current.scrollToBottom()
  //     }
  //   }, [isOpen])

  if (!hasBeenOpened || !isAdmin) {
    return null
  }

  return (
    <Container data-testid="docs-bot-chat-container" isOpen={isOpen} yPos={yPos()}>
      <AskDocsBot.Provider
        token={activeToken}
        tenantId={tenantId}
        proxyBaseURL={Config.ASK_WS_SERVER}
        isCached={isEnabled('ASK_MYIMO_DOCS_CACHING')}
        isOpen={isOpen}
      >
        <AskDocsBot.Chat onClose={() => dispatch(toggleDocsBot())} />
      </AskDocsBot.Provider>
    </Container>
  )
}

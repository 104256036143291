import { Colors } from '@blueprintjs/core'
import { VCControllerIDs } from '@myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/TeamValueCaptureController/utils'
import { IFlagChanging, IIconProperties } from '@myImoClient/components/Header/AppliedFilters/interfaces'
import { has } from 'lodash/fp'

export const getIconProperties = ({ isActive, id, toggleSettings }: IIconProperties) => {
  const color = isActive ? Colors.BLUE3 : Colors.GRAY5
  let text = isActive && id === VCControllerIDs.CONFIDENTIAL ? toggleSettings[VCControllerIDs.CONFIDENTIAL] : ''

  if (text === 'NonConfidential') {
    text = 'Non confidential'
  }

  return [color, text]
}

export const changeActiveFlag = ({ filterList = [], toggleSettings, isAdvancedFinancialsTurnedOn }: IFlagChanging) =>
  filterList
    .filter(
      (filter) => has(filter.id, toggleSettings) && (isAdvancedFinancialsTurnedOn || !filter.advancedFinancialsOnly),
    )
    .map((filter) => ({ ...filter, isActive: Boolean(toggleSettings[filter.id]) }))

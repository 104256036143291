import { handleActions } from 'redux-actions'
import { filter } from 'lodash'
import * as constants from '@myImoConfigActions/teamSetup/actionTypes'
import { teams } from '@common/accessController/constants'
import * as teamConstants from '@generic/selectors/teamConstants'

export interface ITeamSetupState {
  integrationTeams: $TSFixMe[]
  imoTeams: $TSFixMe[]
  centralTeams: $TSFixMe[]
}

const initialState: ITeamSetupState = {
  integrationTeams: [],
  imoTeams: [],
  centralTeams: [],
}

export const teamSetupReducer = handleActions<ITeamSetupState, $TSFixMe>(
  {
    [constants.GET_TEAMS_SUCCESS]: (state, action) => {
      return {
        ...state,
        centralTeams: filter(action.payload, { teamType: teams.CENTRAL }),
        imoTeams: filter(action.payload, (team) => teamConstants.ALL_IMO_ROLES.includes(team.teamType)),
        integrationTeams: filter(action.payload, (team) => teamConstants.INTEGRATION_ROLES.includes(team.teamType)),
      }
    },
    [constants.UPDATE_CENTRAL_TEAMS]: (state, action) => {
      return { ...state, centralTeams: action.payload.centralTeams }
    },
    [constants.UPDATE_IMO_TEAMS]: (state, action) => {
      return { ...state, imoTeams: action.payload.imoTeams }
    },
    [constants.UPDATE_INTEGRATION_TEAMS]: (state, action) => {
      return { ...state, integrationTeams: action.payload.integrationTeams }
    },
  },
  initialState,
)

import { call } from 'redux-saga/effects'
import { getCommunicationsApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const uploadEventAttachment = createSaga(function* uploadEventAttachment({
  eventId,
  file,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const formData = new FormData()
  formData.append('attachment', file)

  const api = yield getCommunicationsApi()
  yield call(api.request('uploadEventAttachment', { query: { eventId }, body: formData }))
})

// Libs
import { find, lowerCase, isArray } from 'lodash'
import { put, call, select } from 'redux-saga/effects'

// Actions
import {
  createCommentSuccess,
  setDeliverableStatusSuccess,
  setDeliverableAcknowledgeData,
} from '@teamDeliverables/actions/actions'

// API
import { getDeliverablesApi } from '@common/net'

// Selectors
import { getUserId } from '@generic/selectors'
import { getTeamDeliverable } from '../selectors'

// Sagas

// Utils
import { getDeliverableDisplayStatus } from '@helpers/statuses'
import { createSaga } from '@common/sagaCreator/createSaga'
import { fetchComments } from './fetchComments'

export const setTeamDeliverableStatus = createSaga(function* setTeamDeliverableStatus(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const deliverable = yield select(getTeamDeliverable)
  const { teamId, deliverableId, status, isTSACase } = action.payload

  const api = yield getDeliverablesApi()
  const { success = true, errorMessage = [] } = yield call(
    api.request('updateTeamDeliverableData', {
      query: { teamId, deliverableId },
      body: { status },
    }),
  )

  if (!success && isTSACase) {
    const missingKeyProcesses = isArray(errorMessage) ? errorMessage : []

    yield put(setDeliverableAcknowledgeData({ keyProcesses: missingKeyProcesses }))
  }

  if (success) {
    yield put(
      setDeliverableStatusSuccess({
        status,
        displayStatus: getDeliverableDisplayStatus({ ...deliverable, status }),
      }),
    )
  }

  // Fetch action-comments and update the store with the latest comment for current user
  const { comments: actionComments } = yield call(fetchComments, teamId, deliverableId, 0)

  if (actionComments && actionComments?.length) {
    const currentUserId = yield select(getUserId)

    yield put(createCommentSuccess(find(actionComments, ({ sender }) => sender.id === currentUserId)))
  }

  return { action: lowerCase(status && success ? status : 'rejected') }
},
true)

import { all } from 'redux-saga/effects'
import genericSaga from '@generic/sagas'
import myImoConfigSaga from '@myImoConfigSagas/root/sagas'
import teamHomeSaga from '@teamHome/sagas'
import teamWorkspaceSaga from '@teamWorkspace/sagas'
import teamCharterSagaV2 from '../myImoClient/myTeam/teamDeliverables/teamCharter/sagas'
import mutualDiscoverySagaV2 from '@mutualDiscovery/sagas'
import dayOneSaga from '@dayOne/sagas'
import dayOneReadinessSaga from '@dayOneReadiness/sagas'
import functionalActivitySaga from '@functionalActivity/sagas'
import communicationsSaga from '@communications/sagas'
import deliverablesSaga from '@teamDeliverables/sagas'
import { teamValueCaptureV2Sagas } from '@teamValueCaptureV2/index'
import hypercareLogSaga from '@hypercareLog/sagas'
import teamStatusTrackerSaga from '@teamStatusTracker/sagas'
import steerCoActionsSaga from '@steerCoRoadmap/sagas'
import day1IssueKPIsSaga from '@day1IssueKPIs/sagas'
import controlPanelSaga from '@controlPanel/sagas'
import customDeliverableSaga from '@customDeliverable/sagas'
import batchUploadSaga from '@batchUpload/sagas'
import projectWatchersSaga from '../myImoClient/common/projectWatchers/sagas'
import taskWatchersSaga from '../myImoClient/common/taskWatchers/sagas'
import functionalProcessDesignSaga from '@functionalProcessDesign/sagas'
import resourcesHomeSaga from '@teamResources/resourcesHome/sagas'
import masterplanSaga from '@teamResources/masterplan/sagas'
import planningAssumptionsReportSaga from '@teamResources/planningAssumptionsReport/sagas'
import scenarioReportSaga from '@teamResources/scenarioReport/sagas'
import scopeTeamMembersSaga from '@teamResources/scopeTeamMembers/sagas'
import interdependenciesSaga from '@teamResources/interdependencies/sagas'
import teamContactsSaga from '@teamResources/teamContacts/sagas'
import dataRequestsSaga from '@teamResources/dataRequestTracker/sagas'
import centralFunctionalTSASaga from '@centralFunctionalTSA/sagas'
import TSAv2Saga from '@domain/myImoClient/TSAv2/sagas'
import decisionLogSaga from '@domain/myImoClient/myTeam/decisionLog/sagas'
import riskLogSaga from '@domain/myImoClient/myTeam/riskLog/sagas'
import teamStructureSaga from '@teamResources/teamStructure/sagas'
import fullProjectPlanSaga from '@teamResources/fullProjectPlan/sagas'
import statusReportSaga from '@teamResources/statusReport/sagas'
import { valueCaptureV2Sagas } from '@domain/myImoClient/valueCaptureV2'
import knowledgeCenterSaga from '@domain/myImoClient/myTeam/knowledgeCenter/sagas'
import importVideosFromLibrary from '@domain/myImoClient/myTeam/importKnowledgeCenterAssets/sagas'
import initiativeWatchersSaga from '@domain/myImoClient/common/initiativeWatchers/sagas'
import projectActivityLogSaga from '@domain/myImoClient/common/projectActivityLog/sagas'
import taskActivityLogSaga from '@domain/myImoClient/common/taskActivityLog/sagas'
import initiativeActivityLogSaga from '@domain/myImoClient/common/initiativeActivityLog/sagas'
import batchUploadActualsSaga from '@domain/myImoClient/common/batchUploadActuals/sagas'
// import instanceConfigSaga from '@domain/instanceConfig/saga'

export default function createRootSaga() {
  return function* rootSaga() {
    yield all([
      // TODO: uncomment after refactoring fetchConfig saga process
      // instanceConfigSaga(),
      myImoConfigSaga(),
      teamHomeSaga(),
      teamWorkspaceSaga(),
      teamCharterSagaV2(),
      mutualDiscoverySagaV2(),
      genericSaga(),
      dayOneSaga(),
      dayOneReadinessSaga(),
      functionalActivitySaga(),
      communicationsSaga(),
      deliverablesSaga(),
      teamValueCaptureV2Sagas(),
      hypercareLogSaga(),
      projectWatchersSaga(),
      taskWatchersSaga(),
      initiativeWatchersSaga(),
      projectActivityLogSaga(),
      taskActivityLogSaga(),
      initiativeActivityLogSaga(),
      teamStatusTrackerSaga(),
      controlPanelSaga(),
      steerCoActionsSaga(),
      customDeliverableSaga(),
      batchUploadSaga(),
      day1IssueKPIsSaga(),
      functionalProcessDesignSaga(),
      resourcesHomeSaga(),
      masterplanSaga(),
      centralFunctionalTSASaga(),
      TSAv2Saga(),
      interdependenciesSaga(),
      planningAssumptionsReportSaga(),
      scenarioReportSaga(),
      scopeTeamMembersSaga(),
      teamContactsSaga(),
      decisionLogSaga(),
      riskLogSaga(),
      dataRequestsSaga(),
      teamStructureSaga(),
      fullProjectPlanSaga(),
      statusReportSaga(),
      valueCaptureV2Sagas(),
      knowledgeCenterSaga(),
      importVideosFromLibrary(),
      batchUploadActualsSaga(),
    ])
  }
}

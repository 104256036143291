import { createStatusComparator } from '@shared/Grid/utils/statusCellUtils'
import { isNil } from 'lodash/fp'
import { IconListRenderer, stringComparator } from '@imo/imo-ui-toolkit'
import { BLANKS_FILTER_LABEL, SPECIAL_ATTRIBUTES } from '@helpers/constants'
import { valueGetterWithInterdependency } from '@shared/DayOne/utils/mapDayOneData'
import { IconName, IconNames } from '@blueprintjs/icons'
import { TFunction } from 'i18next'
import { DayOneProjectListDto, TeamKeyProcessProjectOrTask } from '@common/types/dtos/DayOneProjectListDto'
import { KeyCreatorParams, ValueGetterParams } from 'ag-grid-community'
import { getParentNode, getParentMap } from './projectListHierarchy'

interface LabelArgs {
  t: TFunction
  isSMO: boolean
}

interface TooltipArgs {
  t: TFunction
  data?: $TSFixMe
  parentData?: $TSFixMe
  isSMO: boolean
}

interface SpecialAttribute {
  icon: IconName
  label: (args: LabelArgs) => string
  tooltip: (args: TooltipArgs) => string
  isActive: (data?: $TSFixMe, parentData?: $TSFixMe) => boolean
  transform?: (data?: $TSFixMe, parentData?: $TSFixMe) => string
}

const getProjectMirrorTooltip = (
  t: TFunction,
  teams: { longName: string }[],
  outgoingOrIncoming: 'outgoing' | 'incoming',
) => {
  if (outgoingOrIncoming === 'outgoing') {
    return t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.TOOLTIPS.OUTGOING_MIRROR', {
      teams: teams.map((team) => team.longName),
      count: teams.length,
    })
  } else {
    return t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.TOOLTIPS.INCOMING_MIRROR', { team: teams[0]?.longName })
  }
}
const MAX_ICON_WIDTH = 25
const MIN_COL_WIDTH = 50 // Account for star and sorting icon in header

export const specialAttributesArray = (
  showLinkedTSA: boolean,
  shouldDisplayProjectMirror: boolean,
): SpecialAttribute[] =>
  [
    {
      icon: IconNames.DOLLAR,
      label: ({ t, isSMO }: LabelArgs) =>
        t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.LABELS.LINKED_VALUE_CAPTURE_INITIATIVE', {
          context: isSMO ? 'smo' : undefined,
        }),
      tooltip: ({ t, isSMO }: TooltipArgs) =>
        t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.TOOLTIPS.LINKED_VALUE_CAPTURE_INITIATIVE', {
          context: isSMO ? 'smo' : undefined,
        }),
      isActive: (data: $TSFixMe) => Boolean(data?.linkedInitiative),
    },
    {
      icon: IconNames.SHOPPING_CART,
      label: ({ t }: LabelArgs) => t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.LABELS.LINKED_ONE_TIME_COST'),
      tooltip: ({ t }: TooltipArgs) => t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.TOOLTIPS.LINKED_ONE_TIME_COST'),
      isActive: (data: $TSFixMe) => Boolean(data?.linkedOneTimeCost),
    },
    {
      icon: IconNames.ARROW_RIGHT,
      label: ({ t }: LabelArgs) => t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.LABELS.PREDECESSOR'),
      tooltip: ({ data, t }: TooltipArgs) =>
        t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.TOOLTIPS.PREDECESSOR', { type: data.type }),
      isActive: (data: $TSFixMe) => Boolean(valueGetterWithInterdependency('predecessorId')({ data })),
    },
    {
      icon: IconNames.ARROW_LEFT,
      label: ({ t }: LabelArgs) => t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.LABELS.FOLLOWER'),
      tooltip: ({ data, t }: TooltipArgs) =>
        t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.TOOLTIPS.FOLLOWER', { type: data.type }),
      isActive: (data: $TSFixMe) => Boolean(valueGetterWithInterdependency('followerId')({ data })),
    },
    showLinkedTSA
      ? {
          icon: IconNames.BRIEFCASE,
          label: ({ t }: LabelArgs) => t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.LABELS.LINKED_TSA'),
          tooltip: ({ t }: TooltipArgs) => t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.TOOLTIPS.LINKED_TSA'),
          isActive: (data: $TSFixMe) => Boolean(data?.linkedTSA),
        }
      : null,
    {
      icon: IconNames.INTERSECTION,
      label: ({ t }: LabelArgs) => t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.LABELS.OUTGOING_INTERDEPENDENCY'),
      tooltip: ({ t }: TooltipArgs) => t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.TOOLTIPS.OUTGOING_INTERDEPENDENCY'),
      isActive: (data: $TSFixMe) => Boolean(data?.isOutgoing),
    },
    {
      icon: IconNames.INNER_JOIN,
      label: ({ t }: LabelArgs) => t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.LABELS.INCOMING_INTERDEPENDENCY'),
      tooltip: ({ t }: TooltipArgs) => t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.TOOLTIPS.INCOMING_INTERDEPENDENCY'),
      isActive: (data: $TSFixMe) => (data?.type === 'keyProcess' ? false : Boolean(data?.isIncoming)),
    },
    shouldDisplayProjectMirror
      ? {
          icon: IconNames.GitPull,
          label: ({ t }: LabelArgs) => t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.LABELS.OUTGOING_MIRROR'),
          tooltip: ({ t, data, parentData }: TooltipArgs): string => {
            const rootEntity = data?.type === 'task' ? parentData || data : data
            return getProjectMirrorTooltip(t, rootEntity?.mirroringTeams, 'outgoing')
          },
          isActive: (data: $TSFixMe, parentData: $TSFixMe) => {
            if (data?.type === 'keyProcess') return false
            const rootEntity = data?.type === 'task' ? parentData || data : data
            return rootEntity?.mirroringTeams?.length
          },
          transform: () => 'scale(-1)',
        }
      : null,
    shouldDisplayProjectMirror
      ? {
          icon: IconNames.GitPull,
          label: ({ t }: LabelArgs) => t('PROJECT_PLAN.SPECIAL_ATTRIBUTES.LABELS.INCOMING_MIRROR'),
          tooltip: ({ t, data }: TooltipArgs): string => {
            return getProjectMirrorTooltip(t, [{ longName: data?.mirror?.sendingTeam.name }], 'incoming')
          },
          isActive: (data: $TSFixMe) => {
            if (data?.type === 'keyProcess') return false
            return data?.mirror
          },
          transform: () => 'scale(1, -1)',
        }
      : null,
  ].filter((item): item is SpecialAttribute => !isNil(item))

export const specialAttributesFilterComparator = (
  t: TFunction,
  shouldDisplayTSA = true,
  shouldDisplayProjectMirror = true,
  isSMO = false,
) =>
  createStatusComparator(
    specialAttributesArray(shouldDisplayTSA, shouldDisplayProjectMirror).map((item) => item.label({ t, isSMO })),
  )

export const formatSpecialAttrValueToCompare = (
  t: TFunction,
  value: $TSFixMe,
  shouldDisplayTSA = true,
  shouldDisplayProjectMirror = true,
  isSMO = false,
) =>
  specialAttributesArray(shouldDisplayTSA, shouldDisplayProjectMirror).reduce((result, attribute: $TSFixMe) => {
    const valueForAttribute = value.find(({ label }: $TSFixMe) => label({ t, isSMO }) === attribute.label({ t, isSMO }))
    const isActive = valueForAttribute?.isActive ? 'a' : 'b'
    return result + isActive + (valueForAttribute?.tooltip ?? '')
  }, '')

export const specialAttributesComparator =
  (t: TFunction, shouldDisplayTSA = true, shouldDisplayProjectMirror = true, isSMO = false) =>
  (v1: $TSFixMe, v2: $TSFixMe) => {
    const formattedV1 = formatSpecialAttrValueToCompare(t, v1, shouldDisplayTSA, shouldDisplayProjectMirror, isSMO)
    const formattedV2 = formatSpecialAttrValueToCompare(t, v2, shouldDisplayTSA, shouldDisplayProjectMirror, isSMO)

    return stringComparator(formattedV1, formattedV2)
  }

/**
  Gets the icon count for a row with a given set of parameters
**/
export const specialAttributesIconCount = (
  shouldDisplayTSA: boolean,
  shouldDisplayProjectMirror: boolean,
  parentNode: TeamKeyProcessProjectOrTask | null,
  row: TeamKeyProcessProjectOrTask,
): number => {
  return specialAttributesArray(shouldDisplayTSA, shouldDisplayProjectMirror).filter(({ isActive }) =>
    isActive(row, parentNode),
  ).length
}

export const specialAttributesValueGetter =
  (shouldDisplayTSA: boolean, shouldDisplayProjectMirror: boolean, t: TFunction, isSMO: boolean) =>
  (params: ValueGetterParams) => {
    const parentNode = params.context.parentMap ? getParentNode(params.data, params.context.parentMap) : undefined

    return specialAttributesArray(shouldDisplayTSA, shouldDisplayProjectMirror)
      .filter(({ isActive }) => isActive(params.data, parentNode))
      .map(({ tooltip, transform, ...other }) => ({
        ...other,
        tooltip: tooltip({ t, data: params.data, parentData: parentNode, isSMO }),
        transform: transform ? transform(params.data, parentNode) : undefined,
      }))
  }

export const specialAttributesKeyCreator = (t: TFunction, isSMO: boolean) => {
  return ({ value }: KeyCreatorParams) => {
    const values = value.reduce((arr: $TSFixMe, { label, isActive }: $TSFixMe) => {
      if (isActive) {
        arr.push(label({ t, isSMO }))
      }

      return arr
    }, [])

    return values?.length ? values : BLANKS_FILTER_LABEL
  }
}

export const getSpecialAttributesColumnWidth = ({
  data,
  shouldDisplayTSA,
  shouldDisplayProjectMirror,
}: {
  data: DayOneProjectListDto
  shouldDisplayTSA: boolean
  shouldDisplayProjectMirror: boolean
}): number => {
  const maxPossibleIconCount = specialAttributesArray(shouldDisplayTSA, shouldDisplayProjectMirror).length
  let maxSeenIconCount = 0

  // Quick lookup map for getting the parent node
  const parentMap = getParentMap(data)

  for (const row of data) {
    const parentNode = getParentNode(row, parentMap)
    const rowIcons = specialAttributesIconCount(shouldDisplayTSA, shouldDisplayProjectMirror, parentNode, row)
    if (rowIcons > maxSeenIconCount) {
      maxSeenIconCount = rowIcons
    }
    if (maxSeenIconCount === maxPossibleIconCount) break
  }
  return Math.max(maxSeenIconCount * MAX_ICON_WIDTH, MIN_COL_WIDTH)
}

export const getSpecialAttributesColumn = ({
  showLinkedTSA,
  specialAttributesColumnWidth,
  shouldDisplayProjectMirror,
  t,
  isSMO,
}: {
  showLinkedTSA: boolean
  specialAttributesColumnWidth: number
  shouldDisplayProjectMirror: boolean
  t: TFunction
  isSMO: boolean
}) => ({
  headerName: SPECIAL_ATTRIBUTES,
  field: SPECIAL_ATTRIBUTES,
  headerTooltip: SPECIAL_ATTRIBUTES,
  headerClass: 'header-star',
  cellClass: 'special-attributes',
  minWidth: MIN_COL_WIDTH,
  width: specialAttributesColumnWidth,
  editable: false,
  suppressSizeToFit: true,
  cellRenderer: IconListRenderer,
  keyCreator: specialAttributesKeyCreator(t, isSMO),
  filterParams: {
    comparator: specialAttributesFilterComparator(t, showLinkedTSA, shouldDisplayProjectMirror, isSMO),
  },
  comparator: specialAttributesComparator(t, showLinkedTSA, shouldDisplayProjectMirror, isSMO),
  valueGetter: specialAttributesValueGetter(showLinkedTSA, shouldDisplayProjectMirror, t, isSMO),
  equals: (v1: $TSFixMe, v2: $TSFixMe) =>
    formatSpecialAttrValueToCompare(t, v1, showLinkedTSA, shouldDisplayProjectMirror, isSMO) ===
    formatSpecialAttrValueToCompare(t, v2, showLinkedTSA, shouldDisplayProjectMirror, isSMO),
  hideInExcel: true,
})

import styled from 'styled-components'

import myimoRays from '@assets/svg/myimo-rays.svg'

import myimoElements from '@assets/svg/myimo-elements.svg'

export const BackgroundSceneStyled = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${myimoRays}), linear-gradient(225deg, #0c2670 4.37%, #3473f5 85.64%);
  background-repeat: no-repeat, repeat;
  background-size: cover, cover;
  background-position: center, center;
  position: relative;
`

export const BackgroundUISceneStyled = styled.div`
  content: '';
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: -19px;
  background-image: url(${myimoElements});
  background-repeat: repeat;
  background-size: contain;
  background-position: top;
`

export const MyImoRaysSVGStyled = styled.div`
  background-image: url(${myimoRays});
  background-repeat: repeat;
  background-size: auto;
  width: 100%;
  height: 100%;
`

import { ProcessStatus, STATUS_INTENT_COLOR } from '@imo/imo-ui-toolkit'

export const specificOptions = {
  ALL_STATUSES: 'all',
}

export const basicStatusList = [
  ProcessStatus.COMPLETED,
  ProcessStatus.DELAYED,
  ProcessStatus.ON_TRACK,
  ProcessStatus.NOT_STARTED,
  ProcessStatus.AT_RISK,
]

export const PROCESS_STATUS_COLORS = {
  [ProcessStatus.COMPLETED]: STATUS_INTENT_COLOR.PRIMARY,
  [ProcessStatus.DELAYED]: STATUS_INTENT_COLOR.DANGER,
  [ProcessStatus.STARTED]: STATUS_INTENT_COLOR.NONE,
  [ProcessStatus.ON_TRACK]: STATUS_INTENT_COLOR.SUCCESS,
  [ProcessStatus.NOT_STARTED]: STATUS_INTENT_COLOR.NONE,
  [ProcessStatus.AT_RISK]: STATUS_INTENT_COLOR.WARNING,
}

export const processStatusSortOrder = [
  ProcessStatus.COMPLETED,
  ProcessStatus.NOT_STARTED,
  ProcessStatus.STARTED,
  ProcessStatus.ON_TRACK,
  ProcessStatus.AT_RISK,
  ProcessStatus.DELAYED,
  ProcessStatus.ON_HOLD,
]

export const implementationDetailsStatusList = [
  ProcessStatus.DELAYED,
  ProcessStatus.AT_RISK,
  ProcessStatus.UPCOMING,
  ProcessStatus.ON_TRACK,
]

export const decisionStatusOptions = [
  { label: 'Decided', value: 'Decided' },
  { label: 'Open', value: 'Open' },
  { label: 'Issues', value: 'Issues' },
]

export const riskStatusOptions = [
  { label: 'Mitigated', value: 'Mitigated' },
  { label: 'Open', value: 'Open' },
  { label: 'Issues', value: 'Issues' },
]

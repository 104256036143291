import { dateComparator, formatDate } from '@shared/Grid/utils/dates'
import { stringComparator } from '@helpers/utilsComparators'

import { COLUMN_TYPE } from '@shared/Grid/constants'
import { getSingleSelectionParams, getIsGridSuppressClickEdit, ActionIconRenderer } from '@imo/imo-ui-toolkit'
import FileCellRenderer from './cellRenderers/FileCellRenderer'

export const getFile = ({ node: { data } }: $TSFixMe) => data.url

export const getFileFromURL = (url: $TSFixMe) => {
  if (!url) {
    return null
  }

  const fileRegExp = /(?<fileName>.*)\.(?<fileType>\w*)/u
  const file = url.split('/').pop()
  const {
    // @ts-expect-error fix groups types
    groups: { fileName, fileType },
  } = fileRegExp.exec(file)

  return { fileName, fileType }
}

export const getFileName = ({ fileType, fileName, withExtension }: $TSFixMe) =>
  withExtension ? `${fileName}.${fileType.toLowerCase()}` : fileName

const downloadAttachmentByUrl = (url: $TSFixMe, fileName: $TSFixMe) => {
  const link = document.createElement('a')

  link.setAttribute('download', fileName)
  link.setAttribute('href', url)

  link.click()
}

export const deliverableFilesColumnDefs = [
  {
    headerName: 'Filename',
    cellRenderer: FileCellRenderer,
    cellRendererParams: {
      withExtension: false,
    },
    flex: 1,
    field: 'fileName',
    valueGetter: ({ data: { fileType, fileName } }: $TSFixMe) => ({ fileType, fileName }),
    cellClass: 'ag-cell-tooltip-class-anchor',
    tooltipParams: {
      truncateTargetSelector: '.ag-cell-tooltip-class-anchor > div > span',
    },
  },
  {
    headerName: 'Filetype',
    width: 158,
    valueGetter: ({ data: { fileType } }: $TSFixMe) => fileType.toUpperCase(),
  },
  {
    headerName: 'Category',
    field: 'category',
    width: 288,
  },
  {
    headerName: 'Download',
    valueGetter: getFile,
    field: 'url',
    cellRenderer: ActionIconRenderer,
    cellRendererParams: {
      icon: 'import',
      onClick: ({ value, data: { fileName, fileType } }: $TSFixMe) =>
        downloadAttachmentByUrl(value, `${fileName}.${fileType}`),
      rendererStyle: {
        paddingLeft: '16px',
      },
      contentStyle: {
        alignItems: 'center',
      },
    },
    cellClass: 'action-icon',
    width: 158,
  },
]

export const getUploadedFilesColumnDefs = ({ onDelete }: $TSFixMe) => {
  return [
    {
      headerName: 'Filename',
      cellRenderer: FileCellRenderer,
      cellRendererParams: {
        withExtension: false,
      },
      tooltipParams: {
        truncateTargetSelector: 'span',
      },
      flex: 1,
      sortable: true,
      valueGetter: ({ data: { fileName, fileType } }: $TSFixMe) => ({ fileName, fileType }),
      comparator: (param1: $TSFixMe, param2: $TSFixMe) => stringComparator(param1.fileName, param2.fileName),
    },
    {
      headerName: 'Filetype',
      field: 'fileType',
      width: 158,
      valueGetter: ({ data: { fileType } }: $TSFixMe) => fileType.toUpperCase(),
      sortable: true,
    },
    {
      headerName: 'Uploaded by',
      field: 'uploadedBy',
      type: COLUMN_TYPE.owner,
      sortable: true,
      ...getSingleSelectionParams({ field: 'uploadedBy' }),
      width: 146,
    },
    {
      headerName: 'Upload date',
      field: 'uploadedAt',
      valueFormatter: formatDate,
      width: 142,
      comparator: dateComparator,
      sortable: true,
    },
    {
      headerName: 'Download',
      field: 'url',
      valueGetter: getFile,
      cellRenderer: ActionIconRenderer,
      cellRendererParams: {
        icon: 'import',
        onClick: ({ value, data: { fileName, fileType } }: $TSFixMe) =>
          downloadAttachmentByUrl(value, `${fileName}.${fileType}`),
        rendererStyle: {
          paddingLeft: '16px',
        },
        contentStyle: {
          alignItems: 'center',
        },
      },
      cellClass: 'action-icon',
      width: 81,
    },
    {
      headerName: 'Delete',
      field: 'id',
      cellRenderer: ActionIconRenderer,
      cellRendererParams: ({ api }: $TSFixMe) => {
        const isNotEditable = getIsGridSuppressClickEdit(api)

        return {
          disabled: isNotEditable,
          icon: 'trash',
          onClick: ({ data }: $TSFixMe) => onDelete(data),
          rendererStyle: {
            paddingLeft: '10px',
          },
          contentStyle: {
            alignItems: 'center',
          },
        }
      },
      cellClass: 'action-icon',
      width: 78,
    },
  ]
}

import { isFunction } from 'lodash'
import { getDateWithNullTime } from './utils'

interface SortDeliverablesArgs<T> {
  deliverables: T[]
  startDateAccessor: string | ((deliverable: T) => string | Date | null)
  dueDateAccessor: string | ((deliverable: T) => string | Date | null)
  orgLayerAccessor: string | ((deliverable: T) => unknown)
  orderAccessor: string | ((deliverable: T) => number)
}

const getValue = <T extends { [key: string]: unknown }, K>(
  deliverable: T,
  accessor: string | ((deliverable: T) => K),
): K | null => {
  if (isFunction(accessor)) {
    return accessor(deliverable)
  }
  return deliverable[accessor] !== undefined ? (deliverable[accessor] as unknown as K) : null
}

/** Sorts an array of deliverables according to criteria.
 * 1. If the item has no start date, the item moves to the end of the array.
 * 2. Otherwise, items are ordered from earliest start date to latest start date.
 * 3. If the start date is shared, the item with the earliest end date takes precedence.
 * 4. If the start date and end date are shared, items are sorted by the order property.
 * 5. If the item is an org layer, the order property takes precedence when start date is shared.
 */
export const sortDeliverables = <T extends { [key: string]: unknown }>({
  deliverables,
  startDateAccessor,
  dueDateAccessor,
  orgLayerAccessor,
  orderAccessor,
}: SortDeliverablesArgs<T>): T[] => {
  return [...deliverables].sort((a, b) => {
    const [aOrder, bOrder] = [getValue(a, orderAccessor) ?? 0, getValue(b, orderAccessor) ?? 0]
    if (!getValue(a, startDateAccessor) || !getValue(b, startDateAccessor)) {
      if (getValue(a, orgLayerAccessor) && getValue(b, orgLayerAccessor)) {
        return aOrder - bOrder
      }
      return getValue(a, startDateAccessor) ? -1 : 1
    }
    const [aStartDate, aDueDate] = [getDateWithNullTime(a, startDateAccessor), getDateWithNullTime(a, dueDateAccessor)]
    const [bStartDate, bDueDate] = [getDateWithNullTime(b, startDateAccessor), getDateWithNullTime(b, dueDateAccessor)]
    if (aStartDate !== bStartDate) {
      return aStartDate - bStartDate
    }
    if (getValue(a, orgLayerAccessor) && getValue(b, orgLayerAccessor)) {
      return aOrder - bOrder
    }
    return aDueDate !== bDueDate ? aDueDate - bDueDate : aOrder - bOrder
  })
}

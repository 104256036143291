import { omit } from 'lodash/fp'
import { call, put } from 'redux-saga/effects'

import { getTargetListV2Api, getVCTrackerV2Api } from '@common/net'
import { synergyTypes } from '@helpers/constants'

import {
  deleteTargetSuccess,
  fetchVCSynergyCategories,
  fetchVCBaselineAssumption,
  setTargetList,
  setVCTeams,
  updateTargetSuccess,
} from '../actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchVCTeams = createSaga(function* fetchVCTeams({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload

  const api = yield getVCTrackerV2Api()
  const teams = yield call(api.request('getValueCaptureTeams', { query: { teamId } }))

  yield put(setVCTeams(teams))
})

export const fetchTargetList = createSaga(function* fetchTargetList({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { initiatorId, type } = payload

  const api = yield getTargetListV2Api()
  const targetList = yield call(
    api.request('getTargetList', {
      query: {
        initiatorId,
        type,
      },
    }),
  )

  yield put(
    setTargetList({
      targetList: targetList,
    }),
  )

  yield put(fetchVCBaselineAssumption({ teamId: initiatorId, synergyType: type }))
  yield put(fetchVCSynergyCategories({ teamId: initiatorId, synergyType: type }))
  yield put(fetchVCSynergyCategories({ teamId: initiatorId, synergyType: synergyTypes.ONE_TIME_COST }))
},
true)

export const fetchTarget = createSaga(function* fetchTarget({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { targetId } = payload

  const api = yield getTargetListV2Api()
  const target = yield call(
    api.request('getTarget', {
      query: {
        targetId,
      },
    }),
  )

  yield put(updateTargetSuccess({ id: targetId, data: target }))
},
true)

export const createTarget = createSaga(function* createTarget({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTargetListV2Api()

  const { id } = yield call(
    api.request('createTarget', {
      body: { ...omit(['id', 'isNew', 'team'], payload), teamId: payload.team.id },
    }),
  )

  yield put(updateTargetSuccess({ id: payload.id, data: { ...payload, isNew: false, id } }))
})

export const updateTarget = createSaga(function* updateTarget({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { targetId, data } = payload

  for (const field in data) {
    if (data.hasOwnProperty(field) && data[field] === '') {
      data[field] = null
    }
  }

  const api = yield getTargetListV2Api()
  yield call(
    api.request('updateTarget', {
      query: {
        targetId,
      },
      body: { ...omit(['id', 'createdAt', 'updatedAt', 'isNew', 'team'], data), teamId: data.team.id },
    }),
  )

  yield put(updateTargetSuccess({ id: targetId, data }))
})

export const deleteTarget = createSaga(function* deleteTarget({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { targetId } = payload

  const api = yield getTargetListV2Api()
  yield call(
    api.request('deleteTarget', {
      query: {
        targetId,
      },
    }),
  )

  yield put(deleteTargetSuccess({ id: targetId }))
})

const prefix = '@teamWorkspace/'

export const FETCH_INITIAL_DATA = `${prefix}FETCH_INITIAL_DATA`
export const FETCH_RISK_LOG = `${prefix}FETCH_RISK_LOG`

export const FETCH_WHITEBOARD = `${prefix}FETCH_WHITEBOARD`
export const SET_WHITEBOARD = `${prefix}SET_WHITEBOARD`
export const CREATE_WHITEBOARD = `${prefix}CREATE_WHITEBOARD`
export const ADD_WHITEBOARD = `${prefix}ADD_WHITEBOARD`
export const UPDATE_WHITEBOARD = `${prefix}UPDATE_WHITEBOARD`
export const DELETE_WHITEBOARD = `${prefix}DELETE_WHITEBOARD`
export const REMOVE_WHITEBOARD = `${prefix}REMOVE_WHITEBOARD`

export const FETCH_TEAM_ACTIONS = `${prefix}FETCH_TEAM_ACTIONS`
export const SET_TEAM_ACTIONS = `${prefix}SET_TEAM_ACTIONS`
export const CREATE_TEAM_ACTION = `${prefix}CREATE_TEAM_ACTION`
export const ADD_TEAM_ACTION = `${prefix}ADD_TEAM_ACTION`
export const UPDATE_TEAM_ACTION = `${prefix}UPDATE_TEAM_ACTION`
export const UPDATE_TEAM_ACTION_SUCCESS = `${prefix}UPDATE_TEAM_ACTION_SUCCESS`
export const DELETE_TEAM_ACTION = `${prefix}DELETE_TEAM_ACTION`
export const REMOVE_TEAM_ACTION = `${prefix}REMOVE_TEAM_ACTION`

export const FETCH_DECISIONS_LOG = `${prefix}FETCH_DECISIONS_LOG`
export const SET_DECISIONS_LOG = `${prefix}SET_DECISIONS_LOG`
export const CREATE_DECISIONS_LOG = `${prefix}CREATE_DECISIONS_LOG`
export const ADD_DECISIONS_LOG = `${prefix}ADD_DECISIONS_LOG`
export const UPDATE_DECISIONS_LOG = `${prefix}UPDATE_DECISIONS_LOG`
export const UPDATE_DECISIONS_LOG_SUCCESS = `${prefix}UPDATE_DECISIONS_LOG_SUCCESS`
export const DELETE_DECISIONS_LOG = `${prefix}DELETE_DECISIONS_LOG`
export const DELETE_DECISION_LOG_DATA_FAIL = `${prefix}DELETE_DECISION_LOG_DATA_FAIL`
export const REMOVE_DECISIONS_LOG = `${prefix}REMOVE_DECISIONS_LOG`

export const FETCH_RISKS_LOG = `${prefix}FETCH_RISKS_LOG`
export const SET_RISKS_LOG = `${prefix}SET_RISKS_LOG`
export const CREATE_RISKS_LOG = `${prefix}CREATE_RISKS_LOG`
export const ADD_RISKS_LOG = `${prefix}ADD_RISKS_LOG`
export const UPDATE_RISKS_LOG = `${prefix}UPDATE_RISKS_LOG`
export const UPDATE_RISKS_LOG_SUCCESS = `${prefix}UPDATE_RISKS_LOG_SUCCESS`
export const DELETE_RISKS_LOG = `${prefix}DELETE_RISKS_LOG`
export const REMOVE_RISKS_LOG = `${prefix}REMOVE_RISKS_LOG`
export const DELETE_RISK_LOG_DATA_FAIL = `${prefix}DELETE_RISK_LOG_DATA_FAIL`

export const FETCH_CALENDAR = `${prefix}FETCH_CALENDAR`
export const SET_CALENDAR = `${prefix}SET_CALENDAR`
export const CREATE_CALENDAR = `${prefix}CREATE_CALENDAR`
export const ADD_CALENDAR = `${prefix}ADD_CALENDAR`
export const UPDATE_CALENDAR = `${prefix}UPDATE_CALENDAR`
export const DELETE_CALENDAR = `${prefix}DELETE_CALENDAR`
export const REMOVE_CALENDAR = `${prefix}REMOVE_CALENDAR`

export const FETCH_TEAM_TOPICS = `${prefix}FETCH_TEAM_TOPICS`
export const SET_TEAM_TOPICS = `${prefix}SET_TEAM_TOPICS`
export const CREATE_TEAM_TOPICS = `${prefix}CREATE_TEAM_TOPICS`
export const ADD_TEAM_TOPICS = `${prefix}ADD_TEAM_TOPICS`
export const UPDATE_TEAM_TOPICS = `${prefix}UPDATE_TEAM_TOPICS`
export const DELETE_TEAM_TOPICS = `${prefix}DELETE_TEAM_TOPICS`
export const REMOVE_TEAM_TOPICS = `${prefix}REMOVE_TEAM_TOPICS`
export const SET_IMO_TOPICS = `${prefix}SET_IMO_TOPICS`

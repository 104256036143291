import { QueryClient } from 'react-query'

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        useErrorBoundary: true,
      },
      mutations: {
        retry: false,
        useErrorBoundary: true,
      },
    },
  })
}

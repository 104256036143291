import * as configConstants from '@myImoConfigActions/config/actionTypes'
import { put, takeLatest, all, call, select } from 'redux-saga/effects'
import { getConfigApi } from '@common/net'
import * as selectors from '../../selectors'
import { validateWelcomeNote } from '@helpers/validatorsConfig'
import { createSaga } from '@common/sagaCreator/createSaga'
import { handleForbiddenError } from '../utils'
import { getIsLaunched } from '@domain/instanceConfig/selectors'
import { fetchConfig, updateWelcomePopupText } from '@generic/actions/actions'
import { some, every } from 'lodash'
import { stepStatus } from '@helpers/statuses'
import { fetchUserGenerator } from '@generic/sagas/fetchUser'
import { fetchModuleOptions, sendBulkUpdateModuleOptions } from '../../moduleOptions/saga'
import { FETCH_MODULE_OPTIONS_PENDING, BULK_UPDATE_MODULE_OPTIONS } from '../../moduleOptions/actions/actionTypes'
import { NavigateFunction } from 'react-router-dom'

export const saveWelcomeNote = createSaga(function* saveWelcomeNote({
  payload,
}: {
  payload: { navigate: NavigateFunction }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const configApi = yield getConfigApi()
  const data = yield select(selectors.getWelcomeNote)
  yield call(validateWelcomeNote, data)

  const transformedData = Object.entries(data).map(([key, value]) => ({ key, value, configType: 'UI_TEXT' }))

  yield call(configApi.request('createWelcomeNote', { body: transformedData }))
  yield put({ type: configConstants.SET_STEP_COMPLETED, payload: { no: 9 } })
  yield put(updateWelcomePopupText({ ...data, termsConditions: data.terms }))
  payload.navigate('/config')
},
true)

export const fetchWelcomeNote = createSaga(
  function* fetchWelcomeNote(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const configApi = yield getConfigApi()
    const welcomeNoteData = yield call(configApi.request('getWelcomeNote'))

    const transformedData = welcomeNoteData.reduce((acc: $TSFixMe, setting: { key: string; value: string }) => {
      // $TSFixMe
      // @ts-ignore
      acc[[setting.key]] = setting.value

      return acc
    }, {})

    yield put({ type: configConstants.SET_WELCOME_NOTE_DATA, payload: { welcomeNoteData: transformedData } })
  },
  true,
  handleForbiddenError,
)

export const resetAll = createSaga(function* resetAll({
  payload,
}: {
  payload: { navigate: NavigateFunction }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const configApi = yield getConfigApi()

  yield call(configApi.request('reset'))
  yield put({ type: configConstants.RESET_ALL_STEPS_SUCCESS })
  payload.navigate(0) // refresh
},
true)

export const fetchCompletenessStatuses = createSaga(function* fetchCompletenessStatuses(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const isLaunchedInstance = yield select(getIsLaunched)
  const configApi = yield getConfigApi()
  const completenessStatuses = yield call(configApi.request('getCompletenessStatuses'))

  const isInstanceStateShouldBeChanged =
    (isLaunchedInstance && some(completenessStatuses, ['status', stepStatus.RESUBMITTED])) ||
    (!isLaunchedInstance && every(completenessStatuses, ['status', stepStatus.LAUNCHED]))

  if (isInstanceStateShouldBeChanged) yield put(fetchConfig())

  yield put({
    type: configConstants.FETCH_COMPLETENESS_STATUSES_SUCCESS,
    payload: completenessStatuses,
  })
},
configConstants.FETCH_COMPLETENESS_STATUSES_STATE)

export const launchConfig = createSaga(function* launchConfig({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { isOnlyConfigAdmin = false } = payload
  const configApi = yield getConfigApi()

  yield call(configApi.request('launchConfig'))

  yield put({ type: configConstants.LAUNCH_CONFIG_SUCCESS })

  if (isOnlyConfigAdmin) {
    payload.navigate('/config/terms-conditions')
    return
  }

  payload.navigate('/dashboard/terms-conditions')
},
true)

export const fetchUserAndLaunch = createSaga(function* fetchUserAndLaunch({ payload }: $TSFixMe) {
  // need to get new user data (user settings can be changed before Launch)
  yield call(fetchUserGenerator)
  yield call(launchConfig, { payload })
})

export function* configWatcher() {
  yield all([
    takeLatest(FETCH_MODULE_OPTIONS_PENDING, fetchModuleOptions),
    takeLatest(BULK_UPDATE_MODULE_OPTIONS, sendBulkUpdateModuleOptions),
    takeLatest(configConstants.SAVE_WELCOME_NOTE, saveWelcomeNote),
    takeLatest(configConstants.GET_WELCOME_NOTE, fetchWelcomeNote),
    takeLatest(configConstants.FETCH_COMPLETENESS_STATUSES, fetchCompletenessStatuses),
    takeLatest(configConstants.RESET_ALL_STEPS, resetAll),
    takeLatest(configConstants.FETCH_USER_AND_LAUNCH, fetchUserAndLaunch),
    takeLatest(configConstants.LAUNCH_CONFIG, launchConfig),
  ])
}

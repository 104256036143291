import React from 'react'
import { useSelector } from 'react-redux'
import { getSelectedImoTeamId } from '@generic/selectors/selectedTeams'
import { getIsLaunched } from '@domain/instanceConfig/selectors'
import InsufficientPermissionsPage from '@shared/InsufficientPermissionsPage'
import { Navigate } from 'react-router-dom'

export const ControlTowerRoot = () => {
  const imoTeamId = useSelector(getSelectedImoTeamId)
  const isLaunchedInstance = useSelector(getIsLaunched)

  if (!isLaunchedInstance) return <InsufficientPermissionsPage />

  if (!imoTeamId) return null

  return <Navigate to={{ pathname: `/imo-control-tower/team/${imoTeamId}/imo-home` }} />
}

import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Checkbox, Colors, InputGroup } from '@blueprintjs/core'
import { GridApi, IDoesFilterPassParams, IFilter, IFilterParams, RowNode } from 'ag-grid-community'
import styled from 'styled-components'
import { TeamKeyProcessProjectOrTask } from '@common/types/dtos/DayOneProjectListDto'
import { ReactifiedSet, useSet } from '@hooks/useSet'

const sortStringsWithBlankOnTop = (a: string, b: string) => {
  if (a === '(Blank)') return -1

  if (a < b) return -1
  else if (a > b) return 1
  else return 0
}

export type OwnerFilterModel = {
  excludedProjects: string[]
  excludedTasks: string[]
  projectOwners: string[]
  taskOwners: string[]
}

type OwnerFilterModelSets = {
  excludedProjects: { has(s: string): boolean }
  excludedTasks: { has(s: string): boolean }
}

export const isValidOwnerModel = (model: unknown): model is OwnerFilterModel => {
  if (!model) return false
  const ps = (model as OwnerFilterModel)?.excludedProjects
  const ts = (model as OwnerFilterModel)?.excludedTasks
  return (
    Array.isArray(ps) &&
    ps.every((x) => typeof x === 'string') &&
    Array.isArray(ts) &&
    ts.every((x) => typeof x === 'string')
  )
}

const SearchForm = styled.form`
  padding: 7px;
`

const UnstyledFieldset = styled.fieldset`
  border: none;
  padding: 7px;
  margin: 0;
`

const FieldsetWithBottomBorder = styled(UnstyledFieldset)`
  border-bottom: 1px solid ${Colors.LIGHT_GRAY3};
  padding-bottom: 0;
  margin-bottom: 7px;
`

const Legend = styled.legend`
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
`

function projectPassesFilter(
  projectRowNode: RowNode<TeamKeyProcessProjectOrTask>,
  filterModel: OwnerFilterModelSets,
): boolean {
  return (
    projectRowNode.data != null &&
    projectRowNode.data.type === 'project' &&
    ((projectRowNode.data.owner?.displayName != null &&
      !filterModel.excludedProjects.has(projectRowNode.data.owner?.displayName)) ||
      (projectRowNode.data.owner?.displayName == null && !filterModel.excludedProjects.has('(Blank)')))
  )
}

function taskPassesFilter(
  taskRowNode: RowNode<TeamKeyProcessProjectOrTask>,
  filterModel: OwnerFilterModelSets,
): boolean {
  if (taskRowNode.data?.type !== 'task') return false

  let owner = ''
  if (taskRowNode.data?.isIncoming && taskRowNode.data?.interdependency?.owner?.displayName) {
    owner = taskRowNode.data.interdependency.owner.displayName
  } else if (taskRowNode.data?.isOutgoing && taskRowNode.data?.owner?.displayName) {
    owner = taskRowNode.data.owner.displayName
  } else if (!taskRowNode.data?.interdependency && taskRowNode.data?.owner?.displayName) {
    owner = taskRowNode.data.owner.displayName
  }

  return (
    taskRowNode.parent != null &&
    projectPassesFilter(taskRowNode.parent, filterModel) &&
    !filterModel.excludedTasks.has(owner) &&
    !filterModel.excludedTasks.has(!owner ? '(Blank)' : '')
  )
}

function getOwnerFilterData(
  gridApi: GridApi<TeamKeyProcessProjectOrTask>,
  doesRowPassOtherFilter: (rowNode: RowNode<TeamKeyProcessProjectOrTask>) => boolean,
): {
  projectOwners: string[]
  taskOwners: string[]
} {
  const uniqueProjectOwners = new Set<string>(['(Blank)'])
  const uniqueTaskOwners = new Set<string>(['(Blank)'])
  gridApi.forEachNode((node) => {
    if (!doesRowPassOtherFilter(node)) return
    if (node.data == null) return

    if (node.data.type === 'project' && node.data.owner?.displayName != null) {
      uniqueProjectOwners.add(node.data.owner.displayName)
    } else if (node.data.type === 'task') {
      const owner = !node.data.isIncoming && node.data.owner?.displayName
      const interdependencyOwner = !node.data.isOutgoing && node.data.interdependency?.owner?.displayName

      if (owner) {
        uniqueTaskOwners.add(owner)
      } else if (interdependencyOwner) {
        uniqueTaskOwners.add(interdependencyOwner)
      }
    }
  })

  const projectOwners = Array.from(uniqueProjectOwners).sort(sortStringsWithBlankOnTop)
  const taskOwners = Array.from(uniqueTaskOwners).sort(sortStringsWithBlankOnTop)

  return { projectOwners, taskOwners }
}

function formatFloatingFilters(
  projectOwners: string[],
  taskOwners: string[],
  excludedProjects: ReactifiedSet<string>,
  excludedTasks: ReactifiedSet<string>,
) {
  const currentProjectOwners = projectOwners.filter((project) => !excludedProjects.has(project))
  const currentTaskOwners = taskOwners.filter((project) => !excludedTasks.has(project))
  const projectFilterString = currentProjectOwners.length ? `[Projects] ${currentProjectOwners.join(', ')}` : ''
  const taskFilterString = currentTaskOwners.length ? `[Tasks] ${currentTaskOwners.join(', ')}` : ''

  return `${projectFilterString}${projectFilterString ? ' - ' : ''}${taskFilterString}`
}

export const CustomOwnerFloatingFilter = forwardRef((_, ref) => {
  const [filterValue, setFilterValue] = useState('')

  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel: OwnerFilterModel) {
        if (!parentModel) {
          setFilterValue('')
        } else {
          setFilterValue(
            formatFloatingFilters(
              parentModel.projectOwners,
              parentModel.taskOwners,
              new Set<string>(parentModel.excludedProjects) as unknown as ReactifiedSet<string>,
              new Set<string>(parentModel.excludedTasks) as unknown as ReactifiedSet<string>,
            ),
          )
        }
      },
    }
  })

  return <input style={{ width: '100%' }} disabled value={filterValue} />
})

export const CustomOwnerFilter = forwardRef<IFilter, IFilterParams<TeamKeyProcessProjectOrTask>>(
  (props, ref): JSX.Element => {
    // Deriving state from props here as this component is expecting to be
    // rendered as an ag grid filter popup ag grid doesn't rerender the filter
    // popups when they are displayed/hidden by default, and we derive `data`
    // from methods on the provided `GridApi` instance, which maintains
    // referential equality between rerenders to force a rerender when the
    // component is displayed, we make use of the `afterGuiAttached` method on
    // the `IFilter` imperative handle that this component exposes (see below).
    const [data, setData] = useState(() => getOwnerFilterData(props.api, props.doesRowPassOtherFilter))

    const [searchString, setSearchString] = useState('')

    const matchesSearchString = (candidate: string) =>
      searchString === '' || candidate.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())

    const [allProjects, setAllProjects] = useState(true)
    const [allTasks, setAllTasks] = useState(true)

    const excludedProjects = useSet<string>()
    const excludedTasks = useSet<string>()

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
      const type = event.currentTarget.name
      const value = event.currentTarget.value
      const checked = event.currentTarget.checked

      if (type === 'project' && checked) {
        excludedProjects.remove(value)
      } else if (type === 'project' && !checked) {
        excludedProjects.add(value)
      } else if (type === 'task' && checked) {
        excludedTasks.remove(value)
      } else if (type === 'task' && !checked) {
        excludedTasks.add(value)
      }
    }

    const allProjectsIsIndeterminate =
      (allProjects && excludedProjects.size > 0) || (!allProjects && !excludedProjects.equals(data.projectOwners))
    const allTasksIsIndeterminate =
      (allTasks && excludedTasks.size > 0) || (!allTasks && !excludedTasks.equals(data.taskOwners))

    const handleAllProjectsChange = (event: ChangeEvent<HTMLInputElement>) => {
      // select all checkbox prefers setting all to true
      const nextStateIsChecked = event.currentTarget.checked || allProjectsIsIndeterminate

      if (nextStateIsChecked) {
        excludedProjects.reset()
      } else {
        excludedProjects.reset(data.projectOwners)
      }
      setAllProjects(nextStateIsChecked)
    }
    const handleAllTasksChange = (event: ChangeEvent<HTMLInputElement>) => {
      // select all checkbox prefers setting all to true
      const nextStateIsChecked = event.currentTarget.checked || allProjectsIsIndeterminate

      if (nextStateIsChecked) {
        excludedTasks.reset()
      } else {
        excludedTasks.reset(data.taskOwners)
      }
      setAllTasks(nextStateIsChecked)
    }

    const filterActive = Boolean(excludedProjects.size || excludedTasks.size)
    // We need to expose an api to ag-grid so that it can manage the filter
    // logic defined here `forwardRef` and `useImperativeHandle` allow that.
    // See comments on the individual methods below for details on what they do
    useImperativeHandle(
      ref,
      () => {
        return {
          /**
           * Called by ag grid per-row to check whether that row passes the
           * filter logic we're managing in this component.
           * This method is the reason we're using a set to store excluded
           * projects/tasks
           */
          doesFilterPass(params: IDoesFilterPassParams<TeamKeyProcessProjectOrTask>) {
            const model = { excludedProjects, excludedTasks }

            return (
              projectPassesFilter(params.node, model) ||
              taskPassesFilter(params.node, model)
            )
          },

          /** Called by ag grid to determine whether or not the filter is active
           * - we store the active-ness in state, as this component will remain
           * rendered (but not displayed) even when the filter popup is not
           * visible (see also the comment at the top of the component
           * explaining derived state.
           */
          isFilterActive() {
            return filterActive
          },

          /**
           * As well as calling `doesFilterPass`, ag-grid can also call this
           * method to get the underlying filter model we're using this is to
           * allow for programmatic reading / setting of filters.
           * Per the ag-grid spec, this method should return `null` when the
           * filter is inactive.
           */
          getModel(): OwnerFilterModel | null {
            if (!filterActive) {
              return null
            }
            return {
              excludedProjects: excludedProjects.toArray(),
              excludedTasks: excludedTasks.toArray(),
              projectOwners: data.projectOwners,
              taskOwners: data.taskOwners,
            }
          },

          /**
           * Used in conjuction with `getModel`, ag-grid will call this method
           * to programmatically manage the filter model we're using in this
           * component.
           * Per the ag-grid spec, if this method is called with `null` as an
           * argument, we should set this filter to inactive.
           */
          setModel(model: unknown) {
            if (model == null) {
              return
            }

            if (!isValidOwnerModel(model)) throw new Error('invalid owner filter model provided')
            excludedProjects.reset(model.excludedProjects)
            excludedTasks.reset(model.excludedTasks)
          },

          /**
           * This method gets called when the filter popup is displayed to the
           * user.
           * Used to re-sync data with the contents of the ag-grid table.
           * See comment at the top of this file explaining the use of derived
           * state for more info.
           */
          afterGuiAttached() {
            setData(getOwnerFilterData(props.api, props.doesRowPassOtherFilter))
          },

          getModelAsString() {
            if (!filterActive) {
              return ''
            }
            return formatFloatingFilters(data.projectOwners, data.taskOwners, excludedProjects, excludedTasks)
          },
        }
      },
      [data, excludedProjects, excludedTasks, filterActive, props.api, props.doesRowPassOtherFilter],
    )

    /**
     * We need to inform ag-grid whenever the filter model changes:
     * here that means when excludedProjects or excludedTasks is mutated.
     */
    useEffect(() => {
      props.filterChangedCallback()
    }, [excludedProjects, excludedTasks])

    return (
      <div>
        <SearchForm onSubmit={(e) => e.preventDefault()}>
          <InputGroup
            placeholder="Search"
            aria-label="Search for user to filter by"
            value={searchString}
            onChange={(e) => setSearchString(e.currentTarget.value)}
            round={false}
            small
          />
        </SearchForm>
        <form onSubmit={(e) => e.preventDefault()}>
          <FieldsetWithBottomBorder>
            <Checkbox
              label="(Select all project owners)"
              type="checkbox"
              checked={allProjects}
              indeterminate={allProjectsIsIndeterminate}
              onChange={handleAllProjectsChange}
            />
            <Checkbox
              label="(Select all task owners)"
              type="checkbox"
              checked={allTasks}
              indeterminate={allTasksIsIndeterminate}
              onChange={handleAllTasksChange}
            />
          </FieldsetWithBottomBorder>

          <UnstyledFieldset>
            <Legend>Projects</Legend>
            {data.projectOwners.filter(matchesSearchString).map((owner) => (
              <Checkbox
                key={owner}
                label={owner}
                name="project"
                type="checkbox"
                value={owner}
                checked={!excludedProjects.has(owner)}
                onChange={handleCheckboxChange}
              />
            ))}
          </UnstyledFieldset>

          <UnstyledFieldset>
            <Legend>Tasks</Legend>
            {data.taskOwners.filter(matchesSearchString).map((owner) => (
              <Checkbox
                key={owner}
                label={owner}
                name="task"
                type="checkbox"
                value={owner}
                checked={!excludedTasks.has(owner)}
                onChange={handleCheckboxChange}
              />
            ))}
          </UnstyledFieldset>
        </form>
      </div>
    )
  },
)

import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'
import { assoc } from 'lodash/fp'
import { filteredLists } from './utils'

export interface IVCTeamSetupState {
  targetList: $TSFixMe[]
  valueCaptureTeams?: $TSFixMe[]
  selectedTargetId?: $TSFixMe | null
  baselineAssumptions: $TSFixMe[]
  synergyCategories: $TSFixMe[]
  oneTimeCosts: $TSFixMe[]
}

export const initialState = {
  targetList: [],
  valueCaptureTeams: [],
  selectedTargetId: null,
  baselineAssumptions: [],
  synergyCategories: [],
  oneTimeCosts: [],
}

const teamSetupReducer = handleActions<IVCTeamSetupState, $TSFixMe>(
  {
    [constants.SET_VC_TEAMS]: (state, action) => {
      return { ...state, valueCaptureTeams: action.payload }
    },

    // Target list
    [constants.FETCH_TARGET_LIST]: (state) => ({
      ...state,
      targetList: [],
      baselineAssumptions: [],
      synergyCategories: [],
      oneTimeCosts: [],
    }),
    [constants.SET_TARGET_LIST]: (state, action) => {
      const { targetList } = action.payload

      return { ...state, targetList }
    },

    [constants.CREATE_TARGET_SUCCESS]: (state, action) => {
      const { data } = action.payload

      return {
        ...state,
        targetList: [...state.targetList, data],
      }
    },

    [constants.UPDATE_TARGET_SUCCESS]: (state, action) => {
      const { id, data } = action.payload
      const { targetList } = state

      const updatedTargets = targetList.map((target) => {
        if (target.id === id) {
          return { ...target, ...data }
        }

        return target
      })

      return {
        ...state,
        targetList: updatedTargets,
      }
    },

    [constants.DELETE_TARGET_SUCCESS]: (state, action) => {
      const { id } = action.payload
      const { targetList } = state

      const filteredTargets = targetList.filter((target) => target.id !== id)

      return {
        ...state,
        targetList: filteredTargets,
      }
    },

    [constants.SET_SELECTED_TARGET]: (state, action) => {
      return { ...state, selectedTarget: action.payload }
    },

    //baseline-assumptions
    [constants.SET_VC_BASELINE_ASSUMPTIONS]: (state, action) => {
      const { data } = action.payload

      return assoc('baselineAssumptions', data, state)
    },

    [constants.UPDATE_VC_BASELINE_ASSUMPTIONS_SUCCESS]: (state, action) => {
      const { id, data } = action.payload
      const { baselineAssumptions } = state

      const updatedAssumptions = baselineAssumptions.map((assumption) => {
        if (assumption.id === id) {
          return { ...assumption, ...data }
        }

        return assumption
      })

      return assoc('baselineAssumptions', updatedAssumptions, state)
    },

    //synergy-categories
    [constants.SET_VC_SYNERGY_CATEGORY]: (state, action) => {
      const { data } = action.payload

      return assoc('synergyCategories', data, state)
    },

    [constants.CREATE_VC_SYNERGY_CATEGORY_SUCCESS]: (state, action) => {
      const { data } = action.payload

      return {
        ...state,
        synergyCategories: [...state.synergyCategories, data],
      }
    },

    [constants.UPDATE_VC_SYNERGY_CATEGORY_SUCCESS]: (state, action) => {
      const { id, data } = action.payload
      const { synergyCategories } = state
      //TODO move to utils and reuse below in similar cases
      const updated = synergyCategories.map((cat) => {
        if (cat.id === id) {
          return data
        }

        return cat
      })

      return assoc('synergyCategories', updated, state)
    },

    [constants.DELETE_VC_SYNERGY_CATEGORY_SUCCESS]: (state, action) => {
      const { id } = action.payload
      const { synergyCategories } = state

      const filteredCategories = filteredLists(synergyCategories, id)

      return assoc('synergyCategories', filteredCategories, state)
    },

    //one-time costs
    [constants.SET_VC_ONE_TIME_COSTS]: (state, action) => {
      const { data } = action.payload

      return assoc('oneTimeCosts', data, state)
    },

    [constants.CREATE_VC_ONE_TIME_COSTS_SUCCESS]: (state, action) => {
      const { data } = action.payload

      return {
        ...state,
        oneTimeCosts: [...state.oneTimeCosts, data],
      }
    },

    [constants.UPDATE_VC_ONE_TIME_COSTS_SUCCESS]: (state, action) => {
      const { id, data } = action.payload
      const { oneTimeCosts } = state

      const updated = oneTimeCosts.map((cat) => {
        if (cat.id === id) {
          return data
        }

        return cat
      })

      return assoc('oneTimeCosts', updated, state)
    },

    [constants.DELETE_VC_ONE_TIME_COSTS_SUCCESS]: (state, action) => {
      const { id } = action.payload
      const { oneTimeCosts } = state

      const filteredCategories = filteredLists(oneTimeCosts, id)

      return assoc('oneTimeCosts', filteredCategories, state)
    },
  },
  initialState,
)

export default teamSetupReducer

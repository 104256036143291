import { all, call, put } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { setTeamCharterData } from '../actions/actions'
import { fetchKeyRiskToMitigate } from './keyRiskToMitigate'
import { fetchKeyDecisionToMake } from './keyDecisionToMake'
import { fetchInScopeProcesses } from './inScopeKeyProcess'
import { fetchOutScopeProcesses } from './outScopeKeyProcess'
import { fetchKeyInterdependencies } from './keyInterdependency'
import { fetchMacroPlanningAssumption } from './macroPlanningAssumption'
import { getTeamCharterApi } from '@common/net'
import { fetchTeamMembers } from '@teamCharter/sagas/teamMembers'

export const fetchTeamCharterData = createSaga(function* fetchTeamCharterData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, date } = action.payload

  yield all([
    call(fetchTeamMembers, { teamId, date }),
    call(fetchInScopeProcesses, { teamId, date }),
    call(fetchOutScopeProcesses, { teamId, date }),
    call(fetchMacroPlanningAssumption, { teamId, date }),
    call(fetchKeyInterdependencies, { teamId, date }),
    call(fetchKeyRiskToMitigate, { teamId, date }),
    call(fetchKeyDecisionToMake, { teamId, date }),
  ])

  const teamCharterApi = yield getTeamCharterApi()
  const data = yield call(teamCharterApi.request('getTeamCharterData', { query: { teamId, date } }))

  yield put(setTeamCharterData(data))
},
true)

import * as HttpStatus from 'http-status-codes'

export const httpStatusesHandler = (response) => {
  const { statusCode, message } = response

  switch (statusCode) {
    case 422:
      return { message, fromServer: true }
    case 400:
    case 403:
    case 404:
      return { message, fromServer: true }
    default:
      return {
        message: !!statusCode ? HttpStatus.getStatusText(statusCode) : message,
        fromServer: false,
      }
  }
}

import { Colors } from '@blueprintjs/core'

//Set of blueprint colors to use for active users
export const ACTIVE_USER_COLORS = [
  Colors.LIME3,
  Colors.FOREST3,
  Colors.TURQUOISE3,
  Colors.CERULEAN3,
  Colors.INDIGO3,
  Colors.VIOLET3,
  Colors.ROSE3,
  Colors.VERMILION3,
  Colors.SEPIA3,
  Colors.GOLD3,
] as const

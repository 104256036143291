import { handleActions } from 'redux-actions'
import * as constants from '@teamResources/scopeTeamMembers/actions/actionTypes'

export interface IScopeTeamMembersState {
  scopeTeamMembersData: $TSFixMe
  locked: boolean
}

export const initialState = {
  scopeTeamMembersData: {},
  locked: false,
}

const scopeTeamMembersReducer = handleActions<IScopeTeamMembersState, $TSFixMe>(
  {
    [constants.SET_SCOPE_TEAM_MEMBERS]: (state, action) => {
      const { teamCharterScopeData, locked } = action.payload

      return {
        ...state,
        scopeTeamMembersData: teamCharterScopeData,
        locked,
      }
    },
  },
  initialState,
)

export default scopeTeamMembersReducer

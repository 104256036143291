import { coreModule, managementTypes } from '@helpers/constants'

export const VISIBLE_ORDERED_MODULES = {
  ASK_MYIMO: coreModule.ASK_MYIMO,
  IMO_DELIVERABLES_PANEL: coreModule.IMO_DELIVERABLES_PANEL,
  MUTUAL_DISCOVERY: coreModule.MUTUAL_DISCOVERY,
  DOCUMENT_SHARING: coreModule.DOCUMENT_SHARING,
  VALUE_CAPTURE: coreModule.VALUE_CAPTURE,
  ORG_DESIGN: coreModule.ORG_DESIGN,
  COMMUNICATIONS: coreModule.COMMUNICATIONS,
  DATA_REQUEST_TRACKER: coreModule.DATA_REQUEST_TRACKER,
  DAY1_HYPERCARE_LOG: coreModule.DAY1_HYPERCARE_LOG,
  TEAM_CALENDAR: coreModule.TEAM_CALENDAR,
  NOTIFICATIONS: coreModule.NOTIFICATIONS,
  TEAM_RESOURCES: coreModule.TEAM_RESOURCES,
  MCKINSEY_LIBRARY: coreModule.MCKINSEY_LIBRARY,
  FILE_ATTACHMENTS: coreModule.FILE_ATTACHMENTS,
  KNOWLEDGE_CENTER: coreModule.KNOWLEDGE_CENTER,
  WEEKLY_STATUS_UPDATE: coreModule.WEEKLY_STATUS_UPDATE,
  EXECUTIVE_DASHBOARD: coreModule.EXECUTIVE_DASHBOARD,
}

const titleMutualDiscovery = {
  entanglements: 'Entanglements',
  entanglementsTooltip: 'Enable to show the Entanglement column group in the deliverable by default',
  mutualDiscovery: 'Mutual Discovery',
  mutualDiscoveryTooltip: 'Enable to show the Mutual Discovery column group in the MDPA deliverable by default',
}

const titleEnableCoreComponents = (managementType: string) => {
  if (managementTypes.smo === managementType) {
    return titleMutualDiscovery.entanglements
  }

  return titleMutualDiscovery.mutualDiscovery
}

const tooltipEnableCoreComponents = (managementType: string) => {
  if (managementTypes.smo === managementType) {
    return titleMutualDiscovery.entanglementsTooltip
  }

  return titleMutualDiscovery.mutualDiscoveryTooltip
}

export const getCoreComponentsDescription = (managementType: string) => {
  return {
    [VISIBLE_ORDERED_MODULES.MUTUAL_DISCOVERY]: {
      title: `${titleEnableCoreComponents(managementType)}`,
      tooltip: `${tooltipEnableCoreComponents(managementType)}`,
    },
    [VISIBLE_ORDERED_MODULES.DOCUMENT_SHARING]: {
      title: 'Document Sharing',
      tooltip: 'Enable the Document Sharing module on the Team Resources page',
    },
    [VISIBLE_ORDERED_MODULES.VALUE_CAPTURE]: {
      title: 'Value Capture',
      tooltip:
        'Enable the Value Capture Tracker module in the left sidebar navigation menu and the Team Value Capture module on the My Team page',
    },
    [VISIBLE_ORDERED_MODULES.ORG_DESIGN]: {
      title: 'Org Design',
      tooltip:
        'Enable the Org Design Tracker module in the left sidebar navigation menu and the Team Org Design module on the My Team page',
    },
    [VISIBLE_ORDERED_MODULES.COMMUNICATIONS]: {
      title: 'Communications Calendar',
      tooltip: 'Enable the Communications Calendar module in the left sidebar navigation menu',
    },
    [VISIBLE_ORDERED_MODULES.DATA_REQUEST_TRACKER]: {
      title: 'Data Request Tracker',
      tooltip: 'Enable the Data Request Tracker module on the Team Resources page',
    },
    [VISIBLE_ORDERED_MODULES.DAY1_HYPERCARE_LOG]: {
      title: 'Day 1 Hypercare',
      tooltip: `Enable the Day 1 Hypercare module on the My Team and ${managementType} Control Tower pages`,
    },
    [VISIBLE_ORDERED_MODULES.TEAM_CALENDAR]: {
      title: 'Team Calendar',
      tooltip: 'Enable the Team Calendar module on the Team Resources page',
    },
    [VISIBLE_ORDERED_MODULES.NOTIFICATIONS]: {
      title: 'Notifications',
      tooltip: `Enable the Notifications and Activity log module on the My Team page`,
    },
    [VISIBLE_ORDERED_MODULES.TEAM_RESOURCES]: {
      title: 'Team Resources',
      tooltip: 'Enable the Team Resources module in the left sidebar navigation menu',
    },
    [VISIBLE_ORDERED_MODULES.MCKINSEY_LIBRARY]: {
      title: 'McKinsey Library',
      tooltip: `Turning this off will disable all areas for all workstreams where McKinsey Library inputs are visible, including the ability to search through other McKinsey Libraries that are not assigned to your workstream, and the ability to download McKinsey Guidance documents. Note: it does not prevent the functioning of Team Charter or Day 1 Scenario Planning content as it relates to the linked libraries selected on step 4 of the configuration.`,
    },
    [VISIBLE_ORDERED_MODULES.FILE_ATTACHMENTS]: {
      title: 'File Upload',
      tooltip:
        'Switching this off will disable all file attachment and upload ability for all areas of the application, globally',
    },
    [VISIBLE_ORDERED_MODULES.KNOWLEDGE_CENTER]: {
      title: 'Knowledge Center',
      tooltip:
        'This will unhide the Knowledge Center module for all workstreams. Please note that there is a separate on/off switch on the Knowledge Center tabs for each individual workstream that must be switched on to fully enable this feature for that workstream.',
    },
    [VISIBLE_ORDERED_MODULES.WEEKLY_STATUS_UPDATE]: {
      title: 'Weekly Status Update',
      tooltip:
        'Enable the Weekly Status Update module on the My Team and IMO Control Tower pages (does not affect the Value Capture Status Update page)',
    },
    [coreModule.AIRTABLE_INTEGRATION]: {
      title: 'Enable Airtable integration',
    },
    [coreModule.EXECUTIVE_DASHBOARD]: {
      title: 'Executive Dashboard',
      tooltip:
        'Enables the Executive Dashboard where IMO users can navigate data from all domains and teams via customisable charts and a grid view',
    },
    [coreModule.ASK_MYIMO]: {
      title: 'Ask myIMO',
      tooltip:
        'Ask myIMO is our proprietary GenAI powered coach that serves as your personal M&A trainer based on McKinsey best practices.',
    },
    [VISIBLE_ORDERED_MODULES.IMO_DELIVERABLES_PANEL]: {
      title: `${managementType} Deliverables`,
      tooltip: `Switching this off will hide the ${managementType} Deliverables section.`,
    },
  }
}

export const pickOrderedModules = (modules: $TSFixMe) => {
  if (modules.length === 0) return []

  return Object.values(VISIBLE_ORDERED_MODULES)
    .filter((key) => modules.find((item: $TSFixMe) => item.key === key))
    .map((key: string) => {
      return modules.find((item: $TSFixMe) => item.key === key)
    })
}

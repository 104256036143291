import { combineActions, handleActions } from 'redux-actions'
import { updateData } from '../../decisionLog/utils'
import {
  FETCH_RISK_LOG_DATA_FAIL,
  FETCH_RISK_LOG_DATA_PENDING,
  FETCH_RISK_LOG_DATA_SUCCESS,
  FETCH_TR_RISK_LOG_DATA,
  ADD_RISK_LOG_DATA,
  ADD_RISK_LOG_DATA_SUCCESS,
  DELETE_RISK_LOG_DATA_SUCCESS,
  UPDATE_RISK_LOG_DATA_FAIL,
  UPDATE_RISK_LOG_DATA_SUCCESS,
  FETCH_CONTROL_TOWER_RISK_LOG_DATA,
  UPDATE_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS,
  FETCH_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS,
} from '../actions/actionTypes'

interface RiskLog {
  id: number
  teamId?: number
  status?: string
  topic?: string
  description?: string | null
  mitigationActions?: string | null
  confidential?: boolean | null
  probability?: number | null
  isVisibleToIMO?: boolean | null
  potentialImpact?: string | null
}

export interface IRiskLogState {
  loading: boolean
  createdAt: Date | null
  items: RiskLog[]
}

export const initialState: IRiskLogState = {
  loading: false,
  createdAt: null,
  items: [],
}

const riskLogReducer = handleActions<IRiskLogState, $TSFixMe>(
  {
    [`${combineActions(FETCH_RISK_LOG_DATA_PENDING, FETCH_TR_RISK_LOG_DATA, FETCH_CONTROL_TOWER_RISK_LOG_DATA)}`]: (
      state,
    ) => ({
      ...state,
      loading: true,
    }),
    [`${combineActions(FETCH_RISK_LOG_DATA_SUCCESS, FETCH_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS)}`]: (
      state,
      { payload },
    ) => ({
      ...state,
      loading: false,
      createdAt: payload.data.createdAt,
      items: payload.data.items,
    }),
    [`${combineActions(UPDATE_RISK_LOG_DATA_SUCCESS, UPDATE_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS)}`]: (
      state,
      { payload: { id, body, sortOrder } },
    ) => ({
      ...state,
      items: updateData({ data: state.items, id, body, sortOrder }),
      loading: false,
    }),
    [`${combineActions(FETCH_RISK_LOG_DATA_FAIL, UPDATE_RISK_LOG_DATA_FAIL)}`]: (state) => ({
      ...state,
      loading: false,
    }),
    [ADD_RISK_LOG_DATA]: (state, { payload }) => {
      const { items } = state
      return {
        ...state,
        items: [...items, payload],
      }
    },
    [ADD_RISK_LOG_DATA_SUCCESS]: (state, { payload: { body, sortOrder } }) => {
      return {
        ...state,
        items: updateData({ data: [...state.items, body], id: body.id, sortOrder }) as unknown as RiskLog[],
      }
    },
    [DELETE_RISK_LOG_DATA_SUCCESS]: (state, { payload: riskId }) => {
      const { items } = state

      return {
        ...state,
        items: items.filter((item: RiskLog) => item.id !== riskId),
      }
    },
  },
  initialState,
)

export default riskLogReducer

import styled from 'styled-components'
import { get } from 'lodash'
import { Icon } from '@blueprintjs/core'
import { FONT_FAMILY, greyForBtn, midGrey } from '@imo/imo-ui-toolkit'

export const StyledNoResults = styled.div`
  color: ${midGrey};
  font: 12px ${FONT_FAMILY};
  line-height: 16px;
  display: flex;
  height: ${(props) => get(props, ['style', 'height'], '100%')};
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const StyledNoResultsContent = styled.div`
  text-align: center;

  .bp4-icon {
    margin-bottom: 15px;
  }
`

export const NoResultsWrapper = styled.div`
  width: 410px;
  margin: 0 auto;
  text-align: center;
`

export const NoResultsIcon = styled(Icon)`
  margin-bottom: 10px;
  color: #bfccd6;
`

export const NoResultsTitle = styled.div`
  font: 20px ${FONT_FAMILY};
  font-weight: 500;
  color: ${greyForBtn};
  margin-bottom: 15px;
`

export const Description = styled.div`
  font-family: ${FONT_FAMILY};
  font-size: 13px;
  line-height: 1.38;
  color: #10161a;
`

export const BoldText = styled.span`
  font-weight: bold;
`

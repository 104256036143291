const prefix = '@customDeliverable/'

export const FETCH_ATTACHMENTS = `${prefix}FETCH_ATTACHMENTS`
export const SET_ATTACHMENTS = `${prefix}SET_ATTACHMENTS`

export const CREATE_ATTACHMENT = `${prefix}CREATE_ATTACHMENT`
export const DELETE_ATTACHMENT = `${prefix}DELETE_ATTACHMENT`

export const SET_CUSTOM_DELIVERABLE_STATUS = `${prefix}SET_CUSTOM_DELIVERABLE_STATUS`

export const SET_ONE_DAY_DELIVERABLE_STATUS = `${prefix}SET_ONE_DAY_DELIVERABLE_STATUS`
export const SET_ONE_DAY_COPY_DELIVERABLE_STATUS = `${prefix}SET_ONE_DAY_COPY_DELIVERABLE_STATUS`

import { midfetch } from '@nvt/mid-react'
import UserManagementApi from './myImoConfig/UserManagementApi'
import ConfigApi from './myImoConfig/ConfigApi'
import DeliverableFormApi from './myImoConfig/DeliverableFormApi'
import DeliverableTypeApi from './myImoConfig/DeliverableTypeApi'
import TaxonomyApi from './myImoConfig/TaxonomyApi'
import TeamDeliverablesApi from './myImoConfig/TeamDeliverablesApi'
import TeamHierarchyApi from './myImoConfig/TeamHierarchyApi'
import TeamSetupApi from './myImoConfig/TeamSetupApi'

export const getUserManagementApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new UserManagementApi(yield midfetch())
}

export const getConfigApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new ConfigApi(yield midfetch())
}

export const getDeliverableFormApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new DeliverableFormApi(yield midfetch())
}

export const getDeliverableTypeApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new DeliverableTypeApi(yield midfetch())
}

export const getTaxonomyApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TaxonomyApi(yield midfetch())
}

export const getTeamDeliverablesApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TeamDeliverablesApi(yield midfetch())
}

export const teamHierarchyApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TeamHierarchyApi(yield midfetch())
}

export const getTeamSetupApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TeamSetupApi(yield midfetch())
}

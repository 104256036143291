const prefix = '@tvc/userInitiativeFilter/'

export const FETCH_USER_INITIATIVE_FILTER_AND_INITIATIVES = `${prefix}FETCH_USER_INITIATIVE_FILTER_AND_INITIATIVES`
export const FETCH_USER_INITIATIVE_FILTER_AND_INFINITY_LIST = `${prefix}FETCH_USER_INITIATIVE_FILTER_AND_INFINITY_LIST`
export const SET_USER_INITIATIVE_FILTER = `${prefix}SET_USER_INITIATIVE_FILTER`
export const FLUSH_TABLE_FILTER = `${prefix}FLUSH_TABLE_FILTER`
export const SET_SELECTED_USER_INITIATIVE_FILTER = `${prefix}SET_SELECTED_USER_INITIATIVE_FILTER`
export const ADD_USER_INITIATIVE_FILTER = `${prefix}ADD_USER_INITIATIVE_FILTER`
export const SET_TABLE_FILTERS = `${prefix}SET_TABLE_FILTERS`
export const CHANGE_USER_INITIATIVE_FILTER = `${prefix}CHANGE_USER_INITIATIVE_FILTER`
export const DELETE_USER_INITIATIVE_FILTER = `${prefix}DELETE_USER_INITIATIVE_FILTER`
export const SET_INITIATIVE_LIST_FILTER_STATE = `${prefix}SET_INITIATIVE_LIST_FILTER_STATE`
export const SET_IS_REDIRECT = `${prefix}SET_IS_REDIRECT`

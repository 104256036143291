import { put, call } from 'redux-saga/effects'
import {
  fetchTeamResourcesUpdates as fetchResourcesAction,
  setTeamResourcesUpdates,
} from '@teamResources/resourcesHome/actions/actions'
import { getTeamResourcesApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { get } from 'lodash'

export const fetchTeamResourcesUpdates = createSaga(function* fetchTeamResourcesUpdates(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const api = yield getTeamResourcesApi()
  const data = yield call(api.request('getTeamResourcesUpdates'))

  yield put(setTeamResourcesUpdates(get(data, 'value', '')))
})

export const updateTeamResourcesUpdates = createSaga(function* updateTeamResourcesUpdates(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { value } = action.payload

  const api = yield getTeamResourcesApi()
  yield call(
    api.request('updateTeamResourcesUpdates', {
      body: {
        value,
      },
    }),
  )

  yield put(fetchResourcesAction())
})

import { handleActions } from 'redux-actions'
import { find } from 'lodash'
import * as constants from '@teamResources/resourcesHome/actions/actionTypes'

export interface IResourcesHomeState {
  updates?: string
  events: $TSFixMe[]
  labels: $TSFixMe[]
  documentSharing: $TSFixMe[]
}

export const initialState = {
  updates: '',
  events: [],
  labels: [],
  documentSharing: [],
}

const resourcesHomeReducer = handleActions<IResourcesHomeState, $TSFixMe>(
  {
    [constants.SET_TEAM_RESOURCES_UPDATES]: (state, action) => ({ ...state, updates: action.payload }),

    [constants.SET_MC_EVENTS]: (state, action) => ({
      ...state,
      events: action.payload,
    }),

    [constants.CREATE_MC_EVENT_SUCCESS]: (state, action) => {
      const { data } = action.payload

      return {
        ...state,
        events: [...state.events, data.labelId ? { ...data, label: find(state.labels, { id: data.labelId }) } : data],
      }
    },

    [constants.UPDATE_MC_EVENT_SUCCESS]: (state, action) => {
      const { id, data } = action.payload

      return {
        ...state,
        events: state.events.map((event) =>
          event.id === id
            ? { ...event, ...data, label: data.labelId && find(state.labels, { id: data.labelId }) }
            : event,
        ),
      }
    },

    [constants.DELETE_MC_EVENT_SUCCESS]: (state, { payload }) => ({
      ...state,
      events: state.events.filter((event) => event.id !== payload.id),
    }),

    [constants.SET_MC_LABELS]: (state, action) => ({
      ...state,
      labels: [...action.payload],
    }),

    [constants.CREATE_MC_LABEL_SUCCESS]: (state, action) => {
      const { data } = action.payload

      return {
        ...state,
        labels: [...state.labels, data],
      }
    },

    [constants.UPDATE_MC_LABEL_SUCCESS]: (state, action) => {
      const { id, data, visibilityChange } = action.payload

      return {
        ...state,
        labels: state.labels.map((label) => (label.id === id ? { ...label, ...data } : label)),
        ...(!visibilityChange
          ? {
              events: state.events.map(({ label, ...event }) => ({
                ...event,
                label: event.labelId === id ? { ...label, ...data } : label,
              })),
            }
          : {}),
      }
    },

    [constants.DELETE_MC_LABEL_SUCCESS]: (state, { payload }) => ({
      ...state,
      labels: state.labels.filter((label) => label.id !== payload.id),
      events: state.events.map((event) =>
        event.labelId === payload.id ? { ...event, labelId: null, label: null } : event,
      ),
    }),

    [constants.SET_DOCUMENT_SHARING]: (state, { payload }) => ({
      ...state,
      documentSharing: payload,
    }),
  },
  initialState,
)

export default resourcesHomeReducer

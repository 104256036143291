const prefix = '@communications/'

export const FETCH_COMMUNICATIONS_DATA = `${prefix}FETCH_COMMUNICATIONS_DATA`
export const SET_COMMUNICATIONS_DATA = `${prefix}SET_COMMUNICATIONS_DATA`

export const FETCH_COLUMNS_DATA = `${prefix}FETCH_COLUMNS_DATA`
export const SET_COLUMNS_DATA = `${prefix}SET_COLUMNS_DATA`
export const UPDATE_COLUMNS_DATA = `${prefix}UPDATE_COLUMNS_DATA`
export const DELETE_COLUMNS_DATA = `${prefix}DELETE_COLUMNS_DATA`

export const SAVE_ROW = `${prefix}SAVE_ROW`
export const UPDATE_ROW = `${prefix}UPDATE_ROW`
export const DELETE_ROWS = `${prefix}DELETE_ROWS`
export const DELETE_ROW = `${prefix}DELETE_ROW`
export const DELETE_ROWS_SUCCESS = `${prefix}DELETE_ROWS_SUCCESS`

export const CREATE_REQUEST = `${prefix}CREATE_REQUEST`
export const FETCH_REQUESTS = `${prefix}FETCH_REQUESTS`
export const SET_REQUESTS = `${prefix}SET_REQUESTS`
export const UPDATE_REQUEST = `${prefix}REQUEST`
export const DELETE_REQUEST = `${prefix}DELETE_REQUEST`

export const SAVE_EVENT = `${prefix}SAVE_EVENT`
export const FETCH_EVENT = `${prefix}FETCH_EVENT`
export const SET_EVENT = `${prefix}SET_EVENT`
export const UPDATE_EVENT = `${prefix}UPDATE_EVENT`
export const DELETE_EVENTS = `${prefix}DELETE_EVENTS`

export const UPLOAD_ATTACHMENT = `${prefix}UPLOAD_ATTACHMENT`
export const DELETE_ATTACHMENT = `${prefix}DELETE_ATTACHMENT`

import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import P from 'prop-types'
import { get } from 'lodash/fp'
import SelectLookup from '@shared/Lookup/SelectLookup'
import { SimpleItemRenderer } from '@shared/Lookup/ItemRenderer'

const LookupCellEditor = forwardRef(
  ({ value, options, labelAccessor, shouldCreateFromQuery, stopEditing, canClearSelection }, ref) => {
    const getLabel = get(labelAccessor)

    const [selectedValue, setSelectedValue] = useState(value)

    // return the final value to the grid, the result of the editing
    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return selectedValue
        },
        getReactContainerStyle: () => {
          return { height: '100%' }
        },
      }
    })

    // Need to compare previous value with selected one
    useEffect(() => {
      if (value !== selectedValue) {
        stopEditing()
      }
    }, [selectedValue])

    const handleSelect = (item) => {
      setSelectedValue(item)
    }

    const handleClearValue = () => {
      setSelectedValue(null)
    }

    const checkIfSelected = (item) => selectedValue && getLabel(selectedValue) === getLabel(item)

    const filterItems = (query, item, _index, exactMatch) => {
      const normalizedName = getLabel(item).toLowerCase()
      const normalizedQuery = query.toLowerCase()

      if (exactMatch) {
        return normalizedName === normalizedQuery
      } else {
        return normalizedName.includes(normalizedQuery)
      }
    }

    const createItem = shouldCreateFromQuery
      ? (value) => ({
          [labelAccessor]: value,
          id: null,
        })
      : null

    return (
      <SelectLookup
        isSelected={checkIfSelected}
        itemsList={options}
        isMinimal
        isCellEditorComponent
        selectedValue={selectedValue}
        activeItemText={getLabel(selectedValue) || ''}
        filterItems={filterItems}
        handleSelectValue={handleSelect}
        handleDeselectValue={canClearSelection ? handleClearValue : null}
        createNewItem={createItem}
        itemRenderer={(isSelected) => (item, itemProps) =>
          (
            <SimpleItemRenderer
              item={item}
              itemProps={itemProps}
              isSelected={isSelected}
              key={`item-${item.id}`}
              getText={getLabel}
            />
          )}
      />
    )
  },
)

LookupCellEditor.defaultProps = {
  labelAccessor: 'name',
  shouldCreateFromQuery: false,
  value: null,
  options: [],
  canClearSelection: true,
}

LookupCellEditor.propTypes = {
  labelAccessor: P.string,
  shouldCreateFromQuery: P.bool,
  canClearSelection: P.bool,
  value: P.object,
  options: P.array,
  stopEditing: P.func.isRequired,
}

export default LookupCellEditor

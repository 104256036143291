import React from 'react'
import { CustomTooltip } from '@shared/Popover'
import { StyledTabLink, DisabledLink } from './TabLink.styles'
import { Placement } from '@blueprintjs/popover2'
import { NavLinkProps } from 'react-router-dom'

interface Props {
  to: NavLinkProps['to']
  title: string
  tooltipContent?: React.ReactElement | string
  icon?: React.ReactElement | null
  disabled?: boolean
  withIcon?: boolean
  id?: string
  tooltipPlacement?: Placement
  className?: string
}

const TabLink = ({ to, title, icon, disabled, tooltipContent, id, tooltipPlacement, withIcon, className }: Props) => {
  if (tooltipContent && disabled) {
    return (
      <DisabledLink id={id} className={className} role="menuitem">
        <CustomTooltip content={tooltipContent} className="tooltip-container" placement={tooltipPlacement}>
          <>
            {icon}
            <span className="link-text">{title}</span>
          </>
        </CustomTooltip>
      </DisabledLink>
    )
  }

  if (disabled) {
    return (
      <DisabledLink id={id} className={className} role="menuitem">
        {icon}
        <span className="link-text">{title}</span>
      </DisabledLink>
    )
  }

  return (
    <StyledTabLink id={id} to={to} $withIcon={withIcon} className={className} role="menuitem">
      {title}
    </StyledTabLink>
  )
}

TabLink.defaultProps = {
  icon: null,
  disabled: false,
  withIcon: false,
  tooltipContent: null,
  id: null,
  tooltipPlacement: 'auto',
  className: '',
}

export default TabLink

import { isEqual } from 'lodash'
import { get, isEmpty, isMatch, isObject, pick } from 'lodash/fp'
import { processItemFields } from '@common/accessController/strategies/deliverables/constants'
import { call, select } from 'redux-saga/effects'
import { getUserTeamRelationsInfo } from '@generic/selectors/permissions'
import { DAY_ONE_ID } from '@helpers/constants'
import { teams } from '@common/accessController/constants'
import { TEAM_MEMBER_ROLES } from '@generic/selectors/teamConstants'
import { listItemTypes } from '@shared/DayOne/constants'
import { getGroupedTeamRoles } from '@generic/selectors/getters'
import { ACTION_TYPES } from '@imo/imo-ui-toolkit'

export const refreshPageDialogParams = {
  actionType: ACTION_TYPES.WARNING,
  errorMessage: 'A new version of this page is available. Would you like to refresh the page?',
  additionalAction: {
    text: 'Refresh',
    icon: 'updated',
    onClick: () => window.location.reload(),
  },
  timeout: 0,
}

export const pickOwners = pick([processItemFields.OWNER, processItemFields.OTHER_TEAM_MEMBERS])

export const checkIsOwnersChanged = (oldData: $TSFixMe, newData: $TSFixMe) =>
  !isEqual(pickOwners(oldData), pickOwners(newData))

export const checkIsUserOnlyMember = function* ({ teamId }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const userTeamRelationsInfo = yield select(getUserTeamRelationsInfo)
  // @ts-expect-error check interface
  const { groupedRoles } = getGroupedTeamRoles({
    userTeamRelationsInfo,
    relationParams: { teamId, deliverableId: DAY_ONE_ID },
  })

  return groupedRoles[teams.REPORTER].every(
    ({ role }: $TSFixMe) => role && TEAM_MEMBER_ROLES.includes(role?.toLowerCase()),
  )
}

export const shouldRefreshAfterUpdate = function* (
  data: $TSFixMe,
  oldData: $TSFixMe,
  isMirroredInterdependency: boolean,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  /*
  IMO-2950
   if user changes confidential project/task,
   after assigning the item won’t be visible to them anymore,
   automatically refresh the page and hide not visible items
  */
  //If user is one of TEAM_MEMBER_ROLES he sees not all processes
  const notAllProcessShown = yield checkIsUserOnlyMember({ teamId: data.teamId })

  const isOwnersChanged = checkIsOwnersChanged(oldData, data)

  const isNameChanged = oldData?.name !== data?.name
  const isStatusChanged = oldData?.status !== data?.status
  const isIncomingInterdependency = oldData?.isIncoming
  const isOutgoingWithInterdependency = oldData?.isOutgoing && !isEmpty(data.interdependency)

  if (
    [
      isNameChanged,
      isIncomingInterdependency,
      isOutgoingWithInterdependency,
      notAllProcessShown,
      isStatusChanged,
      isMirroredInterdependency,
    ].includes(true)
  ) {
    return true
  }

  return notAllProcessShown && isOwnersChanged && data.confidential
}

export const updatePredecessorOrFollowerChecker = (oldData: $TSFixMe, newData: $TSFixMe) => {
  const getFollower = get(processItemFields.FOLLOWER)

  if (!isEqual(getFollower(oldData), getFollower(newData))) return processItemFields.FOLLOWER

  const getPredecessor = get(processItemFields.PREDECESSOR)

  if (!isEqual(getPredecessor(oldData), getPredecessor(newData))) return processItemFields.PREDECESSOR

  return null
}

export const shouldRefreshAfterInterdependencyUpdate = function* (
  newInterdependencyData: $TSFixMe,
  oldItemData: $TSFixMe,
  teamId: $TSFixMe,
  isMirroredInterdependency: boolean,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const interdependencyOldData = oldItemData?.interdependency

  if (!isObject(interdependencyOldData)) return

  const interdependencyNewData = { ...interdependencyOldData, ...newInterdependencyData, teamId }

  if (oldItemData?.confidential) interdependencyNewData.confidential = true

  return yield call(shouldRefreshAfterUpdate, interdependencyNewData, interdependencyOldData, isMirroredInterdependency)
}

export const formatItemSelection = (item: $TSFixMe) => ({ ...item, disabled: item.selected })

const findSelected = ({ source, dataToCompare }: $TSFixMe) =>
  source.find((item: $TSFixMe) => isMatch(dataToCompare, item))

const isSelectedProcess = ({ libraryItem, clientItems, teamId }: $TSFixMe) => {
  const { id, type, name } = libraryItem
  const defaultDataToCompare = { type, teamId, name }
  const isTask = type === listItemTypes.TASK
  const isProject = type === listItemTypes.PROJECT
  const idFieldNameByType = isTask ? 'taskId' : isProject ? 'projectId' : 'keyProcessId'

  return findSelected({
    source: clientItems,
    dataToCompare: { ...defaultDataToCompare, [idFieldNameByType]: id },
  })
}

export const setSelectedItems = ({ libraryItems, clientItems, isInitial = false, teamId }: $TSFixMe) => {
  const decoratedLibraryItems = libraryItems?.map((libraryItem: $TSFixMe) => {
    return {
      ...libraryItem,
      teamId,
      libraryFunctionId: clientItems.find((item: $TSFixMe) => item.name === libraryItem.name)?.libraryFunctionId,
      keyProcessName:
        libraryItem.keyProcessId && libraryItems.find((item: $TSFixMe) => item.id === libraryItem.keyProcessId)?.name,
      projectName:
        libraryItem.projectId && libraryItems.find((item: $TSFixMe) => item.id === libraryItem.projectId)?.name,
    }
  })

  return (
    decoratedLibraryItems?.map((item: $TSFixMe) => {
      const isSelected = isSelectedProcess({ libraryItem: item, clientItems, teamId })

      return { ...item, selected: Boolean(isSelected), disabled: isInitial && Boolean(isSelected) }
    }) ?? []
  )
}

import { getOr, isString } from 'lodash/fp'
import { isDataLoadingState } from '@helpers/utils'
import { LOADING_ACTION, loadingState } from '@helpers/constants'

export const getMetaWithIgnoreLoading = () => ({
  ignoreLoading: true,
})

export const getMetaWithGlobalLoading = () => ({
  globalLoader: true,
})

export const getMetaWithActionLoading = () => ({
  [LOADING_ACTION]: true,
})

export const getIsActionsLoadingFromState = (
  actions: $TSFixMe,
  actionsState: $TSFixMe,
  shouldIgnoreInitial: $TSFixMe,
) => {
  const actionList = isString(actions) ? [actions] : actions

  return actionList?.some((action: $TSFixMe) =>
    isDataLoadingState(getOr(shouldIgnoreInitial ? null : loadingState.INITIAL, action, actionsState)),
  )
}

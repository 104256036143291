import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'

import { getAttachments, createAttachment, deleteAttachment } from './attachments'

export default function* customDeliverableSaga() {
  yield all([
    takeLatest(constants.FETCH_ATTACHMENTS, getAttachments),
    takeLatest(constants.CREATE_ATTACHMENT, createAttachment),
    takeLatest(constants.DELETE_ATTACHMENT, deleteAttachment),
  ])
}

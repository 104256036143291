import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@teamResources/masterplan/actions/actionTypes'
import {
  downloadTeamResourcesMasterplan,
  fetchTeamResourcesMasterplan,
  updateTeamResourcesMasterplan,
} from './masterplanSagas'

export default function* resourcesHomeSaga() {
  yield all([
    takeLatest(constants.FETCH_TEAM_RESOURCES_MASTERPLAN, fetchTeamResourcesMasterplan),
    takeLatest(constants.UPDATE_TEAM_RESOURCES_MASTERPLAN, updateTeamResourcesMasterplan),
    takeLatest(constants.DOWNLOAD_TEAM_RESOURCES_MASTERPLAN, downloadTeamResourcesMasterplan),
  ])
}

import { handleActions } from 'redux-actions'

import * as constants from '../actions/actionTypes'

export const initialState = {
  attachments: [],
}

const customDeliverableReducer = handleActions(
  {
    [constants.SET_ATTACHMENTS]: (state, action) => {
      const { attachments } = action.payload

      return {
        ...state,
        attachments,
      }
    },
  },
  initialState,
)

export default customDeliverableReducer

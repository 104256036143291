import { valueGetterWithInterdependency } from '@shared/DayOne/utils/mapDayOneData'

import { processItemFields } from '@common/accessController/strategies/deliverables/constants'

const ownerValueGetter = valueGetterWithInterdependency(processItemFields.OWNER)

type FilterTypes = 'contains' | 'notContains' | 'equals' | 'notEqual' | 'startsWith' | 'endsWith' | 'blank' | 'notBlank'

interface TextMatcherOptions {
  type: FilterTypes
  filterText: string | null
  rowValue: any
  mapper?: () => { value: any; label: any }[]
}

export function textFilterMatcher(options: TextMatcherOptions) {
  const { type, rowValue, filterText, mapper = () => [{ value: rowValue, label: rowValue }] } = options
  const mappedRows = mapper()

  if (filterText === null) {
    return false
  }

  if (type === 'contains') {
    return mappedRows.some((row) => {
      if (row.label.toLowerCase().includes(filterText.toLowerCase())) {
        return row.value === rowValue
      }
      return false
    })
  }

  if (type === 'notContains') {
    const contains = mappedRows.some((row) => {
      if (row.label.toLowerCase().includes(filterText.toLowerCase())) {
        return row.value === rowValue
      }
      return false
    })
    return !contains
  }

  if (type === 'equals') {
    return mappedRows.some((row) => {
      if (row.label.toLowerCase() === filterText.toLowerCase()) {
        return row.value === rowValue
      }
      return false
    })
  }

  if (type === 'notEqual') {
    const equals = mappedRows.some((row) => {
      if (row.label.toLowerCase() === filterText.toLowerCase()) {
        return row.value === rowValue
      }
      return false
    })
    return !equals
  }

  if (type === 'startsWith') {
    return mappedRows.some((row) => {
      if (row.label.toLowerCase().startsWith(filterText.toLowerCase())) {
        return row.value === rowValue
      }
      return false
    })
  }

  if (type === 'endsWith') {
    return mappedRows.some((row) => {
      if (row.label.toLowerCase().endsWith(filterText.toLowerCase())) {
        return row.value === rowValue
      }
      return false
    })
  }

  if (type === 'blank') {
    return mappedRows.some((row) => {
      if (!row.label) {
        return true
      }
      return false
    })
  }

  if (type === 'notBlank') {
    return mappedRows.some((row) => {
      if (row.label) {
        return true
      }
      return false
    })
  }

  return false
}

interface MatcherParams {
  filterOption: FilterTypes
  filterText: string | null
  context: any
  data: any
}

export function priorityTextMatcher(params: MatcherParams) {
  const { filterOption, context, filterText, data } = params

  return textFilterMatcher({
    type: filterOption as FilterTypes,
    rowValue: data.priority,
    filterText,
    mapper: () => {
      return context.priorityOptions
    },
  })
}

export function confidentialTextMatcher(params: MatcherParams) {
  const { filterOption, filterText, data } = params

  return textFilterMatcher({
    type: filterOption as FilterTypes,
    rowValue: data.confidential,
    filterText,
    mapper: () => {
      return [
        {
          value: data.confidential,
          label: data.confidential === true ? 'yes' : data.confidential === false ? 'no' : '',
        },
      ]
    },
  })
}

export function ownerTextMatcher(params: MatcherParams) {
  const { filterOption, filterText } = params
  const { displayName } = ownerValueGetter(params)

  return textFilterMatcher({
    type: filterOption as FilterTypes,
    rowValue: displayName,
    filterText,
    mapper: () => [
      {
        value: displayName,
        label: displayName,
      },
    ],
  })
}

export function categoryTextMatcher(params: MatcherParams) {
  const { filterOption, context, filterText, data } = params

  return textFilterMatcher({
    type: filterOption as FilterTypes,
    rowValue: data.projectCategoryId,
    filterText,
    mapper: () => {
      return context.category
    },
  })
}

import { userRoles, accessRights, teams } from '../constants'
import { routesList } from '../routesList'

export const INTEGRATION_RESTRICTED_PERMISSIONS = {
  name: teams.INTEGRATION,
  role: userRoles.RESTRICTED,
  priority: 8,
  teamsPermissions: {
    deliverable: accessRights.RESTRICTED,
  },
  permissions: [
    {
      route: routesList.DASHBOARD.PROJECTS_AND_TASKS,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_HOME,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.DELIVERABLE.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_WORKSPACE,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.DECISION_LOG,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.RISK_LOG,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_STATUS_UPDATE,
      accessLevel: accessRights.READ_WRITE,
    },
  ],
}

import { put, call } from 'redux-saga/effects'
import { updateIssueSuccess } from '@hypercareLog/actions/actions'
import { getHypercareLogApi } from '@common/net'
import { omit } from 'lodash'
import { transformEmptyStringsToNull } from '@helpers/utils'
import { createSaga } from '@common/sagaCreator/createSaga'

export const saveIssue = createSaga(function* saveIssue(action: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = action.payload

  const api = yield getHypercareLogApi()
  const response = yield call(
    api.request('saveIssue', {
      body: transformEmptyStringsToNull(omit(data, ['id', 'isNew', 'teamId', 'issueId'])),
      query: { teamId },
    }),
  )

  yield put(updateIssueSuccess({ id: response.id, issueId: response.issueId }))
})

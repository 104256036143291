import { put, call } from 'redux-saga/effects'
import { getTeamResourcesApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { setScopeTeamMembers } from '@teamResources/scopeTeamMembers/actions/actions'

export const fetchScopeTeamMembers = createSaga(function* fetchScopeTeamMembers(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const api = yield getTeamResourcesApi()
  const data = yield call(api.request('getScopeTeamMembers'))

  yield put(setScopeTeamMembers(data))
},
true)

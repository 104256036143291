import { isKeyProcess } from '@shared/DayOne/utils'
import { find, get, mergeWith, pick } from 'lodash/fp'
import { listItemTypes } from '@shared/DayOne/constants'

export const isCreatedKeyProcess = (item: $TSFixMe) => !item.isNew && isKeyProcess(item)

export const hasKeyProcess = (data: $TSFixMe) => data.some(isCreatedKeyProcess)

export const getItemsWithNotIncludedKeyProcess = (items: $TSFixMe) =>
  items.reduce((array: $TSFixMe, item: $TSFixMe) => {
    if (!isKeyProcess(item) && !find({ id: item.keyProcessId, type: listItemTypes.KEY_PROCESS }, items))
      array.push(item)

    return array
  }, [])

export const mergeDataToDelete = (source: $TSFixMe, object: $TSFixMe) =>
  mergeWith(
    (sourceValue, value) => {
      return (sourceValue || []).concat(value.map(get('name')))
    },
    source,
    object,
  )

export const itemsListKeys = ['tasks', 'projects', 'keyProcesses', 'subKeyProcesses']

const pickItemsData = pick(itemsListKeys)

const titles = ['teamCharterTitle', 'mutualDiscoveryTitle', 'dayOneProjectTitle']

const arrayMerger = (sourceValue: $TSFixMe, value: $TSFixMe) => (sourceValue || []).concat(value)

export const mergeComplexDataToDelete = (source: $TSFixMe, object: $TSFixMe) => {
  const teamsData = source.map((teamData: $TSFixMe) => {
    return mergeWith(
      (sourceValue, value) =>
        (sourceValue || []).concat(value.filter(({ teamId }: $TSFixMe) => teamData.teamId === teamId).map(get('name'))),
      teamData,
      object,
    )
  })

  const mergedData = teamsData.reduce(
    (combined: $TSFixMe, teamData: $TSFixMe) => {
      const combinedItems = mergeWith(arrayMerger, pickItemsData(combined), pickItemsData(teamData))

      titles.forEach((title) => {
        if (teamData[title] && !combined[title].includes(teamData[title])) combined[title].push(teamData[title])
      })

      return { ...combined, ...combinedItems }
    },
    {
      tasks: [],
      projects: [],
      keyProcesses: [],
      subKeyProcesses: [],
      teamCharterTitle: [],
      mutualDiscoveryTitle: [],
      dayOneProjectTitle: [],
    },
  )

  return { ...mergedData, teamsData }
}

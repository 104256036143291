import styled, { css } from 'styled-components'
import { Colors, Icon } from '@blueprintjs/core'
import { CustomTooltip } from '@shared/Popover'
import { white, darkLabelGrey } from '@imo/imo-ui-toolkit'

export const ProcessContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  & > div:first-of-type {
    flex: 1;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const StyledLabel = styled.div`
  color: ${white};
  background-color: ${Colors.RED3};
  border-radius: 3px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 12px;
  padding: 3px 6px;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 6px); // 100% - margin
`

export const StyledKeyProcessName = styled.div`
  background-color: ${darkLabelGrey};
  color: ${white};
  line-height: 14px;
  padding: 3px 27px 3px 11px;
  border-radius: 10px;
  position: relative;
  min-width: 60px;
  margin-top: 3px;
`

export const KeyProcessIcon = styled(Icon)<{ $flip?: boolean }>`
  position: absolute;
  background-repeat: no-repeat;
  user-select: none;
  right: 11px;
  top: 4px;
  ${(props) =>
    props.$flip &&
    css`
      transform: rotate(180deg) scaleX(-1);
    `}
`

export const StyledLabelTooltip = styled(CustomTooltip)`
  overflow: hidden;
  margin-top: 3px;
`

export const InterdependencyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 27px;
  align-items: center;
  width: 100%;
  overflow: hidden;
`

export const StyledName = styled.div`
  font-size: 12px;
  margin-right: 5px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const InterdependencyIcon = styled(Icon)`
  margin: 3px 3px;
  position: relative;
  background-repeat: no-repeat;
  user-select: none;
`

export const StyledTooltip = styled(CustomTooltip)`
  width: 20px;
  font-size: 12px;
  font-weight: 300;
`

export const StyledTooltipWithFlex = styled(StyledTooltip)`
  display: flex;
`

export const StyledHighlightedName = styled.div`
  span {
    color: #0000008a;
    background: linear-gradient(0deg, rgba(251, 208, 101, 0.2) 0%, rgba(251, 208, 101, 0.2) 100%), #F6F7F9;
  }
`

import { put, call, select } from 'redux-saga/effects'
import {
  launchInterdependencySuccess,
  cancelInterdependencySuccess,
  updateInterdependencyTaskSuccess,
  fetchProgramProjectPlanData,
  fetchProjectList,
} from '@dayOne/actions/actions'
import { updateInterdependencyPopupData } from '@teamResources/interdependencies/actions'
import { getDayOneApi } from '@common/net'
import { omit, pickBy, isNil } from 'lodash'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getSelectedImoTeamId } from '@generic/selectors/selectedTeams'
import { parseOwners } from '@shared/Grid/CellEditors/utils'
import { shouldRefreshAfterInterdependencyUpdate } from '@dayOne/utils'
import { getProcessById } from '@dayOne/selectors'

export const launchInterdependency = createSaga(function* launchInterdependency({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { senderTeamId, receiverTeamId, taskId, comments, status, withInterdependency, setProcessingRequest } = payload
  let data = {
    receiverTeamId,
    comments,
    status,
  } as { receiverTeamId: string; comments?: $TSFixMe; status: string }

  if (!comments) {
    data = omit(data, 'comments')
  }

  const api = yield getDayOneApi()
  const { id } = yield call(
    api.request('launchInterdependency', {
      query: {
        senderTeamId,
        taskId,
      },
      body: data,
    }),
  )

  if (withInterdependency) {
    const imoTeamId = yield select(getSelectedImoTeamId)

    yield put(fetchProgramProjectPlanData({ teamId: imoTeamId }))
  } else {
    yield put(launchInterdependencySuccess({ ...payload, id }))
    yield put(fetchProjectList({ teamId: senderTeamId }))
  }

  setProcessingRequest(false)
})

export const cancelInterdependency = createSaga(function* cancelInterdependency({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { senderTeamId, taskId, interdependencyId, withInterdependency, setProcessingRequest } = payload

  const api = yield getDayOneApi()
  yield call(
    api.request('cancelInterdependency', {
      query: {
        senderTeamId,
        taskId,
        interdependencyId,
      },
    }),
  )

  if (withInterdependency) {
    const imoTeamId = yield select(getSelectedImoTeamId)

    yield put(fetchProgramProjectPlanData({ teamId: imoTeamId }))
  } else {
    yield put(cancelInterdependencySuccess(payload))
  }

  setProcessingRequest(false)
})

export const updateInterdependencyTask = createSaga(function* updateInterdependencyTask({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const {
    senderTeamId,
    taskId,
    interdependencyId,
    withInterdependency,
    rowNodeId,
    teamMembers,
    isMirroredInterdependency,
    refresh,
    ...mainProperties
  } = payload

  const data = {
    ...mainProperties,
  }

  const oldData = yield select((state) => getProcessById(state, rowNodeId))

  if (teamMembers) data.teamMembers = parseOwners(teamMembers)

  const shouldRefresh = yield call(
    shouldRefreshAfterInterdependencyUpdate,
    data,
    oldData,
    oldData?.teamId,
    isMirroredInterdependency,
  )

  const api = yield getDayOneApi()
  yield call(
    api.request('updateInterdependencyTask', {
      query: {
        senderTeamId,
        taskId,
        interdependencyId,
      },
      body: pickBy(data, (value, key) => key === 'owner' || !isNil(value)),
    }),
  )

  yield put(updateInterdependencyTaskSuccess({ ...payload, rowNodeId }))
  if (withInterdependency) yield put(updateInterdependencyTaskSuccess({ ...payload, rowNodeId: withInterdependency }))

  if (shouldRefresh && refresh) return yield call(refresh, true)
})

export const updateInterdependencyTaskStatus = createSaga(function* updateInterdependencyTaskStatus({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { senderTeamId, taskId, interdependencyId, status } = payload

  yield put(updateInterdependencyPopupData({ ...payload }))

  const api = yield getDayOneApi()
  yield call(
    api.request('updateInterdependencyTask', {
      query: {
        senderTeamId,
        taskId,
        interdependencyId,
      },
      body: {
        status,
      },
    }),
  )
})

import { isNil, matches, omit, set } from 'lodash'
import { listItemTypes, processItemHierarchy } from '@shared/DayOne/constants'
import {
  checkValueHierarchyPresence,
  getInterdependencyOwnerPath,
  getInterdependencyRowId,
  getRowNodeIdProcessItem,
} from '@shared/DayOne/utils/mapDayOneData'
import { getValueHierarchy } from '@helpers/utils'
import { processItemFields } from '@common/accessController/strategies/deliverables/constants'
import { cloneDeep, get, isEmpty, pick, sortBy, startsWith } from 'lodash/fp'
import { TeamKeyProcessProjectOrTask } from '@common/types/dtos/DayOneProjectListDto'

function getValueHierarchyFromItems(newProjectListId: string, items: $TSFixMe[], teamId: string) {
  const partials = newProjectListId.split('.')
  const partialsCount = partials?.length - 1

  const ProjectListIdLevel = {
    KEY_PROCESS: 0,
    PROJECT: 1,
    TASK: 2,
  }

  // in case if destination hierarchy is incorrect (kpId = "1", pId = "2.1", newTaskId="2.1.1")
  if (partialsCount === ProjectListIdLevel.TASK) {
    const projectProjectListId = `${partials[ProjectListIdLevel.KEY_PROCESS]}.${partials[ProjectListIdLevel.PROJECT]}`
    const project = items.find((item) => item.teamId === teamId && item.projectListId === projectProjectListId)

    if (project) {
      const keyProcess = items.find((item) => item.teamId === teamId && item.id === project.keyProcessId)

      if (keyProcess) {
        return [keyProcess.projectListId, project.projectListId, newProjectListId]
      }
    }
  }

  return getValueHierarchy(newProjectListId)
}

export const getNewHierarchyEntities = (hierarchy: $TSFixMe, itemHierarchyLevel: $TSFixMe) => {
  const hierarchyEntities = omit(hierarchy, itemHierarchyLevel - 1)

  return Object.values(hierarchyEntities).reduce((result, { type, id, name }) => {
    result[`${type}Id`] = id

    if (type === listItemTypes.PROJECT) result.project = { name, id }

    return result
  }, {})
}

export const getIsChildMatcher = (data: $TSFixMe, itemHierarchyLevel: $TSFixMe) =>
  matches(
    Object.keys(processItemHierarchy).reduce((result, key) => {
      const parentKey = `${key}Id`

      if (processItemHierarchy[key] === itemHierarchyLevel) {
        result[parentKey] = data.id
      }

      if (processItemHierarchy[key] < itemHierarchyLevel) {
        result[parentKey] = data[parentKey]
      }

      return result
    }, {} as { [key: string]: $TSFixMe }),
  )

export const replaceParentId = (itemId: string, parentId: string) => {
  const splitProjectListId = parentId.split('.')

  return itemId
    .split('.')
    .map((id, index) => splitProjectListId[index] || id)
    .join('.')
}

export const getUpdatedItemAndChildrenHierarchy = ({
  items,
  rowNodeId,
  data,
}: {
  items: $TSFixMe[]
  rowNodeId: string
  data: $TSFixMe
}) => {
  const { type, projectListId, teamId } = data

  const itemHierarchyLevel = processItemHierarchy[type]

  const listIdHierarchy = getValueHierarchyFromItems(
    rowNodeId.endsWith(listItemTypes.INTERDEPENDENCY) && data.interdependency
      ? data.interdependency.projectListId
      : projectListId,
    items,
    teamId,
  )

  const valueHierarchyPresence = checkValueHierarchyPresence({
    items,
    teamId,
    valueHierarchy: listIdHierarchy,
    field: processItemFields.PROJECT_LIST_ID,
  })

  const newHierarchy = getNewHierarchyEntities(valueHierarchyPresence, itemHierarchyLevel)

  const getIsChild = getIsChildMatcher(data, itemHierarchyLevel)

  return {
    newHierarchy,
    items: items.map((item) => {
      const isLowerHierarchy = processItemHierarchy[item.type] > itemHierarchyLevel
      const isChild = isLowerHierarchy && getIsChild(item)

      if (getRowNodeIdProcessItem(item) !== rowNodeId && !isChild) return item

      if (isChild) {
        return {
          ...item,
          ...newHierarchy,
          projectListId: replaceParentId(item.projectListId, projectListId),
        }
      }

      return {
        ...item,
        projectListId,
        ...newHierarchy,
      }
    }),
  }
}

export const getIsChildWithInterdependency = (parent: $TSFixMe, child: $TSFixMe) =>
  parent.type === listItemTypes.PROJECT &&
  child.teamId === parent.teamId &&
  child.projectId === parent.id &&
  child.interdependency

export const oppositeItemRelation = {
  [processItemFields.PREDECESSOR]: processItemFields.FOLLOWER,
  [processItemFields.FOLLOWER]: processItemFields.PREDECESSOR,
}

const pickRelatedData = pick(['id', 'name', 'projectListId'])

export const updatedPredecessorFollowerRelations = ({ oldRelated, newRelated, relation, data, list }: $TSFixMe) => {
  const { type, teamId } = data
  const oppositeField = oppositeItemRelation[relation]
  const oppositeFieldId = `${oppositeField}Id`

  const relationFieldId = `${relation}Id`

  return list.map((item: $TSFixMe) => {
    const senderTeamId = get(['interdependency', 'senderTeam', 'id'], item)
    const receiverTeamId = get(['interdependency', 'receiverTeam', 'id'], item)

    if (type !== item.type || (item.teamId !== teamId && senderTeamId !== teamId)) return item

    const newItem = cloneDeep(item)

    if (newRelated && newRelated.id === item.id && receiverTeamId !== teamId) {
      newItem[oppositeField] = pickRelatedData(data)
      newItem[oppositeFieldId] = data.id
    }

    if (get('id', oldRelated) === item.id && item[oppositeFieldId] === data.id) {
      newItem[oppositeField] = null
      newItem[oppositeFieldId] = null
    }

    if (newRelated && item[relationFieldId] === newRelated.id && !item.interdependency) {
      newItem[relationFieldId] = null
      newItem[relation] = null
    }

    return newItem
  })
}

export const updateProcessListWithNewData = ({ list, rowNodeId, data }: $TSFixMe) => {
  const projectList = cloneDeep(list)

  return projectList.map((item: $TSFixMe) => {
    if (
      startsWith(rowNodeId, getRowNodeIdProcessItem(item)) &&
      rowNodeId.length < getRowNodeIdProcessItem(item).length
    ) {
      return { ...item, libraryFunctionId: null }
    }

    if (getRowNodeIdProcessItem(item) === rowNodeId) {
      return { ...item, ...data }
    }

    if (getIsChildWithInterdependency(data, item)) {
      const interdependencyIndex = projectList.findIndex(
        (process: $TSFixMe) => getRowNodeIdProcessItem(process) === getInterdependencyRowId(item),
      )

      const hasInterdependency = interdependencyIndex !== -1

      if (hasInterdependency) set(projectList, [interdependencyIndex, ...getInterdependencyOwnerPath(item)], data.owner)

      if (hasInterdependency && item.isOutgoing)
        set(projectList, [interdependencyIndex, 'interdependency', 'taskL2', 'project', 'name'], data.name)

      if (item.isIncoming) {
        const newItem = cloneDeep(item)

        set(newItem, ['interdependency', 'taskL2', 'L2Projects', 0, 'owner'], data.owner)

        return newItem
      }
    }

    return item
  })
}

export const getPropsForDayOne = ({ payload, type }: $TSFixMe) => {
  const {
    projectListId,
    owner,
    forecastDate,
    dueDate,
    confidential,
    startDate,
    priorityId,
    status,
    projectCategoryId,
    preFollower,
  } = payload

  const followerId = preFollower?.followerTaskL2Id
  const predecessorId = preFollower?.predecessorTaskL2Id

  return {
    name: payload[type].name,
    projectListId,
    owner,
    forecastDate,
    dueDate,
    confidential,
    startDate,
    priority: priorityId,
    status,
    projectCategoryId,
    followerId,
    predecessorId,
  }
}

export const updateDayOneList = ({
  list,
  payload,
}: {
  list: TeamKeyProcessProjectOrTask[]
  payload: TeamKeyProcessProjectOrTask
}) => {
  const parentItem = get(['L2Projects', 0], payload)
  const sortedList = sortBy('type', list)
  const keyProcessIds = new Set<number>()
  const { type, projectListId, id } = payload

  return sortedList.map((listItem: TeamKeyProcessProjectOrTask) => {
    const isItKeyProcess = listItem.type === type && type === listItemTypes.KEY_PROCESS && payload.id === listItem.id

    const isProjectListIdChanged = listItem.projectListId !== payload.projectListId

    if (isItKeyProcess && isProjectListIdChanged) {
      list
        .filter(({ id }: { id: number }) => id === payload.id)
        .forEach(({ id }: { id: number }) => keyProcessIds.add(id))
    }

    if (keyProcessIds.has(listItem.keyProcessId) && projectListId) {
      const listId = listItem?.projectListId?.split('.')
      listId && listId.splice(0, 1, projectListId)
      return { ...listItem, projectListId: listId?.join('.') }
    }

    if (!isEmpty(parentItem) && parentItem.id === listItem.id && parentItem.projectListId === listItem.projectListId) {
      return { ...listItem, status: parentItem.status }
    }

    if (listItem.id === id && listItem.type === type) {
      if (type === 'keyProcess') {
        return {
          ...listItem,
          ...payload,
        }
      }

      if ('projectId' in listItem && 'projectId' in payload) {
        if (listItem.projectId === payload.projectId) {
          return {
            ...listItem,
            ...payload,
          }
        }

        const bothInterdependencies =
          !isNil(listItem.isIncoming) && !isNil(payload.isIncoming) && listItem.isIncoming === payload.isIncoming

        const bothMirrors = Boolean(listItem.mirror) && Boolean(payload.mirror)

        if (bothInterdependencies || bothMirrors) {
          return {
            ...listItem,
            ...payload,
          }
        }
      }
    }

    return listItem
  })
}

import { call, put } from 'redux-saga/effects'
import { getInitiativeListV2Api } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { setValueCaptureStatus } from '../actions'

export const fetchValueCaptureStatuses = createSaga(function* fetchValueCaptureStatuses({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload

  const api = yield getInitiativeListV2Api()
  const valueCaptureStatuses = yield call(
    api.request('getValueCaptureStatusV2', {
      query: {
        teamId,
      },
    }),
  )

  yield put(
    setValueCaptureStatus({
      valueCaptureStatuses,
    }),
  )
})

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'

import {
  fetchTaskWatchers,
  watchTask,
  unwatchTask,
  watchInterdependentTask,
  fetchInterdependentTaskWatchers,
  unwatchInterdependentTask,
} from './watchers'

export default function* taskWatchersSaga() {
  yield all([
    takeLatest(constants.WATCH_TASK, watchTask),
    takeLatest(constants.UNWATCH_TASK, unwatchTask),
    takeLatest(constants.FETCH_TASK_WATCHERS, fetchTaskWatchers),
    takeLatest(constants.WATCH_INTERDEPENDENT_TASK, watchInterdependentTask),
    takeLatest(constants.UNWATCH_INTERDEPENDENT_TASK, unwatchInterdependentTask),
    takeLatest(constants.FETCH_INTERDEPENDENT_TASK_WATCHERS, fetchInterdependentTaskWatchers),
  ])
}

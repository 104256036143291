import { find, flatten, intersection, size, values } from 'lodash'
import { userRoles, VC_DELIVERABLE_TYPE_ID } from '@common/accessController/constants'
import { get, groupBy, isArray } from 'lodash/fp'
import {
  checkIsReadOnlyRole,
  checkIsRestricted,
  checkIsRoleWithVC,
  checkIsWithoutVCOrReadonly,
  checkIsWithoutVCRole,
  isOrgDesignTeam,
} from '@generic/selectors/utils'
import { isGlobalImoTeamType } from '@helpers/utils'
import { TASK_PROJECT_DELIVERABLES_IDS, TSA_V2_DELIVERABLES_IDS } from '@helpers/constants'

// TODO remove after team-tree refactoring
export const getFlattenTeams = (teamsTree: $TSFixMe) => flatten(values(teamsTree))

export const getUserTeamsFromTree = (userTeams: $TSFixMe[] = [], teamsTree: $TSFixMe = {}) =>
  userTeams.map((team: $TSFixMe) => ({
    ...team,
    ...(find(getFlattenTeams(teamsTree), { id: team.id }) || {}),
  }))

export const checkIsRevieweeTeam = (revieweeTeams: $TSFixMe, teamId: $TSFixMe, isVCDeliverableType: boolean) => {
  return revieweeTeams?.find(
    ({ id, deliverableTypeIds }: $TSFixMe) =>
      id === teamId &&
      (!isVCDeliverableType || (isVCDeliverableType && deliverableTypeIds?.includes(VC_DELIVERABLE_TYPE_ID))),
  )
}

export const getIsReviewer = (params: $TSFixMe) => {
  const {
    userTeams,
    teamId,
    teamType = null,
    isVCDeliverableType = false,
    withoutReadonly = false,
    withoutRestricted = false,
    withoutVC = false,
  } = params

  const teams = Boolean(teamType)
    ? userTeams.filter((t: $TSFixMe) => (isArray(teamType) ? teamType.includes(t.teamType) : t.teamType === teamType))
    : userTeams

  return teams.some((team: $TSFixMe) => {
    const { revieweeTeams = [], reporterIds = [], secondaryReporterIds = [] } = team

    if (withoutReadonly && checkIsWithoutVCOrReadonly(team)) {
      return false
    }

    if (withoutRestricted && checkIsRestricted(team?.role)) {
      return false
    }

    if (withoutVC && checkIsWithoutVCRole(team?.role)) {
      return false
    }

    return (
      checkIsRevieweeTeam(revieweeTeams, teamId, isVCDeliverableType) ||
      reporterIds.includes(teamId) ||
      secondaryReporterIds.includes(teamId)
    )
  })
}

export const getRestrictedTeams = (userTeams: $TSFixMe = []) => {
  return userTeams.reduce((arr: $TSFixMe, { id, role }: $TSFixMe) => {
    if (![userRoles.RESTRICTED, userRoles.RESTRICTED_WITH_VC].includes(role?.toLowerCase())) return arr

    const isReviewer = getIsReviewer({ userTeams, teamId: id })

    if (!isReviewer) arr.push(id)

    return arr
  }, [])
}

export const getIdsByCondition = (list: $TSFixMe, condition: $TSFixMe) =>
  list.filter(condition).reduce((ids: $TSFixMe, { id }: $TSFixMe) => {
    if (!ids.includes(id)) ids.push(id)

    return ids
  }, [])

export const groupByResponsibleIMO = groupBy('responsibleIMOLongName')

export const isVcTrackerTeam = (curTeam: $TSFixMe) => {
  const { active, role, parentRole, isValueCapture, parents } = curTeam

  const parentTeamType = get([0, 'teamType'], parents)

  return (
    active && isValueCapture && (role || isGlobalImoTeamType(parentTeamType)) && checkIsRoleWithVC(role || parentRole)
  )
}

export const isOrgDesignTrackerTeam = (curTeam: $TSFixMe) => {
  const { active, role, parentRole, parents } = curTeam

  const parentTeamType = get([0, 'teamType'], parents)

  return (
    active &&
    isOrgDesignTeam(curTeam) &&
    (role || isGlobalImoTeamType(parentTeamType)) &&
    !checkIsRestricted(role || parentRole) &&
    !checkIsReadOnlyRole(role || parentRole)
  )
}

export const isNotRestrictedTeam = ({ active, role, parentRole }: $TSFixMe) =>
  active && !checkIsRestricted(role || parentRole)

export const checkIsActiveDeliverable = (activeDeliverableIds: $TSFixMe, deliverableId: $TSFixMe) => {
  for (const activeId in activeDeliverableIds) {
    if (activeDeliverableIds[activeId] && size(activeDeliverableIds[activeId])) {
      const hasActive = activeDeliverableIds[activeId].some((id: number) => id === deliverableId)

      if (hasActive) return true
    }
  }

  return false
}

export const isActiveTeamHasActiveTSADeliverableInReview = (team: $TSFixMe, allTeams: $TSFixMe[]) => {
  return team?.teamDeliverablesInReview.some(({ deliverableId, teamId }: $TSFixMe) => {
    const teamWithDeliverable = allTeams.find((team: $TSFixMe) => team.id === teamId)

    if (!TSA_V2_DELIVERABLES_IDS.includes(deliverableId) || !teamWithDeliverable.active) return false

    return checkIsActiveDeliverable(teamWithDeliverable.activeDeliverableIds, deliverableId)
  })
}

const hasMatchingDeliverables = (teamDeliverables: Record<string, number>, deliverableIds: number[]): boolean => {
  return intersection(flatten(Object.values(teamDeliverables)), deliverableIds).length > 0
}

export const getActiveTeamsWithActiveDayOneDeliverable = (allTeams: $TSFixMe[]) => {
  return allTeams.filter((team: $TSFixMe) => {
    return hasMatchingDeliverables(team.activeDeliverableWithNotStartedIds, TASK_PROJECT_DELIVERABLES_IDS)
  })
}

export const getActiveTeamsWithStartedDayOneDeliverable = (allTeams: $TSFixMe[]) => {
  return allTeams.filter((team: $TSFixMe) => {
    return hasMatchingDeliverables(team.activeDeliverableIds, TASK_PROJECT_DELIVERABLES_IDS)
  })
}

export const getTSAMasterListTeamList = (teamsList: $TSFixMe, allTeams: $TSFixMe) => {
  const filteredTeams = {}
  Object.entries(teamsList).forEach(([key, value]): $TSFixMe => {
    // @ts-ignore
    filteredTeams[key] = value.filter((team: $TSFixMe) => isActiveTeamHasActiveTSADeliverableInReview(team, allTeams))
  })

  return filteredTeams
}

import { watcher as midWatcher } from '@nvt/mid-react'
import { all, fork } from 'redux-saga/effects'
import { programSetupWatcher } from '../programSetup/programSetup'
import { deliverableTypeWatcher } from '../deliverables/deliverableType'
import { teamSetupWatcher } from '../teamSetup/teamSetup'
import { taxonomyWatcher } from '../taxonomy/taxonomy'
import { teamImoHierarchyWatcher } from '../teamImoHierarchy/teamImoHierarchy'
import { usersWatcher } from '../users/users'
import { teamDeliverablesWatcher } from '../teamDeliverables/teamDeliverables'
import { configWatcher } from '../config/config'

export default function* myImoConfigSaga() {
  yield all([
    fork(midWatcher),
    fork(programSetupWatcher),
    fork(deliverableTypeWatcher),
    fork(taxonomyWatcher),
    fork(teamSetupWatcher),
    fork(teamImoHierarchyWatcher),
    fork(usersWatcher),
    fork(teamDeliverablesWatcher),
    fork(configWatcher),
  ])
}

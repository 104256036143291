import { omit, omitBy, isNull, first, lowerCase, some } from 'lodash/fp'
import { customColumnsFields } from '@myImoClient/components/TSAv2/shared/constants'
import { isEmptyRequiredFields, requiredFieldsPhaseMapper } from '@myImoClient/components/TSAv2/shared/colDefs/utils'
import { transformEmptyStringsToNull } from '@helpers/utils'

export const getTransformedCreateData = (data: $TSFixMe) => {
  return omitBy(isNull, data)
}

export const getTransformedUpdateData = ({ data, field, customColumn }: $TSFixMe) => {
  const fieldsToOmit = ['id', 'teamId']

  if (field === 'keyProcess') {
    return data.teamKeyProcessV2Id ? { teamKeyProcessV2Id: data.teamKeyProcessV2Id } : { keyProcess: data.keyProcess }
  }

  if (field === 'team') {
    data.newTeamId = data?.team?.id

    return omit('team', data)
  }

  if (field === 'tsaContactRemainCOSeller' && data.tsaContactRemainCOSeller?.id) {
    return omit(['tsaContactRemainCOSeller.displayName'], data)
  }

  if (field === 'tsaContactDivestCOBuyer' && data.tsaContactDivestCOBuyer?.id) {
    return omit(['tsaContactDivestCOBuyer.displayName'], data)
  }

  if (field === 'otherLinkedProjects') {
    data.otherLinkedProjects = data.otherLinkedProjects.map((project: $TSFixMe) => project.id)

    return data
  }

  if (field === 'primaryLinkedProject') {
    data.primaryLinkedProjectId = data.primaryLinkedProject ? data.primaryLinkedProject.id : null

    return omit(['primaryLinkedProject'], data)
  }

  if ([customColumnsFields.reviewStatus, customColumnsFields.typeOfService].includes(field)) {
    const option = customColumn.options.find((column: $TSFixMe) => column.id === data[field])?.id || data[field]

    return { [field]: option }
  }

  return omit(fieldsToOmit, transformEmptyStringsToNull(data))
}

export const getUpdatedTSA = (tsa: $TSFixMe, updatedData: $TSFixMe) => {
  const updatedField = first(Object.keys(updatedData)) || ''
  const shouldNotUpdate = ['otherLinkedProjects']

  if (shouldNotUpdate.includes(updatedField)) return tsa

  // @ts-expect-error check interface
  const phase = requiredFieldsPhaseMapper()[updatedField]
  const needToResetCompleteness = isEmptyRequiredFields({ ...tsa, updatedData }, phase)

  if (needToResetCompleteness) {
    return { ...tsa, ...updatedData, [`isPhase${phase}Completed`]: false }
  }

  return { ...tsa, ...updatedData }
}

const isTsaProcess = (process: $TSFixMe) =>
  lowerCase(process.tsa) === 'yes' || lowerCase(process.dayOneSolution) === 'tsa'

export const filterTsaProcessesFromLibrary = (list: $TSFixMe[]) => {
  return list.filter((process) => {
    if (!process.keyProcessId) {
      const isKeyProcessWithTsa = isTsaProcess(process)
      const hasSubProcessesWithTsa = some(isTsaProcess, process.subKeyProcesses)

      return isKeyProcessWithTsa || hasSubProcessesWithTsa
    }

    return isTsaProcess(process)
  })
}

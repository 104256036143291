import { call, put } from 'redux-saga/effects'
import { getTeamWorkspaceApi } from '@common/net'
import { updateImoTeamTopicsSuccess } from '../actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'

export const updateImoTopics = createSaga(function* updateImoTopics(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, imoTopics } = action.payload
  const api = yield getTeamWorkspaceApi()
  yield call(
    api.request('bulkUpdateTeamTopics', {
      query: { teamId, requestedBy: 'imo' },
      body: imoTopics,
    }),
  )
  yield put(updateImoTeamTopicsSuccess({ teamId, imoTopics }))
},
true)

import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getIsAuthorized, getHasRole, getIsPdfEnabled } from '@generic/selectors'
import { Wrapper } from './index.styles'
import withLoader from '@hocs/withLoader'
import InsufficientPermissionsPage from '../InsufficientPermissionsPage'
import { getActivePage } from '@domain/myImoClient/myTeam/knowledgeCenter/selectors'

interface Props {
  children: React.ReactNode
}

const AppWrapper = ({ children }: Props) => {
  const wrapperRef = useRef(null)
  const isAuthorized = useSelector(getIsAuthorized)
  const hasRole = useSelector(getHasRole)
  const activePage = useSelector(getActivePage)
  const isPdfEnabled = useSelector(getIsPdfEnabled)

  useEffect(() => {
    if (wrapperRef.current && activePage) {
      const selectedPdfPage = (wrapperRef.current as $TSFixMe).querySelector(
        `.main-pdf-document [data-page-number='${activePage}']`,
      )

      if (selectedPdfPage) {
        selectedPdfPage.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [wrapperRef.current, activePage])

  if (!isAuthorized) return null

  if (!hasRole) return <InsufficientPermissionsPage styles={{ width: '100vw', height: '100vh' }} />

  return (
    <Wrapper isPdfEnabled={isPdfEnabled} ref={wrapperRef} className="app-wrapper">
      {children}
    </Wrapper>
  )
}

export default withLoader(AppWrapper)

import { call, put } from 'redux-saga/effects'
import { fetchCommunicationsData } from '@communications/actions/actions'
import { getCommunicationsApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const deleteEvents = createSaga(function* deleteEvents({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getCommunicationsApi()
  yield call(api.request('deleteEvents', { query: { eventIds: payload } }))

  yield put(fetchCommunicationsData())
},
true)

import { combineReducers } from 'redux'

import { configReducer } from './config/config'
import { programSetupReducer } from './programSetup/programSetup'
import { deliverablesReducer } from './deliverables/deliverables'
import { projectTaxonomyReducer } from './projectTaxonomy/projectTaxonomy'
import { teamImoHierarchyReducer } from './teamImoHierarchy/teamImoHierarchy'
import { usersReducer } from './users/users'
import { teamSetupReducer } from './teamSetup/teamSetup'
import { teamDeliverablesReducer } from './teamDeliverables/teamDeliverables'

export default combineReducers({
  config: configReducer,
  programSetup: programSetupReducer,
  deliverables: deliverablesReducer,
  projectTaxonomy: projectTaxonomyReducer,
  teamDeliverables: teamDeliverablesReducer,
  teamImoHierarchy: teamImoHierarchyReducer,
  users: usersReducer,
  teamSetup: teamSetupReducer,
})

/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react'
import classnames from 'classnames'
import { StyledTooltip, TooltipContent } from './index.styles'
import { Intent } from '@blueprintjs/core'
import { Placement, PopperModifierOverrides } from '@blueprintjs/popover2'

interface CustomTooltipProps {
  fill?: boolean
  suppressInteractions?: boolean
  portalClassName?: string
  className?: string
  children: ReactNode
  content: ReactNode
  contentStyles?: $TSFixMe
  placement?: Placement
  disabled?: boolean
  hoverOpenDelay?: number
  targetTagName?: string
  intent?: Intent
  modifier?: PopperModifierOverrides
  heightMinContent?: boolean
  targetProps?: $TSFixMe
  boundary?: string
  interactionKind?: string
}

const CustomTooltip = ({
  fill = false,
  children,
  portalClassName,
  suppressInteractions = true,
  content = null,
  contentStyles = {},
  className,
  ...tooltipProps
}: CustomTooltipProps) => {
  return (
    <StyledTooltip
      className={className}
      fill={fill}
      disabled={!content}
      content={<TooltipContent styles={contentStyles}>{content}</TooltipContent>}
      portalClassName={classnames(portalClassName, {
        'disable-interactions': suppressInteractions,
      })}
      {...tooltipProps}
    >
      {children}
    </StyledTooltip>
  )
}

export default CustomTooltip

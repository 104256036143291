import { flow, get } from 'lodash/fp'
import { createSelector } from 'reselect'
import { knowledgeBlocks, knowledgeBlocksNames } from '@domain/myImoClient/myTeam/knowledgeCenter/constants'

const clientState = (state: $TSFixMe) => get(['client', 'knowledgeCenter'], state)

export const getKnowledgeData = createSelector(
  clientState,
  ({ videos, videosStatus, articles, articlesStatus, introductionMaterials, introductionMaterialsStatus }) => [
    {
      ...knowledgeBlocks[knowledgeBlocksNames.hearFromExperts],
      blockName: knowledgeBlocksNames.hearFromExperts,
      status: videosStatus,
      data: videos,
    },
    {
      ...knowledgeBlocks[knowledgeBlocksNames.readMore],
      blockName: knowledgeBlocksNames.readMore,
      status: articlesStatus,
      data: articles,
    },
    {
      ...knowledgeBlocks[knowledgeBlocksNames.introductionMaterials],
      blockName: knowledgeBlocksNames.introductionMaterials,
      status: introductionMaterialsStatus,
      data: introductionMaterials,
    },
  ],
)

export const getEditModalState = flow(clientState, get('editModalState'))

export const getSelectedItem = flow(clientState, get('selectedItem'))
export const getSelectedItemErrors = flow(clientState, get('selectedItemErrors'))

export const getExpertVideos = flow(clientState, get('videos'))
export const getExpertVideosStatus = flow(clientState, get('videosStatus'))
export const getCreateVideoStatus = flow(clientState, get('createVideoStatus'))

export const getExpertArticles = flow(clientState, get('articles'))
export const getExpertArticlesStatus = flow(clientState, get('articlesStatus'))
export const getCreateArticleStatus = flow(clientState, get('createArticleStatus'))
export const getArticleUrlById = createSelector(
  getExpertArticles,
  (_: unknown, articleId: string) => articleId,
  (articles: $TSFixMe[], articleId: string) => {
    if (!articles) return {}

    return articles.find((article) => article.id === articleId)?.url
  },
)

export const getIntroductionMaterials = flow(clientState, get('introductionMaterials'))
export const getIntroductionMaterialsStatus = flow(clientState, get('introductionMaterialsStatus'))
export const getCreateIntroductionMaterialStatus = flow(clientState, get('createIntroductionMaterialStatus'))
export const getActivePage = flow(clientState, get('activePage'))
export const getIntroductionMaterialsFetchDate = flow(clientState, get('introductionMaterialsFetchDate'))

import {
  upperFirst,
  keys,
  mapValues,
  pickBy,
  reduce,
  toPairs,
  get,
  sortBy,
  isArray,
  isEqual,
  omit,
  difference,
  isEmpty,
} from 'lodash'
import { find, flow, includes as includesFP, size, sortBy as sortByFP, get as getFp, toLower } from 'lodash/fp'
import { teams, teamTitles, transformTeamType, userRoles } from '@common/accessController/constants'
import { caseInsensitiveComparator } from '@helpers/utilsComparators'
import { cellPlaceholders } from '@helpers/constants'
import { USER_ROLES_HIERARCHY_LEVELS, ADMINS } from './constants'
import * as teamConstants from '@generic/selectors/teamConstants'
import { permissionsMap } from '@common/accessController/permissions/root'
import { GLOBAL_IMO_TEAM_NAMES } from '@generic/selectors/teamConstants'
import { lengthValidation } from '@helpers/validators'
import { checkUserDuplications } from '@helpers/schemas/utils'
import { en } from '@common/translation/en'

const SMO_GROUP_NAME = 'SMOs'
const IMO_GROUP_NAME = 'IMOs'
export const INTEGRATION_GROUP_TEAM = 'Integration teams'
export const CENTRAL_GROUP_TEAM = 'Central teams'
export const SEPARATION_GROUP_TEAM = 'Separation teams'

export const hasAdminRole = (roles) => roles.includes(ADMINS)

export const isConfigOrUserAdmin = (data) => teamConstants.ADMIN_ROLES.some((role) => data.includes(role))

export const verifyUserData = (data) => {
  if (data.email?.includes('’')) {
    const emailVerified = data.email?.replace('’', "'")

    return { ...data, email: emailVerified }
  }

  return data
}

export const transformTeamTypeNameIntoTeamType = (teamTypeName) => {
  switch (teamTypeName) {
    case ADMINS:
      return 'Admin'
    case INTEGRATION_GROUP_TEAM:
    case SEPARATION_GROUP_TEAM:
    case teams.INTEGRATION:
    case teams.SEPARATION:
      return teams.INTEGRATION
    case IMO_GROUP_NAME:
    case SMO_GROUP_NAME:
    case teams.IMO:
    case teams.SMO:
    case teams.SMO_GLOBAL:
    case teams.IMO_GLOBAL:
      return teams.IMO
    default:
      return teams.CENTRAL
  }
}

export const getGroupTitle = (node, t) => {
  const activeTeams = node.allLeafChildren.filter((child) => Boolean(child.data.isActive))
  const selectedPrivilege = reduce(
    activeTeams,
    (acc, value) => {
      const {
        data: { role },
      } = value

      const roleLevels = role.length

      let groupTitle

      if (node.level === 0 && node.key !== ADMINS) {
        const teamType = transformTeamTypeNameIntoTeamType(node.key)
        const roleName = t(`ROLES.${teamType.toUpperCase()}.${role[roleLevels - 1]}`)
        groupTitle = `${role[roleLevels - 2]}, ${roleName}`
      } else if (node.key === ADMINS) {
        const teamType = transformTeamTypeNameIntoTeamType(node.key)
        groupTitle = t(`ROLES.${teamType.toUpperCase()}.${role[roleLevels - 1]}`)
      } else {
        const teamType = transformTeamTypeNameIntoTeamType(node.parent.key)
        groupTitle = t(`ROLES.${teamType.toUpperCase()}.${role[roleLevels - 1]}`)
      }

      return acc.concat(groupTitle)
    },
    [],
  ).join('; ')

  return selectedPrivilege && !node.expanded ? `${node.key} (${selectedPrivilege})` : node.key
}

export const getActiveTeams = (allTeams, teamsList, isSorted = false) => {
  const activeTeams = toPairs(mapValues(allTeams, (team) => keys(pickBy(team))[0]))
  let sortedActiveTeams = [...activeTeams]

  if (teamsList && isSorted) {
    // Decorating activeTeams list with team types to be able to sort by permission module
    const decoratedActiveTeams = activeTeams.map((teams) => {
      const { teamType } = teamsList.find((tl) => tl.longName === teams[0]) || {}
      const permission = permissionsMap({}).find((permission) => permission.name === transformTeamType(teamType))

      return {
        name: teams[0],
        role: teams[1],
        priority: permission?.priority,
        teamType: transformTeamTypeNameIntoTeamType(teamType),
      }
    })

    sortedActiveTeams = sortBy(decoratedActiveTeams, ['priority', 'name']).map((team) => [
      team.name,
      team.teamType,
      team.role,
    ])
  }

  return sortedActiveTeams.reduce((acc, team) => {
    if (Boolean(team[2]) && !isConfigOrUserAdmin(team)) {
      const [teamName, teamType, role] = team
      const roleName = en.COMMON.ROLES[teamType.toUpperCase()][role]

      acc.push(`${teamName} (${upperFirst(roleName)})`)
    }

    return acc
  }, [])
}

const getRoleId = (privilege, roles) => {
  return roles.find((role) => role.name === privilege)?.id
}

export const mapRoles = (rowData, roles) => {
  return rowData.reduce((acc, { role, isActive }) => {
    if (hasAdminRole(role)) {
      const [, privilege] = role
      acc.push({
        name: privilege,
        active: isActive,
        id: getRoleId(privilege, roles),
      })

      return acc
    }

    return acc
  }, [])
}

export const mapRowData = (rowData) => {
  return rowData.reduce((teams, { role, isActive }) => {
    if (role.length !== USER_ROLES_HIERARCHY_LEVELS) return teams

    const [, team, privilege] = role

    if (teams[team]) {
      teams[team][privilege] = isActive
    } else {
      teams[team] = {
        [privilege]: isActive,
      }
    }

    return teams
  }, {})
}

export const getPrivilegeValue = (params) => {
  const {
    data: { teams = [], roles = [] },
    context,
  } = params

  const sortedTeams = getActiveTeams(teams.allTeams, context?.teamsList, true)

  const activeRoles = roles.filter((role) => role.active).map((role) => role.name)

  if (!sortedTeams || (!sortedTeams.length && !activeRoles.length)) {
    return cellPlaceholders.privileges.empty
  }

  return [...activeRoles, ...sortedTeams].join('; ')
}

export const getUserFullName = ({ name, surname }) => `${name} ${surname}`

export const setTeamsCell = (params) => {
  if (!params.newValue) {
    return false
  }

  const { activeTeams, allTeams, roles } = params.newValue
  const previousTeams = get(params, 'data.teams.activeTeams')
  const previousRoles = get(params, 'data.teams.roles')

  if (isEmpty(difference(activeTeams, previousTeams)) && isEmpty(difference(roles, previousRoles))) {
    return false
  }

  params.data.teams = { activeTeams, allTeams }
  params.data.roles = roles

  return true
}

const move = (arr, from, to) => {
  const elm = arr.splice(from, 1)[0]

  return arr.splice(to, 0, elm)
}

const applySorting = (teamsList, isSMO) => {
  const imoSmoGroupName = isSMO ? SMO_GROUP_NAME : IMO_GROUP_NAME
  const integrationName = isSMO ? SEPARATION_GROUP_TEAM : INTEGRATION_GROUP_TEAM
  const order = [imoSmoGroupName, 'Central teams', integrationName, 'Admins']

  return order.reduce((acc, orderGroup) => {
    const groupTeams = teamsList.filter((team) => team.role.includes(orderGroup))
    const insideTeams = groupTeams.sort(({ role: r1 }, { role: r2 }) => caseInsensitiveComparator(r1[1], r2[1]))

    if (orderGroup === imoSmoGroupName) {
      const globalIMO = insideTeams.find((team) =>
        GLOBAL_IMO_TEAM_NAMES.some((globalRole) => team.role.includes(globalRole)),
      )

      const index = insideTeams.findIndex((item) => isEqual(item, globalIMO))

      move(insideTeams, index, 0)
    }

    acc.push(...insideTeams)

    return acc
  }, [])
}

export const getRoleWeight = ({ role }) => Object.values(userRoles).findIndex((v) => v === role?.toLowerCase())

export const getActiveTeamsRowData = ({ allTeams, extendedTeams, adminRoles, isSMO }) => {
  const displayedGroupNames = {
    IMO: isSMO ? SMO_GROUP_NAME : IMO_GROUP_NAME,
    SMO: isSMO ? SMO_GROUP_NAME : IMO_GROUP_NAME,
    Central: 'Central teams',
    Integration: isSMO ? SEPARATION_GROUP_TEAM : INTEGRATION_GROUP_TEAM,
    'IMO-Global': isSMO ? SMO_GROUP_NAME : IMO_GROUP_NAME,
  }

  const mappedTeams = mapValues(allTeams, (roles, teamName) => {
    const teamGroupName = get(
      extendedTeams.find((team) => team.longName === teamName),
      'teamType',
    )

    const rolesList = keys(roles).map((roleName) => ({
      role: roleName,
      teamName: teamName,
      isActive: roles[roleName],
      groupName: displayedGroupNames[teamGroupName] || teamGroupName,
    }))

    return [...sortBy(rolesList, getRoleWeight)]
  })

  mappedTeams[ADMINS] = adminRoles.map((role) => ({
    role: [ADMINS, role.name],
    teamName: ADMINS,
    isActive: role.active,
    groupName: ADMINS,
  }))

  const teamsWithRolesHierarchy = reduce(
    mappedTeams,
    (rowData, team) => {
      team.forEach((teamItem) => {
        rowData.push({
          role: isArray(teamItem.role) ? teamItem.role : [teamItem.groupName, teamItem.teamName, teamItem.role],
          isActive: teamItem.isActive,
        })
      })

      return rowData
    },
    [],
  )

  return applySorting(teamsWithRolesHierarchy, isSMO)
}

export const hasOnlyRestrictedRoles = (activeTeams) => {
  if (isEmpty(activeTeams)) return false

  return activeTeams.every((item) => includesFP(userRoles.RESTRICTED, item.toLowerCase()))
}

export const isOptionDisabled = (value, isLaunchpadUser, isCurrentUser, isUserAdmin, isConfigAdmin) => {
  const configOptions = [userRoles.USER_ADMIN, userRoles.CONFIG_ADMIN]
  const isOnlyUserAdmin = isUserAdmin && !isConfigAdmin

  if ((isLaunchpadUser || isOnlyUserAdmin) && configOptions.includes(value.toLowerCase())) {
    return true
  }

  return isCurrentUser && configOptions.includes(value.toLowerCase())
}

const omitAdditionalFields = (array) => array.map((item) => omit(item, ['isRestricted', 'extendedTeams']))

export const isUsersListChanged = (users, savedUsers) => {
  if (!savedUsers.length) return false

  const next = sortByFP((u) => u.id)(omitAdditionalFields(users))
  const prev = sortByFP((u) => u.id)(omitAdditionalFields(savedUsers))

  return !isEqual(next, prev)
}

export const getRequiredFieldValidator = () => lengthValidation(null, 1, 'This field cannot be empty')

export const applyRowErrorClassRule = {
  'invalid-row-background-data': (params) => {
    if (params.context.isViewLocked) return false

    return size(checkUserDuplications(params.data, params.context.users))
  },
}

export const hasEmptyRequiredFields = (data) => {
  const { name, surname, role, roles, email, organization } = data

  return !name || !surname || !role || !email || !organization || isEmpty(roles)
}

/*
 * check if user has valid roles and restricted domains are not allowed (in launchpad)
 */
export const hasValidRoleWithoutRestrictions = ({ data, teamsList }) => {
  const allowedWithoutActiveRestrictions = {
    [teamTitles.INTEGRATION]: [
      userRoles.LEAD,
      userRoles.MEMBER_WITH_VC,
      userRoles.MEMBER_WITHOUT_VC,
      userRoles.MEMBER_WITHOUT_VC_READ_ONLY,
      userRoles.RESTRICTED,
      userRoles.RESTRICTED_WITH_VC,
    ],
    [teamTitles.CENTRAL]: [userRoles.RESTRICTED],
  }

  let result = true

  for (const [team, roles] of Object.entries(data.teams.allTeams)) {
    const teamType = flow(find({ longName: team }), getFp('teamType'))(teamsList)

    for (const [key, roleStatus] of Object.entries(roles)) {
      const role = toLower(key)
      const roleConfigurations = allowedWithoutActiveRestrictions[teamType] || []

      if (roleStatus === true && roleConfigurations.includes(role)) {
        continue
      }

      if (roleStatus) {
        result = false
        break
      }
    }

    if (!result) break
  }

  return result
}

import { handleActions } from 'redux-actions'
import { last } from 'lodash/fp'
import * as constants from '@teamValueCaptureV2/teamValueCaptureStatusUpdateV3/actions/actionTypes'

type TitlesType = {
  statusRationalAgendaTopics: string
  keyUpdatesToInitiatives: string
}

export interface IStatusUpdateState {
  id: number | null
  currentStatus: string
  lastWeekStatus: string
  statusRationalAgendaTopics: string
  keyUpdatesToInitiatives: string
  statusUpdatedAt: string
  updatedAt: string
  titles: TitlesType
}

export const initialState = {
  id: null,
  currentStatus: '',
  lastWeekStatus: '',
  statusRationalAgendaTopics: '',
  keyUpdatesToInitiatives: '',
  statusUpdatedAt: '',
  updatedAt: '',
  titles: {
    statusRationalAgendaTopics: '',
    keyUpdatesToInitiatives: '',
  },
}

const statusUpdateReducer = handleActions<IStatusUpdateState, $TSFixMe>(
  {
    [constants.SET_TEAM_VALUE_CAPTURE_STATUS]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      }
    },
    [constants.SET_TEAM_VALUE_CAPTURE_STATUS_ITEM]: (state, { payload }) => {
      const { key, value } = payload

      if (key.startsWith('titles.')) {
        const titleKey: string = last(key.split('.')) || 'non_existent_key'

        if (titleKey === 'non_existent_key') return state

        return {
          ...state,
          titles: {
            ...state.titles,
            [titleKey]: value,
          },
        }
      }

      return {
        ...state,
        [key]: value,
      }
    },
  },
  initialState,
)

export default statusUpdateReducer

import { handleActions } from 'redux-actions'
import * as constants from '@teamStatusTracker/actions/actionTypes'

export interface ITeamStatusTrackerState {
  statuses: $TSFixMe[]
}

export const initialState = {
  statuses: [],
}

const teamStatusTrackerReducer = handleActions<ITeamStatusTrackerState, $TSFixMe>(
  {
    [constants.SET_TEAM_STATUS_TRACKER_DATA]: (state, action) => ({ statuses: action.payload }),

    [constants.UPDATE_IMO_TEAM_TOPICS_SUCCESS]: (state, action) => {
      const { teamId, imoTopics } = action.payload

      return {
        statuses: state.statuses.map((team) => (team.id === teamId ? { ...team, imoTopics } : team)),
      }
    },
  },
  initialState,
)

export default teamStatusTrackerReducer

export const CHANGE_DELIVERABLE_TYPE = 'CHANGE_DELIVERABLE_TYPE'
export const CREATE_DELIVERABLE = 'CREATE_DELIVERABLE'
export const UPDATE_DELIVERABLE = 'UPDATE_DELIVERABLE'
export const DELETE_DELIVERABLE = 'DELETE_DELIVERABLE'
export const GET_DELIVERABLES = 'GET_DELIVERABLES'
export const ON_DELIVERABLES_SUCCESS = 'ON_DELIVERABLES_SUCCESS'
export const GET_DELIVERABLES_DATA = 'GET_DELIVERABLES_DATA'
export const ON_DELIVERABLE_TYPE_SUCCESS = 'ON_DELIVERABLE_TYPE_SUCCESS'
export const GET_DELIVERABLE_TYPES = 'GET_DELIVERABLE_TYPES'
export const UPDATE_DELIVERABLE_TYPES = 'UPDATE_DELIVERABLE_TYPES'
export const CREATE_DELIVERABLE_FILES = 'CREATE_DELIVERABLE_FILES'
export const DELETE_DELIVERABLE_FILE = 'DELETE_DELIVERABLE_FILE'

export const STORE_NUMBER_OF_ORG_DESIGN_LAYERS = 'STORE_NUMBER_OF_ORG_DESIGN_LAYERS'

import HttpClient from '../HttpClient'
import Config from '@root/src/config'
import { buildUrlPath } from '@imo/imo-ui-toolkit'
import { SynergyType } from '../../types/Initiative/shared'

export default class BatchUploadActualsApi extends HttpClient {
  constructor(fetchArg: $TSFixMe) {
    super(fetchArg)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  checkBulkUploadFile = ({ query }: { query: { teamId: number; statusId: number } }) => {
    return this.call({
      pathname: `teams/${query.teamId}/value-capture/v2/detailed-financial-data/retrieve-upload-actuals/${query.statusId}`,
    })
  }

  getBulkUploadFileId = ({ query, body }: { query: { url: string }; body: $TSFixMe }) => {
    const { url } = query

    return this.call(
      {
        pathname: url,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  getAmountOfInitiatives = ({
    query,
    body,
  }: {
    query: { teamId: number; synergyType: SynergyType }
    body: $TSFixMe
  }) => {
    const { teamId, synergyType } = query

    return fetch(
      buildUrlPath(
        `teams/${teamId}/value-capture/v2/detailed-financial-data/initiative-amount?synergyType=${synergyType}`,
        null,
        Config.API_SERVER,
      ),
      body,
    )
  }

  sendBulkUploadActualsFile = ({
    query,
    body,
  }: {
    query: { teamId: number; synergyType: SynergyType }
    body: $TSFixMe
  }) => {
    return fetch(
      buildUrlPath(
        `teams/${query.teamId}/value-capture/v2/detailed-financial-data/upload-actuals`,
        { synergyType: query.synergyType },
        Config.API_SERVER,
      ),
      body,
    )
  }

  sendBulkUploadFile = ({ query, body }: { query: { teamId: number; synergyType: SynergyType }; body: $TSFixMe }) => {
    const { teamId, synergyType } = query

    return fetch(
      buildUrlPath(
        `teams/${teamId}/value-capture/v2/initiatives-v2/upload?synergyType=${synergyType}`,
        null,
        Config.API_SERVER,
      ),
      body,
    )
  }

  fetchBulkUploadDownloadTemplate = ({
    query,
    body,
  }: {
    query: { teamId: number; fileId: string }
    body: { headers: { authorization: string; ['x-tenant']: string }; method: 'GET' }
  }) => {
    const { teamId, fileId } = query

    return fetch(
      buildUrlPath(`teams/${teamId}/value-capture/v2/initiatives-v2/export/excel/${fileId}`, null, Config.JOB_SERVER),
      body,
    )
  }

  getBulkUploadDownloadTemplate = ({
    query,
    body,
  }: {
    query: { teamId: number; synergyType: SynergyType }
    body: { headers: { authorization: string; ['x-tenant']: string }; method: 'GET' }
  }) => {
    const { teamId, synergyType } = query

    return fetch(
      buildUrlPath(
        `teams/${teamId}/value-capture/v2/initiatives-v2/export/initiatives-template?synergyType=${synergyType}`,
        null,
        Config.JOB_SERVER,
      ),
      body,
    )
  }
}

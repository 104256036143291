import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'
import { fetchLateOrAtRiskData, fetchMetricDetails, updateInitiativeMetricDetails } from './lateOrAtRisk'

export default function* lateOrAtRiskSaga() {
  yield all([
    takeLatest(constants.FETCH_LATE_OR_AT_RISK_DATA, fetchLateOrAtRiskData),
    takeLatest(constants.FETCH_METRIC_DETAILS, fetchMetricDetails),
    takeLatest(constants.UPDATE_INITIATIVE_METRIC_DETAILS, updateInitiativeMetricDetails),
  ])
}

import styled from 'styled-components'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { FONT_FAMILY } from '@imo/imo-ui-toolkit'
import { PopoverStyle } from '@shared/Popover/constants'

export const StyledTooltip = styled(Tooltip2)<$TSFixMe>`
  width: ${({ fill }) => (fill ? '100%' : 'auto')};
  ${({ heightMinContent }) => (heightMinContent ? 'height: min-content;' : '')}
`
export const StyledPopover = styled(Popover2)`
  width: ${({ fill }) => (fill ? '100%' : 'auto')};

  button > .bp4-icon-eye-open {
    padding: 0 4px 0 0;
  }
`

export const TooltipContent = styled.div<$TSFixMe>`
  font: 12px ${FONT_FAMILY};
  max-width: ${({ styles }) => styles?.maxWidth || '241px'};
  overflow-x: hidden;
  text-overflow: ellipsis;

  ${({ styles }) => styles}
  h5, h3, b {
    font: 12px ${FONT_FAMILY};
    font-weight: 500;
  }

  h5,
  h3,
  p {
    margin: 0 0 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const PopoverContent = styled(TooltipContent)`
  padding: ${PopoverStyle.VERTICAL_PADDING} ${PopoverStyle.HORIZONTAL_PADDING};

  ${({ styles }) => styles}
`
export const TooltipItem = styled.div<{ active?: boolean }>`
  padding-left: 8px;
  position: relative;

  ${({ active }) => (active ? { fontFamily: FONT_FAMILY } : {})}
  &::before {
    content: '•';
    display: block;
    position: absolute;
    left: 0;
  }
`

import moment from 'moment-business-days'
import { capitalize, getOr } from 'lodash/fp'
import { deliverableStatus } from './deliverableStatuses'
import { VC_DELIVERABLES_IDS, deltaStatusAdaptor } from '../constants'

const AT_RISK_RANGE = 3

export const calculateRiskStatus = (dueDate, statuses) => {
  const today = moment().utc().startOf('day')

  const dueMoment = moment(dueDate).utc().startOf('day')

  const businessDaysDiff = dueMoment.businessDiff(today)
  const includeDueDate = dueMoment.isBusinessDay() ? 1 : 0

  if (today.isAfter(dueMoment, 'day')) return statuses.DELAYED

  if (businessDaysDiff + includeDueDate <= AT_RISK_RANGE) return deliverableStatus.AT_RISK

  return deliverableStatus.ON_TRACK
}

/** Item is potentially different shapes depending on endpoint it's returned by -
 *  should unify in future
 */
export const getDeliverableDisplayStatus = (item) => {
  const isVCDeliverable = VC_DELIVERABLES_IDS.includes(item.id)
  const isODLayerDeliverable = item.orgDesignTeamLayerDetails || item.orgDesignTeamLayerId

  if (isVCDeliverable || isODLayerDeliverable || deliverableStatus.COMPLETE === item.status) {
    return item.status
  }

  const today = moment().utc()
  const startMoment = moment(item.startDate).utc()

  if (today.isBefore(startMoment, 'day')) return deliverableStatus.NOT_STARTED

  return calculateRiskStatus(item.dueDate, deliverableStatus)
}

export const universalStatusFormatter = (value, field, data) => {
  if (Object.keys(deltaStatusAdaptor).includes(value)) {
    return value === 'equal' ? '' : deltaStatusAdaptor[value.toLowerCase()]
  }

  return capitalize(getOr(value, field, data))
}

import { put, call } from 'redux-saga/effects'
import { pick } from 'lodash/fp'
import * as actions from '../actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getTeamCharterApi } from '@common/net'

export const fetchKeyDecisionToMake = createSaga(function* fetchKeyDecisionToMake({
  teamId,
  date,
}: {
  teamId: string
  date: string
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamCharterApi()
  const keyDecisionToMake = yield call(api.request('getKeyDecisionToMake', { query: { teamId, date } }))

  yield put(actions.setKeyDecisionToMake(keyDecisionToMake))
})

export const createKeyDecisionToMakeData = createSaga(function* createKeyDecisionToMakeData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data } = payload
  const api = yield getTeamCharterApi()
  const { id } = yield call(
    api.request('createKeyDecisionToMake', {
      query: { teamId: data.teamId },
      body: pick(['teamId', 'name'], data),
    }),
  )

  const newData = { ...data, id, isNew: false }
  yield put(actions.setUpdateKeyDecisionToMake({ data: newData, id: data.id }))
})

export const updateKeyDecisionToMakeData = createSaga(function* updateKeyDecisionToMakeData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data } = payload
  const api = yield getTeamCharterApi()
  yield call(
    api.request('updateKeyDecisionToMake', {
      query: { teamId: data.teamId, id: data.id },
      body: pick(['teamId', 'name'], data),
    }),
  )

  yield put(actions.setUpdateKeyDecisionToMake({ data, id: data.id }))
})

export const deleteKeyDecisionToMakeData = createSaga(function* deleteKeyDecisionToMakeData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { id, teamId } = payload
  const api = yield getTeamCharterApi()

  yield call(api.request('deleteKeyDecisionToMake', { query: { teamId, id } }))

  yield put(actions.removeKeyDecisionToMake({ id }))
})

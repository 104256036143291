import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class TeamDeliverablesApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch, '')

    this.host = Config.API_SERVER
  }

  getTeamDeliverableData = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: query.include
        ? `/teams/${query.teamId}/deliverables/${query.deliverableId}/?include=["${query.include}"]`
        : `/teams/${query.teamId}/deliverables/${query.deliverableId}`,
    })
  }

  updateTeamDeliverableData = ({ body, query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/deliverables/${query.deliverableId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  updateTeamDeliverableMetaData = ({ body, query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/teams/${query.teamId}/deliverables/${query.deliverableId}/meta`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  getDeliverables = ({ query }: $TSFixMe) => {
    const { teamId } = query

    return this.call({ pathname: `/teams/${teamId}/deliverables?include=deliverableType` })
  }

  getTeamDeliverablesTracker = ({ query }: $TSFixMe) => {
    const { teamId } = query

    return this.call({
      pathname: `teams/${teamId}/team-deliverable-tracker`,
    })
  }

  getActionComments = ({ query }: $TSFixMe) => {
    const { teamId, deliverableId, offset } = query

    return this.call({
      pathname: `teams/${teamId}/deliverables/${deliverableId}/action-comments`,
      query: { offset },
    })
  }

  createActionComment = ({ query, body }: $TSFixMe) => {
    const { teamId, deliverableId } = query

    return this.call(
      {
        pathname: `teams/${teamId}/deliverables/${deliverableId}/action-comments`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  createAttachment = ({ query, body }: $TSFixMe) => {
    const { teamId, deliverableId, key } = query

    return this.call(
      {
        pathname: `/teams/${teamId}/deliverables/${deliverableId}/attachments`,
        query: {
          key,
        },
      },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  deleteAttachment = ({ query }: $TSFixMe) => {
    const { teamId, deliverableId, attachmentId } = query

    return this.call(
      {
        pathname: `/teams/${teamId}/deliverables/${deliverableId}/attachments/${attachmentId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }
}

import { midfetch } from '@nvt/mid-react'
import MyTeamApi from './myImoClient/MyTeamApi'
import TeamCharterApi from './myImoClient/TeamCharterApi'
import MutualDiscoveryApi from './myImoClient/MutualDiscoveryApi'
import DayOneReadinessApi from './myImoClient/DayOneReadinessApi'
import FunctionalActivityMappingApi from './myImoClient/FunctionalActivityMappingApi'
import FunctionalProcessDesignApi from './myImoClient/FunctionalProcessDesignApi'
import InitiativeListV2Api from './myImoClient/InitiativeListV2Api'
import TeamValueCaptureStatusUpdateApi from '@common/net/myImoClient/teamValueCaptureStatusUpdateV3Api'
import CommunicationsApi from './myImoClient/CommunicationsApi'
import TeamDeliverablesApi from './myImoClient/TeamDeliverablesApi'
import HypercareLogApi from './myImoClient/HypercareLogApi'
import TeamWorkspaceApi from './myImoClient/TeamWorkspaceApi'
import ImoControlTowerApi from './myImoClient/ImoControlTowerApi'
import TargetListV2Api from './myImoClient/TargetListV2Api'
import VCTrackerV2Api from './myImoClient/VCTrackerV2Api'
import Day1IssueKPIsApi from './myImoClient/Day1IssueKPIsApi'
import TeamResourcesApi from './myImoClient/TeamResourcesApi'
import CentralFunctionalTSAApi from './myImoClient/CentralFunctionalTSAApi'
import TSAExitPlansApi from './myImoClient/TSAExitPlansApi'
import RegionalLocalTSAApi from './myImoClient/RegionalLocalTSAApi'
import DataRequestTrackerApi from './myImoClient/DataRequestTrackerApi'
import VCSynergyCategoryApi from './myImoClient/VCSynergyCategoryApi'
import ProjectMapApi from './myImoClient/ProjectMapApi'
import StatusUpdateApi from './myImoClient/StatusUpdateApi'
import KnowledgeCenterApi from './myImoClient/KnowledgeCenterApi'
import DayOneApi from './myImoClient/DayOneApi'
import ProjectWatchersApi from './myImoClient/ProjectWatchersApi'
import TaskWatchersApi from './myImoClient/TaskWatchersApi'
import ProgramProjectPlanApi from './myImoClient/ProgramProjectPlanApi'
import SharedApi from './myImoClient/SharedApi'
import ProjectAnalyticsApi from './myImoClient/ProjectAnalyticsApi'
import ProjectActivityLogApi from './myImoClient/ProjectActivityLogApi'
import TaskActivityLogApi from './myImoClient/TaskActivityLogApi'
import InitiativeActivityLogApi from './myImoClient/InitiativeActivityLogApi'
import InitiativeWatchersApi from './myImoClient/InitiativeWatchersApi'
import TSAv2Api from './myImoClient/TSAv2Api'
import BatchUploadActualsApi from './myImoClient/BatchUploadActualsApi'
import InterdependentTaskWatchersApi from './myImoClient/InterdependentTaskWatchersApi'

export const getMyTeamApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new MyTeamApi(yield midfetch())
}

export const getDeliverablesApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TeamDeliverablesApi(yield midfetch())
}

export const getTeamCharterApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TeamCharterApi(yield midfetch())
}

export const getMutualDiscoveryApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new MutualDiscoveryApi(yield midfetch())
}

export const getDayOneApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new DayOneApi(yield midfetch())
}

export const getProjectWatchersApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new ProjectWatchersApi(yield midfetch())
}

export const getInitiativeWatchersApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new InitiativeWatchersApi(yield midfetch())
}

export const getProjectActivityLogApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new ProjectActivityLogApi(yield midfetch())
}

export const getTaskActivityLogApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TaskActivityLogApi(yield midfetch())
}

export const getTaskWatchersApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TaskWatchersApi(yield midfetch())
}

export const getInterdependentTaskWatchersApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new InterdependentTaskWatchersApi(yield midfetch())
}

export const getInitiativeActivityLogApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new InitiativeActivityLogApi(yield midfetch())
}

export const getDayOneReadinessApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new DayOneReadinessApi(yield midfetch())
}

export const getFunctionalActivityMappingApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new FunctionalActivityMappingApi(yield midfetch())
}

export const getInitiativeListV2Api = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new InitiativeListV2Api(yield midfetch())
}

export const getTeamValueCaptureStatusUpdateApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TeamValueCaptureStatusUpdateApi(yield midfetch())
}

export const getProjectMapApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new ProjectMapApi(yield midfetch())
}

export const getStatusUpdateApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new StatusUpdateApi(yield midfetch())
}

export const getCommunicationsApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new CommunicationsApi(yield midfetch())
}

export const getHypercareLogApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new HypercareLogApi(yield midfetch())
}

export const getTeamWorkspaceApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TeamWorkspaceApi(yield midfetch())
}

export const getTargetListV2Api = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TargetListV2Api(yield midfetch())
}

export const getImoControlTowerApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new ImoControlTowerApi(yield midfetch())
}

export const getVCTrackerV2Api = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new VCTrackerV2Api(yield midfetch())
}

export const getProgramProjectPlanApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new ProgramProjectPlanApi(yield midfetch())
}

export const getDay1IssueKPIsApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new Day1IssueKPIsApi(yield midfetch())
}

export const getFunctionalProcessDesignApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new FunctionalProcessDesignApi(yield midfetch())
}

export const getCentralFunctionalTSAApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new CentralFunctionalTSAApi(yield midfetch())
}

export const getTSAExitPlansApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TSAExitPlansApi(yield midfetch())
}

export const getTeamResourcesApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TeamResourcesApi(yield midfetch())
}

export const getRegionalLocalTSAApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new RegionalLocalTSAApi(yield midfetch())
}

export const getDataRequestTrackerApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new DataRequestTrackerApi(yield midfetch())
}

export const getVCSynergyCategoryApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new VCSynergyCategoryApi(yield midfetch())
}

export const getKnowledgeCenterApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new KnowledgeCenterApi(yield midfetch())
}

export const getSharedApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new SharedApi(yield midfetch())
}

export const getProjectAnalyticsApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new ProjectAnalyticsApi(yield midfetch())
}

export const getTSAv2Api = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new TSAv2Api(yield midfetch())
}

export const getBatchUploadActualsApi = function* (): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  return new BatchUploadActualsApi(yield midfetch())
}

import { pick } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getDayOneApi } from '@common/net'

import { createSaga } from '@common/sagaCreator/createSaga'
import * as constants from '@dayOne/actions/actionTypes'
import { delay } from 'redux-saga'
import { getRowNodeIdProcessItem } from '@shared/DayOne/utils/mapDayOneData'
import { setSelectedProcessId } from '@dayOne/actions/actions'

export const createKeyProcess = createSaga(function* createKeyProcess(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data: processData, onSuccess, onFailure, disableLoadingOverlay } = action.payload
  try {
    const dayOneApi = yield getDayOneApi()

    const createdData = yield call(
      dayOneApi.request('createKeyProcess', {
        query: { teamId },
        body: pick(processData, 'name'),
      }),
    )

    yield disableLoadingOverlay()

    const rowNodeId = getRowNodeIdProcessItem({ ...processData, ...createdData, teamId, rowId: undefined })

    yield put(setSelectedProcessId(rowNodeId))

    if (onSuccess) yield call(onSuccess)
  } catch (e) {
    yield call(delay, 100)
    if (onFailure) yield call(onFailure)

    throw e
  }
})

export const updateKeyProcess = createSaga(function* updateKeyProcess(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data: processData, onSuccess, onFailure, optimisticUpdate = true } = action.payload
  try {
    if (onSuccess && optimisticUpdate) yield call(onSuccess)

    const dayOneApi = yield getDayOneApi()
    yield call(
      dayOneApi.request('updateKeyProcess', {
        query: {
          teamId: teamId,
          processId: processData.id,
        },
        body: pick(processData, ['name', 'projectListId']),
      }),
    )

    if (onSuccess && !optimisticUpdate) yield call(onSuccess)
  } catch (e) {
    yield call(delay, 100)
    if (onFailure) yield call(onFailure)

    throw e
  }
},
true)

export const deleteKeyProcess = createSaga(function* deleteKeyProcess(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data: processData, onSuccess } = action.payload
  const dayOneApi = yield getDayOneApi()
  yield call(
    dayOneApi.request('deleteKeyProcess', {
      query: {
        teamId: teamId,
        processId: processData.id,
      },
    }),
  )

  if (onSuccess) yield call(onSuccess)
})

export default [
  takeLatest(constants.CREATE_KEY_PROCESS, createKeyProcess),
  takeLatest(constants.UPDATE_KEY_PROCESS, updateKeyProcess),
  takeLatest(constants.DELETE_KEY_PROCESS, deleteKeyProcess),
]

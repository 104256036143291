import { put, call } from 'redux-saga/effects'
import { pick } from 'lodash/fp'
import * as actions from '../actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getTeamCharterApi } from '@common/net'

export const fetchKeyInterdependencies = createSaga(function* fetchKeyInterdependencies({
  teamId,
  date,
}: {
  teamId: string
  date: string
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamCharterApi()

  const keyInterdependencies = yield call(api.request('getKeyInterdependencies', { query: { teamId, date } }))

  yield put(actions.setKeyInterdependencies(keyInterdependencies))
})

export const createKeyInterdependencyData = createSaga(function* createKeyInterdependencyData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data } = payload
  const api = yield getTeamCharterApi()
  const { id } = yield call(
    api.request('createKeyInterdependency', {
      query: { teamId: data.teamId },
      body: pick(['teamId', 'team', 'name'], data),
    }),
  )

  const newData = { ...data, id, isNew: false }
  yield put(actions.setUpdateKeyInterdependency({ data: newData, id: data.id }))
})

export const updateKeyInterdependencyData = createSaga(function* updateKeyInterdependencyData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data } = payload
  const api = yield getTeamCharterApi()
  yield call(
    api.request('updateKeyInterdependency', {
      query: { teamId: data.teamId, id: data.id },
      body: pick(['teamId', 'team', 'name'], data),
    }),
  )
  yield put(actions.setUpdateKeyInterdependency({ data, id: data.id }))
})

export const deleteKeyInterdependencyData = createSaga(function* removeKeyInterdependencyData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, id } = payload
  const api = yield getTeamCharterApi()
  yield call(api.request('deleteKeyInterdependency', { query: { teamId, id } }))

  yield put(actions.removeKeyInterdependency({ id }))
})

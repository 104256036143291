export default class Storage {
  static setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  static getItem(key) {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch {
      return localStorage.getItem(key)
    }
  }

  static removeItem(key) {
    localStorage.removeItem(key)
  }
}

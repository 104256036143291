import React from 'react'
import { synergyTypes } from '@helpers/constants'
import { Callout, Colors, Intent } from '@blueprintjs/core'
import { Content, StyledWarningLabel } from './index.styles'
import { IAlertProps, IGetWarningAlertProps } from './types.d'

export const getCostWarningMessage = (newValue: number, oldValue: number, lastYear: string) => (
  <Content>
    <div>
      This column is automatically calculated based on the sum of {lastYear} monthly in-periods <b>{oldValue}</b>. If
      you wish to proceed, the tables will be recalculated based on the updated value <b>{newValue}</b>.
    </div>
    <Callout intent={Intent.WARNING}>
      Note: if you previously made manual adjustments to the detailed financials currency and FTE, those changes will be
      lost if you proceed. You may cancel and manually update the sidebar.
    </Callout>
    <StyledWarningLabel>Would you like to continue?</StyledWarningLabel>
  </Content>
)

export const getRevenueWarningMessage = (newValue: number, oldValue: number, lastYear: string) => (
  <Content>
    <div>
      This column is automatically calculated based on the sum of {lastYear} monthly in-periods <b>{oldValue}</b>. If
      you wish to proceed, the tables will be recalculated based on the updated value <b>{newValue}</b>.
    </div>
    <Callout intent={Intent.WARNING}>
      Note: if you previously made manual adjustments to the detailed financials revenue and margin, those changes will
      be lost if you proceed. You may cancel and manually update the sidebar.
    </Callout>
    <StyledWarningLabel>Would you like to continue?</StyledWarningLabel>
  </Content>
)

export const getNWCWarningMessage = (newValue: number, oldValue: number) => (
  <Content>
    <div>
      This column is automatically calculated based on the sum of monthly in-periods <b>{oldValue}</b>. If you wish to
      proceed, the tables will be recalculated based on the updated value <b>{newValue}</b>.
    </div>
    <Callout intent={Intent.WARNING}>
      Note: if you previously made manual adjustments to the detailed financials, those changes will be lost if you
      proceed. You may cancel and manually update the sidebar.
    </Callout>
    <StyledWarningLabel>Would you like to continue?</StyledWarningLabel>
  </Content>
)

export const getWarningAlertProps = ({
  synergyType,
  newValue,
  oldValue,
  context,
}: IGetWarningAlertProps): IAlertProps => {
  const { valuesForValidation } = context

  const props: IAlertProps = {
    fill: Colors.ORANGE2,
    intent: Intent.WARNING,
    confirmButtonLabel: 'Update',
    icon: 'error',
    content: '',
  }

  switch (synergyType) {
    case synergyTypes.REVENUE:
      props.content = getRevenueWarningMessage(newValue, oldValue, valuesForValidation.lastYear)
      break

    case synergyTypes.NWC:
      props.content = getNWCWarningMessage(newValue, oldValue)
      break

    case synergyTypes.COST:
      props.content = getCostWarningMessage(newValue, oldValue, valuesForValidation.lastYear)
      break

    default:
      break
  }

  return props
}

import { call, put } from 'redux-saga/effects'
import { setTeamDeliverablesData } from '@teamDeliverables/actions/actions'
import { getDeliverablesApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchTeamDeliverables = createSaga(function* fetchTeamDeliverables(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = action.payload

  const api = yield getDeliverablesApi()
  const deliverables = yield call(
    api.request('getDeliverables', {
      query: { teamId },
    }),
  )

  yield put(setTeamDeliverablesData({ deliverables }))
})

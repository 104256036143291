import { combineValidators } from '@helpers/validators'
import { capitalize, get, isNull, set } from 'lodash'
import { actionTypes } from '@common/notifications/utils'
import { GRID_CELL_UPDATE } from '@generic/actions/actionTypes'
import { openNotificationWithIcon, validateValueUniqueness } from '@imo/imo-ui-toolkit'
import { toStringSafe } from './utils'
import { curry, flow, isEqual, isString, mapValues, pick } from 'lodash/fp'

export const normalizeStringValue = curry((isCaseSensitive, value) => {
  if (!isString(value)) return value

  const cleanValue = value?.trim()

  return isCaseSensitive ? cleanValue : cleanValue?.toLowerCase()
})

export const validateHierarchyValueUniqueness =
  (isCaseSensitive = false, itemNaming = 'item', customMessage) =>
  (newValue, data, params) => {
    const {
      node,
      api,
      colDef: { field },
      context: { hasValidationByTeam },
    } = params

    const isValid = {
      valid: true,
      message: null,
    }

    const normalizeValue = normalizeStringValue(isCaseSensitive)

    if (newValue)
      api.forEachNode((itemProps) => {
        const { data, id, level, parent } = itemProps
        const isDifferentTeam = hasValidationByTeam && get(data, 'teamId') !== get(node, ['data', 'teamId'])

        if (id === node.id || level !== node.level || parent.id !== node?.parent?.id || isDifferentTeam) return

        const otherValue = get(data, field, '')

        const isSame = normalizeValue(newValue) === normalizeValue(otherValue)

        if (isSame) {
          isValid.valid = false
          isValid.message = customMessage || `${capitalize(itemNaming)} ${newValue?.trim()} already exists`
        }
      })

    return isValid
  }

export const validateComplexValueUniqueness =
  (fieldsToCompare = [], isCaseSensitive = false, itemNaming = 'item', customMessage) =>
  (newValue, data, params) => {
    const {
      node,
      api,
      colDef: { field },
    } = params

    const isValid = {
      valid: true,
      message: null,
    }

    const normalizeString = normalizeStringValue(isCaseSensitive)

    const normalizeValueObject = (data) => {
      return flow([
        pick(fieldsToCompare),
        mapValues((value) => {
          if (!isString(value)) return value

          return normalizeString(value)
        }),
      ])(data)
    }

    if (newValue)
      api.forEachNode((itemProps) => {
        const { data: itemData, id, level, parent } = itemProps

        if (id === node.id || level !== node.level || parent.id !== node?.parent?.id) return

        const isSame = isEqual(normalizeValueObject(itemData), normalizeValueObject({ ...data, [field]: newValue }))

        if (isSame) {
          isValid.valid = false
          isValid.message = customMessage || `${capitalize(itemNaming)} for the same combination already exists`
        }
      })

    return isValid
  }

export const combineGridValidators = (
  params,
  field,
  validators = [],
  checkIsUnique = false,
  updateIfNull = false,
  customMessage,
) => {
  const { newValue, api, data, node } = params

  if (!api) return false

  const oldValue = get(data, field)

  if (toStringSafe(oldValue) !== toStringSafe(newValue)) {
    const isValid = combineValidators(
      checkIsUnique
        ? [...validators, validateValueUniqueness({ field, api, rowId: node.id, isCaseSensitive: false })]
        : validators,
      newValue,
      data,
      params,
    )

    if (isValid.valid) {
      set(data, field, newValue)

      return true
    }

    if (updateIfNull && isNull(oldValue)) {
      data[field] = ''
      const msg = customMessage || isValid.message

      openNotificationWithIcon({
        errorMessage: msg,
        actionType: actionTypes.VALIDATION_FAIL,
        action: !msg ? GRID_CELL_UPDATE : null,
      })

      return true
    }

    const msg = customMessage || isValid.message

    openNotificationWithIcon({
      errorMessage: msg,
      actionType: actionTypes.VALIDATION_FAIL,
      action: !msg ? GRID_CELL_UPDATE : null,
    })
  }

  return false
}

import React from 'react'
import { Divider } from '@blueprintjs/core'
import { GroupName, TeamItemGroupName } from './TeamSelector.styles'
import { mutateValueForSMOCase } from '@helpers/smoTransformations'

interface Props {
  groupName: string
  isSMO?: boolean
}

const ListGroupName = ({ groupName, isSMO }: Props) => (
  <TeamItemGroupName>
    <Divider className="group-divider" />
    <GroupName title={groupName}>{isSMO ? mutateValueForSMOCase(groupName) : groupName}</GroupName>
    <Divider className="group-divider right" />
  </TeamItemGroupName>
)

ListGroupName.defaultProps = {
  isSMO: false,
}

export default ListGroupName

import { put, call } from 'redux-saga/effects'
import { getMutualDiscoveryApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { setProcessesFromLibrary } from '../actions/actions'

export const fetchProcessesFromLibraryForMDPA = createSaga(function* fetchMDPAProjectList({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getMutualDiscoveryApi()
  const { libraryId, teamId } = payload

  const processes = yield call(api.request('getLibraryProcessesList', { query: { libraryId, teamId } }))

  yield put(setProcessesFromLibrary(processes))
})

import { STATUS_INTENT_COLOR } from '@imo/imo-ui-toolkit'

export const deliverableStatus = {
  NOT_STARTED: 'Not Started',
  COMPLETE: 'Completed',
  SUBMITTED: 'Submitted',
  ON_TRACK: 'On Track',
  DELAYED: 'Delayed',
  AT_RISK: 'At Risk',
} as const

export const DELIVERABLE_STATUS_COLORS = {
  [deliverableStatus.NOT_STARTED]: STATUS_INTENT_COLOR.NONE,
  [deliverableStatus.COMPLETE]: STATUS_INTENT_COLOR.PRIMARY,
  [deliverableStatus.SUBMITTED]: STATUS_INTENT_COLOR.PRIMARY,
  [deliverableStatus.ON_TRACK]: STATUS_INTENT_COLOR.SUCCESS,
  [deliverableStatus.DELAYED]: STATUS_INTENT_COLOR.DANGER,
  [deliverableStatus.AT_RISK]: STATUS_INTENT_COLOR.WARNING,
} as const

export const weekStatus = {
  ON_TRACK: 'on track',
  DELAYED: 'delayed',
  AT_RISK: 'at risk',
  NOT_STARTED: 'not started',
} as const

export const WEEK_STATUS_COLORS = {
  [weekStatus.NOT_STARTED]: STATUS_INTENT_COLOR.NONE,
  [weekStatus.ON_TRACK]: STATUS_INTENT_COLOR.SUCCESS,
  [weekStatus.DELAYED]: STATUS_INTENT_COLOR.DANGER,
  [weekStatus.AT_RISK]: STATUS_INTENT_COLOR.WARNING,
} as const

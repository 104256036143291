import { isEmpty } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { Image, Logo, LogoWrapper } from './Header.styles'
import { isSMO } from '@myImoConfig/containers/ConfigSteps/utils'
import { useSelector } from 'react-redux'
import { getCachedCompanyLogos } from '@generic/selectors'

const LogoContainer = ({ sources, managementType, className }) => {
  const { blobCompanyLogoA, blobCompanyLogoB } = useSelector(getCachedCompanyLogos)
  const logoAisEmpty = isEmpty(sources.companyALogo)
  const logoBisEmpty = isEmpty(sources.companyBLogo)
  const showLogoSeparator = !(logoAisEmpty || logoBisEmpty)
  const logoSeparator = isSMO(managementType) ? '/' : '×'
  const companyALogo = blobCompanyLogoA || sources.companyALogo
  const companyBLogo = blobCompanyLogoB || sources.companyBLogo

  return !isEmpty(sources) ? (
    <LogoWrapper className={className}>
      {!logoAisEmpty && (
        <Logo className={'logo-image'}>
          <Image src={companyALogo} />
        </Logo>
      )}
      {showLogoSeparator && <span className="logo-separator">{logoSeparator}</span>}
      {!logoBisEmpty && (
        <Logo className={'logo-image'}>
          <Image src={companyBLogo} />
        </Logo>
      )}
    </LogoWrapper>
  ) : null
}

LogoContainer.propTypes = {
  sources: PropTypes.object,
  managementType: PropTypes.string,
  className: PropTypes.string,
}

LogoContainer.defaultProps = {
  sources: {},
  managementType: 'IMO',
  className: null,
}

export default LogoContainer

import { handleActions, Action } from 'redux-actions'
import * as constants from '@dayOne/actions/actionTypes'
import { isNil, omit, pickBy } from 'lodash'
import { getRowNodeIdProcessItem } from '@shared/DayOne/utils/mapDayOneData'
import {
  getUpdatedItemAndChildrenHierarchy,
  updatedPredecessorFollowerRelations,
  updateProcessListWithNewData,
  updateDayOneList,
} from '@dayOne/reducers/utils'
import { loadingState } from '@imo/imo-ui-toolkit/dist/helpers/constants'
import { setSelectedItems } from '@dayOne/utils'
import { getDayOneRowNodeId } from '@shared/DayOne/LibraryPopup/utils/formatLibrariesList'
import * as socketConstants from '@domain/realTime/actions/actionTypes'
import { DayOneProjectListDto } from '@common/types/dtos/DayOneProjectListDto'

export interface ProjectListMeta {
  status: 'init' | 'fetching' | 'ready'
  // the team the project list fetched for
  teamId: number | null
}

export interface IDayOneState {
  dayOneProjectList: DayOneProjectListDto
  isLockedDayOneProjectList: boolean
  libraryProjectList: $TSFixMe[]
  selectedProcessId: $TSFixMe | null
  //used to scroll to active cell when clicking on active user
  visibleRowId: string | null
  imoTeamHasLinkedLibraries: boolean
  libraryProjectListState: string
  snapshotMinDate: $TSFixMe
  filter: $TSFixMe | null
  // to check to see if projects have been fetched for this team
  projectListMeta: ProjectListMeta
}

export const initialState: IDayOneState = {
  dayOneProjectList: [],
  isLockedDayOneProjectList: false,
  libraryProjectList: [],
  selectedProcessId: null,
  visibleRowId: null,
  imoTeamHasLinkedLibraries: false,
  libraryProjectListState: loadingState.INITIAL,
  snapshotMinDate: new Date(),
  filter: null,
  projectListMeta: { status: 'init', teamId: null },
}

const dayOneReducer = handleActions<IDayOneState, $TSFixMe>(
  {
    [constants.UPDATE_PROCESS_LIST_IDS]: (
      state,
      action: Action<{ updates: { projectListId: number; teamKeyProcessId: number }[] }>,
    ) => {
      const updateMap = action.payload.updates.reduce<Record<number, number>>((map, data) => {
        map[data.teamKeyProcessId] = data.projectListId
        return map
      }, {})

      const nextProjectlist = state.dayOneProjectList.map((item) => {
        if (item.type === 'keyProcess' && updateMap[item.id]) {
          item.projectListId = updateMap[item.id].toString()
        }

        if (item.type === 'project' && updateMap[item.keyProcessId]) {
          item.projectListId = `${updateMap[item.keyProcessId]}.${item.projectListId?.split('.').at(1)}`
        }

        if (item.type === 'task' && updateMap[item.keyProcessId]) {
          const split = item.projectListId?.split('.')
          if (split && split.length === 3) {
            item.projectListId = `${updateMap[item.keyProcessId]}.${split.at(1)}.${split.at(2)}`
          }
        }

        return item
      })
      return { ...state, dayOneProjectList: nextProjectlist }
    },
    [constants.UPDATE_PROJECT_LIST_IDS]: (
      state,
      action: Action<{
        updates: { projectId: number; processListId: number; projectListId: number; keyProcessId: number }[]
      }>,
    ) => {
      const inPayload = (projectId: number) => action.payload.updates.find((u) => u.projectId === projectId)
      const taskUpdateDictionaryByProject = state.dayOneProjectList.reduce<
        Record<number, Record<number, { taskId: number } & (typeof action.payload.updates)[0]>>
      >((acc, cur) => {
        const projectAndProcessMeta = cur.type === 'task' && cur.projectId && inPayload(cur.projectId)
        if (cur.type === 'task' && projectAndProcessMeta) {
          if (acc[projectAndProcessMeta.projectId]) {
            acc[projectAndProcessMeta.projectId][cur.id] = { taskId: cur.id, ...projectAndProcessMeta }
          } else {
            acc[projectAndProcessMeta.projectId] = { [cur.id]: { taskId: cur.id, ...projectAndProcessMeta } }
          }
        }
        return acc
      }, {})

      const next = state.dayOneProjectList.map((item) => {
        const projectAndProcessMeta = inPayload(item.id)
        if (item.type === 'project' && projectAndProcessMeta) {
          item.projectListId = `${projectAndProcessMeta.processListId}.${projectAndProcessMeta.projectListId}`
          item.keyProcessId = projectAndProcessMeta.keyProcessId
        }

        if (item.type === 'task' && item.projectId && taskUpdateDictionaryByProject[item.projectId]?.[item.id]) {
          item.projectListId = `${taskUpdateDictionaryByProject[item.projectId][item.id].processListId}.${
            taskUpdateDictionaryByProject[item.projectId][item.id].projectListId
          }.${item.projectListId?.split('.').at(-1)}`
        }
        return item
      })

      return { ...state, dayOneProjectList: next }
    },
    [constants.UPDATE_TASK_LIST_IDS_AND_PROJECT]: (
      state,
      action: Action<{ updates: { id: number; listId: string; projectId?: number }[] }>,
    ) => {
      const dayOneProjectList = state.dayOneProjectList.map((item) => {
        if (item.type === 'task') {
          const updatedItem = action.payload.updates.find((update) => update.id === item.id)
          if (updatedItem) {
            return {
              ...item,
              projectListId: updatedItem.listId,
              projectId: updatedItem.projectId || item.projectId,
            }
          }
        }
        return item
      })

      return {
        ...state,
        dayOneProjectList,
      }
    },
    [constants.FETCH_PROJECT_LIST]: (state, action) => {
      const { teamId } = action.payload

      return {
        ...state,
        projectListMeta: {
          status: 'fetching',
          teamId,
        },
      }
    },
    [constants.FETCH_PROGRAM_PROJECT_PLAN_DATA]: (state, action) => {
      const { teamId } = action.payload

      return {
        ...state,
        projectListMeta: {
          status: 'fetching',
          teamId,
        },
      }
    },
    [constants.SET_PROJECT_LIST]: (state, action) => {
      const { dayOneProjectList, teamId } = action.payload

      return {
        ...state,
        dayOneProjectList: isNil(dayOneProjectList) ? [] : dayOneProjectList,
        isLockedDayOneProjectList: isNil(dayOneProjectList),
        projectListMeta: { status: teamId ? 'ready' : 'init', teamId },
      }
    },
    [constants.SET_SELECTED_PROCESS_ID]: (state, action) => {
      return {
        ...state,
        selectedProcessId: action.payload,
      }
    },
    [constants.SET_VISIBLE_ROW_ID]: (state, action) => {
      return {
        ...state,
        visibleRowId: action.payload,
      }
    },
    [constants.SET_LIBRARY_PROJECT_LIST]: (state, action) => {
      const { libraryProjectList, isInitial, teamId } = action.payload

      return {
        ...state,
        libraryProjectList: setSelectedItems({
          libraryItems: libraryProjectList,
          clientItems: state.dayOneProjectList,
          isInitial,
          teamId,
        }),
      }
    },

    [constants.UPDATE_LIBRARY_PROJECT_LIST]: (state, { payload }) => {
      const { rowNodeId, selected } = payload

      return {
        ...state,
        libraryProjectList: state.libraryProjectList.map((process) =>
          getDayOneRowNodeId({ data: process }) === rowNodeId && !process.disabled
            ? {
                ...process,
                selected,
              }
            : process,
        ),
      }
    },

    [constants.SET_LIBRARY_PROJECT_LIST_STATE]: (state, action) => ({
      ...state,
      libraryProjectListState: action.payload,
    }),

    [constants.UPLOAD_DAY_ONE_PROCESS_ITEM_FILE_SUCCESS]: (state, action) => {
      const { file, rowNodeId } = action.payload
      const { dayOneProjectList } = state

      return {
        ...state,
        dayOneProjectList: dayOneProjectList.map((item) =>
          getRowNodeIdProcessItem(item) === rowNodeId
            ? { ...item, attachments: [...(item.attachments ? item.attachments : []), file] }
            : item,
        ),
      }
    },

    [constants.DELETE_DAY_ONE_PROCESS_ITEM_FILE_SUCCESS]: (state, action) => {
      const { fileId, rowNodeId } = action.payload
      const { dayOneProjectList } = state

      return {
        ...state,
        dayOneProjectList: dayOneProjectList.map((item) =>
          getRowNodeIdProcessItem(item) === rowNodeId
            ? { ...item, attachments: item.attachments?.filter((f: $TSFixMe) => f.id !== fileId) }
            : item,
        ),
      }
    },

    // INTERDEPENDENCIES
    [constants.LAUNCH_INTERDEPENDENCY_SUCCESS]: (state, action) => {
      const { status, comments, senderTeamId, receiverTeamId, id, rowNodeId, receiverTeam } = action.payload

      const interdependency = {
        id,
        status,
        receiverTeamId,
        senderTeamId,
        comments,
        receiverTeam,
        launched: true,
      }

      return {
        ...state,
        dayOneProjectList: state.dayOneProjectList.map((project) => {
          if (getRowNodeIdProcessItem(project) === rowNodeId) {
            return {
              ...project,
              isOutgoing: true,
              interdependency,
            }
          }

          return project
        }),
      }
    },

    [constants.CANCEL_INTERDEPENDENCY_SUCCESS]: (state, action) => {
      const { rowNodeId } = action.payload

      return {
        ...state,
        dayOneProjectList: state.dayOneProjectList.map((project) => {
          if (getRowNodeIdProcessItem(project) === rowNodeId) {
            return { ...omit(project, 'interdependency'), isOutgoing: false }
          }

          return project
        }),
      }
    },

    [constants.UPDATE_INTERDEPENDENCY_TASK_SUCCESS]: (state, action) => {
      const { receiverTeamId, comments, status, owner, rowNodeId } = action.payload

      const data = {
        receiverTeamId,
        comments,
        status,
        owner,
      }

      return {
        ...state,
        dayOneProjectList: state.dayOneProjectList.map((project) => {
          if (getRowNodeIdProcessItem(project) === rowNodeId) {
            return {
              ...project,
              interdependency: {
                ...project.interdependency,
                ...pickBy(data, (value, key) => key === 'owner' || !isNil(value)),
              },
            }
          }

          return project
        }),
      }
    },

    [constants.ADD_LIST_ITEM]: (state, action) => {
      const { dayOneProjectList } = state

      return { ...state, dayOneProjectList: [...dayOneProjectList, action.payload] }
    },

    [constants.REMOVE_LIST_ITEM]: (state, action) => {
      const { dayOneProjectList } = state
      const { rowNodeId } = action.payload

      return {
        ...state,
        dayOneProjectList: dayOneProjectList.filter((item) => getRowNodeIdProcessItem(item) !== rowNodeId),
      }
    },
    [constants.UPDATE_DAY_ONE_ITEM_SUCCESS]: (state, action) => {
      const { data, rowNodeId } = action.payload

      return {
        ...state,
        dayOneProjectList: updateProcessListWithNewData({ list: state.dayOneProjectList, data, rowNodeId }),
      }
    },

    [constants.UPDATE_PREDECESSOR_FOLLOWER_SUCCESS]: (state, action) => {
      const { oldRelated, newRelated, relation, data } = action.payload

      return {
        ...state,
        dayOneProjectList: updatedPredecessorFollowerRelations({
          list: state.dayOneProjectList,
          oldRelated,
          newRelated,
          relation,
          data,
        }),
      }
    },

    [constants.SET_IMO_LINKED_LIBRARIES_STATE]: (state, action) => {
      return { ...state, imoTeamHasLinkedLibraries: action.payload }
    },

    [constants.UPDATE_DAY_ONE_ITEM_ID_SUCCESS]: (state, action) => {
      const { dayOneProjectList, selectedProcessId } = state
      const { rowNodeId, data } = action.payload

      const { items, newHierarchy } = getUpdatedItemAndChildrenHierarchy({
        items: dayOneProjectList,
        data,
        rowNodeId,
      })

      return {
        ...state,
        selectedProcessId:
          rowNodeId === selectedProcessId ? getRowNodeIdProcessItem({ ...data, ...newHierarchy }) : selectedProcessId,
        dayOneProjectList: items,
      }
    },
    [constants.SET_SNAPSHOT_MIN_DATE]: (state, { payload: snapshotMinDate }) => ({
      ...state,
      snapshotMinDate,
    }),
    [constants.SET_PROJECT_LIST_FILTER]: (state, { payload: filter }) => ({
      ...state,
      filter,
    }),
    [socketConstants.WS_DATA_CREATED]: (state, { payload }) => {
      return { ...state, dayOneProjectList: [...state.dayOneProjectList, payload] }
    },
    [socketConstants.WS_DATA_DELETED]: (state, { payload }) => {
      const keyProcessIds: Set<number> = new Set()
      const taskIds: Set<number> = new Set()
      const projectIds: Set<number> = new Set()

      // Key process is deleted
      if (payload.L2Projects) {
        keyProcessIds.add(payload.id)
        for (const project of payload.L2Projects) {
          projectIds.add(project.id)
          if (project.L2Tasks) {
            for (const task of project.L2Tasks) {
              taskIds.add(task.id)
            }
          }
        }
      }

      // Project is deleted
      else if (payload.type === 'project') {
        projectIds.add(payload.id)
        for (const task of payload.tasks ?? []) {
          taskIds.add(task.id)
        }
      }

      // Task is deleted
      else {
        taskIds.add(payload.id)
      }

      return {
        ...state,
        dayOneProjectList: state.dayOneProjectList.filter((listItem) => {
          switch (listItem.type) {
            case 'keyProcess':
              return !keyProcessIds.has(listItem.id)
            case 'project':
              return !projectIds.has(listItem.id)
            case 'task':
              return !taskIds.has(listItem.id)
            default:
              return true
          }
        }),
      }
    },
    [socketConstants.WS_DATA_UPDATED]: (state, { payload }) => {
      const updatedDayOneProjectList = updateDayOneList({ list: state.dayOneProjectList, payload })

      return {
        ...state,
        dayOneProjectList: updatedDayOneProjectList,
      }
    },
  },
  initialState,
)

export default dayOneReducer

import {
  getTitleDeliverable,
  planningAssumptionOrder,
  dayOneDeliverablesOrder,
  teamCharterOrder,
} from '@myImoClient/components/Deliverables/TeamCharter/InScopeKey/utils'
import { first } from 'lodash/fp'
import { itemsListKeys } from '@shared/DayOne/components/ProcessItemDeletionAlertV2/utils'

export const formatProcessDeliverableDetails = (data: $TSFixMe) => {
  if (!data) return {}

  const { allowedDeliverables, onlyProgramProject } = data

  const newData = {
    ...data,
    teamCharterTitle: onlyProgramProject ? '' : getTitleDeliverable(teamCharterOrder, allowedDeliverables),
    mutualDiscoveryTitle: onlyProgramProject ? '' : getTitleDeliverable(planningAssumptionOrder, allowedDeliverables),
    dayOneProjectTitle: getTitleDeliverable(dayOneDeliverablesOrder, allowedDeliverables),
  }

  if (onlyProgramProject) itemsListKeys.forEach((key) => (newData[key] = []))

  return newData
}

export const formatKeyProcessDeleteDetails = (items: $TSFixMe) => formatProcessDeliverableDetails(first(items))

export const formatComplexKeyProcessDeleteDetails = (items: $TSFixMe) => items.map(formatProcessDeliverableDetails)

import { handleActions, combineActions } from 'redux-actions'
import { updateData } from '../utils'
import {
  FETCH_TR_DECISION_LOG_DATA,
  FETCH_DECISION_LOG_DATA_PENDING,
  FETCH_DECISION_LOG_DATA_SUCCESS,
  FETCH_DECISION_LOG_DATA_FAIL,
  UPDATE_DECISION_LOG_DATA_SUCCESS,
  UPDATE_DECISION_LOG_DATA_FAIL,
  ADD_DECISION_LOG_DATA,
  ADD_DECISION_LOG_DATA_SUCCESS,
  DELETE_DECISION_LOG_DATA_SUCCESS,
  FETCH_CONTROL_TOWER_DECISION_LOG_DATA,
  FETCH_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS,
  UPDATE_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS,
} from '../actions/actionTypes'

interface DecisionLog {
  id: number
  decisionId: number
  teamId?: number
  topic?: string
  status?: string
  dueDate?: Date | null
  description?: string | null
  comments?: string | null
  confidential?: boolean | null
  isVisibleToIMO?: boolean | null
  createdAt?: Date
  updatedAt?: Date
}

export interface IDecisionLogState {
  loading: boolean
  createdAt: Date | null
  items: DecisionLog[]
}

export const initialState: IDecisionLogState = {
  loading: false,
  createdAt: null,
  items: [],
}

const decisionLogReducer = handleActions<IDecisionLogState, $TSFixMe>(
  {
    [`${combineActions(
      FETCH_DECISION_LOG_DATA_PENDING,
      FETCH_TR_DECISION_LOG_DATA,
      FETCH_CONTROL_TOWER_DECISION_LOG_DATA,
    )}`]: (state) => ({
      ...state,
      loading: true,
    }),
    [`${combineActions(FETCH_DECISION_LOG_DATA_SUCCESS, FETCH_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS)}`]: (
      state,
      { payload },
    ) => ({
      ...state,
      loading: false,
      createdAt: payload.data.createdAt,
      items: payload.data.items,
    }),
    [`${combineActions(UPDATE_DECISION_LOG_DATA_SUCCESS, UPDATE_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS)}`]: (
      state,
      { payload: { id, body, sortOrder } },
    ) => ({
      ...state,
      items: updateData({ data: state.items, id, body, sortOrder }),
      loading: false,
    }),
    [ADD_DECISION_LOG_DATA]: (state, { payload }) => {
      if (!payload.isNew) {
        return state
      }

      const { items } = state

      return {
        ...state,
        items: [...items, payload],
      }
    },
    [ADD_DECISION_LOG_DATA_SUCCESS]: (state, { payload: { body, sortOrder } }) => {
      return {
        ...state,
        items: updateData({ data: [...state.items, body], id: body.id, sortOrder }) as unknown as DecisionLog[],
      }
    },
    [DELETE_DECISION_LOG_DATA_SUCCESS]: (state, { payload: decisionId }) => {
      const { items } = state

      return {
        ...state,
        items: items.filter((item) => item.id !== decisionId),
      }
    },
    [`${combineActions(FETCH_DECISION_LOG_DATA_FAIL, UPDATE_DECISION_LOG_DATA_FAIL)}`]: (state) => ({
      ...state,
      loading: false,
    }),
  },
  initialState,
)

export default decisionLogReducer

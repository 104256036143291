import { agRichKeyCreator, getParamsForAgRichSelect, getValues } from '@helpers/gridUtils'
import { find, flow, get, getOr, isFunction, isNil } from 'lodash/fp'
import { advancedFinancialsCustomColumnSetter, applyTextColumnFilterParams } from '@shared/Grid/utils/general'
import { customColumnTypes } from './constants'
import { DEFAULT_MAX_LENGTH } from '@helpers/constants'
import { stringComparator } from '@helpers/utilsComparators'

import { fieldNames } from '@myImoClient/components/TeamValueCaptureV2/InitiativeList/constants'
import { InputCellEditor } from '@imo/imo-ui-toolkit'
import { realtimeCellStyle } from '@views/shared/Grid/utils/realTimeUtils'

export const getNameFromOptions = (options = [], id) => flow(find({ id }), getOr('', 'name'))(options)

export const isFreeTextColumn = (type) => type === customColumnTypes.FREE_TEXT

const getTypeSpecificProps = (column, props, additionalProps, additionalPropsForText) => {
  if (isFreeTextColumn(column?.columnType)) {
    const characterLimit = getOr(DEFAULT_MAX_LENGTH, 'characterLimit', column)

    return {
      customField: 'CUSTOM_COLUMN',
      cellEditor: InputCellEditor,
      cellEditorParams: {
        maxLength: characterLimit,
        placeholder: 'Enter text here',
        type: customColumnTypes.FREE_TEXT,
      },
      ...applyTextColumnFilterParams(),
      valueSetter: advancedFinancialsCustomColumnSetter,
      comparator: stringComparator,
      valueGetter: ({ data }) => {
        return flow([get('customColumns'), find({ id: column.id }), get(['option', 'name'])])(data)
      },
      forceExcelIterate: true,
      ...props,
      ...additionalPropsForText,
    }
  }

  return {
    ...getParamsForAgRichSelect(({ context }) => context[column.name]),
    customField: fieldNames.CUSTOM_COLUMN,
    cellEditor: 'agRichSelectCellEditor',
    filter: 'agSetColumnFilter',
    keyCreator: agRichKeyCreator,
    filterParams: {
      debounceMs: 0,
      suppressAndOrCondition: true,
    },
    valueGetter: ({ data }) => {
      const getFieldToDisplay = (column) => {
        if (isNil(column?.option?.id)) return 'name'

        return props.editable ? 'id' : 'name'
      }

      const currentColumn = flow([get('customColumns'), find({ id: column.id })])(data)

      return get(['option', getFieldToDisplay(currentColumn)])(currentColumn) || null
    },
    valueSetter: advancedFinancialsCustomColumnSetter,
    ...props,
    editable: (params) => {
      const { editable: editableFunc } = props
      const { context } = params
      const options = context[column.name]
      const values = getValues(options, params)

      const editable = isFunction(editableFunc) ? editableFunc(params) : editableFunc

      return editable && values.length > 1
    },
    forceExcelIterate: true,
    comparator: stringComparator,
    ...additionalProps,
  }
}

export const getCustomColumnsColumnDefs = (
  customColumns,
  props = {},
  additionalProps = {},
  additionalPropsForText = {},
) => {
  const synergyType = get('synergyType', props)
  const selector = getOr('initiativeType', 'selector', props)
  const activatedColumns = synergyType
    ? customColumns.filter((column) => column[selector]?.includes(synergyType))
    : customColumns

  return activatedColumns.map((column) => ({
    headerName: column.name,
    field: column.name,
    suppressSizeToFit: true,
    width: props.width || 75,
    customColumnId: column.id,
    cellStyle: realtimeCellStyle,
    ...getTypeSpecificProps(column, props, additionalProps, additionalPropsForText),
  }))
}

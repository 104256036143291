import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchTeamResourcesUpdates = createAction(constants.FETCH_TEAM_RESOURCES_UPDATES)
export const fetchTeamResourcesUpdatesWithLoader = createAction(
  constants.FETCH_TEAM_RESOURCES_UPDATES,
  null,
  getMetaWithActionLoading,
)
export const updateTeamResourcesUpdates = createAction(constants.UPDATE_TEAM_RESOURCES_UPDATES)
export const setTeamResourcesUpdates = createAction(constants.SET_TEAM_RESOURCES_UPDATES)

export const fetchMasterCalendarEvents = createAction(constants.FETCH_MC_EVENTS, null, getMetaWithActionLoading)
export const setMasterCalendarEvents = createAction(constants.SET_MC_EVENTS)

export const createMasterCalendarEvent = createAction(constants.CREATE_MC_EVENT, null, getMetaWithActionLoading)
export const createMasterCalendarEventSuccess = createAction(constants.CREATE_MC_EVENT_SUCCESS)

export const updateMasterCalendarEvent = createAction(constants.UPDATE_MC_EVENT, null, getMetaWithActionLoading)
export const updateMasterCalendarEventSuccess = createAction(constants.UPDATE_MC_EVENT_SUCCESS)

export const deleteMasterCalendarEvent = createAction(constants.DELETE_MC_EVENT, null, getMetaWithActionLoading)
export const deleteMasterCalendarEventSuccess = createAction(constants.DELETE_MC_EVENT_SUCCESS)

export const fetchMasterCalendarLabels = createAction(constants.FETCH_MC_LABELS, null, getMetaWithActionLoading)
export const setMasterCalendarLabels = createAction(constants.SET_MC_LABELS)

export const createMasterCalendarLabel = createAction(constants.CREATE_MC_LABEL, null, getMetaWithActionLoading)
export const createMasterCalendarLabelSuccess = createAction(constants.CREATE_MC_LABEL_SUCCESS)

export const updateMasterCalendarLabel = createAction(constants.UPDATE_MC_LABEL, null, getMetaWithActionLoading)
export const updateMasterCalendarLabelWithNoLoader = createAction(constants.UPDATE_MC_LABEL)
export const updateMasterCalendarLabelSuccess = createAction(constants.UPDATE_MC_LABEL_SUCCESS)

export const deleteMasterCalendarLabel = createAction(constants.DELETE_MC_LABEL, null, getMetaWithActionLoading)
export const deleteMasterCalendarLabelSuccess = createAction(constants.DELETE_MC_LABEL_SUCCESS)

export const fetchDocumentSharing = createAction(constants.FETCH_DOCUMENT_SHARING)
export const fetchDocumentSharingWithLoader = createAction(
  constants.FETCH_DOCUMENT_SHARING,
  null,
  getMetaWithActionLoading,
)
export const setDocumentSharing = createAction(constants.SET_DOCUMENT_SHARING)
export const documentSharingCreateFolder = createAction(
  constants.DOCUMENT_SHARING_CREATE_FOLDER,
  null,
  getMetaWithActionLoading,
)
export const documentSharingUpdateFolder = createAction(
  constants.DOCUMENT_SHARING_UPDATE_FOLDER,
  null,
  getMetaWithActionLoading,
)
export const documentSharingCreateFile = createAction(
  constants.DOCUMENT_SHARING_CREATE_FILE,
  null,
  getMetaWithActionLoading,
)
export const documentSharingDeleteNodes = createAction(
  constants.DOCUMENT_SHARING_DELETE_NODES,
  null,
  getMetaWithActionLoading,
)
export const documentSharingDownloadFiles = createAction(constants.DOCUMENT_SHARING_DOWNLOAD_FILES)

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchTeamStatusTrackerData = createAction(
  constants.FETCH_TEAM_STATUS_TRACKER_DATA,
  null,
  getMetaWithActionLoading,
)
export const setTeamStatusTrackerData = createAction(constants.SET_TEAM_STATUS_TRACKER_DATA)

export const updateImoTeamTopics = createAction(constants.UPDATE_IMO_TEAM_TOPICS)
export const updateImoTeamTopicsSuccess = createAction(constants.UPDATE_IMO_TEAM_TOPICS_SUCCESS)

import React from 'react'
import { noop } from 'lodash/fp'

import { MainContent } from './index.styles'

interface Props {
  children: React.ReactNode
  isPdfEnabled?: boolean
  onContextMenu?: (...args: $TSFixMe[]) => $TSFixMe
}

const MainContentContainer = ({ children, isPdfEnabled, onContextMenu }: Props) => {
  return (
    <MainContent isPdfEnabled={isPdfEnabled} onContextMenu={onContextMenu} className="main-content">
      {children}
    </MainContent>
  )
}

MainContentContainer.defaultProps = {
  isPdfEnabled: false,
  onContextMenu: noop,
}

export default MainContentContainer

import { createSelector } from 'reselect'
import {
  getUserTeams,
  getTeamsPermissions,
  getRedirects,
  getRestrictedTeamIds,
  getRole,
  getRoles,
  getAllTeams,
  getSelectedTeamId,
  getUserTeamsInfo,
} from '.'
import { get, getOr, find, toLower, includes, flow, some, uniqBy, flatten, pick } from 'lodash/fp'
import { GLOBAL_IMO_SHORT_NAME, GLOBAL_SMO_SHORT_NAME, userRoles } from '@common/accessController/constants'
import * as teamConstants from '@generic/selectors/teamConstants'
import { getSelectedDeliverable } from '../../myImoClient/myTeam/teamDeliverables/selectors' // TODO: use baseUrl to src
import {
  hasGlobalRole,
  hasImoRole,
  hasOneOfImoRoles,
  checkTeamMember,
  checkIsIMOTeamType,
  checkIsImoWithoutVCRole,
  getGroupedRolesByLevel,
  getIsGlobalImoWithoutVC,
  getIsGlobalImoMember,
  checkIsRestricted,
  checkIsReadOnlyRole,
  getReportersByDeliverableInReview,
  getReportersByDeliverableTypesInReview,
} from './utils'
import { getTeamReporters } from './teamTree'
import { routesList } from '@common/accessController/routesList'
import { getIsReviewer, getUserTeamsFromTree } from '@common/teamFilter/helpers'
import { TASK_PROJECT_DELIVERABLES_IDS } from '@helpers/constants'
import { intersection } from 'lodash'
import { generatePath } from 'react-router-dom'

export const isImoTeamMember = createSelector(getUserTeams, checkTeamMember(GLOBAL_IMO_SHORT_NAME))
export const isSmoTeamMember = createSelector(getUserTeams, checkTeamMember(GLOBAL_SMO_SHORT_NAME))
export const checkGlobalRole = createSelector(getUserTeams, some(hasGlobalRole))
export const isUserHasImoRole = createSelector(getRole, hasImoRole)

export const getRedirect = createSelector(
  getTeamsPermissions,
  getRedirects,
  getRestrictedTeamIds,
  isImoTeamMember,
  (_: $TSFixMe, url: $TSFixMe, routerParams: $TSFixMe) => ({ url, routerParams }),
  (
    teamPermissions: $TSFixMe,
    redirectsRaw: $TSFixMe,
    restrictedTeamIds: $TSFixMe,
    isImo: $TSFixMe,
    { url, routerParams }: $TSFixMe,
  ) => {
    const { teamId } = routerParams
    if (!teamId) return null

    const redirects = getOr(redirectsRaw, [teamId, 'redirects'], teamPermissions)
    const route = find((path) => generatePath(path, routerParams) === url, Object.keys(redirects))

    // Remove redirect if user reviewer of his restricted team
    if (isImo && !restrictedTeamIds.includes(Number(teamId)) && route === routesList.DASHBOARD.MY_TEAM.TEAM_HOME.route)
      return null

    return route ? generatePath(redirects[route], routerParams) : null
  },
)

export const getCurrentUserRole = createSelector(getUserTeams, getSelectedDeliverable, (userTeams, { teamId }) => {
  return flow(
    find(({ id }) => id === teamId),
    get('role'),
  )(userTeams)
})

// get teams without VC access
export const getUserTeamsWithoutVC = (roles: $TSFixMe) => {
  if (!roles) return

  return roles.filter(({ role }: $TSFixMe) =>
    includes(toLower(role), [
      userRoles.RESTRICTED,
      userRoles.MEMBER_WITHOUT_VC,
      userRoles.MEMBER_READ_ONLY,
      userRoles.MEMBER_WITHOUT_VC_READ_ONLY,
    ]),
  )
}

//TODO look at route permission I think it is wrong realisation IMO-5660
export const hasImoWithoutVCRole = createSelector(getUserTeams, (userTeams) => {
  return userTeams.some((team: $TSFixMe) => {
    const { role, teamType } = team || {}

    return checkIsImoWithoutVCRole(role) && checkIsIMOTeamType(teamType)
  })
})

export const hasImoWithoutVCOrReadonlyRole = createSelector(getUserTeams, (userTeams) => {
  return userTeams.some((team: $TSFixMe) => {
    const { role, teamType } = team || {}

    return checkIsIMOTeamType(teamType) && (checkIsImoWithoutVCRole(role) || checkIsReadOnlyRole(role))
  })
})

export const getIsGlobalImoUser = createSelector(getRole, hasGlobalRole)

export const getIsGlobalOrRegionalIMO = createSelector(
  getIsGlobalImoUser,
  getUserTeams,
  (isGlobalImoUser, userTeams) => {
    const isRegionalIMO = userTeams.some(hasImoRole)

    return isGlobalImoUser || isRegionalIMO
  },
)

export const getIsPrimaryIMO = createSelector(
  getRoles,
  (_: $TSFixMe, teamId: $TSFixMe) => teamId,
  (roles, teamId) => some(({ reporterIds }) => reporterIds?.includes(teamId), roles),
)

export const getIsGlobalOrPrimaryIMO = createSelector(
  getIsGlobalImoUser,
  getIsPrimaryIMO,
  (isGlobalImoUser, getIsPrimaryIMO) => {
    return isGlobalImoUser || getIsPrimaryIMO
  },
)

export const getIsSecondaryIMO = createSelector(
  isUserHasImoRole,
  getAllTeams,
  getRoles,
  (_: $TSFixMe, teamId: $TSFixMe) => teamId,
  (hasUserHasImoRole: $TSFixMe, teams: $TSFixMe, roles: $TSFixMe, teamId: $TSFixMe) => {
    return (
      hasUserHasImoRole &&
      some(({ id }) => {
        const team = find({ id }, teams)

        return team?.secondaryReporterIds?.includes(teamId)
      }, roles)
    )
  },
)

export const getSelectedUserTeam = createSelector(getSelectedTeamId, getUserTeams, (selectedTeamId, userTeams) =>
  userTeams.find(({ id }: $TSFixMe) => id === selectedTeamId),
)

export const getSelectedTeam = createSelector(getSelectedTeamId, getAllTeams, (selectedTeamId, allTeams: $TSFixMe) =>
  allTeams.find(({ id }: $TSFixMe) => id === selectedTeamId),
)

export const getFlattenActiveDeliverables: $TSFixMe = createSelector(
  getAllTeams,
  (_: $TSFixMe, selectedTeamId: number) => selectedTeamId,
  (allTeams: $TSFixMe = [], selectedTeamId: number) => {
    const selectedTeam = allTeams.find(({ id }: { id: number }) => id === Number(selectedTeamId))
    const activeDeliverableIds = getOr([], ['activeDeliverableIds'], selectedTeam)
    return flatten(Object.values(activeDeliverableIds))
  },
)

export const getActiveProjectDeliverablesIDs = createSelector(
  getFlattenActiveDeliverables,
  (teamActiveDeliverablesIDs = []) => {
    return intersection(teamActiveDeliverablesIDs, TASK_PROJECT_DELIVERABLES_IDS)
  },
)

export const hasResponsibleCentralTeam = createSelector(
  getRoles,
  (_: $TSFixMe, teamId: $TSFixMe) => teamId,
  (roles: $TSFixMe, teamId: $TSFixMe) => {
    if (!roles) return false

    return roles.some((role: $TSFixMe) => {
      return (
        teamConstants.CENTRAL_ROLES.includes(role.teamType) &&
        role.revieweeTeams &&
        role.revieweeTeams.find((team: $TSFixMe) => team.id === teamId)
      )
    })
  },
)

export const hasIntegrationLead = createSelector(getRoles, (roles) => {
  if (!roles) return false

  return roles.some(({ role, teamType }: $TSFixMe) => {
    return teamConstants.INTEGRATION_ROLES.includes(teamType) && includes(toLower(role), [userRoles.LEAD])
  })
})

export const checkIsIMOReviewer = createSelector(
  getUserTeams,
  getAllTeams,
  (_: $TSFixMe, teamId: $TSFixMe) => teamId,
  (userTeams, allTeams, teamId) =>
    getIsReviewer({
      userTeams: getUserTeamsFromTree(userTeams, allTeams),
      teamId,
      teamType: teamConstants.IMO_ROLES,
    }),
)

export const getUserReporters = createSelector(getRoles, getAllTeams, (roles: $TSFixMe, allTeams: $TSFixMe) => {
  const allAvailableIMORoles = roles.filter(hasOneOfImoRoles)

  return allAvailableIMORoles.reduce((allImoRoles: $TSFixMe, currRole: $TSFixMe) => {
    const team = find({ id: currRole.id }, allTeams)

    return [...allImoRoles, ...getTeamReporters(team, allTeams)]
  }, [])
})

export const getUserActiveTeamsByType = createSelector(
  getUserTeamsInfo,
  getUserReporters,
  (_: $TSFixMe, ...teamTypes: $TSFixMe) => teamTypes,
  (roles: $TSFixMe, reporters: $TSFixMe, teamTypes: $TSFixMe) => {
    const groupedRoles = getGroupedRolesByLevel([...roles, ...reporters])

    const rolesByType = Object.values(teamTypes?.length ? pick(teamTypes, groupedRoles) : groupedRoles)

    const activeRolesArray = flatten(rolesByType).filter(
      ({ role, active, parentRole }: $TSFixMe) =>
        active && !checkIsRestricted(role || parentRole) && !checkIsReadOnlyRole(role || parentRole),
    )

    return uniqBy('id', activeRolesArray)
  },
)

export const getAllDeliverablesInUserReview = createSelector(
  getRoles,
  getUserReporters,
  getReportersByDeliverableInReview,
)

export const getAllDeliverableTypesInUserReview = createSelector(
  getRoles,
  getUserReporters,
  getReportersByDeliverableTypesInReview,
)

//TODO: move it to autonomous selector
export const getUserGlobalPermissionState = createSelector(getRoles, (roles) => {
  const groupedRoles = getGroupedRolesByLevel(roles)

  return {
    isGlobalImoMember: getIsGlobalImoMember(groupedRoles),
    isGlobalImoWithoutVC: getIsGlobalImoWithoutVC(groupedRoles),
  }
})

export const getUserRolesInfo = createSelector(getUserTeamsInfo, getUserReporters, (roles, reportersToImo) => {
  const groupedRoles = getGroupedRolesByLevel([...roles, ...reportersToImo])

  return {
    groupedRoles,
    reporters: reportersToImo,
  }
})

export const getUserTeamRelationsInfo = createSelector(
  getUserTeamsInfo,
  getUserReporters,
  getAllDeliverablesInUserReview,
  getAllDeliverableTypesInUserReview,
  (roles, reportersToImo, allReportersByDeliverable, allReportersByDeliverableType) => {
    return { roles, reportersToImo, allReportersByDeliverable, allReportersByDeliverableType }
  },
)

export const linkedItemType = {
  L2_PROJECT: 'linkedProject',
  INITIATIVE: 'initiative',
  ONE_TIME_COST: 'oneTimeCost',
}

export const linkedItemTypes = Object.values(linkedItemType)

export const defaultProjectMapItem = {
  [linkedItemType.L2_PROJECT]: null,
  [linkedItemType.INITIATIVE]: null,
  [linkedItemType.ONE_TIME_COST]: null,
}

import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { memo } from 'react'
import PropTypes, { string } from 'prop-types'
import { Container, DescriptionWrapper, TruncateBlock, UserName } from './ReviewRequestsPopup.styles'
import { replaceToAnchor, black, greyIconColor } from '@imo/imo-ui-toolkit'
import { UserAvatar } from '@shared/UserAvatar'

const ReviewRenderer = (props) => {
  const { value, data, colDef } = props
  const { field: columnName } = colDef

  if (isEmpty(value)) {
    return ''
  }

  const user = data[columnName]
  const formattedDate = moment(data.createdAt)
  const userName = `${user.name} ${user.surname}`
  const description = replaceToAnchor(data.description)

  return (
    <DescriptionWrapper>
      <UserAvatar class="user-avatar" user={data.user} backgroundColor={greyIconColor} />
      <Container>
        <UserName color={black}>
          {userName}
          <span>{formattedDate.fromNow()}</span>
        </UserName>
        <TruncateBlock lines={2} dangerouslySetInnerHTML={{ __html: description }} className={'description'} />
      </Container>
    </DescriptionWrapper>
  )
}

ReviewRenderer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  data: PropTypes.object.isRequired,
  colDef: PropTypes.shape({
    field: string,
  }).isRequired,
}

export default memo(ReviewRenderer)

import { call, put } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { setInitiativeWatchers, addInitiativeWatcher, removeInitiativeWatcher } from '../actions/actions'
import { updateNotificationWatchStatus } from '@teamHome/actions/actions'
import { getInitiativeWatchersApi } from '@common/net'
import { NotificationEntityTypes } from '@helpers/constants'

export const watchInitiative = createSaga(function* watchInitiative(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId } = action.payload

  const initiativeWatchersApi = yield getInitiativeWatchersApi()

  const data = yield call(initiativeWatchersApi.request('watchInitiative', { query: { teamId, initiativeId } }))

  const teams = data.user.userTeamRoles.map((teamRole: $TSFixMe) => ({
    role: teamRole.role.name,
    teamName: teamRole.team.longName,
    teamType: teamRole.team.teamType.name,
  }))

  const watcher = {
    name: `${data.user.name} ${data.user.surname}`,
    teams,
    userId: data.user.id,
  }

  yield put(addInitiativeWatcher({ initiativeId, watcher }))

  yield put(
    updateNotificationWatchStatus({
      entityId: initiativeId,
      entityType: NotificationEntityTypes.ValueCaptureInitiativeV2,
      isWatching: true,
    }),
  )
},
false)

export const unwatchInitiative = createSaga(function* unwatchInitiative(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId, userId } = action.payload

  const initiativeWatchersApi = yield getInitiativeWatchersApi()

  yield call(initiativeWatchersApi.request('unwatchInitiative', { query: { teamId, initiativeId } }))

  yield put(removeInitiativeWatcher({ initiativeId, userId }))

  yield put(
    updateNotificationWatchStatus({
      entityId: initiativeId,
      entityType: NotificationEntityTypes.ValueCaptureInitiativeV2,
      isWatching: false,
    }),
  )
},
false)

export const fetchInitiativeWatchers = createSaga(function* fetchInitiativeWatchers(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId } = action.payload

  const initiativeWatchersApi = yield getInitiativeWatchersApi()

  const data = yield call(
    initiativeWatchersApi.request('fetchInitiativeWatchers', {
      query: { teamId, initiativeId },
    }),
  )

  const watchers = data.map(({ user }: $TSFixMe) => {
    const name = `${user.name} ${user.surname}`

    const teams = user.userTeamRoles.map((teamRole: $TSFixMe) => ({
      role: teamRole.role.name,
      teamName: teamRole.team.longName,
      teamType: teamRole.team.teamType.name,
    }))

    return {
      userId: user.id,
      name,
      teams,
    }
  })

  yield put(setInitiativeWatchers({ initiativeId, watchers }))
},
false)

import { call, put, select } from 'redux-saga/effects'
import { getInitiativeListV2Api } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getIsAllInitiative } from '@teamValueCaptureV2/initiativeList/selectors'
import { SET_MIRROR_EDIT_LOADING_STATE } from '../actions/actionTypes'
import {
  fetchInitiativeListWithLoader,
  updateSelectedAndMirroredInitiatives,
  setSubLinesForMirroring,
} from '../actions/actions'
import { getItemsByTeam } from '@teamValueCaptureV2/initiativeList/sagas/utils'

export const getSubLinesForMirroring = createSaga(function* getSubLinesForMirroring({
  payload: query,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getInitiativeListV2Api()
  const subLinesForMirroring = yield call(api.request('subLinesForMirroring', { query }))
  yield put(setSubLinesForMirroring(subLinesForMirroring))
})

export const saveInitiativeMirrorTeams = createSaga(function* saveInitiativeMirrorTeams({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, selectedInitiativeTeamId, initiativeId, subLinesData, synergyType } = payload
  const api = yield getInitiativeListV2Api()
  const isAllInitiative = yield select(getIsAllInitiative)
  const itemsByTeam = getItemsByTeam(subLinesData)

  yield call(
    api.request('updateMirrorTeam', {
      query: {
        teamId: selectedInitiativeTeamId,
        initiativeId,
      },
      body: { itemsByTeam },
    }),
  )

  if (isAllInitiative) {
    yield put(updateSelectedAndMirroredInitiatives({ teamId, synergyType }))
  } else {
    yield put(fetchInitiativeListWithLoader({ teamId, synergyType }))
  }
},
SET_MIRROR_EDIT_LOADING_STATE)

import { createSaga } from '@common/sagaCreator/createSaga'
import { call, put } from 'redux-saga/effects'
import { getInitiativeListV2Api } from '@common/net'
import { setDeltaTrackerV2Data } from '@teamValueCaptureV2/monthByMonth/actions/actions'
import { SET_DELTA_TRACKER_V2_DATA } from '@teamValueCaptureV2/monthByMonth/actions/actionTypes'

export const fetchDeltaTrackerV2Data = createSaga(function* fetchDeltaTrackerData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, ...query } = payload

  const api = yield getInitiativeListV2Api()
  const response = yield call(
    api.request('fetchDeltaTrackerV2Data', {
      query,
      body: { teamId },
    }),
  )

  yield put(setDeltaTrackerV2Data(response))
},
SET_DELTA_TRACKER_V2_DATA)

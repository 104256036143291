import { flow, get, find } from 'lodash/fp'
import { introductionMaterialTypesOptions } from './constants'
import update from 'immutability-helper'

export const getIntroductionMaterialType = (id: $TSFixMe) => {
  return flow([find({ id }), get('name')])(introductionMaterialTypesOptions)
}

export const updateOrder = (data: $TSFixMe[], dragId: $TSFixMe, hoverId: $TSFixMe) => {
  const dragIndex = data.findIndex((el) => el.id === dragId)
  const hoverIndex = data.findIndex((el) => el.id === hoverId)

  return update(data, {
    $splice: [
      [dragIndex, 1],
      [hoverIndex, 0, data[dragIndex]],
    ],
  })
}

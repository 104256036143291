export const USER_FIELD = {
  IS_NEW: 'isNew',
  ID: 'id',
  IS_INVALID: 'isInvalid',
  EXTENDED_TEAMS: 'extendedTeams',
  IS_RESTRICTED: 'isRestricted',
  HAS_RESTRICTED_DOMAIN: 'hasRestrictedDomain',
  ACTIVE_RESTRICTIONS: 'activeRestrictions',
}

export const USER_REQUEST_OMITTED_FIELDS = [
  USER_FIELD.IS_INVALID,
  USER_FIELD.EXTENDED_TEAMS,
  USER_FIELD.IS_RESTRICTED,
  USER_FIELD.ACTIVE_RESTRICTIONS,
]

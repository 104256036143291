import { put, call } from 'redux-saga/effects'
import { setTeamDeliverableData } from '@teamDeliverables/actions/actions'
import { getDeliverablesApi } from '@common/net'
import { transformDeliverable } from './utils'

import { createSaga } from '@common/sagaCreator/createSaga'
import { FORBIDDEN, NOT_FOUND } from 'http-status-codes'

export const fetchTeamDeliverableData = createSaga(function* fetchTeamDeliverableData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, deliverableId, onForbidden, onNotFound } = action.payload

  try {
    const api = yield getDeliverablesApi()
    const data = yield call(
      api.request('getTeamDeliverableData', {
        query: { teamId, deliverableId },
      }),
    )

    yield put(setTeamDeliverableData(transformDeliverable(data)))
  } catch (e) {
    const { status } = e as $TSFixMe
    if ([FORBIDDEN].includes(status)) onForbidden()
    if ([NOT_FOUND].includes(status)) onNotFound ? onNotFound() : onForbidden()
  }
},
true)

import { call, put } from 'redux-saga/effects'
import { fetchColumnsData, fetchCommunicationsData } from '@communications/actions/actions'
import { getCommunicationsApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const deleteColumnsData = createSaga(function* deleteColumnsData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getCommunicationsApi()
  yield call(api.request('deleteColumnsData', { query: { columnIds: payload } }))

  yield put(fetchCommunicationsData())
  yield put(fetchColumnsData())
},
true)

import React from 'react'
import { StyledIcon, PopoverContent, StyledCustomPopover } from './index.styles'
import { instructions } from '@helpers/instructions'
import { downloadAttachment } from '@helpers/downloadAttachment'
import { useSelector } from 'react-redux'
import { get, PropertyName } from 'lodash'
import { getManagementType } from '@domain/instanceConfig/selectors'
import { Placement } from '@shared/Popover'
import { defaultGrey, Attachment } from '@imo/imo-ui-toolkit'

interface Props {
  tooltipMessage?: string | React.ReactElement | React.ReactElement[]
  tooltipKey?: PropertyName
  content?: $TSFixMe | React.ReactElement
  attachment?: $TSFixMe
  placement?: string
  iconProps?: $TSFixMe
  iconStyles?: $TSFixMe
  maxWidth?: number
  iconSize?: number
  customPopoverStyles?: $TSFixMe
}

const InstructionsTooltip = ({
  iconStyles,
  tooltipMessage,
  tooltipKey = '',
  attachment,
  content,
  placement,
  iconProps,
  maxWidth,
  iconSize,
  customPopoverStyles,
  ...props
}: Props) => {
  const managementType = useSelector(getManagementType)
  const configuredInstruction = get(instructions(managementType), [tooltipKey], '')
  const isTooltipPresent = !!content || !!tooltipMessage || !!configuredInstruction || !!attachment

  if (!isTooltipPresent) return null

  return (
    <StyledCustomPopover
      customStyles={customPopoverStyles}
      content={
        <>
          {!!content && content}
          {!!tooltipMessage && <PopoverContent>{tooltipMessage}</PopoverContent>}
          {!!tooltipKey && <PopoverContent>{configuredInstruction}</PopoverContent>}
          {!!attachment && (
            <PopoverContent className="instructions">
              <h3>Instructions</h3>
              <Attachment
                {...attachment}
                fileKey={attachment.key}
                fileIconProps={{ iconSize: 24, color: defaultGrey, fontColor: '#202b33' }}
                actionIconProps={{ iconSize: 16, color: defaultGrey }}
                downloadAttachment={downloadAttachment}
              />
            </PopoverContent>
          )}
        </>
      }
      placement={placement}
      interactionKind={'hover'}
      portalClassName={'instructions-tooltip-content'}
      contentStyles={{ maxWidth }}
      popoverClassName="bp4-dark"
      {...props}
    >
      <StyledIcon styles={iconStyles} icon={'help'} size={iconSize} {...iconProps} />
    </StyledCustomPopover>
  )
}

InstructionsTooltip.defaultProps = {
  tooltipMessage: '',
  tooltipKey: '',
  attachment: null,
  placement: Placement.BOTTOM,
  content: null,
  iconProps: {},
  iconStyles: {},
  maxWidth: 283,
  iconSize: 14,
  customPopoverStyles: {},
}

export default InstructionsTooltip

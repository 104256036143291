import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const initialVideoKnowledgeCenterFetch = createAction(
  constants.INITIAL_VIDEO_KNOWLEDGE_CENTER_FETCH,
  null,
  getMetaWithActionLoading,
)

export const saveLibrariesForVideoImport = createAction(constants.SAVE_LIBRARIES_FOR_VIDEO_IMPORT)
export const importVideos = createAction(constants.IMPORT_VIDEOS)

export const initialArticleKnowledgeCenterFetch = createAction(
  constants.INITIAL_ARTICLE_KNOWLEDGE_CENTER_FETCH,
  null,
  getMetaWithActionLoading,
)

export const saveLibrariesForArticleImport = createAction(constants.SAVE_LIBRARIES_FOR_ARTICLE_IMPORT)
export const importArticles = createAction(constants.IMPORT_ARTICLES)

export const initialIntroductionMaterialKnowledgeCenterFetch = createAction(
  constants.INITIAL_INTRODUCTION_MATERIAL_KNOWLEDGE_CENTER_FETCH,
  null,
  getMetaWithActionLoading,
)

export const saveLibrariesForIntroductionMaterialImport = createAction(
  constants.SAVE_LIBRARIES_FOR_INTRODUCTION_MATERIAL_IMPORT,
)
export const importIntroductionMaterials = createAction(constants.IMPORT_INTRODUCTION_MATERIALS)

export const setLibrariesForImport = createAction(constants.SET_LIBRARIES_FOR_IMPORT)
export const setLibraryAssets = createAction(constants.SET_LIBRARY_ASSETS)
export const setLibraryAssetsWithTime = createAction(constants.SET_LIBRARY_ASSETS_WITH_TIME)
export const fetchIntroductionMaterialsAssetsAndDownload = createAction(
  constants.FETCH_INTRODUCTION_MATERIALS_ASSETS_AND_DOWNLOAD,
)
export const changeAssetStatus = createAction(constants.CHANGE_ASSET_STATUS)

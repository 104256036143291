import React, { useState, useEffect } from 'react'
import { animationStyles } from './index.styles'

type AnimationState = 'entering' | 'entered' | 'exiting' | 'exited'

type AnimationType = 'slide' | 'fade' | 'scale'
type SlideDirection = 'topDown' | 'downTop' | 'leftRight' | 'rightLeft'
type FadeScaleDirection = 'in' | 'out'
type Direction = SlideDirection | FadeScaleDirection

type AnimatedComponentProps = {
  isVisible: boolean
  children: React.ReactNode
  animationType: AnimationType
  direction: Direction
  timeout?: number
  transparent?: boolean
}

const Animation: React.FC<AnimatedComponentProps> = ({
  isVisible,
  children,
  animationType,
  direction,
  timeout = 100,
  transparent = false,
  ...restProps
}) => {
  const [animationState, setAnimationState] = useState<AnimationState>('exited')
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    if (!hasMounted) {
      if (isVisible) {
        setAnimationState('entered') // Start as visible
      } else {
        setAnimationState('exited') // Start as not visible
      }
      setHasMounted(true)
      return
    }

    let startTimeout: NodeJS.Timeout
    let endTimeout: NodeJS.Timeout

    if (isVisible) {
      setAnimationState('entering')
      startTimeout = setTimeout(() => setAnimationState('entered'), timeout)
      setHasMounted(true)
    } else {
      setAnimationState('exiting')
      endTimeout = setTimeout(() => setAnimationState('exited'), timeout)
      setHasMounted(true)
    }
    // Clean up timeouts on component unmount or when dependencies change
    return () => {
      startTimeout && clearTimeout(startTimeout)
      endTimeout && clearTimeout(endTimeout)
    }
  }, [isVisible, animationType, direction, timeout])

  const getCurrentStyle = () => {
    if (animationType === 'slide') {
      if (['topDown', 'downTop', 'leftRight', 'rightLeft'].includes(direction)) {
        return animationStyles.slide[direction as SlideDirection][animationState](timeout)
      }
    } else if (animationType === 'fade' || animationType === 'scale') {
      if (['in', 'out'].includes(direction)) {
        return animationStyles[animationType][direction as FadeScaleDirection][animationState](timeout)
      }
    }
    return {}
  }

  const currentStyle = getCurrentStyle()

  // the transparency mode
  if (transparent) return <>{children}</>

  return (
    <div style={currentStyle} {...restProps}>
      {children}
    </div>
  )
}

export default Animation

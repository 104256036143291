import { useMemo } from 'react'
import { IAppliedFiltersConfig } from '@myImoClient/components/Header/AppliedFilters/interfaces'
import { getIconProperties } from '@myImoClient/components/Header/AppliedFilters/utils'
import { useLocation } from 'react-router-dom'

interface IFilterIcon extends IAppliedFiltersConfig {
  toggleSettings: $TSFixMe
  isVcTracker: boolean
}

const FilterIcon = ({ isActive, id, icon, toggleSettings, isVcTracker, teamVCPages, VCTrackerPages }: IFilterIcon) => {
  const { pathname } = useLocation()

  const [color, text] = getIconProperties({ isActive, id, toggleSettings })

  const isAvailable = useMemo(() => {
    if (isVcTracker) {
      return VCTrackerPages.some((page) => pathname.includes(page))
    }

    return teamVCPages.some((page) => pathname.includes(page))
  }, [isVcTracker, pathname])

  if (!isAvailable) return null

  return icon(color, text)
}

export default FilterIcon

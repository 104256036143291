import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'

import { fetchMutualDiscoveryData } from './fetchMutualDiscoveryData'
import { saveProcess } from './saveProcess'
import { deleteProcess, deleteProcessItems } from './deleteSubProcess'
import { updateProcess } from './updateProcess'
import { fetchProcessesFromLibraryForMDPA } from './fetchProcessesFromLibraryForMDPA'
import { addProcessesFromLibForMDPA } from '../sagas/addProcessesFromLibForMDPA'

export default function* mutualDiscoverySaga() {
  yield all([
    takeLatest(constants.FETCH_MUTUAL_DISCOVERY_DATA, fetchMutualDiscoveryData),
    takeLatest(constants.SAVE_PROCESS, saveProcess),
    takeLatest(constants.UPDATE_PROCESS, updateProcess),
    takeLatest(constants.DELETE_PROCESS, deleteProcess),
    takeLatest(constants.DELETE_PROCESS_ITEMS, deleteProcessItems),
    takeLatest(constants.FETCH_PROCESSES_FROM_LIBRARY, fetchProcessesFromLibraryForMDPA),
    takeLatest(constants.ADD_PROCESSES_FROM_LIBRARY, addProcessesFromLibForMDPA),
  ])
}

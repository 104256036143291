import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchLateOrAtRiskData = createAction(constants.FETCH_LATE_OR_AT_RISK_DATA)
export const fetchLateOrAtRiskDataWithLoader = createAction(
  constants.FETCH_LATE_OR_AT_RISK_DATA,
  null,
  getMetaWithActionLoading,
)
export const setLateOrAtRiskData = createAction(constants.SET_LATE_OR_AT_RISK_DATA)

export const fetchMetricDetails = createAction(constants.FETCH_METRIC_DETAILS, null, getMetaWithActionLoading)
export const setMetricDetails = createAction(constants.SET_METRIC_DETAILS)

export const updateInitiativeMetricDetails = createAction(constants.UPDATE_INITIATIVE_METRIC_DETAILS)

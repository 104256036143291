import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@steerCoRoadmap/actions/actionTypes'
import { fetchSteerCoActionsData, updateSteerCoActionsData } from '@steerCoRoadmap/sagas/steerCoActionsSagas'
import { fetchParkingLotData, updateParkingLotData } from '@steerCoRoadmap/sagas/parkingLotSagas'
import { fetchColumnsData, updateColumnsData, deleteColumnsData } from '@steerCoRoadmap/sagas/columnsSagas'

export default function* steerCoActionsSaga() {
  yield all([
    takeLatest(constants.FETCH_STEER_CO_ACTIONS_DATA, fetchSteerCoActionsData),
    takeLatest(constants.UPDATE_STEER_CO_ACTIONS_DATA, updateSteerCoActionsData),
    takeLatest(constants.FETCH_PARKING_LOT_DATA, fetchParkingLotData),
    takeLatest(constants.UPDATE_PARKING_LOT_DATA, updateParkingLotData),
    takeLatest(constants.FETCH_COLUMNS_DATA, fetchColumnsData),
    takeLatest(constants.UPDATE_COLUMNS_DATA, updateColumnsData),
    takeLatest(constants.DELETE_COLUMNS_DATA, deleteColumnsData),
  ])
}

import React, { memo } from 'react'
import TeamsList from './TeamsList'
import TeamsListItem from './TeamsListItem'
import { SelectorButton, StyledSelect } from './TeamSelector.styles'
import { flatten, includes, get } from 'lodash'
import { teams } from '@common/accessController/constants'
import { groupAndSortTeamListByParentTeam } from '@common/teamFilter/teamFilterConditions'

interface Props {
  teamType: string
  teamsList?: $TSFixMe
  onTeamSelect: (...args: $TSFixMe[]) => $TSFixMe
  selectedTeam?: number
  managementType?: string
  isVCTracker?: boolean
  isOrgDesignTracker?: boolean
}

const TeamSelector = ({
  teamType,
  teamsList,
  onTeamSelect,
  selectedTeam,
  managementType,
  isVCTracker,
  isOrgDesignTracker,
}: Props) => {
  const sortedTeamList = groupAndSortTeamListByParentTeam(teamsList, !!isVCTracker || !!isOrgDesignTracker)
  const flattenTeamList = flatten(Object.values(sortedTeamList))

  const getTeamById = (id: $TSFixMe) => {
    return flattenTeamList.find((team) => (team as $TSFixMe).id === id)
  }

  const filterTeam = (query: $TSFixMe, team: $TSFixMe, _index: $TSFixMe, exactMatch: $TSFixMe) => {
    const normalizedTitle = team.longName?.toLowerCase()
    const normalizedQuery = query.toLowerCase()

    if (exactMatch) {
      return normalizedTitle === normalizedQuery
    } else {
      return includes(normalizedTitle, normalizedQuery)
    }
  }

  const isTeamSelected = (team: $TSFixMe) => selectedTeam === team.id

  const teamLongName = get(getTeamById(selectedTeam), 'longName', null)

  if (!teamLongName) return null

  return (
    <StyledSelect
      items={flattenTeamList}
      inputProps={{
        placeholder: 'Search...',
      }}
      itemPredicate={filterTeam}
      itemListRenderer={(itemListProps) => (
        <TeamsList itemListProps={itemListProps} teamsList={sortedTeamList} teamType={teamType} />
      )}
      itemRenderer={(item: $TSFixMe, itemProps) => (
        <TeamsListItem
          team={{ ...item, teamType: teamType === teams.IMO ? managementType : item.teamType }}
          itemProps={itemProps}
          isSelected={isTeamSelected}
        />
      )}
      onItemSelect={onTeamSelect}
      popoverProps={{
        portalClassName: 'team-selector',
      }}
    >
      <SelectorButton rightIcon="caret-down" text={teamLongName} disabled={false} />
    </StyledSelect>
  )
}

TeamSelector.defaultProps = {
  selectedTeam: null,
  teamsList: {},
  managementType: '',
  isVCTracker: false,
  isOrgDesignTracker: false,
}

export default memo(TeamSelector)

import { put, call } from 'redux-saga/effects'
import { fetchWhiteboard, setWhiteboard } from '@teamWorkspace/actions/actions'
import { getTeamWorkspaceApi } from '@common/net'
import { omit, pick } from 'lodash'
import { createSaga } from '@common/sagaCreator/createSaga'

export const getWhiteboard = createSaga(function* getWhiteboard({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamWorkspaceApi()
  const whiteboardData = yield call(api.request('getWhiteboardList', { query: { teamId: payload } }))

  yield put(setWhiteboard(whiteboardData))
})

export const createWhiteboard = createSaga(function* createWhiteboard({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(
    api.request('createWhiteboard', {
      query: { teamId },
      body: omit(data, 'isNew', 'id'),
    }),
  )

  yield put(fetchWhiteboard(teamId))
})

export const updateWhiteboard = createSaga(function* updateWhiteboard({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(
    api.request('updateWhiteboard', {
      query: { teamId, whiteboardId: data.id },
      body: pick(data, 'description'),
    }),
  )

  yield put(fetchWhiteboard(teamId))
})

export const deleteWhiteboard = createSaga(function* deleteWhiteboard({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(api.request('deleteWhiteboard', { query: { teamId, whiteboardId: data.id } }))

  yield put(fetchWhiteboard(teamId))
})

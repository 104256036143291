// Libs
import { call, put } from 'redux-saga/effects'
// Actions
import { setComments } from '../actions/actions'
import { fetchComments } from './fetchComments'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchCommentsList = createSaga(function* fetchCommentsList(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, deliverableId, offset } = action.payload
  const commentsList = yield call(fetchComments, teamId, deliverableId, offset)

  if (commentsList) {
    yield put(setComments(commentsList))
  }
})

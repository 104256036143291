import { handleActions } from 'redux-actions'
import * as constants from '@day1IssueKPIs/actions/actionTypes'

export interface IDay1IssueKPIState {
  issueKPIs: $TSFixMe[]
  issueKPIsByTeams: $TSFixMe[]
}

export const initialState = {
  issueKPIs: [],
  issueKPIsByTeams: [],
}

const day1IssueKPIsReducer = handleActions<IDay1IssueKPIState, $TSFixMe>(
  {
    [constants.SET_DAY1_ISSUE_KPI_DATA]: (state, action) => ({ ...state, issueKPIs: action.payload }),

    [constants.SET_DAY1_ISSUE_KPI_BY_TEAMS_DATA]: (state, action) => ({ ...state, issueKPIsByTeams: action.payload }),
  },
  initialState,
)

export default day1IssueKPIsReducer

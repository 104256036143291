const prefix = '@functionalActivity/'

export const FETCH_KEY_PROCESSES = `${prefix}FETCH_KEY_PROCESSES`
export const SET_KEY_PROCESSES = `${prefix}SET_KEY_PROCESSES`

export const CREATE_KEY_PROCESS = `${prefix}CREATE_KEY_PROCESS`
export const CREATE_KEY_PROCESS_SUCCESS = `${prefix}CREATE_KEY_PROCESS_SUCCESS`

export const UPDATE_KEY_PROCESS = `${prefix}UPDATE_KEY_PROCESS`
export const UPDATE_KEY_PROCESS_SUCCESS = `${prefix}UPDATE_KEY_PROCESS_SUCCESS`

export const DELETE_KEY_PROCESS = `${prefix}DELETE_KEY_PROCESS`
export const DELETE_KEY_PROCESS_SUCCESS = `${prefix}DELETE_KEY_PROCESS_SUCCESS`

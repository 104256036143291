export const FETCH_PROGRAM_SETUP = 'FETCH_PROGRAM_SETUP'
export const FETCH_PROGRAM_SETUP_SUCCESS = 'FETCH_PROGRAM_SETUP_SUCCESS'
export const CREATE_PROGRAM_SETUP = 'CREATE_PROGRAM_SETUP'
export const CREATE_PROGRAM_SETUP_SUCCESS = 'CREATE_PROGRAM_SETUP_SUCCESS'
export const UPDATE_PROGRAM_SETUP = 'UPDATE_PROGRAM_SETUP'
export const RESET_PROGRAM_SETUP = 'RESET_PROGRAM_SETUP'

export const FETCH_LOGO_LINKS = 'FETCH_LOGO_LINKS'
export const UPLOAD_LOGO_PROGRAM_SETUP = 'UPLOAD_LOGO_PROGRAM_SETUP'
export const DELETE_LOGO = 'DELETE_LOGO'

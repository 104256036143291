import { put, call, select } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getConfigApi } from '@common/net'
import { setStepCompleted } from '@domain/myImoConfig/actions/config/actions'
import { fetchDictionaries } from '@generic/sagas/fetchDictionaries'
import { fetchConfigGenerator } from '@generic/sagas/fetchConfig'
import { FETCH_DICTIONARIES } from '@generic/actions/actionTypes'
import { getAirtableModuleActive, getModuleOptionsState } from '../selectors'
import { fetchModuleOptionsSuccess } from '../actions/actions'
import { prepareModulesSelect, prepareModules, prepareDictionaries } from './prepareModules'
import { NavigateFunction } from 'react-router-dom'

export const fetchModuleOptions = createSaga(function* fetchModuleOptions(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const configApi = yield getConfigApi()
  const response = yield call(configApi.request('getCoreComponentStatuses'))
  yield put(fetchModuleOptionsSuccess(response))
}, true)

export const sendBulkUpdateModuleOptions = createSaga(function* sendBulkUpdateModuleOptions({
  payload: { navigate },
}: {
  payload: { navigate: NavigateFunction }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const configApi = yield getConfigApi()
  const moduleOptionsState = yield select(getModuleOptionsState)

  const preparedModules = prepareModules(moduleOptionsState.MODULES)
  const preparedModulesSelect = prepareModulesSelect(moduleOptionsState.MODULES_SELECT)
  const preparedDictionaries = prepareDictionaries(moduleOptionsState.DICTIONARIES)

  const isAirtableModuleActive = yield select(getAirtableModuleActive)
  const includeIntegrations = isAirtableModuleActive

  yield call(
    configApi.request('setCoreComponentStatuses', {
      body: {
        DICTIONARIES: preparedDictionaries,
        MODULES: preparedModules,
        MODULES_SELECT: preparedModulesSelect,
        INTEGRATIONS: includeIntegrations ? moduleOptionsState.INTEGRATIONS : undefined,
      },
    }),
  )

  yield put(setStepCompleted({ no: 8 }))
  navigate('/config/welcome-note')
  yield call(fetchDictionaries, { type: FETCH_DICTIONARIES })
  yield call(fetchConfigGenerator)
},
true)

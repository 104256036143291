import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { PopoverInteractionKind } from '@blueprintjs/core'
import { ControllerContainer, StyledIconButton } from './index.styles'
import VCControllerMenu from '@myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/TeamValueCaptureController/VCControllerMenu'
import { greyIconColor } from '@imo/imo-ui-toolkit'

const TeamValueCaptureController = ({ teamId, isVCTracker }) => {
  return (
    <ControllerContainer>
      <VCControllerMenu teamId={teamId} isVCTracker={isVCTracker} interactionKind={PopoverInteractionKind.CLICK}>
        <StyledIconButton icon="cog" color={greyIconColor} />
      </VCControllerMenu>
    </ControllerContainer>
  )
}

TeamValueCaptureController.propTypes = {
  teamId: PropTypes.number.isRequired,
  isVCTracker: PropTypes.bool,
}

TeamValueCaptureController.defaultProps = {
  isVCTracker: false,
}

export default memo(TeamValueCaptureController)

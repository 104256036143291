import React from 'react'
import { FONT_FAMILY, greyForBtn, white } from '@imo/imo-ui-toolkit'

export const FileIcon = ({
  iconSize = 24,
  color = greyForBtn,
  extension = null,
  fontColor = white,
}: {
  iconSize?: number
  color?: string
  extension?: string | null
  fontColor?: string
}) => {
  const ext = extension?.slice(0, 4)
  const generateFontSize = (val?: string) => (val ? -2 * val.length + 14 : 8)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={iconSize}
      height={iconSize}
      className="file-icon"
      viewBox="0 0 24 24"
    >
      <defs>
        <path id="prefix__a" d="M0 0.427L16.874 0.427 16.874 22 0 22z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(4 .573)">
          <mask id="prefix__b" fill={white}>
            <use xlinkHref="#prefix__a" />
          </mask>
          <path
            fill={color}
            d="M12.144 5.538v-4.11l3.58 4.11h-3.58zm.934-5.111H1.454C.654.427 0 1.08 0 1.88v18.667C0 21.347.654 22 1.454 22H15.42c.8 0 1.453-.653 1.453-1.453V4.93L13.078.427z"
            mask="url(#prefix__b)"
          />
        </g>
        <text
          fill={fontColor}
          fontFamily={FONT_FAMILY}
          fontWeight="500"
          textLength="14"
          fontSize={generateFontSize(ext)}
          x="5.4"
          y="18"
        >
          {ext ? ext.toUpperCase() : null}
        </text>
      </g>
    </svg>
  )
}

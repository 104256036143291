import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchTeamContacts = createAction(constants.FETCH_TEAM_CONTACTS, null, getMetaWithActionLoading)
export const setTeamContacts = createAction(constants.SET_TEAM_CONTACTS)

export const createTeamContact = createAction(constants.CREATE_TEAM_CONTACT)
export const createTeamContactSuccess = createAction(constants.CREATE_TEAM_CONTACT_SUCCESS)

export const updateTeamContact = createAction(constants.UPDATE_TEAM_CONTACT)
export const updateTeamContactSuccess = createAction(constants.UPDATE_TEAM_CONTACT_SUCCESS)

export const deleteTeamContact = createAction(constants.DELETE_TEAM_CONTACT)
export const deleteTeamContactSuccess = createAction(constants.DELETE_TEAM_CONTACT_SUCCESS)

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchYear5PipelineSummary = createAction(
  constants.FETCH_YEAR_5_PIPELINE_SUMMARY,
  null,
  getMetaWithActionLoading,
)
export const setYear5PipelineSummary = createAction(constants.SET_YEAR_5_PIPELINE_SUMMARY)

export const fetchYearlyImpactChart = createAction(constants.FETCH_YEAR_IMPACT_CHART, null, getMetaWithActionLoading)
export const setYearlyImpactChart = createAction(constants.SET_YEAR_IMPACT_CHART)

export const fetchValueCaptureStatus = createAction(constants.FETCH_VALUE_CAPTURE_STATUS)
export const fetchValueCaptureStatusWithLoader = createAction(
  constants.FETCH_VALUE_CAPTURE_STATUS,
  null,
  getMetaWithActionLoading,
)
export const setValueCaptureStatus = createAction(constants.SET_STAGE_GATE_CALC_TYPE)
export const updateValueCaptureStatus = createAction(constants.UPDATE_VALUE_CAPTURE_STATUS)
export const addValueCaptureStatus = createAction(constants.ADD_VALUE_CAPTURE_STATUS)
export const removeValueCaptureStatus = createAction(constants.REMOVE_VALUE_CAPTURE_STATUS)

export const fetchInitialOneTimeCostsData = createAction(
  constants.FETCH_INITIAL_ONE_TIME_COSTS,
  null,
  getMetaWithActionLoading,
)

export const fetchStatusUpdateOneTimeCosts = createAction(
  constants.FETCH_STATUS_UPDATE_ONE_TIME_COST,
  null,
  getMetaWithActionLoading,
)
export const setStatusUpdateOneTimeCosts = createAction(constants.SET_STATUS_UPDATE_ONE_TIME_COST)

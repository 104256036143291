import * as Yup from 'yup'
import { checkRowsDuplications, combinePaths } from '@helpers/schemas'
import { isEqual, size, isNil, lowerCase, getOr } from 'lodash/fp'
import { PHASES } from '@myImoClient/components/TSAv2/shared/constants'

const fieldsToTest = ['team', 'keyProcess', 'nameOfService', 'serviceGeography']
const requiredText = 'This field is required'
const unexpectedValueErrorMsg = 'Service duration field can only contain decimal numbers with .5 increment'
const duplicationErrorText =
  'TSA for the same combination of Key process, Name of service and Service geography already exists'

const tsaIdMessage = 'ID {{tsaId}} already exists, please choose another ID'
const tsaKeyProcessMessage =
  'TSA with the same combination of key process, name of service and service geography already exists'

export const validateServiceDuration = () => (value) => {
  const isValid = {
    valid: true,
    message: null,
  }

  if (!isNil(value)) {
    const isExpectedValue = Number(value) > 0 && Number(value) % 0.5 === 0

    if (!isExpectedValue) {
      isValid.valid = false
      isValid.message = `${unexpectedValueErrorMsg}`
    }
  }

  return isValid
}

const phase1Schema = {
  descriptionOfService: Yup.string()
    .nullable()
    .max(10000, 'Description of Service must be less than 10000 characters')
    .required(requiredText),
  typeOfService: Yup.string().nullable().required(requiredText),
  serviceGeography: Yup.string()
    .nullable()
    .max(500, 'Service geography must be less than 500 characters')
    .required(requiredText),
  serviceDuration: Yup.string()
    .nullable()
    .test('checkCorrectServiceDuration', validateServiceDuration)
    .required(requiredText),
  primaryExitCriteriaTag: Yup.string().nullable().required(requiredText),
}

const phase2Schema = {
  costRecurrentMonthlyCharge: Yup.string().when(['costNonRecurrentCharge'], {
    is: (costNonRecurrentCharge) => costNonRecurrentCharge,
    then: Yup.string().nullable().max(400),
    otherwise: Yup.string().nullable().required(requiredText),
  }),
  costNonRecurrentCharge: Yup.string().when(['costRecurrentMonthlyCharge'], {
    is: (costRecurrentMonthlyCharge) => costRecurrentMonthlyCharge,
    then: Yup.string().nullable().max(400),
    otherwise: Yup.string().nullable().required(requiredText),
  }),
  costAssumptionTag: Yup.string().nullable().required(requiredText),
}

const phase3Schema = {
  tsaContactRemainCOSeller: Yup.string().nullable().required(requiredText),
  tsaContactDivestCOBuyer: Yup.string().nullable().required(requiredText),
}

const phase4Schema = {
  primaryLinkedProject: Yup.object().nullable().required(requiredText),
}

const phase5Schema = {
  tsaActiveStatus: Yup.string().nullable().required(requiredText),
  exitExtensionRequestStatus: Yup.string().nullable().required(requiredText),
  originalContractualTSAEndDate: Yup.string().nullable().required(requiredText),
  forecastTSAExitDate: Yup.string().nullable().required(requiredText),
  revisedConstructionalExitExtensionDate: Yup.string().nullable().required(requiredText),
}

export const TSAv2FieldsSchema = {
  reviewStatus: Yup.string().nullable().required(requiredText),
  keyProcess: Yup.string()
    .nullable()
    .max(500, 'Key process name must be less than 500 characters')
    .required(requiredText),
  nameOfService: Yup.string()
    .nullable()
    .max(500, 'Name of Service must be less than 500 characters')
    .required(requiredText),
  ...phase1Schema,
  ...phase2Schema,
  ...phase3Schema,
  ...phase4Schema,
  ...phase5Schema,
}

const oneOfFieldsRequired = [
  ['costRecurrentMonthlyCharge', 'costNonRecurrentCharge'],
  ['costNonRecurrentCharge', 'costRecurrentMonthlyCharge'],
]

function isUniqueKeyProcessNameServiceGeographyCombination(value) {
  const duplications = checkRowsDuplications(value, this.parent, fieldsToTest)

  if (size(duplications)) {
    return this.createError({
      path: combinePaths(this.path, duplications),
      params: { duplications },
      message: duplicationErrorText,
    })
  }

  return true
}

export const isUniqueCombination = (rowsToTest, field, rowToTest) => (newValue) => {
  const isValid = {
    valid: true,
    message: null,
  }

  const updatedRowToTest = {
    ...rowToTest,
    [field]: newValue,
    keyProcess: field === 'keyProcess' ? getOr(newValue, 'name', newValue) : rowToTest['keyProcess'],
  }

  const duplications = checkRowsDuplications(updatedRowToTest, rowsToTest, fieldsToTest)

  if (size(duplications) && !isNil(newValue)) {
    isValid.valid = false
    isValid.message = duplicationErrorText
  }

  return isValid
}

export const TSAv2Schema = Yup.array().of(
  Yup.object()
    .shape(TSAv2FieldsSchema, oneOfFieldsRequired)
    .test('TSA duplication validation', duplicationErrorText, isUniqueKeyProcessNameServiceGeographyCombination),
)

const duplicateCheckHelper = ({ processRowToCheck, tsaRows, existCheckedSubprocess }) => {
  const isKeyProcess = isNil(processRowToCheck.keyProcessId)

  const notValidRows = tsaRows.some(({ keyProcess, nameOfService, serviceGeography, teamId }) => {
    let processRowData
    const tsaRowData = {
      processName: keyProcess,
      subProcessName: nameOfService,
      serviceGeography,
      teamId,
    }

    if (isKeyProcess) {
      processRowData = {
        processName: processRowToCheck.name,
        subProcessName: existCheckedSubprocess ? nameOfService : null,
        serviceGeography: null,
        teamId: processRowToCheck.teamId,
      }
    } else {
      processRowData = {
        processName: processRowToCheck.keyProcess.name,
        subProcessName: processRowToCheck.name,
        serviceGeography: null,
        teamId: processRowToCheck.teamId,
      }
    }

    return isEqual(tsaRowData, processRowData)
  })

  return notValidRows
}

export function checkDuplicatedProcess() {
  const { path, options, parent: processRowToCheck } = this
  const { tsaRows, processes: allProcesses } = options.context

  if (!processRowToCheck.selected) return true

  const isKeyProcess = isNil(processRowToCheck.keyProcessId)
  const existCheckedSubprocess = allProcesses.some(
    (process) => process.keyProcessId === processRowToCheck.id && process.selected,
  )

  const subProcesses = allProcesses.filter(
    ({ keyProcessId, selected }) => keyProcessId === processRowToCheck.id && selected,
  )

  const isKeyProcessWithValidSubprocess =
    isKeyProcess &&
    subProcesses.some(
      (subProcess) =>
        !duplicateCheckHelper({
          processRowToCheck: subProcess,
          tsaRows,
        }),
    )

  if (isKeyProcess && existCheckedSubprocess && isKeyProcessWithValidSubprocess) return true

  const notValidRows = duplicateCheckHelper({ processRowToCheck, tsaRows, existCheckedSubprocess })

  if (notValidRows) return this.createError({ path, params: { data: processRowToCheck } })

  return true
}

export function checkNonTsaKeyProcess() {
  const { path, options, parent } = this
  const { processes } = options.context

  if (!parent.selected) return true

  const isKeyProcess = isNil(parent.keyProcessId)
  const noCheckedSubprocess = !processes.some((process) => process.keyProcessId === parent.id && process.selected)
  const isNotTsa = lowerCase(parent.tsa) !== 'yes' && lowerCase(parent.dayOneSolution) !== 'tsa'

  if (isKeyProcess && isNotTsa && noCheckedSubprocess) return this.createError({ path, params: { data: parent } })

  return true
}

export const ImportProcessToTsaSchema = Yup.array().of(
  Yup.object().shape({
    name: Yup.mixed()
      .nullable()
      .test('duplicatedProcess', checkDuplicatedProcess)
      .test('nonTsaKeyProcess', checkNonTsaKeyProcess),
  }),
)

const getPhaseSchema = (phase) => {
  const phaseCompleteFiledSchema = {
    [`isPhase${phase}Completed`]: Yup.mixed().oneOf([true], 'Phase should be marked as completed'),
  }

  switch (phase) {
    case PHASES.ONE:
      return {
        ...phase1Schema,
        ...phaseCompleteFiledSchema,
      }
    case PHASES.TWO:
      return {
        ...phase2Schema,
        ...phaseCompleteFiledSchema,
      }
    case PHASES.THREE:
      return {
        ...phase3Schema,
        ...phaseCompleteFiledSchema,
      }
    case PHASES.FOUR:
      return {
        ...phase4Schema,
        ...phaseCompleteFiledSchema,
      }
    case PHASES.FIVE:
      return {
        ...phase5Schema,
      }
    default:
      return {}
  }
}

export const getTSAv2SubmitAcceptSchema = (phase) =>
  Yup.array().of(Yup.object().shape(getPhaseSchema(phase), oneOfFieldsRequired))

export const isUniqTsaId = (rows, selectedRow) => (value) => {
  const hasDuplication = rows.some((item) => item.teamId === selectedRow.teamId && item.tsaId === value)

  return {
    valid: !hasDuplication,
    message: tsaIdMessage.replace('{{tsaId}}', value),
  }
}

export const suppressZeroAsTsaId = (value) => {
  if (isNil(value))
    return {
      valid: true,
      message: '',
    }

  return {
    valid: value > 0,
    message: 'Invalid TSA ID',
  }
}

export const isRequiredField = (value) => ({
  valid: !isNil(value),
  message: requiredText,
})

export const isUniqKeyProcess = (rows, selectedRow) => (value) => {
  const hasDuplication = rows.some((item) => item.teamId === selectedRow.teamId && item.keyProcess === value.keyProcess)

  return {
    valid: !hasDuplication,
    message: tsaKeyProcessMessage,
  }
}

import { openNotificationAndIcon } from '@imo/imo-ui-toolkit'
import allMessages from '@common/notifications/messages'

export function* showWarningNotification({ payload }: $TSFixMe) {
  const {
    action,
    actionType,
    props = {},
    errorMessage,
    isExtendedNotification = false,
    additionalAction = null,
    timeout,
    messages = allMessages,
    icon,
  } = payload

  yield openNotificationAndIcon({
    action,
    actionType,
    errorMessage,
    props: props ?? {},
    isExtendedNotification,
    additionalAction,
    timeout,
    messages,
    icon,
  })
}

import { createAction } from 'redux-actions'
import {
  FETCH_DATA_PENDING,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAIL,
  FETCH_DATA_INTERDEPENDENCY_POPUP,
  SET_DATA_INTERDEPENDENCY_POPUP_DATA,
  UPDATE_INTERDEPENDENCY_POPUP_DATA,
  FETCH_INTERDEPENDENCY_SUMMARY,
  SET_INTERDEPENDENCY_SUMMARY,
  FETCH_INTERDEPENDENCY_SUMMARY_TOTALS,
  SET_INTERDEPENDENCY_SUMMARY_TOTALS,
  CLEAR_STATE_INTERDEPENDENCY_POPUP_DATA,
  FETCH_INTERDEPENDENCY_FREQUENCY,
  SET_INTERDEPENDENCY_FREQUENCY,
} from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchDataPending = createAction(FETCH_DATA_PENDING, null, getMetaWithActionLoading)
export const fetchDataSuccess = createAction(FETCH_DATA_SUCCESS)
export const fetchDataFail = createAction(FETCH_DATA_FAIL)

export const fetchInterdependencyPopupData = createAction(
  FETCH_DATA_INTERDEPENDENCY_POPUP,
  null,
  getMetaWithActionLoading,
)
export const fetchInterdependencySummary = createAction(FETCH_INTERDEPENDENCY_SUMMARY, null, getMetaWithActionLoading)
export const setInterdependencySummary = createAction(SET_INTERDEPENDENCY_SUMMARY)
export const fetchInterdependencySummaryTotals = createAction(
  FETCH_INTERDEPENDENCY_SUMMARY_TOTALS,
  null,
  getMetaWithActionLoading,
)
export const setInterdependencySummaryTotals = createAction(SET_INTERDEPENDENCY_SUMMARY_TOTALS)
export const setInterdependencyPopupData = createAction(SET_DATA_INTERDEPENDENCY_POPUP_DATA)
export const updateInterdependencyPopupData = createAction(UPDATE_INTERDEPENDENCY_POPUP_DATA)
export const clearStateInterdependencyPopupData = createAction(CLEAR_STATE_INTERDEPENDENCY_POPUP_DATA)
export const fetchInterdependencyFrequency = createAction(
  FETCH_INTERDEPENDENCY_FREQUENCY,
  null,
  getMetaWithActionLoading,
)
export const setInterdependencyFrequency = createAction(SET_INTERDEPENDENCY_FREQUENCY)

import styled from 'styled-components'
import { Button, Menu } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { backgroundGreyDark } from '@imo/imo-ui-toolkit'

export const StyledButton = styled(Button)`
  font-size: 12px;

  &:not([class*='bp4-intent-']) {
    width: 100%;
    justify-content: flex-start;
    box-shadow: none;
    background-image: none;
    background-color: transparent;
    max-width: 250px;
  }

  & > .bp4-icon {
    margin-right: 5px;
  }

  .bp4-button-text {
    line-height: 16px;
    margin-right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bp4-icon.bp4-icon-caret-down {
    margin-right: 0;
  }

  &.single-item {
    cursor: not-allowed;
  }
`

export const Title = styled.span`
  font-size: 12px;
  font-weight: 500;
`

export const StyledMenu = styled(Menu)<$TSFixMe>`
  min-width: 120px;
  font-size: 12px;
  max-width: 200px;
  max-height: 50vh;
  overflow: auto;

  .bp4-popover-open .bp4-menu-item,
  .bp4-menu-item:hover {
    background: ${backgroundGreyDark} !important;
  }

  ${({ styles }) => styles}
`

export const StyledPopover = styled(Popover2)`
  &.transparent {
    .bp4-button {
      box-shadow: none;
      background-color: transparent;
      white-space: nowrap;
      max-width: 100%;

      &:active,
      &:focus {
        box-shadow: none;
        background-color: transparent;
      }
    }

    .bp4-button-text {
      margin-right: 2px;
    }
  }

  &.small {
    .bp4-button {
      padding: 0 6px;
      border-bottom-left-radius: 3px !important;
      border-top-left-radius: 3px !important;

      .bp4-icon-dollar {
        margin-left: -3px;
      }
    }
  }
`

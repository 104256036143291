import { createGlobalStyle } from 'styled-components'
import { PopoverStyle } from '@shared/Popover'
import doubleCaretIcon from '@assets/icons/double-caret-icon.svg'
import {
  FONT_FAMILY,
  lightGray,
  white,
  getIsMac,
  customTippyStyles,
  commonStyles,
  intentStyles,
  gridCheckboxCustomization,
  normalize,
} from '@imo/imo-ui-toolkit'
import { Colors } from '@blueprintjs/core'

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  body {
    overflow: hidden;
    font-family: ${FONT_FAMILY};

    .ag-theme-balham {
      font-family: ${FONT_FAMILY};
    }
  }

  .org-design-rationale-tooltip-content {
    .bp4-popover2 {
      font-size: 12px;
    
      .bp4-popover2-content {
        color: #fff;
        background-color: ${PopoverStyle.BACKGROUND_COLOR};
      }
    
      .bp4-popover2-arrow-fill,
      .bp4-popover2-arrow-border {
        fill: ${PopoverStyle.BACKGROUND_COLOR};
      }
    }
  }
  
  .rtc-input-tooltip-content {
    .bp4-popover2 {
        font-size: 12px;
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
    
      .bp4-popover2-content {
        color: #fff;
        background-color: transparent;
        border: none;
        box-shadow: none;
      }
    }
  }

  .latex.wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .latex.operator {
    display:inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }

  .latex.operator.equals {
    transform:scale(1.5,1);
  }

  .latex.operator.minus {
    transform:scale(3,1);
  }

  .landing {
    width: 100%;
  }

  h1 {
    color: ${white};
  }

  strong,
  b {
    font-weight: 500;
  }

  #index {
    position: relative;
    z-index: 1;
  }

  .instructions-tooltip-content {
    * {
      z-index: 100;
    }
  }

  .my-team-select-popover {
    .bp4-menu {
      min-width: 50px;

      .bp4-menu-item {
        &.bp4-active {
          background-color: #EBF1F5;
        }

        color: #3C4C5A;
      }
    }
  }

  .interdependency-team-select-popover {
    width: 235px;
  }

  .ag-root-wrapper {
    border: none !important;
  }

  .team-popover,
  .libraries-popover {
    .bp4-menu {
      max-height: 342px;
      width: 285px;
      overflow-y: auto;
      font-size: 12px;
    }
  }

  .library-team-select-popover {
    .bp4-menu {
      max-height: 485px;
      overflow-y: auto;
      font-size: 12px;
    }
  }

  .disable-interactions {
    pointer-events: none;

    &.bp4-portal {
      z-index: 100;
    }
  }

  .not-allowed {
    cursor: not-allowed !important;
  }

  .initiative-time-select {
    .bp4-menu {
      max-height: 155px;
      overflow-y: hidden;
    }
  }

  .initiative-month-select {
    .bp4-menu {
      max-height: 155px;
      overflow-y: auto;
    }
  }

  .team-selector {
    > div {
      z-index: 100;
    }

    .bp4-input-action {
      margin: 6px;
    }

    .bp4-input-group {
      padding: 6px;
      margin-bottom: 2px;

      .bp4-icon-search {
        margin: 12px !important;
        left: -1px;
      }

      .bp4-input:not(:first-child) {
        font-size: 12px;
        line-height: 1.5;
      }
    }

    .bp4-tooltip2,
    .bp4-popover2 {
      .bp4-popover2-content {
        background-color: ${white};
      }

      .bp4-popover2-arrow-fill,
      .bp4-popover2-arrow-border {
        fill: ${white};
      }
    }
  }

  .cell-editor-selector {
    .bp4-input-action {
      margin: 6px;
    }

    .bp4-input-group {
      padding: 6px;
      margin-bottom: 0;

      .bp4-icon-search {
        margin: 12px;
        left: -1px;
      }

      .bp4-input:not(:first-child) {
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }

  .ag-theme-balham {
    .ag-row-odd {
      background-color: ${white};
    }

    .ag-center-cols-clipper {
      .ag-cell.cell-special-attributes {
        padding: 0 3px;
        text-align: center;
      }
    }

    .cell-header-special-attributes {
      .ag-header-cell-label {
        justify-content: center;
      }

      .ag-header-cell-text {
        font-size: 0;
        line-height: 0;

        &:before {
          content: '★';
          font-size: ${getIsMac() ? '14px' : '20px'};
          color: ${Colors.GRAY2};
          line-height: 1;
        }
      }
    }

    .contextMenuItem {
      display: flex;
      align-items: center;
      margin: 0 5px;
      border-radius: 2px;
    }

    .ag-menu-option-part {
      .bp4-icon {
        font-size: 16px;
        margin: 2px 0;
      }
    }

    .ag-menu-option-icon {
      width: auto;
    }

    .ag-rich-select-list {
      height: auto;
    }

    .multiselect-lookup input {
      align-self: flex-start;
    }
  }

  .bp4-overlay {
    z-index: 100;

    &.bp4-overlay-scroll-container {
      z-index: 1;
    }
  }

  .bp4-portal {
    .single-lookup-popover {
      height: 100vh;
      width: 100vw;
      position: fixed;
    }

    .bp4-popover-exit {
      visibility: hidden;
    }
  }

  .bp4-toast {
    .bp4-toast-message {
      font-size: 14px;
      line-height: 18px;
      overflow: hidden;

      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .recharts-cartesian-grid-vertical {
    line {
      stroke: #f0f0f0;
    }
  }

  .summary-status-approval-tooltip {
    > div {
      left: 3px !important;
    }
  }

  .grid-tooltip .bp4-popover-content {
    padding: 3px 10px 7px;
    border-radius: 2px;
  }

  .bp4-popover-content {
    font-size: 12px;
  }

  .ag-rich-select-list {
    min-height: 240px;
  }

  .validator-divider {
    border: none;
    margin: 0;
    padding-top: 10px;
  }

  .recharts-surface {
    border-radius: 2px;
  }

  .recharts-default-legend,
  .recharts-legend-item {
    line-height: 0;
  }

  .recharts-legend-item-text {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    vertical-align: middle;
  }

  .recharts-text.recharts-label tspan {
    font-size: 10px;
  }

  .recharts-text.recharts-cartesian-axis-tick-value tspan {
    font-size: 12px;
    font-weight: 400;
  }

  .bp4-datepicker-caption {
    margin: 0 20px 5px;

    .bp4-icon-double-caret-vertical {
      display: none;
    }

    .bp4-html-select::after {
      content: '';
      background-image: url(${doubleCaretIcon});
      position: absolute;
      right: 5px;
      width: 16px;
      height: 16px;
      line-height: 1em;
      top: 50%;
      transform: translateY(-50%);
      user-select: none;
    }

    & > :first-child {
      flex-grow: 0;
      flex-shrink: 1;
      min-width: 85px;
    }
  }

  .full-width.scroll-vertical-body-viewport {
    .ag-body-viewport.ag-layout-normal {
      overflow-y: scroll;
    }
  }

  .full-width.scroll-auto-vertical-body-viewport {
    .ag-body-viewport.ag-layout-normal {
      overflow-y: auto;
    }
  }

  .ag-rich-select-list {
    min-height: 30px;
    max-height: 290px;
    overflow-y: auto;
  }

  .bp4-transition-container {
    z-index: 100;
  }

  .ag-imo-cell-not-selected {
    color: ${lightGray};
  }

  .ag-imo-cell-not-selected input[class^=ag-][type=text] {
    color: var(--ag-data-color, var(--ag-foreground-color, #000));
  }

  textarea.ag-input-field-input {
    width: 40vw;
  }

  .bp4-tooltip2,
  .bp4-dark.bp4-popover2 {
    font-size: 12px;

    .bp4-popover2-content {
      color: #fff;
      background-color: ${PopoverStyle.BACKGROUND_COLOR};
    }

    .bp4-popover2-arrow-fill,
    .bp4-popover2-arrow-border {
      fill: ${PopoverStyle.BACKGROUND_COLOR};
    }
  }

  .bp4-heading {
    font-weight: 500;
  }

  .bp4-dialog {
    background-color: ${white};
  }

  .text-danger {
    color: ${Colors.RED3};
  }

  .text-warning {
    color: ${Colors.ORANGE2};
  }

  .text-bold {
    font-weight: 500;
  }

  ${intentStyles}
  ${customTippyStyles}
  ${commonStyles}
  ${gridCheckboxCustomization}
  .bp4-datepicker {
    .DayPicker-Day--outside {
      visibility: visible;
    }
  }

  .modal-dialog {
    z-index: 50;
  }

  .team-popover {
    z-index: 51;
  }

  .tippy-box {
    will-change: transition;
  }

  .bp4-toast-container {
    z-index: 101;
  }

  .ag-floating-filter-body {
    height: auto;
  }

  .custom-menu-list {
    .bp4-menu {
      min-width: 160px;
    }
  }

  .wysiwyg-editor-container {
    width: 600px;
    height: 400px;
    overflow: auto;
    margin: 5px 0 5px 5px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
    border-radius: 4px;

    .ProseMirror {
      min-height: 100%;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #d2d9de;
    }
  }

  .org-design-stage-tooltip-content {
    .bp4-popover2-content {
      border-radius: 2px;
      padding: 0;
    }
  }

  .status-rationale-layout {
    line-height: 14px;
    > ol > li > p, > ul > li > p {
      margin-bottom: 2px;
    }
  }

  .activity-log-view-changes-dialog {
    .bp4-overlay-scroll-container {
      z-index: 100;
    }
    
    .bp4-dialog-body-scroll-container {
      padding: 0;
    }
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`

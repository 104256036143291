import { createSaga } from '@common/sagaCreator/createSaga'
import { getVCSynergyCategoryApi } from '@common/net'
import { call, put } from 'redux-saga/effects'
import {
  setVCSynergyCategories,
  setVCOneTimeCosts,
  updateVCSynergyCategoriesSuccess,
  updateVCOneTimeCostsSuccess,
  setVCBaselineAssumption,
  updateVCBaselineAssumptionSuccess,
  deleteVCOneTimeCostsSuccess,
  deleteVCSynergyCategoriesSuccess,
} from '@valueCaptureTrackerV2/teamSetup/actions/actions'
import { synergyTypes } from '@helpers/constants'
import { pick } from 'lodash'

export const fetchVCSynergyCategories = createSaga(function* fetchVCSynergyCategories({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, synergyType, ...query } = payload
  const api = yield getVCSynergyCategoryApi()
  const data = yield call(
    api.request('getSynergyCategory', {
      query: {
        teamId,
        synergyType,
        ...query,
      },
    }),
  )

  synergyType === synergyTypes.ONE_TIME_COST
    ? yield put(setVCOneTimeCosts({ data }))
    : yield put(setVCSynergyCategories({ data }))

  return data
})

export const createVCSynergyCategories = createSaga(function* createVCSynergyCategories({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { synergyType, data } = payload
  const api = yield getVCSynergyCategoryApi()
  const { id } = yield call(
    api.request('createSynergyCategory', {
      body: {
        synergyType: synergyType,
        name: data.name,
      },
    }),
  )

  synergyType === synergyTypes.ONE_TIME_COST
    ? yield put(updateVCOneTimeCostsSuccess({ id: data.id, data: { ...data, id, isNew: false } }))
    : yield put(updateVCSynergyCategoriesSuccess({ id: data.id, data: { ...data, id, isNew: false } }))
})

export const updateVCSynergyCategory = createSaga(function* updateVCSynergyCategory({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { synergyType, categoryId, data } = payload

  const api = yield getVCSynergyCategoryApi()
  yield call(
    api.request('updateSynergyCategory', {
      query: {
        categoryId,
      },
      body: pick(data, ['name']),
    }),
  )

  synergyType === synergyTypes.ONE_TIME_COST
    ? yield put(updateVCOneTimeCostsSuccess({ id: categoryId, data }))
    : yield put(updateVCSynergyCategoriesSuccess({ id: categoryId, data }))
})

export const deleteVCSynergyCategory = createSaga(function* deleteVCSynergyCategory({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { synergyType, categoryId } = payload

  const api = yield getVCSynergyCategoryApi()
  yield call(
    api.request('deleteSynergyCategory', {
      query: {
        categoryId,
        synergyType,
      },
    }),
  )

  synergyType === synergyTypes.ONE_TIME_COST
    ? yield put(deleteVCOneTimeCostsSuccess({ id: categoryId }))
    : yield put(deleteVCSynergyCategoriesSuccess({ id: categoryId }))
})

export const fetchVCBaselineAssumption = createSaga(function* fetchVCBaselineAssumption({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, synergyType } = payload

  const api = yield getVCSynergyCategoryApi()
  const data = yield call(
    api.request('getBaselineAssumption', {
      query: {
        teamId,
        synergyType,
      },
    }),
  )

  yield put(setVCBaselineAssumption({ data }))
})

export const updateVCBaselineAssumption = createSaga(function* updateVCBaselineAssumption({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, assumptionId, data } = payload

  const api = yield getVCSynergyCategoryApi()
  yield call(
    api.request('updateBaselineAssumptions', {
      query: {
        teamId,
        assumptionId,
      },
      body: data,
    }),
  )

  yield put(updateVCBaselineAssumptionSuccess({ id: assumptionId, data }))
})

import { formatUrl } from '@helpers/urlUtils'
import { HttpStatusError } from '@helpers/customErrors'
import { isUndefined } from 'lodash'
import { NO_CONTENT } from 'http-status-codes'

export type FetchFn = (url: string, opts: { method: string; headers: unknown; body?: unknown }) => Promise<Response>

export type CustomHttpClientErrorType = {
  fromServer?: boolean
  message?: string
  errorMessage?: string
  name?: string
  status?: number
  action?: null | string
}

export default class HttpClient {
  fetch: FetchFn
  host?: string
  routePrefix: string;
  // this below is for the this[actionsName] lookup
  // definitely needs fixed
  [key: string]: $TSFixMe

  constructor(fetch: $TSFixMe, routePrefix = '/config') {
    this.fetch = fetch
    this.routePrefix = routePrefix
  }

  request = (actionsName: string, { id, query = {}, body = {} }: $TSFixMe = {}) => {
    if (!this[actionsName]) {
      throw new Error(`Action ${actionsName} not found`)
    }

    return async () => {
      const data = await this[actionsName]({
        id,
        query,
        body,
      })

      return data
    }
  }

  hasContent = (status?: number) => {
    switch (status) {
      case NO_CONTENT:
        return false
      default:
        return true
    }
  }

  call = async (
    { pathname, routePrefix, query = {}, host }: $TSFixMe,
    { method = 'GET', headers = {}, body, shouldSubstituteHeader = true }: $TSFixMe = {},
  ) => {
    if (isUndefined(headers['Content-Type']) && shouldSubstituteHeader) {
      headers['Content-Type'] = 'application/json'
    }

    if (body && headers['Content-Type'] === 'application/json') {
      body = JSON.stringify(body)
    }

    const url = formatUrl({
      host: host ? host : this.host,
      pathname: !isUndefined(routePrefix) ? routePrefix + pathname : this.routePrefix + pathname,
      query,
    })

    const res = await this.fetch(url, { method, headers, body })

    const { ok, status } = res

    if (!ok) {
      const result = await res.json()
      // build error with custom message. It ignores error message from backend
      throw new HttpStatusError(result)
    }

    return this.hasContent(status) ? res.json() : null
  }
}

import { put, call } from 'redux-saga/effects'
import { deleteProcessSuccess } from '../actions/actions'
import { getMutualDiscoveryApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { delay } from 'redux-saga'

function* deleteSubProcess({ teamId, id, keyProcessId }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getMutualDiscoveryApi()

  yield call(
    api.request('deleteSubProcess', {
      query: { teamId, id, keyProcessId },
    }),
  )

  yield put(deleteProcessSuccess({ id, keyProcessId }))
}

function* deleteInScopeKeyProcessData({ id, teamId }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getMutualDiscoveryApi()

  yield call(api.request('deleteKeyProcess', { query: { teamId, id } }))

  yield put(deleteProcessSuccess({ id }))
}

export const deleteProcess = createSaga(function* deleteProcess({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { keyProcessId } = payload

  if (keyProcessId) {
    yield call(deleteSubProcess, payload)
  } else {
    yield call(deleteInScopeKeyProcessData, payload)
  }

  yield call(delay, 100)
},
true)

//TODO rework for multiple deletion
export const deleteProcessItems = createSaga(function* deleteProcess() {
  yield call(delay, 100)
}, true)

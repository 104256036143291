import React, { memo } from 'react'
import { Content, HeaderIcon, Heading, Separator, Description } from './index.styles'

interface Props {
  styles?: $TSFixMe
}

const InsufficientPermissionsPage = ({ styles }: Props) => (
  <Content styles={styles}>
    <HeaderIcon icon="disable" size={60} />
    <Heading>Insufficient permissions</Heading>
    <Separator />
    <Description>
      You currently do not possess sufficient permissions to access this section of myIMO. Please contact your program
      administrator if you have additional questions.
    </Description>
  </Content>
)

InsufficientPermissionsPage.defaultProps = {
  styles: {},
}

export default memo(InsufficientPermissionsPage)

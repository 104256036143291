import { call, put } from 'redux-saga/effects'
import { setTeamDeliverableStatus } from '../actions/actions'
import { getGenericApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchDeliverableStatus = createSaga(function* fetchDeliverableStatus(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const genericApi = yield getGenericApi()
  const deliverableStatuses = yield call(genericApi.request('getDeliverableStatus'))

  yield put(setTeamDeliverableStatus({ deliverableStatuses }))
})

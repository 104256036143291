import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { en } from './en'

export const resources = {
  en,
} as const

i18n.use(initReactI18next).init({
  lng: 'en',
  defaultNS: 'COMMON',
  resources,
  interpolation: {
    // not needed for react as it escapes by default
    escapeValue: false,
  },
})

export default i18n

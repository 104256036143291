import { map } from 'lodash'
import moment from 'moment'

export const getMonth = () =>
  map(new Array(12), (_, i) => ({
    label: moment().month(i).format('MMMM'),
    id: i,
  }))

export const getYears = (creationDate = new Date()) => {
  let initialYear = creationDate.getFullYear()

  return map(new Array(5), (_, i) => ({
    label: initialYear + i,
    id: i + 1,
  }))
}

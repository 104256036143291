import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchHypercareLogData = createAction(constants.FETCH_HYPERCARE_LOG_DATA, null, getMetaWithActionLoading)
export const setHypercareLogData = createAction(constants.SET_HYPERCARE_LOG_DATA)
export const resetHypercareLogData = createAction(constants.RESET_HYPERCARE_LOG_DATA)

export const saveIssue = createAction(constants.SAVE_ISSUE)
export const addIssue = createAction(constants.ADD_ISSUE)

export const deleteIssue = createAction(constants.DELETE_ISSUE)
export const deleteIssueSuccess = createAction(constants.DELETE_ISSUE_SUCCESS)

export const updateIssue = createAction(constants.UPDATE_ISSUE)
export const updateIssueSuccess = createAction(constants.UPDATE_ISSUE_SUCCESS)

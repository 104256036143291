import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'

export interface IImportKnowledgeCenterState {
  librariesForImport?: $TSFixMe[]
  libraryAssetsFetchDate?: number
  libraryAssets: $TSFixMe[]
}

export const initialState = {
  librariesForImport: [],
  libraryAssetsFetchDate: 0,
  libraryAssets: [],
}

const importKnowledgeCenterReducer = handleActions<IImportKnowledgeCenterState, $TSFixMe>(
  {
    [constants.SET_LIBRARY_ASSETS]: (state, action) => ({
      ...state,
      libraryAssets: action.payload,
    }),
    [constants.SET_LIBRARY_ASSETS_WITH_TIME]: (state, action) => {
      const { libraryAssetsFetchDate, libraryAssets } = action.payload

      return {
        ...state,
        libraryAssetsFetchDate,
        libraryAssets,
      }
    },
    [constants.SET_LIBRARIES_FOR_IMPORT]: (state, action) => ({
      ...state,
      librariesForImport: action.payload,
    }),
    [constants.CHANGE_ASSET_STATUS]: (state, action) => {
      const { libraryId, videoId: id, selected } = action.payload

      return {
        ...state,
        libraryAssets: state.libraryAssets.map((library) => {
          if (library.id === libraryId) {
            const newLibrary = { ...library }

            if (library.videos) {
              newLibrary.videos = library.videos.map((asset: $TSFixMe) =>
                asset.id === id ? { ...asset, selected } : asset,
              )
            }

            if (library.articles) {
              newLibrary.articles = library.articles.map((asset: $TSFixMe) =>
                asset.id === id ? { ...asset, selected } : asset,
              )
            }

            if (library.introductionMaterials) {
              newLibrary.introductionMaterials = library.introductionMaterials.map((asset: $TSFixMe) =>
                asset.id === id ? { ...asset, selected } : asset,
              )
            }

            return newLibrary
          }

          return library
        }),
      }
    },
  },
  initialState,
)

export default importKnowledgeCenterReducer

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class TeamHierarchyApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
  }

  getTeamHierarchy = () => {
    return this.call({ pathname: '/team-hierarchy' })
  }

  createTeamHierarchy = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: '/team-hierarchy/bulk' },
      {
        method: 'PUT',
        body,
      },
    )
  }
}

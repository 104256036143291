import { put, call, select } from 'redux-saga/effects'
import {
  fetchTeamResourcesMasterplan as fetchMasterplanAction,
  setTeamResourcesMasterplan,
} from '@teamResources/masterplan/actions/actions'
import { getGenericApi, getTeamResourcesApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getTenantId } from '@generic/selectors'
import { runDownloadByUrl } from '@helpers/utils'
import { getTeamResourcesMasterplan } from '@teamResources/masterplan/selectors'

export const fetchTeamResourcesMasterplan = createSaga(function* fetchTeamResourcesMasterplan(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const directory = yield select(getTenantId)
  const api = yield getTeamResourcesApi()
  const genericApi = yield getGenericApi()
  const { key } = yield call(api.request('getTeamResourcesMasterplan'))

  if (key) {
    const { signedUrl } = yield call(genericApi.request('getFileSignedUrl', { query: { key, directory } }))

    yield put(setTeamResourcesMasterplan(signedUrl))
  }
})

export const downloadTeamResourcesMasterplan = createSaga(function* downloadTeamResourcesMasterplan(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  // @ts-expect-error check saga types
  yield call(fetchTeamResourcesMasterplan)
  const masterplan = yield select(getTeamResourcesMasterplan)

  runDownloadByUrl({ url: masterplan, name: 'masterplan' })
})

export const updateTeamResourcesMasterplan = createSaga(function* updateTeamResourcesMasterplan(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { file } = action.payload
  const formData = new FormData()

  formData.append('attachment', file)

  const api = yield getTeamResourcesApi()

  yield call(
    api.request('updateTeamResourcesMasterplan', {
      body: formData,
    }),
  )

  yield put(fetchMasterplanAction())
})

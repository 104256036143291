import React from 'react'
import {
  TeamItem,
  TeamItemTop,
  TeamItemBottom,
  TeamItemName,
  TeamItemType,
  TeamItemNote,
  TeamItemNoteRight,
} from './TeamSelector.styles'
import { Divider } from '@blueprintjs/core'
import { getTypeColor } from '@imo/imo-ui-toolkit'

interface Props {
  team: $TSFixMe
  itemProps: $TSFixMe
  isSelected: (...args: $TSFixMe[]) => $TSFixMe
}

const TeamsListItem = ({ team, itemProps, isSelected }: Props) => {
  const { handleClick, modifiers } = itemProps
  const { longName, teamType, taxonomyGeography, taxonomyBusinessUnit } = team

  if (!modifiers.matchesPredicate) {
    return null
  }

  return (
    <div role="option" aria-selected={isSelected(team)}>
      <Divider className="item-divider" />
      <TeamItem className={isSelected(team) ? 'selected' : ''} onClick={handleClick}>
        <TeamItemTop>
          <TeamItemName title={longName}>{longName}</TeamItemName>
          <TeamItemType typeColor={getTypeColor({ teamType })}>{teamType}</TeamItemType>
        </TeamItemTop>
        <TeamItemBottom>
          <TeamItemNote>{taxonomyGeography}</TeamItemNote>
          <TeamItemNoteRight>{taxonomyBusinessUnit}</TeamItemNoteRight>
        </TeamItemBottom>
      </TeamItem>
    </div>
  )
}

export default TeamsListItem

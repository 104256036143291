//@ts-nocheck
//TODO: remove no check and fix TS errors
import * as Yup from 'yup'
import { hasOnlyRestrictedRole, combinePaths, checkUserDuplications } from '@helpers/schemas/utils'
import { size } from 'lodash/fp'

export const usersSchema = Yup.array().of(
  Yup.object().shape({
    name: Yup.string().trim().required('Name is required.'),
    organization: Yup.string().trim().required('User organization is required.'),
    role: Yup.string().trim().required('User role is required.'),
    surname: Yup.string().trim().required('Surname is required.'),
  }),
)

const UserSchema = {
  name: Yup.string().nullable().required('This field is required'),
  surname: Yup.string().nullable().required('This field is required'),
  role: Yup.string().nullable().required('This field is required'),
  organization: Yup.string().nullable().required('This field is required'),
  email: Yup.string().nullable().required('This field is required'),
  teams: Yup.object().test(
    'hasOnlyRestrictedRole',
    'Only the Restricted User role is allowed for users under this email domain',
    hasOnlyRestrictedRole,
  ),
}

// TODO: maybe better to move to toolkit, we have the same validation in CV
function isUniqueEmailOrName(value) {
  const duplications = checkUserDuplications(value, this.parent)

  if (size(duplications)) {
    return this.createError({
      path: combinePaths(this.path, duplications),
      params: { duplications },
    })
  }

  return true
}

export const DomainsSchema = Yup.array().of(
  Yup.object(UserSchema).test(
    'User duplication validation',
    'User with the same name or email address already exists',
    isUniqueEmailOrName,
  ),
)

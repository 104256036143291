import { dropRight, get, isEmpty, isNil, isString, last, sortBy, startsWith, words } from 'lodash/fp'
import { TextRowTitle } from './index.styles'
import React from 'react'

export const validateFields = (data, validationFields) => {
  return validationFields.reduce((acc, fieldName) => {
    const isEmptyString = isString(data[fieldName]) && !data[fieldName].trim().length
    const isDataInvalid = [isNil(data[fieldName]), isEmpty(data[fieldName]), isEmptyString].includes(true)

    if (isDataInvalid) {
      acc[fieldName] = true
    }

    return acc
  }, {})
}

const removeVersion = (string) => {
  return string.replace(/ \(\d\)$/gu, '')
}

export const makeUniqueName = (targetArray, string, searchProperty, maxLength = 500) => {
  const hasSameName = targetArray.find((item) => item[searchProperty] === string)

  if (hasSameName) {
    const splitString = string.split(' ')

    const hasVersion = last(splitString).match(/\(\d\)/gu)

    if (!isEmpty(splitString)) {
      const initialString = hasVersion ? dropRight(1, splitString).join(' ') : splitString.join(' ')

      let latestVersion

      const relatedItems = targetArray.filter((item) => startsWith(removeVersion(item[searchProperty]), initialString))

      const itemsWithVersions = relatedItems.filter((item) => last(item[searchProperty].split(' ')).match(/\(\d\)/gu))

      const sortedItemsWithVersions = sortBy(searchProperty, itemsWithVersions)

      const getLatestVersion = isEmpty(sortedItemsWithVersions)
        ? 0
        : last(words(last(sortedItemsWithVersions)[searchProperty]))

      latestVersion = Number(getLatestVersion) + 1

      const result = `${initialString} (${latestVersion})`

      if (result.length > maxLength) {
        return `${initialString.slice(0, maxLength - result.length)} (${latestVersion})`
      }

      return result
    }
  }

  return string
}
export const getDeletionConfirmationText = (entity) => {
  return entity.isShared ? (
    <TextRowTitle>
      You are about to delete the <b>{entity.title}</b> {entity.type}. This content is shared with other workstreams and
      will be also removed from their respective Knowledge Centers.
    </TextRowTitle>
  ) : (
    <TextRowTitle>You are about to delete the file.</TextRowTitle>
  )
}

export const tooltipUnshare = 'Enable to show the content for all workstreams'

export const tooltipShare = 'Disable to show the content for only this workstream'
export const getTooltipForReceivingTeam = (teamName, entity) =>
  `This ${entity} is being shared from the ${teamName} team`
export const getTeamNameById = (teamId, teams) =>
  get(
    'longName',
    teams.find(({ id }) => id === teamId),
  )

export const isReadonly = (item = {}, teamId = '') => item.isShared && item.teamId !== teamId

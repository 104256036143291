import { isEmpty } from 'lodash'
import querystring from 'querystring'
import encodeUrl from 'encodeurl'

export function formatUrl({ host, pathname, query }) {
  if (pathname[0] !== '/') {
    pathname = `/${pathname}`
  }

  if (typeof query === 'object' && !isEmpty(query)) {
    query = `?${querystring.stringify(query)}`
  } else {
    query = ''
  }

  return encodeUrl(`${host}${pathname}${query}`)
}

import { handleActions } from 'redux-actions'
import * as constants from '@teamWorkspace/actions/actionTypes'
import { updateStateSingleItem } from '@teamWorkspace/reducers/utils'

export interface ITeamRiskLogState {
  createdAt: Date | null
  items: $TSFixMe[]
}
export interface ITeamDecisionLogState {
  createdAt: Date | null // Represents the date when the first log activity was recorded (null if no activity)
  items: $TSFixMe[]
}

export interface ITeamWorkspaceState {
  teamActions: $TSFixMe[]
  teamWhiteboard: $TSFixMe[]
  teamRisks: ITeamRiskLogState
  teamDecisions: ITeamDecisionLogState
  teamCalendar: $TSFixMe[]
  teamTopics: $TSFixMe[]
  imoTopics: $TSFixMe[]
}

export const initialState: ITeamWorkspaceState = {
  teamActions: [],
  teamWhiteboard: [],
  teamRisks: {
    createdAt: null,
    items: [],
  },
  teamDecisions: {
    createdAt: null,
    items: [],
  },
  teamCalendar: [],
  teamTopics: [],
  imoTopics: [],
}

const teamWorkspace = handleActions<ITeamWorkspaceState, $TSFixMe>(
  {
    [constants.SET_WHITEBOARD]: (state, action) => {
      return {
        ...state,
        teamWhiteboard: action.payload,
      }
    },
    [constants.ADD_WHITEBOARD]: (state, action) => {
      const { teamWhiteboard } = state

      return {
        ...state,
        teamWhiteboard: [...teamWhiteboard, action.payload],
      }
    },
    [constants.REMOVE_WHITEBOARD]: (state, action) => {
      const { teamWhiteboard } = state
      const { id } = action.payload

      return {
        ...state,
        teamWhiteboard: teamWhiteboard.filter((item) => item.id !== id),
      }
    },
    [constants.SET_TEAM_ACTIONS]: (state, action) => {
      return {
        ...state,
        teamActions: action.payload,
      }
    },
    [constants.ADD_TEAM_ACTION]: (state, action) => {
      return {
        ...state,
        teamActions: [...state.teamActions, action.payload],
      }
    },
    [constants.UPDATE_TEAM_ACTION_SUCCESS]: updateStateSingleItem('teamActions'),
    [constants.REMOVE_TEAM_ACTION]: (state, action) => {
      const { teamActions } = state
      const { id } = action.payload

      return {
        ...state,
        teamActions: teamActions.filter((item) => item.id !== id),
      }
    },
    [constants.SET_DECISIONS_LOG]: (state, action) => {
      return {
        ...state,
        teamDecisions: action.payload,
      }
    },
    [constants.ADD_DECISIONS_LOG]: (state, action) => {
      const { teamDecisions } = state

      return {
        ...state,
        teamDecisions: {
          ...teamDecisions,
          items: [...teamDecisions.items, action.payload],
        },
      }
    },
    [constants.UPDATE_DECISIONS_LOG_SUCCESS]: updateStateSingleItem('teamDecisions'),
    [constants.REMOVE_DECISIONS_LOG]: (state, action) => {
      const { teamDecisions } = state
      const { id } = action.payload

      return {
        ...state,
        teamDecisions: {
          ...teamDecisions,
          items: teamDecisions.items.filter((item) => item.id !== id),
        },
      }
    },
    [constants.SET_RISKS_LOG]: (state, action) => {
      return {
        ...state,
        teamRisks: action.payload,
      }
    },
    [constants.ADD_RISKS_LOG]: (state, action) => {
      const { teamRisks } = state

      return {
        ...state,
        teamRisks: {
          ...teamRisks,
          items: [...teamRisks.items, action.payload],
        },
      }
    },
    [constants.UPDATE_RISKS_LOG_SUCCESS]: updateStateSingleItem('teamRisks'),
    [constants.REMOVE_RISKS_LOG]: (state, action) => {
      const { teamRisks } = state
      const { id } = action.payload

      return {
        ...state,
        teamRisks: {
          ...teamRisks,
          items: teamRisks.items.filter((item) => item.id !== id),
        },
      }
    },
    [constants.SET_CALENDAR]: (state, action) => {
      return {
        ...state,
        teamCalendar: action.payload,
      }
    },
    [constants.ADD_CALENDAR]: (state, action) => {
      const { teamCalendar } = state

      return {
        ...state,
        teamCalendar: [...teamCalendar, action.payload],
      }
    },
    [constants.REMOVE_CALENDAR]: (state, action) => {
      const { teamCalendar } = state
      const { id } = action.payload

      return {
        ...state,
        teamCalendar: teamCalendar.filter((item) => item.id !== id),
      }
    },
    [constants.SET_TEAM_TOPICS]: (state, action) => {
      return {
        ...state,
        teamTopics: action.payload,
      }
    },
    [constants.SET_IMO_TOPICS]: (state, action) => {
      return {
        ...state,
        imoTopics: action.payload,
      }
    },
    [constants.ADD_TEAM_TOPICS]: (state, action) => {
      const { teamTopics } = state

      return {
        ...state,
        teamTopics: [...teamTopics, action.payload],
      }
    },
    [constants.REMOVE_TEAM_TOPICS]: (state, action) => {
      const { teamTopics } = state
      const { id } = action.payload

      return {
        ...state,
        teamTopics: teamTopics.filter((item) => item.id !== id),
      }
    },
  },
  initialState,
)

export default teamWorkspace

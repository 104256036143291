import {
  getFilterForRequest,
  getSortingForRequest,
} from '@myImoClient/components/TeamValueCaptureV2/InitiativeList/utils'
import { isEmpty, omit, isEqual, mapKeys, filter } from 'lodash/fp'

export const getBodyParams = ({ body, sorting, filters, customColumns }) => {
  const bodyParams = body

  if (getSortingForRequest(sorting, customColumns)) {
    bodyParams.sort = JSON.stringify(getSortingForRequest(sorting, customColumns))
  }

  if (!isEmpty(getFilterForRequest(filters, customColumns))) {
    bodyParams.filters = JSON.stringify(getFilterForRequest(filters, customColumns))
  }

  return bodyParams
}

export const checkIsFiltersEqual = ({ filterStateModel, savedFilter, api }) => {
  let isFilterEquals = true

  const check = (a1, a2) => {
    mapKeys((key) => {
      if (!isFilterEquals) return

      const filter2 = a2[key]

      if (!filter2) return (isFilterEquals = false)

      const filter1 = a1[key]

      if (filter2.filterType === 'set') {
        const valuesExistInTable = api.getFilterInstance(key).getValues()
        const isValueInList = (value) => valuesExistInTable.includes(value)
        const normalizedFilter1 = { ...filter1, values: filter(isValueInList, filter1?.values) }
        const normalizedFilter2 = { ...filter2, values: filter(isValueInList, filter2?.values) }

        isFilterEquals = isEqual(omit('custom', normalizedFilter1), omit('custom', normalizedFilter2))
      } else {
        isFilterEquals = isEqual(omit('custom', filter1), omit('custom', filter2))
      }
    }, a1)
  }

  check(filterStateModel, savedFilter)
  check(savedFilter, filterStateModel)

  return isFilterEquals
}

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@hypercareLog/actions/actionTypes'

import { fetchHypercareLogData } from './fetchHypercareLogData'
import { saveIssue } from './saveIssue'
import { deleteIssue } from './deleteIssue'
import { updateIssue } from './updateIssue'

export default function* hypercareLogSaga() {
  yield all([
    takeLatest(constants.FETCH_HYPERCARE_LOG_DATA, fetchHypercareLogData),
    takeLatest(constants.SAVE_ISSUE, saveIssue),
    takeLatest(constants.DELETE_ISSUE, deleteIssue),
    takeLatest(constants.UPDATE_ISSUE, updateIssue),
  ])
}

import { call, put, select } from 'redux-saga/effects'
import { fetchTeamWeekStatus } from '@teamHome/actions/actions'
import { getMyTeamApi } from '@common/net'
import { getTeamWeekStatus, getStatusRationale, getEscalations } from '@teamHome/selectors'
import { createSaga } from '@common/sagaCreator/createSaga'

export const updateTeamWeekStatus = createSaga(function* updateTeamWeekStatus({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const name = yield select(getTeamWeekStatus)
  const rationale = yield select(getStatusRationale)
  const escalations = yield select(getEscalations)

  const { teamId, ...statusUpdates } = payload
  const myTeamApi = yield getMyTeamApi()
  yield call(
    myTeamApi.request('updateTeamWeeklyStatus', {
      query: { teamId },
      body: { name, rationale, escalations, ...statusUpdates },
    }),
  )

  yield put(fetchTeamWeekStatus({ teamId }))
})

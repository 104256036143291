import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class TargetListV2Api extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = 'value-capture/v2/'
  }

  getTargetList = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `target?type=${query.type}&initiatorId=${query.initiatorId}` })
  }

  getTarget = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `target/${query.targetId}` })
  }

  createTarget = ({ body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `target`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateTarget = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `target/${query.targetId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteTarget = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `target/${query.targetId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }
}

import { get, has, find, isNil } from 'lodash'
import { formatOwner } from '@shared/Grid/CellEditors/utils'
import { isFunction, isString } from 'lodash/fp'
import { BLANKS_FILTER_LABEL } from '@helpers/constants'
import OwnerCellRenderer from '@shared/Grid/CellRenderers/OwnerCellRenderer/OwnerCellRenderer'
import OwnerCellEditor from '@shared/Grid/CellEditors/OwnerCellEditor'

const getOwnerObject = (owner, user) =>
  user ? { ...owner, name: `${user.name} ${user.surname}` } : { name: owner.displayName }

export const getOwner = (owner, users) => {
  if (!owner) return ''

  const userByOwnerId = has(owner, 'id') ? find(users, { id: owner.id }) : null
  const ownerObject = getOwnerObject(owner, userByOwnerId)

  return ownerObject.name || null
}

export const transformOwnerToString = (params) => {
  const {
    context: { users },
    data,
  } = params

  if (data && data.interdependency) {
    return getOwner(data.interdependency, users)
  } else {
    return getOwner(data, users)
  }
}

export const getInitialOwnerObject = ({ name, id }) => {
  if (!id && !name) return null

  return id ? { id, displayName: name } : { displayName: name }
}

export const newOwnerFormatter = ({ value }) => {
  if (isString(value)) return value

  return !isNil(get(value, 'displayName')) ? get(value, 'displayName') : null
}

export const newOwnerComparator = (item1, item2) => {
  const valueA = get(item1, 'displayName', '')
  const valueB = get(item2, 'displayName', '')

  return valueA.toLowerCase().localeCompare(valueB.toLowerCase())
}

export const newOwnersFormatter = ({ value }) =>
  !!get(value, 'length') ? value.map((item) => newOwnerFormatter({ value: item })).join(', ') : ''

export const parseOwner = (owner) => {
  if (!owner) return null

  const { displayName, id } = owner

  return id ? { id } : { displayName }
}

export const parseOwners = (owners) => {
  if (!get(owners, 'length')) return []

  return owners.map(parseOwner)
}

export const getOwnerRendererProps = () => ({
  cellEditor: OwnerCellEditor,
  cellRenderer: OwnerCellRenderer,
  cellEditorParams: {
    parseValue: getInitialOwnerObject,
  },
  tooltipValueGetter: transformOwnerToString,
  valueFormatter: formatOwner,
})

export const getOwnersFromContext = ({ context: { users }, value: owners }) => {
  return owners.map((owner) => {
    const user = users.find((item) => item.id === owner.id)
    const userFullName = user && `${user.name} ${user.surname}`

    return userFullName || owner.displayName
  })
}

export const ownerKeyCreator = (props) => {
  const {
    value,
    colDef: { valueGetter, valueFormatter },
  } = props

  if (!isFunction(valueGetter)) return value || BLANKS_FILTER_LABEL

  const realValue = valueGetter(props)

  if (!realValue) return BLANKS_FILTER_LABEL

  if (isFunction(valueFormatter)) return valueFormatter({ ...props, value: realValue })

  return realValue
}

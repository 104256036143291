import { mapValues, orderBy } from 'lodash'
import { find, getOr } from 'lodash/fp'
import { defaultProjectMapItem, linkedItemType, linkedItemTypes } from '@teamValueCaptureV2/projectMap/constants'
import { generateNextId } from '@helpers/utils'
import { getSortingByListIdHierarchyLevels } from '@shared/DayOne/utils/mapDayOneData'
import { listItemTypes, processItemHierarchy } from '@shared/DayOne/constants'

export const mapLinkedItems = ({ l2Projects, initiatives, oneTimeCosts }: $TSFixMe) => {
  const results = orderBy(
    l2Projects,
    getSortingByListIdHierarchyLevels(processItemHierarchy[listItemTypes.PROJECT]),
  ).map((linkedProject, index) => ({
    ...defaultProjectMapItem,
    [linkedItemType.L2_PROJECT]: { ...linkedProject },
    id: index + 1,
  }))

  initiatives.forEach((initiative: $TSFixMe) => {
    const { linkedProjectId } = initiative
    if (!linkedProjectId)
      return results.push({
        ...defaultProjectMapItem,
        [linkedItemType.INITIATIVE]: { ...initiative },
        id: results.length + 1,
      })

    const linkedItem = find(['linkedProject.id', linkedProjectId], results)

    if (!linkedItem) return

    linkedItem[linkedItemType.INITIATIVE] = { ...initiative }
  })

  oneTimeCosts.forEach((oneTimeCost: $TSFixMe) => {
    const { linkedProjectId, initiativeId } = oneTimeCost

    if (!linkedProjectId && !initiativeId)
      return results.push({
        ...defaultProjectMapItem,
        [linkedItemType.ONE_TIME_COST]: { ...oneTimeCost },
        id: results.length + 1,
      })

    const linkedItem = find(
      linkedProjectId ? ['linkedProject.id', linkedProjectId] : ['initiative.id', initiativeId],
      results,
    )

    if (!linkedItem) return

    linkedItem[linkedItemType.ONE_TIME_COST] = { ...oneTimeCost }
  })

  return results
}

export const linkItem = (source: $TSFixMe, itemToLink: $TSFixMe, linkedItemType: $TSFixMe) => {
  const updatedLinkedItem = { ...itemToLink[linkedItemType] }

  linkedItemTypes.forEach((type) => {
    if (type !== linkedItemType) updatedLinkedItem[`${type}Id`] = getOr(null, `${type}.id`, source)
  })

  return mapValues(source, (valueData, key) => {
    if (key === linkedItemType) return updatedLinkedItem

    return valueData && key !== 'id'
      ? {
          ...valueData,
          [`${linkedItemType}Id`]: updatedLinkedItem.id,
        }
      : valueData
  })
}

export const unlinkAllFromLinkedItem = (item: $TSFixMe) =>
  mapValues(item, (value, key) => (linkedItemTypes.includes(key.replace('Id', '')) ? null : value))

export const unlinkTypeFromItem = (source: $TSFixMe, linkedItemType: $TSFixMe) =>
  mapValues(source, (valueData, key) => {
    if (key === linkedItemType) return null

    return valueData && key !== 'id' ? { ...valueData, [`${linkedItemType}Id`]: null } : valueData
  })

export const linkItems = (
  items: $TSFixMe,
  { sourceType, sourceId, linkedItemType, linkedItemId, shouldRemovePrevious }: $TSFixMe,
) => {
  const itemToLink = find([`${linkedItemType}.id`, linkedItemId], items)

  return items.reduce((results: $TSFixMe, item: $TSFixMe) => {
    //remove newly linked item from list
    if (getOr(null, `${linkedItemType}.id`, item) === linkedItemId) return results

    if (getOr(null, `${sourceType}.id`, item) === sourceId) {
      const itemToUnlink = item[linkedItemType]
      //add rewritten source with newly linked item
      results.push(linkItem(item, itemToLink, linkedItemType))
      //add unlinked item from source
      if (itemToUnlink && !shouldRemovePrevious)
        results.push({
          ...defaultProjectMapItem,
          [linkedItemType]: unlinkAllFromLinkedItem(item[linkedItemType]),
          id: generateNextId(items),
        })
    } else {
      results.push(item)
    }

    return results
  }, [])
}

export const unlinkItems = (
  items: $TSFixMe,
  { linkedItemType, linkedItemId }: $TSFixMe,
  shouldRemovePrevious: boolean,
  shouldRemoveParent: boolean,
) =>
  items.reduce((results: $TSFixMe, item: $TSFixMe) => {
    if (getOr(null, `${linkedItemType}.id`, item) === linkedItemId) {
      if (!shouldRemoveParent) results.push(unlinkTypeFromItem(item, linkedItemType))

      if (!shouldRemovePrevious)
        results.push({
          ...defaultProjectMapItem,
          [linkedItemType]: unlinkAllFromLinkedItem(item[linkedItemType]),
          id: generateNextId(items),
        })
    } else {
      results.push(item)
    }

    return results
  }, [])

import { call, fork, put } from 'redux-saga/effects'
import { getInitiativeListV2Api } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import {
  setInitiativeCommentsList,
  setStageGateValidationStatus,
  createInitiativeCommentSuccess,
  updateStageGateSuccess,
} from '../actions/actions'

export const fetchInitiativeCommentsList = createSaga(function* fetchInitiativeCommentsList({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getInitiativeListV2Api()
  const initiativeCommentsList = yield call(
    api.request('getInitiativeCommentsList', {
      query: payload,
    }),
  )

  yield put(setInitiativeCommentsList({ initiativeCommentsList }))
})

export const fetchStageGateValidationStatus = createSaga(function* fetchStageGateValidationStatus({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getInitiativeListV2Api()
  const stageGateValidationStatus = yield call(
    api.request('getStageGateValidationStatus', {
      query: payload,
    }),
  )

  yield put(setStageGateValidationStatus({ stageGateValidationStatus }))
})

export const createInitiativeComment = createSaga(function* createInitiativeComment({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getInitiativeListV2Api()
  const newComment = yield call(
    api.request('createInitiativeComment', {
      query: payload,
      body: payload.data,
    }),
  )

  const data = {
    ...payload.data,
    id: newComment.id,
    highestRole: newComment.highestRole,
    highestRoleTeamType: newComment.highestRoleTeamType,
    createdAt: newComment.createdAt,
    userRoles: newComment.userRoles,
  }

  yield put(createInitiativeCommentSuccess({ data }))
})

export const updateStageGate = createSaga(function* updateStageGate({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getInitiativeListV2Api()
  const { stageGateId, selectedInitiative, teamId, isManual } = payload

  const stageGateData = yield call(
    api.request('updateStageGate', {
      query: {
        initiativeId: selectedInitiative.id,
        synergyType: selectedInitiative.synergyType,
        isManual,
        teamId,
      },
      body: {
        stageGateId: stageGateId === 0 ? 1 : stageGateId,
      },
    }),
  )

  yield put(updateStageGateSuccess({ selectedInitiative, stageGateId, teamId, actualStageGateData: stageGateData }))

  yield fork(fetchInitiativeCommentsList, {
    payload: {
      initiativeId: selectedInitiative.id,
      teamId,
    },
  })

  yield fork(fetchStageGateValidationStatus, {
    payload: {
      initiativeId: selectedInitiative.id,
      teamId,
    },
  })
})

import * as Yup from 'yup'

export const welcomeNoteSchema = Yup.object().shape({
  welcomeNote: Yup.string()
    .trim()
    .required('Welcome note is required')
    .nullable()
    .max(1000, 'Welcome note must be less than 1000 characters'),
  legalDisclaimer: Yup.string()
    .trim()
    .required('Client legal disclaimer is required')
    .nullable()
    .max(10000, 'Client legal disclaimer must be less than 10000 characters'),
  terms: Yup.string().trim().nullable(),
})

import { call, put } from 'redux-saga/effects'
import { getCommunicationsApi } from '@common/net'
import { fetchColumnsData } from '@communications/actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'
import { omit } from 'lodash'

export const updateColumnsData = createSaga(function* updateColumnsData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getCommunicationsApi()
  yield call(
    api.request('updateColumnsData', {
      body: payload.map(({ isNew, ...column }: $TSFixMe) => (isNew ? omit(column, ['id']) : column)),
    }),
  )

  yield put(fetchColumnsData())
},
true)

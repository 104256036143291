import { call, put } from 'redux-saga/effects'
import { getGenericApi } from '@common/net'
import { setSnapshotMinDate } from '@generic/actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchSnapshotMinDate = createSaga(function* fetchSnapshotMinDate({
  payload: query,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getGenericApi()
  const response = yield call(api.request('getSnapshotMinDate', { query }))
  yield put(setSnapshotMinDate(response?.minDate))
})

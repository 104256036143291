export const SET_BULK_UPLOAD_ACTUALS_FILE = 'SET_BULK_UPLOAD_ACTUALS_FILE'
export const CANCEL_BULK_UPLOAD_ACTUALS_FILE = 'CANCEL_BULK_UPLOAD_ACTUALS_FILE'
export const SEND_BULK_UPLOAD_ACTUALS_FILE_PENDING = 'SEND_BULK_UPLOAD_ACTUALS_FILE_PENDING'
export const SEND_BULK_UPLOAD_ACTUALS_FILE_SUCCESS = 'SEND_BULK_UPLOAD_ACTUALS_FILE_SUCCESS'
export const SEND_BULK_UPLOAD_ACTUALS_FILE_FAIL = 'SEND_BULK_UPLOAD_ACTUALS_FILE_FAIL'

export const UPDATE_BULK_UPLOAD_SENDING_STATUS = 'UPDATE_BULK_UPLOAD_SENDING_STATUS'
export const FLUSH_BULK_UPLOAD_ACTUALS = 'FLUSH_BULK_UPLOAD_ACTUALS'

export const SEND_BULK_UPLOAD_FILE_PENDING = 'SEND_BULK_UPLOAD_FILE_PENDING'

export const DOWNLOAD_BULK_UPLOAD_TEMPLATE_PENDING = 'DOWNLOAD_BULK_UPLOAD_TEMPLATE_PENDING'
export const DOWNLOAD_BULK_UPLOAD_TEMPLATE_SUCCESS = 'DOWNLOAD_BULK_UPLOAD_TEMPLATE_SUCCESS'
export const DOWNLOAD_BULK_UPLOAD_TEMPLATE_FAIL = 'DOWNLOAD_BULK_UPLOAD_TEMPLATE_FAIL'
export const UPDATE_DOWNLOAD_BULK_UPLOAD_TEMPLATE_STATUS = 'UPDATE_DOWNLOAD_BULK_UPLOAD_TEMPLATE_STATUS'

export const FETCH_AMOUNT_OF_INITIATIVES_PENDING = 'FETCH_AMOUNT_OF_INITIATIVES_PENDING'
export const FETCH_AMOUNT_OF_INITIATIVES_SUCCESS = 'FETCH_AMOUNT_OF_INITIATIVES_SUCCESS'
export const FETCH_AMOUNT_OF_INITIATIVES_FAIL = 'FETCH_AMOUNT_OF_INITIATIVES_FAIL'

export const GET_BULK_UPLOAD_DOWNLOAD_TEMPLATE = 'GET_BULK_UPLOAD_DOWNLOAD_TEMPLATE'

import { call } from 'redux-saga/effects'
import { getDayOneApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const deleteProjectFile = createSaga(function* deleteProjectFile(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, id: projectId, keyProcessId, fileId, onSuccess } = action.payload

  const api = yield getDayOneApi()
  yield call(api.request('deleteProjectFile', { query: { teamId, projectId, keyProcessId, fileId } }))

  yield onSuccess && call(onSuccess)
},
true)

import React from 'react'
import momentBusinessDays from 'moment-business-days'
import { renderToString } from 'react-dom/server'
import { deliverableStatus, getDeliverableDisplayStatus } from '@helpers/statuses'
import {
  filter,
  get,
  hasIn,
  isEmpty,
  isNull,
  isNumber,
  map,
  mapValues,
  matches,
  omit,
  sortBy,
  isEqual,
  size,
  toLower,
} from 'lodash'
import { startsWith, curry, flow, find, get as getFp } from 'lodash/fp'
import { initialConfig } from '@generic/reducers/reducer'
import {
  ENTER_KEY_CODE,
  imoPostfix,
  loadingState,
  synergyTypes,
  DAY_ONE_ID,
  DETAILED_TASK_PLANS,
  POST_CLOSE_PROJECT_AND_TASK_PLAN_UPDATE,
  TASK_PROJECT_DELIVERABLES_IDS,
  ORG_DESIGN_DELIVERABLE_TYPE_ID,
} from './constants'
import { Intent } from '@blueprintjs/core'
import { teams, VC_DELIVERABLE_TYPE_ID, userRoles } from '@common/accessController/constants'
import { startOfMonth } from '@shared/Grid/utils/dates'
import moment from 'moment'
import { StyledLabel } from '@shared/BatchUploadPopup/index.styles'

export const transformArrayToExactSize = (arr, size, mapper) => {
  let transformedArr = [...arr]
  transformedArr.length = size

  return map(transformedArr, mapper)
}

export const mapDeliverableTypes = (deliverableTypes) => {
  return deliverableTypes.map(({ deliverables, ...typeData }) => ({
    ...typeData,
    deliverables: deliverables.map((deliverable) =>
      omit(deliverable, ['deliverableTypeName', 'templateFileLink', 'instructionFileLink', 'origin']),
    ),
  }))
}

export const getErrorConfig = (message) => ({
  type: Intent.DANGER,
  message,
})

export const generateImoUrl = (codename) => {
  return `http://${codename}.${imoPostfix}`
}

export const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const generateNextId = (collection, field = 'id') =>
  collection.reduce((prev, item) => (prev > item[field] ? prev : item[field]), 0) + 1

export const combineStepData = (stepData, staticData) => {
  return stepData.map((step, idx) => Object.assign({}, step, staticData[idx]))
}

export const maxLen = (string, length) => {
  if (!string) return true

  return string && string.length <= length
}

export const minLen = (string, length) => {
  if (!string) return false

  const value = string.toString()

  return value && value?.trim() && value?.trim().length >= length
}

export const getIntentValue = (condition) => (condition ? Intent.DANGER : Intent.NONE)

export const isGlobalGeography = (geography) =>
  matches({ level0Name: 'Global', level1Name: '', level2Name: '', level3Name: '', level4Name: '' })(geography)

export const blurInputOnEnterKeyPress = (e) => {
  if (e.keyCode === ENTER_KEY_CODE) {
    e.target.blur()
  }
}

export const flattenTree = (tree, childrenPath = 'children', mapper = (i) => i, parent) => {
  const newItems = []

  tree.forEach((branch) => {
    const children = branch[childrenPath]

    newItems.push(mapper(branch, parent))

    if (get(children, 'length')) {
      newItems.push(...flattenTree(branch[childrenPath], childrenPath, mapper, branch))
    }
  })

  return newItems
}

export const getDateWithNullTime = (item, propertyPath) => {
  const date = new Date(get(item, propertyPath))

  return date ? date.setHours(0, 0, 0, 0) : date
}

export const sortByDateRange = (items, startPath = 'startDate', endPath = 'dueDate') => {
  return sortBy(items, [(a) => getDateWithNullTime(a, startPath), (a) => getDateWithNullTime(a, endPath), 'id'])
}

export const transformEmptyStringsToNull = (obj) => mapValues(obj, (value) => (value === '' ? null : value))

export const transformValueToBoolean = (obj, fields) =>
  mapValues(obj, (value, field) => {
    const isFieldToTransform = (fields && fields.includes(field)) || !fields

    return isFieldToTransform ? !!value : value
  })

export const runDownloadByUrl = ({ url, name }) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name)
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
}

export const getCompanyLogos = (config) => {
  const companyA = config.find((configItem) => configItem.key === 'companyALogo')
  const companyB = config.find((configItem) => configItem.key === 'companyBLogo')

  return {
    companyALogo: companyA && companyA.value,
    companyBLogo: companyB && companyB.value,
  }
}

export const getCompanyLogoURL = (decodePathURL) => {
  const logoURL = new URL(decodePathURL).pathname

  return decodeURI(logoURL.substring(logoURL.indexOf('logo')))
}

export const isValidUrl = (url) => startsWith('http', url)

export const getCompanyLogosPath = (companyALogo, companyBLogo) => {
  const data = {}

  if (companyALogo)
    data.companyALogoPath = isValidUrl(companyALogo) ? getCompanyLogoURL(decodeURI(companyALogo)) : companyALogo

  if (companyBLogo)
    data.companyBLogoPath = isValidUrl(companyBLogo) ? getCompanyLogoURL(decodeURI(companyBLogo)) : companyBLogo

  return data
}

export const getCompanyLogoPath = (companyLogo) => {
  if (!startsWith('http', companyLogo)) {
    return { companyLogoPath: companyLogo }
  }

  const decodeCompanyPathURL = decodeURI(companyLogo)

  const companyLogoPath = getCompanyLogoURL(decodeCompanyPathURL)

  return {
    companyLogoPath,
  }
}

export const useQuery = (search) => new URLSearchParams(search)

export const transformTopicsResponse = (resp) => {
  const teamTopics = resp.filter((topic) => topic.requestedBy === 'team')
  const imoTopics = resp.filter((topic) => topic.requestedBy === 'imo')

  return {
    teamTopics,
    imoTopics,
  }
}

export const dayOneDeliverablesAvailable = (teamType, dayOneDeliverables) => {
  let requiredDeliverables

  if (teamType === 'Integration') {
    requiredDeliverables = [DAY_ONE_ID, DETAILED_TASK_PLANS, POST_CLOSE_PROJECT_AND_TASK_PLAN_UPDATE]
  } else if (teamType === 'Central') {
    requiredDeliverables = [DAY_ONE_ID, DETAILED_TASK_PLANS]
  } else {
    return false
  }

  const available = dayOneDeliverables.some(
    (d) => requiredDeliverables.includes(d.id) && d.active && d.displayStatus !== deliverableStatus.NOT_STARTED,
  )

  return available
}

export const getDayOneDeliverable = (deliverablesList, selectedTeamId) =>
  filter(
    deliverablesList.reduce((acc, { deliverables }) => {
      acc.push(...deliverables)

      return acc
    }, []),
    (deliverable) =>
      TASK_PROJECT_DELIVERABLES_IDS.includes(deliverable.id) && deliverable.teamId === Number(selectedTeamId),
  )

export const isDayOneDeliverableDisabled = (deliverablesList, selectedTeamId) => {
  const dayOneDeliverable = getDayOneDeliverable(deliverablesList, selectedTeamId)

  if (!isEmpty(dayOneDeliverable)) {
    return dayOneDeliverable.every((deliverable) => {
      return !deliverable.active || getDeliverableDisplayStatus(deliverable) === deliverableStatus.NOT_STARTED
    })
  }

  return true
}

export const getGuidanceConfigurations = (selectedTeam, libraries, isVC) => {
  const { libraryId } = selectedTeam
  const library = libraries.find((lib) => lib.id === libraryId)

  if (!library) {
    return
  }

  const libraryName = library.name.replace(/\s/gu, '_')

  const directory = isVC ? 'libraries/guidance/vc' : 'libraries/guidance/md'
  const key = isVC ? `myIMO_${libraryName}_VC_pack.pdf` : `myIMO_${libraryName}_Planning_Assumptions.pdf`

  return {
    key,
    directory,
  }
}

export const isElementInViewport = (el) => {
  if (!el) return true

  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  )
}

export const cloneCollection = (collection) => collection.map((col) => ({ ...col }))

export const clickOnTabKeyPress = (e) => {
  if (e.keyCode === 9) {
    e.target.click()
  }
}

export const getIsMac = () => {
  const { platform } = navigator
  const isMacLike = /(Mac|iPhone|iPod|iPad)/iu.test(platform)
  const isIOS = /(iPhone|iPod|iPad)/iu.test(platform)
  const isMac = platform.toUpperCase().includes('MAC')

  return isMacLike || isIOS || isMac
}

export const toStringSafe = (value) => (hasIn(value, 'toString') ? value.toString() : value)

export const isValidFileSize = (file, maxSize = 25) => !!file && file.size / (1024 * 1024) <= maxSize

export const isGlobalImoTeamType = (teamType) => teamType === teams.IMO_GLOBAL

export const isGlobalImoTeam = (data) => isGlobalImoTeamType(get(data, 'teamType'))

export const geIsVerticalScrollbarVisible = (element) => element.scrollHeight > element.clientHeight

export const decimalCount = (x) => (x.toString().includes('.') ? x.toString().split('.').pop().length : 0)

export const uppercaseAllWords = (str) => str.replace(/(^\w{1})|(\s+\w{1})/gu, (l) => l.toUpperCase())

export const formatInputStringToNumber = (inputValue) => {
  const number = !['', '-'].includes(inputValue) ? parseFloat(inputValue.replace(',', '.')) : null

  return isNull(number) || isNumber(number) ? number : inputValue
}

export const formatTextStringFromList = (arr, options = {}) => {
  const { getter } = options
  const list = getter ? arr.map(getter) : arr
  const listSize = size(list)

  if (listSize > 2) return `${list.slice(0, -1).join(', ')} and ${list.slice(-1)}`

  return listSize ? list.join(' and ') : list.toString()
}

export const formatBoldTextFromList = (list) => {
  const listSize = size(list)

  if (listSize > 1)
    return (
      <>
        <b>{list.slice(0, -1).join(', ')}</b> and <b>{list.slice(-1)}</b>
      </>
    )

  return <b>{list.toString()}</b>
}

export const isDateBefore = (date, { year, month }) => {
  return new Date(`${month} ${year}`) < new Date(startOfMonth(date))
}

export const isDataLoadingState = (dataState) => [loadingState.LOADING, loadingState.INITIAL].includes(dataState)

export const getValueHierarchy = (value) =>
  value.split('.').map((id, level, otherValues) => otherValues.slice(0, level + 1).join('.'))

export const isValueCaptureDeliverableType = (data) => get(data, 'deliverableTypeId') === VC_DELIVERABLE_TYPE_ID

// eslint-disable-next-line no-control-regex
export const removeTabSymbolFromString = (str) => str.replace(/\u000b/gu, '')

export const getColoredLabelValue =
  (getLabel, getColor) =>
  ({ node }) => ({
    label: getLabel(node.data),
    color: getColor(node.data),
    toString() {
      return getLabel(node.data)
    },
  })

export const transformToOptions = (items) => {
  if (!items) return []

  return items.map((dictionary) => ({
    label: dictionary.name,
    value: dictionary.id,
  }))
}

export const checkIsConfigUnset = (config) => isEqual(config, initialConfig)

export const getFormattedOwners = (owners = []) => owners.map((owner) => owner.displayName).join(', ')

export const generatePageElementId = curry((primaryPage, secondaryPage) => `${primaryPage}-${secondaryPage}`)

export const formattedDateToLLFormat = (date) => moment.utc(date).format('ll')

export const isL4PlannedDateNotMatchDueDate = (valueL4, valueDueDate) =>
  formattedDateToLLFormat(valueL4) !== formattedDateToLLFormat(valueDueDate)

export const hasAdvancedFinancialsOption = (type) => [synergyTypes.COST, synergyTypes.REVENUE].includes(type)

export const removeExtraSpaces = (string) => string?.replace(/\s+/gu, ' ').trim() || ''

export const getStringOrder = (v) => {
  if (isNull(v)) return 'a'

  return v ? 'b' : 'c'
}

export const capitalizeValue = (value) => value?.charAt(0).toUpperCase() + value?.slice(1)

export const matchWordFromString = (string, word, makeBold) => {
  let result = word

  const regExp = new RegExp(`${word}`, 'ui')
  const matchedWord = string?.match(regExp)
  const firstLetter = get(matchedWord, [[0], [0]])
  const isUpperCase = firstLetter?.toUpperCase() === firstLetter

  if (isUpperCase) {
    result = capitalizeValue(word)
  }

  if (isUpperCase && makeBold) {
    result = renderToString(<StyledLabel>{capitalizeValue(word)}</StyledLabel>)
  }

  return result
}

export const getCustomSynergyNames = ({
  costTitle = null,
  revenueTitle = null,
  nwcTitle = null,
  oneTimeCostTitle = null,
}) => ({
  [synergyTypes.COST]: costTitle || synergyTypes.COST,
  [synergyTypes.REVENUE]: revenueTitle || synergyTypes.REVENUE,
  [synergyTypes.NWC]: nwcTitle || synergyTypes.NWC,
  [synergyTypes.ONE_TIME_COST]: oneTimeCostTitle || synergyTypes.ONE_TIME_COST,
})

export const clearHistoryState = () => window.history.replaceState({}, document.title)

export const getMasterPlanTeamDeliverableName = ({ teamId, deliverableId, teamDeliverables }) =>
  flow(
    find(({ id }) => `${id}` === `${teamId}`),
    getFp(['deliverableTypes', 'Master Planning', 'teamDeliverables']),
    find({ deliverableId }),
    getFp('longName'),
  )(teamDeliverables?.integrationTeams)

export const getSecondArg = (_, arg) => arg

export const columnDefsDecorator = (originColDefs, additionalColDefs) =>
  originColDefs.reduce((acc, item) => {
    if (item.insertAfter) {
      if (Array.isArray(additionalColDefs)) acc.push(item, ...additionalColDefs)
      else acc.push(item, additionalColDefs)

      return acc
    }

    acc.push(item)

    return acc
  }, [])

// temporary location
export const valueCaptureSettings = {
  STAGE_GATE_CALC_TYPE: 'stageGatesCalculationType',
  PUBLISH_DAY: 'publishDay',
  INITIATIVE_COLUMNS_CUSTOM_NAME: 'initiativeColumnsCustomNames',
  MIRRORING_TEAMS: 'mirroringTeams',
  TARGET_BASE: 'targetBase',
  PROJECT_TASK_STATUS_DATE: 'projectTaskStatusDate',
  INITIATIVE_STATUS: 'initiativeStatus',
}

export const calculateBusinessDaysDifference = (dueDate) => {
  const formattedDueDate = momentBusinessDays(dueDate).utc().format('ll')
  const today = momentBusinessDays().utc().startOf('day')
  const dueDay = momentBusinessDays(dueDate).utc().startOf('day')
  const daysDiff = dueDay.businessDiff(today)

  return {
    formattedDueDate,
    daysDiff,
  }
}

export const noRowToShowHandler =
  (isLoading = false) =>
  ({ api }) => {
    if (isLoading) return api.hideOverlay()

    if (api.getDisplayedRowCount() === 0) {
      api.showNoRowsOverlay()
    } else {
      api.hideOverlay()
    }
  }

export const containTeamLeadOrTeamMember = (roles = []) => {
  const teamLeadOrTeamMemberRoles = [
    userRoles.LEAD,
    userRoles.MEMBER_WITH_VC,
    userRoles.MEMBER,
    userRoles.MEMBER_WITHOUT_VC,
    userRoles.MEMBER_WITHOUT_VC_READ_ONLY,
    userRoles.MEMBER_READ_ONLY,
  ]

  for (let index = 0; index < roles.length; roles++) {
    const role = toLower(roles[index])

    if (teamLeadOrTeamMemberRoles.includes(role)) return true
  }

  return false
}

export const includesOrgDesignLayers = (deliverables) => {
  return deliverables
    .filter(({ deliverableTypeId }) => deliverableTypeId === ORG_DESIGN_DELIVERABLE_TYPE_ID)
    .some(({ orgDesignLayer }) => orgDesignLayer !== null)
}

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class DataRequestTrackerApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch, '')

    this.host = Config.API_SERVER
  }

  getDataRequests = () => {
    return this.call({ pathname: `/team-resources/data-request-tracker` })
  }

  createDataRequest = ({ body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/team-resources/data-request-tracker`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateDataRequest = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/team-resources/data-request-tracker/${query.requestId}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteDataRequest = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/team-resources/data-request-tracker/${query.requestId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }
}

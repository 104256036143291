const prefix = '@TSAv2/'

export const FETCH_TSA = `${prefix}FETCH_TSA`
export const FETCH_TEAM_RESOURCES_TSA = `${prefix}FETCH_TEAM_RESOURCES_TSA`
export const SET_TSA = `${prefix}SET_TSA`
export const RESET_TSA = `${prefix}RESET_TSA`

export const CREATE_TSA = `${prefix}CREATE_TSA`
export const CREATE_TSA_SUCCESS = `${prefix}CREATE_TSA_SUCCESS`

export const UPDATE_TSA = `${prefix}UPDATE_TSA`
export const UPDATE_TSA_SUCCESS = `${prefix}UPDATE_TSA_SUCCESS`

export const DELETE_TSA = `${prefix}DELETE_TSA`
export const DELETE_TSA_SUCCESS = `${prefix}DELETE_TSA_SUCCESS`

export const FETCH_TSA_PROCESSES_FROM_LIBRARY = `${prefix}FETCH_TSA_PROCESSES_FROM_LIBRARY`
export const SET_TSA_PROCESSES_FROM_LIBRARY = `${prefix}SET_TSA_PROCESSES_FROM_LIBRARY`
export const UPDATE_TSA_PROCESSES_FROM_LIBRARY = `${prefix}UPDATE_TSA_PROCESSES_FROM_LIBRARY`
export const RESET_TSA_PROCESSES_FROM_LIBRARY = `${prefix}RESET_TSA_PROCESSES_FROM_LIBRARY`
export const ADD_TSA_PROCESSES_FROM_LIBRARY = `${prefix}ADD_TSA_PROCESSES_FROM_LIBRARY`

export const GET_SNAPSHOT_MIN_DATE = `${prefix}GET_SNAPSHOT_MIN_DATE`
export const SET_SNAPSHOT_MIN_DATE = `${prefix}SET_SNAPSHOT_MIN_DATE`
export const SET_SELECTED_TSA = `${prefix}SET_SELECTED_TSA`

export const GET_MASTER_LIST_TSA_STATUS = `${prefix}GET_MASTER_LIST_TSA_STATUS`
export const SET_MASTER_LIST_TSA_STATUS = `${prefix}SET_MASTER_LIST_TSA_STATUS`
export const RESET_MASTER_LIST_TSA_STATUS = `${prefix}RESET_MASTER_LIST_TSA_STATUS`

export const FETCH_ACTIVITY_LOG_PENDING = `${prefix}FETCH_ACTIVITY_LOG_PENDING`
export const FETCH_ACTIVITY_LOG_SUCCESS = `${prefix}FETCH_ACTIVITY_LOG_SUCCESS`

export const FETCH_MORE_ACTIVITY_LOG_PENDING = `${prefix}FETCH_MORE_ACTIVITY_LOG_PENDING`

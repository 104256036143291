import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import {
  INITIAL_VIDEO_KNOWLEDGE_CENTER_FETCH,
  INITIAL_ARTICLE_KNOWLEDGE_CENTER_FETCH,
  INITIAL_INTRODUCTION_MATERIAL_KNOWLEDGE_CENTER_FETCH,
  SAVE_LIBRARIES_FOR_VIDEO_IMPORT,
  SAVE_LIBRARIES_FOR_ARTICLE_IMPORT,
  SAVE_LIBRARIES_FOR_INTRODUCTION_MATERIAL_IMPORT,
  IMPORT_VIDEOS,
  IMPORT_ARTICLES,
  IMPORT_INTRODUCTION_MATERIALS,
  FETCH_INTRODUCTION_MATERIALS_ASSETS_AND_DOWNLOAD,
} from '../actions/actionTypes'
import { getLibraryAssetsFetchDate, getSelectedAssetIds } from '@generic/selectors/selectedTeams'
import { getLocationParams } from '@generic/selectors'
import * as actions from '../actions/actions'
import { routesList } from '@common/accessController/routesList'
import { getKnowledgeCenterApi } from '@common/net'
import { find, flow, get } from 'lodash/fp'
import { URL_EXPIRE_MINUTES } from '@helpers/constants'
import { generatePath, NavigateFunction } from 'react-router-dom'

export const fetchLibraryVideos = createSaga(function* fetchLibraryVideos({
  payload: { teamId },
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  const libraryAssets = yield call(api.request('getLibraryVideos', { query: { teamId } }))

  yield put(actions.setLibraryAssets(libraryAssets))
})

export const fetchLibrariesForVideoImport = createSaga(function* fetchLibrariesForVideoImport({
  payload: { teamId },
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  const librariesForImport = yield call(api.request('getLibrariesForVideoImport', { query: { teamId } }))

  yield put(actions.setLibrariesForImport(librariesForImport))
})

export const initialVideoKnowledgeCenterFetch = createSaga(function* initialVideoKnowledgeCenterFetch({
  payload: { teamId },
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  yield put(actions.setLibraryAssets([]))
  yield all([
    call(fetchLibraryVideos, { payload: { teamId } }),
    call(fetchLibrariesForVideoImport, { payload: { teamId } }),
  ])
})

export const saveLibrariesForVideoImport = createSaga(function* saveLibrariesForVideoImport({
  payload: { teamId, libraries },
}: {
  payload: { teamId: string; libraries: $TSFixMe[] }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  yield call(api.request('saveLibrariesForVideoImport', { query: { teamId, libraries } }))
  yield put(actions.setLibrariesForImport(libraries))
  const libraryVideos = yield call(api.request('getLibraryVideos', { query: { teamId } }))

  yield put(actions.setLibraryAssets(libraryVideos))
})

export const importVideos = createSaga(function* importVideos({
  payload: { navigate },
}: {
  payload: { navigate: NavigateFunction }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()
  const assetIds = yield select(getSelectedAssetIds)
  const { teamId } = yield select(getLocationParams)

  yield call(api.request('importVideos', { query: { teamId, assetIds } }))

  navigate(generatePath(routesList.DASHBOARD.MY_TEAM.HEAR_FROM_EXPERT_MODAL.route, { teamId }), {
    state: {
      knowledgeCenterBackground: {
        pathname: generatePath(routesList.DASHBOARD.MY_TEAM.KNOWLEDGE_CENTER.route, { teamId }),
      },
    },
  })
})

export const fetchLibraryArticles = createSaga(function* fetchLibraryArticles({
  payload: { teamId },
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  const libraryArticles = yield call(api.request('getLibraryArticles', { query: { teamId } }))

  yield put(actions.setLibraryAssets(libraryArticles))
})

export const fetchLibrariesForArticleImport = createSaga(function* fetchLibrariesForArticleImport({
  payload: { teamId },
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  const librariesForImport = yield call(api.request('getLibrariesForArticleImport', { query: { teamId } }))

  yield put(actions.setLibrariesForImport(librariesForImport))
})

export const initialArticleKnowledgeCenterFetch = createSaga(function* initialArticleKnowledgeCenterFetch({
  payload: { teamId },
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  yield put(actions.setLibraryAssets([]))
  yield all([
    call(fetchLibraryArticles, { payload: { teamId } }),
    call(fetchLibrariesForArticleImport, { payload: { teamId } }),
  ])
})

export const saveLibrariesForArticleImport = createSaga(function* saveLibrariesForArticleImport({
  payload: { teamId, libraries },
}: {
  payload: { teamId: string; libraries: $TSFixMe }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  yield call(api.request('saveLibrariesForArticleImport', { query: { teamId, libraries } }))
  yield put(actions.setLibrariesForImport(libraries))
  const libraryArticles = yield call(api.request('getLibraryArticles', { query: { teamId } }))

  yield put(actions.setLibraryAssets(libraryArticles))
})

export const importArticles = createSaga(function* importArticles({
  payload: { navigate },
}: {
  payload: { navigate: NavigateFunction }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()
  const assetIds = yield select(getSelectedAssetIds)
  const { teamId } = yield select(getLocationParams)

  yield call(api.request('importArticles', { query: { teamId, assetIds } }))

  navigate(generatePath(routesList.DASHBOARD.MY_TEAM.READ_MORE_MODAL.route, { teamId }), {
    state: {
      knowledgeCenterBackground: {
        pathname: generatePath(routesList.DASHBOARD.MY_TEAM.KNOWLEDGE_CENTER.route, { teamId }),
      },
    },
  })
})

export const fetchLibraryIntroductionMaterials = createSaga(function* fetchLibraryIntroductionMaterials({
  payload: { teamId },
}: {
  payload: { teamId: string }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()

  const libraryAssets = yield call(api.request('getLibraryIntroductionMaterials', { query: { teamId } }))

  yield put(
    actions.setLibraryAssetsWithTime({
      libraryAssetsFetchDate: new Date(),
      libraryAssets,
    }),
  )
})

export const fetchIntroductionMaterialsAssetsAndDownload = createSaga(
  function* fetchIntroductionMaterialsAssetsAndDownload({
    payload: { item, teamId },
  }: {
    payload: { teamId: string; item: $TSFixMe }
  }): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const libraryAssetsFetchDate = yield select(getLibraryAssetsFetchDate)
    const fetchTime = libraryAssetsFetchDate.getTime()
    const currentTime = new Date().getTime()
    const minutesPast = (currentTime - fetchTime) / (1000 * 60)
    const link = document.createElement('a')

    link.href = item.url
    link.download = item.fileName

    if (minutesPast > URL_EXPIRE_MINUTES) {
      const api = yield getKnowledgeCenterApi()

      const libraryAssets = yield call(api.request('getLibraryIntroductionMaterials', { query: { teamId } }))

      yield put(
        actions.setLibraryAssetsWithTime({
          libraryAssetsFetchDate: new Date(),
          libraryAssets,
        }),
      )

      const assets = libraryAssets.reduce((acc: $TSFixMe[], { introductionMaterials = [] }) => {
        introductionMaterials.forEach((asset) => acc.push(asset))

        return acc
      }, [])

      const updatedUrl = flow(find({ id: item.id }), get('url'))(assets)

      link.href = updatedUrl
    }

    link.click()
  },
)

export const fetchLibrariesForIntroductionMaterialsImport = createSaga(
  function* fetchLibrariesForIntroductionMaterialsImport({
    payload: { teamId },
  }: {
    payload: { teamId: string }
  }): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const api = yield getKnowledgeCenterApi()

    const librariesForImport = yield call(
      api.request('getLibrariesForIntroductionMaterialsImport', { query: { teamId } }),
    )

    yield put(actions.setLibrariesForImport(librariesForImport))
  },
)

export const initialIntroductionMaterialKnowledgeCenterFetch = createSaga(
  function* initialIntroductionMaterialKnowledgeCenterFetch({ payload: { teamId } }: { payload: { teamId: string } }) {
    yield put(actions.setLibraryAssets([]))
    yield all([
      call(fetchLibraryIntroductionMaterials, { payload: { teamId } }),
      call(fetchLibrariesForIntroductionMaterialsImport, { payload: { teamId } }),
    ])
  },
)

export const saveLibrariesForIntroductionMaterialImport = createSaga(
  function* saveLibrariesForIntroductionMaterialImport({
    payload: { teamId, libraries },
  }: {
    payload: { teamId: string; libraries: $TSFixMe[] }
  }): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const api = yield getKnowledgeCenterApi()

    yield call(api.request('saveLibrariesForIntroductionMaterialImport', { query: { teamId, libraries } }))
    yield put(actions.setLibrariesForImport(libraries))
    const libraryIntroductionMaterials = yield call(
      api.request('getLibraryIntroductionMaterials', { query: { teamId } }),
    )

    yield put(actions.setLibraryAssets(libraryIntroductionMaterials))
  },
)

export const importIntroductionMaterials = createSaga(function* importIntroductionMaterials({
  payload: { navigate },
}: {
  payload: { navigate: NavigateFunction }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getKnowledgeCenterApi()
  const assetIds = yield select(getSelectedAssetIds)
  const { teamId } = yield select(getLocationParams)

  yield call(api.request('importIntroductionMaterials', { query: { teamId, assetIds } }))

  navigate(generatePath(routesList.DASHBOARD.MY_TEAM.INTRODUCTION_MATERIALS_MODAL.route, { teamId }), {
    state: {
      knowledgeCenterBackground: {
        pathname: generatePath(routesList.DASHBOARD.MY_TEAM.KNOWLEDGE_CENTER.route, { teamId }),
      },
    },
  })
})

export default function* importVideosFromLibrarySaga() {
  yield all([
    takeLatest(INITIAL_VIDEO_KNOWLEDGE_CENTER_FETCH, initialVideoKnowledgeCenterFetch),
    takeLatest(SAVE_LIBRARIES_FOR_VIDEO_IMPORT, saveLibrariesForVideoImport),
    takeLatest(IMPORT_VIDEOS, importVideos),
    takeLatest(INITIAL_ARTICLE_KNOWLEDGE_CENTER_FETCH, initialArticleKnowledgeCenterFetch),
    takeLatest(SAVE_LIBRARIES_FOR_ARTICLE_IMPORT, saveLibrariesForArticleImport),
    takeLatest(IMPORT_ARTICLES, importArticles),
    takeLatest(INITIAL_INTRODUCTION_MATERIAL_KNOWLEDGE_CENTER_FETCH, initialIntroductionMaterialKnowledgeCenterFetch),
    takeLatest(SAVE_LIBRARIES_FOR_INTRODUCTION_MATERIAL_IMPORT, saveLibrariesForIntroductionMaterialImport),
    takeLatest(IMPORT_INTRODUCTION_MATERIALS, importIntroductionMaterials),
    takeLatest(FETCH_INTRODUCTION_MATERIALS_ASSETS_AND_DOWNLOAD, fetchIntroductionMaterialsAssetsAndDownload),
  ])
}

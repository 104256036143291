import { createSaga } from '@common/sagaCreator/createSaga'
import { getTeamResourcesApi } from '@common/net'
import { call, put, select } from 'redux-saga/effects'
import {
  setTeamContacts,
  updateTeamContactSuccess,
  deleteTeamContactSuccess,
} from '@teamResources/teamContacts/actions/actions'
import { getTeamContacts } from '@teamResources/teamContacts/selectors'
import { omit } from 'lodash'

export const fetchTeamContacts = createSaga(function* fetchTeamContacts(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamResourcesApi()

  const data = yield call(api.request('getTeamContacts'))

  yield put(setTeamContacts(data))
}, true)

export const createTeamContact = createSaga(function* createTeamContact({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data } = payload
  const api = yield getTeamResourcesApi()
  const teamContacts = yield select(getTeamContacts)

  const { id } = yield call(
    api.request('createTeamContact', {
      body: omit({ ...data }, ['id', 'isNew']),
    }),
  )

  const createdTeamContact = { ...data, id, isNew: false }
  const updatedTeamContacts = teamContacts.map((contact: $TSFixMe) =>
    contact.id === data.id ? createdTeamContact : contact,
  )

  yield put(updateTeamContactSuccess({ data: updatedTeamContacts }))
},
true)

export const updateTeamContact = createSaga(function* updateTeamContact({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data, id } = payload
  const teamIDs = data.teams.map((team: $TSFixMe) => team.id)
  const api = yield getTeamResourcesApi()
  const teamContacts = yield select(getTeamContacts)

  yield call(
    api.request('updateTeamContact', {
      query: {
        id,
      },
      body: omit({ ...data, teams: teamIDs }, ['isNew', 'isSpecified']),
    }),
  )

  const updatedTeamContacts = teamContacts.map((contact: $TSFixMe) =>
    contact.id === id ? { ...contact, ...data } : contact,
  )

  yield put(updateTeamContactSuccess({ data: updatedTeamContacts }))
})

export const deleteTeamContact = createSaga(function* deleteTeamContact({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { id } = payload

  const api = yield getTeamResourcesApi()

  yield call(
    api.request('deleteTeamContact', {
      query: {
        id,
      },
    }),
  )

  yield put(deleteTeamContactSuccess({ id: id }))
},
true)

import styled from 'styled-components'
import { AnchorButton } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

export const ControllerContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  .bp4-control {
    margin-bottom: 0;
    margin-left: 3px;
  }
`

export const StyledPopover = styled(Popover2)`
  display: flex;
  margin: 0 15px;

  & > a {
    margin: 0;
  }
`

export const StyledIconButton = styled(AnchorButton)`
  min-width: 26px;
  min-height: 26px;
  padding: 0;

  &.bp4-button {
    background-color: transparent;
    box-shadow: none;
    background-image: none;
  }
`

export const StyledList = styled.div`
  padding: 0 11px;
`

export const LabelSpanFlex = styled.span`
  display: flex;
  gap: 5px;
`

export const ListItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 8px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(16, 22, 26, 0.2);

  .bp4-control {
    margin: 0 0 0 auto;
  }

  .item-text {
    font-size: 12px;
    color: #394b59;
    letter-spacing: normal;
  }

  &:last-child {
    border-bottom: none;
  }

  .bp4-button {
    padding: 0 8px;
    margin-left: 4px;
    min-height: 20px;
  }
`

import { pick } from 'lodash'
import { all, call, put } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { updateEvent, fetchCommunicationsData } from '@communications/actions/actions'
import { getCommunicationsApi } from '@common/net'
import { getEventRequestQuery } from '../helpers'
import { transformEmptyStringsToNull } from '@helpers/utils'
import { uploadEventAttachment } from '@communications/sagas/uploadEventAttachment'
import { deleteEventAttachment } from '@communications/sagas/deleteEventAttachment'
import { DELETE_ATTACHMENT, UPLOAD_ATTACHMENT } from '@communications/actions/actionTypes'

const fieldsToUpdate = (data: $TSFixMe) =>
  pick(data, [
    'columnId',
    'comments',
    'confidential',
    'description',
    'medium',
    'name',
    'rowId',
    'status',
    'teamMembers',
    'otherMembers',
  ])

export function* handleFilesChanges(eventId: string, { uploaded, deleted }: $TSFixMe) {
  yield all([
    all(uploaded.map(({ file }: $TSFixMe) => call(uploadEventAttachment, { eventId, file, type: UPLOAD_ATTACHMENT }))),
    all(
      deleted.map(({ id }: $TSFixMe) => call(deleteEventAttachment, { eventId, fileId: id, type: DELETE_ATTACHMENT })),
    ),
  ])
}

export const saveEvent = createSaga(function* saveEvent({
  payload: { attachments, ...payload },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { id } = payload
  const isNew = !id

  const updatedPayload = transformEmptyStringsToNull(payload)

  const api = yield getCommunicationsApi()

  const apiResponse = yield call(
    api.request(isNew ? 'addEvent' : 'updateEvent', {
      query: getEventRequestQuery(isNew, id),
      body: fieldsToUpdate(updatedPayload),
    }),
  )

  if (!isNew) {
    yield handleFilesChanges(id, attachments)
  }

  let eventToSet = { ...payload }
  if (isNew) {
    const { id } = apiResponse
    eventToSet = { ...payload, id }
    yield handleFilesChanges(id, attachments)
  }

  // Update row in store to show data in the table
  yield put(updateEvent(eventToSet))
  yield put(fetchCommunicationsData())
},
true)

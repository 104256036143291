import { createSaga } from '@common/sagaCreator/createSaga'
import { getImoControlTowerApi } from '@common/net'
import { call, put } from 'redux-saga/effects'
import { setSteerCoActionsData, updateSteerCoActionsDataSuccess } from '@steerCoRoadmap/actions/actions'

export const fetchSteerCoActionsData = createSaga(function* fetchSteerCoActionsData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = action.payload

  const api = yield getImoControlTowerApi()
  const data = yield call(
    api.request('getSteerCoActionsData', {
      query: { teamId },
    }),
  )

  yield put(setSteerCoActionsData(data))
},
true)

export const updateSteerCoActionsData = createSaga(function* updateSteerCoActionsData(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, rowId, columnId, value } = action.payload

  const api = yield getImoControlTowerApi()
  yield call(
    api.request('updateSteerCoActionsData', {
      query: { teamId, rowId, columnId },
      body: { value },
    }),
  )

  yield put(updateSteerCoActionsDataSuccess({ value, rowId, columnId }))
},
false)

import React, { memo } from 'react'
import { get, isNil } from 'lodash'
import { Button, Colors, Intent, Overlay, Icon, MaybeElement } from '@blueprintjs/core'
import {
  ActionBtnContainer,
  IconWrapper,
  LightLabel,
  OverlayContainer,
  OverlayContent,
  OverlayContentWrapper,
  OverlayHeader,
} from './index.styles'

interface Props {
  isOpen?: boolean
  headerText?: string
  onCancel?: (...args: $TSFixMe[]) => $TSFixMe
  onConfirm?: (...args: $TSFixMe[]) => $TSFixMe
  containerStyles?: $TSFixMe
  contentStyles?: $TSFixMe
  headerStyles?: $TSFixMe
  children?: React.ReactNode[] | React.ReactNode
  fill?: string
  intent?: Intent
  icon?: MaybeElement | string
  width?: string
  confirmButtonWidth?: string
  confirmButtonLabel?: string
  showCancelButton?: boolean
  cancelButtonLabel?: string
  showConfirmButton?: boolean
  disableConfirmButton?: boolean
  iconStyles?: $TSFixMe
  className?: string
}

// TODO: looks the same as AlertModal from toolkit
const AlertPopup = ({
  isOpen,
  onCancel,
  onConfirm,
  headerText,
  containerStyles,
  contentStyles,
  iconStyles,
  confirmButtonLabel,
  confirmButtonWidth,
  cancelButtonLabel,
  showCancelButton,
  showConfirmButton,
  fill,
  intent,
  icon,
  width,
  children,
  disableConfirmButton,
  headerStyles,
  className,
}: Props) => {
  if (!isOpen) return null

  const renderPopupContent = () => <LightLabel className="content-wrapper">{children}</LightLabel>

  const renderIcon = () =>
    icon ? (
      <Icon
        icon={icon as $TSFixMe}
        size={get(iconStyles, 'iconSize') || 30}
        color={!isNil(get(iconStyles, 'iconColor')) ? get(iconStyles, 'iconColor') : fill}
      />
    ) : (
      <Icon icon="warning-sign" size={get(iconStyles, 'iconSize') || 32} intent={intent} />
    )

  return (
    // @ts-expect-error onCancel isn't on the overlay type?
    <Overlay className={className} isOpen={isOpen} onCancel={onCancel} canOutsideClickCancel={true}>
      <OverlayContainer
        className="overlay-container"
        containerStyles={containerStyles}
        data-cy="alert-popup"
        width={width}
      >
        <OverlayHeader className="overlay-header" styles={headerStyles}>
          {headerText}
        </OverlayHeader>

        <OverlayContentWrapper className="overlay-content-wrapper">
          <IconWrapper>{renderIcon()}</IconWrapper>

          <OverlayContent styles={contentStyles}>{renderPopupContent()}</OverlayContent>
        </OverlayContentWrapper>

        <ActionBtnContainer
          className="overlay-btn-container"
          justify="flex-end"
          confirmButtonWidth={confirmButtonWidth}
        >
          {showCancelButton ? (
            <Button onClick={onCancel} text={cancelButtonLabel ?? 'Cancel'} className="action-btn" />
          ) : null}
          {showConfirmButton ? (
            <Button
              disabled={disableConfirmButton}
              intent={intent}
              onClick={onConfirm}
              text={confirmButtonLabel}
              className="action-btn"
            />
          ) : null}
        </ActionBtnContainer>
      </OverlayContainer>
    </Overlay>
  )
}

AlertPopup.defaultProps = {
  isOpen: false,
  containerStyles: null,
  headerStyles: null,
  headerText: '',
  children: null,
  onCancel: () => {},
  onConfirm: () => {},
  confirmButtonLabel: '',
  confirmButtonWidth: null,
  showCancelButton: true,
  showConfirmButton: true,
  disableConfirmButton: false,
  fill: Colors.RED2,
  intent: Intent.DANGER,
  icon: null,
  width: null,
  contentStyles: null,
  iconStyles: null,
  className: '',
}

export default memo(AlertPopup)

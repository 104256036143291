import { assoc, cloneDeep, get, getOr, identity, isArray, isObject, size } from 'lodash/fp'
import { set } from 'lodash'
import React from 'react'
import { StyledFilterHeader } from '@shared/Grid/HierarchyFilter/styles'
import { FilterItem } from '@shared/Grid/HierarchyFilter/FilterItem'
import { ALL_FILTER_LABEL } from '@helpers/constants'

export const getRowFieldsValues = (data, model) =>
  model.reduce((result, { field, values }) => {
    if (!result) return result

    const fieldValue = get([field], data)

    if (isArray(values) && !values?.includes(fieldValue)) return null

    result.push(fieldValue)

    return result
  }, [])

export const getValuesDeep = (obj) =>
  Object.values(obj).reduce((res, value) => {
    if (!isObject(value)) {
      res.push(value)

      return res
    }

    const values = getValuesDeep(value)

    return [...res, ...values]
  }, [])

export const renderFilterValues = ({ obj, onChange, model, path = [] }) => {
  let list = Object.keys(obj)
  const { comparator, renderer: CustomRenderer } = getOr({}, `${size(path)}`, model)

  //mutates value
  if (comparator) list.sort(comparator)

  return list.map((key) => {
    const value = obj[key]
    const itemPath = [...path, key]
    const itemKey = JSON.stringify(itemPath)

    const NameToRender = CustomRenderer ? <CustomRenderer value={key} /> : key

    if (isObject(value)) {
      const resultValues = getValuesDeep(value)
      const isAllChecked = !resultValues.some((v) => !v)
      const isIndeterminate = !isAllChecked && resultValues.some(identity)

      return (
        <React.Fragment key={`section_${itemKey}`}>
          <StyledFilterHeader>{NameToRender}</StyledFilterHeader>
          <FilterItem
            onChange={onChange}
            name={ALL_FILTER_LABEL}
            itemKey={itemKey}
            value={isAllChecked}
            key={ALL_FILTER_LABEL}
            isIndeterminate={isIndeterminate}
          />
          {renderFilterValues({ obj: value, onChange, model, path: itemPath })}
        </React.Fragment>
      )
    }

    return (
      <FilterItem onChange={onChange} name={NameToRender} itemKey={itemKey} value={value} key={`item_${itemKey}`} />
    )
  })
}

export const setDeepValues = (obj, value) => {
  const newObject = cloneDeep(obj)

  Object.keys(obj).forEach((key) => {
    if (!isObject(obj[key])) return (newObject[key] = value)

    return (newObject[key] = setDeepValues(newObject[key], value))
  })

  return newObject
}

export const getValuesModel = ({ api, doesRowPassOtherFilter, model, selected = {}, defaultValue = true }) => {
  const resultValues = {}

  api.forEachNode((row) => {
    const isRowPassedOtherFilter = !doesRowPassOtherFilter || doesRowPassOtherFilter(row)

    const rowPath = isRowPassedOtherFilter && getRowFieldsValues(row?.data, model)

    if (!rowPath) return resultValues

    if (!get(rowPath, resultValues)) set(resultValues, rowPath, getOr(defaultValue, rowPath, selected))

    return resultValues
  }, {})

  return resultValues
}

export const setModelValue = (model, path, value) => {
  const currentValue = get(path, model)

  if (!isObject(currentValue)) return assoc(path, value, model)

  const newData = setDeepValues(currentValue, value)

  return assoc(path, newData, model)
}

export const isActiveFilter = (selected) => getValuesDeep(selected).some((v) => !v)

import { userRoles, accessRights } from '../../constants'
import { routesList } from '../../routesList'

export const USER_ADMIN_PERMISSIONS = {
  name: userRoles.USER_ADMIN,
  role: userRoles.USER_ADMIN,
  priority: 8,
  exceptions: [routesList.USER_MANAGEMENT.route],
  redirects: {
    [routesList.USER_MANAGEMENT.route]: routesList.USER_MANAGEMENT.route,
  },
  permissions: [
    {
      route: routesList.USER_MANAGEMENT,
      accessLevel: accessRights.READ_WRITE,
    },
  ],
}

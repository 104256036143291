import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class KnowledgeCenterApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  getKnowledgeCenterVideos = ({ query }: $TSFixMe) =>
    this.call({
      pathname: `/teams/${query.teamId}/knowledge-center/video`,
    })

  uploadVideo = ({ body, query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/knowledge-center/video/upload`,
        routePrefix: '',
        query: { key: query.key },
      },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  createKnowledgeCenterVideos = ({ query: { teamId }, body }: $TSFixMe) =>
    this.call(
      {
        pathname: `/teams/${teamId}/knowledge-center/video`,
      },
      {
        body,
        method: 'POST',
      },
    )

  updateKnowledgeCenterVideos = ({ query: { teamId, videoId }, body }: $TSFixMe) =>
    this.call(
      {
        pathname: `/teams/${teamId}/knowledge-center/video/${videoId}`,
      },
      { body, method: 'PATCH' },
    )

  deleteKnowledgeCenterVideos = ({ query: { teamId, videoId } }: $TSFixMe) =>
    this.call({ pathname: `/teams/${teamId}/knowledge-center/video/${videoId}` }, { method: 'DELETE' })

  getLibraryVideos = ({ query: { teamId } }: $TSFixMe) =>
    this.call({ pathname: `teams/${teamId}/knowledge-center/library-videos` })

  getLibrariesForVideoImport = ({ query: { teamId } }: $TSFixMe) =>
    this.call({
      pathname: `/teams/${teamId}/knowledge-center/video/select`,
    })

  saveLibrariesForVideoImport = ({ query: { teamId, libraries } }: $TSFixMe) =>
    this.call(
      { pathname: `/teams/${teamId}/knowledge-center/video/select` },
      {
        method: 'PUT',
        body: libraries,
      },
    )

  importVideos = ({ query: { teamId, assetIds } }: $TSFixMe) =>
    this.call(
      { pathname: `/teams/${teamId}/knowledge-center/library-videos/bulk` },
      {
        method: 'PUT',
        body: assetIds,
      },
    )

  getKnowledgeCenterArticles = ({ query }: $TSFixMe) =>
    this.call({
      pathname: `/teams/${query.teamId}/knowledge-center/article`,
    })

  createKnowledgeCenterArticles = ({ query: { teamId }, body }: $TSFixMe) =>
    this.call(
      {
        pathname: `/teams/${teamId}/knowledge-center/article`,
      },
      {
        body,
        method: 'POST',
      },
    )

  updateKnowledgeCenterArticles = ({ query: { teamId, articleId }, body }: $TSFixMe) =>
    this.call(
      {
        pathname: `/teams/${teamId}/knowledge-center/article/${articleId}`,
      },
      { body, method: 'PATCH' },
    )

  deleteKnowledgeCenterArticle = ({ query: { teamId, articleId } }: $TSFixMe) =>
    this.call({ pathname: `/teams/${teamId}/knowledge-center/article/${articleId}` }, { method: 'DELETE' })

  getKnowledgeCenterIntroductionMaterials = ({ query }: $TSFixMe) =>
    this.call({
      pathname: `/teams/${query.teamId}/knowledge-center/introduction-material`,
    })

  getLibraryArticles = ({ query: { teamId } }: $TSFixMe) =>
    this.call({ pathname: `teams/${teamId}/knowledge-center/library-articles` })

  getLibrariesForArticleImport = ({ query: { teamId } }: $TSFixMe) =>
    this.call({
      pathname: `/teams/${teamId}/knowledge-center/article/select`,
    })

  saveLibrariesForArticleImport = ({ query: { teamId, libraries } }: $TSFixMe) =>
    this.call(
      { pathname: `/teams/${teamId}/knowledge-center/article/select` },
      {
        method: 'PUT',
        body: libraries,
      },
    )

  importArticles = ({ query: { teamId, assetIds } }: $TSFixMe) =>
    this.call(
      { pathname: `/teams/${teamId}/knowledge-center/library-articles/bulk` },
      {
        method: 'PUT',
        body: assetIds,
      },
    )

  createKnowledgeCenterIntroductionMaterial = ({ query: { teamId }, body }: $TSFixMe) =>
    this.call(
      {
        pathname: `/teams/${teamId}/knowledge-center/introduction-material`,
      },
      {
        body,
        method: 'POST',
      },
    )

  updateKnowledgeCenterIntroductionMaterial = ({ query: { teamId, materialId }, body }: $TSFixMe) =>
    this.call(
      {
        pathname: `/teams/${teamId}/knowledge-center/introduction-material/${materialId}`,
      },
      { body, method: 'PATCH' },
    )

  deleteIntroductionMaterial = ({ query: { teamId, materialId } }: $TSFixMe) =>
    this.call(
      { pathname: `/teams/${teamId}/knowledge-center/introduction-material/${materialId}` },
      { method: 'DELETE' },
    )

  getLibraryIntroductionMaterials = ({ query: { teamId } }: $TSFixMe) =>
    this.call({ pathname: `teams/${teamId}/knowledge-center/library-introduction-materials` })

  getLibrariesForIntroductionMaterialsImport = ({ query: { teamId } }: $TSFixMe) =>
    this.call({
      pathname: `/teams/${teamId}/knowledge-center/introductionMaterial/select`,
    })

  saveLibrariesForIntroductionMaterialImport = ({ query: { teamId, libraries } }: $TSFixMe) =>
    this.call(
      { pathname: `/teams/${teamId}/knowledge-center/introductionMaterial/select` },
      {
        method: 'PUT',
        body: libraries,
      },
    )

  saveVideosOrder = ({ query: { videos, teamId } }: $TSFixMe) =>
    this.call(
      { pathname: `/teams/${teamId}/knowledge-center/video/bulk` },
      {
        method: 'PUT',
        body: videos,
      },
    )

  saveArticlesOrder = ({ query: { articles, teamId } }: $TSFixMe) =>
    this.call(
      { pathname: `/teams/${teamId}/knowledge-center/article/bulk` },
      {
        method: 'PUT',
        body: articles,
      },
    )

  saveIntroductionMaterialsOrder = ({ query: { materials, teamId } }: $TSFixMe) =>
    this.call(
      { pathname: `/teams/${teamId}/knowledge-center/introduction-material/bulk` },
      {
        method: 'PUT',
        body: materials,
      },
    )

  importIntroductionMaterials = ({ query: { teamId, assetIds } }: $TSFixMe) =>
    this.call(
      { pathname: `/teams/${teamId}/knowledge-center/library-introduction-materials/bulk` },
      {
        method: 'PUT',
        body: assetIds,
      },
    )
}

import { put, call } from 'redux-saga/effects'
import { setTeamsList } from '@generic/actions/actions'
import { getGenericApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchTeamsList = createSaga(function* fetchTeamsList(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getGenericApi()
  const teams = yield call(api.request('getTeamsList'))

  yield put(setTeamsList({ teams }))
})

import { EntityType, EntityName, NotificationEntityTypes } from '@helpers/constants'

export const entityNameMap: Record<EntityType, EntityName> = {
  ValueCaptureInitiativeV2: 'Initiative',
  TeamKeyProcessL2Project: 'Project',
  TeamKeyProcessL2ProjectTask: 'Task',
  TSADeliverableV2: 'TSADeliverableV2',
  InterdependencyTask: 'Incoming Interdependency',
  OutgoingInterdependencyTask: 'Outgoing Interdependency',
  TeamKeyProcessProjectImport: 'Project Library Import',
  TeamKeyProcessProjectTaskImport: 'Task Library Import',
  TeamKeyProcessProjectUpload: 'Project Upload',
  TeamKeyProcessProjectTaskUpload: 'Task Upload',
}

export const entityValueMap: Record<EntityName, EntityType> = {
  Initiative: NotificationEntityTypes.ValueCaptureInitiativeV2,
  Project: NotificationEntityTypes.TeamKeyProcessL2Project,
  Task: NotificationEntityTypes.TeamKeyProcessL2ProjectTask,
  'Incoming Interdependency': NotificationEntityTypes.InterdependencyTask,
  'Outgoing Interdependency': NotificationEntityTypes.OutgoingInterdependencyTask,
  TSADeliverableV2: NotificationEntityTypes.TSADeliverableV2,
  'Project Library Import': NotificationEntityTypes.TeamKeyProcessProjectImport,
  'Task Library Import': NotificationEntityTypes.TeamKeyProcessProjectTaskImport,
  'Project Upload': NotificationEntityTypes.TeamKeyProcessProjectUpload,
  'Task Upload': NotificationEntityTypes.TeamKeyProcessProjectTaskUpload,
}

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@teamStatusTracker/actions/actionTypes'

import { fetchTeamStatusTrackerData } from './fetchTeamStatusTrackerData'
import { updateImoTopics } from './updateImoTopics'

export default function* teamStatusTrackerSaga() {
  yield all([
    takeLatest(constants.FETCH_TEAM_STATUS_TRACKER_DATA, fetchTeamStatusTrackerData),
    takeLatest(constants.UPDATE_IMO_TEAM_TOPICS, updateImoTopics),
  ])
}

import { all, call, put, select } from 'redux-saga/effects'
import { fetchLibs } from './fetchLibs'
import { fetchTeamsList } from './fetchTeamsList'
import { fetchUsers } from './fetchUsers'
import { fetchDictionaries } from './fetchDictionaries'
import { setLoader, fetchInitialDataSuccess, setInitialDataLoaded } from '@generic/actions/actions'
import { FETCH_LIBRARIES, FETCH_TEAMS_LIST, FETCH_USERS_LIST, FETCH_DICTIONARIES } from '../actions/actionTypes'
import { getIsLoadedInitialData } from '@generic/selectors'

export function* fetchInitialData(args = { withLoader: true }): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const isLoadedInitialData = yield select(getIsLoadedInitialData)
  const isFirstTimeLoading = !isLoadedInitialData

  try {
    if (isFirstTimeLoading && args?.withLoader) {
      yield put(setLoader({ loader: true }))
    }

    yield all([
      call(fetchUsers, { type: FETCH_USERS_LIST }),
      call(fetchLibs, { type: FETCH_LIBRARIES }),
      call(fetchTeamsList, { type: FETCH_TEAMS_LIST }),
      call(fetchDictionaries, { type: FETCH_DICTIONARIES }),
    ])

    yield put(fetchInitialDataSuccess())
  } finally {
    if (isFirstTimeLoading) {
      if (args?.withLoader) {
        yield put(setLoader({ loader: false }))
      }
      yield put(setInitialDataLoaded(true))
    }
  }
}

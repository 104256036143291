import { put, call } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import {
  updateFuncDesignProcessSuccess,
  setFuncDesignProcesses,
  deleteFuncDesignProcessSuccess,
} from '@functionalProcessDesign/actions/actions'
import { setIsDeliverableLocked } from '@teamDeliverables/actions/actions'
import { getFunctionalProcessDesignApi } from '@common/net'
import { omit } from 'lodash'
import { createSaga } from '@common/sagaCreator/createSaga'
import { transformEmptyStringsToNull } from '@helpers/utils'

export const fetchFuncDesignProcesses = createSaga(function* fetchFuncDesignProcesses({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload

  const api = yield getFunctionalProcessDesignApi()
  const data = yield call(
    api.request('getFuncDesignProcesses', {
      query: {
        teamId,
      },
    }),
  )

  yield put(setFuncDesignProcesses(data))

  yield call(delay, 100)

  yield put(setIsDeliverableLocked({ isDeliverableLocked: data.locked }))
},
true)

export const createFuncDesignProcess = createSaga(function* createFuncDesignProcess({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload

  const api = yield getFunctionalProcessDesignApi()
  const { id } = yield call(
    api.request('createFuncDesignProcess', {
      query: {
        teamId,
      },
      body: omit(data, ['id', 'isNew']),
    }),
  )

  yield put(updateFuncDesignProcessSuccess({ id: data.id, data: { ...data, id, isNew: false } }))
},
true)

export const updateFuncDesignProcess = createSaga(function* updateFuncDesignProcess({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, keyProcessId, data } = payload
  const transformedData = transformEmptyStringsToNull(data)

  const api = yield getFunctionalProcessDesignApi()
  yield call(
    api.request('updateFuncDesignProcess', {
      query: {
        teamId,
        processId: keyProcessId,
      },
      body: omit(transformedData, ['id', 'isNew']),
    }),
  )

  yield put(updateFuncDesignProcessSuccess({ id: keyProcessId, data }))
})

export const deleteFuncDesignProcess = createSaga(function* deleteFuncDesignProcess({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, keyProcessId } = payload

  const api = yield getFunctionalProcessDesignApi()
  yield call(
    api.request('deleteFuncDesignProcess', {
      query: {
        teamId,
        processId: keyProcessId,
      },
    }),
  )

  yield put(deleteFuncDesignProcessSuccess({ id: keyProcessId }))
},
true)

import { put, call } from 'redux-saga/effects'
import { isArray, map, sortBy } from 'lodash/fp'
import { getTeamResourcesApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { setStatusReportData } from '@teamResources/statusReport/actions/actions'

export const fetchStatusReportData = createSaga(function* fetchStatusReportData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { type, priorityIds, companyA, companyB } = payload

  const query = { companyA, companyB, type } as $TSFixMe
  if (isArray(priorityIds) && priorityIds.length) {
    query.priority = map(JSON.stringify, priorityIds).toString()
  }

  const api = yield getTeamResourcesApi()
  const data = yield call(api.request('getStatusReport', { query }))

  const sortedData = sortBy((d1) => new Date(d1.date).getTime(), data).reverse()

  yield put(setStatusReportData(sortedData))
},
true)

import { handleActions, Action } from 'redux-actions'
import { get } from 'lodash/fp'
import * as constants from '../actions/actionTypes'
import { AirtableTeam, IModuleOptionsReducer } from './types.d'
import { updateDependantModules, updateDefaultDictionaries, updateDictionaries } from './utils'
import { Dictionary } from '@myImoConfig/components/ModuleOptionsV2/CustomDataColumns/DictionaryModal'

export const initialState: IModuleOptionsReducer = {
  MODULES: [],
  MODULES_SELECT: [],
  DICTIONARIES: {
    projectPlan: [],
    valueCapture: [],
    tsa: [],
    riskLog: [],
    decisionLog: [],
  },
  INTEGRATIONS: {
    airtable: {
      customColumnId: null,
      teams: [],
    },
  },
  loading: false,
}

const moduleOptionsReducer = handleActions<IModuleOptionsReducer, $TSFixMe>(
  {
    [constants.FETCH_MODULE_OPTIONS_PENDING]: (state): IModuleOptionsReducer => {
      return { ...state, loading: true }
    },
    [constants.FETCH_MODULE_OPTIONS_SUCCESS]: (state, { payload }): IModuleOptionsReducer => {
      return {
        ...state,
        ...payload,
        loading: false,
      }
    },
    [constants.FETCH_MODULE_OPTIONS_FAIL]: (state, _action: Action<unknown>): IModuleOptionsReducer => {
      return { ...state, loading: false }
    },
    [constants.SAVE_MODULE_OPTIONS_PENDING]: (state, _action: Action<unknown>): IModuleOptionsReducer => {
      return { ...state, loading: true }
    },
    [constants.SAVE_MODULE_OPTIONS_SUCCESS]: (state, _action: Action<unknown>): IModuleOptionsReducer => {
      return { ...state, loading: false }
    },
    [constants.SAVE_MODULE_OPTIONS_FAIL]: (state, _action: Action<unknown>): IModuleOptionsReducer => {
      return { ...state, loading: false }
    },
    [constants.UNSET_AIRTABLE_DICTIONARY]: (state): IModuleOptionsReducer => {
      return {
        ...state,
        ...{
          INTEGRATIONS: {
            ...state.INTEGRATIONS,
            airtable: {
              ...state.INTEGRATIONS.airtable,
              customColumnId: null,
            },
          },
        },
      }
    },
    [constants.DEACTIVATE_AIRTABLE_DICTIONARY]: (
      state,
      { payload }: { payload: { dictionary: Dictionary } },
    ): IModuleOptionsReducer => {
      const { dictionary } = payload
      const isAirtableCustomColumn = dictionary.id === state.INTEGRATIONS.airtable.customColumnId

      return {
        ...state,
        // Disable Airtable integration and reset shared column ID if we delete the custom column
        ...(isAirtableCustomColumn
          ? {
              MODULES: state.MODULES.map((module) =>
                module.key === 'airtableIntegration' ? { ...module, active: false } : module,
              ),
              INTEGRATIONS: {
                ...state.INTEGRATIONS,
                airtable: {
                  ...state.INTEGRATIONS.airtable,
                  customColumnId: null,
                },
              },
            }
          : {}),
      }
    },
    [constants.DELETE_DICTIONARY]: (state, { payload }: $TSFixMe): IModuleOptionsReducer => {
      const { dictionary, dictionaryType } = payload
      const dictionaryList: $TSFixMe = get(['DICTIONARIES', dictionaryType], state)
      const isAirtableCustomColumn = dictionary.id === state.INTEGRATIONS.airtable.customColumnId

      return {
        ...state,
        DICTIONARIES: {
          ...state.DICTIONARIES,
          [dictionaryType]: dictionaryList.filter((item: $TSFixMe) => item.id !== dictionary.id),
        },
        // Disable Airtable integration and reset shared column ID if we delete the custom column
        ...(isAirtableCustomColumn
          ? {
              MODULES: state.MODULES.map((module) =>
                module.key === 'airtableIntegration' ? { ...module, active: false } : module,
              ),
              INTEGRATIONS: {
                ...state.INTEGRATIONS,
                airtable: {
                  ...state.INTEGRATIONS.airtable,
                  customColumnId: null,
                },
              },
            }
          : {}),
      }
    },
    [constants.UPDATE_DICTIONARIES]: (state, { payload }: $TSFixMe): IModuleOptionsReducer => {
      const { dictionary, dictionaryType } = payload
      const dictionaryList: $TSFixMe = get(['DICTIONARIES', dictionaryType], state)

      const updatedList = dictionary.isCustom
        ? updateDictionaries(dictionary, dictionaryList)
        : updateDefaultDictionaries(dictionary, dictionaryList)

      return {
        ...state,
        DICTIONARIES: {
          ...state.DICTIONARIES,
          [dictionaryType]: updatedList,
        },
      }
    },
    [constants.UPDATE_MODULES]: (state, { payload }: Action<$TSFixMe>): IModuleOptionsReducer => {
      return {
        ...state,
        MODULES: updateDependantModules(payload, state.MODULES),
      }
    },
    [constants.UPDATE_MODULES_SELECT]: (state, { payload }: $TSFixMe): IModuleOptionsReducer => {
      const result = state.MODULES_SELECT.map((item) => (item.key === payload.key ? payload : item))

      return {
        ...state,
        MODULES_SELECT: result,
      }
    },
    [constants.ADD_AIRTABLE_INTEGRATION_TEAM]: (
      state,
      { payload: team }: { payload: AirtableTeam },
    ): IModuleOptionsReducer => ({
      ...state,
      INTEGRATIONS: {
        ...state.INTEGRATIONS,
        airtable: {
          ...state.INTEGRATIONS.airtable,
          teams: [...state.INTEGRATIONS.airtable.teams, team],
        },
      },
    }),
    [constants.UPDATE_AIRTABLE_INTEGRATION_TEAM]: (
      state,
      { payload: team }: { payload: AirtableTeam },
    ): IModuleOptionsReducer => ({
      ...state,
      INTEGRATIONS: {
        ...state.INTEGRATIONS,
        airtable: {
          ...state.INTEGRATIONS.airtable,
          teams: state.INTEGRATIONS.airtable.teams.map((t) => (t.teamId === team.teamId ? team : t)),
        },
      },
    }),
    [constants.DELETE_AIRTABLE_INTEGRATION_TEAM]: (
      state,
      { payload: teamId }: { payload: number },
    ): IModuleOptionsReducer => ({
      ...state,
      INTEGRATIONS: {
        ...state.INTEGRATIONS,
        airtable: {
          ...state.INTEGRATIONS.airtable,
          teams: state.INTEGRATIONS.airtable.teams.filter((t) => t.teamId !== teamId),
        },
      },
    }),
    [constants.SET_AIRTABLE_CUSTOM_COLUMN_ID]: (
      state,
      { payload: customColumnId }: { payload: number },
    ): IModuleOptionsReducer => ({
      ...state,
      INTEGRATIONS: {
        ...state.INTEGRATIONS,
        airtable: {
          ...state.INTEGRATIONS.airtable,
          customColumnId,
        },
      },
    }),
  },
  initialState,
)

export default moduleOptionsReducer

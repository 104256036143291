const prefix = '@functionalProcessDesign/'

export const FETCH_FUNC_DESIGN_PROCESSES = `${prefix}FETCH_FUNC_DESIGN_PROCESSES`
export const SET_FUNC_DESIGN_PROCESSES = `${prefix}SET_FUNC_DESIGN_PROCESSES`

export const CREATE_FUNC_DESIGN_PROCESS = `${prefix}CREATE_FUNC_DESIGN_PROCESS`
export const CREATE_FUNC_DESIGN_PROCESS_SUCCESS = `${prefix}CREATE_FUNC_DESIGN_PROCESS_SUCCESS`

export const UPDATE_FUNC_DESIGN_PROCESS = `${prefix}UPDATE_FUNC_DESIGN_PROCESS`
export const UPDATE_FUNC_DESIGN_PROCESS_SUCCESS = `${prefix}UPDATE_FUNC_DESIGN_PROCESS_SUCCESS`

export const DELETE_FUNC_DESIGN_PROCESS = `${prefix}DELETE_FUNC_DESIGN_PROCESS`
export const DELETE_FUNC_DESIGN_PROCESS_SUCCESS = `${prefix}DELETE_FUNC_DESIGN_PROCESS_SUCCESS`

import { createSaga } from '@common/sagaCreator/createSaga'
import { call } from 'redux-saga/effects'
import { getSharedApi } from '@common/net'
import { delay } from 'redux-saga'
import { getOr } from 'lodash/fp'

export const updateCustomColumn = createSaga(function* updateCustomColumn(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { customColumn, data, onSuccess, onFailure, teamId } = action.payload
  try {
    const column = data.customColumns.find((column: $TSFixMe) => column.columnName === customColumn)
    const body = {
      entityId: data.id,
      type: data.type,
      optionValue: getOr(null, ['option', 'id'], column) ?? getOr(null, ['option', 'name'], column),
      teamId,
    }

    const api = yield getSharedApi()

    yield call(
      api.request('updateCustomColumn', {
        query: {
          columnId: column.id,
        },
        body,
      }),
    )

    if (onSuccess) yield call(onSuccess)
  } catch (e) {
    yield call(delay, 100)
    if (onFailure) yield call(onFailure)

    throw e
  }
})

import { put, takeLatest, select, all, call } from 'redux-saga/effects'
import * as taxonomyConstants from '@myImoConfigActions/projectTaxonomy/actionTypes'
import * as configConstants from '@myImoConfigActions/config/actionTypes'
import { getTaxonomyApi } from '@common/net'
import { validateTaxonomy } from '@helpers/validatorsConfig'
import * as selectors from '../../selectors'
import { createSaga } from '@common/sagaCreator/createSaga'
import { handleForbiddenError } from '../utils'
import { omit } from 'lodash/fp'
import { NavigateFunction } from 'react-router-dom'

export const createTaxonomy = createSaga(function* createTaxonomy({
  payload: { navigate },
}: {
  payload: { navigate: NavigateFunction }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const taxonomyApi = yield getTaxonomyApi()
  const data = yield select(selectors.getTaxonomy)
  const { taxonomyFunctions, taxonomyGeographies, taxonomyBusinessUnits } = data

  const omitIsNew = omit('isNew')

  const transformedData = {
    taxonomyFunctions: taxonomyFunctions.map(omitIsNew),
    taxonomyGeographies: taxonomyGeographies.map((g: $TSFixMe) => ({ ...omitIsNew(g), level0Name: 'Global' })),
    taxonomyBusinessUnits: taxonomyBusinessUnits.map(omitIsNew),
  }

  yield call(validateTaxonomy, transformedData)

  yield call(taxonomyApi.request('createTaxonomy', { body: transformedData }))

  yield put({ type: configConstants.SET_STEP_COMPLETED, payload: { no: 3 } })
  navigate('/config/team-setup')
},
true)

export const getTaxonomy = createSaga(
  function* getTaxonomy(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const taxonomyApi = yield getTaxonomyApi()
    const getTaxonomyData = yield call(taxonomyApi.request('getTaxonomy'))

    yield put({ type: taxonomyConstants.GET_TAXONOMY_SUCCESS, payload: getTaxonomyData })
  },
  true,
  handleForbiddenError,
)

export function* taxonomyWatcher() {
  yield all([
    takeLatest(taxonomyConstants.CREATE_TAXONOMY, createTaxonomy),
    takeLatest(taxonomyConstants.GET_TAXONOMY, getTaxonomy),
  ])
}

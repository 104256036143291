import { managementTypes, customTypes, separationDealTypes, integrationDealTypes } from '@helpers/constants'

export const isSeparationDealType = (dealType) =>
  dealType === separationDealTypes.DIVESTITURE || dealType === separationDealTypes.DEMERGER

export const isSMO = (managementType) => managementType === managementTypes.smo

export const checkIsBuySideDealType = (dealType) => dealType === integrationDealTypes.BUY_SIDE_CURVE_OUT

export const managementType = (dealType) => {
  return isSeparationDealType(dealType) ? managementTypes.smo : managementTypes.imo
}

export const nameManagementType = (managementType) => {
  if (isSMO(managementType)) {
    return managementTypes.separation
  }

  return managementTypes.integration
}

export const customType = (managementType) => {
  if (isSMO(managementType)) {
    return customTypes.entanglements
  }

  return customTypes.mutualDiscovery
}

import { call, put } from 'redux-saga/effects'
import { fetchRisksLog, setRisksLog, updateRisksLogSuccess } from '@teamWorkspace/actions/actions'
import { getTeamWorkspaceApi } from '@common/net'
import { omit } from 'lodash'
import { createSaga } from '@common/sagaCreator/createSaga'

export const getRisks = createSaga(function* getRisks({ payload }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamWorkspaceApi()
  const risksData = yield call(api.request('getRisks', { query: { teamId: payload } }))

  yield put(setRisksLog(risksData))
})

export const createRisks = createSaga(function* createRisks({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  const { id } = yield call(api.request('createRisk', { query: { teamId }, body: omit(data, 'isNew', 'id', 'team') }))

  yield put(updateRisksLogSuccess({ id: data.id, data: { ...data, id, isNew: false } }))
},
true)

export const updateRisks = createSaga(function* updateRisks({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data, body } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(
    api.request('updateRisk', {
      query: { teamId: data.team.id, riskId: data.id },
      body: body,
    }),
  )

  yield put(updateRisksLogSuccess({ id: data.id, data }))
})

export const deleteRisks = createSaga(function* deleteRisks({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(api.request('deleteRisk', { query: { teamId, riskId: data.id } }))

  yield put(fetchRisksLog(teamId))
})

import * as Yup from 'yup'

export const FunctionalProcessSchema = Yup.object().shape({
  keyProcess: Yup.string().when(['isNew'], {
    is: (isNew) => !!isNew,
    then: Yup.string().nullable().max(300),
    otherwise: Yup.string().nullable().required('This field cannot be empty').max(300),
  }),
})

export const FunctionalProcessDesignSchema = Yup.array().of(
  Yup.object().shape({
    keyProcess: Yup.string().nullable().required('This field cannot be empty'),
    currentState: Yup.string()
      .nullable()
      .max(1000, 'Current State must be less than 1000 characters')
      .required('This field cannot be empty'),
    companyAFutureState: Yup.string()
      .nullable()
      .max(1000, 'Company A future state must be less than 1000 characters')
      .required('This field cannot be empty'),
    companyBFutureState: Yup.string()
      .nullable()
      .max(1000, 'Company B future state must be less than 1000 characters')
      .required('This field cannot be empty'),
  }),
)

import styled from 'styled-components'
import { Grid } from '@imo/imo-ui-toolkit'
import { Colors } from '@blueprintjs/core'

export const StyledGrid = styled(Grid)`
  ${(props) => {
    if (!props.$enhancedFilterVisuals) {
      return ''
    }
    return `
        .ag-icon-filter {
          cursor: pointer;
        }

        .ag-icon-filter::before {
          content: '\f11d';
        }

        .ag-menu .ag-icon-filter::before {
          content: '\f114';
        }

        .ag-header-cell-filtered .ag-icon-filter::before {
          content: '\f114';
        }

        .ag-header-cell.ag-header-cell-filtered {
          border-top: 2px solid var(--gold-gold-3, ${Colors.GOLD3});
          background: linear-gradient(0deg, rgba(251, 208, 101, 0.2) 0%, rgba(251, 208, 101, 0.2) 100%),
            ${Colors.LIGHT_GRAY5};
        }
      `
  }}
`

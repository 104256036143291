import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@batchUpload/actions/actionTypes'

import {
  batchUploadFile,
  batchUploadInitiatives,
  batchFileUploadSuccess,
  downloadBatchUploadTemplate,
} from './batchUploadFile'

export default function* dayOneSaga() {
  yield all([
    takeLatest(constants.BATCH_UPLOAD_FILE, batchUploadFile),
    takeLatest(constants.BATCH_UPLOAD_VC_INITIATIVES_FILE, batchUploadInitiatives),
    takeLatest(constants.BATCH_UPLOAD_FILE_SUCCESS, batchFileUploadSuccess),
    takeLatest(constants.DOWNLOAD_BATCH_UPLOAD_TEMPLATE, downloadBatchUploadTemplate),
  ])
}

import { createAction } from 'redux-actions'
import {
  FETCH_TR_DECISION_LOG_DATA,
  UPDATE_TR_DECISION_LOG_DATA,
  FETCH_DECISION_LOG_DATA_PENDING,
  FETCH_DECISION_LOG_DATA_SUCCESS,
  FETCH_DECISION_LOG_DATA_FAIL,
  UPDATE_DECISION_LOG_DATA_PENDING,
  UPDATE_DECISION_LOG_DATA_SUCCESS,
  UPDATE_DECISION_LOG_DATA_FAIL,
  ADD_DECISION_LOG_DATA,
  DELETE_DECISION_LOG_DATA_PENDING,
  DELETE_DECISION_LOG_DATA_SUCCESS,
  DELETE_DECISION_LOG_DATA_FAIL,
  FETCH_CONTROL_TOWER_DECISION_LOG_DATA,
  UPDATE_CONTROL_TOWER_DECISION_LOG_DATA,
  FETCH_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS,
  UPDATE_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS,
  ADD_DECISION_LOG_DATA_SUCCESS,
} from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchTrDecisionLogData = createAction(FETCH_TR_DECISION_LOG_DATA, null, getMetaWithActionLoading)
export const updateTrDecisionLogData = createAction(UPDATE_TR_DECISION_LOG_DATA)
export const fetchDecisionLogDataPending = createAction(FETCH_DECISION_LOG_DATA_PENDING, null, getMetaWithActionLoading)
export const fetchDecisionLogDataSuccess = createAction(FETCH_DECISION_LOG_DATA_SUCCESS)
export const fetchDecisionLogDataFail = createAction(FETCH_DECISION_LOG_DATA_FAIL)

export const updateDecisionLogDataPending = createAction(UPDATE_DECISION_LOG_DATA_PENDING)
export const updateDecisionLogDataSuccess = createAction(UPDATE_DECISION_LOG_DATA_SUCCESS)
export const updateDecisionLogDataFail = createAction(UPDATE_DECISION_LOG_DATA_FAIL)

export const addDecisionLogData = createAction(ADD_DECISION_LOG_DATA)
export const addDecisionLogDataSuccess = createAction(ADD_DECISION_LOG_DATA_SUCCESS)

export const deleteDecisionLogDataPending = createAction(DELETE_DECISION_LOG_DATA_PENDING)
export const deleteDecisionLogDataSuccess = createAction(DELETE_DECISION_LOG_DATA_SUCCESS)
export const deleteDecisionLogDataFail = createAction(DELETE_DECISION_LOG_DATA_FAIL)

export const fetchControlTowerDecisionLogData = createAction(
  FETCH_CONTROL_TOWER_DECISION_LOG_DATA,
  null,
  getMetaWithActionLoading,
)
export const updateControlTowerDecisionLogData = createAction(UPDATE_CONTROL_TOWER_DECISION_LOG_DATA)

export const fetchControlTowerDecisionLogDataSuccess = createAction(FETCH_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS)

export const updateControlTowerDecisionLogDataSuccess = createAction(UPDATE_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS)

const prefix = '@dayOneReadiness/'

export const FETCH_SCENARIOS = `${prefix}FETCH_SCENARIOS`
export const SET_SCENARIOS = `${prefix}SET_SCENARIOS`

export const CREATE_SCENARIO = `${prefix}CREATE_SCENARIO`
export const CREATE_SCENARIO_SUCCESS = `${prefix}CREATE_SCENARIO_SUCCESS`

export const UPDATE_SCENARIO = `${prefix}UPDATE_SCENARIO`
export const UPDATE_SCENARIO_SUCCESS = `${prefix}UPDATE_SCENARIO_SUCCESS`

export const UPDATE_SCENARIO_FROM_LIBRARY = `${prefix}UPDATE_SCENARIOS_FROM_LIBRARY`

export const ADD_SCENARIOS_FROM_LIBRARY = `${prefix}ADD_SCENARIOS_FROM_LIBRARY`

export const DELETE_SCENARIO = `${prefix}DELETE_SCENARIO`
export const DELETE_SCENARIO_SUCCESS = `${prefix}DELETE_SCENARIO_SUCCESS`

export const FETCH_SCENARIOS_FROM_LIBRARY = `${prefix}FETCH_SCENARIOS_FROM_LIBRARY`
export const SET_SCENARIOS_FROM_LIBRARY = `${prefix}SET_SCENARIOS_FROM_LIBRARY`
export const RESET_SCENARIOS_FROM_LIBRARY = `${prefix}RESET_SCENARIOS_FROM_LIBRARY`

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchCentralFunctionalTSA = createAction(
  constants.FETCH_CENTRAL_FUNCTIONAL_TSA,
  null,
  getMetaWithActionLoading,
)
export const setCentralFunctionalTSA = createAction(constants.SET_CENTRAL_FUNCTIONAL_TSA)

export const createCentralFunctionalTSA = createAction(constants.CREATE_CENTRAL_FUNCTIONAL_TSA)
export const createCentralFunctionalTSASuccess = createAction(constants.CREATE_CENTRAL_FUNCTIONAL_TSA_SUCCESS)

export const updateCentralFunctionalTSA = createAction(constants.UPDATE_CENTRAL_FUNCTIONAL_TSA)
export const updateCentralFunctionalTSASuccess = createAction(constants.UPDATE_CENTRAL_FUNCTIONAL_TSA_SUCCESS)

export const deleteCentralFunctionalTSA = createAction(constants.DELETE_CENTRAL_FUNCTIONAL_TSA)
export const deleteCentralFunctionalTSASuccess = createAction(constants.DELETE_CENTRAL_FUNCTIONAL_TSA_SUCCESS)

export const fetchRegionalLocalTSA = createAction(constants.FETCH_REGIONAL_LOCAL_TSA)

export const fetchProjectList = createAction(constants.FETCH_PROJECT_LIST)
export const setProjectList = createAction(constants.SET_PROJECT_LIST)

import { createSelector } from 'reselect'
import {
  isEmpty,
  getOr,
  sortBy,
  flow,
  groupBy,
  mapValues,
  values,
  map,
  keys,
  get,
  compose,
  uniqBy,
  concat,
} from 'lodash/fp'
import { getFileFromURL } from '@shared/FilesGrid/utils'
import { transformDeliverable, transformDeliverablesTracker } from '../sagas/utils'
import { dayOneDeliverablesOrder } from '@myImoClient/components/Deliverables/TeamCharter/InScopeKey/utils'
import { getActiveDeliverablesByIds } from '@teamDeliverables/selectors/utils'
import { sortTeamRoles } from '@common/accessController/utils'
import { Deliverable, DeliverableGroup } from '@views/myImoClient/components/TeamHome/Deliverables'
import { sortDeliverables } from '@helpers/sortDeliverables'

export const clientState = (state: $TSFixMe) => state.client.teamDeliverables || {}
export const getTeamDeliverable = (state: $TSFixMe) => {
  const deliverable = clientState(state).deliverable

  return !isEmpty(deliverable) ? deliverable : null
}

export const getDeliverableFiles = (state: $TSFixMe) => {
  const deliverable = getTeamDeliverable(state)

  if (!deliverable) return []

  const { templateURL, instructionURL, templateFileName, instructionFileName } = deliverable
  const templateFile = getFileFromURL(templateURL)
  const instructionFile = getFileFromURL(instructionURL)
  const resultFiles = []
  const template = {
    fileName: templateFileName,
    fileType: getOr('', 'fileType', templateFile).toUpperCase(),
    category: 'Template',
    url: templateURL,
  }

  const instruction = {
    fileName: instructionFileName,
    fileType: getOr('', 'fileType', instructionFile).toUpperCase(),
    category: 'Instruction',
    url: instructionURL,
  }

  if (templateURL) {
    resultFiles.push(template)
  }

  if (instructionURL) {
    resultFiles.push(instruction)
  }

  return resultFiles.slice(0, 2)
}

export const getDeliverablesStatusFromState = (state: $TSFixMe) => get('status', clientState(state))

export const getDeliverablesFromState = (state: $TSFixMe) => get('deliverables', clientState(state))

export const getDeliverableComments = (state: $TSFixMe) => get('comments', clientState(state))

export const getDeliverablesStatus = createSelector(
  getDeliverablesStatusFromState,
  (deliverablesStatus) => deliverablesStatus,
)

export const getDeliverables = createSelector(
  getDeliverablesFromState,
  (deliverables: Deliverable[]): DeliverableGroup[] => {
    return flow(
      groupBy('deliverableType.name'),
      mapValues((list: Deliverable[]) => ({
        name: list[0]?.deliverableType?.name,
        order: list[0]?.deliverableType?.order,
        deliverables: sortDeliverables({
          deliverables: list,
          startDateAccessor: 'startDate',
          dueDateAccessor: 'dueDate',
          orgLayerAccessor: 'orgDesignTeamLayerDetails',
          orderAccessor: (child) => child.deliverable?.order ?? 0,
        }).map(transformDeliverable),
      })),
      values,
      sortBy((i: DeliverableGroup) => i.order),
    )(deliverables)
  },
)

export const getActiveProjectsListDeliverable = createSelector(getDeliverablesFromState, (deliverables) =>
  getActiveDeliverablesByIds(deliverables, dayOneDeliverablesOrder),
)

export const getSelectedDeliverable = (state: $TSFixMe) => clientState(state).deliverable
export const getSortedDeliverableComments = createSelector(getDeliverableComments, (deliverableComments) => {
  const { comments, commentsCount } = deliverableComments

  const sortedComments = [...comments].map((comment) => {
    comment.sender.teams = sortTeamRoles(comment.sender.teams)

    return comment
  })

  return {
    comments: sortedComments,
    commentsCount,
  }
})

export const getDeliverablesTracker = (state: $TSFixMe) => clientState(state).deliverablesTracker

export const getTransformedDeliverablesTrackerData = createSelector(
  getDeliverablesTracker,
  transformDeliverablesTracker,
)

export const getSortedDeliverableOrderNames = createSelector(getDeliverablesTracker, (deliverablesTracker) => {
  const orders = deliverablesTracker.reduce(
    (acc: $TSFixMe, { deliverableTypesOrder }: $TSFixMe) => Object.assign(acc, deliverableTypesOrder),
    {},
  )

  return flow([keys, map((name: string) => ({ name, order: orders[name] })), sortBy('order'), map('name')])(orders)
})

export const getDeliverableColumnOrderState = createSelector(getDeliverablesTracker, (deliverablesTracker) => {
  return deliverablesTracker.reduce(
    (acc: { types: $TSFixMe; customTypes: $TSFixMe }, { deliverableTypes, deliverableTypesCreatedAt }: $TSFixMe) => {
      Object.keys(deliverableTypes).forEach((typeKey) => {
        if (!acc.types[typeKey]) acc.types[typeKey] = []
        const sortedDeliverables = sortDeliverables({
          deliverables: deliverableTypes[typeKey],
          startDateAccessor: 'deliverableStartDate',
          dueDateAccessor: 'deliverableDueDate',
          orgLayerAccessor: 'orgDesignTeamLayerId',
          orderAccessor: 'order',
        })

        acc.types[typeKey] = compose(uniqBy('id'), concat(acc.types[typeKey]))(sortedDeliverables)
      })

      Object.keys(deliverableTypesCreatedAt).forEach((customTypeKey) => {
        if (deliverableTypesCreatedAt[customTypeKey]) {
          acc.customTypes[customTypeKey] = deliverableTypesCreatedAt[customTypeKey]
        }
      })

      return acc
    },
    { types: {}, customTypes: {} },
  )
})

export const getIsDeliverableLocked = (state: $TSFixMe) => clientState(state).isDeliverableLocked

export const getDeliverableData = (state: $TSFixMe) => state['@deliverableData'] || {}

export const getAcknowledgeKeyProcesses = (state: $TSFixMe) => clientState(state).keyProcessesToAcknowledge

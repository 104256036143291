import { put, call } from 'redux-saga/effects'
import { setProjectList } from '@dayOne/actions/actions'
import { getDayOneApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { delay } from 'redux-saga'

export const fetchDayOneProjectList = createSaga(function* fetchDayOneProjectList(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = action.payload

  const dayOneApi = yield getDayOneApi()
  const data = yield call(dayOneApi.request('getDayOneData', { query: { teamId } }))

  yield put(
    setProjectList({
      dayOneProjectList: data,
      teamId,
    }),
  )
},
true)

export const reFetchDayOneProjectListForSocket = createSaga(function* reFetchDayOneProjectListForSocket(
  action: $TSFixMe,
) {
  yield delay(2000)

  yield call(fetchDayOneProjectList, action)
})

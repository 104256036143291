export const FETCH_MODULE_OPTIONS_PENDING = 'FETCH_MODULE_OPTIONS_PENDING'
export const FETCH_MODULE_OPTIONS_SUCCESS = 'FETCH_MODULE_OPTIONS_SUCCESS'
export const FETCH_MODULE_OPTIONS_FAIL = 'FETCH_MODULE_OPTIONS_FAIL'

export const SAVE_MODULE_OPTIONS_PENDING = 'SAVE_MODULE_OPTIONS_PENDING'
export const SAVE_MODULE_OPTIONS_SUCCESS = 'SAVE_MODULE_OPTIONS_SUCCESS'
export const SAVE_MODULE_OPTIONS_FAIL = 'SAVE_MODULE_OPTIONS_FAIL'

export const UPDATE_DICTIONARIES = 'UPDATE_DICTIONARIES'
export const UPDATE_MODULES = 'UPDATE_MODULES'
export const UPDATE_MODULES_SELECT = 'UPDATE_MODULES_SELECT'
export const ADD_AIRTABLE_INTEGRATION_TEAM = 'ADD_AIRTABLE_INTEGRATION_TEAM'
export const UPDATE_AIRTABLE_INTEGRATION_TEAM = 'UPDATE_AIRTABLE_INTEGRATION_TEAM'
export const DELETE_AIRTABLE_INTEGRATION_TEAM = 'DELETE_AIRTABLE_INTEGRATION_TEAM'
export const DEACTIVATE_AIRTABLE_DICTIONARY = 'DEACTIVATE_AIRTABLE_DICTIONARY'
export const UNSET_AIRTABLE_DICTIONARY = 'UNSET_AIRTABLE_DICTIONARY'
export const SET_AIRTABLE_CUSTOM_COLUMN_ID = 'SET_AIRTABLE_COLUMN_ID'

export const DELETE_DICTIONARY = 'DELETE_DICTIONARY'

export const BULK_UPDATE_MODULE_OPTIONS = 'BULK_UPDATE_MODULE_OPTIONS'

const prefix = '@myTeam/'

export const FETCH_PROJECT_PLAN_LIST = `${prefix}FETCH_PROJECT_PLAN_LIST`
export const SET_PROJECT_PLAN_LIST = `${prefix}SET_PROJECT_PLAN_LIST`
export const RESET_PROJECT_PLAN_LIST = `${prefix}RESET_PROJECT_PLAN_LIST`

export const REMOVE_NOTIFICATION_FROM_LOADING = `${prefix}REMOVE_NOTIFICATION_TO_LOADING`
export const ADD_NOTIFICATION_TO_LOADING = `${prefix}ADD_NOTIFICATION_TO_LOADING`
export const FETCH_NOTIFICATIONS = `${prefix}FETCH_NOTIFICATIONS`
export const UPDATE_INBOX_NOTIFICATIONS = `${prefix}UPDATE_INBOX_NOTIFICATIONS`
export const UPDATE_ARCHIVE_NOTIFICATIONS = `${prefix}UPDATE_ARCHIVE_NOTIFICATIONS`
export const SET_NOTIFICATIONS = `${prefix}SET_NOTIFICATIONS`
export const APPEND_INBOX_NOTIFICATIONS = `${prefix}APPEND_INBOX_NOTIFICATIONS`
export const APPEND_ARCHIVE_NOTIFICATIONS = `${prefix}APPEND_ARCHIVE_NOTIFICATIONS`
export const REMOVE_NOTIFICATIONS_FROM_INBOX = `${prefix}REMOVE_NOTIFICATIONS_FROM_INBOX`
export const REMOVE_NOTIFICATIONS_FROM_ARCHIVE = `${prefix}REMOVE_NOTIFICATIONS_FROM_ARCHIVE`
export const ARCHIVE_ENTITY_NOTIFICATIONS = `${prefix}ARCHIVE_ENTITY_NOTIFICATIONS`
export const UNARCHIVE_ENTITY_NOTIFICATIONS = `${prefix}UNARCHIVE_ENTITY_NOTIFICATIONS`
export const ARCHIVE_ALL_NOTIFICATIONS = `${prefix}ARCHIVE_ALL_NOTIFICATIONS`

export const FETCH_TEAM_WEEK_STATUS = `${prefix}FETCH_TEAM_WEEK_STATUS`
export const SET_TEAM_WEEKLY_STATUS_DATA = `${prefix}SET_TEAM_WEEKLY_STATUS_DATA`
export const UPDATE_TEAM_WEEKLY_STATUS = `${prefix}UPDATE_TEAM_WEEKLY_STATUS`

export const FETCH_DELIVERABLE_TYPES_STATUSES = `${prefix}FETCH_DELIVERABLE_TYPES_STATUSES`
export const SET_DELIVERABLE_TYPES_STATUSES = `${prefix}SET_DELIVERABLE_TYPES_STATUSES`

export const FETCH_TEAM_PROJECTS_STATUSES = `${prefix}FETCH_TEAM_PROJECTS_STATUSES`
export const SET_TEAM_PROJECTS_STATUSES = `${prefix}SET_TEAM_PROJECTS_STATUSES`

export const FETCH_KEY_ISSUES = `${prefix}FETCH_KEY_ISSUES`
export const SET_KEY_ISSUES = `${prefix}SET_KEY_ISSUES`
export const CREATE_KEY_ISSUE = `${prefix}CREATE_KEY_ISSUE`
export const DELETE_KEY_ISSUE = `${prefix}DELETE_KEY_ISSUE`
export const UPDATE_KEY_ISSUE = `${prefix}UPDATE_KEY_ISSUE`

export const FETCH_FLAGGED_RISKS_AND_DECISIONS = `${prefix}FETCH_FLAGGED_RISKS_AND_DECISIONS`
export const SET_FLAGGED_RISKS_AND_DECISIONS = `${prefix}SET_FLAGGED_RISKS_AND_DECISIONS`
export const SET_FLAGGED_RISKS_AND_DECISIONS_DATA_STATE = `${prefix}SET_FLAGGED_RISKS_AND_DECISIONS_DATA_STATE`

export const FETCH_OTHER_TEAM_UPDATES = `${prefix}FETCH_OTHER_TEAM_UPDATES`
export const SET_OTHER_TEAM_UPDATES = `${prefix}SET_OTHER_TEAM_UPDATES`
export const SET_OTHER_TEAM_UPDATES_DATA_STATE = `${prefix}SET_OTHER_TEAM_UPDATES_DATA_STATE`

export const CREATE_OTHER_TEAM_UPDATE = `${prefix}CREATE_OTHER_TEAM_UPDATES`
export const CREATE_OTHER_TEAM_UPDATE_SUCCESS = `${prefix}CREATE_OTHER_TEAM_UPDATES_SUCCESS`

export const DELETE_OTHER_TEAM_UPDATE = `${prefix}DELETE_OTHER_TEAM_UPDATES`
export const DELETE_OTHER_TEAM_UPDATE_SUCCESS = `${prefix}DELETE_OTHER_TEAM_UPDATES_SUCCESS`

export const UPDATE_OTHER_TEAM_UPDATE = `${prefix}UPDATE_OTHER_TEAM_UPDATE`
export const UPDATE_OTHER_TEAM_UPDATE_SUCCESS = `${prefix}UPDATE_OTHER_TEAM_UPDATES_SUCCESS`

export const SUBMIT_WEEKLY_STATUS_UPDATE = `${prefix}SUBMIT_WEEKLY_STATUS_UPDATE`
export const SUBMIT_WEEKLY_STATUS_UPDATE_SUCCESS = `${prefix}SUBMIT_WEEKLY_STATUS_UPDATE_SUCCESS`

export const FETCH_KEY_PROCESS_DELETE_DETAILS = `${prefix}FETCH_KEY_PROCESS_DELETE_DETAILS`
export const SET_KEY_PROCESS_DELETE_DETAILS = `${prefix}SET_KEY_PROCESS_DELETE_DETAILS`
export const SET_KEY_PROCESS_DELETE_DETAILS_STATE = `${prefix}SET_KEY_PROCESS_DELETE_DETAILS_STATE`

export const UPDATE_NOTIFICATION_WATCH_STATUS = `${prefix}UPDATE_NOTIFICATION_WATCH_STATUS`

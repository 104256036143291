import React from 'react'
import { StyledMenuItem } from './Lookup.styles'
import { getUserDisplayName } from '@shared/Lookup/utils'
import { isFunction } from 'lodash'
import classnames from 'classnames'

type ItemRendererProps = {
  item: {
    name?: string
    surname?: string
    id?: number | string
    displayName?: string
  }
  itemProps: $TSFixMe
  isSelected?: (...args: $TSFixMe[]) => boolean
  getText?: (...args: $TSFixMe[]) => string
  className?: string
}

export const ItemRenderer = ({ item, itemProps, isSelected = () => false, getText, className }: ItemRendererProps) => {
  const { handleClick, modifiers } = itemProps

  if (!(modifiers as $TSFixMe).matchesPredicate) {
    return null
  }

  return (
    <StyledMenuItem
      className={classnames([isSelected(item) ? 'bp4-intent-primary bp4-active' : '', className])}
      text={getText && isFunction(getText) ? getText(item) : getUserDisplayName(item)}
      icon={isSelected(item) ? 'tick' : 'blank'}
      active={(modifiers as $TSFixMe).active}
      disabled={(modifiers as $TSFixMe).disabled}
      onClick={handleClick}
      data-cy={'lookup-item'}
    />
  )
}

export const SimpleItemRenderer = ({
  item,
  itemProps,
  isSelected = () => false,
  getText,
  className,
}: ItemRendererProps) => {
  const { handleClick, modifiers } = itemProps

  if (!(modifiers as $TSFixMe).matchesPredicate) {
    return null
  }

  return (
    <StyledMenuItem
      className={classnames([isSelected(item) ? 'bp4-intent-primary bp4-active' : '', className])}
      text={getText ? getText(item) : (item as $TSFixMe).name}
      icon={isSelected(item) ? 'tick' : 'blank'}
      active={(modifiers as $TSFixMe).active}
      disabled={(modifiers as $TSFixMe).disabled}
      onClick={handleClick}
      data-cy={'lookup-simple-item-renderer'}
    />
  )
}

export const CreateItemOption = (query: $TSFixMe, active: $TSFixMe, handleClick: $TSFixMe) => (
  <StyledMenuItem
    icon="add"
    text={`Create "${query}"`}
    active={active}
    onClick={handleClick}
    shouldDismissPopover={false}
    data-cy={'lookup-create-new'}
  />
)

const itemDefaultProps = {
  isSelected: () => false,
  getText: null,
  className: null,
}

ItemRenderer.defaultProps = itemDefaultProps
SimpleItemRenderer.defaultProps = itemDefaultProps

import { ModuleOption } from './types.d'

const toggleDependencies: $TSFixMe = {
  mutualDiscovery: [],
  documentSharing: [],
  valueCapture: [],
  communications: [],
  dataRequestTracker: [],
  day1HypercareLog: [],
  imoDeliverablesPanel: [],
  teamCalendar: [],
  notifications: [],
  teamResources: ['documentSharing', 'dataRequestTracker', 'teamCalendar'],
  revenueSynergies: [],
  mirroringRestrictions: [],
  fileAttachments: [],
  NWCSynergies: [],
  contentVault: [],
  valueCaptureInitiativesUpload: [],
  mcKinseyLibrary: [],
  knowledgeCenter: [],
  weeklyStatusUpdate: [],
  advancedFinancials: [],
  unreleasedFeatures: [],
  airtableIntegration: [],
  orgDesign: [],
  executiveDashboard: [],
  npv: [],
  askMyIMO: [],
}

export const updateDependantModules = (payload: ModuleOption, modules: ModuleOption[]): ModuleOption[] => {
  const dependencies: string[] = toggleDependencies[payload.key]

  return modules.map((module) => {
    if (dependencies.includes(module.key)) {
      return {
        ...module,
        locked: !payload.active,
        active: false,
      }
    }

    return module.key === payload.key ? payload : module
  })
}

export const updateDefaultDictionaries = (dictionary: $TSFixMe, list: $TSFixMe) => {
  return list.map((item: $TSFixMe) => (item.name === dictionary.name ? dictionary : item))
}

export const updateDictionaries = (dictionary: $TSFixMe, list: $TSFixMe) => {
  const index = list.findIndex((item: $TSFixMe) => item.id === dictionary.id)

  if (index >= 0) return list.map((item: $TSFixMe) => (item.id === dictionary.id ? dictionary : item))

  return [...list, dictionary]
}

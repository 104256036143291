import styled from 'styled-components'
import { Colors } from '@blueprintjs/core'
import { rgba } from '@imo/imo-ui-toolkit'

export const ActionItemCell = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;

  .action-item-checkbox {
    flex-shrink: 0;
  }

  .bp4-control.bp4-disabled:hover input:checked ~ .bp4-control-indicator {
    background-color: ${rgba(Colors.BLUE3, 0.5)};
  }
`

export const ActionText = styled.span`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
`

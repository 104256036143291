import nanoid from 'nanoid'
import { initiativeStatuses } from '@helpers/statuses'
import { getOr, isEmpty, sortBy, find, last } from 'lodash/fp'
import { synergyTypes, STAGE_GATE_IDS } from '@helpers/constants'

export const defaultItemBySynergy = {
  [synergyTypes.COST]: {
    FTE: null,
  },
}

export const getDefaultInitiativeData = (synergyType: $TSFixMe, companyAName: string, locationState?: $TSFixMe) => ({
  id: nanoid(),
  isNew: true,
  name: null,
  synergyType,
  company: { id: 'companyA', name: companyAName },
  status: initiativeStatuses.ON_TRACK,
  stageGateId: STAGE_GATE_IDS.L1,
  stageGate: {
    id: STAGE_GATE_IDS.L1,
    level: STAGE_GATE_IDS.L1,
  },
  owners: [],
  impact: null,
  team: locationState?.initiativeTeam,
  ...getOr({}, synergyType, defaultItemBySynergy),
})

export const getDefaultAllInitiativesData = (synergyType: $TSFixMe, companyAName: string) => {
  return { ...getDefaultInitiativeData(synergyType, companyAName), team: null }
}

export const getWithLinkedSynergyType = (synergyInitiatives: $TSFixMe[], type: $TSFixMe) =>
  synergyInitiatives.filter((item) => item.linkedSynergyInitiative?.type === type)

export const updateMirroredInitiative = (
  selectedInitiative: $TSFixMe,
  mirroredInitiative: $TSFixMe,
  properties = {},
) => {
  const result = {
    ...selectedInitiative,
    id: mirroredInitiative.id,
    mirroredInitiatives: mirroredInitiative.mirroredInitiatives,
    parentInitiative: mirroredInitiative.parentInitiative,
    parentInitiativeId: mirroredInitiative.parentInitiativeId,
    ...properties,
  }

  if (selectedInitiative.team) {
    result.team = mirroredInitiative.team
  }

  return result
}

export const updateMirroredInitiatives = ({ selectedInitiative, initiativeList, id }: $TSFixMe) => {
  const { mirroredInitiatives } = selectedInitiative

  if (isEmpty(mirroredInitiatives))
    return initiativeList.map((item: $TSFixMe) => (item.id === id ? selectedInitiative : item))

  return initiativeList.map((initiative: $TSFixMe) => {
    const mirroredState = mirroredInitiatives.find((item: $TSFixMe) => item.initiativeId === initiative.id)

    if (mirroredState) {
      return updateMirroredInitiative(selectedInitiative, initiative, {
        listId: mirroredState.listId,
      })
    }

    return initiative.id === selectedInitiative.id ? selectedInitiative : initiative
  })
}

export const updateInitiativeStageGate = ({ initiative, stageGateId, actualStageGateData }: $TSFixMe) => ({
  ...initiative.stageGate,
  id: stageGateId,
  ...actualStageGateData,
})

export const sortAllInitiativeList = (list: $TSFixMe, sorting: $TSFixMe) => {
  const addedInitiativeArray = []
  let oldInitiativeArray = []

  for (let i = 0; i < list.length; i++) {
    if (list[i].added) {
      addedInitiativeArray.push(list[i])
    } else {
      oldInitiativeArray.push(list[i])
    }
  }

  if (!sorting?.length) {
    oldInitiativeArray = sortBy(
      [({ team }) => !team, ({ team }) => getOr('', 'longName', team).toLowerCase(), 'listId'],
      oldInitiativeArray,
    )
  }

  return [...addedInitiativeArray, ...oldInitiativeArray]
}

export const getListAfterUpdate = ({
  previousInitiatives,
  newInitiatives,
  initiativeList,
  isMirroringInitiativesInactive,
  initiativeListLength,
  sorting,
}: $TSFixMe) => {
  let result = []
  const toBeChanged = {} as { [key: string]: $TSFixMe }

  const toBeRemovedIds = previousInitiatives.reduce((acc: $TSFixMe, initiative: $TSFixMe) => {
    const newInitiative = find({ id: initiative.initiativeId }, newInitiatives)
    if (!newInitiative) {
      acc.push(initiative.initiativeId)
    } else {
      toBeChanged[initiative.initiativeId] = newInitiative.id
    }

    return acc
  }, [])

  const toBeAddedInitiatives = newInitiatives.reduce((acc: $TSFixMe, initiative: $TSFixMe) => {
    const oldInitiative = find({ initiativeId: initiative.id }, previousInitiatives)
    if (!oldInitiative) {
      acc.push(initiative)
    } else {
      toBeChanged[initiative.id] = initiative.id
    }

    return acc
  }, [])

  const toBeChangedIds = Object.values(toBeChanged)

  for (let count = 0; count < initiativeList.length; count++) {
    const initiativeId = initiativeList[count].id

    if (toBeChangedIds.includes(initiativeId)) {
      result.push(find({ id: initiativeId }, newInitiatives))

      continue
    }

    if (toBeRemovedIds.includes(initiativeId)) continue

    result.push(initiativeList[count])
  }

  toBeAddedInitiatives.forEach((initiative: $TSFixMe) => {
    if (!isMirroringInitiativesInactive) return

    result.push(initiative)
  })

  const toBeAddedInitiativesIds = toBeAddedInitiatives.map(({ id }: $TSFixMe) => id)

  result = sortAllInitiativeList(result, sorting)

  while (initiativeListLength > result.length && toBeAddedInitiativesIds.includes(last(result).id)) {
    result.pop()
  }

  return result
}

import { call, put } from 'redux-saga/effects'
import { deleteTeamMember, updateTeamMember } from '../actions/actions'
import { getTeamCharterApi } from '@common/net'
import { getTeamMembersRequestQuery, formatTeamMembersRequestBody } from '../helpers'
import { createSaga } from '@common/sagaCreator/createSaga'

export const saveTeamMemberRow = createSaga(function* saveTeamMemberRow(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamMemberRow, teamId } = action.payload
  const { id, isNew, topic, userA, userB } = teamMemberRow
  if (!topic && !userA.name && !userB.name) {
    yield put(deleteTeamMember({ id, teamId }))

    return
  }

  // Exclude keys with invalid/empty data
  const updatedData = yield formatTeamMembersRequestBody(teamMemberRow)

  const api = yield getTeamCharterApi()
  const teamMembers = yield call(
    api.request(isNew ? 'saveTeamMembers' : 'updateTeamMembers', {
      query: getTeamMembersRequestQuery(isNew, teamId, id),
      body: updatedData,
    }),
  )

  let teamMembersToSet = { ...teamMemberRow }

  // Set id after POST for new rows
  if (isNew && teamMembers.id) {
    teamMembersToSet = {
      ...teamMembersToSet,
      isNew: false,
      id: teamMembers.id,
    }
  }

  // Update team members in store to show data in the table
  yield put(updateTeamMember({ data: teamMembersToSet, id }))
},
true)

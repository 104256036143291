// Libs
import { call, put } from 'redux-saga/effects'

// Actions
import { createCommentSuccess } from '../actions/actions'

// API
import { getDeliverablesApi } from '@common/net'

import { createSaga } from '@common/sagaCreator/createSaga'

export const createComment = createSaga(function* createComment(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, deliverableId, data } = action.payload

  const api = yield getDeliverablesApi()
  const comment = yield call(
    api.request('createActionComment', {
      query: { teamId, deliverableId },
      body: data,
    }),
  )

  yield put(createCommentSuccess(comment))
})

import { transform, union } from 'lodash'
import { PERMISSION_ACTION } from '@common/accessController/constants'

export const combineRolePermissions = ({
  getPermissionsByRoleStrategy,
  rolesInfo,
  userInfo,
  strategyTestingAdditionalArguments,
}: {
  getPermissionsByRoleStrategy: $TSFixMe
  rolesInfo: $TSFixMe
  userInfo: $TSFixMe
  strategyTestingAdditionalArguments: $TSFixMe
}) => {
  const allRolesPermissionsList = transform<$TSFixMe, $TSFixMe>(
    rolesInfo.groupedRoles,
    (permissions, roles, teamType) => {
      roles.forEach((role: $TSFixMe) => {
        const rolePermissionList = getPermissionsByRoleStrategy(
          { ...role, teamType },
          userInfo,
          rolesInfo,
        )(...strategyTestingAdditionalArguments)

        permissions.push(rolePermissionList)
      })

      return permissions
    },
    [],
  )

  return union(...allRolesPermissionsList)
}

export const applyStrategy =
  ({
    userInfo,
    userRolesInfo,
    userRolesMetaInfo,
    userTeamRolesInfo,
    getPermissionsByRoleStrategy,
  }: {
    userInfo: $TSFixMe
    userRolesInfo: $TSFixMe
    userRolesMetaInfo: $TSFixMe
    userTeamRolesInfo: $TSFixMe
    getPermissionsByRoleStrategy: $TSFixMe
  }) =>
  (...checkPermissionCallArgs: $TSFixMe) => {
    if (!getPermissionsByRoleStrategy) return true

    const [field = PERMISSION_ACTION.EDIT, ...strategyTestingAdditionalArguments] = checkPermissionCallArgs
    const rolesInfo = { ...(userTeamRolesInfo || userRolesInfo), ...userRolesMetaInfo }

    const permissions = combineRolePermissions({
      getPermissionsByRoleStrategy,
      rolesInfo,
      userInfo,
      strategyTestingAdditionalArguments,
    })

    return permissions.includes(field)
  }

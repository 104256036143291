import React, { forwardRef, useImperativeHandle } from 'react'
import { GridApi } from 'ag-grid-community'
import {
  InterdependencyIcon,
  KeyProcessIcon,
  InterdependencyWrapper,
  ProcessContainer,
  StyledKeyProcessName,
  StyledLabel,
  StyledLabelTooltip,
  StyledName,
  StyledTooltipWithFlex,
  StyledHighlightedName,
} from './index.styles'
import { Classes } from '@blueprintjs/core'
import { lightGray, white } from '@imo/imo-ui-toolkit'
import { INTERDEPENDENCY_PROJECT_LIST_ID } from '@shared/DayOne/constants'
import { TeamKeyProcessProjectOrTask } from '@common/types/dtos/DayOneProjectListDto'
import { ProjectMirror } from '@common/types/dtos/KeyProcessProjectTaskBaseDto'

export const getInterdependenciesContent = (num: $TSFixMe) => {
  if (num === 1) return `There is ${num} outstanding interdependency that is not complete`

  return `There are ${num} outstanding interdependencies that are not complete`
}

type KeyProcessCellProps = {
  value: {
    name: string
    required: boolean
    isIncoming: boolean
    isOutgoing: boolean
    uncompleted: boolean
    mirror: ProjectMirror
  }
  data: TeamKeyProcessProjectOrTask
  api: GridApi
}

const renderNameWithHighlight = (name: string, highlight: string | null) => {
  if (!highlight || !highlight.length) {
    return <StyledHighlightedName>{name}</StyledHighlightedName>
  }

  const startIndex = name.toLowerCase().indexOf(highlight.toLowerCase())

  if (startIndex === -1) {
    return <StyledHighlightedName>{name}</StyledHighlightedName>
  }

  const highlightedString = (
    <>
      {name.substring(0, startIndex)}
      <span>{name.substring(startIndex, startIndex + highlight.length)}</span>
      {name.substring(startIndex + highlight.length)}
    </>
  )

  return <StyledHighlightedName>{highlightedString}</StyledHighlightedName>
}

const KeyProcessCell = forwardRef<$TSFixMe, KeyProcessCellProps>(({ value, data, api }, ref) => {
  const { name, required, isIncoming, isOutgoing, uncompleted, mirror } = value

  const filterValue = api.getFilterModel()['ag-Grid-AutoColumn']?.filter || null

  useImperativeHandle(ref, () => ({}))

  if (isIncoming || isOutgoing) {
    return (
      <InterdependencyWrapper>
        <StyledName>{renderNameWithHighlight(name, filterValue)}</StyledName>
        <StyledTooltipWithFlex
          targetTagName={'div'}
          content={`${isIncoming ? 'Incoming' : 'Outgoing'} interdependency`}
          className={Classes.FOCUS_DISABLED}
        >
          <InterdependencyIcon
            icon={isIncoming ? 'inner-join' : 'intersection'}
            color={lightGray}
            className={Classes.FOCUS_DISABLED}
          />
        </StyledTooltipWithFlex>
      </InterdependencyWrapper>
    )
  }

  if (mirror) {
    return (
      <ProcessContainer>
        <StyledKeyProcessName>
          {renderNameWithHighlight(name, filterValue)}
          <KeyProcessIcon icon={'git-pull'} color={white} iconSize={12} className={Classes.FOCUS_DISABLED} $flip />
        </StyledKeyProcessName>
      </ProcessContainer>
    )
  }

  const processName =
    data.projectListId === INTERDEPENDENCY_PROJECT_LIST_ID ? (
      <StyledKeyProcessName>
        {name}
        <KeyProcessIcon icon={'fork'} color={white} iconSize={12} className={Classes.FOCUS_DISABLED} />
      </StyledKeyProcessName>
    ) : (
      <div>{renderNameWithHighlight(name, filterValue)}</div>
    )

  return (
    <ProcessContainer>
      {processName}
      {required && <StyledLabel className="required-label">required</StyledLabel>}
      {Boolean(uncompleted) && (
        <StyledLabelTooltip
          content={getInterdependenciesContent(uncompleted)}
          className={Classes.FOCUS_DISABLED}
          targetTagName={'div'}
        >
          <StyledLabel className="required-label">incomplete</StyledLabel>
        </StyledLabelTooltip>
      )}
    </ProcessContainer>
  )
})

export default KeyProcessCell

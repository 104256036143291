import { actionTypes, ActionTypes } from './utils'
import { Intent } from '@blueprintjs/core'
import { errorType } from '@helpers/customErrors'

export const getType = (type: ActionTypes) => {
  switch (type) {
    case actionTypes.FAIL:
    case actionTypes.VALIDATION_FAIL:
      return Intent.DANGER
    case actionTypes.SUCCESS:
      return Intent.SUCCESS
    case actionTypes.WARNING:
      return Intent.WARNING
    default:
      return Intent.WARNING
  }
}

export const getErrorMessage = ({ name, message, fromServer, status }: $TSFixMe) => {
  if (status === 500) return message

  if ((name === errorType.HTTP && fromServer) || name === errorType.VALIDATION) return message

  return null
}

export const getActionType = (name: string, extendedNotification?: $TSFixMe) => {
  if (name === errorType.VALIDATION) return actionTypes.VALIDATION_FAIL

  return extendedNotification ? actionTypes.WARNING : actionTypes.FAIL
}

const isNotFoundStatus = (status: number) => status === 404

export const isUnauthorized = (status: number) => status === 401

export const isForbidden = (status: number) => status === 403

const isDayOne = (action: $TSFixMe) => action.includes('dayOne')

export const isExtendedNotification = (status: $TSFixMe, action: $TSFixMe) =>
  isNotFoundStatus(status) && isDayOne(action)

import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'
import { loadingState } from '@helpers/constants'
import {
  checkIsChildSubProcess,
  checkIsMatchProcess,
  flattenProcessFromLibrary,
  getMDPARowNodeId,
  updateProcesses,
} from '../utils'

export interface IMutalDiscoveryState {
  processes: $TSFixMe[]
  dataState?: $TSFixMe
  processesFromLibrary: $TSFixMe[]
  processesFromLibraryState?: $TSFixMe
}

export const initialState = {
  processes: [],
  dataState: loadingState.INITIAL,
  processesFromLibrary: [],
  processesFromLibraryState: loadingState.INITIAL,
}

const mutualDiscoveryReducer = handleActions<IMutalDiscoveryState, $TSFixMe>(
  {
    [constants.SET_MUTUAL_DISCOVERY_DATA]: (state, action) => ({ ...state, processes: action.payload }),
    [constants.CLEAR_MUTUAL_DISCOVERY_DATA]: (state) => ({ ...state, processes: initialState.processes }),

    [constants.ADD_PROCESS]: (state, action) => {
      const { data } = action.payload

      return { ...state, processes: [...state.processes, data] }
    },

    [constants.UPDATE_PROCESS_SUCCESS]: (state, { payload }) => {
      const { data, keyProcessId, id } = payload

      return {
        ...state,
        processes: updateProcesses({ data, keyProcessId, id }, state.processes),
      }
    },

    [constants.DELETE_PROCESS_SUCCESS]: (state, { payload }) => {
      const { id, keyProcessId } = payload
      const isKeyProcess = !keyProcessId

      return {
        ...state,
        processes: state.processes.filter((process) => {
          return !(
            checkIsMatchProcess(process, { id, keyProcessId }) ||
            (isKeyProcess && checkIsChildSubProcess(process, id))
          )
        }),
      }
    },
    [constants.SET_PROCESSES_FROM_LIBRARY]: (state, { payload }) => {
      return {
        ...state,
        processesFromLibrary: flattenProcessFromLibrary(payload),
      }
    },
    [constants.RESET_PROCESSES_FROM_LIBRARY]: (state) => ({
      ...state,
      processesFromLibraryState: loadingState.INITIAL,
      processesFromLibrary: [],
    }),
    [constants.UPDATE_PROCESSES_FROM_LIBRARY]: (state, { payload }) => {
      const { rowNodeId, selected } = payload

      return {
        ...state,
        processesFromLibrary: state.processesFromLibrary.map((process) =>
          getMDPARowNodeId({ data: process }) === rowNodeId && !process.disabled ? { ...process, selected } : process,
        ),
      }
    },
    [constants.SET_VISIBLE_ROW_ID]: (state, action) => {
      return {
        ...state,
        visibleRowId: action.payload,
      }
    },
  },
  initialState,
)

export default mutualDiscoveryReducer

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchFuncDesignProcesses = createAction(
  constants.FETCH_FUNC_DESIGN_PROCESSES,
  null,
  getMetaWithActionLoading,
)
export const setFuncDesignProcesses = createAction(constants.SET_FUNC_DESIGN_PROCESSES)

export const createFuncDesignProcess = createAction(constants.CREATE_FUNC_DESIGN_PROCESS)
export const createFuncDesignProcessSuccess = createAction(constants.CREATE_FUNC_DESIGN_PROCESS_SUCCESS)

export const updateFuncDesignProcess = createAction(constants.UPDATE_FUNC_DESIGN_PROCESS)
export const updateFuncDesignProcessSuccess = createAction(constants.UPDATE_FUNC_DESIGN_PROCESS_SUCCESS)

export const deleteFuncDesignProcess = createAction(constants.DELETE_FUNC_DESIGN_PROCESS)
export const deleteFuncDesignProcessSuccess = createAction(constants.DELETE_FUNC_DESIGN_PROCESS_SUCCESS)

import { put, call } from 'redux-saga/effects'
import { deleteIssueSuccess } from '@hypercareLog/actions/actions'
import { getHypercareLogApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const deleteIssue = createSaga(function* deleteIssue(action: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, id } = action.payload

  const api = yield getHypercareLogApi()
  yield call(
    api.request('deleteIssue', {
      query: { teamId, issueId: id },
    }),
  )

  yield put(deleteIssueSuccess({ id }))
})

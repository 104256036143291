import { isString } from 'lodash'
import { day1TsaDeliverables } from '@generic/selectors/utils'
import { sourceDeliverables } from '@helpers/constants'

const getReadableParam = (param: string | number | null) => {
  if (param === '') return null

  if (isString(param)) return parseInt(param)

  return param
}

const isDay1Deliverable = (pathname: string) => {
  return day1TsaDeliverables.some((deliverableId) => {
    return pathname.includes(`/deliverables/${deliverableId}`)
  })
}

export const getTeamParamsFromLocation = (pathname: string, search: URLSearchParams) => {
  const urlParams = new URLSearchParams(search)
  const fromTeam = getReadableParam(urlParams.get(sourceDeliverables.FROM_TEAM))
  const fromImoTeam = getReadableParam(urlParams.get(sourceDeliverables.FROM_IMO_TEAM))
  const fromTeamHome = urlParams.get('fromTeamHome')

  const valueCaptureDelId = getReadableParam(urlParams.get('deliverableId'))

  // TODO: this approach is fragile and will need to change when we change URLs.
  const pathnameList = pathname.split('/')
  const teamIndex = pathnameList.indexOf('team')
  const teamId = getReadableParam(pathnameList[teamIndex + 1])

  const deliverableIndex = pathnameList.indexOf('deliverables')
  const deliverableId = getReadableParam(pathnameList[deliverableIndex + 1])

  const isDeliverable = pathname.includes('/dashboard/') && pathname.includes('/deliverables/')

  const isImoControlTower = pathname.includes('/imo-control-tower')

  const isVcTracker = pathname.includes('/value-capture-tracker')

  const isKnowledgeCenter = pathname.includes('/knowledge-center')

  const isTeamVc = pathname.includes('/team-value-capture')

  const isMyTeamHome = pathname.includes('/team-home')

  const isIMODeliverablesTracker = pathname.includes('/integration-team-deliverable-tracker')

  const isTSAv2MasterList = pathname.includes('/tsa-master-list')

  const isOrgDesignTracker = pathname.includes('/org-design-tracker')

  const isTeamOrgDesign = pathname.includes('/team-org-design')

  const isMyTeam = pathname.includes('/dashboard/team')

  const isRiskDecisionLogs =
    pathname.includes('/dashboard/team') && (pathname.includes('/risk-log') || pathname.includes('/decision-log'))

  const isTeamWorkspace = pathname.includes('/team-workspace')

  const isTeamStatusUpdate = pathname.includes('/team-status-update')

  return {
    pathname,
    search,
    isDeliverable,
    isDay1Deliverable: isDay1Deliverable(pathname),
    deliverableId,
    isImoControlTower,
    isVcTracker,
    isTeamVc,
    teamId,
    fromTeam,
    fromImoTeam,
    fromTeamHome,
    valueCaptureDelId,
    isMyTeamHome,
    isMyTeam,
    isRiskDecisionLogs,
    isTeamWorkspace,
    isTeamStatusUpdate,
    isKnowledgeCenter,
    isIMODeliverablesTracker,
    isTSAv2MasterList,
    isOrgDesignTracker,
    isTeamOrgDesign,
  }
}

export const priorityOptions = [
  { label: 'Day 1 critical', value: 'Day 1 critical' },
  { label: 'Day 1 non-critical', value: 'Day 1 non-critical' },
  { label: 'Day 100', value: 'Day 100' },
  { label: 'Year 1', value: 'Year 1' },
  { label: 'Year 2', value: 'Year 2' },
]

export const PRIORITIES = {
  Day1Critical: 'Day 1 critical',
  Day1NonCritical: 'Day 1 non-critical',
  Day100: 'Day 100',
  Year1: 'Year 1',
  Year2: 'Year 2',
}

export const SPECIFIC_PRIORITIES = {
  ALL_PRIORITIES: { label: 'All priorities', value: 'ALL' },
  NO_PRIORITY: { label: 'No priority', value: null },
}

export const listItemTypes = {
  KEY_PROCESS: 'keyProcess',
  PROJECT: 'project',
  TASK: 'task',
  INTERDEPENDENCY: 'incomingInterdependencies',
}

export const INTERDEPENDENCY_PROJECT_LIST_ID = '99'
export const MIRRORED_PROJECT_PROCESS_LIST_ID = 100

export const defaultWindowWidth = 1385

export const processItemHierarchy = {
  [listItemTypes.KEY_PROCESS]: 1,
  [listItemTypes.PROJECT]: 2,
  [listItemTypes.TASK]: 3,
}

export const POSITIONS = {
  START: 'a',
  MIDDLE: 'x',
  END: 'y',
  ENDv2: 'z',
}

export const processItemPatterns = {
  [listItemTypes.KEY_PROCESS]: /^\d{1,5}$/u,
  [listItemTypes.PROJECT]: /^(\d{1,5}(|\.)|(|\d{1,5})\.\d{1,5})$/u,
  [listItemTypes.TASK]: /^(\d{1,5}(|\.)|(|\d{1,5})\.(\d{1,5}(|\.)|(|\d{1,5})\.\d{1,5}))$/u,
}

export const dayOneColumnsName = [
  'name',
  'id',
  'key process/project/task',
  'status',
  'priority',
  'due date',
  'forecast date',
  'owner',
  'confidential',
  'category',
  'other team members',
  'initiative',
  'project',
  'description',
  'Predecessor',
  'follower',
  'start date',
  'comments',
  'ancestor',
  'attachments',
  'interdependency',
  'projectCategoryId',
  'batch upload',
  'linked synergy initiative',
  'linked one-time cost',
  'linked attachments',
  'other team members',
  'key process',
  'integration team',
  'project owner',
  'task owner',
  'linked id',
  'shared comments',
]

import { upperFirst } from 'lodash'
import {
  GLOBAL_IMO,
  GLOBAL_IMO_SHORT_NAME,
  GLOBAL_SMO,
  GLOBAL_SMO_SHORT_NAME,
  teamTitles,
} from '@common/accessController/constants'

// Need to change team type text from Integration to Separation, IMO to SMO for SMO case
// Should be unnecessary after: 1) corresponding to dealType team types would be sent in teamTree from BE side
// 2) role/permission refactoring on FE side (currently a lot of logic has dependencies on a constant "Integration" type)

export const mutateValueForSMOCase = (value = '') => {
  if (upperFirst(value) === teamTitles.INTEGRATION) {
    return teamTitles.SEPARATION
  }

  if (value === teamTitles.IMO) {
    return teamTitles.SMO
  }

  if (value === GLOBAL_IMO) {
    return GLOBAL_SMO
  }

  if (value === GLOBAL_IMO_SHORT_NAME) {
    return GLOBAL_SMO_SHORT_NAME
  }

  return value
}

export const mutateListForSMOCase = (list = []) => {
  const mutatedList = [...list]

  mutatedList.forEach((item) => {
    item.type = mutateValueForSMOCase(item.type).toLowerCase()
  })

  return mutatedList
}

export const mutateTeamsForSMOCase = (groups = {}) => {
  const mutatedGroups = { ...groups }

  for (const group in mutatedGroups) {
    if (mutatedGroups.hasOwnProperty(group)) {
      for (const team of mutatedGroups[group]) {
        team.teamType = mutateValueForSMOCase(team.teamType)
        team.longName = mutateValueForSMOCase(team.longName)
      }
    }
  }

  return mutatedGroups
}

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class UserManagementApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)
    this.host = Config.API_SERVER
  }

  getAllUsers = () => {
    return this.call({ pathname: '/user/step-seven' })
  }

  bulkUpdateUsers = ({ body, query }: $TSFixMe) => {
    const origin = query.isUserManagementPage ? '?origin=user-management' : ''

    return this.call(
      { pathname: `/user/bulk${origin}` },
      {
        method: 'PUT',
        body,
      },
    )
  }

  sendUserInviteEmail = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: `/user/resend-activation-email` },
      {
        method: 'POST',
        body,
      },
    )
  }

  sendUserResetEmail = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: `/user/reset-password` },
      {
        method: 'POST',
        body,
      },
    )
  }

  getWhoLockedData = () => {
    return this.call({ pathname: '/config/user/lock' })
  }

  setLockUserManagement = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: `/user/lock` },
      {
        method: 'PUT',
        body,
      },
    )
  }
}

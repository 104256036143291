// Constants
import { KeyProcessDto } from '@common/types/dtos/KeyProcessDto'
import { TeamKeyProcessProjectOrTask } from '@common/types/dtos/DayOneProjectListDto'
import { listItemTypes } from '../constants'

export const isKeyProcess = (item: TeamKeyProcessProjectOrTask): item is KeyProcessDto =>
  item && item.type === listItemTypes.KEY_PROCESS && !item.isIncoming

export const isIncomingKeyProcess = (item: $TSFixMe) =>
  item && item.type === listItemTypes.KEY_PROCESS && item.isIncoming

export const isProject = (item: $TSFixMe) => item && item.type === listItemTypes.PROJECT
export const isTask = (item: $TSFixMe) => item && item.type === listItemTypes.TASK

import { handleActions } from 'redux-actions'
import * as constants from '@teamResources/planningAssumptionsReport/actions/actionTypes'

export interface IPlanningAssumptionsReportState {
  planningAssumptionsReport: $TSFixMe[]
}

export const initialState = {
  planningAssumptionsReport: [],
}

const planningAssumptionsReportReducer = handleActions<IPlanningAssumptionsReportState, $TSFixMe>(
  {
    [constants.SET_PLANNING_ASSUMPTIONS_REPORT]: (state, action) => ({
      ...state,
      planningAssumptionsReport: action.payload,
    }),
  },
  initialState,
)

export default planningAssumptionsReportReducer

import { deliverableStatus, getDeliverableDisplayStatus } from '@helpers/statuses'
import { flow, find, getOr, sortBy } from 'lodash/fp'

export const getActiveDeliverablesByIds = (deliverables: $TSFixMe[], ids: $TSFixMe[]) =>
  flow(
    sortBy('id'),
    find(
      ({ id, active, ...deliverable }: $TSFixMe) =>
        ids.includes(id) && active && getDeliverableDisplayStatus(deliverable) !== deliverableStatus.NOT_STARTED,
    ),
    getOr(null, 'id'),
  )(deliverables)

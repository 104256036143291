import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'
import {
  fetchUserInitiativeFilterAndInitiatives,
  fetchUserInitiativeFilterAndInfinityList,
  addUserInitiativeFilter,
  changeUserInitiativeFilter,
  deleteUserInitiativeFilter,
} from './userInitiativeFilter'

export default function* initiativeListSagaV2() {
  yield all([
    takeLatest(constants.FETCH_USER_INITIATIVE_FILTER_AND_INITIATIVES, fetchUserInitiativeFilterAndInitiatives),
    takeLatest(constants.FETCH_USER_INITIATIVE_FILTER_AND_INFINITY_LIST, fetchUserInitiativeFilterAndInfinityList),
    takeLatest(constants.ADD_USER_INITIATIVE_FILTER, addUserInitiativeFilter),
    takeLatest(constants.CHANGE_USER_INITIATIVE_FILTER, changeUserInitiativeFilter),
    takeLatest(constants.DELETE_USER_INITIATIVE_FILTER, deleteUserInitiativeFilter),
  ])
}

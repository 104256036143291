import { get, isString } from 'lodash'
import { store } from '@store'
import { getFileNameFromFile } from '@imo/imo-ui-toolkit'
import { downloadFile } from '@generic/actions/actions'
import { runDownloadByUrl } from '@helpers/utils'

// TODO: refactor and replace to appropriate folder
export const downloadAttachment = (file, fileUrl = null, directory = null) => {
  const name = getFileNameFromFile(file)
  const state = store.getState()
  const tenantId = get(state, ['mid', 'tenant', 'id'])

  if (isString(file) && !fileUrl) {
    store.dispatch(downloadFile({ key: file, name, directory: directory || tenantId }))
  } else {
    runDownloadByUrl({ url: fileUrl || URL.createObjectURL(file), name })
  }
}

import { CustomSearch, NOT_SELECTED_LABEL } from '@imo/imo-ui-toolkit'
import { stringComparator } from '@helpers/utilsComparators'
import { FILTER_NUMBER_OPTIONS_ORDER, FILTER_OPTIONS_ORDER } from '@helpers/constants'
import { find, flow, get as getFp, size, getOr, isNil, isEmpty } from 'lodash/fp'
import { get } from 'lodash'
import { getNumberRegex } from '@helpers/regex'
import { CELL_TOP_PADDING, LINE_HEIGHT, ROW_BORDER } from '@shared/Grid/constants'
import { isFreeTextColumn } from '@shared/Grid/customColumnsColDef'

export const formatBooleanToYesNo = (value) => (value ? 'Yes' : 'No')

export const getParamsForYesNo = () => ({
  cellRenderer: ({ value }) => formatBooleanToYesNo(value),
  tooltipValueGetter: ({ value }) => formatBooleanToYesNo(value),
})

export const clearFocusedCellOnSortChange = ({ api }) => {
  const focusedRowIndex = get(api.getFocusedCell(), 'rowIndex')
  const selectedRowIndex = get(api.getSelectedNodes(), [0, 'rowIndex'])

  if (focusedRowIndex !== selectedRowIndex) api.clearFocusedCell()
}

export const expandRowsByType = (gridApi, type) => () => {
  gridApi.forEachNode((node) => {
    node.setExpanded(node.data.type !== type)
  })
}

export const applyTextColumnFilterParams = ({
  withHeaderSearch,
  cellRendererParams = {},
  comparator = stringComparator,
  textMatcher = {},
  ...props
} = {}) => {
  return {
    filter: 'agTextColumnFilter',
    floatingFilterComponent: withHeaderSearch ? CustomSearch : null,
    comparator,
    filterParams: {
      filterOptions: FILTER_OPTIONS_ORDER,
      debounceMs: 700,
      caseSensitive: false,
      suppressAndOrCondition: true,
      comparator,
      ...(typeof textMatcher === 'function' ? { textMatcher } : {}),
      ...props,
    },
    cellRendererParams: {
      ...cellRendererParams,
      calculateColumnWidth: true,
    },
  }
}

export const textFilterParamsWithIncludedChildren = ({
   withHeaderSearch,
   cellRendererParams = {},
   comparator = stringComparator,
   ...props
 } = {}) => {
  const getParentData = (data, context) => {
    const parentKeyProcess = context.dayOneData.find(
      (item) => item.id === data.keyProcessId && item.type === 'keyProcess'
    )
    const parentProject = context.dayOneData.find(
      (item) => item.id === data.projectId && item.type === 'project'
    )
    return { parentKeyProcess, parentProject }
  };

  const checkFilter = (value, filterText, parentKeyProcess, parentProject, checkFunc) => {
    return (
      checkFunc(value, filterText) ||
      (parentKeyProcess?.name && checkFunc(parentKeyProcess.name.toLowerCase(), filterText)) ||
      (parentProject?.name && checkFunc(parentProject.name.toLowerCase(), filterText))
    )
  }

  const textMatcher = (params) => {
    const { filterText, filterOption, value, data, context } = params
    if (filterText == null) return false

    const { parentKeyProcess, parentProject } = getParentData(data, context)
    const lowerFilterText = filterText.toLowerCase()

    switch (filterOption) {
      case 'contains':
        return checkFilter(value, lowerFilterText, parentKeyProcess, parentProject, (a, b) => a.includes(b))
      case 'notContains':
        return !checkFilter(value, lowerFilterText, parentKeyProcess, parentProject, (a, b) => a.includes(b))
      case 'equals':
        return checkFilter(value, lowerFilterText, parentKeyProcess, parentProject, (a, b) => a === b)
      case 'notEqual':
        return !checkFilter(value, lowerFilterText, parentKeyProcess, parentProject, (a, b) => a === b)
      case 'startsWith':
        return checkFilter(value, lowerFilterText, parentKeyProcess, parentProject, (a, b) => a.startsWith(b))
      case 'endsWith':
        return checkFilter(value, lowerFilterText, parentKeyProcess, parentProject, (a, b) => a.endsWith(b))
      default:
        return false
    }
  }

  return {
    filter: 'agTextColumnFilter',
    floatingFilterComponent: withHeaderSearch ? CustomSearch : null,
    comparator,
    filterParams: {
      textMatcher,
      filterOptions: FILTER_OPTIONS_ORDER,
      suppressAndOrCondition: true,
      debounceMs: 700,
      caseSensitive: false,
      ...props,
    },
    cellRendererParams: {
      ...cellRendererParams,
      calculateColumnWidth: true,
    },
  }
};

export const applySetFilterParams = ({ cellRendererParams, ...props } = {}) => ({
  filter: 'agSetColumnFilter',
  comparator: stringComparator,
  filterParams: {
    filterOptions: FILTER_OPTIONS_ORDER,
    debounceMs: 700,
    caseSensitive: false,
    suppressAndOrCondition: true,
    comparator: stringComparator,
    ...props,
  },
  cellRendererParams: {
    ...cellRendererParams,
    calculateColumnWidth: true,
  },
})

export const applyNumberColumnFilterParams = (props) => ({
  filter: 'agNumberColumnFilter',
  filterParams: {
    filterOptions: FILTER_NUMBER_OPTIONS_ORDER,
    debounceMs: 700,
    suppressAndOrCondition: true,
    inRangeInclusive: true,
    numberParser: (text) => {
      const regexp = getNumberRegex('(,|.)', {}, null)

      return regexp.test(text) ? parseFloat(text) : null
    },
    ...props,
  },
})

export const getIndentClassName = ({ node }) => {
  const indent = getFp('level', node) || 0

  return `indent-${indent} ag-cell-tooltip-class-anchor`
}

export const getGridLastColumnIndex = ({ columnDefs, autoGroupColumnDef }) =>
  size(columnDefs) + (autoGroupColumnDef ? 1 : 0)

export const getGridApi = getFp(['current', 'gridApi'])
export const getColumnApi = getFp(['current', 'columnApi'])

export const getRowNodeIdDefault = (params) => params.data.id

export const getNodeLinesNumber = ({ node }) =>
  Math.floor(((get(node, 'rowHeight') || LINE_HEIGHT) - CELL_TOP_PADDING * 2 - ROW_BORDER * 2) / LINE_HEIGHT)

export const advancedFinancialsCustomColumnSetter = ({ newValue, oldValue, data, colDef, context }) => {
  if (newValue === oldValue) return

  const type = colDef.cellEditorParams.type

  // avoid saving empty values
  if (isFreeTextColumn(type) && isEmpty(newValue) && isEmpty(oldValue)) {
    return false
  }

  const customColumn = find({ id: colDef.customColumnId }, data.customColumns)
  const isCustomColumnExist = Boolean(customColumn)
  const optionLabel = flow([getFp(colDef.field), find({ value: newValue }), getFp('label')])(context)
  const adoptedNewValue = isFreeTextColumn(type) ? { id: null, name: newValue } : { id: newValue, name: optionLabel }

  if (isCustomColumnExist) {
    data.customColumns = data.customColumns.map((colData) =>
      colData.id === colDef.customColumnId
        ? {
            ...colData,
            option: newValue ? adoptedNewValue : null,
          }
        : colData,
    )

    return true
  }

  const customColumnId = flow([find({ name: colDef.field }), getFp(['id'])])(context.customColumns)
  const newCustomColumn = {
    columnName: colDef.field,
    id: customColumnId,
    option: newValue ? adoptedNewValue : null,
  }

  if (data.customColumns) {
    data.customColumns = [...data.customColumns, newCustomColumn]
  } else {
    data.customColumns = [newCustomColumn]
  }

  return true
}

export const disableIfNoSelected = ({ api }, setIsDisablesDelete) => {
  const selectedRows = api.getSelectedNodes()
  const selected = getOr(null, '0', selectedRows)

  if (isNil(selected)) {
    setIsDisablesDelete(true)
  }
}

const formatAgRichSelectValue = (value) => {
  if (!value || isNil(value.value)) return ''

  if (value.label === NOT_SELECTED_LABEL) return null

  return value.label
}

export const labelComparator = (v1, v2) => {
  const label1 = formatAgRichSelectValue(v1)
  const label2 = formatAgRichSelectValue(v2)

  return stringComparator(label1, label2)
}

export const basicRichSelectorValueFormatter = (params) => formatAgRichSelectValue(params.value)

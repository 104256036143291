import { PERMISSION_ACTION, teams } from '@common/accessController/constants'

export const TEAM_HOME_VIEW = 'team hove view'
export const RESTRICTED_VIEW = 'restricted view'

export const LOCK_PHASE_FIELDS = {
  IS_PHASE_1_LOCKED: 'isPhase1Locked',
  IS_PHASE_2_LOCKED: 'isPhase2Locked',
  IS_PHASE_3_LOCKED: 'isPhase3Locked',
  IS_PHASE_4_LOCKED: 'isPhase4Locked',
  IS_PHASE_5_LOCKED: 'isPhase5Locked',
  IS_ALL_LOCKED: 'isAllLocked',
}

export const itemFields = {
  CONFIDENTIAL: 'confidential',
  LOCKED: 'locked',
  CUSTOM: 'custom',
  EDIT_COMMUNICATIONS_GRID: 'edit communications grid',
  EDIT_AUDIENCE: 'edit audience',
  ADD_REQUEST: 'add request',
  VIEW_REQUEST: 'view request',
  EDIT_REQUEST: 'edit request',

  VIEW_COMMENTS: 'view comments',
  ADD_COMMENTS: 'add comments',

  LIBRARY: 'library',

  VALUE_CAPTURE: 'value capture',
  STAGE_GATE_REVIEW: 'stage gate review',
  STAGE_GATE_SUBMIT: 'stage gate submit',
  CONFIRM_DAY_ONE: 'confirmDayOne',
  ...LOCK_PHASE_FIELDS,
}

export const dataRequestTrackerPermissions = [
  PERMISSION_ACTION.REVIEW, //Mark item as approved
  PERMISSION_ACTION.ADD,
  PERMISSION_ACTION.DELETE,
  PERMISSION_ACTION.EDIT,
  itemFields.CONFIDENTIAL,
]

export const teamLogsPermissions = [PERMISSION_ACTION.EDIT, itemFields.CONFIDENTIAL]

export const teamContactsPermissions = [PERMISSION_ACTION.ADD, PERMISSION_ACTION.DELETE, PERMISSION_ACTION.EDIT]

export const communicationsPermissions = [
  itemFields.EDIT_COMMUNICATIONS_GRID, //Edit rows, columns, add events

  itemFields.ADD_REQUEST,
  itemFields.VIEW_REQUEST,
  itemFields.EDIT_REQUEST,

  PERMISSION_ACTION.EDIT,
  PERMISSION_ACTION.VIEW,
  PERMISSION_ACTION.DELETE,
]

export const PERMISSION_SETUP = {
  [teams.IMO_GLOBAL]: {
    [teams.IMO_GLOBAL]: PERMISSION_ACTION.EDIT,
    [teams.IMO]: PERMISSION_ACTION.EDIT,
    [teams.INTEGRATION]: PERMISSION_ACTION.VIEW,
    [teams.CENTRAL]: PERMISSION_ACTION.VIEW,
  },
  [teams.IMO]: {
    [teams.IMO_GLOBAL]: PERMISSION_ACTION.VIEW,
    [teams.IMO]: PERMISSION_ACTION.EDIT,
    [teams.INTEGRATION]: PERMISSION_ACTION.VIEW,
    [teams.CENTRAL]: PERMISSION_ACTION.VIEW,
  },
}

export enum TEAM_OD_ROLES {
  GlobalImoODMember = 'GlobalImoODMember',
  ResponsibleImoODMember = 'ResponsibleImoODMember',
  CentralODLead = 'CentralODLead',
  CentralODMember = 'CentralODMember',
  IntegrationODLead = 'IntegrationODLead',
  IntegrationODMember = 'IntegrationODMember',
}

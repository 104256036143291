import { MAX_INPUT_DECIMAL_COUNT } from '@helpers/constants'

export const noSpecialCharsRegex = /^[^#[\\\]<>]*$/gu

export const specialCharsRegex = /[<>[\\\]^#]/gu

export const emailRegexp = /([a-zA-Z0-9_.-`'])*@\w+([.-]?\w+)*(\.\w{2,3})+$/u

export const mcKinseyEmailRegexp = /.+@(|external\.)mckinsey\.com$/u

export const xlsRegExp = /.+(.xls|.xlsx)$/u

export const endSymbolRegexp = /(\.|!|\?|:|,|;)$/gu

export const floatRegex = /^-?\d*\.?\d+$/u
export const integerRegex = /^-?\d*$/u
export const negativeIntegerRegex = /^-\d*$/u
export const phoneNumberRegex = /^\+\d*$/u
export const positiveIntegerRegex = /^\d*$/u

export const integerRegexString = '\\d?|[1-9]\\d*'

export const getNumberRegex = (
  separator,
  { isWholeNumber, isPositiveNumber, isNegativeNumber } = {},
  decimalCount = MAX_INPUT_DECIMAL_COUNT,
) => {
  const decimalCountRegex = decimalCount ? `{0,${decimalCount}}` : '*'
  const separatorRegex = separator === '.' ? '\\.' : separator
  let baseRegex = isWholeNumber
    ? `(${integerRegexString}|0)$`
    : `(${integerRegexString})(${separatorRegex}?|${separatorRegex}\\d${decimalCountRegex})$`

  if (isNegativeNumber) {
    baseRegex = '^-' + baseRegex
  } else if (isPositiveNumber) {
    baseRegex = '^' + baseRegex
  } else {
    baseRegex = '^-?' + baseRegex
  }

  return new RegExp(baseRegex, 'u')
}

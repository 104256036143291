import styled from 'styled-components'
import { Icon, Colors } from '@blueprintjs/core'
import { defaultGrey } from '@imo/imo-ui-toolkit'

export const Content = styled.div<$TSFixMe>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  ${(props) => props.styles}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
`

export const Heading = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.31;
  margin-top: 11px;
`

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  margin: 23px 0 20px 0;
  color: ${Colors.DARK_GRAY5};
`

export const Separator = styled.hr`
  width: 363px;
  height: 1px;
  background-color: rgba(16, 22, 26, 0.15);
  margin: 16px 0 13px;
`

export const Description = styled.p`
  max-width: 385px;
  text-align: center;
  font-size: 14px;
`

export const DescriptionInfo = styled.p`
  max-width: 380px;
  text-align: center;
  font-size: 14px;
  color: ${Colors.DARK_GRAY5};
  margin: 0;

  &.full-width {
    max-width: max-content;
  }
`

export const HeaderIcon = styled(Icon)`
  color: ${defaultGrey};
`

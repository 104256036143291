import { startCase, capitalize, toLower } from 'lodash'

export const interdependencyStatuses = {
  REQUESTED: 'requested',
  REQUIRES_DISCUSSION: 'requires discussion',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
}

export const getInterdependencyStatuses = () => {
  return Object.values(interdependencyStatuses).map((status) => toLower(startCase(status)))
}

export const getStatusesList = () => {
  return Object.values(interdependencyStatuses).map((status, id) => {
    return {
      id,
      name: capitalize(startCase(status)),
      selected: false,
    }
  })
}

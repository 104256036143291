import * as constants from '@teamStatusTracker/actions/actionTypes'
import { actionTypes } from '../../utils'

export default {
  [constants.FETCH_TEAM_STATUS_TRACKER_DATA]: {
    [actionTypes.FAIL]: ['We were unable to load the team status tracker'],
  },
  [constants.UPDATE_IMO_TEAM_TOPICS]: {
    [actionTypes.FAIL]: ['We were unable to update the IMO team topics'],
  },
}

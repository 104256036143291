import { call, put } from 'redux-saga/effects'
import { mergeWith } from 'lodash/fp'
import { getInitiativeListV2Api } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { setFinancials, setFinancialsFail, updateFinancialsFail, updateFinancialsSuccess } from '../actions/actions'
import { fetchInitiative } from './initiativeList'
import { prepareFinancialsBody } from '../selectors/utils'
import { FINANCIALS_FIELD, FINANCIALS_FILTER } from '@helpers/financialsConstants'

// TODO: find another way to handle validation between financials margin subtype and revenue subtype
export const mutateFinancialsData = createSaga(function* mutateFinancialsData({
  payload,
  subtype,
  data,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getInitiativeListV2Api()
  const additionalData = yield call(
    api.request('getFinancialsData', {
      query: { ...payload, subtype2: subtype },
    }),
  )

  return Object.values(
    mergeWith((objValue, srcValue) => ({ ...srcValue, [FINANCIALS_FIELD.ADDITIONAL]: objValue }), additionalData, data),
  )
})

export const fetchFinancials = createSaga(function* fetchFinancials({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  try {
    const { subtype2 } = payload
    const api = yield getInitiativeListV2Api()
    let financialsData = yield call(
      api.request('getFinancialsData', {
        query: payload,
      }),
    )

    if (FINANCIALS_FILTER.MARGIN === subtype2) {
      financialsData = yield call(mutateFinancialsData, {
        payload,
        subtype: FINANCIALS_FILTER.REVENUE,
        data: financialsData,
      })
    }

    yield put(setFinancials({ financialsData }))
  } catch (e) {
    yield put(setFinancialsFail())
  }
})

export const updateFinancials = createSaga(function* updateFinancials({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  try {
    const { synergyType, id: initiativeId, teamId, data } = payload

    const api = yield getInitiativeListV2Api()
    const body = prepareFinancialsBody({ data })
    const financialItems = yield call(
      api.request('updateFinancialsData', {
        query: { teamId, id: initiativeId },
        body,
      }),
    )

    yield put(updateFinancialsSuccess({ financialItems }))

    yield call(fetchInitiative, {
      payload: {
        teamId,
        synergyType,
        initiativeId,
      },
    })
  } catch (e) {
    global.console.error('e', e)
    yield put(updateFinancialsFail())
  }
})

export const populateInitiative = createSaga(function* populateInitiative({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, id, synergyType, subtype1, subtype2, subtype3 } = payload
  const api = yield getInitiativeListV2Api()

  // TODO: update tests

  let financialsData = yield call(
    api.request('populateInitiative', {
      query: {
        teamId,
        id,
        synergyType,
        subtype1,
        subtype2,
        subtype3,
      },
    }),
  )

  if (FINANCIALS_FILTER.MARGIN === subtype2) {
    financialsData = yield call(mutateFinancialsData, {
      payload,
      subtype: FINANCIALS_FILTER.REVENUE,
      data: financialsData,
    })
  }

  yield put(setFinancials({ financialsData }))

  yield call(fetchInitiative, {
    payload: {
      teamId,
      synergyType,
      initiativeId: id,
    },
  })
})

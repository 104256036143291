import styled from 'styled-components'
import { paleGray } from '@imo/imo-ui-toolkit'

export const FilterContainer = styled.div`
  height: ${({ height }) => height || '144px'};
  overflow-y: auto;
`

export const StyledFilterItem = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 6px;
`
export const StyledFilterHeader = styled(StyledFilterItem)`
  font-weight: 500;

  &:not(:first-of-type) {
    border-top: 1px solid ${paleGray};
  }
`

import * as constants from '@teamCharter/actions/actionTypes'
import { actionTypes } from '../../utils'

export default {
  [constants.FETCH_TEAM_CHARTER_DATA]: {
    [actionTypes.FAIL]: ['We were unable to load the Team Charter', 14],
  },
  [constants.UPDATE_TEAM_CHARTER_DATA]: {
    [actionTypes.FAIL]: ['We were unable to save the Team Charter', 19],
  },
  [constants.DELETE_OUT_SCOPE_KEY_PROCESS]: {
    [actionTypes.FAIL]: ['We were unable to delete the out-of-scope key process', 11],
  },
  [constants.FETCH_TEAM_CHARTER_LIBRARY_DATA]: {
    [actionTypes.FAIL]: ['We were unable to load the McKinsey Library', 9],
  },
  [constants.FETCH_TEAM_MEMBERS]: {
    [actionTypes.FAIL]: ['We were unable to load the list of team members', 15],
  },
  [constants.SAVE_TEAM_MEMBERS]: {
    [actionTypes.FAIL]: ['We were unable to save the list of team members', 16],
    [actionTypes.VALIDATION_FAIL]: ['We were unable to save the list of team members'],
  },
}

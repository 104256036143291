import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class TaxonomyApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
  }

  createTaxonomy = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: '/taxonomy/bulk' },
      {
        method: 'PUT',
        body,
      },
    )
  }

  getTaxonomy = () => {
    return this.call({ pathname: '/taxonomy' })
  }
}

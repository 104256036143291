import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class TeamDeliverablesApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
  }

  createTeamDeliverables = ({ body }: $TSFixMe) => {
    return this.call(
      { pathname: '/team-deliverable/bulk' },
      {
        method: 'PUT',
        body,
      },
    )
  }

  getTeamDeliverables = () => {
    return this.call({ pathname: '/team-deliverable' })
  }
}

import { find } from 'lodash/fp'
import { handleActions } from 'redux-actions'
import * as constants from '@teamValueCaptureV2/statusUpdate/actions/actionTypes'
import { getMappedCurrentStatusData } from '@teamValueCaptureV2/statusUpdate/utils'

export interface IStatusUpdateState {
  year5PipelineSummary: {
    L1: $TSFixMe
    L2: $TSFixMe
    L3: $TSFixMe
    L4: $TSFixMe
    L5: $TSFixMe
    totalInitiativesPipelineSummary: $TSFixMe
    totalTargetSummary: $TSFixMe
  }
  currentVCStatus: {
    [key: string]: $TSFixMe
    actions?: $TSFixMe[]
    keyUpdates?: $TSFixMe[]
    supportDependencies?: $TSFixMe[]
  }
  lastWeekVCStatus: $TSFixMe
  yearlyImpactChart?: {
    targets: $TSFixMe[]
    runRates: $TSFixMe[]
  }
  costsCategories?: $TSFixMe[]
  statusUpdateOneTimeCost?: $TSFixMe[]
}

export const initialState = {
  year5PipelineSummary: {
    L1: {},
    L2: {},
    L3: {},
    L4: {},
    L5: {},
    totalInitiativesPipelineSummary: {},
    totalTargetSummary: {},
  },
  currentVCStatus: {
    actions: [],
    keyUpdates: [],
    supportDependencies: [],
  },
  lastWeekVCStatus: {},
  yearlyImpactChart: {
    targets: [],
    runRates: [],
  },
  costsCategories: [],
  statusUpdateOneTimeCost: [],
}

const statusUpdateReducer = handleActions<IStatusUpdateState, $TSFixMe>(
  {
    [constants.SET_YEAR_5_PIPELINE_SUMMARY]: (state, { payload: { year5PipelineSummary } }) => {
      return { ...state, year5PipelineSummary }
    },

    [constants.SET_STAGE_GATE_CALC_TYPE]: (state, { payload: { valueCaptureStatuses } }) => {
      const currentVCStatus = find({ type: 'current' }, valueCaptureStatuses)
      const lastWeekVCStatus = find({ type: 'lastWeek' }, valueCaptureStatuses) || {}

      return {
        ...state,
        currentVCStatus: getMappedCurrentStatusData(currentVCStatus),
        lastWeekVCStatus,
      }
    },

    [constants.UPDATE_VALUE_CAPTURE_STATUS]: (state, { payload: { data } }) => {
      const { currentVCStatus } = state

      return {
        ...state,
        currentVCStatus: {
          ...currentVCStatus,
          ...data,
        },
      }
    },

    [constants.REMOVE_VALUE_CAPTURE_STATUS]: (state, { payload: { rowId, fieldName } }) => {
      const { currentVCStatus } = state
      const currentUpdates = [...currentVCStatus[fieldName]] || []

      return {
        ...state,
        currentVCStatus: {
          ...currentVCStatus,
          [fieldName]: currentUpdates.filter(({ id }) => id !== rowId),
        },
      }
    },

    [constants.ADD_VALUE_CAPTURE_STATUS]: (state, { payload: { newRow, fieldName } }) => {
      const { currentVCStatus } = state

      const currentUpdates = currentVCStatus[fieldName] ? [...currentVCStatus[fieldName], newRow] : [newRow]

      return {
        ...state,
        currentVCStatus: {
          ...currentVCStatus,
          [fieldName]: currentUpdates,
        },
      }
    },

    [constants.SET_YEAR_IMPACT_CHART]: (state, action) => {
      const { yearlyImpactChart } = action.payload

      return { ...state, yearlyImpactChart }
    },

    [constants.SET_STATUS_UPDATE_ONE_TIME_COST]: (state, { payload: { statusUpdateOneTimeCost } }) => {
      return { ...state, statusUpdateOneTimeCost }
    },
  },
  initialState,
)

export default statusUpdateReducer

import { omit } from 'lodash/fp'
import { transformFromFlatAssumption } from '@myImoClient/components/Deliverables/TeamCharter/AddLibraryPopup/utils'

export const getTeamMembersRequestQuery = (isNewRow: boolean, teamId: string, rowId: string) =>
  isNewRow
    ? {
        teamId,
      }
    : {
        teamId,
        rowId,
      }

export const formatTeamMembersRequestBody = (teamMemberRow: $TSFixMe) => {
  const { userA, userB, topic } = teamMemberRow
  const updatedData = {} as { topic?: $TSFixMe; userA?: $TSFixMe; userB?: $TSFixMe }

  if (topic) {
    updatedData.topic = topic
  }

  if (userA?.name) {
    updatedData.userA = userA.id ? { id: userA.id } : { name: userA.name }
  }

  if (userB?.name) {
    updatedData.userB = userB.id ? { id: userB.id } : { name: userB.name }
  }

  return updatedData
}

export const getMacroPlanningAssumptionsData = (macroPlanningAssumption: $TSFixMe[]) => {
  return macroPlanningAssumption.reduce((acc, planning) => {
    const { macroPlanningItems, ...restProps } = planning

    acc.push({
      hierarchy: [restProps.name],
      ...restProps,
    })

    macroPlanningItems.forEach((macroPlanningItem: $TSFixMe) => {
      acc.push({
        hierarchy: [restProps.name, macroPlanningItem.name],
        ...macroPlanningItem,
      })
    })

    return acc
  }, [])
}

export const updateKeyProcessData = (state: $TSFixMe[], data: $TSFixMe, id: $TSFixMe) => {
  return state.map((item) => (item.id === id ? { ...item, ...data } : item))
}

export const updateKeyProcessIdData = (state: $TSFixMe[], data: $TSFixMe, keyProcessId: $TSFixMe) => {
  return state.map((item) => (item.keyProcessId === keyProcessId ? { ...item, ...data } : item))
}

const librariesDisableMapper = (lib: $TSFixMe) => ({ ...lib, disabled: lib.selected })
const librariesOmitMapper = (lib: $TSFixMe) => omit('disabled', lib)

// TODO: refactor this
export const makeItemsDisabled = (data: $TSFixMe) => {
  const vaultLibsWithDisable = {
    assumptions: [],
  } as { assumptions: $TSFixMe; [key: string]: string }

  Object.keys(data).forEach((key) => {
    vaultLibsWithDisable[key] = key === 'meta' ? data[key] : data[key].map(librariesDisableMapper)
  })

  return vaultLibsWithDisable
}

// TODO: refactor this
export const getOmittedData = (libs: $TSFixMe) => {
  const vaultLibsWithoutDisable = {
    assumptions: [],
  } as { assumptions: $TSFixMe; [key: string]: string }

  Object.keys(libs).forEach((key) => {
    if (key === 'assumptions') {
      vaultLibsWithoutDisable.assumptions = transformFromFlatAssumption(libs[key])
    } else {
      vaultLibsWithoutDisable[key] = key === 'meta' ? libs[key] : libs[key].map(librariesOmitMapper)
    }
  })

  return vaultLibsWithoutDisable
}

export const teamCharterTypeKeys = {
  risks: 'risks',
  decisions: 'decisions',
  assumptions: 'assumptions',
  inScopeProcesses: 'inScopeProcesses',
  outScopeProcesses: 'outScopeProcesses',
  keyInterdependencies: 'keyInterdependencies',
}

import { handleActions } from 'redux-actions'
import * as constants from '@teamValueCaptureV2/advancedFinancials/actions/actionTypes'
import { loadingState } from '@imo/imo-ui-toolkit/dist/helpers/constants'

export interface IAdvancedFinancialsState {
  advancedFinancials: $TSFixMe[]
  isAdvancedFinancialsOpened: boolean
  advancedFinancialsLoading: boolean
}

export const initialState = {
  advancedFinancials: [],
  isAdvancedFinancialsOpened: false,
  advancedFinancialsLoading: false,
}

const advancedFinancialsReducer = handleActions<IAdvancedFinancialsState, $TSFixMe>(
  {
    [constants.SET_ADVANCED_FINANCIALS]: (state, { payload: advancedFinancials }) => {
      return { ...state, advancedFinancials }
    },
    [constants.SET_IS_ADVANCED_FINANCIALS_OPENED]: (state, { payload: isAdvancedFinancialsOpened }) => {
      return { ...state, isAdvancedFinancialsOpened }
    },
    [constants.CREATE_ADVANCED_FINANCIALS_SUCCESS]: (state, { payload: financial }) => {
      const result = [...state.advancedFinancials, financial]

      return {
        ...state,
        advancedFinancials: result,
      }
    },
    [constants.DELETE_ADVANCED_FINANCIALS_SUCCESS]: (state, action) => {
      const { id } = action.payload
      const result = state.advancedFinancials.filter((financial) => financial.id !== id)

      return {
        ...state,
        advancedFinancials: result,
      }
    },
    [constants.ADVANCED_FINANCIALS_LOADER]: (state, action) => {
      return {
        ...state,
        advancedFinancialsLoading: action.payload === loadingState.LOADING,
      }
    },
  },
  initialState,
)

export default advancedFinancialsReducer

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@functionalActivity/actions/actionTypes'
import { fetchKeyProcesses, createKeyProcess, updateKeyProcess, deleteKeyProcess } from './functionalActivity'

export default function* functionalActivitySaga() {
  yield all([
    takeLatest(constants.FETCH_KEY_PROCESSES, fetchKeyProcesses),
    takeLatest(constants.CREATE_KEY_PROCESS, createKeyProcess),
    takeLatest(constants.UPDATE_KEY_PROCESS, updateKeyProcess),
    takeLatest(constants.DELETE_KEY_PROCESS, deleteKeyProcess),
  ])
}

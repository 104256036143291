import * as Yup from 'yup'
import { isEqual } from 'lodash/fp'

export const getSubProcessesSchema = (TSAOptions = []) =>
  Yup.object().shape({
    name: Yup.string().when(['isNew'], {
      is: (isNew) => !!isNew,
      then: Yup.string().nullable().max(400),
      otherwise: Yup.string().nullable().required('Name is required').max(400),
    }),
    complete: Yup.bool().nullable(),
    owners: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().nullable(),
          displayName: Yup.string().nullable(),
        }),
      )
      .nullable(),
    companyOne: Yup.string().max(10000).nullable(),
    companyTwo: Yup.string().max(10000).nullable(),
    entanglement: Yup.mixed().oneOf(['high', 'low', 'none', null]),
    entanglementDescription: Yup.string().max(10000).nullable(),
    dayOneSolution: Yup.mixed().oneOf([
      'Buyer responsible',
      'TSA',
      'Separate',
      'Build/outsource',
      'Not required',
      'Not entangled',
      null,
    ]),
    changingDayOne: Yup.bool().nullable(),
    tsa: Yup.mixed().oneOf([...TSAOptions.map(({ value }) => value), true, false, null]),
    dayOneAssumptions: Yup.string().max(10000).nullable(),
    postCloseAssumptions: Yup.string().max(10000).nullable(),
  })

export const MutualDiscoverySubmitSchema = Yup.array().of(
  Yup.object().shape({
    complete: Yup.mixed().when('keyProcessId', {
      is: (v) => !v,
      then: Yup.bool().nullable().oneOf([true], 'Process should be marked as completed'),
      otherwise: Yup.mixed(),
    }),
    dayOneAssumptions: Yup.string().when('changingDayOne', {
      is: (changingOnDayOne) => !!changingOnDayOne,
      then: Yup.string().nullable().required('If the Changing on Day 1 is Yes, this field should be filled'),
      otherwise: Yup.string().nullable(),
    }),
    tsa: Yup.mixed().when(['entanglement', 'keyProcessId', '$isSMO'], {
      is: (entanglement, keyProcessId, isSMO) => (isSMO ? false : entanglement && !keyProcessId),
      then: Yup.mixed()
        .nullable()
        .required('If the Entanglement is “None”, “Low” or “High”, this field should be filled'),
      otherwise: Yup.string().nullable(),
    }),
  }),
)

export const EntanglementSubmitSchema = Yup.array().of(
  Yup.object().shape({
    complete: Yup.mixed().when('keyProcessId', {
      is: (v) => !v,
      then: Yup.bool().nullable().oneOf([true], 'Process should be marked as completed'),
      otherwise: Yup.mixed(),
    }),
  }),
)

const getDataToCompare = ({ name, keyProcess }) => ({
  name,
  keyProcessName: keyProcess?.name,
})

function validateSelectedProcess() {
  const { path, options, parent } = this
  const { list } = options.context
  const itemWithSameName = list.some((item) => isEqual(getDataToCompare(item), getDataToCompare(parent)))

  if (!parent.disabled && parent.selected && itemWithSameName) {
    return this.createError({ path, message: itemWithSameName.name, params: { data: parent } })
  }

  return true
}

export const ImportLibraryMDPASchema = Yup.array().of(
  Yup.object().shape({
    name: Yup.mixed().nullable().test('checkSelectedProcess', validateSelectedProcess),
  }),
)

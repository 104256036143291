import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class ProjectWatchersApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  watchProject = ({ query }: $TSFixMe) => {
    const { teamId, entityId } = query

    return this.call(
      { pathname: `/teams/${teamId}/team-key-process-v2/${entityId}/projects/${entityId}/watch` },
      { method: 'POST' },
    )
  }

  unwatchProject = ({ query }: $TSFixMe) => {
    const { teamId, entityId } = query

    return this.call(
      { pathname: `/teams/${teamId}/team-key-process-v2/${entityId}/projects/${entityId}/unwatch` },
      { method: 'POST' },
    )
  }

  fetchProjectWatchers = ({ query }: $TSFixMe) => {
    const { teamId, keyProcessId, keyProcessProjectId } = query

    return this.call({
      pathname: `/teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/watchers`,
    })
  }
}

import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@controlPanel/actions/actionTypes'
import { fetchControlPanelData, fetchMetricData, updateTaskMetricData } from './controlPanelSagas'

export default function* controlPanelSaga() {
  yield all([
    takeLatest(constants.FETCH_CONTROL_PANEL_DATA, fetchControlPanelData),
    takeLatest(constants.FETCH_METRIC_DATA, fetchMetricData),
    takeLatest(constants.UPDATE_TASK_METRIC_DATA, updateTaskMetricData),
  ])
}

import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as constants from '../actions/actionTypes'
import { getProjectMapApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import {
  linkProjectMapItemsSuccess,
  setProjectMap,
  unlinkProjectMapItemsSuccess,
} from '@teamValueCaptureV2/projectMap/actions/actions'

export const fetchProjectMapItems = createSaga(function* fetchProjectMapItems({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload

  const api = yield getProjectMapApi()
  const data = yield call(
    api.request('getProjectMapItems', {
      query: {
        teamId,
      },
    }),
  )

  yield put(setProjectMap({ data }))
})

export const linkProjectMapItem = createSaga(function* linkProjectMapItem({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, sourceType, sourceId, linkedItemType, linkedItemId, shouldRemovePrevious } = payload

  yield put(linkProjectMapItemsSuccess({ sourceType, sourceId, linkedItemType, linkedItemId, shouldRemovePrevious }))

  const api = yield getProjectMapApi()
  yield call(
    api.request('linkProjectMapItem', {
      query: {
        teamId,
      },
      body: {
        [`${sourceType}Id`]: sourceId,
        [`${linkedItemType}Id`]: linkedItemId,
      },
    }),
  )
})

export const unlinkProjectMapItem = createSaga(function* unlinkProjectMapItem({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, linkedItemType, linkedItemId, shouldRemovePrevious, shouldRemoveParent } = payload

  yield put(unlinkProjectMapItemsSuccess({ linkedItemType, linkedItemId, shouldRemovePrevious, shouldRemoveParent }))

  const api = yield getProjectMapApi()
  yield call(
    api.request('unlinkProjectMapItem', {
      query: {
        teamId,
      },
      body: {
        [`${linkedItemType}Id`]: linkedItemId,
      },
    }),
  )
})

export default function* projectMapSaga() {
  yield all([
    takeLatest(constants.FETCH_PROJECT_MAP, fetchProjectMapItems),
    takeLatest(constants.LINK_ITEM, linkProjectMapItem),
    takeLatest(constants.UNLINK_ITEM, unlinkProjectMapItem),
  ])
}

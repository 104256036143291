import { call } from 'redux-saga/effects'
import { getDeliverablesApi } from '@common/net'

export function* fetchComments(
  teamId: string,
  deliverableId: string,
  offset: number,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getDeliverablesApi()

  return yield call(
    api.request('getActionComments', {
      query: { teamId, deliverableId, offset },
    }),
  )
}

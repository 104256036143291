import { put, all } from 'redux-saga/effects'
import {
  fetchWhiteboardWithLoader,
  fetchTeamActionsWithLoader,
  fetchDecisionsLogWithLoader,
  fetchRisksLogWithLoader,
  fetchCalendarWithLoader,
} from '@teamWorkspace/actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchInitialData = createSaga(function* fetchInitialData({ payload }: $TSFixMe) {
  yield all([
    put(fetchTeamActionsWithLoader(payload)),
    put(fetchWhiteboardWithLoader(payload)),
    put(fetchDecisionsLogWithLoader(payload)),
    put(fetchCalendarWithLoader(payload)),
    put(fetchRisksLogWithLoader(payload)),
  ])
})

const prefix = '@taskWatchers/'

// api calls
export const FETCH_TASK_WATCHERS = `${prefix}FETCH_TASK_WATCHERS`
export const WATCH_TASK = `${prefix}WATCH_TASK`
export const UNWATCH_TASK = `${prefix}UNWATCH_TASK`

export const FETCH_INTERDEPENDENT_TASK_WATCHERS = `${prefix}FETCH_INTERDEPENDENT_TASK_WATCHERS`
export const WATCH_INTERDEPENDENT_TASK = `${prefix}WATCH_INTERDEPENDENT_TASK`
export const UNWATCH_INTERDEPENDENT_TASK = `${prefix}UNWATCH_INTERDEPENDENT_TASK`

// store updates
export const SET_TASK_WATCHERS = `${prefix}SET_TASK_WATCHERS`
export const ADD_TASK_WATCHER = `${prefix}ADD_TASK_WATCHER`
export const REMOVE_TASK_WATCHER = `${prefix}REMOVE_TASK_WATCHER`

import { teams, teamTitles } from '@common/accessController/constants'
import * as teamConstants from '@generic/selectors/teamConstants'
import { isValueCaptureTeam } from './utils'
import { flow, orderBy, uniqBy, size, get, getOr } from 'lodash/fp'
import { teamConnections } from '@generic/selectors/teamConstants'

export const buildReporterTeamState = ({ id, connection }: $TSFixMe, parentTeam: $TSFixMe, allTeams: $TSFixMe) => {
  const reporterTeam = allTeams?.find((team: $TSFixMe) => team?.id === id) || {}

  const parents = [...(parentTeam.parents || []), { ...parentTeam, connection }]

  return {
    ...reporterTeam,
    connection,
    parents,
    parentLevel: parents?.length,
    parentRole: get([0, 'role'], parents),
    role: null,
    team: reporterTeam,
  }
}

export const getTeamReporters = (team: $TSFixMe, allTeams: $TSFixMe): $TSFixMe => {
  if (!Array.isArray(team?.reporterIds) && !Array.isArray(team?.secondaryReporterIds)) {
    return []
  }

  const primarySecondaryReporters = [
    ...team.reporterIds
      .map((id: $TSFixMe) => buildReporterTeamState({ id, connection: teamConnections.PRIMARY }, team, allTeams))
      .filter(({ responsibleIMOId }: $TSFixMe) => responsibleIMOId === team.id),
    ...team.secondaryReporterIds.map((id: $TSFixMe) =>
      buildReporterTeamState({ id, connection: teamConnections.SECONDARY }, team, allTeams),
    ),
  ]

  let currentReporters = [...primarySecondaryReporters]

  for (const reporter of primarySecondaryReporters) {
    if (reporter?.id === team?.id) continue

    const newReporters = getTeamReporters(reporter, allTeams)

    currentReporters = [...currentReporters, ...newReporters]
  }

  return uniqBy('id')(currentReporters)
}

export const getUserTeams = (allTeams: $TSFixMe) => {
  return allTeams.filter((team: $TSFixMe) => team?.isAssignedToTeam)
}

export const getReporters = (allTeams: $TSFixMe) => {
  const userTeams = getUserTeams(allTeams)

  const allReporters = userTeams.reduce(
    (result: $TSFixMe, userTeam: $TSFixMe) => {
      const currentReporters = getTeamReporters(userTeam, allTeams)
      result.push(...currentReporters)

      return result
    },
    [...userTeams],
  )

  return flow(orderBy('connection', 'asc'), uniqBy('id'))(allReporters)
}

export const isResponsibleTeam = (parentTeamId: $TSFixMe) => (team: $TSFixMe) =>
  parentTeamId === team?.responsibleIMOId &&
  [...teamConstants.INTEGRATION_ROLES, teams.CENTRAL].includes(team?.teamType)

export const getTeamTree = (allReporters: $TSFixMe) => {
  return allReporters?.reduce(
    (result: $TSFixMe, value: $TSFixMe) => {
      if (teamConstants.ALL_IMO_ROLES.includes(value?.teamType)) {
        const responsibleTeams = allReporters?.filter(isResponsibleTeam(value.id))

        if (size(responsibleTeams) && value?.longName) {
          result[teams.REPORTER][value.longName] = responsibleTeams
        }

        result[teams.IMO].push(value)
      }

      if ([...teamConstants.INTEGRATION_ROLES, teams.CENTRAL].includes(value?.teamType)) {
        const responsibleImo = value?.responsibleIMOLongName
        const imoResponsibleTeams = getOr([], [teams.REPORTER, responsibleImo], result)

        if (responsibleImo && !imoResponsibleTeams.includes(value)) {
          result[teams.REPORTER][responsibleImo] = [...imoResponsibleTeams, value]
        }
      }

      if (isValueCaptureTeam(value)) {
        result[teamTitles.CENTRAL_VC].push(value)
      }

      return result
    },
    { [teams.REPORTER]: {}, [teams.IMO]: [], [teamTitles.CENTRAL_VC]: [] },
  )
}

import React from 'react'
import { getCompanyLogos } from '@domain/generic/selectors'
import { getManagementType } from '@domain/instanceConfig/selectors'
import { useSelector } from 'react-redux'
import LogoContainer from '../../myImoClient/components/Header/LogoContainer'
import styled from 'styled-components'
import { BreadcrumbType, Breadcrumbs } from './Breadcrumbs'
import { BreadcrumbTeamSelector } from './BreadcrumbTeamSelector'
import { Colors } from '@blueprintjs/core'
import AppliedFilters from '../../myImoClient/components/Header/AppliedFilters'
import { getSelectedCentralTeamId } from '@generic/selectors/selectedTeams'
import { VCControllerMenu } from '@myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/TeamValueCaptureController'

const BreadcrumbsHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  gap: 5px;
`

const BreadcrumbsHeaderContainer = styled.div`
  display: flex;
  padding: 0px 15px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  max-height: 40px;
  border-bottom: 1px solid ${Colors.LIGHT_GRAY1};
`

export const BreadcrumbsHeader = ({
  showTeamSelect = true,
  withoutCrumbs = [],
  appendCrumbs = [],
  withVCFilterMenu = false,
}: {
  showTeamSelect?: boolean
  withoutCrumbs?: string[]
  appendCrumbs?: (BreadcrumbType & { url: string })[]
  withVCFilterMenu?: boolean
}) => {
  const companiesLogo = useSelector(getCompanyLogos)
  const managementType = useSelector(getManagementType)
  const selectedCentralTeamId = useSelector(getSelectedCentralTeamId)

  return (
    <BreadcrumbsHeaderContainer>
      <BreadcrumbsHeaderLeft>
        <Breadcrumbs withoutCrumbs={withoutCrumbs} appendCrumbs={appendCrumbs} />
        {showTeamSelect && <BreadcrumbTeamSelector />}
        {withVCFilterMenu ? (
          <VCControllerMenu teamId={selectedCentralTeamId} isVCTracker>
            <AppliedFilters />
          </VCControllerMenu>
        ) : (
          <AppliedFilters />
        )}
      </BreadcrumbsHeaderLeft>
      <LogoContainer sources={companiesLogo} managementType={managementType} />
    </BreadcrumbsHeaderContainer>
  )
}

import React from 'react'
import { ImoIcon } from '@imo/imo-ui-toolkit'
import { RouteObject } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getManagementType } from '@root/src/domain/instanceConfig/selectors'

export const ImoMasterplanCrumbTitle = () => {
  const managementType = useSelector(getManagementType)
  return <>{managementType ?? 'IMO'} Masterplan</>
}

export const teamResourcesRouter: RouteObject[] = [
  {
    path: '/team-resources',
    lazy: async () => {
      const { TeamResourcesWithAccessControl } = await import(
        /* webpackChunkName: "team-resources" */
        /* webpackPrefetch: true */
        '@views/myImoClient/pages/TeamResources'
      )
      return { element: <TeamResourcesWithAccessControl /> }
    },
    handle: { crumb: { title: 'Team Resources', icon: <ImoIcon icon="team-resources" size={16} /> } },
    children: [
      {
        path: '',
        lazy: async () => {
          const { ResourcesHome } = await import(
            /* webpackChunkName: "team-resources" */
            '@views/myImoClient/pages/TeamResources/ResourcesHome'
          )
          return { element: <ResourcesHome /> }
        },
      },
      {
        path: 'masterplan',
        handle: { crumb: { title: <ImoMasterplanCrumbTitle /> } },
        lazy: async () => {
          const { Masterplan } = await import(
            /* webpackChunkName: "team-resources" */
            '@views/myImoClient/pages/TeamResources/Masterplan'
          )
          return { element: <Masterplan /> }
        },
      },
      {
        path: 'interdependency',
        handle: { crumb: { title: 'Interdependencies Summary' } },
        lazy: async () => {
          const { InterdependenciesNav } = await import(
            /* webpackChunkName: "team-resources" */
            '@views/myImoClient/pages/TeamResources/Interdependencies/Interdependencies'
          )
          return { element: <InterdependenciesNav /> }
        },
        children: [
          {
            path: 'matrix',
            lazy: async () => {
              const { Matrix } = await import(
                /* webpackChunkName: "team-resources" */
                '@views/myImoClient/pages/TeamResources/Interdependencies/Matrix/Matrix'
              )
              return { element: <Matrix /> }
            },
          },
          {
            path: 'status',
            lazy: async () => {
              const { InterdependencyStatus } = await import(
                /* webpackChunkName: "team-resources" */
                '@views/myImoClient/pages/TeamResources/Interdependencies/Status/InterdependencyStatus'
              )
              return { element: <InterdependencyStatus /> }
            },
            handle: { crumb: { title: 'Interdependency Status' } },
          },
        ],
      },
      {
        path: 'team-structure-and-contacts',
        lazy: async () => {
          const { TeamStructureAndContactsNav } = await import(
            /* webpackChunkName: "team-resources" */
            '@views/myImoClient/pages/TeamResources/TeamStructureAndContacts'
          )
          return { element: <TeamStructureAndContactsNav /> }
        },
        handle: { crumb: { title: 'Team Structure & Contacts' } },
        children: [
          {
            path: 'structure',
            lazy: async () => {
              const { TeamStructure } = await import(
                /* webpackChunkName: "team-resources" */
                '@views/myImoClient/pages/TeamResources/TeamStructureAndContacts/TeamStructure'
              )
              return { element: <TeamStructure /> }
            },
          },
          {
            path: 'contacts',
            lazy: async () => {
              const { TeamContacts } = await import(
                /* webpackChunkName: "team-resources" */
                '@views/myImoClient/pages/TeamResources/TeamStructureAndContacts/TeamContacts'
              )
              return { element: <TeamContacts /> }
            },
            handle: { crumb: { title: 'Team Contacts' } },
          },
        ],
      },
      {
        path: 'scope-and-members',
        lazy: async () => {
          const { ScopeTeamMembers } = await import(
            /* webpackChunkName: "team-resources" */
            '@views/myImoClient/pages/TeamResources/ScopeTeamMembers'
          )
          return { element: <ScopeTeamMembers /> }
        },
        handle: { crumb: { title: 'Scope and Team Members' } },
      },
      {
        path: 'planning-assumptions-report',
        lazy: async () => {
          const { PlanningAssumptionsReport } = await import(
            /* webpackChunkName: "team-resources" */
            '@views/myImoClient/pages/TeamResources/PlanningAssumptionsReport'
          )
          return { element: <PlanningAssumptionsReport /> }
        },
        handle: { crumb: { title: 'Planning Assumptions Report' } },
      },
      {
        path: 'data-request-tracker',
        handle: { crumb: { title: 'Data Request Tracker' } },
        lazy: async () => {
          const { DataRequestTrackerNav } = await import(
            /* webpackChunkName: "team-resources" */
            '@views/myImoClient/pages/TeamResources/DataRequestTracker'
          )
          return { element: <DataRequestTrackerNav /> }
        },
        children: [
          {
            path: 'master-tracker',
            lazy: async () => {
              const { MasterTracker } = await import(
                /* webpackChunkName: "team-resources" */
                '@views/myImoClient/pages/TeamResources/DataRequestTracker/MasterTracker'
              )
              return { element: <MasterTracker /> }
            },
          },
          {
            path: 'status-report',
            lazy: async () => {
              const { StatusReport } = await import(
                /* webpackChunkName: "team-resources" */
                '@views/myImoClient/pages/TeamResources/DataRequestTracker/StatusReport'
              )
              return { element: <StatusReport /> }
            },
            handle: { crumb: { title: 'Status Report' } },
          },
        ],
      },
      {
        path: 'full-project-plan',
        lazy: async () => {
          const { FullProjectPlan } = await import(
            /* webpackChunkName: "team-resources" */
            '@views/myImoClient/pages/TeamResources/FullProjectPlan'
          )
          return { element: <FullProjectPlan /> }
        },
        handle: { crumb: { title: 'Full Project Plan' } },
      },
      {
        path: 'decision-log',
        lazy: async () => {
          const { DecisionLog } = await import(
            /* webpackChunkName: "team-resources" */
            '@views/myImoClient/pages/TeamResources/DecisionLog'
          )
          return { element: <DecisionLog /> }
        },
        handle: { crumb: { title: 'Decision Log' } },
      },
      {
        path: 'risk-log',
        lazy: async () => {
          const { RiskLog } = await import(
            /* webpackChunkName: "team-resources" */
            '@views/myImoClient/pages/TeamResources/RiskLog'
          )
          return { element: <RiskLog /> }
        },
        handle: { crumb: { title: 'Risk Log' } },
      },
      {
        path: 'tsa-master-list',
        lazy: async () => {
          const { TeamResourcesTSA } = await import(
            /* webpackChunkName: "team-resources" */
            '@views/myImoClient/pages/TSAv2/TeamResourcesTSA/TeamResourcesTSA'
          )
          return { element: <TeamResourcesTSA /> }
        },
        handle: { crumb: { title: 'TSA Master List' } },
      },
      {
        path: 'scenario-report',
        lazy: async () => {
          const { DayOneScenarioReport } = await import(
            /* webpackChunkName: "team-resources" */
            '@views/myImoClient/pages/TeamResources/DayOneScenarioReport'
          )
          return { element: <DayOneScenarioReport /> }
        },
        handle: { crumb: { title: 'Day 1 Scenario Report' } },
      },
    ],
  },
]

import { createSaga } from '@common/sagaCreator/createSaga'
import { getDeliverablesApi } from '@common/net'
import { call, put } from 'redux-saga/effects'
import * as actions from '../actions/actions'

export const updateDeliverableMeta = createSaga(function* updateDeliverableMeta({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { body } = payload
  const api = yield getDeliverablesApi()
  yield call(
    api.request('updateTeamDeliverableMetaData', {
      query: { teamId: body.teamId, deliverableId: body.id },
      body: { meta: body.meta },
    }),
  )

  yield put(actions.updateDeliverableMetaSuccess({ meta: body.meta }))
})

import { createSaga } from '@common/sagaCreator/createSaga'
import { getMyTeamApi } from '@common/net'
import { all, call, put } from 'redux-saga/effects'
import { setKeyProcessDeleteDetails } from '@teamHome/actions/actions'
import { SET_KEY_PROCESS_DELETE_DETAILS_STATE } from '@teamHome/actions/actionTypes'

export const fetchKeyProcessDetails = function* fetchKeyProcessDetails(
  payload: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { ids, teamId, ...props } = payload
  const api = yield getMyTeamApi()

  const data = yield call(
    api.request('getKeyProcessV2DeleteDetails', {
      query: { ids, teamId },
    }),
  )

  return { ...data, teamId, ...props }
}

export const fetchAllTeamsKeyProcessDetails = createSaga(function* fetchAllTeamsKeyProcessDetails({
  payload: itemsToDelete,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const data = yield all(itemsToDelete.map(fetchKeyProcessDetails))

  yield put(setKeyProcessDeleteDetails(data))
},
SET_KEY_PROCESS_DELETE_DETAILS_STATE)

import { getDayOneApi } from '@common/net'
import { call, put } from 'redux-saga/effects'
import { setSnapshotMinDate } from '@dayOne/actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchSnapshotMinDate = createSaga(function* fetchSnapshotMinDate({
  payload: teamId,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getDayOneApi()
  const response = yield call(api.request('getSnapshotMinDate', { query: { teamId } }))
  yield put(setSnapshotMinDate(response?.minDate))
})

const prefix = '@centralFunctionalTSA/'

export const FETCH_CENTRAL_FUNCTIONAL_TSA = `${prefix}FETCH_CENTRAL_FUNCTIONAL_TSA`
export const SET_CENTRAL_FUNCTIONAL_TSA = `${prefix}SET_CENTRAL_FUNCTIONAL_TSA`

export const CREATE_CENTRAL_FUNCTIONAL_TSA = `${prefix}CREATE_CENTRAL_FUNCTIONAL_TSA`
export const CREATE_CENTRAL_FUNCTIONAL_TSA_SUCCESS = `${prefix}CREATE_CENTRAL_FUNCTIONAL_TSA_SUCCESS`

export const UPDATE_CENTRAL_FUNCTIONAL_TSA = `${prefix}UPDATE_CENTRAL_FUNCTIONAL_TSA`
export const UPDATE_CENTRAL_FUNCTIONAL_TSA_SUCCESS = `${prefix}UPDATE_CENTRAL_FUNCTIONAL_TSA_SUCCESS`

export const DELETE_CENTRAL_FUNCTIONAL_TSA = `${prefix}DELETE_CENTRAL_FUNCTIONAL_TSA`
export const DELETE_CENTRAL_FUNCTIONAL_TSA_SUCCESS = `${prefix}DELETE_CENTRAL_FUNCTIONAL_TSA_SUCCESS`

export const FETCH_REGIONAL_LOCAL_TSA = `${prefix}FETCH_REGIONAL_LOCAL_TSA`

export const FETCH_PROJECT_LIST = `${prefix}FETCH_PROJECT_LIST`
export const SET_PROJECT_LIST = `${prefix}SET_PROJECT_LIST`

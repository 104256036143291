/* eslint-disable no-unused-vars */
import { call } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getMutualDiscoveryApi } from '@common/net'
import { transformProcessFromLibraryToAdd } from '../utils'
import { fetchMutualDiscoveryDataWithLoader } from '../sagas/fetchMutualDiscoveryData'

export const addProcessesFromLibForMDPA = createSaga(function* createMDPAProcessFromLib({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { libraryId, teamId, data } = payload

  const api = yield getMutualDiscoveryApi()
  yield call(
    api.request('createProcessFromLib', {
      query: { teamId, libraryId },
      body: transformProcessFromLibraryToAdd(data),
    }),
  )

  yield call(fetchMutualDiscoveryDataWithLoader, { payload: { teamId } })
},
true)

import { put, call } from 'redux-saga/effects'
import { updateProcessSuccess } from '../actions/actions'
import { getMutualDiscoveryApi } from '@common/net'
import { omit } from 'lodash'
import { transformEmptyStringsToNull } from '@helpers/utils'
import { createSaga } from '@common/sagaCreator/createSaga'
import { delay } from 'redux-saga'

function* saveSubProcess({ teamId, data, keyProcessId, id }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const mutualDiscoveryApi = yield getMutualDiscoveryApi()
  const newData = yield call(
    mutualDiscoveryApi.request('createSubProcess', {
      body: transformEmptyStringsToNull(
        omit(data, ['id', 'isNew', 'keyProcessId', 'custom', 'keyProcess', 'processName', 'subProcessName']),
      ),
      query: { teamId, keyProcessId },
    }),
  )

  yield put(updateProcessSuccess({ id, keyProcessId, data: { id: newData.id, isNew: false } }))
}

function* saveKeyProcess({ data, id, teamId }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const mutualDiscoveryApi = yield getMutualDiscoveryApi()
  const newData = yield call(
    mutualDiscoveryApi.request('createKeyProcess', {
      body: transformEmptyStringsToNull(omit(data, ['id', 'isNew', 'custom', 'processName', 'subProcessName'])),
      query: { teamId },
    }),
  )

  yield put(updateProcessSuccess({ id, data: { id: newData.id, isNew: false } }))
}

export const saveProcess = createSaga(function* saveProcess({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { keyProcessId } = payload

  if (keyProcessId) {
    return yield call(saveSubProcess, payload)
  } else {
    yield call(saveKeyProcess, payload)
  }

  yield call(delay, 100)
},
true)

import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class SharedApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  updateCustomColumn = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `/dictionary/${query.columnId}/option`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }
}

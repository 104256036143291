import styled from 'styled-components'

export const StyledPaginator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 24px;

  nav {
    ul {
      list-style: none;
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 11px;
      background: var(--Status-Requested, #abb3bf);
      margin: 4px;
    }

    .dot-current {
      background: var(--Status-Completed, #4c90f0);
    }
  }
`

import React from 'react'

import { BackgroundSceneStyled, BackgroundUISceneStyled } from './index.styles'

const BackgroundScene = ({ children }: { children: React.ReactNode }) => {
  return (
    <BackgroundSceneStyled>
      <BackgroundUISceneStyled>{children}</BackgroundUISceneStyled>
    </BackgroundSceneStyled>
  )
}

export default BackgroundScene

export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const UPDATE_USERS = 'UPDATE_USERS'
export const SET_USERS = 'SET_USERS'
export const SEND_INVITE_EMAIL = 'SEND_INVITE_EMAIL'
export const SEND_RESET_EMAIL = 'SEND_RESET_EMAIL'
export const GET_WHO_LOCKED_DATA = 'GET_WHO_LOCKED_DATA'
export const SET_WHO_LOCKED_DATA = 'SET_WHO_LOCKED_DATA'
export const CLOSE_WHO_LOCKED_DATA = 'CLOSE_WHO_LOCKED_DATA'
export const SET_IS_VIEW_LOCKED = 'SET_IS_VIEW_LOCKED'
export const SET_LOCK = 'SET_LOCK'
export const SET_IS_READ_ONLY = 'SET_IS_READ_ONLY'

import React from 'react'
import { useSelector } from 'react-redux'
import { hasAnyTeams, getIsFirstTimeUser } from '@generic/selectors'
import MainContent from '@shared/MainContentContainer'
import { Outlet } from 'react-router-dom'

export const ControlTowerWrapper = () => {
  const hasTeams = useSelector(hasAnyTeams)
  const isFirstTimeUser = useSelector(getIsFirstTimeUser)

  if (!hasTeams && !isFirstTimeUser) return null

  return (
    <MainContent>
      <Outlet />
    </MainContent>
  )
}

import moment from 'moment'
import React from 'react'
import { get, toNumber } from 'lodash'
import { STAGE_GATE_IDS } from '@helpers/constants'
import { ColoredStatus, ErrorDescription } from '@myImoClient/components/TeamStatusUpdate/index.styles'
import { isL4PlannedDateNotMatchDueDate } from '@helpers/utils'
import { getInitiativeAlias } from '../../utils'
import { getFormattedDate } from '@shared/Grid/utils/dates'
import { size } from 'lodash/fp'
import { ProcessStatus, TooltipContent } from '@imo/imo-ui-toolkit'

const HOURS = 24

export const checkIsStageGateReady = (initiative, stageGateId) => get(initiative, ['stageGate', 'id']) >= stageGateId

export const isStageGateInRange = (initiative, stageGate1, stageGate2) =>
  checkIsStageGateReady(initiative, stageGate1) && get(initiative, ['stageGate', 'id']) <= stageGate2

export const isStageGateCompleted = (stageGate) =>
  [STAGE_GATE_IDS.L5_APPROVED, STAGE_GATE_IDS.L5_SUBMITTED].includes(stageGate)

export const getStageGateLevelById = (id) => {
  switch (id) {
    case STAGE_GATE_IDS.L1:
      return 'L1'
    case STAGE_GATE_IDS.L2_SUBMITTED:
      return 'L2'
    case STAGE_GATE_IDS.L2_APPROVED:
    case STAGE_GATE_IDS.L3_SUBMITTED:
      return 'L3'
    case STAGE_GATE_IDS.L3_APPROVED:
    case STAGE_GATE_IDS.L4_SUBMITTED:
      return 'L4'
    case STAGE_GATE_IDS.L4_APPROVED:
    case STAGE_GATE_IDS.L5_SUBMITTED:
    case STAGE_GATE_IDS.L5_APPROVED:
    case 10:
      return 'L5'
    default:
      return null
  }
}

export const checkIsLinkedProjectCompleted = (linkedProject) => {
  return linkedProject && linkedProject?.status === ProcessStatus.COMPLETED
}

export const getLinkedProjectValidationMessage = ({
  stageGateValue,
  linkedProject,
  plannedL4Date,
  isAdvancedFinancialsTurnedOn,
  isSubmit,
}) => {
  const isCompleted = checkIsLinkedProjectCompleted(linkedProject)
  const level = getStageGateLevelById(stageGateValue)
  const dueDate = get(linkedProject, ['dueDate'])

  if (isL4PlannedDateNotMatchDueDate(plannedL4Date, dueDate) && linkedProject && isAdvancedFinancialsTurnedOn) {
    return `The L4 date (plan) for this initiative and the linked project due date must match in order to progress to ${level}`
  }

  if (linkedProject && !isCompleted && stageGateValue === STAGE_GATE_IDS.L3_APPROVED && isSubmit) {
    return (
      <>
        This initiative must have a linked project that is{' '}
        <ColoredStatus status={ProcessStatus.COMPLETED}>{ProcessStatus.COMPLETED}</ColoredStatus> in order to progress
        to {level}
      </>
    )
  }

  if (!linkedProject && isSubmit) {
    return `This initiative must have a linked project in order to progress to ${level}`
  }

  return null
}

export const getApproveValidationMessage = ({ plannedL4Date, linkedProject, stageGateValue }) => {
  const dueDate = get(linkedProject, ['dueDate'])
  const isL2Submitted = stageGateValue === STAGE_GATE_IDS.L2_SUBMITTED

  if (!isL4PlannedDateNotMatchDueDate(plannedL4Date, dueDate) && isL2Submitted) {
    return (
      <>
        The detailed <b>Financials</b> for this initiative must be fully populated without conflicts before you can{' '}
        approve for L2
      </>
    )
  }

  return null
}

export const l2PopulationMessage = (synergyType) =>
  `You may no longer refresh the detailed financials after the ${getInitiativeAlias(
    synergyType,
  )} has been approved for L2`

export const stageGateStatuses = {
  PAST_DUE: 'PAST DUE',
  NON_SPECIFIED: 'Non specified',
  NOT_APPLICABLE: 'N/A',
  COMPLETED: 'Completed',
  APPROVED: 'Approved',
}

export const calculateNextLevelDueIn = (nextStageGateDate) => {
  const dateA = moment().utc()
  const dateB = moment(nextStageGateDate).utc().endOf('day')

  const diff = dateB.diff(dateA)

  const duration = moment.duration(diff)
  const days = Math.floor(duration.asDays())
  const hours = Math.abs(days * HOURS - Math.floor(duration.asHours()))
  const minutes = toNumber(moment.utc(diff).format('mm'))

  return {
    days,
    hours,
    minutes,
    pastDue: !(days >= 0 && hours >= 0 && minutes >= 0),
  }
}

export const STAGE_GATE_COLORS = {
  L1: '#86caf6',
  L2: '#48aff0',
  L3: '#137cbd',
  L4: '#0E5A8A',
  L5: '#04304b',
}

export const getLevelColorById = (id) => {
  switch (id) {
    case STAGE_GATE_IDS.L1:
      return STAGE_GATE_COLORS.L1
    case STAGE_GATE_IDS.L2_SUBMITTED:
      return STAGE_GATE_COLORS.L2
    case STAGE_GATE_IDS.L2_APPROVED:
    case STAGE_GATE_IDS.L3_SUBMITTED:
      return STAGE_GATE_COLORS.L3
    case STAGE_GATE_IDS.L3_APPROVED:
    case STAGE_GATE_IDS.L4_SUBMITTED:
      return STAGE_GATE_COLORS.L4
    case STAGE_GATE_IDS.L4_APPROVED:
    case STAGE_GATE_IDS.L5_SUBMITTED:
    case STAGE_GATE_IDS.L5_APPROVED:
    case 10:
      return STAGE_GATE_COLORS.L5
    default:
      return STAGE_GATE_COLORS.L1
  }
}

export const getTooltipErrorsMessage = (errors) =>
  size(errors) ? (
    <TooltipContent>
      {errors.map((error) => (
        <ErrorDescription key={error}>{error}</ErrorDescription>
      ))}
    </TooltipContent>
  ) : null

export const getNextLevelDateDueIn = ({ isCompleted, nextStageGateDate }) => {
  if (isCompleted) return `-`

  if (!nextStageGateDate) return stageGateStatuses.NOT_APPLICABLE

  const dueToInfo = calculateNextLevelDueIn(nextStageGateDate)

  return !dueToInfo.pastDue
    ? `${dueToInfo.days} days ${dueToInfo.hours} hours ${dueToInfo.minutes} minutes`
    : stageGateStatuses.PAST_DUE
}

export const getNextLevelDateFormatted = ({ isCompleted, nextStageGateDate }) => {
  if (isCompleted) return '-'

  if (!nextStageGateDate) return stageGateStatuses.NON_SPECIFIED

  return getFormattedDate(nextStageGateDate, 'MMMM D, YYYY')
}

export const getNextStageGateDate = ({ nextStageGateLevel, initiative }) => {
  const { plannedL5Date, plannedL4Date, l3DueDate, l2DueDate } = initiative

  switch (nextStageGateLevel) {
    case 'L5':
      return plannedL5Date
    case 'L4':
      return plannedL4Date
    case 'L3':
      return l3DueDate
    default:
      return l2DueDate
  }
}

export const getNextLevelDateInfo = ({ isCompleted, initiative, nextStageGateLevel }) => {
  const nextStageGateDate = getNextStageGateDate({ initiative, nextStageGateLevel })

  return {
    nextLevelDateDueIn: getNextLevelDateDueIn({ isCompleted, nextStageGateDate }),
    nextLevelDateFormatted: getNextLevelDateFormatted({ isCompleted, nextStageGateDate }),
  }
}
export const stageGateBoundaryValues = {
  min: 0,
  max: 9,
}

import { call, put, select } from 'redux-saga/effects'
import { getTeamValueCaptureStatusUpdateApi } from '@common/net'
import { set } from 'lodash/fp'
import { createSaga } from '@common/sagaCreator/createSaga'
import { clientState as getStatusClientState } from '@teamValueCaptureV2/teamValueCaptureStatusUpdateV3/selectors'
import { IStatusUpdateState } from '@teamValueCaptureV2/teamValueCaptureStatusUpdateV3/reducers/reducer'
import {
  setTeamValueCaptureStatus,
  setTeamValueCaptureStatusItem,
  fetchTeamValueCaptureStatus as fetchTeamValueCaptureStatusAction,
} from '@teamValueCaptureV2/teamValueCaptureStatusUpdateV3/actions'

export const fetchTeamValueCaptureStatus = createSaga(function* fetchTeamValueCaptureStatus({
  payload,
}: {
  payload: { teamId: number }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload

  const api = yield getTeamValueCaptureStatusUpdateApi()
  const statuses = yield call(api.request('getTeamValueCaptureStatusUpdateV3', { query: { teamId } }))

  yield put(setTeamValueCaptureStatus(statuses))
})

export const changeTeamValueCaptureStatus = createSaga(function* changeTeamValueCaptureStatus({
  payload,
}: {
  payload: { teamId: number; key: string; value: string | null }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, key, value } = payload
  const api = yield getTeamValueCaptureStatusUpdateApi()
  const state: IStatusUpdateState = yield select(getStatusClientState)

  yield put(setTeamValueCaptureStatusItem({ key, value }))

  try {
    const requestData = set(key, value, { titles: { ...state.titles } })

    yield call(api.request('changeTeamValueCaptureStatusUpdateV3', { query: { teamId, body: requestData } }))
  } catch (error) {
    yield put(fetchTeamValueCaptureStatusAction({ teamId }))

    throw error
  }
})

import { call, put } from 'redux-saga/effects'

// Actions
import { updateRow } from '@communications/actions/actions'

// Api
import { getCommunicationsApi } from '@common/net'

// Utils
import { getRowRequestQuery, formatRowRequestBody } from '../helpers'
import { createSaga } from '@common/sagaCreator/createSaga'

export const saveRow = createSaga(function* saveRow({ payload }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { id } = payload
  const isNewRow = payload.isNew

  // Exclude keys with invalid/empty data
  const updatedData = yield formatRowRequestBody(payload)

  const api = yield getCommunicationsApi()

  const apiResponse = yield call(
    api.request(isNewRow ? 'addRow' : 'updateRow', {
      query: getRowRequestQuery(isNewRow, id),
      body: updatedData,
    }),
  )

  let rowToSet = { ...payload }
  if (isNewRow) {
    rowToSet = { ...payload, id: apiResponse.id.toString(), isNew: false }
  }

  // Update row in store to show data in the table
  yield put(updateRow({ id, data: rowToSet }))
}, true)

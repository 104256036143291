// TODO refactor to avoid using @store inside static function, it crashing tests

import { isFunction, isNil, isNumber, round, toNumber } from 'lodash'
import { CURRENCY_ABBREVIATION, CURRENCY_ROUNDING, SEPARATOR } from '@helpers/constants'
import {
  getCurrencySetting,
  getCurrencyRounding,
  getSeparatorSetting,
  getThousandSeparatorSetting,
} from '@domain/instanceConfig/selectors'
import { store } from '../../../domain/store'
import { getOr, toLower } from 'lodash/fp'
import Currency from '@shared/Currency/Currency'
import { InitiativeDto } from '@common/types/Initiative/InitiativeDto'

export const ROUNDING_PARAMS = {
  [CURRENCY_ROUNDING.THOUSAND_NO_DECIMAL]: {
    denominator: 100000,
    digitsAfterPoint: 0,
    word: 'k',
  },
  [CURRENCY_ROUNDING.MILLION_NO_DECIMAL]: {
    denominator: 100000000,
    digitsAfterPoint: 0,
    word: 'm',
  },
  [CURRENCY_ROUNDING.MILLION_ONE_DECIMAL]: {
    denominator: 100000000,
    digitsAfterPoint: 1,
    word: 'm',
  },
  [CURRENCY_ROUNDING.MILLION_TWO_DECIMAL]: {
    denominator: 100000000,
    digitsAfterPoint: 2,
    word: 'm',
  },
  default: {
    denominator: 100,
    digitsAfterPoint: 2,
    word: '',
  },
}

export const CURRENCY_SYMBOL = {
  [CURRENCY_ABBREVIATION.USD]: '$',
  [CURRENCY_ABBREVIATION.CAD]: '$',
  [CURRENCY_ABBREVIATION.EUR]: '€',
  [CURRENCY_ABBREVIATION.GBP]: '£',
  [CURRENCY_ABBREVIATION.JPY]: '¥',
}

export const SEPARATOR_SYMBOL = {
  [SEPARATOR.PERIOD]: '.',
  [SEPARATOR.COMMA]: ',',
  [SEPARATOR.NONE]: '',
}

export const DIGIT_AMOUNT_WITHOUT_CENTS = 6

/**
 * @deprecated. Use useCurrencyInstance instead
 */
export const roundNumber = (amount: $TSFixMe, type: $TSFixMe, separatorType: $TSFixMe, withWordings = true) => {
  const { denominator = 100, word = '', digitsAfterPoint = 0 } = ROUNDING_PARAMS[type] || {}

  const separatorSymbol = SEPARATOR_SYMBOL[separatorType] || '.'

  return `${round(amount / denominator, digitsAfterPoint)
    .toString()
    .replace('.', separatorSymbol)}${withWordings ? word : ''}`
}

/**
 * @deprecated. Use useCurrencyInstance instead
 * (can be deleted after deleting src/views/myImoClient/components/TeamValueCaptureV2/InitiativeList/AsidePanel/OneTimeCost.js)
 */
export const roundByCurrencyDeprecated = (number: $TSFixMe, type: $TSFixMe, isRound = true) => {
  if (!number) return number

  const { denominator = 100 } = ROUNDING_PARAMS[type] || {}
  const amount = number.replace && number.replace(',', '.') * denominator

  return isRound ? round(+amount) : amount
}

/**
 * @deprecated. Use useCurrencyInstance instead
 */
export const parseCurrencyAmount = (amount: $TSFixMe, round = true) => {
  if (amount === null) return null

  const state = store.getState()
  const currency = getCurrencySetting(state)
  const roundingType = getCurrencyRounding(state)
  const separator = getSeparatorSetting(state)
  const thousandsSeparator = getThousandSeparatorSetting(state)

  const currencyInstance = new Currency({ currency, roundingType, separator, thousandsSeparator })

  return currencyInstance.roundByCurrency(Number(amount), round)
}

export const formatNumberToDisplayString = ({
  value,
  decimalSeparatorSymbol = '.',
  thousandsSeparatorSymbol = '',
}: $TSFixMe) => {
  if (isNil(value)) return ''

  const [wholeNumber, decimalPart] = value.toString().split('.')
  let wholePartResult = ''

  // add thousands separator to wholeNumber
  for (let index = wholeNumber.length - 1, count = 1; index >= 0; index--, count++) {
    wholePartResult = wholeNumber[index] + wholePartResult

    if (count % 3 === 0 && index !== 0) wholePartResult = `${thousandsSeparatorSymbol}${wholePartResult}`
  }

  return decimalPart ? `${wholePartResult}${decimalSeparatorSymbol}${decimalPart}` : wholePartResult
}

const transformCurrencyDefaultOptions = {
  onlyNumber: false,
  onlyDenominate: false,
  onlyInteger: false,
  withCurrency: true,
  withWording: true,
  withRounding: true,
}

/**
 * @deprecated. Use useCurrencyInstance instead
 */
export const addCurrencySymbolToString = (string: $TSFixMe) => {
  const state = store.getState()
  const currency = getCurrencySetting(state)

  if (CURRENCY_SYMBOL[currency]) {
    return `${string} (${CURRENCY_SYMBOL[currency]})`
  }

  return string
}

/**
 * @deprecated. Use useCurrencyInstance instead
 */
export const addCurrencySymbol = (value: $TSFixMe, state: $TSFixMe = store.getState()) => {
  const currency = toLower(getCurrencySetting(state))
  const currencySymbol = CURRENCY_SYMBOL[currency] || ''

  switch (currency) {
    case CURRENCY_ABBREVIATION.EUR:
      return `${value} ${currencySymbol}`
    default:
      return `${currencySymbol}${value}`
  }
}

/**
 * @deprecated. Use useCurrencyInstance instead
 */
export const combineCurrencySymbolWithRounding = (value: $TSFixMe) => {
  const state = store.getState()
  const currency = getCurrencySetting(state)
  const rounding = getCurrencyRounding(state)

  return `${value} (${CURRENCY_SYMBOL[currency] || currency.toUpperCase()}${ROUNDING_PARAMS[rounding].word})`
}

/**
 * @deprecated. Use useCurrencyInstance instead
 */
export const transformCurrencyValue = (amount: $TSFixMe, options = {}) => {
  const state = store.getState()
  const {
    onlyNumber,
    withRounding,
    withCurrency,
    withWording,
    onlyDenominate,
    fullSized,
    onlyInteger,
    roundTo,
    withSeparator,
    isDownload,
  } = Object.assign({}, transformCurrencyDefaultOptions, options) as $TSFixMe

  const currencyRounding = getCurrencyRounding(state)
  const { denominator = 100, word = '', digitsAfterPoint = 0 } = ROUNDING_PARAMS[currencyRounding] || {}
  let digitsAmount
  if (roundTo) {
    digitsAmount = roundTo
  } else {
    digitsAmount = fullSized ? `${amount % denominator}`.length : digitsAfterPoint
  }

  let value = amount

  if (onlyInteger) {
    digitsAmount = 0
  }

  if (isNil(amount)) {
    // return empty value if 0 does not being set explicitly
    return amount
  }

  if (onlyDenominate) {
    value = round(amount / denominator, DIGIT_AMOUNT_WITHOUT_CENTS)
  } else if (withRounding) {
    value = round(amount / denominator, digitsAmount)
  }

  if (fullSized) {
    const amountChars = amount.split('')

    amountChars.splice(amount.length - digitsAmount, 0, '.')

    return amountChars.join('')
  }

  const separator = getSeparatorSetting(state)
  const separatorSymbol = SEPARATOR_SYMBOL[separator] || '.'

  const thousandsSeparator = getThousandSeparatorSetting(state)
  const thousandsSeparatorSymbol = SEPARATOR_SYMBOL[thousandsSeparator]

  let formattedValue = formatNumberToDisplayString({
    value,
    decimalSeparatorSymbol: separatorSymbol,
    thousandsSeparatorSymbol,
  })

  if (onlyNumber) {
    if (withSeparator) return formattedValue

    return value
  }

  if (withWording) {
    formattedValue = formattedValue + word
  }

  if (!withCurrency) {
    if (isDownload) {
      // @ts-ignore
      formattedValue = formattedValue.replaceAll(thousandsSeparatorSymbol, '')
    }

    return Number(formattedValue)
  }

  return addCurrencySymbol(formattedValue, state)
}

/**
 * @deprecated. Use useCurrencyInstance instead
 */
export const formatCentsToMoney = (value: $TSFixMe, options: $TSFixMe) => {
  if (options.emptyIfZero && toNumber(value) === 0) return ''

  return isNumber(value) ? transformCurrencyValue(value / 100, options) : value
}

export const formatEmptyNumberValue = (value: $TSFixMe) => (value === 0 ? '-' : '')

export const formatFTEEmptyNumberValue = (value: $TSFixMe) => (value === 0 ? '0' : '')

export const displayDashForZeroValue = (value: $TSFixMe) => (value === 0 ? '-' : value)

/**
 * @deprecated. Use useCurrencyInstance instead
 */
export const getDisplayNumber = (
  { value, formatValue, formatOptions, isFTE }: $TSFixMe,
  ignoreEmptyValues: $TSFixMe,
) => {
  if (!value && isFTE) return formatFTEEmptyNumberValue(value)

  if (value && isFTE) return value

  if (!ignoreEmptyValues && !value) return formatEmptyNumberValue(value)

  const formattedNumber = isFunction(formatValue) ? formatValue(Math.abs(value), formatOptions) : Math.abs(value)

  if (isNumber(value) && !isFTE && value < 0) return `(${formattedNumber})`

  return formattedNumber
}

/**
 * @deprecated. Use useCurrencyInstance instead
 */
export const getDisplayNumberCurrency = ({ value, ignoreEmptyValues }: $TSFixMe) =>
  getDisplayNumber(
    {
      value: value ? +value : value,
      formatValue: transformCurrencyValue,
    },
    ignoreEmptyValues,
  )

/**
 * @deprecated. Use useCurrencyInstance instead
 */
export const getDisplayNumberCurrencyForChart = ({
  value,
  withWording,
  withCurrency,
  onlyNumber,
  withSeparator,
}: $TSFixMe) =>
  isNil(value)
    ? '-'
    : getDisplayNumber(
        {
          value: value ? +value : value,
          formatValue: transformCurrencyValue,
          formatOptions: { withWording, withCurrency, onlyNumber, withSeparator },
        },
        true,
      )

/**
 * @deprecated. Use useCurrencyInstance instead
 */
export const getDenominatedCurrencyValue = (value: $TSFixMe, options = {}) => {
  const defaultOptions = {
    onlyDenominate: true,
    withCurrency: false,
    withWording: false,
    onlyNumber: true,
  }

  return transformCurrencyValue(value, {
    ...defaultOptions,
    ...options,
  })
}

export const getDisplayCurrencyOptions = (value: $TSFixMe, ignoreEmptyValues = true) => ({
  value,
  ignoreEmptyValues,
})

export const getMirroredValueForTooltip = (data: InitiativeDto) => {
  const totalValue = getOr(0, ['parentInitiative', 'totalImpact'], data)
  const mirroredValue = getOr(0, ['parentInitiative', 'mirroredImpact'], data)
  const totalImpact = getDisplayNumberCurrency(getDisplayCurrencyOptions(totalValue))
  const mirroredImpact = getDisplayNumberCurrency(getDisplayCurrencyOptions(mirroredValue))

  return [totalImpact, mirroredImpact]
}

export const wrapNegativeValuesInBrackets = (value: number, formattedValue: string) => {
  if (value < 0) return `(${formattedValue})`

  return formattedValue
}

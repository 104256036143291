import { all, takeLatest } from 'redux-saga/effects'
import * as constants from '@teamResources/teamContacts/actions/actionTypes'
import { fetchTeamContacts, createTeamContact, updateTeamContact, deleteTeamContact } from './teamContactsSagas'

export default function* teamContactsSaga() {
  yield all([
    takeLatest(constants.FETCH_TEAM_CONTACTS, fetchTeamContacts),
    takeLatest(constants.CREATE_TEAM_CONTACT, createTeamContact),
    takeLatest(constants.UPDATE_TEAM_CONTACT, updateTeamContact),
    takeLatest(constants.DELETE_TEAM_CONTACT, deleteTeamContact),
  ])
}

import { get, uniq } from 'lodash'
import { ValidationError } from './customErrors'
import {
  deliverablesSchema,
  taxonomySchema,
  teamDeliverablesSchema,
  teamSetupSchema,
  teamsHierarchySchema,
  usersSchema,
  welcomeNoteSchema,
} from './schemas'
import { validateTeamNameUniq } from './validators'

export const validateWelcomeNote = async (data) => {
  try {
    await welcomeNoteSchema.validate(data)
  } catch (e) {
    throw new ValidationError(e)
  }
}

export const validateTeamSetupSchema = async (data) => {
  try {
    await teamSetupSchema.validate(data, { abortEarly: false })
  } catch (e) {
    throw new ValidationError({
      message: '{teamType} teams must have all Client Taxonomy fields filled out before proceeding',
    })
  }
}

export const validateTeamsHierarchy = async (data) => {
  try {
    await teamsHierarchySchema.validate(data)
  } catch (e) {
    throw new ValidationError(e)
  }
}

export const validateTaxonomy = async (data) => {
  try {
    await taxonomySchema.validate(data, { abortEarly: false })
  } catch (e) {
    throw new ValidationError({ message: get(e, 'errors', []).join(' ') })
  }
}

export const validateUsers = async (data) => {
  try {
    await usersSchema.validate(data, { abortEarly: false })
  } catch (e) {
    throw new ValidationError({ message: uniq(get(e, 'errors', [])).join(' ') })
  }
}

export const validateDeliverables = async (data) => {
  try {
    await deliverablesSchema.validate(data)
  } catch (e) {
    throw new ValidationError(e)
  }
}

export const validateTeamDeliverables = async (data) => {
  try {
    await teamDeliverablesSchema.validate(data)
  } catch (e) {
    throw new ValidationError(e)
  }
}

export const validateTeamSetup = async (data) => {
  await validateTeamNameUniq(data)
  await validateTeamSetupSchema(data)
}

// Libs
import { call, put } from 'redux-saga/effects'

// Actions
import { fetchProgramProjectPlanData, fetchProjectList } from '@dayOne/actions/actions'

// Utils
import { getDayOneApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'

export const createProcessFromLib = createSaga(function* createProcessFromLib({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, processesToCreate, imoTeamId, isProgramProjectList = false } = payload

  const api = yield getDayOneApi()
  yield call(
    api.request('createProcessFromLib', {
      query: { teamId },
      body: processesToCreate,
    }),
  )

  if (isProgramProjectList) {
    yield put(fetchProgramProjectPlanData({ teamId: imoTeamId }))
  } else {
    yield put(fetchProjectList({ teamId }))
  }
},
true)

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchSteerCoActionsData = createAction(
  constants.FETCH_STEER_CO_ACTIONS_DATA,
  null,
  getMetaWithActionLoading,
)
export const setSteerCoActionsData = createAction(constants.SET_STEER_CO_ACTIONS_DATA)

export const updateSteerCoActionsData = createAction(constants.UPDATE_STEER_CO_ACTIONS_DATA)
export const updateSteerCoActionsDataSuccess = createAction(constants.UPDATE_STEER_CO_ACTIONS_DATA_SUCCESS)
export const updateSteerCoGridData = createAction(constants.UPDATE_STEER_CO_GRID_DATA)
export const updateSteerCoGridDataSuccess = createAction(constants.UPDATE_STEER_CO_GRID_DATA_SUCCESS)

export const fetchParkingLotData = createAction(constants.FETCH_PARKING_LOT_DATA, null, getMetaWithActionLoading)
export const setParkingLotData = createAction(constants.SET_PARKING_LOT_DATA)

export const updateParkingLotData = createAction(constants.UPDATE_PARKING_LOT_DATA)
export const updateParkingLotDataSuccess = createAction(constants.UPDATE_PARKING_LOT_DATA_SUCCESS)

export const fetchColumnsData = createAction(constants.FETCH_COLUMNS_DATA)
export const fetchColumnsDataWithLoader = createAction(constants.FETCH_COLUMNS_DATA, null, getMetaWithActionLoading)
export const setColumnsData = createAction(constants.SET_COLUMNS_DATA)
export const updateColumnsData = createAction(constants.UPDATE_COLUMNS_DATA)
export const deleteColumnsData = createAction(constants.DELETE_COLUMNS_DATA)

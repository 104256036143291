import styled from 'styled-components'
import { Button, Tabs, Icon } from '@blueprintjs/core'
import { DELIVERABLE_STATUS_COLORS } from '@helpers/statuses'
import { statusIndicatorHeight } from '@helpers/constants'
import MyIMOLogoWhite from '@assets/img/logo/my-imo-logo-white.svg'
import { greyForBtn, greyDarkBackColor, borderColor, white } from '@imo/imo-ui-toolkit'

export const Container = styled.div`
  width: 100%;
  min-width: 700px;
  user-select: none;
  position: relative;
  z-index: 1;
`

export const StyledIcon = styled(Icon)`
  margin-right: 10px;
`

export const StatusIndicator = styled.div`
  height: ${({ size }) => (size === 'min' ? '5px' : `${statusIndicatorHeight}px`)};
  width: 100%;
  background: ${({ status }) => (status ? DELIVERABLE_STATUS_COLORS[status] : 'none')};
`

export const StatusContent = styled.div`
  background-color: ${({ color }) => color || 'none'};
  text-transform: uppercase;
  border-radius: 3px;
  color: ${white};
  line-height: 14px;
  padding: 1px 6px 1px;
  text-align: center;
`

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${borderColor};
  align-items: center;
  padding: 0 5px 0 15px;
  background: ${white};
  position: relative;
  top: 0;
  min-height: 40px;
  flex-wrap: wrap;
  z-index: 95;
  margin: ${({ $innerHeader }) => ($innerHeader ? '0 6px' : 0)};
  border-radius: ${({ $innerHeader }) => ($innerHeader ? '4px 4px 0 0' : 0)};
`

export const LogoWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 100%;

  .logo-separator {
    font-size: 20px;
    font-weight: 300;
    line-height: 1;
  }
`

export const Logo = styled.div`
  height: 100%;
  margin: 6px 10px 5px;
  max-height: 25px;

  &:last-of-type {
    margin-right: 0;
  }
`

export const Image = styled.img`
  max-height: 25px;
  height: 25px;
`

export const StyledTabs = styled(Tabs)`
  .bp4-tab-list {
    padding: 10px 15px;
    border-bottom: 1px solid ${borderColor};
    background: ${white};
  }

  .bp4-tab-panel {
    margin-top: 0;
  }
`

export const InfoArea = styled.div`
  display: flex;
  align-items: center;
  min-height: 100%;
  margin: 5px 0;
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 100%;
  margin: 5px 0;
`

export const StyledButton = styled(Button)`
  font-size: 14px;
  width: 90px;
  height: 30px;
  box-sizing: border-box;
  margin-left: 5px;
  padding: 0;
  ${(props) => props.styles}

  &.reset-to-pre-submit {
    width: 45px;
  }

  .bp4-button-text {
    color: ${(props) => (!!props.background ? white : 'inherit')};
  }
`

export const MinimalButton = styled(Button)`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  border-radius: 2px;
  padding: 4px 7px;

  &.disabled {
    cursor: no-drop;
    color: #909ba4;
  }
`

export const CommentsButton = styled.div`
  display: flex;
  align-items: center;
  color: ${greyForBtn};
  font-size: 12px;
  cursor: pointer;
  height: 30px;
  border-radius: 3px !important;
  padding: 0 5px;
  transition: background 0.3s;

  &:hover {
    background: ${greyDarkBackColor};
  }

  &.disabled {
    cursor: no-drop;
    color: #909ba4;
  }
`

export const Label = styled.div`
  margin-left: 5px;
`

export const CloseButton = styled(Button)`
  margin-left: 5px;
`

export const LogoWhite = styled.div`
  background-image: url(${MyIMOLogoWhite});
  background-attachment: unset;
  background-repeat: no-repeat;
  background-size: cover;
  width: 17px;
  height: 16px;
`

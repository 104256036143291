import React from 'react'
import { Colors, Icon } from '@blueprintjs/core'
import { Wrapper, Title, FilterName } from './CustomFilterTitle.styles'
import P from 'prop-types'

const CustomFilter = ({ filterName }) => (
  <Wrapper>
    <Icon icon="filter-list" size={12} color={Colors.GRAY1} />
    <Title>Applied filter:</Title>
    <FilterName id="filter-tool-panel-title">{filterName}</FilterName>
  </Wrapper>
)

CustomFilter.propTypes = {
  filterName: P.string,
}

CustomFilter.defaultProps = {
  filterName: 'None',
}

export default CustomFilter

import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchTeamCharterData = createAction(constants.FETCH_TEAM_CHARTER_DATA)
export const refetchTeamCharterData = createAction(constants.REFETCH_TEAM_CHARTER_DATA)
export const fetchTeamCharterDataWithLoader = createAction(
  constants.FETCH_TEAM_CHARTER_DATA,
  null,
  getMetaWithActionLoading,
)
export const setTeamCharterData = createAction(constants.SET_TEAM_CHARTER_DATA)
export const updateTeamCharterData = createAction(constants.UPDATE_TEAM_CHARTER_DATA)

export const setInScopeKeyProcesses = createAction(constants.SET_IN_SCOPE_KEY_PROCESSES)
export const createInScopeKeyProcessSuccess = createAction(constants.CREATE_IN_SCOPE_KEY_PROCESS_SUCCESS)
export const createInScopeKeyProcessPending = createAction(constants.CREATE_IN_SCOPE_KEY_PROCESS_PENDING)
export const updateInScopeKeyProcessPending = createAction(constants.UPDATE_IN_SCOPE_KEY_PROCESS_PENDING)
export const setUpdateInScopeKeyProcess = createAction(constants.SET_UPDATE_IN_SCOPE_KEY_PROCESS)
export const deleteInScopeKeyProcessSuccess = createAction(constants.DELETE_IN_SCOPE_KEY_PROCESS_SUCCESS)
export const deleteInScopeKeyProcessPending = createAction(constants.DELETE_IN_SCOPE_KEY_PROCESS_PENDING)

export const setOutScopeKeyProcesses = createAction(constants.SET_OUT_SCOPE_KEY_PROCESSES)
export const addOutScopeKeyProcess = createAction(constants.ADD_OUT_SCOPE_KEY_PROCESS)
export const createOutScopeKeyProcess = createAction(constants.CREATE_OUT_SCOPE_KEY_PROCESS)
export const updateOutScopeKeyProcess = createAction(constants.UPDATE_OUT_SCOPE_KEY_PROCESS)
export const setUpdateOutScopeKeyProcess = createAction(constants.SET_UPDATE_OUT_SCOPE_KEY_PROCESS)
export const removeOutScopeKeyProcess = createAction(constants.REMOVE_OUT_SCOPE_KEY_PROCESS)
export const deleteOutScopeKeyProcess = createAction(constants.DELETE_OUT_SCOPE_KEY_PROCESS)

export const setMacroPlanningAssumption = createAction(constants.SET_MACRO_PLANNING_ASSUMPTION)
export const addMacroPlanningAssumption = createAction(constants.ADD_MACRO_PLANNING_ASSUMPTION)
export const createMacroPlanningAssumption = createAction(constants.CREATE_MACRO_PLANNING_ASSUMPTION)
export const setUpdateMacroPlanningAssumption = createAction(constants.SET_UPDATE_MACRO_PLANNING_ASSUMPTION)
export const updateMacroPlanningAssumption = createAction(constants.UPDATE_MACRO_PLANNING_ASSUMPTION)
export const removeMacroPlanningAssumption = createAction(constants.REMOVE_MACRO_PLANNING_ASSUMPTION)
export const deleteMacroPlanningAssumption = createAction(constants.DELETE_MACRO_PLANNING_ASSUMPTION)

export const setKeyInterdependencies = createAction(constants.SET_KEY_INTERDEPENDENCIES)
export const addKeyInterdependency = createAction(constants.ADD_KEY_INTERDEPENDENCY)
export const createKeyInterdependency = createAction(constants.CREATE_KEY_INTERDEPENDENCY)
export const updateKeyInterdependency = createAction(constants.UPDATE_KEY_INTERDEPENDENCY)
export const setUpdateKeyInterdependency = createAction(constants.SET_UPDATE_KEY_INTERDEPENDENCY)
export const removeKeyInterdependency = createAction(constants.REMOVE_KEY_INTERDEPENDENCY)
export const deleteKeyInterdependency = createAction(constants.DELETE_KEY_INTERDEPENDENCY)

export const setKeyRiskToMitigate = createAction(constants.SET_KEY_RISK_TO_MITIGATE)
export const addKeyRiskToMitigate = createAction(constants.ADD_KEY_RISK_TO_MITIGATE)
export const createKeyRiskToMitigate = createAction(constants.CREATE_KEY_RISK_TO_MITIGATE)
export const updateKeyRiskToMitigate = createAction(constants.UPDATE_KEY_RISK_TO_MITIGATE)
export const setUpdateKeyRiskToMitigate = createAction(constants.SET_UPDATE_KEY_RISK_TO_MITIGATE)
export const removeKeyRiskToMitigate = createAction(constants.REMOVE_KEY_RISK_TO_MITIGATE)
export const deleteKeyRiskToMitigate = createAction(constants.DELETE_KEY_RISK_TO_MITIGATE)

export const setKeyDecisionToMake = createAction(constants.SET_KEY_DECISION_TO_MAKE)
export const addKeyDecisionToMake = createAction(constants.ADD_KEY_DECISION_TO_MAKE)
export const createKeyDecisionToMake = createAction(constants.CREATE_KEY_DECISION_TO_MAKE)
export const updateKeyDecisionToMake = createAction(constants.UPDATE_KEY_DECISION_TO_MAKE)
export const setUpdateKeyDecisionToMake = createAction(constants.SET_UPDATE_KEY_DECISION_TO_MAKE)
export const removeKeyDecisionToMake = createAction(constants.REMOVE_KEY_DECISION_TO_MAKE)
export const deleteKeyDecisionToMake = createAction(constants.DELETE_KEY_DECISION_TO_MAKE)

export const fetchTeamMembers = createAction(constants.FETCH_TEAM_MEMBERS)
export const setTeamMembers = createAction(constants.SET_TEAM_MEMBERS)
export const addTeamMember = createAction(constants.ADD_TEAM_MEMBER)
export const updateTeamMember = createAction(constants.UPDATE_TEAM_MEMBER)
export const removeTeamMember = createAction(constants.REMOVE_TEAM_MEMBER)
export const deleteTeamMember = createAction(constants.DELETE_TEAM_MEMBER)
export const saveTeamMembers = createAction(constants.SAVE_TEAM_MEMBERS)

export const fetchTeamCharterLibraryData = createAction(
  constants.FETCH_TEAM_CHARTER_LIBRARY_DATA,
  null,
  getMetaWithActionLoading,
)
export const setTeamCharterLibraryData = createAction(constants.SET_TEAM_CHARTER_LIBRARY_DATA)
export const updateTeamCharterLibraryData = createAction(constants.UPDATE_TEAM_CHARTER_LIBRARY_DATA)
export const updateLibraryAssumptionsGroup = createAction(constants.UPDATE_LIBRARY_ASSUMPTIONS_GROUP)
export const saveTeamCharterLibraryData = createAction(constants.SAVE_TEAM_CHARTER_LIBRARY_DATA)

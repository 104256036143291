import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchTeamValueCaptureStatusWithLoader = createAction(
  constants.FETCH_TEAM_VALUE_CAPTURE_STATUS,
  null,
  getMetaWithActionLoading,
)
export const fetchTeamValueCaptureStatus = createAction(constants.FETCH_TEAM_VALUE_CAPTURE_STATUS)
export const setTeamValueCaptureStatus = createAction(constants.SET_TEAM_VALUE_CAPTURE_STATUS)
export const setTeamValueCaptureStatusItem = createAction(constants.SET_TEAM_VALUE_CAPTURE_STATUS_ITEM)
export const changeTeamValueCaptureStatus = createAction(constants.CHANGE_TEAM_VALUE_CAPTURE_STATUS)
